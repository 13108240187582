// @flow
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { withFormik, Form } from 'formik';
import { withRouter, Redirect } from 'react-router';
import { Button } from '@material-ui/core';
import type { FormikBag } from 'formik';
import type { History, Location } from 'history';
import { withSnackbar } from 'notistack';
import withWidth from '@material-ui/core/withWidth';

import SetupPageLayout from '../../components/SetupPageLayout';
import AgreementContent from '../../components/AgreementContent';
import { defaultHandleError } from '../../lib/apiHelpers';
import { type AccountSetupStatus } from '../../components/AccountSetupCheck/reducer';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(3, 2, 0, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 120,
    },
  },
  borrowerName: {
    margin: theme.spacing(2, 0),
  },
  checkbox: {
    paddingTop: 0,
    alignSelf: 'baseline',
  },
}));

type FormValues = {
  agreementVersion: string,
};

type Props = {
  gcAccountId: string,
  contactFullName: string,
  privacyAgreementSetup: AccountSetupStatus,
};

type WithHOCProps = {
  ...Props,
  isSubmitting: boolean,
};

const PrivacyAgreement = ({ privacyAgreementSetup, isSubmitting }: WithHOCProps) => {
  const classes = useStyles();
  const hasAgreementFailedToLoad = privacyAgreementSetup && !privacyAgreementSetup.data;

  if (hasAgreementFailedToLoad || privacyAgreementSetup.isCompleted) {
    return <Redirect to='/' />;
  }

  // $FlowFixMe
  const agreementText = privacyAgreementSetup.data.base64text;

  return (
    <SetupPageLayout title='Privacy Agreement' subtitle='Please read the following agreement carefully before continuing.'>
      {privacyAgreementSetup ? <AgreementContent base64Text={agreementText} height='55vh' /> : null}
      <Form noValidate autoComplete='off'>
        <Button className={classes.button} variant='contained' color='primary' type='submit' disabled={isSubmitting} data-testid='uia-agreeButton'>
          I agree
        </Button>
      </Form>
    </SetupPageLayout>
  );
};

type WrapperProps = {
  ...WithHOCProps,
  signPrivacyAgreement: (gcAccountId: string, agreementVersion: string) => Promise<any>,
  updatePrivacyAgreementSetupCompleted: (value: boolean) => void,
};

type WithHOCWrapperProps = {
  ...WrapperProps,
  history: History<Location>,
};

export default withRouter<WrapperProps>(
  withSnackbar(
    withFormik({
      mapPropsToValues: (): FormValues => ({
        acknowledgement: false,
        agreementVersion: '',
      }),
      validateOnBlur: false,
      handleSubmit: (values: FormValues, formikBag: FormikBag<WithHOCWrapperProps, FormValues>) => {
        const { props } = formikBag;

        props
          .signPrivacyAgreement(
            props.gcAccountId,
            // $FlowFixMe
            props.privacyAgreementSetup.data.currentActiveAgreementVersion
          )
          .then((response) => {
            formikBag.setSubmitting(false);

            if (response.error) {
              defaultHandleError(formikBag.props);
              return;
            }

            formikBag.props.updatePrivacyAgreementSetupCompleted(true);
          });
      },
      displayName: 'PrivacyAgreement',
    })(withWidth()(PrivacyAgreement))
  )
);
