// @flow
import { selectActiveProfileMode } from '../../lib/profileHelper';
import type { ReduxState } from './reducer';

import { PARTITION } from './constants';

const selectPartition = (state: any): ReduxState => state[PARTITION];
const selectOpenTradeAccount = (state: any) => selectPartition(state).openTradeAccount;

export default {
  selectActiveProfileMode,
  selectOpenTradeAccount,
};
