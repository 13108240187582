// @flow
import libphonenumber from 'google-libphonenumber';
import { DEFAULT_TERM_PERIOD } from '../constants';

export const formatAbnOrAcn = (raw: string): string => {
  if (!raw) {
    return raw;
  }

  const trimmed = raw.replace(/ /g, '');

  // ABN - ## ### ### ###
  if (trimmed.length === 11) {
    return `${trimmed.substring(0, 2)} ${trimmed.substring(2, 5)} ${trimmed.substring(5, 8)} ${trimmed.substring(8)}`;
  }

  // ACN - ### ### ###
  if (trimmed.length === 9) {
    return `${trimmed.substring(0, 3)} ${trimmed.substring(3, 6)} ${trimmed.substring(6)}`;
  }

  return raw;
};

export const formatMobileNumber = (raw: string, international: boolean = false): string => {
  if (!raw) {
    return '';
  }

  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  const googleMobileNumber = phoneUtil.parse(raw, 'AU');
  const formattedMobileNumber = phoneUtil.format(
    googleMobileNumber,
    international ? libphonenumber.PhoneNumberFormat.INTERNATIONAL : libphonenumber.PhoneNumberFormat.NATIONAL
  );

  return formattedMobileNumber;
};

export const formatSupplierTerm = (termInDays: number, termPeriod: string = DEFAULT_TERM_PERIOD, pluralize: boolean = true): string => {
  if (termInDays === 1) pluralize = false;
  if (!termPeriod) termPeriod = DEFAULT_TERM_PERIOD;

  if (termInDays === 0) {
    return 'COD';
  }
  if (termInDays === 365) {
    return '52 weeks';
  }
  if (termInDays === 182) {
    return '26 weeks';
  }
  let termIsDaysDisplay = DEFAULT_TERM_PERIOD;
  switch (termPeriod) {
    case 'EOM': {
      termIsDaysDisplay = pluralize ? 'days EOM' : 'day EOM';
      break;
    }
    default:
      termIsDaysDisplay = pluralize ? 'days' : 'day';
      break;
  }

  return `${termInDays} ${termIsDaysDisplay}`;
};

export default {
  formatAbnOrAcn,
  formatMobileNumber,
  formatSupplierTerm,
};
