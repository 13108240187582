// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  theme: any,
};

const BusinessIcon = withTheme((props: Props) => (
  <SvgIcon viewBox='0 0 53 53' {...(props: any)}>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-637.000000, -509.000000)' fill={props.theme.palette.primary.main} fillRule='nonzero'>
        <g transform='translate(637.000000, 509.000000)'>
          <path
            d='M31.8076923,31.8750093 L30.8257692,31.8750093 C32.0174102,30.724276 32.690217,29.147277 32.6923077,27.5000059 L32.6923077,27.2025101 C35.1337675,26.3529957 36.5998196,23.8855893 36.1622786,21.3624525 C35.7247376,18.8393157 33.511191,16.9961979 30.9230769,17.0000059 L18.5384615,17.0000059 C17.5613424,17.0000059 16.7692308,17.7835134 16.7692308,18.7500115 L16.7692308,22.2500109 C16.768051,24.4758814 18.1860595,26.4605535 20.3076923,27.2025101 L20.3076923,27.5000059 C20.309783,29.147277 20.9825898,30.724276 22.1742308,31.8750093 L21.1923077,31.8750093 C17.7723906,31.8750093 15,34.6172647 15,38.0000059 L15,51.1250061 C15,51.6082551 15.3960558,52.0000059 15.8846154,52.0000059 C16.373175,52.0000059 16.7692308,51.6082551 16.7692308,51.1250061 L16.7692308,38.0000059 C16.7692308,35.5837629 18.7495098,33.625009 21.1923077,33.625009 L31.8076923,33.625009 C34.2504902,33.625009 36.2307692,35.5837629 36.2307692,38.0000059 L36.2307692,51.1250061 C36.2307692,51.6082551 36.626825,52.0000059 37.1153846,52.0000059 C37.6039442,52.0000059 38,51.6082551 38,51.1250061 L38,38.0000059 C38,34.6172647 35.2276094,31.8750093 31.8076923,31.8750093 L31.8076923,31.8750093 Z M18.5384615,22.2500115 L18.5384615,18.7500115 L30.9230769,18.7500115 C32.5220947,18.7528097 33.9205131,19.8159843 34.3345823,21.3436743 C34.7486515,22.8713642 34.0754627,24.4838926 32.6923077,25.2775104 L32.6923077,22.2500115 C32.6923077,21.7667618 32.2962519,21.3750111 31.8076923,21.3750111 L21.1923077,21.3750111 C20.7037481,21.3750111 20.3076923,21.7667618 20.3076923,22.2500115 L20.3076923,25.2775104 C19.2140103,24.6529372 18.5397569,23.4991579 18.5384615,22.2500115 Z M22.0769231,27.50001 L22.0769231,23.1250108 L30.9230769,23.1250108 L30.9230769,27.50001 C30.9230769,29.9162554 28.9427979,31.8750093 26.5,31.8750093 C24.0572021,31.8750093 22.0769231,29.9162554 22.0769231,27.50001 Z'
            id='Shape'
          />
          <path
            d='M20,39 C19.4477153,39 19,39.3917508 19,39.875 L19,52.125 C19,52.6082492 19.4477153,53 20,53 C20.5522847,53 21,52.6082492 21,52.125 L21,39.875 C21,39.3917508 20.5522847,39 20,39 Z'
            id='Path'
          />
          <path
            d='M32,39 C31.4477153,39 31,39.3917508 31,39.875 L31,52.125 C31,52.6082492 31.4477153,53 32,53 C32.5522847,53 33,52.6082492 33,52.125 L33,39.875 C33,39.3917508 32.5522847,39 32,39 Z'
            id='Path'
          />
          <path
            d='M28.301047,40.5992445 C28.7470807,40.1283897 28.9974405,39.5006265 29,38.8466595 C29,38.3790624 28.627462,38 28.1679131,38 C27.7083641,38 27.3358261,38.3790624 27.3358261,38.8466595 C27.335378,39.1237064 27.2017455,39.3830036 26.9780287,39.5409202 L26.9780287,39.5409202 C26.7003343,39.7269994 26.3404275,39.7269994 26.0627331,39.5409202 L26.0627331,39.5409202 C25.8263359,39.3907413 25.6795243,39.1301161 25.6716522,38.8466595 C25.6716522,38.3790624 25.2991142,38 24.8395653,38 C24.3800164,38 24.0074783,38.3790624 24.0074783,38.8466595 C24.0100378,39.5006265 24.2603977,40.1283897 24.7064314,40.5992445 L24.0074783,45.5014028 C23.980322,45.70614 24.0276726,45.9139155 24.1406122,46.0855978 L25.8047861,48.6255762 C25.959356,48.8596178 26.2182938,49 26.4954183,49 C26.7725428,49 27.0314806,48.8596178 27.1860505,48.6255762 L28.8502244,46.0855978 C28.963164,45.9139155 29.0105146,45.70614 28.9833583,45.5014028 L28.301047,40.5992445 Z M26.5037392,46.6359265 L25.6716522,45.4167368 L26.2790757,41.3866378 L26.7284026,41.3866378 L27.3358261,45.4167368 L26.5037392,46.6359265 Z'
            id='Shape'
          />
          <path
            d='M18.8888889,7.33333333 C18.3979691,7.33333333 18,7.70642938 18,8.16666667 C18,8.62690396 18.3979691,9 18.8888889,9 L33.1111111,9 C33.6020309,9 34,8.62690396 34,8.16666667 L34,4.83333333 C34,4.37309604 33.6020309,4 33.1111111,4 L18.8888889,4 C18.3979691,4 18,4.37309604 18,4.83333333 C18,5.29357062 18.3979691,5.66666667 18.8888889,5.66666667 L32.2222222,5.66666667 L32.2222222,7.33333333 L18.8888889,7.33333333 Z'
            id='Path'
          />
          <path
            d='M35.1666667,12.6666667 C35.626904,12.6666667 36,12.2935706 36,11.8333333 C36,11.373096 35.626904,11 35.1666667,11 L31.8333333,11 C31.373096,11 31,11.373096 31,11.8333333 L31,15.1666667 C31,15.626904 31.373096,16 31.8333333,16 L35.1666667,16 C35.626904,16 36,15.626904 36,15.1666667 C36,14.7064294 35.626904,14.3333333 35.1666667,14.3333333 L32.6666667,14.3333333 L32.6666667,12.6666667 L35.1666667,12.6666667 Z'
            id='Path'
          />
          <path
            d='M24.8333333,11 C24.373096,11 24,11.373096 24,11.8333333 L24,15.1666667 C24,15.626904 24.373096,16 24.8333333,16 L28.1666667,16 C28.626904,16 29,15.626904 29,15.1666667 L29,11.8333333 C29,11.373096 28.626904,11 28.1666667,11 L24.8333333,11 Z M27.3333333,14.3333333 L25.6666667,14.3333333 L25.6666667,12.6666667 L27.3333333,12.6666667 L27.3333333,14.3333333 Z'
            id='Shape'
          />
          <path
            d='M17,11.8333333 L17,15.1666667 C17,15.626904 17.373096,16 17.8333333,16 L21.1666667,16 C21.626904,16 22,15.626904 22,15.1666667 L22,11.8333333 C22,11.373096 21.626904,11 21.1666667,11 L17.8333333,11 C17.373096,11 17,11.373096 17,11.8333333 Z M18.6666667,12.6666667 L20.3333333,12.6666667 L20.3333333,14.3333333 L18.6666667,14.3333333 L18.6666667,12.6666667 Z'
            id='Shape'
          />
          <path
            d='M50.35,10.6046512 L40.6333333,10.6046512 L40.6333333,2.65116279 C40.6333333,1.18696601 39.4468879,0 37.9833333,0 L15.0166667,0 C13.5531121,0 12.3666667,1.18696601 12.3666667,2.65116279 L12.3666667,10.6046512 L2.65,10.6046512 C1.18644541,10.6046512 0,11.7916172 0,13.255814 L0,36.2325581 C0,36.7206237 0.395481804,37.1162791 0.883333333,37.1162791 C1.37118486,37.1162791 1.76666667,36.7206237 1.76666667,36.2325581 L1.76666667,13.255814 C1.76666667,12.7677484 2.16214847,12.372093 2.65,12.372093 L12.3666667,12.372093 L12.3666667,30.9302326 C12.3666667,31.4182982 12.7621485,31.8139535 13.25,31.8139535 C13.7378515,31.8139535 14.1333333,31.4182982 14.1333333,30.9302326 L14.1333333,2.65116279 C14.1333333,2.1630972 14.5288151,1.76744186 15.0166667,1.76744186 L37.9833333,1.76744186 C38.4711849,1.76744186 38.8666667,2.1630972 38.8666667,2.65116279 L38.8666667,30.9302326 C38.8666667,31.4182982 39.2621485,31.8139535 39.75,31.8139535 C40.2378515,31.8139535 40.6333333,31.4182982 40.6333333,30.9302326 L40.6333333,12.372093 L50.35,12.372093 C50.8378515,12.372093 51.2333333,12.7677484 51.2333333,13.255814 L51.2333333,37.1162791 C51.2333333,37.6043447 51.6288151,38 52.1166667,38 C52.6045182,38 53,37.6043447 53,37.1162791 L53,13.255814 C53,11.7916172 51.8135546,10.6046512 50.35,10.6046512 Z'
            id='Path'
          />
          <path
            d='M48.125,15.6666667 C48.6082492,15.6666667 49,15.2935706 49,14.8333333 C49,14.373096 48.6082492,14 48.125,14 L42.875,14 C42.3917508,14 42,14.373096 42,14.8333333 L42,18.1666667 C42,18.626904 42.3917508,19 42.875,19 L48.125,19 C48.6082492,19 49,18.626904 49,18.1666667 C49,17.7064294 48.6082492,17.3333333 48.125,17.3333333 L43.75,17.3333333 L43.75,15.6666667 L48.125,15.6666667 Z'
            id='Path'
          />
          <path
            d='M42,25.1666667 C42,25.626904 42.3917508,26 42.875,26 L48.125,26 C48.6082492,26 49,25.626904 49,25.1666667 L49,21.8333333 C49,21.373096 48.6082492,21 48.125,21 L42.875,21 C42.3917508,21 42,21.373096 42,21.8333333 L42,25.1666667 Z M43.75,22.6666667 L47.25,22.6666667 L47.25,24.3333333 L43.75,24.3333333 L43.75,22.6666667 Z'
            id='Shape'
          />
          <path
            d='M42.875,30 L48.125,30 C48.6082492,30 49,29.5522847 49,29 C49,28.4477153 48.6082492,28 48.125,28 L42.875,28 C42.3917508,28 42,28.4477153 42,29 C42,29.5522847 42.3917508,30 42.875,30 Z'
            id='Path'
          />
          <path
            d='M9.125,14 L3.875,14 C3.39175084,14 3,14.373096 3,14.8333333 L3,18.1666667 C3,18.626904 3.39175084,19 3.875,19 L9.125,19 C9.60824916,19 10,18.626904 10,18.1666667 L10,14.8333333 C10,14.373096 9.60824916,14 9.125,14 Z M8.25,17.3333333 L4.75,17.3333333 L4.75,15.6666667 L8.25,15.6666667 L8.25,17.3333333 Z'
            id='Shape'
          />
          <path
            d='M9.125,22.6666667 C9.60824916,22.6666667 10,22.2935706 10,21.8333333 C10,21.373096 9.60824916,21 9.125,21 L3.875,21 C3.39175084,21 3,21.373096 3,21.8333333 L3,25.1666667 C3,25.626904 3.39175084,26 3.875,26 L9.125,26 C9.60824916,26 10,25.626904 10,25.1666667 C10,24.7064294 9.60824916,24.3333333 9.125,24.3333333 L4.75,24.3333333 L4.75,22.6666667 L9.125,22.6666667 Z'
            id='Path'
          />
          <path
            d='M9.125,28 L3.875,28 C3.39175084,28 3,28.4477153 3,29 C3,29.5522847 3.39175084,30 3.875,30 L9.125,30 C9.60824916,30 10,29.5522847 10,29 C10,28.4477153 9.60824916,28 9.125,28 Z'
            id='Path'
          />
          <path
            d='M50.2857143,42.5 L49.4285714,42.5 L49.4285714,41.625 C49.4285714,40.1752525 48.2773036,39 46.8571429,39 L45.1428571,39 C43.7226964,39 42.5714286,40.1752525 42.5714286,41.625 L42.5714286,42.5 L41.7142857,42.5 C40.7675119,42.5 40,43.2835017 40,44.25 L40,51.25 C40,52.2164983 40.7675119,53 41.7142857,53 L50.2857143,53 C51.2324881,53 52,52.2164983 52,51.25 L52,44.25 C52,43.2835017 51.2324881,42.5 50.2857143,42.5 Z M44.2857143,41.625 C44.2857143,41.1417508 44.6694702,40.75 45.1428571,40.75 L46.8571429,40.75 C47.3305298,40.75 47.7142857,41.1417508 47.7142857,41.625 L47.7142857,42.5 L44.2857143,42.5 L44.2857143,41.625 Z M50.2857143,51.25 L41.7142857,51.25 L41.7142857,47.75 L46.8571429,47.75 C47.3305298,47.75 47.7142857,47.3582492 47.7142857,46.875 C47.7142857,46.3917508 47.3305298,46 46.8571429,46 L41.7142857,46 L41.7142857,44.25 L50.2857143,44.25 L50.2857143,51.25 Z'
            id='Shape'
          />
          <path
            d='M6.5,37.5 C7.46649831,37.5 8.25,38.3058875 8.25,39.3 C8.25,39.7970563 8.64175084,40.2 9.125,40.2 C9.60824916,40.2 10,39.7970563 10,39.3 C9.99881026,37.659406 8.91937393,36.2271482 7.375,35.817 L7.375,33.9 C7.375,33.4029437 6.98324916,33 6.5,33 C6.01675084,33 5.625,33.4029437 5.625,33.9 L5.625,35.817 C3.92353185,36.2743806 2.81623509,37.9593652 3.03791092,39.7537994 C3.25958675,41.5482335 4.74139117,42.8948349 6.5,42.9 C7.46649831,42.9 8.25,43.7058875 8.25,44.7 C8.25,45.6941125 7.46649831,46.5 6.5,46.5 C5.53350169,46.5 4.75,45.6941125 4.75,44.7 C4.75,44.2029437 4.35824916,43.8 3.875,43.8 C3.39175084,43.8 3,44.2029437 3,44.7 C3.00118974,46.340594 4.08062607,47.7728518 5.625,48.183 L5.625,50.1 C5.625,50.5970563 6.01675084,51 6.5,51 C6.98324916,51 7.375,50.5970563 7.375,50.1 L7.375,48.183 C9.07646815,47.7256194 10.1837649,46.0406348 9.96208908,44.2462006 C9.74041325,42.4517665 8.25860883,41.1051651 6.5,41.1 C5.53350169,41.1 4.75,40.2941125 4.75,39.3 C4.75,38.3058875 5.53350169,37.5 6.5,37.5 Z'
            id='Path'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
));

export default BusinessIcon;
