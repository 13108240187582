// @flow
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, FormControl, Grid, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/styles';
import InfoIcon from '../../svg/InfoIcon';
import WidgetPreview from './WidgetPreview';
import initialDataSelectors from '../../InitialData/selectors';
import { useOpenAccountButtonThemes, useEmbedOpenAccountButton } from './hooks';
import FormItem from '../../FormItem';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2, 4, 4),
  },
  formControl: {
    display: 'flex',
    width: theme.spacing(25),
  },
  themeColor: {
    borderRadius: 2,
    width: theme.spacing(10),
    height: theme.spacing(2.5),
  },
  icon: {
    width: 24,
    height: 24,
  },
  iconBox: {
    display: 'flex',
    paddingTop: 40,
  },
  iconText: {
    paddingLeft: theme.spacing(1),
  },
  themeText: {
    paddingLeft: theme.spacing(1),
  },
}));

type Props = {
  term: number,
  termPeriod: string,
};

const OpenAccountWidget = (props: Props) => {
  const { term, termPeriod } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(' ');
  const gcAccountId = useSelector(initialDataSelectors.selectSelectedGcAccountId);
  const themes = useOpenAccountButtonThemes();
  const embedWidget = useEmbedOpenAccountButton(selectedTheme, term, termPeriod, gcAccountId);

  const handleThemeChange = (event) => {
    setSelectedTheme(event.target.value);
  };

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <Grid container className={classes.container}>
            <Grid item xs={3}>
              <FormItem label='Theme'>
                <FormControl className={classes.formControl} variant='outlined'>
                  <Select id='theme' name='theme' data-testid='uia-theme' onChange={handleThemeChange} value={selectedTheme} disableUnderline>
                    <MenuItem value=' '>
                      <Typography variant='body1'>Select a theme</Typography>
                    </MenuItem>
                    {themes.map((x) => (
                      <MenuItem key={x.name} value={x.name}>
                        <Box display='flex'>
                          {x.hexColor ? <Box className={classes.themeColor} style={{ backgroundColor: x.hexColor }} /> : null}
                          <Typography variant='body1' className={x.name ? classes.themeText : ''}>
                            {x.name}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormItem>
            </Grid>
            <Grid item xs={9} className={classes.iconBox}>
              <InfoIcon className={classes.icon} color={theme.palette.primary.main} viewBox='0 0 26 26' />
              <Typography variant='body1' component='label' className={classes.iconText}>
                Add an &apos;Open Account&apos; button to your website to help customers easily register to your Trade Account.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <WidgetPreview embeddableCode={embedWidget.embeddableCode} isLoading={embedWidget.isLoading} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OpenAccountWidget;
