import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { bindActionCreators } from 'redux';
import BankConnectCards from './Component';
import actions from './actions';
import selectors from './selectors';

const mapStateToProps = createSelector(selectors.selectPopularBanks, (popularBanks) => ({
  popularBanks,
}));

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BankConnectCards);
