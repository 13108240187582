import { useEffect, useState } from 'react';

import { shiftPaymentsApi } from 'src/api';

export type AnalyticsAccountSummary = {
  isError: boolean;
  isRequireConnection: boolean;
  isTransactionsOlderThanAllowed: boolean;
  probabilityOfDefault: number;
};

const getAnalyticsAccountSummary = async (gcAccountId: string) => {
  let result = {
    isError: false,
    isRequireConnection: false,
    isTransactionsOlderThanAllowed: false,
    probabilityOfDefault: 0,
  };
  try {
    const response = await shiftPaymentsApi.getAnalyticsAccountSummary(gcAccountId);

    if (response && response.data) {
      return {
        ...result,
        isRequireConnection: response.data.isReconnectRequired,
        isTransactionsOlderThanAllowed: response.data.isTransactionsOlderThanAllowed,
        probabilityOfDefault: response.data.probabilityOfDefault,
      };
    }
    return { ...result, isError: true };
  } catch (error) {
    return { ...result, isError: true };
  }
};

export const useGetAnalyticsAccountSummary = (gcAccountId: string) => {
  const [analyticsAccountSummary, setAnalyticsAccountSummary] = useState<AnalyticsAccountSummary>({
    isError: false,
    isRequireConnection: false,
    isTransactionsOlderThanAllowed: false,
    probabilityOfDefault: 0,
  });

  useEffect(() => {
    let mounted = true;
    const getBankConnectReinforcementStatusAsync = async (gcAccountId: string) => {
      const response = await getAnalyticsAccountSummary(gcAccountId);
      if (mounted) {
        if (response.isError) {
          setAnalyticsAccountSummary({ isRequireConnection: false, isTransactionsOlderThanAllowed: false, probabilityOfDefault: 0, isError: true });
        }
        setAnalyticsAccountSummary({
          isRequireConnection: response.isRequireConnection,
          isTransactionsOlderThanAllowed: response.isTransactionsOlderThanAllowed,
          probabilityOfDefault: response.probabilityOfDefault ?? 0,
          isError: false,
        });
      }
    };
    if (gcAccountId) {
      getBankConnectReinforcementStatusAsync(gcAccountId);
    }
    return () => {
      mounted = false;
    };
  }, [gcAccountId]);

  return analyticsAccountSummary;
};
