// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';

import { type JwtValues } from '../../api/claims';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import { SpStatus, type Account, type ReferralAccount } from '../../types';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';
import applicationState from '../../lib/applicationState';
import selectors from './selectors';

export const {
  getInitialDataFulfilled,
  getInitialDataPending,
  getInitialDataRejected,
  setJwtValues,
  setIsAllKycCompleted,
  setAccounts,
  setReferralAccount,
} = createActions(
  actionTypes.GET_INITIAL_DATA_FULFILLED,
  actionTypes.GET_INITIAL_DATA_PENDING,
  actionTypes.GET_INITIAL_DATA_REJECTED,
  actionTypes.SET_JWT_VALUES,
  actionTypes.SET_IS_ALL_KYC_COMPLETED,
  actionTypes.SET_ACCOUNTS,
  actionTypes.SET_REFERRAL_ACCOUNT,
  getActionOptions(ACTION_PREFIX)
);

const sortAccounts = (a, b) => {
  // account with isDirect=true has to be the first in the list since it's the first account linked to the contact.
  // Sort the rest by name in alphabetical order.
  if (a.isDirect) {
    return -1;
  }

  if (b.isDirect) {
    return 1;
  }

  const aName = a.name.toUpperCase();
  const bName = b.name.toUpperCase();
  if (aName < bName) {
    return -1;
  }

  if (aName > bName) {
    return 1;
  }

  return 0;
};

const getAccountsWithGoodSpStatus = (accounts) => {
  return accounts.filter((x) => {
    if (!x.spStatus) {
      return false;
    }

    const spStatus = x.spStatus.toLowerCase();
    return spStatus === SpStatus.Enable || spStatus === SpStatus.Delinquent || spStatus === SpStatus.NotSigned;
  });
};

const getMergedAccounts = (jwtValues: JwtValues, stateAccounts: ?(Account[])): Account[] => {
  const jwtAccounts = JSON.parse(jwtValues.accounts);
  const filteredAccounts = jwtAccounts.map((jwtAccount) => {
    let stateAccount;
    if (stateAccounts) {
      stateAccount = stateAccounts.find((y) => jwtAccount.gcAccountId === y.gcAccountId);
      if (stateAccount) {
        // TODO: This overwriting will be removed once the Salesforce updated with this logic.
        // overwriting what we received from ID server with what we decided in ShiftTrade.
        jwtAccount.isSupplier = stateAccount.isSupplier;
      }
    }
    return { ...stateAccount, ...jwtAccount };
  });
  const rawAccounts = getAccountsWithGoodSpStatus(filteredAccounts);
  const sortedAccounts = rawAccounts.sort(sortAccounts);
  return sortedAccounts;
};

const getInitialData = (jwtValues: JwtValues, refreshCache: boolean = false) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(getInitialDataPending());
    return shiftPaymentsApi
      .getContactDetail(jwtValues.externalReferenceId, refreshCache)
      .then((response) => {
        const sortedAccounts = getMergedAccounts(jwtValues, response.data.relatedAccounts);
        return dispatch(getInitialDataFulfilled({ accounts: sortedAccounts, contact: response.data.contact }));
      })
      .catch((error) => {
        return dispatch(getInitialDataRejected(error));
      });
  };
};

export const setIsAllKycCompletedWithSessionStorage = (isAllKycCompleted: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    applicationState.set({ isAllKycCompleted });

    dispatch(setIsAllKycCompleted(isAllKycCompleted));
  };
};

const updateValues = (jwtValues: any) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    dispatch(setJwtValues(jwtValues));
    const stateAccounts = selectors.selectAccounts(getState());
    const sortedAccounts = getMergedAccounts(jwtValues, stateAccounts);
    dispatch(setAccounts(sortedAccounts));
  };
};

export const setReferralAccountFromQueryString = (account: ?ReferralAccount) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setReferralAccount(account));
  };
};

export default {
  getInitialData,
  setJwtValues,
  setIsAllKycCompletedWithSessionStorage,
  updateValues,
  setReferralAccountFromQueryString,
};
