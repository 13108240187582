export const ACTION_PREFIX = 'SUPPLIER_INVOICE_REVIEW_AUTHORIZATION';
export const PARTITION = 'supplierInvoiceReviewAuthorization';

export const actionTypes = {
  CONFIRM_INVOICE_PENDING: 'CONFIRM_INVOICE_PENDING',
  CONFIRM_INVOICE_FULFILLED: 'CONFIRM_INVOICE_FULFILLED',
  CONFIRM_INVOICE_REJECTED: 'CONFIRM_INVOICE_REJECTED',
  SET_SUPPLIER_AUTHORIZATION_PENDING: 'SET_SUPPLIER_AUTHORIZATION_PENDING',
  SET_SUPPLIER_AUTHORIZATION_FULFILLED: 'SET_SUPPLIER_AUTHORIZATION_FULFILLED',
  SET_SUPPLIER_AUTHORIZATION_REJECTED: 'SET_SUPPLIER_AUTHORIZATION_REJECTED',
};
