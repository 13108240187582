// @flow
/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';
import React from 'react';

type Props = {
  theme: any,
};

const InfoIcon = withTheme((props: Props) => (
  <SvgIcon style={{ alignSelf: 'baseline' }} {...(props: any)}>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-93.000000, -560.000000)'>
        <g transform='translate(74.000000, 540.000000)'>
          <g transform='translate(19.000000, 20.000000)'>
            <g>
              <g>
                <circle fill={props.color || props.theme.palette.primary.lillyWhite} cx='11' cy='11' r='10.5' />
              </g>
            </g>
            <g id='noun_Info_1683881' transform='translate(10.000000, 5.000000)' fill={props.theme.palette.primary.main}>
              <path
                d='M1,10 C1.55230224,10 2,10.4477 2,11 C2,11.5523 1.55230224,12 1,12 C0.447707761,12 0,11.5523 0,11 C0,10.4477 0.447707761,10 1,10 Z M1,0 C1.55230224,0 2,0.447721492 2,1 L2,8.00001667 C2,8.55229851 1.55230224,9 1,9 C0.447707761,9 0,8.55229851 0,8.00001667 L0,1 C0,0.447721492 0.447707761,0 1,0 Z'
                id='Combined-Shape'
                transform='translate(1.000000, 6.000000) scale(1, -1) translate(-1.000000, -6.000000) '
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
));

export default InfoIcon;
