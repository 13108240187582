// @flow
import React from 'react';
import moment from 'moment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import { STANDARD_DATE_FORMAT_WITH_DAY_OF_WEEK } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  calendarIcon: {
    fontSize: '13px',
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  date: moment,
};

const DateLabel = (props: Props) => {
  const classes = useStyles();
  const { date } = props;

  return (
    <Typography variant='subtitle2'>
      <CalendarTodayIcon className={classes.calendarIcon} />
      {date ? <span data-testid='uia-dateLabel'>{date.format(STANDARD_DATE_FORMAT_WITH_DAY_OF_WEEK)}</span> : null}
    </Typography>
  );
};

export default DateLabel;
