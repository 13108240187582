// @flow
export const ACTION_PREFIX = 'LOAN_AGREEMENT';
export const PARTITION = 'loanAgreement';

export const actionTypes = {
  SIGN_LOAN_AGREEMENT_PENDING: 'SIGN_LOAN_AGREEMENT_PENDING',
  SIGN_LOAN_AGREEMENT_FULFILLED: 'SIGN_LOAN_AGREEMENT_FULFILLED',
  SIGN_LOAN_AGREEMENT_REJECTED: 'SIGN_LOAN_AGREEMENT_REJECTED',
  AUTO_APPROVE_REQUEST_PENDING: 'AUTO_APPROVE_REQUEST_PENDING',
  AUTO_APPROVE_REQUEST_FULFILLED: 'AUTO_APPROVE_REQUEST_FULFILLED',
  AUTO_APPROVE_REQUEST_REJECTED: 'AUTO_APPROVE_REQUEST_REJECTED',
};
