/* eslint-disable react/display-name */
import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { isWidthDown } from '@material-ui/core/withWidth';
import {useMediaQuery} from "@material-ui/core";

export const AdapterNavLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);
export const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

export const isMobileResolution = (width) => {
  return isWidthDown('xs', width);
};

export const isLargeMobileResolution = (width) => {
  return isWidthDown('sm', width);
};

export const useIsMobileResolution = () => {
    return useMediaQuery((t) => t.breakpoints.down('xs'));
};

export const useIsLargeMobileResolution = () => {
    return useMediaQuery((t) => t.breakpoints.down('sm'));
};