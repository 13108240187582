// @flow
import * as React from 'react';

import startupConfig from '../../lib/startupConfig';
import type { StartupConfigValues } from '../../lib/startupConfig';
import { initSession } from '../../lib/threatmetrix';

type Props = {
  configuration: StartupConfigValues,
  isConfigurationSet: boolean,
  getStartupConfig: () => any,
};

const StartupConfig = ({ configuration, isConfigurationSet, getStartupConfig }: Props) => {
  React.useEffect(() => {
    if (isConfigurationSet) {
      startupConfig.set(configuration);

      initSession(configuration.threatMetrix);
    } else {
      getStartupConfig();
    }
  }, [isConfigurationSet, configuration, getStartupConfig]);

  return null;
};

export default StartupConfig;
