// @flow
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Paper,
  Radio,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import { Button } from '@shiftfinancial/design-system';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import PaymentDateSelector from './../Review/PaymentDateSelector';
import { type PaymentMethod, PaymentMethodValues } from '../../../api/shiftPaymentsApi';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';
import BasicPageLayout from '../../../components/BasicPageLayout';
import TabLabel from '../../../components/TabLabel';
import TabPanel from '../../../components/TabPanel';
import { defaultHandleError } from '../../../lib/apiHelpers';
import { addDays } from '../../../lib/dateUtils';
import { isMobileResolution } from '../../../lib/materialUiUtils';
import { type ExtendInvoiceRequest } from '../../../types';
import CurrentExtensionSchedule from '../ExtendInvoice/CurrentExtensionSchedule';
import NewExtensionSchedule from '../ExtendInvoice/NewExtensionSchedule';
import InvoiceDetail, { type SetSupplierAuthorization } from '../Review/InvoiceDetail';
import type { ExtensionTermsAndFees, Invoice, WeeklyExtensionFee } from '../Review/types';
import { calculateSupplierTermsInDays } from '../Review/calculateTransactionFee';

const useStyle = makeStyles((theme) => ({
  secondaryHeading: {
    marginTop: theme.spacing(1),
    color: theme.palette.grey.text,
    fontWeight: 400,
    lineHeight: 1.4,
  },
  payMode: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  expansionSummary: {
    marginBottom: theme.spacing(4),
    '&.MuiExpansionPanelSummary-root': {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0),
      },
    },
    '&.Mui-expanded': {
      margin: theme.spacing(0),
    },
    '& .MuiExpansionPanelSummary-content': {
      margin: 0,
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0),
      '& .MuiExpansionPanelSummary-content': {
        margin: 0,
      },
    },
  },
  expansionDetails: {
    margin: 0,
    padding: theme.spacing(0, 1, 1, 0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 0, 0, 0),
    },
  },
  selectPanelSelected: theme.palette.selectPanel.selected,
  selectPanelDefault: theme.palette.selectPanel.default,
  buttonContainer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap-reverse',
    },
  },
  radio: {
    '&.PrivateSwitchBase-root': {
      padding: theme.spacing(0),
    },
    '&.MuiIconButton-root': {
      padding: theme.spacing(0),
    },
    textAlign: 'center',
  },
  paper: {
    boxShadow: `0px 10px 30px rgba(0, 0, 0, 0.11)`,
  },
  primaryButtonContainer: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  buttons: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
  },
  captionWrapper: {
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(4),
  },
  caption: {
    paddingLeft: theme.spacing(1),
  },
  extendOptionWrapper: {
    padding: theme.spacing(1, 4, 4, 4),
  },
  extensionContainer: {
    margin: theme.spacing(2, 2, 2, 4),
  },
  extentionSchedule: {
    width: `calc(50% - ${theme.spacing(1)}px)`,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  scheduleGap: {
    marginRight: theme.spacing(2),
  },
  currentSchedule: {
    border: `1px solid ${theme.palette.secondary.solitude}`,
    borderRadius: theme.spacing(0.5),
  },
  tab: {
    width: '50%',
  },
  payInFullNotAvailable: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
}));

type Props = {
  width: string,
  gcAccountId: string,
  invoice: Invoice,
  extensionTermsAndFees: ExtensionTermsAndFees,
  paymentDate?: moment,
  setSupplierAuthorization: SetSupplierAuthorization,
  getInvoice: (contractId: string, gcAccountId: string) => Promise<any>,
  getInvoiceAttachment: (buyerGcAccountId: string, filePath: string) => Promise<any>,
  getExtensionTermsAndFees: (invoiceId: string, supplierGcAccountId: string, buyerGcAccountId: string) => Promise<any>,
  enqueueSnackbar: (message: string, options?: Object) => void,
};

const ExtendInvoice = (props: Props) => {
  const {
    width,
    gcAccountId,
    invoice,
    extensionTermsAndFees,
    paymentDate,
    setSupplierAuthorization,
    getInvoice,
    getInvoiceAttachment,
    getExtensionTermsAndFees,
    enqueueSnackbar,
  } = props;

  const classes = useStyle();
  const { id } = useParams();
  const history = useHistory();
  const isMobile = isMobileResolution(width);

  const isBuyerInitiated = !id;
  const isSupplierInitiated = !isBuyerInitiated;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [payInFullTransactionFee, setPayInFullTransactionFee] = useState<number>(0);
  const [canConfirm, setCanConfirm] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<?PaymentMethod>(null);
  const [supplierDueDate, setSupplierDueDate] = useState<moment | null>(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const selectedExtensionRef = useRef();

  // fetch invoice
  useEffect(() => {
    if (id && gcAccountId) {
      getInvoice(id, gcAccountId).then((response) => {
        if (response.error) {
          defaultHandleError({ enqueueSnackbar });
        }
      });
    }
  }, [id, gcAccountId]);

  // fetch terms and fees for invoice extensions
  useEffect(() => {
    if (invoice && invoice.supplierGcAccountId && invoice.amount) {
      getExtensionTermsAndFees(invoice.id, invoice.supplierGcAccountId, gcAccountId);
    }
  }, [gcAccountId, invoice]);

  // sets transaction fee by the term for daily
  useEffect(() => {
    const termsAndFee =
      extensionTermsAndFees &&
      extensionTermsAndFees.oneTime.find((x) => {
        const dateOfRepayment = moment(x.repaymentDate);
        const p = paymentDate && paymentDate.isSame(dateOfRepayment);
        return p;
      });

    if (termsAndFee) {
      setPayInFullTransactionFee(termsAndFee.fee);
    }
  }, [paymentDate, extensionTermsAndFees]);

  // decides confirm button availability to the user
  useEffect(() => {
    const isPayInFullAllowed = paymentMethod === PaymentMethodValues.Full && paymentDate !== supplierDueDate && payInFullTransactionFee > 0;
    const isPayInWeeklyAllowed = paymentMethod === PaymentMethodValues.Weekly && selectedExtensionRef?.current?.newExtensionFee > 0;
    if (isPayInFullAllowed || isPayInWeeklyAllowed) {
      setCanConfirm(true);
    } else {
      setCanConfirm(false);
    }
  }, [paymentMethod, activeTabIndex, paymentDate, supplierDueDate]);

  // sets supplier due date
  useEffect(() => {
    setSupplierDueDate(isSupplierInitiated ? moment(invoice.nextInstallmentDate) : null);
  }, [isSupplierInitiated, invoice]);

  const handleCancel = () => {
    history.push('/buyer/payments');
  };

  const handleTabChange = (e, tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  const handlePayInFullPanelChange = (event, isExpanded) => {
    setPaymentMethod(isExpanded ? PaymentMethodValues.Full : null);
  };
  const handleWeeklyPanelChange = (event, isExpanded) => {
    setPaymentMethod(isExpanded ? PaymentMethodValues.Weekly : null);
  };

  const enableConfirm = (extension: WeeklyExtensionFee) => {
    setCanConfirm(true);
    if (extension) {
      selectedExtensionRef.current = extension;
    }
  };

  const handleConfirm = () => {
    setIsSubmitting(true);

    let newRepaymentDate;
    if (paymentMethod === PaymentMethodValues.Full) {
      newRepaymentDate = paymentDate && paymentDate.local().toDate();
    } else {
      if (selectedExtensionRef.current && selectedExtensionRef.current.newRepaymentStartDate) {
        newRepaymentDate = selectedExtensionRef.current.newRepaymentStartDate;
      }
    }
    const data = ({
      invoiceId: invoice.id,
      gcAccountId: gcAccountId,
      isWeekly: paymentMethod !== PaymentMethodValues.Full,
      term: selectedExtensionRef.current ? selectedExtensionRef.current.week : 0,
      transactionFee:
        paymentMethod === PaymentMethodValues.Full
          ? payInFullTransactionFee
          : selectedExtensionRef.current
          ? selectedExtensionRef.current.newExtensionFee
          : 0,
      repaymentDate: newRepaymentDate,
    }: ExtendInvoiceRequest);
    shiftPaymentsApi
      .extendInvoice(data)
      .then(() => {
        history.push(`/buyer/payments/${invoice.id}/extended`);
      })
      .catch(() => {
        defaultHandleError({ enqueueSnackbar });
      });
    setIsSubmitting(false);
  };

  const renderExtensionScheduleView = () => (isMobile ? renderMobileExtensionSchedules() : renderExtensionSchedules());

  const renderCurrentSchedule = () => <CurrentExtensionSchedule schedule={extensionTermsAndFees} title={isMobile ? '' : 'Current schedule'} />;

  const renderNewSchedule = () => (
    <NewExtensionSchedule
      title={isMobile ? '' : 'New schedule'}
      feeAndTerms={extensionTermsAndFees.weeklyExtensionFees}
      existingFee={extensionTermsAndFees.existingFee}
      enableConfirm={enableConfirm}
      selectedExtension={selectedExtensionRef.current}
    />
  );

  const renderExtensionSchedules = () => {
    return (
      <ExpansionPanelDetails className={classes.expansionDetails}>
        <Grid item container className={classes.extensionContainer}>
          <Grid item className={`${classes.extentionSchedule} ${classes.scheduleGap}`}>
            <Paper className={classes.paper}>{renderNewSchedule()}</Paper>
          </Grid>
          <Grid item className={`${classes.extentionSchedule} ${classes.currentSchedule}`}>
            {renderCurrentSchedule()}
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    );
  };

  const renderMobileExtensionSchedules = () => {
    return (
      <>
        <Tabs value={activeTabIndex} onChange={handleTabChange} indicatorColor='primary'>
          <Tab label={<TabLabel label='New schedule' showBadge={false} data-testid='uia-extension-new-tab' />} className={classes.tab} />
          <Tab label={<TabLabel label='Current schedule' showBadge={false} data-testid='uia-extension-current-tab' />} className={classes.tab} />
        </Tabs>
        <TabPanel tabIndex={0} activeTabIndex={activeTabIndex}>
          <Grid item className={`${classes.extentionSchedule} ${classes.scheduleGap}`}>
            <Paper>{renderNewSchedule()}</Paper>
          </Grid>
        </TabPanel>
        <TabPanel tabIndex={1} activeTabIndex={activeTabIndex}>
          <Grid item className={`${classes.extentionSchedule}`}>
            <Paper>{renderCurrentSchedule()}</Paper>
          </Grid>
        </TabPanel>
      </>
    );
  };

  const renderCancel = () => {
    return (
      <Button appearance='text' className={classes.buttons} onClick={() => handleCancel()} data-testid='uia-cancelButton' disabled={isSubmitting}>
        Cancel
      </Button>
    );
  };

  const renderExtend = () => {
    return (
      <Button
        appearance='primary'
        className={classes.buttons}
        onClick={() => handleConfirm()}
        data-testid='uia-confirmInvoiceExtend'
        disabled={!canConfirm || isSubmitting}
      >
        Extend
      </Button>
    );
  };

  const renderButtons = () => {
    return (
      <>
        <Grid item xs={12} sm={6}>
          {renderExtend()}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.primaryButtonContainer}>
          {id ? renderCancel() : null}
        </Grid>
      </>
    );
  };

  const renderButtonsMobileView = () => {
    return (
      <>
        <Grid item xs={12} sm={6}>
          {id ? renderCancel() : null}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.primaryButtonContainer}>
          {renderExtend()}
        </Grid>
      </>
    );
  };

  const createdOrSupplierDate = supplierDueDate ?? moment(invoice.created).local();
  const maxDate = addDays(createdOrSupplierDate, extensionTermsAndFees.availableDays);
  const isPayInFullNotAvailable = !maxDate || !maxDate.isAfter(createdOrSupplierDate);

  return (
    <BasicPageLayout title='Extend invoice'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} className={classes.left}>
          {invoice ? (
            <InvoiceDetail
              buyerGcAccountId={gcAccountId}
              // $FlowFixMe
              {...invoice}
              getInvoiceAttachment={getInvoiceAttachment}
              setSupplierAuthorization={setSupplierAuthorization}
              authorizationStatus={invoice.supplierAuthorizationStatus}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} sm={9}>
          <Paper className={classes.payMode}>
            {extensionTermsAndFees && extensionTermsAndFees.canPayInFull ? (
              <ExpansionPanel
                expanded={paymentMethod === PaymentMethodValues.Full}
                className={paymentMethod === PaymentMethodValues.Full ? classes.selectPanelSelected : classes.selectPanelDefault}
                onChange={handlePayInFullPanelChange}
                data-testid='uia-payByFull-panel'
              >
                <ExpansionPanelSummary disabled={isPayInFullNotAvailable} className={classes.expansionSummary} data-testid='uia-payInFullPanel'>
                  <Grid container className={classes.captionWrapper}>
                    <Grid container>
                      <Grid item>
                        <Radio
                          className={classes.radio}
                          checked={paymentMethod === PaymentMethodValues.Full}
                          display='inline'
                          data-testid='uia-payByFull'
                        />
                      </Grid>
                      <Grid item className={classes.caption}>
                        <Typography variant='subtitle2'>Repay in full</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography className={classes.secondaryHeading} variant='h6'>
                          Extend and pay in full on your elected due date
                        </Typography>
                        {isPayInFullNotAvailable ? (
                          <Typography className={classes.payInFullNotAvailable} data-testid='uia-payInFullNotAvailableMessage' variant='h6'>
                            Pay in instalments to extend. Pay in full extension has been fully consumed.
                          </Typography>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.expansionDetails}>
                  <Grid container className={classes.extendOptionWrapper}>
                    <Grid item xs={12}>
                      <PaymentDateSelector
                        invoiceDate={invoice.created}
                        supplierDueDate={supplierDueDate}
                        isBuyerInitiated={isBuyerInitiated}
                        availableDays={extensionTermsAndFees.availableDays}
                        transactionFee={payInFullTransactionFee}
                        isExtension={true}
                        supplierTerms={calculateSupplierTermsInDays(invoice.created, invoice.supplierTerm, invoice.supplierTermPeriod)}
                        showDontChangeButton={false}
                      />
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ) : null}
            <ExpansionPanel
              expanded={paymentMethod === PaymentMethodValues.Weekly}
              className={paymentMethod === PaymentMethodValues.Weekly ? classes.selectPanelSelected : classes.selectPanelDefault}
              onChange={handleWeeklyPanelChange}
            >
              <ExpansionPanelSummary className={classes.expansionSummary}>
                <Grid container className={classes.captionWrapper}>
                  <Grid container>
                    <Grid item>
                      <Radio
                        className={classes.radio}
                        checked={paymentMethod === PaymentMethodValues.Weekly}
                        display='inline'
                        data-testid='uia-payByInstallments'
                      />
                    </Grid>
                    <Grid item className={classes.caption}>
                      <Typography variant='subtitle2'>Repay in instalments</Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography className={classes.secondaryHeading} variant='h6'>
                        Extend and pay in weekly instalments
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              {!!extensionTermsAndFees && renderExtensionScheduleView()}
            </ExpansionPanel>
            <Grid container className={classes.buttonContainer} spacing={1}>
              {isMobile ? renderButtonsMobileView() : renderButtons()}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </BasicPageLayout>
  );
};

export default withSnackbar(withWidth()(ExtendInvoice));
