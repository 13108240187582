// @flow
import { useEffect } from 'react';

import { useAuth } from '../api/authhooks';

const Logout = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout().then(() => {
      window.location.pathname = '/login';
    });
  }, []);

  return null;
};

export default Logout;
