import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from '@reduxjs/toolkit';
import Component from './Component';
import actions from './actions';
import initialDataSelectors from '../../../components/InitialData/selectors';

const mapStateToProps = createSelector(
  initialDataSelectors.selectJwtValues,
  initialDataSelectors.selectSelectedGcAccountId,
  (jwtValues, gcAccountId) => ({
    jwtValues,
    gcAccountId,
  })
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Component);
