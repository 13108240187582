// @flow
import baseApi from './baseApi';
import startupConfig from '../lib/startupConfig';

const get = (partUrl: string): Promise<any> => {
  const config = startupConfig.get();
  const baseUrl = config ? config.identityServerUrl : '';
  return baseApi.get({ url: `${baseUrl}/api${partUrl}` });
};

const post = (partUrl: string, data: any, apiVersion: string = ''): Promise<any> => {
  const config = startupConfig.get();
  const baseUrl = config ? config.identityServerUrl : '';
  return baseApi.post({ url: `${baseUrl}/api${partUrl}`, data, apiVersion });
};

const getPinByMobileNumber = (mobileNumber: string) => get(`/auth/phone?mobileNumber=${mobileNumber}&portal=Shift Payments`);

const getTokenByPin = (data: { pin: string, phoneNumber: string }) => post('/auth/ssotoken', data, '2.0');

const getTokenByTokenAlias = (tokenAlias: string) => get(`/auth/token-alias/${tokenAlias}/token`);

const getUserClaimsIntrospect = (token: string) => post('/auth/introspect', { token }, '2.0');

const revokeAccessToken = (token: string) => post('/auth/revocation', { token }, '2.0');

const refreshToken = (refreshToken: string, version: string) => post('/auth/ssorefreshtoken', { refreshToken }, version);

const createTokenAlias = ({ accessToken, refreshToken, tokenType, expiresIn }: any) => {
  return post(`/auth/token-alias`, { accessToken, refreshToken, tokenType, expiresIn });
};

export default {
  getPinByMobileNumber,
  getTokenByPin,
  getUserClaimsIntrospect,
  revokeAccessToken,
  getTokenByTokenAlias,
  refreshToken,
  createTokenAlias,
};
