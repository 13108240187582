// @flow
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import AlertError from '../../components/AlertError';
import AlertSuccess from '../../components/AlertSuccess';
import Footer from '../../components/Footer';
import { LoginForm } from '../../components/LoginForm';
import { PinLoginForm } from '../../components/PinLoginForm';
import ShiftTradeLogo from '../../components/svg/ShiftTradeLogo';
import StixVaultIcon from '../../components/svg/StixVaultIcon';
import { LINKS } from '../../constants';
import { formatMobileNumber } from '../../lib/formatter';
import type { StartupConfigValues } from '../../lib/startupConfig';

const useStyles = makeStyles((theme) => ({
  gridForm: {
    [theme.breakpoints.up('sm')]: {
      flex: '0.9 0 auto',
      backgroundColor: theme.palette.common.lightBlue,
    },
    paddingTop: '20%',
    backgroundColor: theme.palette.common.mediumGreen,
  },
  container: {
    backgroundColor: theme.palette.common.mediumGreen,
    height: '100vh',
  },
  content: {
    [theme.breakpoints.up('xs')]: {
      backgroundColor: theme.palette.common.mediumGreen,
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.common.lightBlue,
    },
  },
  footer: {
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
    },
  },
  gridGraphic: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    backgroundColor: theme.palette.common.mediumGreen,
  },
  alertContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 2, 0),
    },
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '420px',
    padding: theme.spacing(1, 2, 0),
    margin: '0 auto',
  },
  abn: {
    marginTop: theme.spacing(2),
    color: theme.palette.grey[600],
    '& a': {
      color: theme.palette.grey[600],
      textDecoration: 'none',
    },
    '& a:hover': {
      color: theme.palette.grey[600],
      textDecoration: 'underline',
    },
  },
  mainHeading: {
    [theme.breakpoints.down('xs')]: {
      color: theme.palette.common.white,
      margin: `${theme.spacing(1)}px auto`,
      textAlign: 'center',
      padding: theme.spacing(3, 2, 2, 2),
    },
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.common.black,
      maxWidth: 420,
      margin: 'auto',
      textAlign: 'center',
      padding: theme.spacing(8, 2, 2, 2),
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
      padding: theme.spacing(8, 2, 2, 2),
    },
  },
  stixIcon: {
    [theme.breakpoints.down('xs')]: {
      width: '70vw',
      height: '5.5em',
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      width: '30vw',
      height: '60vh',
    },
  },
}));

type Props = {
  mobileNumber: string,
  step: number,
  codeExpiry: number,
  configuration: StartupConfigValues,
  getPinByMobileNumber: (mobileNumber: string) => Promise<any>,
  setInputMobileNumberValue: (mobileNumber: string) => void,
  getTokenByPin: (values: { pin: string }, mobileNumber: string) => Promise<any>,
  setLoginStep: (step: number) => void,
  inputMobileNumberValue: string,
};

const Login = (props: Props) => {
  const { mobileNumber, step, codeExpiry } = props;
  const classes = useStyles();
  const [stateError, setErrorState] = useState(null);

  const form =
    step === 0 ? (
      <LoginForm
        configuration={props.configuration}
        getPinByMobileNumber={props.getPinByMobileNumber}
        setInputMobileNumberValue={props.setInputMobileNumberValue}
        setError={setErrorState}
      />
    ) : (
      <PinLoginForm
        getTokenByPin={props.getTokenByPin}
        getPinByMobileNumber={props.getPinByMobileNumber}
        setLoginStep={props.setLoginStep}
        mobileNumber={props.mobileNumber}
        inputMobileNumberValue={props.inputMobileNumberValue}
        setError={setErrorState}
      />
    );

  return (
    <>
      <Helmet>
        <title>Shift Trade - Log In</title>
      </Helmet>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={6} className={classes.content}>
          <div className={classes.gridForm}>
            <Box display='flex' justifyContent='center'>
              <Hidden smUp>
                <Link href={LINKS.SHIFT_HOME_PAGE} target='_blank'>
                  <ShiftTradeLogo width='113px' height='41px' dark />
                </Link>
              </Hidden>
              <Hidden xsDown>
                <Link href={LINKS.SHIFT_HOME_PAGE} target='_blank'>
                  <ShiftTradeLogo />
                </Link>
              </Hidden>
            </Box>
            <Typography className={classes.mainHeading} variant='h1'>
              Log in to Shift Trade
            </Typography>
            {!stateError && mobileNumber && step === 1 ? (
              <Grid className={classes.alertContainer} container>
                <AlertSuccess
                  message={`A code has been sent to ${formatMobileNumber(mobileNumber, true)} via SMS.`}
                  subMessage={`Please enter it below. This code will expire in ${codeExpiry} minutes`}
                />
              </Grid>
            ) : null}
            {stateError ? (
              <Grid className={classes.alertContainer} container>
                <AlertError message={stateError} data-testid='uia-error' />
              </Grid>
            ) : null}
            {form}
          </div>

          <Hidden xsDown>
            <Footer />
          </Hidden>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridGraphic}>
          <StixVaultIcon className={classes.stixIcon} dark={false} />
          <Hidden smUp>
            <Footer />
          </Hidden>
        </Grid>
      </Grid>
    </>
  );
};

Login.defaultProps = {
  mobileNumber: null,
  step: 0,
  codeExpiry: 0,
  inputMobileNumberValue: null,
};

export default Login;
