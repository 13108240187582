import { createActions } from 'redux-actions';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import { shiftPaymentsApi } from '../../api';
import { updateBankAccountSetupCompleted } from '../AccountSetupCheck/actions';
import { setBankLoginAccountResult } from '../MultiFactorAuthenticationForm/actions';

export const {
  loginBankLinkUserPending,
  loginBankLinkUserFulfilled,
  loginBankLinkUserRejected,
  getBankLoginFieldsPending,
  getBankLoginFieldsFulfilled,
  getBankLoginFieldsRejected,
  resetBankLogin,
} = createActions(
  actionTypes.LOGIN_BANK_LINK_USER_PENDING,
  actionTypes.LOGIN_BANK_LINK_USER_FULFILLED,
  actionTypes.LOGIN_BANK_LINK_USER_REJECTED,
  actionTypes.GET_BANK_LOGIN_FIELDS_PENDING,
  actionTypes.GET_BANK_LOGIN_FIELDS_FULFILLED,
  actionTypes.GET_BANK_LOGIN_FIELDS_REJECTED,
  actionTypes.RESET_BANK_LOGIN,
  getActionOptions(ACTION_PREFIX)
);

const bankLogin = (values) => {
  return shiftPaymentsApi.bankLogin(values);
};

const getLoginFields = (bankId) => (dispatch) => {
  dispatch(getBankLoginFieldsPending());

  return shiftPaymentsApi
    .getBankLoginFields(bankId)
    .then((response) => {
      dispatch(getBankLoginFieldsFulfilled(response));
    })
    .catch((error) => {
      dispatch(getBankLoginFieldsRejected(error));
    });
};
export default {
  bankLogin,
  getLoginFields,
  loginBankLinkUserPending,
  loginBankLinkUserRejected,
  loginBankLinkUserFulfilled,
  updateBankAccountSetupCompleted,
  setBankLoginAccountResult,
  resetBankLogin,
};
