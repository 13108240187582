// @flow
import React, { useEffect, useState } from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withFormik, Form, Field } from 'formik';
import type { FormikBag } from 'formik';
import { TextField } from 'formik-material-ui';
import Checkbox from '@material-ui/core/Checkbox';
import { withRouter, Redirect } from 'react-router';
import { withSnackbar } from 'notistack';
import type { History, Location } from 'history';
import { SuccessOutlinedIcon, ShiftTradeLogo } from '../../../components/svg';
import { handleError } from '../../../lib/apiHelpers';
import Footer from '../../../components/Footer';
import { AdapterLink } from '../../../lib/materialUiUtils';
import FormItem from '../../../components/FormItem';
import { formSchema } from './validations';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    backgroundColor: theme.palette.common.mediumGreen,
  },
  main: {
    [theme.breakpoints.up('xs')]: {
      maxWidth: 500,
      padding: theme.spacing(1, 2, 2, 2),
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.up('sm')]: {
      width: 500,
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3, 3, 4, 3),
      margin: theme.spacing(1, 3),
    },
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
  },
  logo: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      maxWidth: 300,
      margin: theme.spacing(1),
      padding: theme.spacing(2.5),
    },
    [theme.breakpoints.up('sm')]: {
      width: 300,
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(4),
    },
  },
  pageTitle: {
    fontSize: '20px',
    fontWeight: 500,
    padding: theme.spacing(2, 0),
  },
  form: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 1, 5, 1),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 3, 2, 3),
    },
    width: '100%',
  },
  paragraph: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  icon: {
    width: 54,
    height: 54,
    alignSelf: 'inherit !important',
    margin: theme.spacing(4, 0, 1, 0),
  },
  sendButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: 150,
    },
  },
  backLinkContainer: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 0),
      '& a': {
        width: '100%',
      },
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  backButtonContainer: {
    marginTop: theme.spacing(3),
  },
}));

type FormValues = {
  email: string,
  isToDirector: boolean,
};

type Props = {
  getAccount: (gcAccountId: string) => Promise<any>,
  isSubmitting: boolean,
  values: FormValues,
  history: History<Location>,
  setFieldValue: (fieldName: string, value: any, shouldValidate?: boolean) => void,
};

const ShareInvoice = (props: Props) => {
  const { getAccount, isSubmitting, values, history, setFieldValue } = props;
  const classes = useStyles();
  const [isChangeLimitLinkVisible, setIsChangeLimitLinkVisible] = useState<boolean>(false);
  const { isSupplierInitiated = false, gcAccountId } = (history.location.state || {}: any);

  useEffect(() => {
    getAccount(gcAccountId).then((response) => {
      if (response.error) {
        return;
      }

      // if the credit limit usage has exceeded 85%, then display the change limit link
      const { data: { spCreditLimit = 0, availableBalance = 0 } = {} } = response.payload;
      if (spCreditLimit <= 0) {
        setIsChangeLimitLinkVisible(true);
      } else {
        const usage = availableBalance / spCreditLimit;
        setIsChangeLimitLinkVisible(usage > 0.85);
      }
    });
  }, [gcAccountId]);

  if (!history.location.state) {
    return <Redirect to='/404' />;
  }

  return (
    <Box className={classes.container} flexDirection='column' display='flex' alignItems='center'>
      <ShiftTradeLogo className={classes.logo} dark />
      <Form noValidate autoComplete='off'>
        <Box className={classes.main} flexDirection='column' display='flex' alignItems='center' justifyContent='space-around'>
          <SuccessOutlinedIcon className={classes.icon} />
          <Typography variant='h2' className={classes.pageTitle}>
            Invoice paid
          </Typography>
          <div className={classes.form}>
            <Typography variant='body1' component='p' paragraph align='center' className={classes.paragraph}>
              Payment may take up to one business day to process.
            </Typography>

            <Typography variant='h6' component='p' paragraph align='center'>
              {isChangeLimitLinkVisible ? (
                <Button color='primary' disabled={isSubmitting} component={AdapterLink} to='/buyer/settings' data-testid='uia-change-limit'>
                  Change my limit
                </Button>
              ) : (
                <Button
                  color='primary'
                  disabled={isSubmitting}
                  component={AdapterLink}
                  to='/buyer/payments/pay-new-or-existing'
                  data-testid='uia-pay-another-invoice'
                >
                  Pay another invoice
                </Button>
              )}
            </Typography>
            {isSupplierInitiated ? (
              <Box flexDirection='column' display='flex' alignItems='center' justifyContent='space-around' className={classes.backButtonContainer}>
                <Button
                  variant='outlined'
                  color='primary'
                  disabled={isSubmitting}
                  component={AdapterLink}
                  to='/buyer/dashboard'
                  data-testid='uia-backButton'
                >
                  Go back to dashboard
                </Button>
              </Box>
            ) : (
              <>
                <FormItem label='Send Remittance'>
                  <Field
                    component={TextField}
                    variant='outlined'
                    fullWidth
                    id='email'
                    name='email'
                    data-testid='uia-email'
                    value={values.email}
                    type='email'
                  />
                </FormItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isToDirector}
                      onChange={(_, checked) => setFieldValue('isToDirector', checked)}
                      data-testid='uia-isToDirector-check'
                      id='isToDirector'
                      name='isToDirector'
                    />
                  }
                  label='Email me a copy'
                />
                <Grid container className={classes.paragraph2}>
                  <Grid item xs={12} sm={4}>
                    <Button
                      variant='contained'
                      color='primary'
                      type='submit'
                      disabled={isSubmitting}
                      data-testid='uia-sendButton'
                      className={classes.sendButton}
                    >
                      Send
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={8} className={classes.backLinkContainer}>
                    <Button
                      variant='outlined'
                      color='primary'
                      disabled={isSubmitting}
                      component={AdapterLink}
                      to='/buyer/dashboard'
                      data-testid='uia-backButton'
                    >
                      Go back to dashboard
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </div>
        </Box>
      </Form>
      <Footer />
    </Box>
  );
};

type WrapperProps = {
  ...Props,
  shareInvoice: (
    gcAccountId: string,
    email: string,
    loanAmount: ?number,
    isToDirector: boolean,
    invoiceNumber: string,
    supplierName: string,
    effectiveDate: string
  ) => Promise<any>,
  enqueueSnackbar: (message: string, options: any) => void,
  closeSnackbar: () => void,
  resetForm: () => void,
  history: History<Location>,
};

export default withRouter<WrapperProps>(
  withSnackbar(
    withFormik({
      mapPropsToValues: (): FormValues => ({
        email: '',
        isToDirector: false,
      }),
      validateOnBlur: false,
      validationSchema: formSchema,
      handleSubmit: (values: FormValues, formikBag: FormikBag<WrapperProps, FormValues>) => {
        const { props } = formikBag;

        const { email, isToDirector } = values;

        const { gcAccountId, amount, invoiceNumber, supplierAccountName } = (props.history.location.state: any);

        props.closeSnackbar();

        props
          .shareInvoice(gcAccountId, email, amount, isToDirector, invoiceNumber, supplierAccountName, moment().format('DD/MM/YYYY'))
          .then((response) => {
            formikBag.setSubmitting(false);

            if (response.error) {
              handleError(response, props, formikBag);
              formikBag.resetForm();
              return;
            }

            if (response.payload && response.payload.status === 200) {
              props.enqueueSnackbar(`Email sent out to ${email}`, {
                variant: 'success',
                key: 'share-invoice',
              });
              props.history.push({
                pathname: '/buyer/dashboard',
              });
              formikBag.resetForm();
            }
          });
      },
      displayName: 'ShareInvoice',
    })(ShareInvoice)
  )
);
