// @flow
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withWidth from '@material-ui/core/withWidth';
import { useSnackbar } from 'notistack';
import { truncate } from 'lodash';

import DollarsAndCentsText from '../../../components/DollarsAndCentsText';
import CompanyIcon from '../../../components/svg/CompanyIcon';
import type { Invoice } from './types';
import ResponsiveLabelValue from './ResponsiveLabelValue';
import { isMobileResolution } from '../../../lib/materialUiUtils';
import { defaultHandleError } from '../../../lib/apiHelpers';
import StyledSwitch from '../../../components/StyledSwitch';
import { type AuthorizationStatus, AuthorizationStatusValues } from '../../../api/shiftPaymentsApi';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { STANDARD_DATE_FORMAT } from '../../../constants';
import { formatAbnOrAcn } from '../../../lib/formatter';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 4, // needed due to MuiGrid-spacing-xs-1 using -4 margin
  },
  header: {
    backgroundColor: theme.palette.grey.background,
    paddingLeft: theme.spacing(1.5),
    display: 'block',
  },
  date: {
    color: theme.palette.grey.text,
    fontSize: theme.typography.body2.fontSize,
  },
  values: {
    padding: theme.spacing(2),
  },
  companyName: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  icon: {
    fontSize: '13px',
    marginRight: theme.spacing(2),
  },
  heavy: {
    fontWeight: 500,
  },
  expansionSummary: {
    padding: theme.spacing(0, 2),
    '& .MuiExpansionPanelSummary-content': {
      margin: theme.spacing(0),
    },
  },
  expansionPanelDetails: {
    padding: 0,
    display: 'block',
  },
  mobileHeader: {
    fontSize: 14,
  },
  mobileSecondaryHeader: {
    fontSize: 12,
    color: theme.palette.grey.text,
  },
  companyIcon: {
    minWidth: 0,
    paddingRight: theme.spacing(1),
  },
  list: {
    '& .MuiListItem-root': {
      alignItems: 'end',
    },
    '& .MuiListItemText-multiline': {
      marginTop: 0,
    },
    paddingTop: theme.spacing(1),
  },
  link: {
    cursor: 'pointer',
  },
}));

export type SetSupplierAuthorization = (
  buyerGcAccountId: string,
  supplierGcAccountId: string,
  authorizationStatus: AuthorizationStatus,
  reason: ?string
) => Promise<any>;

type DesktopHeaderProps = {
  classes: any,
  created: Date,
  supplierAccountName: string,
  supplierAbn: string,
  supplierGcAccountId: string,
  buyerGcAccountId: string,
  orderId: string,
  authorizationStatus: AuthorizationStatus,
  setSupplierAuthorization: SetSupplierAuthorization,
};

type PendingAuthorizationAction = {
  authorizationStatus: AuthorizationStatus,
  cancelAction: () => void,
};

const DesktopHeader = (props: DesktopHeaderProps) => {
  const {
    classes,
    created,
    supplierAccountName,
    supplierAbn,
    supplierGcAccountId,
    buyerGcAccountId,
    orderId,
    authorizationStatus,
    setSupplierAuthorization,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [pendingAuthorizationAction, setPendingAuthorizationAction] = useState<?PendingAuthorizationAction>(null);
  const [inputDialogBoxFieldValue, setInputDialogBoxFieldValue] = useState('');

  const setAuthorization = (status: AuthorizationStatus, reason: ?string) => {
    setSupplierAuthorization(buyerGcAccountId, supplierGcAccountId, status, reason).then((response) => {
      const { error } = response;

      if (error) {
        defaultHandleError({ enqueueSnackbar }, undefined);
        return;
      }

      if (status === AuthorizationStatusValues.NotRequired) {
        enqueueSnackbar(
          `Auto-billing has been successfully enabled for ${supplierAccountName}. All future transactions will be authorised automatically.`,
          { variant: 'success', key: supplierAccountName }
        );
      } else {
        enqueueSnackbar(
          `Auto-billing has been successfully disabled for ${supplierAccountName}. You will need to review and approve all future transactions.`,
          { variant: 'success', key: supplierAccountName }
        );
      }
      setPendingAuthorizationAction(null);
    });
  };

  const closeConfirmation = (confirmation: boolean) => {
    if (!pendingAuthorizationAction) {
      return;
    }

    if (confirmation) {
      setAuthorization(AuthorizationStatusValues.Required, inputDialogBoxFieldValue);
    } else {
      if (pendingAuthorizationAction) {
        pendingAuthorizationAction.cancelAction();
      }
      setPendingAuthorizationAction(null);
    }
  };

  const handleInputFieldChange = (e) => {
    setInputDialogBoxFieldValue(e && e.target ? e.target.value : '');
  };

  const handleAuthorizationChange = (checked: boolean, cancelAction: () => void) => {
    if (checked) {
      setAuthorization(AuthorizationStatusValues.NotRequired, '');
    } else {
      const status = checked ? AuthorizationStatusValues.NotRequired : AuthorizationStatusValues.Required;
      setPendingAuthorizationAction({ authorizationStatus: status, cancelAction });
    }
  };

  return (
    <Grid id='desktop' container className={classes.values} spacing={1}>
      <Grid item xs={12}>
        <Typography className={classes.date}>
          <CalendarTodayIcon className={classes.icon} />
          <span data-testid='uia-invoiceDateCreated'>{moment(created).format(STANDARD_DATE_FORMAT)}</span>
        </Typography>
        <Typography variant='h5' className={classes.companyName} data-testid='uia-supplierAccountName'>
          {supplierAccountName}
        </Typography>
      </Grid>
      {supplierAbn ? <ResponsiveLabelValue label='ABN' value={formatAbnOrAcn(supplierAbn)} classes={{ value: classes.heavy }} data-testid='uia-supplierAbn' /> : null}
      {orderId ? <>
          <ResponsiveLabelValue label='Order Id' value={orderId} classes={{ value: classes.heavy }} data-testid='uia-orderId' />
          <ResponsiveLabelValue
            label='Auto-billing'
            value={
              <StyledSwitch
                onChange={handleAuthorizationChange}
                checked={authorizationStatus === AuthorizationStatusValues.NotRequired}
                data-testid='uia-authorizationStatus'
              />
            }
            classes={{ value: classes.heavy }}
          />
        </> : null}
      {pendingAuthorizationAction && pendingAuthorizationAction.authorizationStatus === AuthorizationStatusValues.Required ? <ConfirmationDialog
          isOpen={!!pendingAuthorizationAction}
          onClose={() => closeConfirmation(false)}
          onConfirm={() => closeConfirmation(true)}
          title='Auto-billing'
          contentText={`Are you sure you want to disable auto-billing for ${supplierAccountName}?`}
          id='confirmation'
          showChildren
        >
          <>
            <TextField
              data-testid='uia-dialogInput'
              multiline
              placeholder='Please let us know the reason'
              rows={2}
              variant='outlined'
              fullWidth
              onChange={() => handleInputFieldChange()}
              autoFocus
              autoComplete='off'
              inputProps={{
                maxLength: 200,
              }}
            />
            <Typography variant='subtitle1'>{200 - inputDialogBoxFieldValue.length} character(s) remaining</Typography>
          </>
        </ConfirmationDialog> : null}
    </Grid>
  );
};

type DetailProps = {
  classes: any,
  invoiceNumber: string,
  amount: number,
  attachments: Array<any>,
  buyerGcAccountId: string,
  getInvoiceAttachment: (buyerGcAccountId: string, filePath: string) => Promise<any>,
};

const Detail = (props: DetailProps) => {
  const { classes, invoiceNumber, amount, attachments, buyerGcAccountId, getInvoiceAttachment } = props;
  const { enqueueSnackbar } = useSnackbar();

  const getDownloadInvoiceLink = (filePath: string) => {
    getInvoiceAttachment(buyerGcAccountId, filePath).then((response) => {
      if (response.error) {
        defaultHandleError({ enqueueSnackbar });
      }
    });
  };

  return (
    <React.Fragment>
      <Typography className={classes.header} variant='overline'>
        Invoices
      </Typography>
      <Grid container className={classes.values} spacing={1}>
        <ResponsiveLabelValue label='Invoice No.' value={invoiceNumber} classes={{ value: classes.heavy }} data-testid='uia-invoiceNumber' />
        <ResponsiveLabelValue
          label='Invoice $'
          value={<DollarsAndCentsText amount={amount} />}
          classes={{ value: classes.heavy }}
          data-testid='uia-invoiceAmount'
        />
      </Grid>
      <Typography className={classes.header} variant='overline'>
        Attachments ({(attachments && attachments.length) || 0})
      </Typography>
      <Grid container className={classes.values} spacing={1}>
        {attachments ? attachments.map((attachment) => {
            const filepath = `${attachment.path}/${attachment.label}`;
            return (
              <React.Fragment key={attachment.path}>
                <ResponsiveLabelValue
                  label={truncate(attachment.label || attachment.name, { length: 25 })}
                  value={
                    <Link className={classes.link} onClick={() => getDownloadInvoiceLink(filepath)} title={filepath}>
                      View invoice
                    </Link>
                  }
                  hideValue={!attachment.path}
                  classes={{ value: classes.heavy }}
                  widthProps={{ left: 8, right: 4 }}
                  data-testid='uia-invoiceAttachment'
                />
              </React.Fragment>
            );
          }) : null}
      </Grid>
    </React.Fragment>
  );
};

type Props = {
  buyerGcAccountId: string,
  getInvoiceAttachment: (buyerGcAccountId: string, filePath: string) => Promise<any>,
  width: string,
  authorizationStatus: AuthorizationStatus,
  setSupplierAuthorization: SetSupplierAuthorization,
} & Invoice;

const InvoiceDetail = (props: Props) => {
  const {
    created,
    supplierAccountName,
    supplierAbn,
    orderId,
    invoiceNumber,
    amount,
    attachments,
    buyerGcAccountId,
    getInvoiceAttachment,
    width,
    authorizationStatus,
    setSupplierAuthorization,
    supplierGcAccountId,
  } = props;

  const classes = useStyles();

  const isMobile = isMobileResolution(width);

  return (
    <Paper className={classes.paper} id='paper'>
      {!isMobile && (
        <DesktopHeader
          created={created}
          supplierAccountName={supplierAccountName}
          supplierAbn={supplierAbn}
          supplierGcAccountId={supplierGcAccountId}
          buyerGcAccountId={buyerGcAccountId}
          orderId={orderId}
          classes={classes}
          authorizationStatus={authorizationStatus}
          setSupplierAuthorization={setSupplierAuthorization}
        />
      )}
      {isMobile ? <ExpansionPanel id='mobile'>
          <ExpansionPanelSummary className={classes.expansionSummary} data-testid='uia-expand-company' expandIcon={<ExpandMoreIcon />}>
            <List classes={{ root: classes.list }}>
              <ListItem disableGutters>
                <ListItemIcon className={classes.companyIcon}>
                  <CompanyIcon width={32} height={32} />
                </ListItemIcon>
                <ListItemText
                  primary={supplierAccountName}
                  primaryTypographyProps={{ variant: 'h5', className: classes.companyName }}
                  secondary={supplierAbn}
                />
              </ListItem>
            </List>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            <Detail
              classes={classes}
              invoiceNumber={invoiceNumber}
              amount={amount}
              attachments={attachments}
              buyerGcAccountId={buyerGcAccountId}
              getInvoiceAttachment={getInvoiceAttachment}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel> : null}
      {!isMobile && (
        <Detail
          classes={classes}
          invoiceNumber={invoiceNumber}
          amount={amount}
          attachments={attachments}
          buyerGcAccountId={buyerGcAccountId}
          getInvoiceAttachment={getInvoiceAttachment}
        />
      )}
    </Paper>
  );
};

export default withWidth()(InvoiceDetail);
