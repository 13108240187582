// @flow
/* eslint-disable flowtype/no-types-missing-file-annotation */
import { createActions } from 'redux-actions';
// $FlowFixMe
import { batch } from 'react-redux';
import type { Dispatch } from 'redux';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import shiftPaymentsApi, { type PaymentMethod, type AuthorizationStatus } from '../../../api/shiftPaymentsApi';
import fetchiApi from '../../../api/fetchiApi';

export const {
  confirmInvoicePending,
  confirmInvoiceFulfilled,
  confirmInvoiceRejected,
  setSupplierAuthorizationPending,
  setSupplierAuthorizationFulfilled,
  setSupplierAuthorizationRejected,
} = createActions(
  actionTypes.CONFIRM_INVOICE_PENDING,
  actionTypes.CONFIRM_INVOICE_FULFILLED,
  actionTypes.CONFIRM_INVOICE_REJECTED,
  actionTypes.SET_SUPPLIER_AUTHORIZATION_PENDING,
  actionTypes.SET_SUPPLIER_AUTHORIZATION_FULFILLED,
  actionTypes.SET_SUPPLIER_AUTHORIZATION_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const confirmInvoice =
  (
    contractId: string,
    gcAccountId: string,
    paymentMethod: PaymentMethod,
    selectedDate: ?Date,
    selectedTerm: ?number,
    supplierGcAccountId: string,
    authorizationStatus: AuthorizationStatus,
    requestId: string
  ) =>
  (dispatch: Dispatch<any>) => {
    dispatch(setSupplierAuthorizationPending());

    return shiftPaymentsApi
      .setSupplierAuthorization(gcAccountId, supplierGcAccountId, authorizationStatus)
      .then((response) => {
        batch(() => {
          dispatch(setSupplierAuthorizationFulfilled(response));
          dispatch(confirmInvoicePending());
        });

        window.dispatchEvent(new Event('fetchStart'));
        return fetchiApi
          .confirmInvoice(contractId, gcAccountId, paymentMethod, requestId, selectedDate, selectedTerm)
          .then((response) => response.json())
          .then((confirmResponse) => {
            return dispatch(confirmInvoiceFulfilled(confirmResponse));
          })
          .catch((error) => {
            return dispatch(confirmInvoiceRejected(error));
          })
          .finally(() => {
            window.dispatchEvent(new Event('fetchEnd'));
          });
      })
      .catch((error) => {
        return dispatch(setSupplierAuthorizationRejected(error));
      });
  };

export default {
  confirmInvoice,
};
