// @flow
import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

type Props = {
  anchorOrigin: {
    horizontal: 'center' | 'left' | 'right',
    vertical: 'bottom' | 'center' | 'top',
  },
  transformOrigin: {
    horizontal: 'center' | 'left' | 'right',
    vertical: 'bottom' | 'center' | 'top',
  },
};

const StyledMenu = withStyles((theme) => ({
  paper: {
    border: `1px solid ${theme.palette.grey['300']}`,
    '& .MuiListItem-root': {
      minWidth: 220,
    },
    '& .MuiListItem-root:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey['300']}`,
    },
  },
  '& .MuiList-padding': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))((props: Props) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={
      props.anchorOrigin || {
        vertical: 'bottom',
        horizontal: 'center',
      }
    }
    transformOrigin={
      props.transformOrigin || {
        vertical: 'top',
        horizontal: 'center',
      }
    }
    // $FlowFixMe
    {...props}
  />
));

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&[data-selected="true"]': {
      backgroundColor: `${theme.palette.action.selected} !important`,
    },
    fontSize: 14,
  },
}))(MenuItem);

export default StyledMenu;
