// @flow
import { handleActions } from 'redux-actions';
import { PARTITION, actionTypes, ACTION_PREFIX } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';

type PopularBank = {
  bankId: string,
  bankName: string,
  bankIcon: string,
  slug: string,
};

export type ReduxState = {
  popularBanks: PopularBank[],
};

const initialState: ReduxState = {
  popularBanks: [],
};

const reducer = handleActions(
  {
    [actionTypes.GET_POPULAR_BANKS_FULFILLED]: (state, { payload }) => ({
      ...state,
      popularBanks: payload.data.result,
    }),
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
