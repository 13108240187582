// @flow
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { withFormik, Form } from 'formik';
import { withRouter } from 'react-router';
import { Button, Box } from '@material-ui/core';
import type { FormikBag, FormikErrors } from 'formik';
import type { History, Location } from 'history';
import { withSnackbar } from 'notistack';
import withWidth from '@material-ui/core/withWidth';

import BasicPageLayout from '../../../components/BasicPageLayout';
import { formSchema } from './validations';
import { ATTACHMENT_SUPPORTED_FORMATS } from '../../../constants';
import { handleError } from '../../../lib/apiHelpers';
import PageItem from '../../../components/PageItem';
import { isMobileResolution } from '../../../lib/materialUiUtils';
import startupConfig, { type AttachmentConfig } from '../../../lib/startupConfig';
import FormItem from '../../../components/FormItem';
import { FormikDndAttachFiles } from '../../../components/formik';

const { v4: uuidv4 } = require('uuid');

const useStyles = makeStyles((theme) => ({
  footerButtons: {
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    fontWeight: 'normal',
    [theme.breakpoints.up('xs')]: {
      width: '50%',
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      width: 90,
      marginRight: theme.spacing(1),
    },
  },
  submitButton: {
    [theme.breakpoints.up('xs')]: {
      width: '50%',
      marginLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      width: 150,
    },
  },
}));

type FormValues = {
  files: Array<any>,
  fileGroupId: string,
};

type Props = {
  gcAccountId: string,
  deleteTrustDeedFiles: (gcAccountId: string, fileGroupId: string) => Promise<any>,
};

type WithHOCProps = {
  ...Props,
  width: string,
  isSubmitting: boolean,
  values: FormValues,
  errors: FormikErrors<FormValues>,
  touched: any,
  setFieldValue: (fieldName: string, value: any, shouldValidate?: boolean) => void,
  resetForm: () => void,
  uploadTrustDeedFile: (gcAccountId: string, fileGroupId: string, file: any) => Promise<any>,
  deleteTrustDeedFile: (gcAccountId: string, fileGroupId: string, fileName: string) => Promise<any>,
};

const TrustDeed = (props: WithHOCProps) => {
  const {
    gcAccountId,
    deleteTrustDeedFiles,
    width,
    isSubmitting,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
    uploadTrustDeedFile,
    deleteTrustDeedFile,
  } = props;
  const classes = useStyles();
  const isMobile = isMobileResolution(width);

  const handleCancel = () => {
    if (values.files && values.files.length > 0) {
      deleteTrustDeedFiles(gcAccountId, values.fileGroupId);
    }
    resetForm();
    setFieldValue('files', []);
  };

  const { maxFilesCount, maxFileSizeInMb } = startupConfig.get().trustDeedAttachment;

  return (
    <BasicPageLayout title='Trust Deed' noMargin={!isMobile}>
      <Form noValidate autoComplete='off'>
        <PageItem>
          <FormItem label='Upload Trust Deed'>
            <FormikDndAttachFiles
              name='files'
              values={values}
              errors={errors}
              touched={touched}
              accept={ATTACHMENT_SUPPORTED_FORMATS.toString()}
              fileGroupId={values.fileGroupId}
              gcAccountId={gcAccountId}
              onFileUpload={uploadTrustDeedFile}
              onFileDelete={deleteTrustDeedFile}
              maxFilesCount={maxFilesCount}
              maxFileSizeInMb={maxFileSizeInMb}
            />
          </FormItem>
        </PageItem>
        <Box display='flex' justifyContent={isMobile ? 'inherit' : 'flex-end'} className={classes.footerButtons}>
          <Button className={classes.cancelButton} color='primary' disabled={isSubmitting} onClick={handleCancel} data-testid='uia-cancelButton'>
            Cancel
          </Button>
          <Button
            className={classes.submitButton}
            variant='contained'
            color='primary'
            type='submit'
            disabled={isSubmitting}
            data-testid='uia-processButton'
          >
            Send
          </Button>
        </Box>
      </Form>
    </BasicPageLayout>
  );
};

type WrapperProps = {
  ...Props,
  setError: (error: ?string) => void,
  fileGroupIdForTesting: string,
  closeSnackbar: () => void,
  updateTrustDeeds: (gcAccountId: string, fileGroupId: string, files: Array<any>) => Promise<any>,
  refreshToken: () => Promise<any>,
  trustDeedAttachmentConfig: AttachmentConfig,
};

type WithHOCWrapperProps = {
  ...WrapperProps,
  history: History<Location>,
};

export default withRouter<WrapperProps>(
  withSnackbar(
    withFormik({
      mapPropsToValues: ({ fileGroupIdForTesting }): FormValues => ({
        files: [],
        fileGroupId: fileGroupIdForTesting || uuidv4(),
      }),
      validateOnBlur: false,
      validationSchema: formSchema,
      handleSubmit: (values: FormValues, formikBag: FormikBag<WithHOCWrapperProps, FormValues>) => {
        const { props } = formikBag;
        const { files, fileGroupId } = values;

        props.closeSnackbar();

        props.updateTrustDeeds(props.gcAccountId, fileGroupId, files).then((response) => {
          formikBag.setSubmitting(false);

          if (response.error) {
            let errorMessage = null;
            const errorDetail = response.payload.response.data;
            if (errorDetail) {
              if (response.payload.response.status === 400 && errorDetail.message) {
                errorMessage = errorDetail.message;
              }
            }
            handleError(errorDetail, props, formikBag, undefined, errorMessage);
            return;
          }

          if (response.payload && response.payload.status === 204) {
            props.refreshToken().then(() => {
              props.history.push({
                pathname: '/buyer/dashboard',
              });
            });
          }
        });
      },
      displayName: 'TrustDeed',
    })(withWidth()(TrustDeed))
  )
);
