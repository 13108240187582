// @flow
import { createContext } from 'react';
import { type File } from '../../types';

export type FileUploadContextProps = {
  maxFilesCount: number,
  maxFileSizeInMb: number,
  onFileUpload: (gcAccountId: string, fileGroupId: string, file: any) => Promise<any>,
  fileGroupId: string,
  gcAccountId: string,
  canUpload: (file: File) => boolean,
  fileCount: number,
  supportedFileTypesText?: string,
};

const FileUploadContext = createContext<FileUploadContextProps>({});

export default FileUploadContext;
