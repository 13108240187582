import { createMuiTheme } from '@material-ui/core/styles';
import getCapitalTheme from '.';

const theme = createMuiTheme(getCapitalTheme);

const themeWithOverride = {
  ...theme,
  overrides: {
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        [theme.breakpoints.down('xs')]: {
          minWidth: 0,
        },
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        [theme.breakpoints.down('xs')]: {
          minWidth: 0,
          minHeight: 0,
        },
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: 160,
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        [theme.breakpoints.down('xs')]: {
          width: 28,
        },
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: theme.palette.action.selected,
        '&:hover': {
          border: `1px solid ${theme.palette.datePicker.selectedHoverBorder}`,
          backgroundColor: `${theme.palette.action.selected} !important`,
        },
        '& .MuiTypography-body2': {
          fontWeight: '500 !important',
          color: theme.palette.common.black,
          fontSize: 13,
        },
      },
      day: {
        [theme.breakpoints.down('xs')]: {
          width: 28,
          height: 28,
        },
        '&.MuiIconButton-root:hover': {
          backgroundColor: theme.palette.selectPanel.selected.backgroundColor,
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 14,
      },
    },
    MuiFormLabel: {
      root: {
        color: theme.palette.text.primary,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: theme.palette.common.mediumGreen,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
      outlinedSecondary: {
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTabs: {
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: 0,
        minWidth: 0,
        padding: 0,
        backgroundColor: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.grey['200']}`,
        borderTop: `1px solid ${theme.palette.grey['200']}`,
        borderRight: `1px solid ${theme.palette.grey['200']}`,
        fontWeight: 'normal',
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(0, 2),
          minWidth: 150,
        },
        '&$selected': {
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          borderTop: `3px solid ${theme.palette.common.orange}`,
          borderLeft: `1px solid ${theme.palette.grey['200']}`,
          borderRight: `1px solid ${theme.palette.grey['200']}`,
          borderBottom: 0,
          fontWeight: 500,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: theme.palette.common.mediumBlue,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {},
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiFormHelperText: {
      contained: {
        margin: theme.spacing(1, 0),
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          color: theme.palette.common.black,
        },
        'input::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        'input::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        'input[type=number]': {
          '-moz-appearance': 'textfield',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: theme.palette.common.white,
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: theme.palette.primary.main,
        },
      },
      input: {
        paddingTop: 14,
        paddingBottom: 14,
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
      },
    },
    MuiMenuItem: {
      root: {
        '&:hover': {
          backgroundColor: `${theme.palette.action.hover} !important`,
        },
      },
    },
    MuiPaper: {
      // use the same shadow effect for all elevations
      ...Array(25)
        .fill('0 1px 8px 0 rgba(17, 18, 18, 0.1) !important')
        .map((value) => {
          return { elevation1: value };
        })
        .reduce((o, item, index) => {
          return index === 0 ? null : { ...o, [`elevation${index}`]: { boxShadow: item.elevation1 } };
        }),
      root: {
        color: theme.palette.common.black,
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: theme.spacing(6),
    },
  },
};

export default themeWithOverride;
