// @flow
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { withFormik, Form, Field } from 'formik';
import { Redirect, withRouter, type Location } from 'react-router';
import { Button, Box, FormControlLabel, Radio, Typography } from '@material-ui/core';
import { RadioGroup } from 'formik-material-ui';
import type { FormikBag } from 'formik';
import type { History } from 'history';
import { withSnackbar } from 'notistack';
import withWidth from '@material-ui/core/withWidth';

import BasicPageLayout from '../../../components/BasicPageLayout';
import { type AuthorizationStatus, AuthorizationStatusValues, type PaymentMethod } from '../../../api/shiftPaymentsApi';
import { defaultHandleError } from '../../../lib/apiHelpers';
import PageItem from '../../../components/PageItem';
import { isMobileResolution } from '../../../lib/materialUiUtils';
import { type Invoice } from '../Review/types';

const useStyles = makeStyles((theme) => ({
  footerButtons: {
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    fontWeight: 'normal',
    [theme.breakpoints.up('xs')]: {
      width: '50%',
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      width: 90,
      marginRight: theme.spacing(1),
    },
  },
  submitButton: {
    [theme.breakpoints.up('xs')]: {
      width: '50%',
      marginLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      width: 150,
    },
  },
  optionDescription: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    color: theme.palette.grey.light,
  },
}));

type FormValues = {
  authorizationStatus: AuthorizationStatus,
};

type Props = {
  width: string,
  isSubmitting: boolean,
  values: FormValues,
  history: History<Location>,
};

const SupplierInvoiceReviewAuthorization = (props: Props) => {
  const { width, isSubmitting, history } = props;
  const classes = useStyles();
  const isMobile = isMobileResolution(width);

  if (!history.location.state || !(history.location.state: any).invoice) {
    return <Redirect to={{ pathname: '/buyer/dashboard' }} />;
  }

  const { invoice } = (history.location.state: any);

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <BasicPageLayout data-testid='uia-reviewAuth-layout' title={`Authorisation Options for ${invoice.supplierAccountName}`} noMargin={!isMobile}>
      <Form noValidate autoComplete='off'>
        <PageItem>
          <Field component={RadioGroup} name='authorizationStatus' value={invoice.supplierAuthorizationStatus}>
            <FormControlLabel
              value={AuthorizationStatusValues.NotRequired}
              control={<Radio />}
              label={<Typography variant='subtitle2'>I trust this supplier - allow automatic billing</Typography>}
            />
            <Typography variant='body2' className={classes.optionDescription}>
              By selecting this option, next time {invoice.supplierAccountName} bills your account, the transaction will&nbsp; be authorised
              automatically and you will not need to review and approve the transaction. This option will save&nbsp; you time and will allow the
              transaction to occur seamlessly.
              <br />
              <br />
              We recommend selecting this option if you trust this supplier as you are giving them the ability to bill your&nbsp; Shift Trade account
              without your approval each time.
            </Typography>
            <FormControlLabel
              value={AuthorizationStatusValues.Required}
              control={<Radio />}
              label={<Typography variant='subtitle2'>I want to approve every transaction</Typography>}
            />
            <Typography variant='body2' className={classes.optionDescription}>
              By selecting this option, every time {invoice.supplierAccountName} bills your account, you will need to review and&nbsp; approve every
              transaction (just like you did for this transaction).
              <br />
              <br />
              We recommend selecting this option if this is a new supplier relationship. Once you have built a relationship with this&nbsp; supplier
              and you trust them, you can change the authorisation options and set up automatic billing at a later date.
            </Typography>
          </Field>
        </PageItem>
        <Box display='flex' justifyContent={isMobile ? 'inherit' : 'flex-end'} className={classes.footerButtons}>
          <Button className={classes.cancelButton} color='primary' disabled={isSubmitting} onClick={handleCancel} data-testid='uia-cancelButton'>
            Go back
          </Button>
          <Button
            className={classes.submitButton}
            variant='contained'
            color='primary'
            type='submit'
            disabled={isSubmitting}
            data-testid='uia-confirmInvoiceAccept'
          >
            Confirm
          </Button>
        </Box>
      </Form>
    </BasicPageLayout>
  );
};

type WrapperProps = {
  ...Props,
  setError: (error: ?string) => void,
  closeSnackbar: () => void,
  enqueueSnackbar: (message: string, options?: Object) => void,
  gcAccountId: string,
  invoice: Invoice,
  paymentMethod: PaymentMethod,
  paymentDate: ?Date,
  installmentTermInWeeks: number,
  confirmInvoice: (
    contractId: string,
    gcAccountId: string,
    paymentMethod: PaymentMethod,
    selectedDate: ?Date,
    selectedTerm: ?number,
    supplierGcAccountId: string,
    authorizationStatus: AuthorizationStatus,
    invoiceConfirmRequestId: string
  ) => Promise<any>,
};

type WithHOCWrapperProps = {
  ...WrapperProps,
};

export default withRouter<WrapperProps>(
  withSnackbar(
    withFormik({
      mapPropsToValues: (): FormValues => ({
        authorizationStatus: AuthorizationStatusValues.NotRequired,
        reviewAuthorizationRequestId: '',
      }),
      validateOnBlur: false,
      handleSubmit: (values: FormValues, formikBag: FormikBag<WithHOCWrapperProps, FormValues>) => {
        const { props } = formikBag;
        const { authorizationStatus } = values;
        const { closeSnackbar, enqueueSnackbar, confirmInvoice, gcAccountId, history } = props;
        const { invoice, paymentMethod, paymentDate, installmentTermInWeeks, invoiceConfirmRequestId } = (history.location.state: any);

        closeSnackbar();

        confirmInvoice(
          invoice.id,
          gcAccountId,
          paymentMethod,
          paymentDate,
          installmentTermInWeeks,
          invoice.supplierGcAccountId,
          authorizationStatus,
          invoiceConfirmRequestId
        ).then((response) => {
          formikBag.setSubmitting(false);
          if (response?.payload?.status) {
            defaultHandleError({ enqueueSnackbar });
            return;
          }

          if (response?.payload?.showAddStaffPage) {
            history.push({
              pathname: '/buyer/payments/staffs/add',
              state: {
                amount: invoice.amount,
                supplierAccountName: invoice.supplierAccountName,
                gcAccountId,
                invoiceNumber: invoice.invoiceNumber,
                isSupplierInitiated: !!invoice.id,
              },
            });
          } else {
            history.push({
              pathname: '/buyer/payments/paid',
              state: {
                amount: invoice.amount,
                supplierAccountName: invoice.supplierAccountName,
                gcAccountId,
                invoiceNumber: invoice.invoiceNumber,
                isSupplierInitiated: !!invoice.id,
              },
            });
          }
        });
      },
      displayName: 'SupplierInvoiceReviewAuthorization',
    })(withWidth()(SupplierInvoiceReviewAuthorization))
  )
);
