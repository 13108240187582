/* eslint-disable flowtype/no-types-missing-file-annotation */
/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { withFormik, Form, Field } from 'formik';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withTheme } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

import { Checkbox } from '../../lib/materialUiFormikComponents';
import { formSchema } from './validations';
import AlertError from '../AlertError';
import { defaultHandleError, handleError } from '../../lib/apiHelpers';
import SuccessIcon from '../svg/SuccessIcon';
import BsbText from '../BsbText';
import { startNewCreditLimitRequestPolling } from '../../lib/creditLimitStatusService';
import { LBFCID_FALLBACK } from '../../constants';

const styles = (theme) => {
  return {
    listItems: {
      maxHeight: 250,
      overflowY: 'auto',
      '& div.MuiListItem-root:not(:first-of-type)': {
        borderTop: `1px solid ${theme.palette.grey['300']}`,
      },
    },
    headerText: {
      margin: theme.spacing(0, 1),
      fontWeight: 500,
    },
    listItemHeader: {
      padding: theme.spacing(1, 2),
    },
    submitButtonWithCancel: {
      [theme.breakpoints.down('xs')]: {
        width: '50%',
      },
    },
    submitButton: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: 130,
      },
      marginRight: 10,
      margin: theme.spacing(2, 0),
    },
    cancelButton: {
      [theme.breakpoints.down('xs')]: {
        width: '50%',
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 90,
      },
      margin: theme.spacing(2, 0),
    },
    accountCheckbox: { display: 'none' },
    accountDetailsText: {
      fontWeight: 500,
      fontSize: '14px',
    },
    accountDetailsSubText: {
      fontWeight: 500,
      color: theme.palette.grey['500'],
      marginRight: theme.spacing(1),
    },
    formControlLabel: {
      width: '100%',
      display: 'block',
      padding: theme.spacing(2, 1),
    },
    image: {
      maxWidth: '100px',
      width: '100%',
      height: 'auto',
    },
    directDebitTitle: {
      margin: theme.spacing(4, 0, 1, 0),
    },
    selectedItem: {
      alignSelf: 'center',
      display: 'inline-flex',
    },
    status: {
      fontWeight: 500,
      marginLeft: theme.spacing(1),
      alignSelf: 'center',
      color: theme.palette.success.main,
    },
    bankContainer: {
      border: `1px solid ${theme.palette.grey['300']}`,
      margin: theme.spacing(0, 0, 3),
    },
  };
};

const StyledListItem = withStyles((theme) => {
  return {
    button: {
      '&:hover, &:active, &:focus, .checked': {
        backgroundColor: theme.palette.common.lightGreen,
      },
    },
  };
})(ListItem);

const AddBankSelectAccountForm = (props) => {
  const { accounts, theme, classes, values, errors, isSubmitting, setFieldValue, submitCount, history, showCancel = false } = props;

  const goBackToBankSearch = useCallback(() => {
    history.push('/settings/add-bank');
  });

  const accountGrouping = accounts.reduce((acc, cur) => {
    if (!(cur.bankId in acc)) {
      acc[cur.bankId] = {
        bankName: cur.bankName,
        bankIcon: cur.bankIcon,
        accounts: [],
      };
    }

    acc[cur.bankId].accounts.push(cur);
    return acc;
  }, {});

  return (
    <Box>
      {submitCount > 0 && errors.selectedAccount ? (
        <>
          <AlertError message={errors.selectedAccount} />
          <br />
        </>
      ) : null}
      <Form noValidate autoComplete='off'>
        {accountGrouping
          ? Object.values(accountGrouping).map((g) => (
              <Paper key={g.bankName} elevation={0} className={classes.bankContainer}>
                <List>
                  <ListItem alignItems='center' className={classes.listItemHeader} divider>
                    <img src={g.bankIcon} className={classes.image} alt={g.bankName} />
                    <Typography className={classes.headerText} style={{ marginRight: 'auto' }}>
                      {g.bankName}
                    </Typography>
                    <Typography className={classes.accountDetailsSubText} variant='body2' />
                    <Typography className={classes.headerText} />
                  </ListItem>
                  <div className={classes.listItems}>
                    {g.accounts
                      ? g.accounts.map((a) => (
                          <StyledListItem
                            key={a.id}
                            style={{
                              backgroundColor:
                                (values.selectedAccount && values.selectedAccount.id === a.id) || (!values.selectedAccount && a.isShiftBankAccount)
                                  ? theme.palette.common.lightGreen
                                  : 'inherit',
                            }}
                            button
                            dense
                            onClick={(e) => {
                              e.preventDefault();
                              setFieldValue('selectedAccount', a);
                            }}
                          >
                            <FormControlLabel
                              className={clsx(classes.formControlLabel, `uia-${a.id}`)}
                              control={<Field name={a.id} component={Checkbox} className={classes.accountCheckbox} />}
                              label={
                                <Grid container alignItems='baseline'>
                                  <Grid item xs={12} sm={3}>
                                    <Typography className={classes.accountDetailsText}>{a.name}</Typography>
                                  </Grid>
                                  <Grid item xs={5} sm={3}>
                                    <Typography className={classes.accountDetailsSubText} variant='body2' display='inline'>
                                      BSB
                                    </Typography>
                                    <Typography display='inline' className={classes.accountDetailsText}>
                                      <BsbText value={a.bsb} />
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={7} sm={3}>
                                    <Typography variant='body2' display='inline' className={classes.accountDetailsSubText}>
                                      ACC
                                    </Typography>
                                    <Typography display='inline' className={classes.accountDetailsText}>
                                      {a.accountNumber}
                                    </Typography>
                                  </Grid>
                                  {a.isShiftBankAccount ? (
                                    <Grid item xs={12} sm={3} className={classes.selectedItem}>
                                      <SuccessIcon className={classes.alertIcon} />
                                      <Typography variant='body2' display='inline' className={classes.status}>
                                        Current payment method
                                      </Typography>
                                    </Grid>
                                  ) : null}
                                </Grid>
                              }
                            />
                          </StyledListItem>
                        ))
                      : null}
                  </div>
                </List>
              </Paper>
            ))
          : null}
        <Box display='flex'>
          <Button
            className={clsx(classes.submitButton, { [classes.submitButtonWithCancel]: showCancel })}
            fullWidth
            variant='contained'
            color='primary'
            type='submit'
            disabled={isSubmitting}
            data-testid='uia-select-button'
          >
            Select
          </Button>
          {showCancel ? (
            <Button
              className={classes.cancelButton}
              fullWidth
              color='primary'
              type='button'
              disabled={isSubmitting}
              data-testid='uia-cancel-button'
              onClick={() => goBackToBankSearch()}
            >
              Cancel
            </Button>
          ) : null}
        </Box>
      </Form>
    </Box>
  );
};

AddBankSelectAccountForm.defaultProps = {
  accounts: [],
};

export default withRouter(
  withSnackbar(
    withFormik({
      mapPropsToValues: () => ({
        selectedAccount: null,
      }),
      validationSchema: formSchema,
      handleSubmit: (values: any, formikBag) => {
        const { closeSnackbar, enqueueSnackbar, gcAccountId, setupBankAccount, resetAccountSetupAfterBankAccountUpdate, history, match } =
          formikBag.props;
        const { selectedAccount } = values;

        closeSnackbar();

        let lbfcId = match.params.lbfcId ?? null;
        let selectBankAccount = false;
        if (lbfcId === LBFCID_FALLBACK) {
          selectBankAccount = true;
          lbfcId = null;
        }

        if (selectedAccount.ddrAgreementSigned) {
          const bankAccount = {
            accountNumber: selectedAccount.accountNumber,
            accountName: selectedAccount.name,
            bsb: selectedAccount.bsb,
            bankAccountId: selectedAccount.id,
            select: selectBankAccount,
          };
          setupBankAccount(gcAccountId, lbfcId, bankAccount)
            .then((response) => {
              formikBag.setSubmitting(false);

              if (response.error) {
                defaultHandleError(formikBag.props);
                return;
              }

              enqueueSnackbar('Your payment method is successfully added', {
                variant: 'success',
                key: 'ddr',
              });
              resetAccountSetupAfterBankAccountUpdate();

              if (response.payload && response.payload.data && response.payload.data.requestedCreditLimit) {
                //start polling if credit limit change requested
                const { isNewLimitBasedOnSales, newAccountLimit, currentAccountLimit } = response.payload.data.requestedCreditLimit;
                if (isNewLimitBasedOnSales || newAccountLimit) {
                  startNewCreditLimitRequestPolling(
                    formikBag.props.dispatch,
                    enqueueSnackbar,
                    gcAccountId,
                    currentAccountLimit,
                    isNewLimitBasedOnSales,
                    newAccountLimit
                  );
                }
              }
            })
            .catch((error) => {
              handleError(error, formikBag.props);
              formikBag.setSubmitting(false);
            });
        } else {
          history.push({
            pathname: '/direct-debit-agreement',
            state: {
              selectedBankAccount: selectedAccount,
              lbfcId: lbfcId,
              select: selectBankAccount,
            },
          });
        }
      },
      displayName: 'AddBankSelectAccountForm',
    })(withStyles(styles)(withTheme(AddBankSelectAccountForm)))
  )
);
