// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  theme: any,
};

const GrowthIcon = withTheme((props: Props) => (
  <SvgIcon viewBox='0 0 46 56' {...(props: any)}>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-642.000000, -620.000000)' fill={props.theme.palette.primary.main} fillRule='nonzero'>
        <g transform='translate(642.000000, 620.000000)'>
          <path
            d='M1.17272727,45.6981132 L16.4181818,45.6981132 L16.4181818,53.6226415 L11.7272727,53.6226415 C11.0795933,53.6226415 10.5545455,54.1548313 10.5545455,54.8113208 C10.5545455,55.4678102 11.0795933,56 11.7272727,56 L31.2727273,56 C31.9204067,56 32.4454545,55.4678102 32.4454545,54.8113208 C32.4454545,54.1548313 31.9204067,53.6226415 31.2727273,53.6226415 L26.5818182,53.6226415 L26.5818182,45.6981132 L41.8272727,45.6981132 C42.4749521,45.6981132 43,45.1659234 43,44.509434 L43,37.3773585 C43,36.7208691 42.4749521,36.1886792 41.8272727,36.1886792 L2.34545455,36.1886792 L2.34545455,16.3773585 L13.6818182,16.3773585 C14.3294976,16.3773585 14.8545454,15.8451686 14.8545454,15.1886792 C14.8545454,14.5321898 14.3294976,14 13.6818182,14 L1.17272727,14 C0.525047884,14 0,14.5321898 0,15.1886792 L0,44.509434 C0,45.1659234 0.525047884,45.6981132 1.17272727,45.6981132 Z M24.2363636,53.6226415 L18.7636364,53.6226415 L18.7636364,45.6981132 L24.2363636,45.6981132 L24.2363636,53.6226415 Z M2.34545455,38.5660377 L40.6545455,38.5660377 L40.6545455,43.3207547 L2.34545455,43.3207547 L2.34545455,38.5660377 Z'
            id='Shape'
          />
          <path
            d='M15,34 L24,34 L24,26 L15,26 L15,34 Z M17.4545455,28.4 L21.5454545,28.4 L21.5454545,31.6 L17.4545455,31.6 L17.4545455,28.4 Z'
            id='Shape'
          />
          <path
            d='M46,4 L37,4 L37,34 L46,34 L46,4 Z M43.5454545,31.6315789 L39.4545455,31.6315789 L39.4545455,6.36842105 L43.5454545,6.36842105 L43.5454545,31.6315789 Z'
            id='Shape'
          />
          <path
            d='M26,34 L35,34 L35,15 L26,15 L26,34 Z M28.4545455,17.375 L32.5454545,17.375 L32.5454545,31.625 L28.4545455,31.625 L28.4545455,17.375 Z'
            id='Shape'
          />
          <path
            d='M9.50383866,23.4591751 C9.23256227,23.563959 9.0406663,23.8138493 9.00570106,24.1078587 C8.97073581,24.4018682 9.09848105,24.691397 9.33731148,24.8594389 C9.5761419,25.0274808 9.88657894,25.0462584 10.1431666,24.9081833 C14.8978555,22.7313633 19.2090377,19.6605117 22.8517593,15.8558629 C26.3595134,12.1996911 29.1887252,7.92411298 31.2020064,3.2367532 L32.465069,6.90704317 C32.5382427,7.19856727 32.7661375,7.42304472 33.0540742,7.48721595 C33.3420109,7.55138718 33.6407743,7.44428383 33.8262426,7.21040217 C34.0117109,6.97652052 34.0521833,6.65583642 33.9308453,6.38157866 L31.9193012,0.513891668 C31.8390725,0.297824781 31.6708718,0.12801156 31.4583802,0.0485519522 C31.2458885,-0.0309076557 31.0100723,-0.0121734598 30.8121723,0.0998893295 L25.5104281,3.17306054 C25.1358071,3.3929136 25.0066514,3.88125314 25.2219509,4.26379744 C25.4372503,4.64634175 25.9154753,4.77822908 26.2900964,4.55837605 L29.8141969,2.48836436 C27.8748909,7.04085325 25.1375078,11.1932996 21.7368337,14.7412413 C18.2307585,18.4050208 14.0809021,21.3624465 9.50383866,23.4591751 L9.50383866,23.4591751 Z'
            id='Path'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
));

export default GrowthIcon;
