// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  theme: any,
};

const PopupWidgetIcon = withTheme((props: Props) => (
  <SvgIcon style={{ height: '121', width: '116' }} viewBox='0 0 116 121' {...(props: any)}>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path
        d='M17.6472083,12.0490128 C17.6472083,17.4392148 9.56177928,17.4392148 9.56177928,12.0490128 C9.56177928,6.65881075 17.6472083,6.65881075 17.6472083,12.0490128'
        id='Path'
        fill='#EEEEEE'
        fillRule='nonzero'
      />
      <path
        d='M28.6184828,12.0490128 C28.6184828,17.4392148 20.5281436,17.4392148 20.5281436,12.0490128 C20.5281436,6.65881075 28.6184828,6.65881075 28.6184828,12.0490128'
        id='Path'
        fill='#EEEEEE'
        fillRule='nonzero'
      />
      <path
        d='M39.5859803,12.0490128 C39.5859803,17.4392148 31.5005513,17.4392148 31.5005513,12.0490128 C31.5005513,6.65881075 39.5859803,6.65881075 39.5859803,12.0490128'
        id='Path'
        fill='#EEEEEE'
        fillRule='nonzero'
      />
      <polygon
        id='Path'
        fill='#EEEEEE'
        fillRule='nonzero'
        points='85.7965254 10.0823923 103.880117 10.0823923 103.880117 14.0168923 85.7965254 14.0168923'
      />
      <path
        d='M7.89846154,0 C3.54105,0 0,3.54105 0,7.89846154 L0,111.493801 C0,115.846302 3.54105,119.392263 7.89846154,119.392263 L107.514965,119.392263 C111.867467,119.392263 115.413427,115.851213 115.413427,111.493801 L115.413427,58.210959 L115.408508,43.8604211 L115.408508,7.89972058 C115.408508,3.54230904 111.867458,0.00125904 107.514957,0.00125904 L7.89846154,0 Z M111.474017,18.6791174 L3.9343741,18.6791174 L3.9343741,7.89858744 C3.9343741,5.71490846 5.7098723,3.9346259 7.89833563,3.9346259 L107.514839,3.9346259 C109.698518,3.9346259 111.478801,5.71012411 111.478801,7.89858744 L111.474017,18.6791174 Z'
        id='Shape'
        fill='#EEEEEE'
        fillRule='nonzero'
      />
      <path
        d='M111.474017,33.0447638 L111.474017,111.493297 C111.474017,113.676976 109.698518,115.457259 107.510055,115.457259 L7.89858744,115.457259 C5.70999821,115.457259 3.9346259,113.681761 3.9346259,111.498208 L3.9346259,22.6134915 L111.479305,22.6134915 L111.474017,33.0447638 Z'
        id='shape'
        fill='#EEEEEE'
        fillRule='nonzero'
      />
      <path
        d='M4.7889849,22.8882677 L110.788985,22.8882677 L110.788985,111.888268 C110.788985,113.545122 109.445839,114.888268 107.788985,114.888268 L7.7889849,114.888268 C6.13213065,114.888268 4.7889849,113.545122 4.7889849,111.888268 L4.7889849,22.8882677 L4.7889849,22.8882677 Z'
        id='Rectangle'
        fill='#FFFFFF'
        fillRule='nonzero'
      />
      <rect id='Rectangle' fill='#DADADA' fillRule='nonzero' opacity='0.347224746' x='14.7889849' y='32.8882677' width='84' height='5' rx='2.5' />
      <rect id='Rectangle' fill='#DADADA' fillRule='nonzero' opacity='0.347224746' x='14.7889849' y='40.8882677' width='84' height='5' rx='2.5' />
      <rect id='Rectangle' fill='#DADADA' fillRule='nonzero' opacity='0.347224746' x='14.7889849' y='65.8882677' width='84' height='5' rx='2.5' />
      <rect id='Rectangle' fill='#DADADA' fillRule='nonzero' opacity='0.347224746' x='14.7889849' y='73.8882677' width='84' height='5' rx='2.5' />
      <rect id='Rectangle' fill='#DADADA' fillRule='nonzero' opacity='0.347224746' x='14.7889849' y='48.8882677' width='84' height='5' rx='2.5' />
      <rect id='Rectangle' fill='#DADADA' fillRule='nonzero' opacity='0.347224746' x='14.7889849' y='57.8882677' width='84' height='5' rx='2.5' />
      <rect id='Rectangle' fill='#DADADA' fillRule='nonzero' opacity='0.347224746' x='14.7889849' y='90.8882677' width='46' height='5' rx='2.5' />
      <rect id='Rectangle' fill='#DADADA' fillRule='nonzero' opacity='0.347224746' x='14.7889849' y='98.8882677' width='46' height='5' rx='2.5' />
      <rect id='Rectangle' fill='#DADADA' fillRule='nonzero' opacity='0.347224746' x='64.7889849' y='90.8882677' width='34' height='5' rx='2.5' />
      <rect id='Rectangle' fill='#DADADA' fillRule='nonzero' opacity='0.347224746' x='14.7889849' y='82.8882677' width='46' height='5' rx='2.5' />
      <rect id='Rectangle' fill='#DADADA' fillRule='nonzero' opacity='0.347224746' x='64.7889849' y='82.8882677' width='34' height='5' rx='2.5' />
      <rect id='Rectangle' fill='#DADADA' fillRule='nonzero' opacity='0.347224746' x='64.7889849' y='98.8882677' width='34' height='5' rx='2.5' />
      <rect
        id='Rectangle'
        stroke='#80DBE0'
        strokeWidth='2.1875'
        fill='#CCF1F3'
        strokeLinecap='round'
        strokeLinejoin='round'
        x='30.65625'
        y='45.175'
        width='52.5'
        height='39.375'
      />
      <line
        x1='75.5'
        y1='49.55'
        x2='78.78125'
        y2='52.83125'
        id='Path'
        stroke='#80DBE0'
        strokeWidth='2.1875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line
        x1='78.78125'
        y1='49.55'
        x2='75.5'
        y2='52.83125'
        id='Path'
        stroke='#80DBE0'
        strokeWidth='2.1875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        id='Rectangle'
        stroke='#80DBE0'
        strokeWidth='2.1875'
        strokeLinecap='round'
        strokeLinejoin='round'
        x='30.65625'
        y='45.175'
        width='52.5'
        height='12.03125'
      />
      <line x1='38.3125' y1='65.95625' x2='52.53125' y2='65.95625' id='Path' stroke='#B3E9EC' strokeWidth='2.1875' strokeLinecap='round' />
      <line x1='38.3125' y1='51.7375' x2='52.53125' y2='51.7375' id='Path' stroke='#B3E9EC' strokeWidth='2.1875' strokeLinecap='round' />
      <line x1='38.3125' y1='71.425' x2='75.5' y2='71.425' id='Path' stroke='#B3E9EC' strokeWidth='2.1875' strokeLinecap='round' />
      <line x1='38.3125' y1='76.89375' x2='75.5' y2='76.89375' id='Path' stroke='#B3E9EC' strokeWidth='2.1875' strokeLinecap='round' />
    </g>
  </SvgIcon>
));

export default PopupWidgetIcon;
