// @flow
import React from 'react';
import type { Match } from 'react-router';

import { AddBankConnectForm } from '../../components/AddBankConnectForm';
import { MultiFactorAuthenticationForm } from '../../components/MultiFactorAuthenticationForm';
import CardContentSecurity from '../../components/CardContentSecurity';
import SetupPageLayout from '../../components/SetupPageLayout';

type Props = {
  match: Match,
};

const AddBankLogin = (props: Props) => {
  return (
    <SetupPageLayout
      title='Add a payment method'
      subtitle='Enter your credentials below, as you would for online banking. Your details are encrypted and processed over secure servers.'
      footer={<CardContentSecurity />}
    >
      <AddBankConnectForm bankId={props.match.params.bankId} />
      <MultiFactorAuthenticationForm />
    </SetupPageLayout>
  );
};

export default AddBankLogin;

// todo move get credentials here to prefill properly
