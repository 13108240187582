// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export type BankAccount = {
  accountName: string,
  bsbNumber: string,
  accountNumber: string,
};

export type BankAccountsProps = {
  accounts: BankAccount[],
};

const useStyles = makeStyles((theme) => ({
  table: {
    width: 'auto',
    padding: theme.spacing(0, 0, 1),
  },
  cell: {
    padding: theme.spacing(0, 8, 1, 0),
    border: 0,
    fontSize: theme.typography.body1.fontSize,
  },
  header: {
    fontWeight: 400,
  },
  row: {
    fontWeight: 500,
  },
  status: {
    color: theme.palette.success.main,
  },
}));

export const BankAccounts = (props: BankAccountsProps) => {
  const classes = useStyles();
  const header = clsx(classes.header, classes.cell);
  const row = clsx(classes.row, classes.cell);
  const status = clsx(row, classes.status);

  return (
    <Grid container>
      {props.accounts ? props.accounts.map((account) => (
          <Grid key={`${account.accountName}`} item>
            <Grid container>
              <Grid item xs={12} sm>
                <Typography className={header}>Account name</Typography>
                <Typography className={row}>{account.accountName}</Typography>
              </Grid>
              <Grid item>
                <Typography className={header}>BSB</Typography>
                <Typography className={row}>{account.bsbNumber}</Typography>
              </Grid>
              <Grid item>
                <Typography className={header}>Account</Typography>
                <Typography className={row}>{account.accountNumber}</Typography>
              </Grid>
              <Grid item>
                <Typography className={header}>Status</Typography>
                <Typography className={status}>Successfully connected</Typography>
              </Grid>
            </Grid>
          </Grid>
        )) : null}
    </Grid>
  );
};
