// @flow
import { PARTITION } from './constants';
import type { ReduxState } from './reducer';

const selectPartition = (state: any): ReduxState => state[PARTITION];
const selectRepaymentDay = (state: any) => selectPartition(state).repaymentDay;
const selectDirectDebitAccount = (state: any) => selectPartition(state).directDebitAccount;
const selectSmsReminder = (state: any) => selectPartition(state).smsReminder;
const selectCurrentAccountLimit = (state: any) => selectPartition(state).currentAccountLimit;
const selectCreditLimitPendingRequest = (state: any) => selectPartition(state).creditLimitPendingRequest;
const selectHasNewCreditLimitPendingRequest = (state: any) => selectPartition(state).hasNewCreditLimitPendingRequest;
const selectPreApprovalDetail = (state: any) => selectPartition(state).preApprovalDetail;

export default {
  selectPartition,
  selectRepaymentDay,
  selectDirectDebitAccount,
  selectSmsReminder,
  selectCurrentAccountLimit,
  selectCreditLimitPendingRequest,
  selectHasNewCreditLimitPendingRequest,
  selectPreApprovalDetail,
};
