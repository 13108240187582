// @flow
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import { Button, FormField } from '@shiftfinancial/design-system';
import { Field, Form, withFormik } from 'formik';
import type { FormikBag } from 'formik';
import { withSnackbar } from 'notistack';
import React from 'react';
import { withRouter } from 'react-router';

import { formSchema } from './validations';
import { LINKS } from '../../constants';
import { formatMobileNumber } from '../../lib/formatter';
import FormikPhoneNumberField from '../formik/FormikPhoneNumberField';

const styles = (theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 2, 4),
      margin: '0 auto',
      maxWidth: '420px',
    },
    padding: theme.spacing(1, 2, 0),
  },
  loginForm: {
    width: '100%',
  },
  label: {
    [theme.breakpoints.up('xs')]: {
      color: theme.palette.grey.light,
    },
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.common.black,
    },
  },
  inputBase: {
    margin: theme.spacing(1, 0, 0),
  },
  callUs: {
    color: theme.palette.grey.light,
    fontWeight: 400,
  },
  register: {
    [theme.breakpoints.up('xs')]: {
      color: theme.palette.grey.light,
    },
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.common.black,
    },
  },
});

type FormValues = {
  mobileNumber: string,
};

type LoginFormProps = {
  classes: any,
  isSubmitting: boolean,
  values: FormValues,
  configuration: { registrationUiUrl: string },
};

const LoginForm = ({ classes, isSubmitting, values, configuration }: LoginFormProps) => {
  return (
    <Grid className={classes.container} container>
      <Grid item xs={12}>
        <Form className={classes.loginForm} noValidate autoComplete='off'>
          <Grid item xs={12}>
            <FormField label='What is your mobile number?' fieldId='mobileNumber'>
              {({ fieldId }) => (
                <Field
                  component={FormikPhoneNumberField}
                  variant='outlined'
                  id={fieldId}
                  name={fieldId}
                  fullWidth
                  pattern='^4[0-9]{2} [0-9]{3} [0-9]{3}'
                  format='### ### ###'
                  InputProps={{
                    classes: {
                      root: classes.inputBase,
                    },
                  }}
                  autoFocus
                  data-testid='uia-mobileNumber'
                  value={values.mobileNumber}
                />
              )}
            </FormField>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth appearance='primary' type='submit' disabled={isSubmitting} data-testid='uia-sendCode'>
              Send verification code via SMS
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box display='flex' justifyContent='center' marginY={2}>
              <Typography className={classes.callUs} variant='h6' component='p'>
                Trouble logging in? Call us on 1300 249 649.
              </Typography>
            </Box>
          </Grid>

          {configuration && configuration.registrationUiUrl ? (
            <Grid item xs={12}>
              <Box display='flex' justifyContent='center' mt={6}>
                <Typography className={classes.register} variant='h6' component='p'>
                  <Link href={LINKS.SHIFT_TRADE_LEARN_MORE} target='_blank'>
                    Learn more{' '}
                  </Link>
                  about Shift Trade
                </Typography>
              </Box>
              <Box display='flex' justifyContent='center' mt={1}>
                <Typography className={classes.register} variant='h6' component='p'>
                  New to Shift Trade?{' '}
                  <Link
                    href={configuration.registrationUiUrl + '?referrer_account=8691268295fc53edec65e954a1db69cdf31d693a65609a147813db7421cdb7be'}
                    target='_blank'
                  >
                    Register here
                  </Link>
                </Typography>
              </Box>
            </Grid>
          ) : null}
        </Form>
      </Grid>
    </Grid>
  );
};

type LoginFormWrapperProps = {
  ...LoginFormProps,
  setError: (error: ?string) => void,
  setInputMobileNumberValue: (mobileNumber: ?string) => void,
  getPinByMobileNumber: (mobileNumber: string) => Promise<any>,
};

export default withRouter<LoginFormWrapperProps>(
  withSnackbar(
    withFormik({
      mapPropsToValues: (): FormValues => ({
        mobileNumber: '',
      }),
      validateOnBlur: false,
      validationSchema: formSchema,
      handleSubmit: (values: FormValues, formikBag: FormikBag<LoginFormWrapperProps, FormValues>) => {
        const { props } = formikBag;
        let { mobileNumber } = values;
        if (mobileNumber[0] !== '0') {
          mobileNumber = `0${mobileNumber}`;
        }

        props.setError(null);
        props.setInputMobileNumberValue(mobileNumber);
        props.getPinByMobileNumber(mobileNumber).then((response) => {
          formikBag.setSubmitting(false);
          if (response.error) {
            const { payload } = response;
            if (payload.response && payload.response.status === 400 && payload.response && payload.response.data && payload.response.data.messages) {
              const { messages } = payload.response.data;
              if (messages && Array.isArray(messages) && messages.length > 0) {
                const unregisteredMessage = messages.find((message) => message === "That phone number doesn't seem to be registered with us.");

                if (unregisteredMessage) {
                  const formattedMobileNumber = formatMobileNumber(mobileNumber, true);
                  const message =
                    `The number ${formattedMobileNumber} is not registered with Shift Trade. ` +
                    'If new to Shift Trade, click on "Register here" link below.';
                  props.setError(message);
                } else {
                  props.setError(messages[0]);
                }
              }
            }
          }
        });
      },
      displayName: 'LoginForm',
    })(withStyles(styles)(LoginForm))
  )
);
