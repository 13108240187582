// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { type Location, useLocation, Redirect } from 'react-router';

import MessagePageLayout from '../components/MessagePageLayout';
import { SuccessOutlinedIcon, TriangularWarningIcon, InfoIcon, AlertIcon } from '../components/svg';

const useStyles = makeStyles((theme) => ({
  statusCodeHeader: {
    fontSize: '120px',
    fontWeight: 500,
  },
  pageTitle: {
    fontSize: '20px',
    fontWeight: 500,
    padding: theme.spacing(2, 0),
  },
  paragraphs: {
    padding: theme.spacing(2, 0, 5, 0),
  },
  paragraph2: {
    margin: theme.spacing(2, 0, 4, 0),
  },
  icon: {
    width: 54,
    height: 54,
    alignSelf: 'inherit !important',
    margin: theme.spacing(4, 0, 1, 0),
  },
}));

const MessagePage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const location: Location = useLocation();

  if (!location.state) {
    return <Redirect to='/404' />;
  }

  const { variant, title, paragraph1, paragraph2, backButtonLabel, backButtonRoute } = location.state;

  let icon;
  switch (variant) {
    case 'success':
      icon = <SuccessOutlinedIcon className={classes.icon} />;
      break;
    case 'info':
      icon = <InfoIcon className={classes.icon} />;
      break;
    case 'warning':
      icon = <TriangularWarningIcon className={classes.icon} />;
      break;
    case 'error':
      icon = <AlertIcon className={classes.icon} htmlColor={theme.palette.error.main} viewBox='0 0 26 26' />;
      break;
    default:
      return <Redirect to='/404' />;
  }

  return (
    <MessagePageLayout backButtonLabel={backButtonLabel} backButtonRoute={backButtonRoute}>
      {icon}
      <Typography variant='h2' className={classes.pageTitle}>
        {title}
      </Typography>
      <div className={classes.paragraph}>
        <Typography variant='body1' component='p' paragraph align='center'>
          {paragraph1}
        </Typography>
        {paragraph2 ? <Typography variant='body1' component='p' paragraph align='center' className={classes.paragraph2}>
            {paragraph2}
          </Typography> : null}
      </div>
    </MessagePageLayout>
  );
};

export default MessagePage;
