// @flow
import React from 'react';
import type { FormikState, FormikHelpers } from 'formik';

import type { Field } from './types';
import AbnAutoSuggest from '../AbnAutoSuggest';
import FormikField from './FormikField';

type Props = {
  field: Field,
  form: FormikState<any> & FormikHelpers<any>,
  onSuggestionSelected: (suggestion: any) => void,
  delay: number,
};

const FormikAbnAutoSuggestField = ({ field, form, onSuggestionSelected, delay, ...other }: Props) => (
  <FormikField field={field} form={form}>
    <AbnAutoSuggest
      fieldValue={field.value}
      onSuggestionSelected={(_e, { suggestion }) => {
        form.setFieldValue(field.name, suggestion, true);
        if (onSuggestionSelected) {
          onSuggestionSelected(suggestion);
        }
      }}
      delay={delay}
      {...(other: any)}
    />
  </FormikField>
);

export default FormikAbnAutoSuggestField;
