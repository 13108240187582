import { createAction } from 'redux-actions';
import { actionTypes } from './constants';

const addCount = createAction(actionTypes.ADD_COUNT, (values) => values);

const subtractCount = createAction(actionTypes.SUBTRACT_COUNT, (values) => values);

export default {
  addCount,
  subtractCount,
};
