// @flow
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import NumberFormat from 'react-number-format';
type InputTypes = 'text' | 'number' | 'tel' | 'password';

type NumberFormatWrapperProps = {
  inputRef: ((el: HTMLInputElement) => void) | React.Ref<any>,
  onChange: (event: any) => void,
  format: string,
  pattern: string,
  readOnly: boolean,
  value: string,
  autoFocus: boolean,
  type: InputTypes,
};

const NumberFormatWrapper = ({ inputRef, onChange, format, pattern, readOnly, value, autoFocus, type, ...other }: NumberFormatWrapperProps) => {
  const handleValueChange = (values) => {
    if (onChange && values) {
      onChange({ target: { value: values.value } });
    }
  };

  return (
    <NumberFormat
      required
      pattern={pattern}
      format={format}
      getInputRef={inputRef}
      onValueChange={handleValueChange}
      readOnly={readOnly}
      autoFocus={autoFocus}
      value={value}
      type={type}
      // $FlowFixMe
      {...other}
    />
  );
};

NumberFormatWrapper.defaultProps = {
  readOnly: false,
  value: null,
  autoFocus: false,
  onChange: undefined,
  other: undefined,
};

type NumberFormatInputProps = {
  fullWidth: boolean,
  onChange: (event: any) => void,
  format: string,
  pattern: string,
  disabled: boolean,
  value: string,
  startAdornment: React.Node,
  ref: any,
  readOnly: boolean,
  onKeyPress: (event: SyntheticKeyboardEvent<any>) => void,
  onKeyUp: (event: SyntheticKeyboardEvent<any>) => void,
  autoFocus: boolean,
  type: InputTypes,
  InputProps: any,
  error: boolean,
};

const NumberFormatInput = ({
  fullWidth,
  onChange,
  format,
  pattern,
  disabled,
  readOnly,
  value,
  startAdornment,
  ref,
  onKeyPress,
  onKeyUp,
  autoFocus,
  type,
  InputProps,
  error,
  ...other
}: NumberFormatInputProps) => (
  <TextField
    fullWidth={fullWidth}
    variant='outlined'
    onChange={onChange}
    onKeyPress={onKeyPress}
    onKeyUp={onKeyUp}
    inputRef={ref}
    InputProps={{
      inputComponent: NumberFormatWrapper,
      inputProps: {
        format,
        pattern,
        readOnly,
        value,
        type,
        // $FlowFixMe
        ...other,
      },
      startAdornment,
      ...InputProps,
    }}
    autoFocus={autoFocus}
    readOnly={readOnly}
    disabled={disabled}
    error={error}
    {...(other: any)}
  />
);

NumberFormatInput.defaultProps = {
  fullWidth: true,
  disabled: false,
  value: null,
  startAdornment: null,
  ref: null,
  readOnly: false,
  onKeyPress: null,
  onKeyUp: null,
  autoFocus: false,
  type: 'text',
  onChange: undefined,
  other: undefined,
  InputProps: undefined,
};

export default NumberFormatInput;
