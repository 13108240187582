/* eslint-disable flowtype/no-types-missing-file-annotation */
import { createActions } from 'redux-actions';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';

const ACTION_PREFIX = 'EXTENDABLE_INVOICES';

export const actionTypes = {
  GET_EXTENDABLE_INVOICES_PENDING: 'GET_EXTENDABLE_INVOICES_PENDING',
  GET_EXTENDABLE_INVOICES_FULFILLED: 'GET_EXTENDABLE_INVOICES_FULFILLED',
  GET_EXTENDABLE_INVOICES_REJECTED: 'GET_EXTENDABLE_INVOICES_REJECTED',
};

export const { getExtendableInvoicesPending, getExtendableInvoicesFulfilled, getExtendableInvoicesRejected } = createActions(
  actionTypes.GET_EXTENDABLE_INVOICES_PENDING,
  actionTypes.GET_EXTENDABLE_INVOICES_FULFILLED,
  actionTypes.GET_EXTENDABLE_INVOICES_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getExtendableInvoices = (gcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(getExtendableInvoicesPending());

  return shiftPaymentsApi
    .getExtendableInvoices(gcAccountId)
    .then((response) => {
      return dispatch(getExtendableInvoicesFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getExtendableInvoicesRejected(error));
    });
};

export default {
  getExtendableInvoices,
};
