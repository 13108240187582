// @flow
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SupplierProfile from './SupplierProfile';
import { defaultHandleError } from '../../../lib/apiHelpers';
import actions from './actions';
import initialDataSelectors from '../../../components/InitialData/selectors';
import { type Supplier } from '../../../types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

type Props = {
  onExistingSupplierSelected: (supplier: Supplier) => void,
};

const SupplierSearch = (props: Props) => {
  const { onExistingSupplierSelected } = props;
  const [supplier, setSupplier] = useState<Supplier>({ abn: '', gcAccountId: '', accountName: '' });
  const [supplierBankAccount, setSupplierBankAccount] = useState<SupplierBankAccount | null>(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const supplierGcAccountId = history.location.state && history.location.state.supplierGcAccountId;
  const activeGcAccountId = useSelector(initialDataSelectors.selectSelectedGcAccountId);

  useEffect(() => {
    if (!supplierGcAccountId) {
      return;
    }

    dispatch(actions.getSupplier(activeGcAccountId, supplierGcAccountId))
      .then((response) => {
        const { payload, error } = response;

        if (!error && payload.data.suppliers.length > 0) {
          const selectedSupplier = payload.data.suppliers[0];
          setSupplier(selectedSupplier);
          onExistingSupplierSelected(selectedSupplier);
        }
      })
      .catch(() => {
        defaultHandleError({ enqueueSnackbar }, undefined);
      });

    dispatch(actions.getSupplierBankAccount(activeGcAccountId, supplierGcAccountId))
      .then((response) => {
        const { payload, error } = response;

        if (!error) {
          setSupplierBankAccount(payload.data);
        }
      })
      .catch(() => {
        defaultHandleError({ enqueueSnackbar }, undefined);
      });
  }, [supplierGcAccountId]);

  return (
    <>
      {supplierGcAccountId && supplierBankAccount ? (
        <SupplierProfile
          abn={supplier.abn}
          accountName={supplier.accountName}
          bsb={supplierBankAccount.bsb}
          bankAccountNumber={supplierBankAccount.accountNumber}
        />
      ) : null}
    </>
  );
};

export default SupplierSearch;
