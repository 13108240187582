// @flow
import React, { useState, useMemo } from 'react';
import { Breadcrumbs, FormControl, Grid, Link, MenuItem, Paper, Tabs, Tab, Typography, Select } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import FormItem from '../../../components/FormItem';
import TabPanel from '../../../components/TabPanel';
import TabLabel from '../../../components/TabLabel';
import BasicPageLayout from '../../../components/BasicPageLayout';
import { ExplainerWidget } from '../../../components/widgets/ExplainerWidget';
import { OpenAccountWidget } from '../../../components/widgets/OpenAccountWidget';
import { formatSupplierTerm } from '../../../lib/formatter';
import { useSupplierTermFeeLookup, useNotAuthorizedWidgets } from './hooks';
import { widgetNames } from './constants';
import initialDataSelectors from '../../../components/InitialData/selectors';
import AlertWarning from '../../../components/AlertWarning';
import { DEFAULT_TERM_PERIOD } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  container: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    padding: theme.spacing(4, 5),
  },
  marginBetweenContents: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  breadcrumblink: {
    cursor: 'pointer',
  },
  breadcrumb: {
    fontSize: '12px',
  },
  terms: {
    display: 'flex',
    width: theme.spacing(22),
  },
  alertContainer: {
    marginTop: theme.spacing(2),
  },
}));

const AccountOpeningWidget = () => {
  const classes = useStyles();
  const history = useHistory();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const gcAccountId = useSelector(initialDataSelectors.selectSelectedGcAccountId);
  // $FlowFixMe
  const termFeeLookup = useSupplierTermFeeLookup(gcAccountId);
  // $FlowFixMe
  const notAuthorizedWidgets = useNotAuthorizedWidgets(gcAccountId);
  const [term, setTerm] = useState(-1);
  const [termPeriod, setTermPeriod] = useState(DEFAULT_TERM_PERIOD);
  const [termValue, setTermValue] = useState(' ');
  const authorizedWidgets = useMemo(() => {
    const tabs = [];
    const tabPanels = [];

    if (notAuthorizedWidgets.isLoading) {
      return { tabs, tabPanels };
    }

    if (!notAuthorizedWidgets.names.includes(widgetNames.OpenAccountButton)) {
      tabs.push({ label: 'Button', dataTestId: 'uia-OpenAccountTab' });
      tabPanels.push(<OpenAccountWidget term={term} termPeriod={termPeriod} />);
    }

    if (!notAuthorizedWidgets.names.includes(widgetNames.Explainer)) {
      tabs.push({ label: 'Landing page', dataTestId: 'uia-ExplainerWidgetTab' });
      tabPanels.push(<ExplainerWidget term={term} termPeriod={termPeriod} />);
    }

    return { tabs, tabPanels };
  }, [gcAccountId, notAuthorizedWidgets, term, termPeriod, activeTabIndex]);

  const handleClick = () => {
    history.push({
      pathname: '/supplier/resources',
    });
  };

  const handleTabChange = (e, tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  const handleTermChange = (event) => {
    if (event.target.value) {
      setTerm(event.target.value.term);
      setTermPeriod(event.target.value.termPeriod);
      setTermValue(event.target.value);
    }
  };

  return (
    <BasicPageLayout noHeaderButGap={true}>
      <Paper className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
              <Link color='inherit' className={classes.breadcrumblink} onClick={handleClick}>
                <Typography className={classes.breadcrumb}>Get started</Typography>
              </Link>
              <Typography className={classes.breadcrumb}>Website assets</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} className={classes.marginBetweenContents}>
            <Typography variant='h3' component='h3'>
              Website assets
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' component='label'>
              Embed your unique registration link or landing page to help educate and onboard customers to your new Trade Account.
            </Typography>
          </Grid>
          {authorizedWidgets.tabs.length > 0 && (
            <>
              <Grid item xs={4} className={classes.marginBetweenContents}>
                <FormItem label='Terms'>
                  <FormControl className={classes.terms} variant='outlined'>
                    <Select id='term' name='term' data-testid='uia-term' onChange={handleTermChange} value={termValue} disableUnderline>
                      <MenuItem value=' '>
                        <Typography variant='body1'>Choose terms</Typography>
                      </MenuItem>
                      {termFeeLookup.map((x, index) => (
                        <MenuItem key={index} value={x}>
                          {formatSupplierTerm(x.term, x.termPeriod)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormItem>
              </Grid>
              <Grid item xs={12} className={classes.marginBetweenContents}>
                <Tabs value={activeTabIndex} onChange={handleTabChange} indicatorColor='primary'>
                  {authorizedWidgets.tabs.map((x) => (
                    <Tab key={x.label} label={<TabLabel label={x.label} showBadge={false} />} className={classes.tab} data-testid={x.dataTestId} />
                  ))}
                </Tabs>
                {authorizedWidgets.tabPanels.map((x, index) => (
                  <TabPanel key={index} tabIndex={index} activeTabIndex={activeTabIndex}>
                    {x}
                  </TabPanel>
                ))}
              </Grid>
            </>
          )}
          {!notAuthorizedWidgets.isLoading && !authorizedWidgets.tabs.length && (
            <Grid item xs={12} data-testid='uia-alert' className={classes.alertContainer}>
                          <AlertWarning message='No widgets available. For assistance, please call on 1300 249 649.' />
            </Grid>
          )}
        </Grid>
      </Paper>
    </BasicPageLayout>
  );
};

export default AccountOpeningWidget;
