/* eslint-disable react/prop-types */
import React from 'react';
import TextField from '@material-ui/core/TextField';

function CustomSearchRender(props) {
  const handleTextChange = (event) => {
    props.onSearch(event.target.value);
  };

  return (
    <TextField
      variant='outlined'
      placeholder={'Search...'}
      value={props.searchText || ''}
      onChange={handleTextChange}
      fullWidth={true}
      data-testid='uia-searchbar'
    />
  );
}

export default CustomSearchRender;
