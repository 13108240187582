import identityApi from '../api/identityApi';
import startupConfig from './startupConfig';
import Auth from '../api/auth';

import { handleError } from './apiHelpers';
import { PORTAL_TYPE } from '../constants';

export const getSingleSignOnUrlAsync = async (gcAccountId, portalType, useNewAccessToken = false, isSetupSettlementAccount = false) => {
  const config = startupConfig.get();
  const authorization = Auth.ssoConfiguration();
  let ssoUrl;
  if (portalType === PORTAL_TYPE.SHIFTONLINE) {
    ssoUrl = config.shiftOnlineUrl;
  }
  try {
    let createTokenAliasRequestBody;
    if (useNewAccessToken) {
      const getTokenByRefreshTokenResponse = await identityApi.getTokenByRefreshToken();
      if (getTokenByRefreshTokenResponse && getTokenByRefreshTokenResponse.data) {
        createTokenAliasRequestBody = getTokenByRefreshTokenResponse.data.token;
      }
    } else {
      createTokenAliasRequestBody = authorization;
    }

    const createTokenAliasResponse = await identityApi.createTokenAlias(createTokenAliasRequestBody);
    if (createTokenAliasResponse && createTokenAliasResponse.data) {
      if (isSetupSettlementAccount) {
        return ssoUrl + `?_referrer=registration&account_type=vendor&_a=${gcAccountId}&_ta=${createTokenAliasResponse.data.tokenAlias}`;
      } else {
        return ssoUrl + `?_referrer=gco&_a=${gcAccountId}&_ta=${createTokenAliasResponse.data.tokenAlias}`;
      }
    }
  } catch (error) {
    handleError(error);
    return ssoUrl;
  }
};
