// @flow
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import shiftPaymentsApi from '../../api/shiftPaymentsApi';
import { defaultHandleError } from '../../lib/apiHelpers';

export type SupplierTermFee = {
  term: number,
  termPeriod: string,
  feeInPercentage: number,
};

export const useSupplierTermFeeLookup = (gcAccountId: string): Array<SupplierTermFee> => {
  const [lookup, setLookup] = useState<Array<SupplierTermFee>>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    shiftPaymentsApi
      .getSupplierTermFeeLookup(gcAccountId)
      .then((response) => {
        setLookup(response.data);
      })
      .catch(() => {
        defaultHandleError({ enqueueSnackbar }, undefined);
      });
  }, [gcAccountId]);

  return lookup;
};
