// @flow
import moment from 'moment';

const clone = (date: moment) => moment(date.toDate());

export const addDays = (date: ?moment, days: number) => {
  return date ? clone(date).add(days, 'days') : null;
};

const isSunday = (date: moment) => {
  return date.day() === 0;
};

const isSaturday = (date: moment) => {
  return date.day() === 6;
};

export const clampToWeekday = (date: ?moment) => {
  if (!date) {
    return date;
  }

  if (isSunday(date)) {
    return addDays(date, 1);
  }

  if (isSaturday(date)) {
    return addDays(date, 2);
  }
  return date;
};

/* istanbul ignore next */
export const getTimezoneOffset = () => {
  const date = new Date();
  const parts = date.toLocaleString('ja', { timeZone: 'Australia/Sydney' }).split(/[/\s:]/);
  parts[1] = (parseInt(parts[1], 10) - 1).toString();
  const t1 = Date.UTC.apply(null, parts);
  const t2 = new Date(date).setMilliseconds(0);
  return (t1 - t2) / 60 / 1000 / 60;
};
