// @flow
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useAbility } from '@casl/react';

import { ROUTES, Permission } from '../../constants';
import { selectActiveProfileModeBasedPath } from '../../lib/profileHelper';
import { type AccountSetupStatus } from './reducer';
import { AbilityContext } from '../../lib/ability';
import { type Account } from '../../types';
import { ROLE_TYPE } from '../../constants';

type Props = {
  isBankAccountSetupComplete: ?boolean,
  bankAccountSetup: AccountSetupStatus,
  isDirectDebitAgreementSetupComplete: ?boolean,
  isLoanAgreementSetupComplete: ?boolean,
  isTermsAndConditionsAgreementSetupComplete: ?boolean,
  isPrivacyAgreementSetupComplete: ?Boolean,
  isAccountSetupLoaded: boolean,
  gcAccountId: string,
  account: Account,
  openTradeAccount: any,
  getAccountSetup: (account: Account) => Promise<any>,
  updateOpenTradeAccount: ({ isOpenTradeAccount: boolean, attempt: number }) => void,
};

const AccountSetupCheck = ({
  isBankAccountSetupComplete,
  bankAccountSetup,
  isDirectDebitAgreementSetupComplete,
  isLoanAgreementSetupComplete,
  isTermsAndConditionsAgreementSetupComplete,
  isPrivacyAgreementSetupComplete,
  isAccountSetupLoaded,
  account,
  openTradeAccount,
  getAccountSetup,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const ability = useAbility(AbilityContext);

  useEffect(() => {
    if (!account || isAccountSetupLoaded) {
      return;
    }
    getAccountSetup(account).then((response) => {
      if (response.error) {
        history.push('/500');
      }
    });
  }, [account, isAccountSetupLoaded]);

  useEffect(() => {
    if (!account || !isAccountSetupLoaded) {
      return;
    }

    const isDirector = account.roles && account.roles.some((i) => i === ROLE_TYPE.DIRECTOR);
    const isARM = account.roles && account.roles.some((i) => i === ROLE_TYPE.ACCOUNT_RECEIVABLE_MANAGER);
    const isAPM = account.roles && account.roles.some((i) => i === ROLE_TYPE.ACCOUNT_PAYABLE_MANAGER);
    let isSupplierOpensTradeAccount = account.isSupplier && openTradeAccount.isOpenTradeAccount;
    if (!account.isBuyer && account.isSupplier && openTradeAccount.isOpenTradeAccount) {
      isSupplierOpensTradeAccount = true;
    }

    if ((account.isBuyer || (account.isSupplier && !isDirector) || isSupplierOpensTradeAccount) && !isTermsAndConditionsAgreementSetupComplete) {
      history.push({
        pathname: '/welcome',
        state: { gcAccountId: account.gcAccountId },
      });
      return;
    } else if (!account.isBuyer && account.isSupplier && isARM && !isDirector) {
      history.push('/supplier/payments');
      return;
    }

    if (ability.can(Permission.Actions.Sign, Permission.Buyer.PrivacyAgreement) && account.isBuyer && !isPrivacyAgreementSetupComplete) {
      history.push('/privacy-agreement');
      return;
    }

    if (
      account.isBuyer &&
      account.isSupplier &&
      ability.can(Permission.Actions.Sign, Permission.Buyer.PrivacyAgreement) &&
      !isPrivacyAgreementSetupComplete
    ) {
      history.push('/privacy-agreement');
      return;
    }

    if (ability.can(Permission.Actions.Sign, Permission.Buyer.DDRAgreement) && !isBankAccountSetupComplete) {
      if (bankAccountSetup.data && bankAccountSetup.data.length) {
        if (bankAccountSetup.data.findIndex((x) => x.isShiftBankAccount) > -1) {
          history.push('/direct-debit-agreement');
          return;
        } else if (account.isBuyer && !account.isSupplier) {
          history.push(selectActiveProfileModeBasedPath('/settings/add-bank/account-select'));
          return;
        }
      } else {
        if (account.isBuyer) {
          history.push(selectActiveProfileModeBasedPath(ROUTES.ADD_BANK));
          return;
        } else if (account.isSupplier && (isSupplierOpensTradeAccount || isTermsAndConditionsAgreementSetupComplete)) {
          history.push(selectActiveProfileModeBasedPath(ROUTES.ADD_BANK, true));
          return;
        }
      }
    }

    if (ability.can(Permission.Actions.Sign, Permission.Buyer.DDRAgreement) && isBankAccountSetupComplete && !isDirectDebitAgreementSetupComplete) {
      history.push('/direct-debit-agreement');
      return;
    }

    if (
      ability.can(Permission.Actions.Sign, Permission.Buyer.LoanAgreement) &&
      isDirectDebitAgreementSetupComplete &&
      !isLoanAgreementSetupComplete
    ) {
      history.push('/loan-agreement');
      return;
    }

    if (!account.isBuyer && account.isSupplier && !isSupplierOpensTradeAccount) {
      history.push('/supplier/payments');
      return;
    } else if (account.isBuyer && account.isSupplier && (isDirector || isAPM)) {
      history.push('/buyer/dashboard');
      return;
    } else if (account.isBuyer && account.isSupplier && (isDirector || isARM)) {
      history.push('/supplier/payments');
      return;
    }

    // if the user has already signed the T&C, then redirect the user to the dashboard
    if (
      !location.pathname ||
      location.pathname === '/' ||
      location.pathname === '/p' ||
      location.pathname === '/welcome' ||
      location.pathname === '/direct-debit-agreement' ||
      location.pathname === '/privacy-agreement' ||
      location.pathname === selectActiveProfileModeBasedPath('/settings/add-bank/account-select')
    ) {
      history.push('/buyer/dashboard');
      return;
    }
  }, [
    account,
    isAccountSetupLoaded,
    isTermsAndConditionsAgreementSetupComplete,
    isBankAccountSetupComplete,
    isDirectDebitAgreementSetupComplete,
    isLoanAgreementSetupComplete,
    isPrivacyAgreementSetupComplete,
    openTradeAccount,
  ]);

  return null;
};

export default AccountSetupCheck;
