import Auth from './auth';
import startupConfig from '../lib/startupConfig';
import { getCorrelationId } from '../lib/telemetryUtils';

const correlationId = getCorrelationId();

export type Method =
  | 'get'
  | 'GET'
  | 'delete'
  | 'DELETE'
  | 'head'
  | 'HEAD'
  | 'options'
  | 'OPTIONS'
  | 'post'
  | 'POST'
  | 'put'
  | 'PUT'
  | 'patch'
  | 'PATCH';

export type HttpRequestOptions = {
  url: string;
  apiVersion?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  contentType?: string;
  accept?: string;
  cacheControl?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers?: any;
  responseType?: ResponseType;
  sendAuthToken?: boolean;
  isPolling?: boolean;
  requestId?: string;
  method?: Method;
};

function sendRequest({
  url,
  method,
  apiVersion,
  data = undefined,
  contentType = 'application/json',
  accept = 'application/json',
  cacheControl = 'no-cache',
  headers = undefined,
  responseType = undefined,
  sendAuthToken = true,
  isPolling = false,
  requestId,
}: HttpRequestOptions) {
  const customHeaders = {
    'x-correlation-id': correlationId,
    ...headers,
  };

  if (apiVersion) {
    customHeaders['api-version'] = apiVersion;
  }

  if (contentType) {
    customHeaders['content-type'] = contentType;
  }

  if (accept) {
    customHeaders.accept = accept;
  }

  if (cacheControl) {
    customHeaders['cache-control'] = cacheControl;
  }

  if (sendAuthToken && Auth.isLoggedIn()) {
    customHeaders.Authorization = `Bearer ${Auth.getAccessToken()}`;
  }

  if (isPolling) {
    customHeaders['x-polling'] = '1';
  }

  if (requestId) {
    customHeaders['x-request-id'] = requestId;
  }

  const config = {
    url,
    method,
    body: JSON.stringify(data),
    headers: customHeaders,
    responseType,
  };

  return config;
}

export const getBaseUrl = (): string => {
  const config = startupConfig.get();
  return config ? config.shiftServiceUrl || '' : '';
};

function get(request: HttpRequestOptions) {
  return sendRequest({
    ...request,
    method: 'GET',
  });
}

function post(request: HttpRequestOptions) {
  return sendRequest({
    ...request,
    method: 'POST',
  });
}

export default {
  sendRequest,
  get,
  post,
};
