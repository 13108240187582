// @flow
import { useState, useEffect } from 'react';
import widgetsApi, { type WidgetType } from '../../../api/widgetsApi';
import { type EmbedWidget } from '../types';

export const useEmbedExplainer = (gcAccountId: ?string, term: number, termPeriod: string, widgetType: WidgetType): EmbedWidget => {
  const [embedWidget, setEmbedWidget] = useState<EmbedWidget>({ embeddableCode: '', isLoading: false });

  useEffect(() => {
    if (gcAccountId && term > -1 && widgetType) {
      setEmbedWidget({
        embeddableCode: '',
        isLoading: true,
      });
      widgetsApi.getEmbedExplainer(gcAccountId, term, termPeriod, widgetType).then((response) => {
        setEmbedWidget({
          embeddableCode: response.data,
          isLoading: false,
        });
      });
    } else {
      setEmbedWidget({
        embeddableCode: '',
        isLoading: false,
      });
    }
  }, [gcAccountId, term, termPeriod, widgetType]);

  return embedWidget;
};
