// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';
import abnSearchApi from '../../../api/abnSearchApi';
import registrationApi from '../../../api/registrationApi';

import { type BuyerRequestTypeEnum } from '../../../types';

const {
  searchCustomersPending,
  searchCustomersFulfilled,
  searchCustomersRejected,
  addCustomerPending,
  addCustomerFulfilled,
  addCustomerRejected,
  getSupplierTermFeeLookupPending,
  getSupplierTermFeeLookupFulfilled,
  getSupplierTermFeeLookupRejected,
  validateNewBuyerPending,
  validateNewBuyerFulfilled,
  validateNewBuyerRejected,
} = createActions(
  actionTypes.SEARCH_CUSTOMERS_PENDING,
  actionTypes.SEARCH_CUSTOMERS_FULFILLED,
  actionTypes.SEARCH_CUSTOMERS_REJECTED,
  actionTypes.ADD_CUSTOMER_PENDING,
  actionTypes.ADD_CUSTOMER_FULFILLED,
  actionTypes.ADD_CUSTOMER_REJECTED,
  actionTypes.GET_SUPPLIER_TERM_FEE_LOOKUP_PENDING,
  actionTypes.GET_SUPPLIER_TERM_FEE_LOOKUP_FULFILLED,
  actionTypes.GET_SUPPLIER_TERM_FEE_LOOKUP_REJECTED,
  actionTypes.GET_CONTACTS_PENDING,
  actionTypes.GET_CONTACTS_FULFILLED,
  actionTypes.GET_CONTACTS_REJECTED,
  actionTypes.VALIDATE_NEW_BUYER_PENDING,
  actionTypes.VALIDATE_NEW_BUYER_FULFILLED,
  actionTypes.VALIDATE_NEW_BUYER_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const searchCustomers = (search: string) => (dispatch: Dispatch<any>) => {
  dispatch(searchCustomersPending());

  return abnSearchApi
    .searchAbn(search)
    .then((response) => {
      return dispatch(searchCustomersFulfilled(response));
    })
    .catch((error) => {
      return dispatch(searchCustomersRejected(error));
    });
};

const searchRelatedCompany = (search: string) => (dispatch: Dispatch<any>) => {
  dispatch(searchCustomersPending());

  return registrationApi
    .searchCompany(search)
    .then((response) => {
      return dispatch(searchCustomersFulfilled(response));
    })
    .catch((error) => {
      return dispatch(searchCustomersRejected(error));
    });
};

const searchRelatedTrust = (search: string) => (dispatch: Dispatch<any>) => {
  dispatch(searchCustomersPending());

  return registrationApi
    .searchTrust(search)
    .then((response) => {
      return dispatch(searchCustomersFulfilled(response));
    })
    .catch((error) => {
      return dispatch(searchCustomersRejected(error));
    });
};

const addCustomer =
  (
    supplierGcAccountId: string,
    contactId: string,
    contactFirstName: string,
    contactLastName: string,
    mobileNumber: string,
    emailAddress: string,
    avgMonthlyOrderValue: number,
    termInDays: number,
    termPeriod: string,
    buyerAbnOrAcn: string,
    relatedEntityAbnOrAcn: string,
    requestType: BuyerRequestTypeEnum,
    isRelatedEntityATrustee: boolean,
    isAutoBillingEnabled: boolean
  ) =>
  (dispatch: Dispatch<any>) => {
    dispatch(addCustomerPending());

    return shiftPaymentsApi
      .addCustomer(
        supplierGcAccountId,
        contactId,
        contactFirstName,
        contactLastName,
        mobileNumber,
        emailAddress,
        avgMonthlyOrderValue,
        termInDays,
        termPeriod,
        buyerAbnOrAcn,
        relatedEntityAbnOrAcn,
        requestType,
        isRelatedEntityATrustee,
        isAutoBillingEnabled
      )
      .then((response) => {
        return dispatch(addCustomerFulfilled(response));
      })
      .catch((error) => {
        return dispatch(addCustomerRejected(error));
      });
  };

const getSupplierTermFeeLookup = (gcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(getSupplierTermFeeLookupPending(gcAccountId));

  return shiftPaymentsApi
    .getSupplierTermFeeLookup(gcAccountId)
    .then((response) => {
      return dispatch(getSupplierTermFeeLookupFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getSupplierTermFeeLookupRejected(error));
    });
};

const validateNewBuyer = (supplierGcAccountId: string, buyerAbnAcn: string, isTrustee: boolean) => (dispatch: Dispatch<any>) => {
  dispatch(validateNewBuyerPending());

  return shiftPaymentsApi
    .validateNewBuyer(supplierGcAccountId, buyerAbnAcn, isTrustee)
    .then((response) => {
      return dispatch(validateNewBuyerFulfilled(response));
    })
    .catch((error) => {
      return dispatch(validateNewBuyerRejected(error));
    });
};

const validateRelatedEntity =
  (supplierGcAccountId: string, buyerAbnOrAcn: string, relatedEntityAbnOrAcn: string, isRelatedEntityATrustee: boolean) =>
  (dispatch: Dispatch<any>) => {
    dispatch(validateNewBuyerPending());

    return shiftPaymentsApi
      .validateRelatedEntity(supplierGcAccountId, buyerAbnOrAcn, relatedEntityAbnOrAcn, isRelatedEntityATrustee)
      .then((response) => {
        return dispatch(validateNewBuyerFulfilled(response));
      })
      .catch((error) => {
        return dispatch(validateNewBuyerRejected(error));
      });
  };

export default {
  searchCustomers,
  addCustomer,
  getSupplierTermFeeLookup,
  validateNewBuyer,
  validateRelatedEntity,
  searchRelatedCompany,
  searchRelatedTrust,
};
