// @flow
import * as React from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { withRouter } from 'react-router-dom';
import type { History, Location } from 'history';

import { type StartupConfigValues } from '../../lib/startupConfig';
import { ai } from '../../lib/telemetryUtils';

type Props = {
  children: React.Node,
  history: History<Location>,
  configuration: StartupConfigValues,
  isConfigurationSet: boolean,
  mobileNumber: string,
};

type State = {
  initialized: boolean,
  initializedWithAuth: boolean,
};

// This component must be implemented as a class component to make it work with withAITracking HOC
class TelemetryProvider extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      initialized: false,
      initializedWithAuth: false,
    };
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.configuration !== this.props.configuration || prevProps.mobileNumber !== this.props.mobileNumber) {
      this.initialize();
    }
  }

  initialize() {
    const { history, configuration, isConfigurationSet, mobileNumber } = this.props;
    const { initialized, initializedWithAuth } = this.state;

    if (!!history && isConfigurationSet) {
      if (!initializedWithAuth && mobileNumber) {
        ai.initialize(configuration.instrumentationKey, history, (envelope) => {
          // eslint-disable-next-line no-param-reassign
          envelope.tags['ai.user.id'] = mobileNumber;
          // eslint-disable-next-line no-param-reassign
          envelope.tags['ai.user.authUserId'] = mobileNumber;
        });
        this.setState({ initialized: false, initializedWithAuth: true });
      } else if (!initialized && !mobileNumber) {
        ai.initialize(configuration.instrumentationKey, history);
        this.setState({ initialized: true, initializedWithAuth: false });
      }
    }
  }

  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider));
