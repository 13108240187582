/* eslint-disable flowtype/no-types-missing-file-annotation */
import { createActions } from 'redux-actions';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';
import type { OrderBy } from '../../api/shiftPaymentsApi';

export const { getPaymentHistoryPending, getPaymentHistoryFulfilled, getPaymentHistoryRejected } = createActions(
  actionTypes.GET_PAYMENT_HISTORY_PENDING,
  actionTypes.GET_PAYMENT_HISTORY_FULFILLED,
  actionTypes.GET_PAYMENT_HISTORY_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getPaymentHistory = (gcAccountId: string, skip: number, limit: number, orderBy: ?Array<OrderBy>) => (dispatch: Dispatch<any>) => {
  dispatch(getPaymentHistoryPending());

  return shiftPaymentsApi
    .getPaymentHistory(gcAccountId, skip, limit, orderBy)
    .then((response) => {
      return dispatch(getPaymentHistoryFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getPaymentHistoryRejected(error));
    });
};

export default {
  getPaymentHistory,
};
