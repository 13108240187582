// @flow
export const ACTION_PREFIX = 'INITIAL_DATA';
export const PARTITION = 'initialData';

export const actionTypes = {
  GET_INITIAL_DATA_PENDING: 'GET_INITIAL_DATA_PENDING',
  GET_INITIAL_DATA_FULFILLED: 'GET_INITIAL_DATA_FULFILLED',
  GET_INITIAL_DATA_REJECTED: 'GET_INITIAL_DATA_REJECTED',
  SET_JWT_VALUES: 'SET_JWT_VALUES',
  SET_IS_ALL_KYC_COMPLETED: 'SET_IS_ALL_KYC_COMPLETED',
  SET_ACCOUNTS: 'SET_ACCOUNTS',
  SET_REFERRAL_ACCOUNT: 'SET_REFERRAL_ACCOUNT',
};
