/* eslint-disable flowtype/no-types-missing-file-annotation */
import { createActions } from 'redux-actions';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';

export const { getInvoicesPending, getInvoicesFulfilled, getInvoicesRejected } = createActions(
  actionTypes.GET_INVOICES_PENDING,
  actionTypes.GET_INVOICES_FULFILLED,
  actionTypes.GET_INVOICES_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getInvoices =
  (gcAccountId: string, skip: number, limit: number, search: ?string, from: ?Date, to: ?Date, orderBy: ?Array<OrderBy>) =>
  (dispatch: Dispatch<any>) => {
    dispatch(getInvoicesPending());

    return shiftPaymentsApi
      .getInvoices(gcAccountId, skip, limit, search, from, to, orderBy)
      .then((response) => {
        return dispatch(getInvoicesFulfilled(response));
      })
      .catch((error) => {
        return dispatch(getInvoicesRejected(error));
      });
  };

export default {
  getInvoices,
};
