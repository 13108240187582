import * as Yup from 'yup';

const phoneRegExp = /^4[0-9]{8}|^04[0-9]{8}/;
const allowedCharactersRegExp = /^([a-zA-Z0-9 _-]+)$/;

export const mobileNumber = Yup.string().required('Please enter a mobile number').matches(phoneRegExp, 'Please enter a valid mobile number');

export const inviteeFormSchema = Yup.object().shape({
  mobileNumber,
});

export const checkSpecialCharacters = (value) => {
  if (!allowedCharactersRegExp.test(value)) {
    return true;
  }
  return false;
};
