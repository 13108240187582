// @flow
import { type Dispatch } from 'redux';
import { createActions } from 'redux-actions';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import shiftPaymentsApi, { type OrderBy } from '../../../api/shiftPaymentsApi';
import initialDataActions from '../../../components/InitialData/actions';

export const { getPaymentsPending, getPaymentsFulfilled, getPaymentsRejected } = createActions(
  actionTypes.GET_PAYMENTS_PENDING,
  actionTypes.GET_PAYMENTS_FULFILLED,
  actionTypes.GET_PAYMENTS_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getPayments =
  (gcAccountId: string, skip: number, limit: number, search: ?string, from: ?Date, to: ?Date, orderBy: ?Array<OrderBy>) =>
  (dispatch: Dispatch<any>) => {
    dispatch(getPaymentsPending());

    return shiftPaymentsApi
      .getPayments(gcAccountId, 'Supplier', skip, limit, search, from, to, orderBy)
      .then((response) => {
        return dispatch(getPaymentsFulfilled(response));
      })
      .catch((error) => {
        return dispatch(getPaymentsRejected(error));
      });
  };

export default {
  getPayments,
  setIsAllKycCompletedWithSessionStorage: initialDataActions.setIsAllKycCompletedWithSessionStorage,
};
