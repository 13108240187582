// @flow
import { handleActions } from 'redux-actions';
import { PARTITION, actionTypes, ACTION_PREFIX } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';

export type BankAccount = {
  name: string,
  accountNumber: string,
  id: string,
  bsb: string,
  accountHolder: string,
  accountType: string,
  accountTypeResolved: string,
};

export type BankLoginConfiguration = {
  slug: string,
  name: string,
  imageLink: string,
  credentials: any[],
};

export type BankLoginAccountOptions = {
  isVerificationCodeRequired: boolean,
  accounts: Array<BankAccount>,
  bankId: string,
  lbfcId: ?string,
};

export type ReduxState = {
  bankLoginConfiguration: ?BankLoginConfiguration,
  bankLoginAccountOptions: ?BankLoginAccountOptions,
};

const initialState: ReduxState = {
  bankLoginConfiguration: null,
  bankLoginAccountOptions: null,
};

const reducer = handleActions(
  {
    [actionTypes.GET_BANK_LOGIN_FIELDS_FULFILLED]: (state, { payload }) => ({
      ...state,
      bankLoginConfiguration: payload.data.result,
    }),
    [actionTypes.LOGIN_BANK_LINK_USER_FULFILLED]: (state, { payload }) => {
      let bankLoginAccountOptions = null;
      if (payload && payload.data && payload.data.result) {
        bankLoginAccountOptions = payload.data.result;
      } else if (payload) {
        bankLoginAccountOptions = payload.data;
      }
      return {
        ...state,
        bankLoginAccountOptions,
      };
    },
    [actionTypes.RESET_BANK_LOGIN]: (state) => ({
      ...state,
      bankLoginConfiguration: null,
      bankLoginAccountOptions: null,
    }),
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
