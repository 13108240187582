// @flow
import { Grid, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import accountReadinessSelectors from '../../../components/AccountReadinessAlerts/selectors';
import { BalanceSummary } from '../../../components/BalanceSummary';
import { BankLinkDialog, useGetAnalyticsAccountSummary } from '../../../components/BankLinkDialog/';

import BasicPageLayout from '../../../components/BasicPageLayout';
import initialDataSelectors from '../../../components/InitialData/selectors';
import { PaymentHistory } from '../../../components/PaymentHistory';
import TabPanel from '../../../components/TabPanel';
import { UpcomingPayments } from '../../../components/UpcomingPayments';
import { PROBABILITY_OF_DEFAULT_CUTOFF } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  left: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(2),
    },
  },
  right: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 !important',
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0),
    },
  },
  balanceSummaryContainer: {
    marginBottom: theme.spacing(2),
  },
  tab: {
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      margin: '0 !important',
      width: '100% !important',
    },
  },
}));

const PaymentSchedule = () => {
  const classes = useStyles();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const history = useHistory();

  const activeAccountIsDelinquent = useSelector(initialDataSelectors.selectSelectedAccountIsDelinquent);
  const isAccountReady = useSelector(accountReadinessSelectors.selectIsAccountReady);
  const gcAccountId = useSelector(initialDataSelectors.selectSelectedGcAccountId);

  const [bankLinkEnforcementStatusDialog, setBankLinkEnforcementStatusDialog] = useState(false);
  const analyticsAccountSummary = useGetAnalyticsAccountSummary(gcAccountId);

  const handleTabChange = (e, tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  const handleNewInvoice = () => {
    if (analyticsAccountSummary && analyticsAccountSummary.isRequireConnection) {
      setBankLinkEnforcementStatusDialog(true);
      return;
    }
    history.push('/buyer/payments/pay-new-or-existing');
  };

  return (
    <BasicPageLayout title='Payment schedule' noMargin>
      {bankLinkEnforcementStatusDialog ? <BankLinkDialog onClose={() => setBankLinkEnforcementStatusDialog(false)} /> : null}
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} sm={4} className={classes.left}>
          <div className={classes.balanceSummaryContainer}>
            <BalanceSummary
              activeAccountIsDelinquent={activeAccountIsDelinquent}
              isAccountReady={isAccountReady}
              handleNewInvoice={handleNewInvoice}
              isRequiredToConnectBank={analyticsAccountSummary.isTransactionsOlderThanAllowed}
              isPdEligibleForCli={analyticsAccountSummary.probabilityOfDefault < PROBABILITY_OF_DEFAULT_CUTOFF}
              openBankLinkDialog={() => setBankLinkEnforcementStatusDialog(true)}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={8} className={classes.right}>
          <Tabs value={activeTabIndex} indicatorColor='primary' data-testid='uia-tabs' onChange={handleTabChange}>
            <Tab label='Upcoming payments' className={classes.tab} />
            <Tab label='Payment history' className={classes.tab} />
          </Tabs>
          <TabPanel tabIndex={0} activeTabIndex={activeTabIndex}>
            <UpcomingPayments />
          </TabPanel>
          <TabPanel tabIndex={1} activeTabIndex={activeTabIndex}>
            <PaymentHistory />
          </TabPanel>
        </Grid>
      </Grid>
    </BasicPageLayout>
  );
};

export default PaymentSchedule;
