// @flow
import React, { useState } from 'react';
import { makeStyles, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { type MoreActionsContextMenuItem } from '../types';

type Props = {
  isVisible: boolean,
  options: Array<MoreActionsContextMenuItem>,
  supplierGcAccountId: string,
  buyerGcAccountId: string,
  buyerAccountName: string,
  buyerAccountAbn: string,
  actions: Array<string>,
  ability: any,
  term: number,
  termsPeriod: string,
};

const MoreActionsContextMenu = (props: Props) => {
  const { options, supplierGcAccountId, buyerGcAccountId, buyerAccountName, buyerAccountAbn, actions, ability, term, termsPeriod } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ left: 0, top: 0 });
  const [isActive, setIsActive] = useState(false);
  const open = Boolean(anchorEl);

  const PIXELS_FROM_LEFT = 150;
  const PIXELS_FROM_TOP = 0;

  const handleClick = (event) => {
    setIsActive(true);
    const p = event.currentTarget.getBoundingClientRect();
    setMenuPosition({ left: p.left - PIXELS_FROM_LEFT, top: p.top + PIXELS_FROM_TOP });
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsActive(false);
  };

  const handleMenuItemClick = ({ action, actionType, buyerAccountId, buyerAccountName, buyerAccountAbn, term, termsPeriod }) => {
    handleClose();
    if (action) action({ actionType, supplierGcAccountId, buyerAccountId, buyerAccountName, buyerAccountAbn, term, termsPeriod });
  };

  const mnuUseStyle = makeStyles((theme) => ({
    menuItem: {
      color: theme.palette.common.mediumGreen,
      '&:hover': {
        backgroundColor: '#E2F7F8',
        fontWeight: '500',
      },
      minWidth: '150px',
    },
    iconButton: {
      '&:hover': {
        backgroundColor: 'transparent',
        fontWeight: '500',
      },
      padding: '0px',
    },
    ellipseActive: {
      color: theme.palette.primary.main,
    },
    menu: {
      minWidth: '150px',
    },
  }));

  const classes = mnuUseStyle();

  const availableOptions = options
    .map((o) => (actions.includes(o.actionType) && ability.can(o.permissionTo, o.subject) ? o : undefined))
    .filter((i) => i);

  if (!availableOptions || availableOptions.length === 0) return <></>;

  return (
    <>
      <IconButton
        data-testid='uia-moreActions'
        aria-label='more'
        id='long-button'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
        className={classes.iconButton}
      >
        <MoreVertIcon className={isActive ? classes.ellipseActive : ''} />
      </IconButton>
      <Menu
        anchorReference='anchorPosition'
        anchorPosition={{ left: menuPosition.left, top: menuPosition.top }}
        transformOrigin={{ left: menuPosition.left, top: menuPosition.top }}
        open={open}
        onClose={handleClose}
        className={classes.menu}
      >
        {availableOptions.map((o) => {
          if (o) {
            return (
              <MenuItem
                className={classes.menuItem}
                data-testid='uia-menuItem'
                key={o.actionText}
                onClick={() =>
                  handleMenuItemClick({
                    action: o.action,
                    actionType: o.actionType,
                    supplierGcAccountId: supplierGcAccountId,
                    buyerAccountId: buyerGcAccountId,
                    buyerAccountName: buyerAccountName,
                    buyerAccountAbn: buyerAccountAbn,
                    term,
                    termsPeriod,
                  })
                }
              >
                {o.actionText}
              </MenuItem>
            );
          }
        })}
      </Menu>
    </>
  );
};

export default MoreActionsContextMenu;
