// @flow
import React from 'react';
import type { Node } from 'react';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';

const styles = (theme) => ({
  pageItemContainer: {
    padding: theme.spacing(3),
    width: '100%',
    display: 'block',
  },
  header: {
    marginBottom: theme.spacing(2.5),
  },
});

type Props = {
  classes: any,
  header?: string,
  children: Node,
  className: string,
};

const PageItem = (props: Props) => {
  const { classes, header, children, className } = props;

  return (
    <Paper className={clsx(classes.pageItemContainer, className)}>
      {header ? <Typography className={classes.header} variant='h3'>
          {header}
        </Typography> : null}
      {children}
    </Paper>
  );
};

export default withStyles(styles)(PageItem);
