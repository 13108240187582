import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import actions from './actions';
import UserSelectProfileMode from './Component';
import selectors from './selectors';
import { ROLE_TYPE } from '../../constants';
import { supplierBuyerMenuList } from '../../lib/profileHelper';
import { UserProfileMode } from '../../types';
import accountReadinessSelectors from '../AccountReadinessAlerts/selectors';
import initialDataSelectors from '../InitialData/selectors';

const mapStateToProps = createSelector(
  initialDataSelectors.selectIsAllKycCompleted,
  initialDataSelectors.selectActiveAccount,
  selectors.selectActiveProfileMode,
  accountReadinessSelectors.selectIsAccountReady,
  (isAllKycCompleted, activeAccount, activeProfileModeIndex, isAccountReady) => {
    const isDirector = activeAccount && activeAccount.roles.some((i) => i === ROLE_TYPE.DIRECTOR);
    const isAPM = activeAccount && activeAccount.roles.some((i) => i === ROLE_TYPE.ACCOUNT_PAYABLE_MANAGER);
    const isARM = activeAccount && activeAccount.roles.some((i) => i === ROLE_TYPE.ACCOUNT_RECEIVABLE_MANAGER);
    const supplierBuyerMenuListOptions = supplierBuyerMenuList();
    const isAtLeastOneDirectorKycCompleted = activeAccount && activeAccount.isAtLeastOneDirectorKycCompleted;
    return {
      options: supplierBuyerMenuListOptions,
      selected: isAtLeastOneDirectorKycCompleted ? activeProfileModeIndex : UserProfileMode.Buyer,
      isAccountReady,
      isDirector,
      isAPM,
      isARM,
    };
  }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserSelectProfileMode);
