import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Divider, Select, SelectItem, Text } from '@shiftfinancial/design-system';
import moment from 'moment';
import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import actions from './actions';
import type { WeeklyFeeOption } from './types';
import DollarsAndCentsText from '../../../components/DollarsAndCentsText';

import { DATE_AND_MONTH, STANDARD_DATE_FORMAT } from '../../../constants';

type Props = {
  feeOptions: WeeklyFeeOption[];
  repaymentDayOfTheWeek: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 3, 1, 6),
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(1),
    },
    boxShadow: 'none !important',
  },
  values: { textAlign: 'left', marginTop: theme.spacing(1) },
  lableValueRow: { marginTop: theme.spacing(1) },
  weeklyTitle: { marginTop: theme.spacing(3) },
  weeklyTitleValue: { textAlign: 'left' },
  dropdown: {
    margin: theme.spacing(1, 6, 4, 0),
    minWidth: theme.spacing(25),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 6, 0, 0),
    },
  },
  detailsContainer: { backgroundColor: '#F9F9FA', padding: theme.spacing(2) },
}));

export const WeeklyRepaymentPanel = (props: Props) => {
  const classes = useStyles();
  const { feeOptions, repaymentDayOfTheWeek } = props;

  const dispatch = useDispatch();
  const setInstallmentTermInWeeks = (term: number) => dispatch(actions.setInstallmentTermInWeeks(term));
  const [selectedWeeklyOption, setSelectedWeeklyOption] = useState<WeeklyFeeOption | undefined>();

  const setWeeklyOption = (term: number) => {
    const option = feeOptions.find((item) => item.term === term);
    setSelectedWeeklyOption(option);
    setInstallmentTermInWeeks(term);
  };

  const zeroFeeView = () => {
    return (
      <Grid item xs={12} sm={7} className={classes.detailsContainer}>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Text fontSize='standard' fontWeight='medium' data-testid='uia-weekly-title'>
              Weekly repayments
            </Text>
          </Grid>
          <Grid item xs={4} className={classes.weeklyTitleValue}>
            <Text fontSize='standard' fontWeight='medium'>
              <DollarsAndCentsText amount={selectedWeeklyOption ? selectedWeeklyOption?.weeklyRepaymentAmount + selectedWeeklyOption?.fee : 0} />
            </Text>
          </Grid>
          <Divider />
          <Grid item xs={8} className={classes.lableValueRow}>
            <Text fontSize='small'>Debited on</Text>
          </Grid>
          <Grid item xs={4} className={classes.values}>
            <Grid>
              <Text fontSize='small' fontWeight='medium'>{`Every ${repaymentDayOfTheWeek}`}</Text>
            </Grid>
          </Grid>
          <Grid item xs={8} className={classes.lableValueRow}>
            <Text fontSize='small'>First debit on</Text>
          </Grid>
          <Grid item xs={4} className={classes.values}>
            <Text fontSize='small' fontWeight='medium'>
              {moment(selectedWeeklyOption?.firstRepaymentDate).format(STANDARD_DATE_FORMAT)}
            </Text>
          </Grid>
          <Grid item xs={8} className={classes.lableValueRow}>
            <Text fontSize='small'>Last debit on</Text>
          </Grid>
          <Grid item xs={4} className={classes.values}>
            <Text fontSize='small' fontWeight='medium'>
              {moment(selectedWeeklyOption?.lastRepaymentDate).format(STANDARD_DATE_FORMAT)}
            </Text>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Paper className={classes.container} data-testid='uia-week-selector'>
      <Grid container>
        <Grid item xs={12} sm={selectedWeeklyOption ? 5 : 12}>
          <Text fontSize='standard' fontWeight='medium'>
            Repayment term
          </Text>
          <Grid className={classes.dropdown} data-testid='uia-week-selector-dropdown'>
            <Select placeholder='-Select-' onValueChange={(value: string) => setWeeklyOption(Number(value))}>
              {feeOptions.length
                ? feeOptions.map((option: WeeklyFeeOption) => (
                    <SelectItem key={`options-${option.term}`} value={`${option.term}`}>
                      {option.term} instalments
                    </SelectItem>
                  ))
                : null}
            </Select>
          </Grid>
        </Grid>
        {selectedWeeklyOption ? (
          selectedWeeklyOption.fee > 0 ? (
            <Grid item xs={12} sm={7} className={classes.detailsContainer}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Text fontSize='standard' fontWeight='medium' data-testid='uia-weekly-title'>
                    First repayment
                  </Text>
                </Grid>
                <Grid item xs={4} className={classes.weeklyTitleValue}>
                  <Text fontSize='standard' fontWeight='medium'>
                    <DollarsAndCentsText
                      amount={selectedWeeklyOption ? selectedWeeklyOption?.weeklyRepaymentAmount + selectedWeeklyOption?.fee : 0}
                    />
                  </Text>
                </Grid>
                <Divider />
                <Grid item xs={8} className={classes.lableValueRow}>
                  <Text fontSize='small'>Repayment amount</Text>
                </Grid>
                <Grid item xs={4} className={classes.values}>
                  <Text fontSize='small' fontWeight='medium'>
                    <DollarsAndCentsText amount={selectedWeeklyOption ? selectedWeeklyOption?.weeklyRepaymentAmount : 0} />
                  </Text>
                </Grid>
                <Grid item xs={8} className={classes.lableValueRow}>
                  <Text fontSize='small'>One-off transaction fee</Text>
                </Grid>
                <Grid item xs={4} className={classes.values}>
                  <Text fontSize='small' fontWeight='medium'>
                    <DollarsAndCentsText amount={selectedWeeklyOption ? selectedWeeklyOption?.fee : 0} />
                  </Text>
                </Grid>
                <Grid item xs={8} className={classes.lableValueRow}>
                  <Text fontSize='small'>First debit on</Text>
                </Grid>
                <Grid item xs={4} className={classes.values}>
                  <Text fontSize='small' fontWeight='medium'>
                    {moment(selectedWeeklyOption?.firstRepaymentDate).format(STANDARD_DATE_FORMAT)}
                  </Text>
                </Grid>
              </Grid>
              <Grid container spacing={1} className={classes.weeklyTitle}>
                <Grid item xs={8}>
                  <Text fontSize='standard' fontWeight='medium'>
                    Weekly repayments
                  </Text>
                </Grid>
                <Grid item xs={4} className={classes.weeklyTitleValue}>
                  <Text fontSize='standard' fontWeight='medium'>
                    <DollarsAndCentsText amount={selectedWeeklyOption ? selectedWeeklyOption?.weeklyRepaymentAmount : 0} />
                  </Text>
                </Grid>
                <Divider />
                <Grid item xs={8} className={classes.lableValueRow}>
                  <Text fontSize='small'>Debited on</Text>
                </Grid>
                <Grid item xs={4} className={classes.values}>
                  <Grid>
                    <Text fontSize='small' fontWeight='medium'>{`Every ${repaymentDayOfTheWeek}`}</Text>
                  </Grid>
                  <Grid>
                    <Text fontSize='small' color='secondary'>
                      {`From ${moment(selectedWeeklyOption?.secondRepaymentDate).format(DATE_AND_MONTH)}`}
                    </Text>
                  </Grid>
                </Grid>
                <Grid item xs={8} className={classes.lableValueRow}>
                  <Text fontSize='small'>Last debit on</Text>
                </Grid>
                <Grid item xs={4} className={classes.values}>
                  <Text fontSize='small' fontWeight='medium'>
                    {moment(selectedWeeklyOption?.lastRepaymentDate).format(STANDARD_DATE_FORMAT)}
                  </Text>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            zeroFeeView()
          )
        ) : null}
      </Grid>
    </Paper>
  );
};
