// @flow
import { SESSION_STORAGE_APPLICATION_STATE } from '../constants';

export type ApplicationStateValues = {
  isAllKycCompleted?: boolean,
  selectedAccountId?: string,
};

let appState: ?ApplicationStateValues = null;

const set = (value: ApplicationStateValues): void => {
  if (window && window.sessionStorage) {
    appState = {
      ...appState,
      ...value,
    };
    window.sessionStorage.setItem(SESSION_STORAGE_APPLICATION_STATE, JSON.stringify(appState));
    return;
  }

  throw Error('sessionStorage is undefined');
};

const get = (): ApplicationStateValues => {
  if (window && window.sessionStorage) {
    if (!appState) {
      const json = window.sessionStorage.getItem(SESSION_STORAGE_APPLICATION_STATE);
      if (json) {
        appState = JSON.parse(json);
      }
    }
    return appState || {};
  }

  throw Error('sessionStorage is undefined');
};

export default {
  set,
  get,
};
