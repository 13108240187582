export const ACTION_PREFIX = 'SUPPLIER_REQUEST';
export const PARTITION = 'supplierRequest';

export const actionTypes = {
  REJECT_REQUEST_PENDING: 'REJECT_REQUEST_PENDING',
  REJECT_REQUEST_FULFILLED: 'REJECT_REQUEST_FULFILLED',
  REJECT_REQUEST_REJECTED: 'REJECT_REQUEST_REJECTED',
  APPROVE_REQUEST_PENDING: 'APPROVE_REQUEST_PENDING',
  APPROVE_REQUEST_FULFILLED: 'APPROVE_REQUEST_FULFILLED',
  APPROVE_REQUEST_REJECTED: 'APPROVE_REQUEST_REJECTED',
  GET_SUPPLIER_REQUEST_PENDING: 'GET_SUPPLIER_REQUEST_PENDING',
  GET_SUPPLIER_REQUEST_FULFILLED: 'GET_SUPPLIER_REQUEST_FULFILLED',
  GET_SUPPLIER_REQUEST_REJECTED: 'GET_SUPPLIER_REQUEST_REJECTED',
};
