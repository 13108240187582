import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from '@reduxjs/toolkit';
import Component from './Component';
import actions from './actions';
import initialDataSelectors from '../../../components/InitialData/selectors';
import accountReadinessSelectors from '../../../components/AccountReadinessAlerts/selectors';

const mapStateToProps = createSelector(
  initialDataSelectors.selectSelectedGcAccountId,
  initialDataSelectors.selectSelectedAccountIsDelinquent,
  accountReadinessSelectors.selectIsAccountReady,
  (gcAccountId, activeAccountIsDelinquent, isAccountReady) => ({
    gcAccountId,
    isActionBlocked: activeAccountIsDelinquent || !isAccountReady,
  })
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Component);
