// @flow
import React, { useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import moment from 'moment';

import { ATTACHMENT_SUPPORTED_FORMATS, MANUAL_STATEMENT_SUPPORTED_FORMATS } from '../../constants';
import FileUploadContext, { type FileUploadContextProps } from './FileUploadContext';
import { type File } from '../../types';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.snowDrift,
    border: `1px dashed ${theme.palette.primary.aquamarineBlue}`,
    borderRadius: 4,
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      textDecoration: 'none',
    },
    padding: theme.spacing(0.5),
    textAlign: 'center',
  },
  paragraph2: {
    color: theme.palette.grey.light,
  },
}));

type Props = {
  gcAccountId: string,
  fileGroupId: string,
  onFilesSelected: (files: Array<File>) => void,
  onFileStatusChange: (file: File) => void,
  fileCount: number,
};

const DndFileUpload = (props: Props) => {
  const { onFilesSelected, onFileStatusChange, ...other } = props;

  const { maxFilesCount, maxFileSizeInMb, onFileUpload, fileGroupId, gcAccountId, canUpload, fileCount, supportedFileTypesText } =
    useContext<FileUploadContextProps>(FileUploadContext);

  const classes = useStyles();

  const handleFilesAccepted = (files: Array<any>) => {
    const fileDetails = [];
    const actualFiles = [];
    let newFileCount = fileCount + 1;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (newFileCount > maxFilesCount) {
        fileDetails.push({
          name: file.name,
          type: file.type,
          size: file.size,
          error: 'Upload failed',
          uploaded: false,
          timestamp: moment().toDate(),
          documentFileId: file.documentFileId,
        });
        actualFiles.push(file);
        // eslint-disable-next-line no-continue
        continue;
      }

      if (canUpload && !canUpload(file)) {
        return;
      }

      fileDetails.push({
        name: file.name,
        type: file.type,
        size: file.size,
        error: '',
        uploaded: false,
        timestamp: moment().toDate(),
        documentFileId: file.documentFileId,
      });
      actualFiles.push(file);

      newFileCount++;
    }

    onFilesSelected(fileDetails);

    fileDetails
      .filter((detail) => !detail.error)
      .forEach((detail) => {
        const actualFile = actualFiles.find((x) => x.name === detail.name);
        onFileUpload(gcAccountId, fileGroupId, actualFile).then((response) => {
          const { error } = response;

          onFileStatusChange({
            type: detail.type,
            name: detail.name,
            size: detail.size,
            error: error ? 'Upload failed' : '',
            uploaded: !error,
            timestamp: moment().toDate(),
            documentFileId: response?.data?.documentFileId,
          });
        });
      });
  };

  const handleFilesRejected = (files: any) => {
    onFilesSelected(
      files.map(({ file, errors }) => ({
        name: file.name,
        type: file.type,
        size: file.size,
        error: errors[0].code === 'file-too-large' ? `The maximum file size allowed is ${maxFileSizeInMb}MB` : errors[0].message,
        uploaded: false,
        timestamp: moment().toDate(),
        documentFileId: file.documentFileId,
      }))
    );
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: supportedFileTypesText ? MANUAL_STATEMENT_SUPPORTED_FORMATS.join(',') : ATTACHMENT_SUPPORTED_FORMATS.join(','),
    maxFilesCount,
    maxSize: maxFileSizeInMb * 1048576,
    noClick: true,
    noKeyboard: true,
    onDropAccepted: handleFilesAccepted,
    onDropRejected: handleFilesRejected,
  });

  const handleBrowseFiles = (e) => {
    e.preventDefault();
    open();
  };

  const isMobile = useMediaQuery('(max-width:600px)');
  const supportedText = `${
    supportedFileTypesText ? supportedFileTypesText : 'Upload files in PDF, JPG or PNG format.'
  } Max file size ${maxFileSizeInMb}MB.`;
  const uploadText = isMobile ? '' : 'Drag-n-drop your files or ';
  const browseText = isMobile ? 'Upload files' : 'browse';
  const maxFileText = isMobile ? '' : supportedText;

  return (
    <Box className={classes.container} {...getRootProps()} {...(other: any)}>
      <input {...getInputProps()} />
      <Typography variant='body2'>
        {uploadText}
        <a href='#' onClick={handleBrowseFiles}>
          {browseText}
        </a>
      </Typography>
      <Typography variant='body2' className={classes.paragraph2}>
        {maxFileText}
      </Typography>
    </Box>
  );
};

export default DndFileUpload;
