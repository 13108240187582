// @flow
import { handleActions } from 'redux-actions';

import { actionTypes, PARTITION, ACTION_PREFIX } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';

export type ReduxState = {
  activeProfileModeIndex: number,
  openTradeAccount: {
    isOpenTradeAccount: boolean,
    attempt: number,
  },
};

const initialState: ReduxState = {
  activeProfileModeIndex: 1,
  openTradeAccount: {
    isOpenTradeAccount: false,
    attempt: 0,
  },
};

const reducer = handleActions<ReduxState>(
  {
    [actionTypes.UPDATE_PROFILE_MODE_INDEX]: (state, { payload }) => {
      return { ...state, activeProfileModeIndex: payload.id };
    },
    [actionTypes.UPDATE_OPEN_TRADE_ACCOUNT]: (state, { payload }) => {
      return {
        ...state,
        openTradeAccount: {
          isOpenTradeAccount: payload.isOpenTradeAccount,
          attempt: payload.attempt === 0 ? 0 : state.openTradeAccount.attempt + payload.attempt,
        },
      };
    },
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
