// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  theme: any,
  width?: number,
  height?: number,
};

const CompanyIcon = withTheme((props: Props) => {
  const width = props.width || 30;
  const height = props.height || 30;
  return (
    <SvgIcon
      {...props}
      style={{
        alignSelf: 'baseline',
        width,
        height,
      }}
      viewBox='0 0 30 30'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-1009.000000, -86.000000)' fill={props.theme.palette.primary.main}>
          <g transform='translate(1009.000000, 86.000000)'>
            <g>
              <circle cx='15' cy='15' r='15' fill={props.theme.palette.common.lightGreen} />
            </g>
            <g transform='translate(8.000000, 7.000000)' fillRule='nonzero'>
              <path d='M7,3 L4,3 L4,6 L7,6 L7,3 Z M6.35008666,5.35008666 L4.64991334,5.35008666 L4.64991334,3.64991334 L6.35008666,3.64991334 L6.35008666,5.35008666 Z' />
              <path d='M11,3 L8,3 L8,6 L11,6 L11,3 Z M10.3500867,5.35008666 L8.64991334,5.35008666 L8.64991334,3.64991334 L10.3500867,3.64991334 L10.3500867,5.35008666 Z' />
              <path d='M7,7 L4,7 L4,10 L7,10 L7,7 Z M6.35008666,9.35003972 L4.64991334,9.35003972 L4.64991334,7.64996028 L6.35008666,7.64996028 L6.35008666,9.35003972 Z' />
              <path d='M11,7 L8,7 L8,10 L11,10 L11,7 Z M10.3500867,9.35003972 L8.64991334,9.35003972 L8.64991334,7.64996028 L10.3500867,7.64996028 L10.3500867,9.35003972 Z' />
              <path d='M13.1887776,16.3855422 L13.1887776,0 L1.81122241,0 L1.81122241,16.3855422 L0,16.3855422 L0,17 L1.81122241,17 L6.06726932,17 L8.93293428,17 L13.1887776,17 L15,17 L15,16.3855422 L13.1887776,16.3855422 Z M8.32213535,16.3855422 L6.67806825,16.3855422 L6.67806825,14.3615181 L8.32213535,14.3615181 L8.32213535,16.3855422 Z M12.5779787,16.3855422 L8.93293428,16.3855422 L8.93293428,13.7470602 L6.06726932,13.7470602 L6.06726932,16.3855422 L2.42202134,16.3855422 L2.42202134,0.614457831 L12.5779787,0.614457831 L12.5779787,16.3855422 Z' />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
});

export default CompanyIcon;
