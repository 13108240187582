// @flow
/* eslint-disable flowtype/no-types-missing-file-annotation */
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';
import type { OrderBy, AuthorizationStatus } from '../../api/shiftPaymentsApi';

export const {
  getPublicSuppliersPending,
  getPublicSuppliersFulfilled,
  getPublicSuppliersRejected,
  setSupplierAuthorizationPending,
  setSupplierAuthorizationFulfilled,
  setSupplierAuthorizationRejected,
} = createActions(
  actionTypes.GET_PUBLIC_SUPPLIERS_PENDING,
  actionTypes.GET_PUBLIC_SUPPLIERS_FULFILLED,
  actionTypes.GET_PUBLIC_SUPPLIERS_REJECTED,
  actionTypes.SET_SUPPLIER_AUTHORIZATION_PENDING,
  actionTypes.SET_SUPPLIER_AUTHORIZATION_FULFILLED,
  actionTypes.SET_SUPPLIER_AUTHORIZATION_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getPublicSuppliers = (gcAccountId: string, skip: number, limit: number, orderBy: ?Array<OrderBy>) => (dispatch: Dispatch<any>) => {
  dispatch(getPublicSuppliersPending());

  return shiftPaymentsApi
    .getPublicSuppliers(gcAccountId, '', skip, limit, orderBy)
    .then((response) => {
      return dispatch(getPublicSuppliersFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getPublicSuppliersRejected(error));
    });
};

const setSupplierAuthorization =
  (buyerGcAccountId: string, supplierGcAccountId: string, authorizationStatus: AuthorizationStatus, reason: ?string) => (dispatch: Dispatch<any>) => {
    dispatch(setSupplierAuthorizationPending());

    return shiftPaymentsApi
      .setSupplierAuthorization(buyerGcAccountId, supplierGcAccountId, authorizationStatus, reason)
      .then((response) => {
        return dispatch(setSupplierAuthorizationFulfilled(response));
      })
      .catch((error) => {
        return dispatch(setSupplierAuthorizationRejected(error));
      });
  };

export default {
  getPublicSuppliers,
  setSupplierAuthorization,
};
