// @flow
import React, { useMemo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Box, Grid, Tabs, Tab } from '@material-ui/core';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { useAbility } from '@casl/react';

import { UserSelectProfileMode } from '../UserSelectProfileMode';
import { getNavigations } from '../../lib/profileHelper';
import { AbilityContext } from '../../lib/ability';
import { Permission } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '11%',
  },
  box: {
    alignSelf: 'center !important',
    display: 'inline-flex',
    minHeight: '48px !important',
    color: '#EDF1F6',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 2),
      width: '100% !important',
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 0),
      width: '1200px !important',
    },
    backgroundColor: theme.palette.common.darkGreen,
  },
  appbar: {
    boxShadow: 'none',
    backgroundColor: theme.palette.common.darkGreen,
  },
  tabs: {
    margin: 'auto',
    justifyContent: 'center',
    '& .Mui-selected': {
      backgroundColor: `${theme.palette.action.selectedDark} !important`,
    },
    '& .MuiTab-root': {
      backgroundColor: 'inherit',
      border: 0,
    },
  },
  headertab: {
    width: '90%',
    '& a': {
      [theme.breakpoints.up('md')]: {
        margin: 'inherit',
      },
      maxWidth: '130px !important',
      opacity: '1 !important',
      color: theme.palette.common.white,
    },
    '& span': {
      width: '0px !important',
      [theme.breakpoints.up('md')]: {
        maxWidth: '14% !important',
        margin: 'inherit',
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: '12% !important',
      },
      whiteSpace: 'nowrap !important',
    },
  },
}));

type Props = {
  isBuyer: boolean,
  isSupplier: false,
  isAllKycCompleted: boolean,
  activeProfileMode: number,
  isNavigationVisible: boolean,
  isPrivacyAgreementSetupComplete: boolean,
  isAllSetupCompleteRequirement: boolean,
  isAtLeastOneDirectorKycCompleted: boolean,
};

const HeaderNavigation = (props: Props) => {
  const {
    isBuyer,
    isSupplier,
    isAllKycCompleted,
    activeProfileMode,
    isPrivacyAgreementSetupComplete,
    isAllSetupCompleteRequirement,
    isAtLeastOneDirectorKycCompleted,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const ability = useAbility(AbilityContext);
  const navList = useMemo(
    () => getNavigations(isAllKycCompleted, isBuyer, isSupplier, activeProfileMode, false, ability),
    [isSupplier, isAllKycCompleted, activeProfileMode, ability]
  );

  const [localIsNavigationVisible, setLocalIsNavigationVisible] = useState(false);

  useEffect(() => {
    const canSignPrivacyAgreement = ability.can(Permission.Actions.Sign, Permission.Buyer.PrivacyAgreement);
    if (isBuyer && isSupplier && isAllKycCompleted && canSignPrivacyAgreement && !isPrivacyAgreementSetupComplete) {
      setLocalIsNavigationVisible(false);
    } else if (isBuyer && !isSupplier && isAllKycCompleted && !isAllSetupCompleteRequirement) {
      setLocalIsNavigationVisible(false);
    } else if (isBuyer && !isSupplier && isAllKycCompleted && isAllSetupCompleteRequirement) {
      setLocalIsNavigationVisible(true);
    } else if (isBuyer && isSupplier && isAtLeastOneDirectorKycCompleted && isAllSetupCompleteRequirement) {
      setLocalIsNavigationVisible(true);
    } else if (!isBuyer && isSupplier && isAtLeastOneDirectorKycCompleted) {
      setLocalIsNavigationVisible(true);
    } else {
      setLocalIsNavigationVisible(false);
    }
  }, [isBuyer, isSupplier, isAllKycCompleted, isPrivacyAgreementSetupComplete, isAllSetupCompleteRequirement, isAtLeastOneDirectorKycCompleted]);

  // set tab to default, when we change account or profile mode
  const getTabIndex = () => {
    const tab = navList.find((x) => {
      return x.routes.find((r) => history.location.pathname.indexOf(r) > -1);
    });
    if (tab) {
      return tab.id;
    }
    return null;
  };

  return (
    localIsNavigationVisible && (
      <Grid className={classes.root}>
        <AppBar position='static' className={classes.appbar}>
          <Box className={classes.box} data-testid='box'>
            <Box className={classes.headertab}>
              <Tabs variant='fullWidth' value={getTabIndex()} className={classes.tabs} data-testid='tabs'>
                {navList.map((nav) => {
                  return !nav.disabled && <Tab component={Link} key={nav.key} label={nav.label} to={nav.routes[0]} />;
                })}
              </Tabs>
            </Box>
            <UserSelectProfileMode
              isBuyer={isBuyer}
              isSupplier={isSupplier}
              isAllKycCompleted={isAllKycCompleted}
              isAtLeastOneDirectorKycCompleted={isAtLeastOneDirectorKycCompleted}
            />
          </Box>
        </AppBar>
      </Grid>
    )
  );
};

export default withRouter(HeaderNavigation);
