// @flow
import { accountReadinessFlags } from '../../types';
import { selectors as initialDataSelectors } from '../InitialData';

const shiftAccountReadyFlags = [accountReadinessFlags.digitalKycPass, accountReadinessFlags.pepResultPass, accountReadinessFlags.amlResultPass];

const selectIsAccountReady = (state: any): boolean => {
  const isTrustDeedApprovalRequired = initialDataSelectors.selectSelectedAccountTrustDeedNeedsApproval(state);

  if (isTrustDeedApprovalRequired) {
    return false;
  }

  const activeAccount = initialDataSelectors.selectActiveAccount(state);

  if (!activeAccount) {
    return false;
  }

  // eslint-disable-next-line no-bitwise
  const isReady = shiftAccountReadyFlags.every((x) => (activeAccount.accountReadiness & x) === x);

  return isReady;
};

export default {
  selectIsAccountReady,
};
