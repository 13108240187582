// @flow
import { handleActions } from 'redux-actions';

import { actionTypes, PARTITION, ACTION_PREFIX } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';

export type AccountSetupStatus = {
  isCompleted: boolean,
  data?: any,
};

export type ReduxState = {
  isAccountSetLoaded: boolean,
  bankAccountSetup: AccountSetupStatus,
  termAndConditionsAgreementSetup: AccountSetupStatus,
  directDebitAgreementSetup: AccountSetupStatus,
  loanAgreementSetup: AccountSetupStatus,
  privacyAgreementSetup: AccountSetupStatus,
  isBuyer: boolean,
  isSupplier: boolean,
  isSettlementAccountSetupCompleted: boolean,
  isMerchantAgreementSetupCompleted: boolean,
};

const initialState: ReduxState = {
  isAccountSetLoaded: false,
  bankAccountSetup: { isCompleted: false },
  termAndConditionsAgreementSetup: { isCompleted: false },
  directDebitAgreementSetup: { isCompleted: false },
  loanAgreementSetup: { isCompleted: false },
  privacyAgreementSetup: { isCompleted: false },
  isBuyer: false,
  isSupplier: false,
  isSettlementAccountSetupCompleted: false,
  isMerchantAgreementSetupCompleted: false,
};

const reducer = handleActions<ReduxState>(
  {
    [actionTypes.UPDATE_BANK_ACCOUNT_SETUP]: (state, { payload }) => ({
      ...state,
      bankAccountSetup: payload,
    }),
    [actionTypes.UPDATE_BANK_ACCOUNT_SETUP_COMPLETED]: (state, { payload }) => ({
      ...state,
      bankAccountSetup: {
        isCompleted: payload,
        data: state.bankAccountSetup.data,
      },
    }),
    [actionTypes.UPDATE_DIRECT_DEBIT_AGREEMENT_SETUP]: (state, { payload }) => ({
      ...state,
      directDebitAgreementSetup: payload,
    }),
    [actionTypes.UPDATE_DIRECT_DEBIT_AGREEMENT_SETUP_COMPLETED]: (state, { payload }) => ({
      ...state,
      directDebitAgreementSetup: {
        isCompleted: payload,
        data: state.directDebitAgreementSetup && state.directDebitAgreementSetup.data,
      },
    }),
    [actionTypes.UPDATE_LOAN_AGREEMENT_SETUP]: (state, { payload }) => ({
      ...state,
      loanAgreementSetup: payload,
    }),
    [actionTypes.UPDATE_LOAN_AGREEMENT_SETUP_COMPLETED]: (state, { payload }) => ({
      ...state,
      loanAgreementSetup: {
        isCompleted: payload,
        data: state.loanAgreementSetup && state.loanAgreementSetup.data,
      },
    }),
    [actionTypes.UPDATE_PRIVACY_AGREEMENT_SETUP]: (state, { payload }) => ({
      ...state,
      privacyAgreementSetup: payload,
    }),
    [actionTypes.UPDATE_PRIVACY_AGREEMENT_SETUP_COMPLETED]: (state, { payload }) => ({
      ...state,
      privacyAgreementSetup: {
        isCompleted: payload,
        data: state.privacyAgreementSetup && state.privacyAgreementSetup.data,
      },
    }),
    [actionTypes.UPDATE_TERMS_AND_CONDITIONS_AGREEMENT_SETUP]: (state, { payload }) => ({
      ...state,
      termAndConditionsAgreementSetup: payload,
    }),
    [actionTypes.UPDATE_TERMS_AND_CONDITIONS_AGREEMENT_SETUP_COMPLETED]: (state, { payload }) => ({
      ...state,
      termAndConditionsAgreementSetup: {
        isCompleted: payload,
        data: state.termAndConditionsAgreementSetup && state.termAndConditionsAgreementSetup.data,
      },
    }),
    [actionTypes.RESET_ACCOUNT_SETUP]: (state) => ({
      ...state,
      isAccountSetLoaded: false,
      bankAccountSetup: { isCompleted: false },
      termAndConditionsAgreementSetup: { isCompleted: false },
      directDebitAgreementSetup: { isCompleted: false },
      loanAgreementSetup: { isCompleted: false },
      privacyAgreementSetup: { isCompleted: false },
    }),
    [actionTypes.GET_ACCOUNT_SETUP_FULFILLED]: (state) => ({
      ...state,
      isAccountSetLoaded: true,
    }),
    [actionTypes.SET_ACCOUNT_PROFILE]: (state, { payload }) => ({
      ...state,
      isBuyer: payload.isBuyer,
      isSupplier: payload.isSupplier,
    }),
    [actionTypes.UPDATE_SETTLEMENT_ACCOUNT_SETUP_COMPLETED]: (state, { payload }) => {
      return { ...state, isSettlementAccountSetupCompleted: payload };
    },
    [actionTypes.UPDATE_MERCHANT_AGREEMENT_SETUP_COMPLETED]: (state, { payload }) => {
      return { ...state, isMerchantAgreementSetupCompleted: payload };
    },
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
