// @flow
import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import AccountOpenIcon from '../../../components/svg/AccountOpenIcon';
import TxnApiIcon from '../../../components/svg/TxnApiIcon';
import StoreIcon from '../../../components/svg/StoreIcon';
import BasicPageLayout from '../../../components/BasicPageLayout';
import WidgetTile from '../../../components/WidgetTile';
import { SupplierReferralLink } from '../../../components/SupplierReferralLink';

const useStyles = makeStyles((theme) => ({
  header: {
    [theme.breakpoints.down('xs')]: {
      '& .MuiButton-root': {
        minWidth: 35,
        width: 35,
        height: 35,
      },
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  container: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    padding: theme.spacing(4, 4),
    fontSize: '14px',
  },
  marginBetweenContents: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const Resources = () => {
  const classes = useStyles();
  return (
    <BasicPageLayout noHeaderButGap={true}>
      <Paper className={classes.container}>
        <Typography variant='h3' component='h3' className={classes.title}>
          Get started
        </Typography>
        <Typography variant='body1' component='label'>
          Embed Shift into your business as a payment option online, in-store or your back-office ordering systems.
        </Typography>
        <Grid container>
          <Grid item xs={12} className={classes.marginBetweenContents}>
            <SupplierReferralLink />
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={4}>
              <WidgetTile
                id='uia-accountOpenWidget'
                heading='Website assets'
                content='Embed your unique landing page into your website to help educate and onboard customers to your new Trade Account program.'
                isActive
                redirectTo='/supplier/resources/widgets/account-opening'
                icon={<AccountOpenIcon />}
              />
            </Grid>
            <Grid item xs={4}>
              <WidgetTile
                id='uia-apiWidget'
                heading='API'
                content='Integrate Shift with your online check-out, in-store and back-office ordering system to easily accept payments using Shift.'
                redirectTo='/supplier/resources/widgets/transaction-api'
                isActive={true}
                icon={<TxnApiIcon />}
              />
            </Grid>
            <Grid item xs={4}>
              <WidgetTile
                id='uia-inStoreWidget'
                heading='Merchant resources'
                content='Embed Shift into your business as your new Trade Account Program with these onboarding, FAQs, in-store material and marketing tools.'
                redirectTo={'https://www.shift.com.au/merchant-resources/'}
                openNewTab={true}
                isActive={true}
                icon={<StoreIcon />}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </BasicPageLayout>
  );
};

export default Resources;
