/* eslint-disable no-param-reassign */
// @flow
import { Box, Button, Grid, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/styles';
import type { FormikBag } from 'formik';
import { Field, Form, withFormik } from 'formik';
import { TextField } from 'formik-material-ui';
import type { History, Location } from 'history';
import { withSnackbar } from 'notistack';
import React from 'react';
import { withRouter } from 'react-router';

import { formSchema } from './validations';
import BasicPageLayout from '../../../components/BasicPageLayout';
import FormikPhoneNumberField from '../../../components/formik/FormikPhoneNumberField';
import FormItem from '../../../components/FormItem';
import { isMobileResolution } from '../../../lib/materialUiUtils';

const useStyles = makeStyles((theme) => ({
  fields: {
    borderTop: `1px solid ${theme.palette.grey['300']}`,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 5),
    },
  },
  marginWithSpace: {
    marginTop: theme.spacing(2),
  },
  fieldmargin: {
    marginLeft: theme.spacing(0),
  },
  pageText: {
    fontsize: '12px',
    fontWeight: 'normal',
  },
  linkButton: {
    fontWeight: 'normal',
    [theme.breakpoints.up('xs')]: {
      width: '50%',
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      width: 90,
      marginRight: theme.spacing(1),
    },
  },
  submitButton: {
    minWidth: '10px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  formControl: {
    minWidth: '100%',
  },
  row: {
    margin: theme.spacing(0.5),
  },
}));

type staff = {
  id: number,
  firstName: string,
  lastName: string,
  mobileNumber: string,
};

type FormValues = {
  staffs: Array<staff>,
};

type Props = {
  width: string,
  gcAccountId: string,
  addStaffs: (staffs: Array<staff>, gcAccountId: string) => Promise<any>,
  setStaffPageVisibility: (gcAccountId: string) => Promise<any>,
};

type WithHOCProps = {
  ...Props,
  isSubmitting: boolean,
  values: FormValues,
  history: History<Location>,
  errors: any,
  touched: any,
  setFieldError: (fieldName: string, error: string) => void,
};

const AddAPMStaffs = ({
  width,
  gcAccountId,
  setStaffPageVisibility,
  isSubmitting,
  values,
  history,
  errors,
  touched,
  setFieldError,
}: WithHOCProps) => {
  const classes = useStyles();
  const isMobile = isMobileResolution(width);

  const handleSkip = () => {
    setStaffPageVisibility(gcAccountId);
    history.push({
      pathname: '/buyer/dashboard',
    });
  };
  const [staffs, setStaffsState] = React.useState(values.staffs);
  const handleAddStaff = () => {
    if (staffs.length <= 5) {
      staffs.push({
        id: staffs.length,
        firstName: '',
        lastName: '',
        mobileNumber: '',
      });
    }
    setStaffsState([...staffs]);
    values.staffs = staffs;
  };
  const handleChange = (event, index) => {
    const fieldName = !event.target.id ? `staffs[${index}].${event.name}` : event.target.id;
    setFieldError(fieldName, '');
    const updatedStaffs = [...staffs]; // copying the old datas array
    let item = updatedStaffs[index];
    item = { ...item, [event.name]: event.target.value };
    updatedStaffs[index] = item;

    setStaffsState([...updatedStaffs]);
    values.staffs = updatedStaffs;
  };
  return (
    <BasicPageLayout title='Review transaction' noMargin={!isMobile}>
      <Form noValidate autoComplete='off'>
        <Paper className={classes.marginWithSpace}>
          <Box className={classes.fields}>
            <Grid item xs={12} sm={12}>
              <Typography variant='h2' component='h4'>
                Add your Accounts Payable staff
              </Typography>
            </Grid>
            <Grid container spacing={2} className={classes.marginWithSpace}>
              <Grid item xs={12} sm={12}>
                <Typography className={classes.pageText}>
                  If you would like to grant access to your Accounts Payable staff to manage your invoices on Shift, please add their name and mobile
                  number below and they will be sent an invite.
                </Typography>
              </Grid>
              {staffs.map((input, i) => (
                <Grid key={input.id} container spacing={2} className={classes.fieldmargin}>
                  <Grid item xs={12} sm={3}>
                    <FormItem label='First name'>
                      <Field
                        component={TextField}
                        variant='outlined'
                        fullWidth
                        id={`staffs[${i}].firstName`}
                        name={`staffs[${i}].firstName`}
                        data-testid={`uia-staffs[${i}]-firstName`}
                        placeholder='Enter the first name'
                        inputProps={{
                          maxLength: 50,
                          onChange: (event) => {
                            event.name = 'firstName';
                            handleChange(event, i);
                          },
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        value={`${staffs[i].firstName}`}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormItem label='Last name'>
                      <Field
                        component={TextField}
                        variant='outlined'
                        fullWidth
                        id={`staffs[${i}].lastName`}
                        name={`staffs[${i}].lastName`}
                        data-testid={`uia-staffs[${i}]-lastName`}
                        placeholder='Enter the last name'
                        inputProps={{
                          maxLength: 50,
                          onChange: (event) => {
                            event.name = 'lastName';
                            handleChange(event, i);
                          },
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        value={`${staffs[i].lastName}`}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormItem label='Mobile number'>
                      <Field
                        component={FormikPhoneNumberField}
                        variant='outlined'
                        id={`staffs[${i}].mobileNumber`}
                        name={`staffs[${i}].mobileNumber`}
                        fullWidth
                        pattern='^4[0-9]{2} [0-9]{3} [0-9]{3}'
                        format='### ### ###'
                        InputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                          classes: {
                            root: classes.inputBase,
                          },
                          onChange: (event) => {
                            event.name = 'mobileNumber';
                            handleChange(event, i);
                          },
                          error:
                            touched && touched.staffs && errors && errors.staffs && !!errors.staffs[`${i}`] && !!errors.staffs[`${i}`].mobileNumber,
                        }}
                        data-testid={`uia-staffs[${i}]-mobileNumber`}
                        value={`${staffs[i].mobileNumber}`}
                      />
                    </FormItem>
                  </Grid>
                  {isMobile ? <Grid item xs={12} sm={4} /> : null}
                </Grid>
              ))}
              <Grid>
                {staffs && staffs.length < 5 ? (
                  <Button color='primary' disabled={isSubmitting} data-testid='uia-addStaffButton' onClick={handleAddStaff}>
                    + Add more
                  </Button>
                ) : null}
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.marginWithSpace}>
              <Grid item xs={12} sm={12}>
                <Typography className={classes.pageText}>
                  Your Accounts Payable staff will be able to process invoices and add new suppliers but they will not be able to increase your limit
                  or change your payment method.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Box display='flex' justifyContent={isMobile ? 'inherit' : 'flex-end'} className={classes.marginWithSpace}>
          <Button className={classes.linkButton} color='primary' disabled={isSubmitting} data-testid='uia-skipButton' onClick={handleSkip}>
            Skip
          </Button>
          <Button
            className={classes.submitButton}
            variant='contained'
            color='primary'
            type='submit'
            disabled={isSubmitting}
            data-testid='uia-SaveButton'
          >
            Save & Finish
          </Button>
        </Box>
      </Form>
    </BasicPageLayout>
  );
};

type WrapperProps = {
  ...Props,
  setError: (error: ?string) => void,
  history: History<Location>,
};

export default withRouter<WrapperProps>(
  withSnackbar(
    withFormik({
      mapPropsToValues: (): FormValues => ({
        staffs: [
          {
            id: 0,
            firstName: '',
            lastName: '',
            mobileNumber: '',
          },
        ],
      }),
      validateOnBlur: false,
      validationSchema: formSchema,
      handleSubmit: (values: FormValues, formikBag: FormikBag<WrapperProps, FormValues>) => {
        const { props } = formikBag;
        const staffs = values.staffs.map((element) => {
          if (element.mobileNumber !== '0' || element.mobileNumber !== '') {
            element.mobileNumber = `0${element.mobileNumber}`;
          }
          return element;
        });
        props.addStaffs(staffs, props.gcAccountId);
        formikBag.setSubmitting(false);
        const { amount, invoiceNumber, gcAccountId, supplierAccountName, isSupplierInitiated } = (props.history.location.state: any);
        props.history.push({
          pathname: '/buyer/payments/paid',
          state: {
            amount,
            supplierAccountName,
            gcAccountId,
            invoiceNumber,
            isSupplierInitiated,
          },
        });
      },
      displayName: 'AddStaffs',
    })(withWidth()(AddAPMStaffs))
  )
);
