import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AccountSetupCheck from './Component';
import actions from './actions';
import selectors from './selectors';
import initialDataSelectors from '../InitialData/selectors';
import userProfileModeSelectors from '../UserSelectProfileMode/selectors';
import { SpStatus } from '../../types';

const mapStateToProps = createSelector(
  selectors.selectIsBankAccountSetupComplete,
  selectors.selectBankAccountSetup,
  selectors.selectIsDirectDebitAgreementSetupComplete,
  selectors.selectIsLoanAgreementSetupComplete,
  selectors.selectIsTermsAndConditionsAgreementSetupComplete,
  selectors.selectIsPrivacyAgreementSetupComplete,
  selectors.selectIsAccountSetupLoaded,
  initialDataSelectors.selectActiveAccount,
  userProfileModeSelectors.selectOpenTradeAccount,
  (
    isBankAccountSetupComplete,
    bankAccountSetup,
    isDirectDebitAgreementSetupComplete,
    isLoanAgreementSetupComplete,
    isTermsAndConditionsAgreementSetupComplete,
    isPrivacyAgreementSetupComplete,
    isAccountSetupLoaded,
    account,
    openTradeAccount
  ) => {
    return {
      isBankAccountSetupComplete,
      bankAccountSetup,
      isDirectDebitAgreementSetupComplete,
      isLoanAgreementSetupComplete: isLoanAgreementSetupComplete && account && account.spStatus.toLowerCase() !== SpStatus.NotSigned,
      isTermsAndConditionsAgreementSetupComplete,
      isPrivacyAgreementSetupComplete,
      isAccountSetupLoaded,
      gcAccountId: account && account.gcAccountId,
      account: account,
      openTradeAccount,
    };
  }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AccountSetupCheck);
