// @flow
import React, { useEffect, useState } from 'react';
import { FormControl, makeStyles, Select, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { type Account, type ReferralAccount } from '../../types';
import ConfirmationDialog from '../ConfirmationDialog';
import { withSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  main: {
    [theme.breakpoints.up('xs')]: {
      maxWidth: 500,
      padding: theme.spacing(1, 2, 2, 2),
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.up('sm')]: {
      width: 500,
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3, 3, 4, 3),
      margin: theme.spacing(1, 3),
    },
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
  },
  continueButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: 150,
    },
  },
  formControl: {
    minWidth: '65%',
    '& .MuiSelect-select': {
      height: theme.spacing(1.8),
    },
    marginBottom: theme.spacing(1.5),
  },
  contentText: {
    marginBottom: theme.spacing(1.5),
  },
}));

type Props = {
  accounts: Array<Account>,
  referralAccount?: ReferralAccount,
  activeAccountIndex: number,
  getAccountName: (gcAccountId: string) => Promise<any>,
  addReferralAccountRelation: (gcAccountId: string, referralAccount?: ReferralAccount) => Promise<any>,
  enqueueSnackbar: (message: string, options?: Object) => void,
  closeSnackbar: () => void,
};

const AssociateWithReferer = (props: Props) => {
  const { accounts, activeAccountIndex, referralAccount, getAccountName, addReferralAccountRelation, closeSnackbar, enqueueSnackbar } = props;
  const classes = useStyles();
  const [supplierName, setSupplierName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({ gcAccountId: '' });

  useEffect(() => {
    if (!(accounts && referralAccount && referralAccount.hashedAccountId)) {
      return;
    }
    if (accounts.length === 1) {
      associateWithReferrer(accounts[0].gcAccountId, false);
      return;
    }
    setSelectedAccount(accounts[activeAccountIndex]);
    getAccountName(referralAccount.hashedAccountId).then((response) => {
      const { error, payload } = response;
      if (error) {
        return;
      }
      if (payload && payload.status === 200 && payload.data) {
        setSupplierName(payload.data.accountName);
      }
    });
  }, [referralAccount, accounts]);

  const isValid = (confirmation: boolean) => {
    if (confirmation && !selectedAccount) {
      return false;
    }
    return true;
  };
  const onSubmit = (confirmation: boolean) => {
    if (isValid(confirmation)) {
      if (!confirmation) {
        setSelectedAccount(accounts[activeAccountIndex]);
      }
      closeSnackbar();
      setIsSubmitting(true);
      associateWithReferrer(selectedAccount.gcAccountId, confirmation);
    }
  };

  const handleAccountChange = (event) => {
    setSelectedAccount(event.target.value);
  };

  const associateWithReferrer = (gcAccountId: string, showSnackBar: boolean) => {
    if (gcAccountId && referralAccount && referralAccount.hashedAccountId) {
      addReferralAccountRelation(gcAccountId, referralAccount).then((response) => {
        setIsSubmitting(false);

        if (response.payload && response.payload.status === 200 && showSnackBar) {
          enqueueSnackbar(`Associated with ${supplierName}`, {
            variant: 'success',
            key: 'assoicate-referer',
          });
        }
      });
    }
  };

  return (
    <>
      {accounts && referralAccount ? <ConfirmationDialog
          isOpen
          onClose={() => onSubmit(false)}
          onConfirm={() => onSubmit(true)}
          title='Choose account'
          subTitle={`Please select the account you would like to be associated with the ${supplierName}`}
          contentText=''
          id='confirmation'
          showChildren
          overrideActions
          actions={
            <>
              <Button onClick={() => onSubmit(false)} color='primary' disabled={isSubmitting} data-testid='uia-cancelButton'>
                Cancel
              </Button>
              <Button
                onClick={() => onSubmit(true)}
                color='primary'
                variant='contained'
                disabled={isSubmitting}
                autoFocus
                data-testid='uia-continueButton'
              >
                Continue
              </Button>
            </>
          }
        >
          <FormControl className={classes.formControl} variant='outlined'>
            <Typography className={classes.contentText} variant='h5'>
              Accounts
            </Typography>
            <Select
              name='selectedAccount'
              id='selectedAccount'
              data-testid='uia-selectedAccount'
              onChange={handleAccountChange}
              value={selectedAccount}
              disableUnderline
            >
              {accounts.map((account) => (
                <MenuItem key={account.gcAccountId} value={account}>
                  {account.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ConfirmationDialog> : null}
    </>
  );
};

export default withSnackbar(AssociateWithReferer);
