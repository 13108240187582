// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  theme: any,
};

const Icon = withTheme((props: Props) => (
  <SvgIcon style={{ width: '20', height: '21' }} viewBox='0 0 20 21' {...(props: any)}>
    <path d='M4.43026 1.41504C4.70262 1.10297 4.67043 0.629184 4.35835 0.356822C4.04628 0.0844593 3.5725 0.116654 3.30013 0.42873L2.00832 1.9089C1.27556 2.74851 0.861333 3.81902 0.838096 4.93318L0.781416 7.65083C0.77278 8.06496 1.10149 8.40767 1.51561 8.41631C1.92974 8.42494 2.27245 8.09623 2.28109 7.68211L2.33777 4.96445C2.35367 4.20214 2.63709 3.46968 3.13845 2.89521L4.43026 1.41504Z' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.23846 6.70282C4.37736 4.48209 6.21896 2.75208 8.44403 2.75208H9.00145V2.00208C9.00145 1.44979 9.44916 1.00208 10.0014 1.00208C10.5537 1.00208 11.0014 1.44979 11.0014 2.00208V2.75208H11.5589C13.7839 2.75208 15.6255 4.48209 15.7644 6.70282L15.9854 10.2363C16.0697 11.5835 16.522 12.8817 17.293 13.9897C17.9897 14.9907 17.3695 16.3733 16.1586 16.5186L12.7514 16.9275V18.0021C12.7514 19.5209 11.5202 20.7521 10.0014 20.7521C8.48266 20.7521 7.25145 19.5209 7.25145 18.0021V16.9275L3.84432 16.5186C2.63339 16.3733 2.01317 14.9907 2.70985 13.9897C3.48091 12.8817 3.9332 11.5835 4.01746 10.2363L4.23846 6.70282ZM8.44403 4.25208C7.01103 4.25208 5.82499 5.36625 5.73554 6.79646L5.51454 10.3299C5.41314 11.951 4.86889 13.5133 3.94104 14.8465C3.89068 14.9188 3.93551 15.0188 4.02304 15.0293L7.7607 15.4778C9.24919 15.6564 10.7537 15.6564 12.2422 15.4778L15.9798 15.0293C16.0674 15.0188 16.1122 14.9189 16.0618 14.8465C15.134 13.5133 14.5897 11.951 14.4883 10.3299L14.2673 6.79645C14.1779 5.36625 12.9919 4.25208 11.5589 4.25208H8.44403ZM10.0014 19.2521C9.31109 19.2521 8.75145 18.6924 8.75145 18.0021V17.2521H11.2514V18.0021C11.2514 18.6924 10.6918 19.2521 10.0014 19.2521Z'
    />
    <path d='M15.6445 0.356822C15.3325 0.629184 15.3003 1.10297 15.5726 1.41504L16.8644 2.89521C17.3658 3.46968 17.6492 4.20214 17.6651 4.96445L17.7218 7.68211C17.7304 8.09623 18.0732 8.42494 18.4873 8.41631C18.9014 8.40767 19.2301 8.06496 19.2215 7.65083L19.1648 4.93318C19.1416 3.81902 18.7273 2.74851 17.9946 1.9089L16.7028 0.42873C16.4304 0.116654 15.9566 0.0844593 15.6445 0.356822Z' />
  </SvgIcon>
));

export default Icon;
