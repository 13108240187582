import { ACTION_PREFIX as accountSelectPrefix, actionTypes as accountSelectActionTypes } from '../components/AccountSelectMenu/constants';
import { ACTION_PREFIX as initialDataPrefix, actionTypes as initialDataActionTypes } from '../components/InitialData/constants';
import accountSelectMenuActions from '../components/AccountSelectMenu/actions';
import applicationState from '../lib/applicationState';
import initialData from '../components/InitialData/selectors';

const INDEX_CHANGED = `${accountSelectPrefix}/${accountSelectActionTypes.UPDATE_ACTIVE_ACCOUNT_INDEX}`;
const ACCOUNTS_CHANGED = `${initialDataPrefix}/${initialDataActionTypes.GET_INITIAL_DATA_FULFILLED}`;

export default (store) => (next) => (action) => {
  const result = next(action);
  switch (action.type) {
    case ACCOUNTS_CHANGED: {
      const previousAccountId = applicationState.get().selectedAccountId;
      if (previousAccountId) {
        const index = action.payload.accounts.map((account) => account.gcAccountId).indexOf(previousAccountId);
        if (index !== -1) {
          store.dispatch(accountSelectMenuActions.updateActiveAccount(index));
        }
      }
      break;
    }
    case INDEX_CHANGED: {
      const selectedAccountId = initialData.selectSelectedGcAccountId(store.getState());
      if (selectedAccountId) {
        applicationState.set({ selectedAccountId });
      }
      break;
    }
    default:
      break;
  }
  return result;
};
