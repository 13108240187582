// @flow
import * as React from 'react';
import { Box, Hidden, makeStyles, Tooltip, Typography, ClickAwayListener } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import InfoIcon from './svg/InfoIcon';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 15,
    width: 15,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1),
    },
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.common.ripTide}`,
    borderRadius: 0,
    maxWidth: '100%',
  },
  containerDisabled: {
    color: theme.palette.grey.text,
  },
  container: {
    cursor: 'pointer',
  },
}));

type Props = {
  children: React.Node,
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top',
  label: string,
  disabled: boolean,
};

const InfoTooltip = (props: Props) => {
  const { children, placement = 'right', label, disabled } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  if (disabled) {
    return (
      <Box display='flex' className={classes.containerDisabled}>
        <InfoIcon className={classes.icon} viewBox='0 0 26 26' style={{ alignSelf: 'center' }} color={theme.palette.grey.text} />
        <Hidden xsDown>
          <Typography variant='body1'>{label}</Typography>
        </Hidden>
      </Box>
    );
  }

  const handleTooltipClick = () => {
    setOpen(!open);
  };

  const handleTooltipClose = () => setOpen(false);

  return (
    <>
      <Hidden xsDown>
        <Tooltip
          title={children}
          placement={placement}
          classes={{
            tooltip: classes.tooltip,
          }}
        >
          <Box display='flex' className={classes.container}>
            <InfoIcon className={classes.icon} viewBox='0 0 26 26' style={{ alignSelf: 'center' }} color={theme.palette.primary.main} />
            <Typography variant='body1'>{label}</Typography>
          </Box>
        </Tooltip>
      </Hidden>
      <Hidden smUp>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            title={children}
            placement={placement}
            classes={{
              tooltip: classes.tooltip,
            }}
            open={open}
          >
            <Box display='flex' className={classes.container} onClick={handleTooltipClick}>
              <InfoIcon className={classes.icon} viewBox='0 0 26 26' style={{ alignSelf: 'center' }} color={theme.palette.primary.main} />
            </Box>
          </Tooltip>
        </ClickAwayListener>
      </Hidden>
    </>
  );
};

export default InfoTooltip;
