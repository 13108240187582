import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Component from './Component';
import actions from './actions';
import initialDataSelectors from '../../../components/InitialData/selectors';
import startupConfigSelectors from '../../../components/StartupConfig/selectors';

const mapStateToProps = createSelector(
  initialDataSelectors.selectSelectedGcAccountId,
  startupConfigSelectors.selectTrustDeedAttachment,
  (gcAccountId, trustDeedAttachmentConfig) => ({
    gcAccountId,
    trustDeedAttachmentConfig,
  })
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Component);
