// @flow
import { handleActions } from 'redux-actions';
import { PARTITION, actionTypes, ACTION_PREFIX } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import type { BankLoginAccountOptions } from '../AddBankConnectForm';

export type ReduxState = {
  bankLoginAccountResult: ?BankLoginAccountOptions,
};

const initialState: ReduxState = {
  bankLoginAccountResult: null,
};

const reducer = handleActions(
  {
    [actionTypes.SET_BANK_LOGIN_ACCOUNT_RESULT]: (state, { payload }) => {
      let bankLoginAccountResult = null;
      if (payload && payload.data && payload.data.result) {
        bankLoginAccountResult = payload.data.result;
      }
      return {
        ...state,
        bankLoginAccountResult,
      };
    },
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
