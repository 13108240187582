// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  theme: any,
};

const LinkIcon = withTheme((props: Props) => (
  <SvgIcon style={{ height: '3rem', width: '4rem', backgroundColor: props.theme.palette.common.lightYellow }} viewBox='0 0 72 50' {...(props: any)}>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='account_open_icon' transform='translate(11.049073, 12.258542)' fill={props.theme.palette.common.orange}>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.53993 13.5041C11.4718 15.1658 14.3909 15.065 16.2172 13.2387L20.5636 8.89201C22.4788 6.97581 22.4788 3.85146 20.5636 1.93634C18.6474 0.0212197 15.523 0.0212197 13.6078 1.93634L9.47845 6.06566C9.30074 6.23856 9.20108 6.47389 9.19868 6.72124C9.19748 6.96739 9.29474 7.20511 9.46884 7.37921C9.64414 7.55452 9.88068 7.65177 10.128 7.64937C10.3742 7.64817 10.6107 7.54731 10.7824 7.36961V7.37081L14.9118 3.24149C16.1269 2.02639 18.0434 2.02639 19.2585 3.24149C20.4736 4.45655 20.4736 6.37303 19.2585 7.58812L14.9118 11.9348C13.7533 13.0946 11.957 13.1439 10.7418 12.0981C10.5557 11.9372 10.3132 11.8567 10.0682 11.8759C9.82209 11.8939 9.59516 12.0104 9.43426 12.1965C9.27457 12.3838 9.19652 12.6264 9.21573 12.8725C9.23494 13.1175 9.35142 13.3444 9.53993 13.5029V13.5041ZM1.43637 21.0637C3.3526 22.9788 6.477 22.9788 8.39216 21.0637L8.39246 21.0625L12.5219 16.9332C12.6984 16.7615 12.7992 16.5262 12.8004 16.2788C12.8016 16.0327 12.7044 15.7962 12.5303 15.6221C12.3562 15.4468 12.1184 15.3495 11.8723 15.3519C11.6261 15.3531 11.3896 15.4528 11.2179 15.6293L7.08849 19.7586C5.8734 20.9737 3.95689 20.9737 2.74177 19.7586C1.52665 18.5435 1.52665 16.6271 2.74177 15.412L7.08849 11.0653C8.24705 9.90544 10.0433 9.85623 11.2585 10.902C11.6463 11.2334 12.2311 11.1878 12.5624 10.8C12.8951 10.4121 12.8494 9.82739 12.4604 9.496C10.5285 7.83425 7.60937 7.9351 5.78308 9.76136L1.43637 14.108C-0.478789 16.0242 -0.478789 19.1485 1.43637 21.0637Z'
          fill='#E5A923'
        />
      </g>
    </g>
  </SvgIcon>
));

export default LinkIcon;
