// @flow
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import AbnAutoSuggest, { type EntitySuggestionItem } from '../AbnAutoSuggest';
import AlertInfo from '../AlertInfo';
import initialDataSelectors from '../InitialData/selectors';
import actions from './actions';
import { type EntityModeEnum, EntityMode } from './constants';

const useStyles = makeStyles((theme) => ({
  alertInfo: {
    marginTop: theme.spacing(3),
  },
}));

type Props = {
  onSelectionChanged: (entity: EntitySuggestionItem, relatedEntity: ?EntitySuggestionItem, validationResponse: any) => void,
  searchLabel: string,
  selectionMode: EntityModeEnum,
  searchDelay?: number,
  defaultSelectedAbn: string,
  enableTrustAndTrustee?: boolean,
};

const BusinessSearch = (props: Props) => {
  const {
    onSelectionChanged,
    searchLabel,
    selectionMode = EntityMode.Default,
    searchDelay = 1000,
    defaultSelectedAbn = '',
    enableTrustAndTrustee = true,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedEntity, setSelectedEntity] = useState<any>(null);
  const gcAccountId = (useSelector(initialDataSelectors.selectSelectedGcAccountId): any);
  const [entityMode, setEntityMode] = useState<EntityModeEnum>(EntityMode.Default);

  const searchBusiness = (search: string) => dispatch(actions.searchBusiness(search));
  const searchRelatedCompany = (search: string) => dispatch(actions.searchRelatedCompany(search));
  const searchRelatedTrust = (search: string) => dispatch(actions.searchRelatedTrust(search));

  const handleEntitySelectionChanged = (e, data: { suggestion: EntitySuggestionItem }) => {
    setSelectedEntity(data.suggestion);

    dispatch(actions.validateEntity(gcAccountId, data.suggestion.id, data.suggestion.isTrustee)).then((response) => {
      if (!response.error) {
        if (enableTrustAndTrustee) {
          const { locateTrustee = false, locateTrust = false } = response.payload.data;

          if (locateTrust && !selectedEntity) {
            setEntityMode(EntityMode.Trust);
          } else if (locateTrustee && !selectedEntity) {
            setEntityMode(EntityMode.Trustee);
          }
        }
      }

      onSelectionChanged(data.suggestion, null, response);
    });
  };

  const handleRelatedEntitySelectionChanged = (e, data: { suggestion: EntitySuggestionItem }) => {
    dispatch(actions.validateRelatedEntity(gcAccountId, selectedEntity.id, data.suggestion.id, data.suggestion.isTrustee)).then((response) => {
      onSelectionChanged(selectedEntity, data.suggestion, response);
    });
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='subtitle2' component='label'>
            {selectionMode === EntityMode.Default && searchLabel}
            {selectionMode === EntityMode.Trust && 'Please search for the Related Trust'}
            {selectionMode === EntityMode.Trustee && 'Please search for the company acting as Trustee'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {selectionMode === EntityMode.Default && (
            <AbnAutoSuggest
              onSuggestionSelected={handleEntitySelectionChanged}
              delay={searchDelay}
              getSuggestions={searchBusiness}
              defaultSelectedAbn={defaultSelectedAbn}
            />
          )}
          {selectionMode === EntityMode.Trust && (
            <AbnAutoSuggest onSuggestionSelected={handleRelatedEntitySelectionChanged} delay={searchDelay} getSuggestions={searchRelatedTrust} />
          )}
          {selectionMode === EntityMode.Trustee && (
            <AbnAutoSuggest onSuggestionSelected={handleRelatedEntitySelectionChanged} delay={searchDelay} getSuggestions={searchRelatedCompany} />
          )}
        </Grid>
      </Grid>
      {enableTrustAndTrustee && selectionMode === EntityMode.Default && entityMode === EntityMode.Trust ? <AlertInfo className={classes.alertInfo} message='This business has been identified as a trust' /> : null}
      {enableTrustAndTrustee && selectionMode === EntityMode.Default && entityMode === EntityMode.Trustee ? <AlertInfo className={classes.alertInfo} message='This business has been identified as a trustee' /> : null}
    </Box>
  );
};

export default BusinessSearch;
