// @flow
import { PARTITION } from './constants';
import type { ReduxState } from './reducer';

const selectPartition = (state: any): ReduxState => state[PARTITION];
const selectBankLoginAccountResult = (state: any) => {
  return selectPartition(state).bankLoginAccountResult;
};

export default {
  selectPartition,
  selectBankLoginAccountResult,
};
