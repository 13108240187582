// @flow
export const ACTION_PREFIX = 'BUSINESS_SEARCH';
export const PARTITION = 'businessSearch';

export const actionTypes = {
  SEARCH_BUSINESS_PENDING: 'SEARCH_BUSINESS_PENDING',
  SEARCH_BUSINESS_FULFILLED: 'SEARCH_BUSINESS_FULFILLED',
  SEARCH_BUSINESS_REJECTED: 'SEARCH_BUSINESS_REJECTED',
  VALIDATE_ENTITY_PENDING: 'VALIDATE_ENTITY_PENDING',
  VALIDATE_ENTITY_FULFILLED: 'VALIDATE_ENTITY_FULFILLED',
  VALIDATE_ENTITY_REJECTED: 'VALIDATE_ENTITY_REJECTED',
};

export const EntityMode = {
  Default: 'Default',
  Trust: 'Trust',
  Trustee: 'Trustee',
};

export type EntityModeEnum = $Keys<typeof EntityMode>;
