// @flow
import React, { useRef, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { PreviewIcon, LoadingIcon } from '../../svg';
import EmbedCode from '../EmbedCode';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.grey['200'],
    padding: theme.spacing(4),
  },
  noPreview: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  previewTextHeading: {
    fontSize: '11px',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
  },
  previewTextSubHeading: {
    fontSize: '8px',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    color: theme.palette.grey.light,
  },
  previewIFrame: {
    border: 0,
    height: 60,
    margin: 'auto',
    width: '100%',
  },
  preview: {
    display: 'flex',
    margin: theme.spacing(2.5, 4),
  },
  generatingPreview: {
    margin: 'auto',
  },
}));

type Props = {
  embeddableCode: string,
  isLoading: boolean,
};

const WidgetPreview = (props: Props) => {
  const { embeddableCode, isLoading } = props;
  const classes = useStyles();
  const [isRendering, setIsRendering] = useState(false);
  const elIFrame = useRef();
  const theme = useTheme();

  useEffect(() => {
    if (embeddableCode && elIFrame && elIFrame.current) {
      setIsRendering(true);

      // $FlowFixMe
      elIFrame.current.onload = () => {
        // $FlowFixMe
        elIFrame.current.onload = undefined;
        setIsRendering(false);
      };

      // $FlowFixMe
      const doc = elIFrame.current.contentDocument;
      doc.open();
      doc.write(embeddableCode);
      doc.close();
    }
  }, [embeddableCode, elIFrame]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='subtitle2' component='label'>
              Preview
            </Typography>
          </Grid>
          {(isLoading || isRendering) ? <Grid item xs={12} className={classes.preview}>
              <Box display='flex' className={classes.generatingPreview}>
                <LoadingIcon fill={theme.palette.primary.main} />
              </Box>
            </Grid> : null}
          {embeddableCode ? <>
              <Grid item xs={12} className={classes.preview}>
                {/* eslint-disable-next-line react/self-closing-comp */}
                <iframe
                  className={classes.previewIFrame}
                  title='Preview'
                  ref={elIFrame}
                  style={{ display: isLoading || isRendering ? 'none' : 'block' }}
                  data-testid='uia-preview-iframe'
                ></iframe>
              </Grid>
              <Grid item xs={12}>
                <EmbedCode embeddableCode={embeddableCode} />
              </Grid>
            </> : null}
          {!isLoading && !isRendering && !embeddableCode && (
            <Grid item xs={12}>
              <Box className={classes.noPreview}>
                <PreviewIcon />
                <Box className={classes.previewTextHeading}>
                  <Typography variant='subtitle2' component='label'>
                    No preview available
                  </Typography>
                </Box>
                <Box className={classes.previewTextSubHeading}>
                  <Typography variant='body2' alignCenter component='label'>
                    To generate a button, choose the terms & theme above.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WidgetPreview;
