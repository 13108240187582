// @flow
// $FlowFixMe Flow doesn't like type re-exports
import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import initialDataSelectors from '../../components/InitialData/selectors';
import welcomePageActions from './actions';
import { updateActiveAccount } from '../../components/AccountSelectMenu/actions';
import { selectors as settingsPageSelectors, actions as settingsPageActions } from '../Settings';
import { selectors as accountSetupCheckSelectors } from '../../components/AccountSetupCheck';
import { selectors as userSelectProfileModeSelectors } from '../../components/UserSelectProfileMode';

import WelcomePage from './Component';

const setActiveAccount = (accounts, gcAccountId: string) => {
  if (accounts) {
    const indexOfActiveAccount = accounts.findIndex((gcAccount) => gcAccount.gcAccountId === gcAccountId);
    if (indexOfActiveAccount !== -1) {
      updateActiveAccount(indexOfActiveAccount);
    }
  }
};

const mapStateToProps = createSelector(
  initialDataSelectors.selectAccounts,
  initialDataSelectors.selectSelectedAccountIsNotSigned,
  initialDataSelectors.selectFirstName,
  settingsPageSelectors.selectDirectDebitAccount,
  settingsPageSelectors.selectPreApprovalDetail,
  accountSetupCheckSelectors.selectTermsAndConditionsAgreementSetup,
  accountSetupCheckSelectors.selectIsAccountSetupLoaded,
  accountSetupCheckSelectors.selectIsPrivacyAgreementSetupComplete,
  initialDataSelectors.selectActiveAccount,
  userSelectProfileModeSelectors.selectOpenTradeAccount,
  (
    accounts,
    selectedAccountIsNotSigned,
    contactFirstName,
    directDebitAccounts,
    preApprovalDetail,
    termsAndConditionsAgreementSetup,
    isAccountSetupLoaded,
    isPrivacyAgreementSetupComplete,
    account,
    openTradeAccount
  ) => {
    // To be handled - multiple directDebitAccounts; Please refer reducer for settingsPage
    const directDebitAccount = directDebitAccounts ? directDebitAccounts[0] : null;
    const isBankSetupDone = directDebitAccount != null;
    const preApprovedLimitAmount = preApprovalDetail && preApprovalDetail.isPreApprovedLimit ? preApprovalDetail.preApprovedLimit : null;

    return {
      preApprovedLimitAmount,
      isBankSetupDone,
      contactFirstName,
      termsAndConditionsAgreementSetup,
      updateActiveAccount: (gcAccountId) => setActiveAccount(accounts, gcAccountId),
      selectedAccountIsNotSigned,
      isAccountSetupLoaded,
      isPrivacyAgreementSetupComplete,
      account,
      openTradeAccount,
    };
  }
);

const actions = {
  ...settingsPageActions,
  ...welcomePageActions,
};

const mapDispatchToProps = (dispatch: any): any => bindActionCreators(actions, dispatch);

export default connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps)(WelcomePage);
