// @flow
import { createActions } from 'redux-actions';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';

export const { updateProfileModeIndex, updateOpenTradeAccount } = createActions(
  actionTypes.UPDATE_PROFILE_MODE_INDEX,
  actionTypes.UPDATE_OPEN_TRADE_ACCOUNT,
  getActionOptions(ACTION_PREFIX)
);

export default {
  updateProfileModeIndex,
  updateOpenTradeAccount,
};
