import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CardContent from '@material-ui/core/CardContent';

import PadlockIcon from './svg/PadlockIcon';
import CheckIcon from './svg/CheckIcon';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.success.lighter,
      borderTop: `1px solid ${theme.palette.grey['300']}`,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3),
      },
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2, 5),
      },
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    checkCircleIcon: {
      width: '10px',
      height: '10px',
      margin: theme.spacing(1),
    },
    padlockIcon: {
      width: '24px',
      height: '24px',
    },
    listItem: {
      padding: theme.spacing(0, 1, 0, 3),
    },
  };
});

export default function CardContentSecurity() {
  const classes = useStyles();

  return (
    <CardContent className={classes.root}>
      <Box display='flex' flexDirection='row' alignItems='center'>
        <Box mr={1}>
          <PadlockIcon viewBox='0 0 24 24' className={classes.padlockIcon} />
        </Box>
        <Typography variant='h5' style={{ display: 'inline' }}>
          Your security matters to us
        </Typography>
      </Box>

      <List>
        <ListItem className={classes.listItem}>
          <Box display='flex' flexDirection='row'>
            <CheckIcon className={classes.checkCircleIcon} viewBox='0 0 10 10' htmlColor='#3DB038' />
            <ListItemText
              primary={
                <>
                  <Typography component='span' variant='subtitle2'>
                    Data encryption:{' '}
                  </Typography>
                  <Typography component='span' variant='body1'>
                    All data sent via Bank Connect is securely encrypted to the same level that banks use.
                  </Typography>
                </>
              }
            />
          </Box>
        </ListItem>

        <ListItem className={classes.listItem}>
          <Box display='flex' flexDirection='row'>
            <CheckIcon className={classes.checkCircleIcon} viewBox='0 0 10 10' htmlColor='#3DB038' />

            <ListItemText
              primary={
                <>
                  <Typography component='span' variant='subtitle2'>
                    Privacy policy:{' '}
                  </Typography>
                  <Typography component='span' variant='body1'>
                    Keeping data secure is our top priority and we adhere to Australian privacy principles and laws.
                  </Typography>
                </>
              }
            />
          </Box>
        </ListItem>

        <ListItem className={classes.listItem}>
          <Box display='flex' flexDirection='row'>
            <CheckIcon className={classes.checkCircleIcon} viewBox='0 0 10 10' htmlColor='#3DB038' />

            <ListItemText
              primary={
                <>
                  <Typography component='span' variant='subtitle2'>
                    Secure environment:{' '}
                  </Typography>
                  <Typography component='span' variant='body1'>
                    Servers are in secure Australian data centres.
                  </Typography>
                </>
              }
            />
          </Box>
        </ListItem>
      </List>
    </CardContent>
  );
}
