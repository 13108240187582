// @flow
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid, Typography } from '@material-ui/core';

import InfoTooltip from '../../../components/InfoTooltip';

const useStyles = makeStyles((theme) => ({
  orLabel: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  hardCodedValues: {
    color: theme.palette.grey.light,
  },
  inputtedValues: {
    fontWeight: 500,
  },
  row: {
    marginTop: theme.spacing(1.5),
  },
  amount: {
    textAlign: 'right',
  },
  container: {
    border: `1px dashed ${theme.palette.common.ripTide}`,
    margin: theme.spacing(1.5),
    padding: theme.spacing(1),
    minWidth: 250,
    width: 'auto',
  },
}));

type Props = {
  accountName: string,
  description: string,
  amount: string,
};

const ExampleTooltip = (props: Props) => {
  const { accountName = '', description = '', amount } = props;
  const classes = useStyles();

  return (
    <InfoTooltip placement='top-end' label='How will the supplier see the payment?' disabled={!amount}>
      <Box className={classes.container}>
        <Grid container className={classes.row}>
          <Grid item xs={6}>
            <Typography variant='body2' className={classes.hardCodedValues}>
              ABC RESTAURANT
            </Typography>
            <Typography variant='subtitle1' className={classes.hardCodedValues}>
              Coffee beans
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.amount}>
            <Typography variant='body2' className={classes.hardCodedValues}>
              $290
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.row}>
          <Grid item xs={6}>
            <Typography variant='body2' className={classes.inputtedValues}>
              {accountName.toUpperCase()}
            </Typography>
            <Typography variant='subtitle1' className={classes.inputtedValues}>
              {description}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.amount}>
            <Typography variant='body2' className={classes.inputtedValues}>
              ${amount}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.row}>
          <Grid item xs={6}>
            <Typography variant='body2' className={classes.hardCodedValues}>
              BLUE BEACH CAFE
            </Typography>
            <Typography variant='subtitle1' className={classes.hardCodedValues}>
              Blue tea order
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.amount}>
            <Typography variant='body2' className={classes.hardCodedValues}>
              $90
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </InfoTooltip>
  );
};

export default ExampleTooltip;
