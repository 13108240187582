// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  theme: any,
};

const SuccessIcon = withTheme(({ theme, ...props }: Props) => (
  <SvgIcon {...props} style={{ alignSelf: 'baseline' }}>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-93.000000, -372.000000)'>
        <g transform='translate(74.000000, 350.000000)'>
          <g id='success' transform='translate(19.000000, 22.000000)'>
            <g>
              <g id='Oval'>
                <circle fill={theme.palette.success.main} cx='11' cy='11' r='10.5' />
              </g>
            </g>
            <g transform='translate(4.500000, 5.500000)' fill={theme.palette.common.white}>
              <g>
                <path d='M11.6215015,2.32933006 L5.02778304,9.26116902 C4.6031176,9.70761097 3.91561574,9.70761097 3.4920364,9.26116902 L0.737684582,6.36671806 C0.314105241,5.92027612 0.314105241,5.19751973 0.737684582,4.75107778 C1.16235002,4.30577764 1.84985188,4.30577764 2.27343122,4.75107778 L4.25990972,6.8394213 L10.0857548,0.714831572 C10.5093342,0.268389629 11.196836,0.268389629 11.6215015,0.714831572 C12.0461669,1.16013172 12.0461669,1.88288811 11.6215015,2.32933006' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
));

export default SuccessIcon;
