import * as React from 'react';
import { required } from 'redux-form-validators';
import { Field } from 'redux-form';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import { withRouter } from 'react-router-dom';

import BankLinkPadlockIcon from '../svg/BankLinkPadlockIcon';
import { renderTextField, RenderSelectField } from '../../lib/materialUiFormComponents';

const styles = (theme) => ({
  bankLinkFormContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
      margin: theme.spacing(1, 0),
      border: `1px solid ${theme.palette.grey['300']}`,
    },
  },
  captchaImage: {
    [theme.breakpoints.up('lg')]: {
      width: 'auto',
    },
    width: '100%',
  },
  submitButton: {
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 130,
    },
    marginRight: 10,
    margin: theme.spacing(2, 0),
  },
  cancelButton: {
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 90,
    },
    margin: theme.spacing(2, 0),
  },
  field: {
    margin: theme.spacing(1, 0),
  },
  fieldSubElement: {
    '& .MuiInputBase-input': {
      textAlign: 'center',
      [theme.breakpoints.only('xs')]: {
        padding: theme.spacing(1),
      },
    },
  },
  fieldSubElementDisabled: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.grey['100'],
    },
  },
});

const oneCharacterOnly = (value) => value.charAt(0);

const getPasswordFieldSubElements = (credential, classes, subIndex) => {
  return (
    <Grid key={`subElement-${subIndex}`} item xs>
      <Field
        name={credential.fieldID || `${credential.htmlAttrs.disabled}-subIndex`}
        type={credential.type.toLowerCase()}
        component={renderTextField}
        variant='outlined'
        disabled={!!credential.htmlAttrs.disabled}
        className={clsx({
          [classes.fieldSubElement]: true,
          [classes.fieldSubElementDisabled]: credential.htmlAttrs.disabled,
        })}
        normalize={oneCharacterOnly}
        {...(credential.htmlAttrs.disabled ? {} : { validate: [required({ message: ' ' })] })}
      />
    </Grid>
  );
};

const getField = (credential, classes, autoFocus) => {
  if (
    credential.type === 'set' &&
    credential.subElements.length > 0 &&
    credential.subElements.every((subElement) => subElement.type === 'password')
  ) {
    return (
      <Grid container spacing={0}>
        {credential.subElements.map((credentialSubElement, subIndex) => {
          return getPasswordFieldSubElements(credentialSubElement, classes, subIndex);
        })}
      </Grid>
    );
  }

  if (credential.type === 'set') {
    return credential.subElements.map((f) => getField(f, classes));
  }

  if (!credential.fieldID && (credential.type === 'instructions' || credential.type === 'header')) {
    return (
      <Typography variant='body2' gutterBottom>
        {credential.text}
      </Typography>
    );
  }

  if (credential.type === 'captcha') {
    return (
      <React.Fragment>
        <InputLabel>
          <Typography variant='h6' component='label'>
            {credential.type !== 'hidden' && credential.fieldID ? credential.fieldID.toUpperCase() : null}
          </Typography>
        </InputLabel>
        <img className={classes.captchaImage} src={credential.src} alt={credential.alt} />
        <Field
          name={credential.fieldID}
          type={credential.type.toLowerCase()}
          component={renderTextField}
          className={clsx(classes.textField, `uia-${credential.fieldID}`)}
          fullWidth
          inputProps={{ margin: 'none' }}
          variant='outlined'
          validate={[required()]}
        />
      </React.Fragment>
    );
  }

  if (credential.type === 'options') {
    return (
      <Field
        name={credential.fieldID}
        component={RenderSelectField}
        className={`uia-${credential.fieldID}`}
        variant='outlined'
        validate={[required()]}
      >
        <option value='' />
        {Object.keys(credential.values).map((option) => {
          return (
            <option key={credential.values[option]} value={option}>
              {credential.values[option]}
            </option>
          );
        })}
      </Field>
    );
  }

  if (credential.type === 'javascript') {
    return null;
  }

  return (
    <React.Fragment>
      <InputLabel>
        <Typography variant='h6' component='label'>
          {credential.type !== 'hidden' && credential.fieldID ? credential.fieldID.toUpperCase() : null}
        </Typography>
      </InputLabel>
      <Field
        name={credential.fieldID}
        type={credential.type.toLowerCase()}
        component={renderTextField}
        className={clsx(classes.textField, `uia-${credential.fieldID}`)}
        fullWidth
        inputProps={{ margin: 'none' }}
        variant='outlined'
        autoFocus={autoFocus}
        {...(credential.type !== 'hidden' ? { validate: [required()] } : {})}
      />
    </React.Fragment>
  );
};

class MultiFactorAuthenticationForm extends React.Component<any, any> {
  static displayName = 'MultiFactorAuthenticationForm';

  constructor(props) {
    super(props);
    this.state = {};
  }

  goBackToBankSearch() {
    this.props.history.push('/settings/add-bank');
  }

  render() {
    const { classes, bankLoginAccountResult } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} md={8} lg={6} className='uia-MultiFactorAuthenticationForm'>
          {bankLoginAccountResult ? <Box className={classes.bankLinkFormContainer} borderRadius={5}>
              <form noValidate autoComplete='off' onSubmit={this.props.handleSubmit}>
                <Box display='flex'>
                  <BankLinkPadlockIcon />
                  <Typography className={classes.logInto} display='inline'>
                    {this.props.bankLoginAccountResult.title}
                  </Typography>
                </Box>
                <Grid container>
                  {bankLoginAccountResult ? bankLoginAccountResult.fields.map((field, fieldIndex) => {
                      return (
                        <Grid
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${field.fieldID}-${fieldIndex}`}
                          item
                          xs={12}
                          className={classes.field}
                        >
                          {getField(field, classes, fieldIndex === 0)}
                        </Grid>
                      );
                    }) : null}
                </Grid>
                <Box display='flex'>
                  <Button
                    className={classes.submitButton}
                    fullWidth
                    variant='contained'
                    color='primary'
                    type='submit'
                    disabled={this.props.submitting}
                    data-testid='uia-connect-button'
                  >
                    Connect now
                  </Button>
                  <Button
                    className={classes.cancelButton}
                    fullWidth
                    color='primary'
                    type='button'
                    disabled={this.props.submitting}
                    data-testid='uia-cancel-button'
                    onClick={() => this.goBackToBankSearch()}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </Box> : null}
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(MultiFactorAuthenticationForm));
