import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import InitialData from './Component';
import actions from './actions';
import selectors from './selectors';

const mapStateToProps = createSelector(selectors.selectJwtValues, selectors.selectAccounts, (jwtValues, accounts) => ({
  jwtValues,
  accounts,
}));

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InitialData);
