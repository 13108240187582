export const ACTION_PREFIX = 'ASSOCIATE_REFERER';
export const PARTITION = 'associateReferer';
export const SELECT_AN_ACCOUNT = 'SELECT_AN_ACCOUNT';

export const actionTypes = {
  ADD_REFERRAL_ACCOUNT_RELATION_PENDING: 'ADD_REFERRAL_ACCOUNT_RELATION_PENDING',
  ADD_REFERRAL_ACCOUNT_RELATION_FULFILLED: 'ADD_REFERRAL_ACCOUNT_RELATION_FULFILLED',
  ADD_REFERRAL_ACCOUNT_RELATION_REJECTED: 'ADD_REFERRAL_ACCOUNT_RELATION_REJECTED',
  GET_ACCOUNT_NAME_PENDING: 'GET_ACCOUNT_NAME_PENDING',
  GET_ACCOUNT_NAME_FULFILLED: 'GET_ACCOUNT_NAME_FULFILLED',
  GET_ACCOUNT_NAME_REJECTED: 'GET_ACCOUNT_NAME_REJECTED',
};
