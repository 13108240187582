// @flow
import React from 'react';
import Box from '@material-ui/core/Box';

import { BankConnectCards } from '../../components/BankConnectCards';
import { BankSearch } from '../../components/BankSearch';
import SetupPageLayout from '../../components/SetupPageLayout';
import CardContentSecurity from '../../components/CardContentSecurity';

const AddBank = () => {
  return (
    <SetupPageLayout
      title='Connect your bank account'
      subtitle='Select your bank from the choices below, or search for your bank to get started'
      footer={<CardContentSecurity />}
    >
      <BankConnectCards />
      <Box mb={2}>
        <BankSearch />
      </Box>
    </SetupPageLayout>
  );
};

export default AddBank;
