// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import { shiftPaymentsApi, abnSearchApi, registrationApi } from '../../api';

export const {
  searchBusinessPending,
  searchBusinessFulfilled,
  searchBusinessRejected,
  validateEntityPending,
  validateEntityFulfilled,
  validateEntityRejected,
} = createActions(
  actionTypes.SEARCH_BUSINESS_PENDING,
  actionTypes.SEARCH_BUSINESS_FULFILLED,
  actionTypes.SEARCH_BUSINESS_REJECTED,
  actionTypes.VALIDATE_ENTITY_PENDING,
  actionTypes.VALIDATE_ENTITY_FULFILLED,
  actionTypes.VALIDATE_ENTITY_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const searchBusiness = (search: string) => (dispatch: Dispatch<any>) => {
  dispatch(searchBusinessPending());

  return abnSearchApi
    .searchAbn(search)
    .then((response) => {
      return dispatch(searchBusinessFulfilled(response));
    })
    .catch((error) => {
      return dispatch(searchBusinessRejected(error));
    });
};

const searchRelatedCompany = (search: string) => (dispatch: Dispatch<any>) => {
  dispatch(searchBusinessPending());

  return registrationApi
    .searchCompany(search)
    .then((response) => {
      return dispatch(searchBusinessFulfilled(response));
    })
    .catch((error) => {
      return dispatch(searchBusinessRejected(error));
    });
};

const searchRelatedTrust = (search: string) => (dispatch: Dispatch<any>) => {
  dispatch(searchBusinessPending());

  return registrationApi
    .searchTrust(search)
    .then((response) => {
      return dispatch(searchBusinessFulfilled(response));
    })
    .catch((error) => {
      return dispatch(searchBusinessRejected(error));
    });
};

const validateEntity = (buyerGcAccountId: string, supplierAbnOrAcn: string, isTrustee: boolean) => (dispatch: Dispatch<any>) => {
  dispatch(validateEntityPending());

  return shiftPaymentsApi
    .validateNewSupplier(buyerGcAccountId, supplierAbnOrAcn, isTrustee)
    .then((response) => {
      return dispatch(validateEntityFulfilled(response));
    })
    .catch((error) => {
      return dispatch(validateEntityRejected(error));
    });
};

const validateRelatedEntity =
  (buyerGcAccountId: string, supplierAbnOrAcn: string, relatedEntityAbnOrAcn: string, isRelatedEntityATrustee: boolean) =>
  (dispatch: Dispatch<any>) => {
    dispatch(validateEntityPending());

    return shiftPaymentsApi
      .validateNewSupplierRelatedEntity(buyerGcAccountId, supplierAbnOrAcn, relatedEntityAbnOrAcn, isRelatedEntityATrustee)
      .then((response) => {
        return dispatch(validateEntityFulfilled(response));
      })
      .catch((error) => {
        return dispatch(validateEntityRejected(error));
      });
  };

export default {
  searchBusiness,
  searchRelatedCompany,
  searchRelatedTrust,
  validateEntity,
  validateRelatedEntity,
};
