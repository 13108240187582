// @flow
import { handleActions } from 'redux-actions';

import {
  actionTypes,
  loanAgreementPermissionName,
  ddrAgreementPermissionName,
  privacyAgreementPermissionName,
  PARTITION,
  ACTION_PREFIX,
} from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import { type RolePermissionMap } from '../../lib/ability';

export type ReduxState = {
  rolePermissionMap: Array<RolePermissionMap>,
  hasPermissionToSignPrivacyAgreement: boolean,
  hasPermissionToSignLoanAgreement: boolean,
  hasPermissionToSignDDRAgreement: boolean,
};

const initialState: ReduxState = {
  rolePermissionMap: [],
  hasPermissionToSignPrivacyAgreement: false,
  hasPermissionToSignLoanAgreement: false,
  hasPermissionToSignDDRAgreement: false,
};

const reducer = handleActions<ReduxState>(
  {
    [actionTypes.GET_ROLE_PERMISSION_MAP_FULFILLED]: (state, { payload }) => ({
      ...state,
      rolePermissionMap: payload,
      hasPermissionToSignPrivacyAgreement: payload.findIndex((e) => e.permissionName === privacyAgreementPermissionName) > -1,
      hasPermissionToSignLoanAgreement: payload.findIndex((e) => e.permissionName === loanAgreementPermissionName) > -1,
      hasPermissionToSignDDRAgreement: payload.findIndex((e) => e.permissionName === ddrAgreementPermissionName) > -1,
    }),
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
