import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { v4 as uuidv4 } from 'uuid';

const correlationId = uuidv4();

let reactPlugin = null;
let appInsights = null;

const createTelemetryService = () => {
  const initialize = (instrumentationKey, browserHistory, enhanceEnvelope) => {
    let key;
    let disableTelemetry;
    if (process.env.REACT_APP_STAND_ALONE && process.env.REACT_APP_AI_INSTRUMENTATION_KEY) {
      key = process.env.REACT_APP_AI_INSTRUMENTATION_KEY;
      disableTelemetry = true;
    } else {
      key = instrumentationKey;
      disableTelemetry = false;
    }

    if (!instrumentationKey) {
      throw new Error('Instrumentation key not provided');
    }

    if (!browserHistory) {
      throw new Error('Could not initialize Telemetry Service');
    }

    reactPlugin = new ReactPlugin();
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: key,
        disableTelemetry,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: browserHistory,
          },
        },
      },
    });

    appInsights.loadAppInsights();

    appInsights.addTelemetryInitializer((envelope) => {
      if (envelope.baseData.target && envelope.baseData.target.indexOf('mouseflow.com') > -1) {
        return false;
      }

      // eslint-disable-next-line no-param-reassign
      envelope.tags['ai.cloud.role'] = 'ShiftPayments-UI';
      // eslint-disable-next-line no-param-reassign
      envelope.data.CorrelationId = correlationId;
      const context = appInsights.context;
      if (context && context.telemetryTrace && context.telemetryTrace.traceID) {
          envelope.data.TraceId = context.telemetryTrace.traceID;
      }

      if (enhanceEnvelope) {
        enhanceEnvelope(envelope);
      }

      return true;
    });
  };

  return {
    reactPlugin,
    appInsights,
    initialize,
  };
};

export const ai = createTelemetryService();

export const getAppInsights = () => appInsights;

export const getCorrelationId = () => correlationId;

export const traceInfo = (message) => appInsights && appInsights.trackTrace({ message, severityLevel: SeverityLevel.Information });

export const traceError = (message) => appInsights && appInsights.trackTrace({ message, severityLevel: SeverityLevel.Error });
