// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import { shiftPaymentsApi } from '../../api';

export const { getRolePermissionMapPending, getRolePermissionMapFulfilled, getRolePermissionMapRejected } = createActions(
  actionTypes.GET_ROLE_PERMISSION_MAP_PENDING,
  actionTypes.GET_ROLE_PERMISSION_MAP_FULFILLED,
  actionTypes.GET_ROLE_PERMISSION_MAP_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getRolePermissionFilteredByHierarchy = (gcAccountId: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(getRolePermissionMapPending());

    return shiftPaymentsApi
      .getRolePermissionFilteredByHierarchy(gcAccountId)
      .then((response) => {
        return dispatch(getRolePermissionMapFulfilled(response.data));
      })
      .catch((error) => {
        return dispatch(getRolePermissionMapRejected(error));
      });
  };
};

export default {
  getRolePermissionFilteredByHierarchy,
};
