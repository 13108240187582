// @flow
import React from 'react';
import { Breadcrumbs, Grid, Link, Paper, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import BasicPageLayout from '../../../components/BasicPageLayout';
import { Button } from '@material-ui/core';
import { defaultHandleError } from '../../../lib/apiHelpers';
import Theme from '../../../theme';

const { palette } = Theme;

const useStyles = makeStyles((theme) => ({
  container: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    padding: theme.spacing(4, 4),
  },
  marginBetweenContents: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  smallMarginBetweenContents: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  breadcrumblink: {
    cursor: 'pointer',
  },
  breadcrumb: {
    fontSize: '12px',
  },
  boxInner: {
    backgroundColor: palette.grey.ghostWhite,
    borderColor: palette.grey[200],
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: 'none!important',
    padding: theme.spacing(4, 4),
  },
}));

type Props = {
  jwtValues: ?Object,
  gcAccountId: string,
  registerInterest: (gcAccountId: string, gcContactId: string) => Promise<any>,
};

const TransactionAPIWidget = (props: Props) => {
  const { gcAccountId, jwtValues, registerInterest } = props;
  const classes = useStyles();
  const history = useHistory();
  const snackbar = useSnackbar();

  const handleClick = () => {
    history.push({
      pathname: '/supplier/resources',
    });
  };

  const onRegisterNow = () => {
    registerInterest(gcAccountId, jwtValues ? jwtValues.externalReferenceId : '').then((resp) => {
      if (resp.error) {
        defaultHandleError({ enqueueSnackbar: snackbar.enqueueSnackbar }, undefined);
        return;
      }
      if (resp.payload && resp.payload.status === 200)
        snackbar.enqueueSnackbar('You have successfully registered your interest.', {
          variant: 'success',
          key: 'register-interest-feedback',
        });
      return;
    });
  };

  return (
    <BasicPageLayout noHeaderButGap={true}>
      <Paper className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
              <Link color='inherit' className={classes.breadcrumblink} onClick={handleClick}>
                <Typography className={classes.breadcrumb}>Get started</Typography>
              </Link>
              <Typography className={classes.breadcrumb}>API</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} className={classes.marginBetweenContents}>
            <Typography variant='h3' component='h3'>
              API
            </Typography>
            <Typography>Offer more flexible payment terms to your customers.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper className={[classes.boxInner, classes.container, classes.marginBetweenContents]}>
              <Grid item xs={12}>
                <Typography variant='h3'>Express your interest</Typography>
                <Typography variant='body1' component='label'>
                  Offer Shift payments to your customers
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.marginBetweenContents}>
                <Typography variant='body1' component='label'>
                  Integrate Shift with your online check-out, in-store and back-office ordering system to easily accept payments using Shift.
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.marginBetweenContents}>
                <Button variant='contained' color='primary' size='large' data-testid='uia-registerButton' onClick={onRegisterNow}>
                  Register now
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </BasicPageLayout>
  );
};

export default TransactionAPIWidget;
