export const ACTION_PREFIX = 'TRUST_DEED';
export const PARTITION = 'trustDeed';

export const actionTypes = {
  UPDATE_TRUST_DEEDS_PENDING: 'UPDATE_TRUST_DEEDS_PENDING',
  UPDATE_TRUST_DEEDS_FULFILLED: 'UPDATE_TRUST_DEEDS_FULFILLED',
  UPDATE_TRUST_DEEDS_REJECTED: 'UPDATE_TRUST_DEEDS_REJECTED',
  DELETE_TRUST_DEEDS_PENDING: 'DELETE_TRUST_DEEDS_PENDING',
  DELETE_TRUST_DEEDS_FULFILLED: 'DELETE_TRUST_DEEDS_FULFILLED',
  DELETE_TRUST_DEEDS_REJECTED: 'DELETE_TRUST_DEEDS_REJECTED',
  FILE_UPLOAD_PENDING: 'FILE_UPLOAD_PENDING',
  FILE_UPLOAD_FULFILLED: 'FILE_UPLOAD_FULFILLED',
  FILE_UPLOAD_REJECTED: 'FILE_UPLOAD_REJECTED',
  FILE_DELETE_PENDING: 'FILE_DELETE_PENDING',
  FILE_DELETE_FULFILLED: 'FILE_DELETE_FULFILLED',
  FILE_DELETE_REJECTED: 'FILE_DELETE_REJECTED',
  REFRESH_TOKEN_PENDING: 'REFRESH_TOKEN_PENDING',
  REFRESH_TOKEN_FULFILLED: 'REFRESH_TOKEN_FULFILLED',
  REFRESH_TOKEN_REJECTED: 'REFRESH_TOKEN_REJECTED',
};
