import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ReferralAccountFromQueryString from './Component';
import actions from './actions';
import initialDataSelectors from '../InitialData/selectors';

const mapStateToProps = createSelector(initialDataSelectors.selectReferralAccount, (referralAccount) => ({ referralAccount }));
const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReferralAccountFromQueryString);
