import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Component from './Component';
import actions from './actions';
import addBankConnectFormSelectors from '../../components/AddBankConnectForm/selectors';
import { resetBankLogin } from '../../components/AddBankConnectForm/actions';
import accountSetupCheckSelectors from '../../components/AccountSetupCheck/selectors';
import { selectors as initialDataSelectors } from '../../components/InitialData';

const mapStateToProps = createSelector(
  addBankConnectFormSelectors.selectBankLoginAccountOptions,
  addBankConnectFormSelectors.selectBankLoginConfiguration,
  accountSetupCheckSelectors.selectIsDirectDebitAgreementSetupComplete,
  initialDataSelectors.selectSelectedGcAccountId,
  (bankLoginAccountOptions, bankLoginConfiguration, isDirectDebitAgreementSetupComplete, gcAccountId) => {
    return {
      bankLoginAccountOptions,
      bankLoginConfiguration,
      isDirectDebitAgreementSetupComplete,
      gcAccountId,
    };
  }
);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...actions,
      resetBankLogin,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Component);
