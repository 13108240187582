// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, FormControl, Select, MenuItem, Grid, Button, withWidth, Paper } from '@material-ui/core';

import BasicPageLayout from '../../../components/BasicPageLayout';
import { isMobileResolution } from '../../../lib/materialUiUtils';
import { SELECT_A_SUPPLIER } from './constants';
import actions from './actions';
import { type Supplier } from '../../../types';
import initialDataSelectors from '../../../components/InitialData/selectors';

const useStyles = makeStyles((theme) => ({
  container: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 5),
    },
  },
  formControl: {
    minWidth: '100%',
  },
  title: {
    marginBottom: theme.spacing(2.5),
  },
  orLabel: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1.5),
    },
  },
  payNewSupplierButton: {
    marginTop: theme.spacing(0.5),
  },
}));

type Props = {
  width: string,
};

const defaultSelectedSupplier: Supplier = { gcAccountId: SELECT_A_SUPPLIER, accountName: 'Select a supplier', abn: '', isAto: false };

const PayNewOrExistingSupplier = (props: Props) => {
  const { width } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = isMobileResolution(width);
  const [suppliers, setSuppliers] = useState<Array<Supplier>>([]);
  const activeGcAccountId = useSelector(initialDataSelectors.selectSelectedGcAccountId);

  useEffect(() => {
    if (!activeGcAccountId) {
      return;
    }

    dispatch(actions.getSuppliers(activeGcAccountId)).then((response) => {
      if (!response.error) {
        const suppliersResponse: Supplier[] = [
          defaultSelectedSupplier,
          ...response.payload.data.suppliers.sort((a, b) => {
            if (a.accountName < b.accountName) {
              return -1;
            }
            if (a.accountName > b.accountName) {
              return 1;
            }
            return 0;
          }),
        ];
        setSuppliers(suppliersResponse);
      }
    });
  }, [activeGcAccountId]);

  const handleExistingSupplierChange = (event) => {
    const supplierGcAccountId = event.target.value;

    if (supplierGcAccountId === SELECT_A_SUPPLIER) {
      return;
    }

    history.push(`/buyer/payments/pay/${supplierGcAccountId}`);
  };

  const handlePayNewSupplier = () => {
    history.push('/buyer/payments/pay');
  };

  return (
    <BasicPageLayout title='Make a payment' noMargin={!isMobile}>
      <Paper className={classes.container}>
        <Typography variant='h3' component='h3' className={classes.title}>
          Pay a supplier
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='subtitle2' component='label'>
              Choose your supplier
            </Typography>
          </Grid>
          <Grid item xs={12} md={7} xl={8}>
            <FormControl className={classes.formControl} variant='outlined'>
              <Select
                name='supplierId'
                id='supplierId'
                data-testid='uia-supplierslist'
                onChange={handleExistingSupplierChange}
                value={defaultSelectedSupplier.gcAccountId}
                disableUnderline
              >
                {suppliers.map((supplier: Supplier) => (
                  <MenuItem key={supplier.gcAccountId} value={supplier.gcAccountId}>
                    {supplier.accountName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} xl={1} className={classes.orLabel}>
            <Typography variant='subtitle2' component='span'>
              Or
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <Button
              variant='contained'
              color='primary'
              type='button'
              fullWidth
              data-testid='uia-newSupplier'
              className={classes.payNewSupplierButton}
              onClick={handlePayNewSupplier}
            >
              Pay a new supplier
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </BasicPageLayout>
  );
};

export default withWidth()(PayNewOrExistingSupplier);
