// @flow
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import * as queryString from 'query-string';

import Auth from '../api/auth';
import { useAuth } from '../api/authhooks';
import applicationState from '../lib/applicationState';
import { handleError } from '../lib/apiHelpers';
import startupConfigSelectors from './StartupConfig/selectors';

type Props = {
  children: React.Node,
};

export const SingleSignOnProvider = (props: Props) => {
  const location = useLocation();
  const history = useHistory();
  const isConfigurationSet = useSelector(startupConfigSelectors.selectIsConfigurationSet);
  const [renderChildren, setRenderChildren] = React.useState(false);
  const { sso } = useAuth();

  let gcAccountId: string;
  let tokenAlias: string;
  let referrer: string;

  if (isConfigurationSet && location.pathname === '/' && !Auth.isLoggedIn()) {
    const queryStrings = queryString.parse(location.search);
    gcAccountId = queryStrings._a ? queryStrings._a.toString() : '';
    tokenAlias = queryStrings._ta ? queryStrings._ta.toString() : '';
    referrer = queryStrings._referrer ? queryStrings._referrer.toString() : '';
  }

  React.useEffect(() => {
    async function handleSSO() {
      if (isConfigurationSet && !renderChildren) {
        if (!tokenAlias) {
          setRenderChildren(true);
          return;
        }

        try {
          await sso(tokenAlias);

          if (referrer === 'registration') {
            // replicate behaviour of UseWelcomePageRedirectToken
            history.replace({
              pathname: '/welcome',
              state: { gcAccountId },
            });
          } else if (gcAccountId) {
            applicationState.set({ selectedAccountId: gcAccountId });
          }
        } catch (error) {
          handleError(error);
        } finally {
          setRenderChildren(true);
        }
      }
    }
    handleSSO();
  }, [isConfigurationSet, renderChildren, gcAccountId, tokenAlias, referrer, history]);

  if (!renderChildren) {
    return null;
  }

  return props.children;
};

export default SingleSignOnProvider;
