// @flow
import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// $FlowFixMe Flow doesn't like type re-exports
import { createSelector } from '@reduxjs/toolkit';
import DollarsAndCentsText from '../../../components/DollarsAndCentsText';
import ResponsiveLabelValue from './ResponsiveLabelValue';
import TransactionFee from './TransactionFee';
import type { Fee } from './types';
import actions from './actions';
import selectors from './selectors';
type Props = {
  fees: Fee[],
  nextPaymentAmount: number,
  invoiceAmount: number,
  balance: number,
  transactionFee: number,
  installmentTermInWeeks: number,
  isBuyerInitiated: boolean,
  isExtension?: boolean,
  setInstallmentTermInWeeks: (term: number) => void,
};

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    boxShadow: 'none !important',
  },
  secondaryHeading: {
    color: theme.palette.grey.text,
  },
  heavy: {
    fontWeight: 500,
  },
  transactionFeeAmount: {
    fontWeight: 500,
    fontSize: 14,
  },
  select: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '190px',
    },
  },
  nextPaymentContainer: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  nextPaymentHeader: {
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(0, 1)} !important`,
    },
  },
  nextPaymentAmounts: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: `${theme.spacing(0.5)}px !important`,
    },
  },
}));

const WeekSelector = (props: Props) => {
  const classes = useStyles();
  const {
    fees,
    invoiceAmount,
    balance,
    nextPaymentAmount,
    transactionFee,
    installmentTermInWeeks,
    isBuyerInitiated,
    isExtension,
    setInstallmentTermInWeeks,
  } = props;

  const newBalance = balance + transactionFee + (isBuyerInitiated ? invoiceAmount : 0);
  const afterThisInvoiceAmount =
    invoiceAmount / (installmentTermInWeeks === -1 ? 1 : installmentTermInWeeks || 1) + transactionFee + nextPaymentAmount;

  return (
    <Paper className={classes.container} data-testid='uia-week-selector'>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl className={classes.select} variant='outlined'>
                {fees.length ? (
                  <Select
                    value={installmentTermInWeeks}
                    onChange={(e) => setInstallmentTermInWeeks(e.target.value)}
                    data-testid='uia-weeksTermSelect'
                  >
                    <MenuItem value={-1} disabled>
                      Select term...
                    </MenuItem>
                    {fees.map((fee: Fee) => (
                      <MenuItem key={fee.term} value={fee.term}>
                        {fee.term} week{fee.term > 1 ? 's' : ''}
                      </MenuItem>
                    ))}
                  </Select>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TransactionFee isExtension={isExtension} amount={transactionFee} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} className={classes.nextPaymentContainer}>
            <Grid item xs={12} className={classes.nextPaymentHeader}>
              <Typography variant='subtitle2'>Next weekly account repayment</Typography>
            </Grid>
            <Grid item xs={12} className={classes.nextPaymentAmounts}>
              <Grid container>
                <ResponsiveLabelValue
                  label='Current'
                  value={<DollarsAndCentsText amount={nextPaymentAmount} />}
                  classes={{ value: classes.heavy }}
                  valueVariant='subtitle2'
                  data-testid='uia-nextPaymentAmount'
                />
                <ResponsiveLabelValue
                  label='New'
                  value={<DollarsAndCentsText amount={afterThisInvoiceAmount} />}
                  classes={{ value: classes.heavy }}
                  valueVariant='subtitle2'
                  data-testid='uia-afterThisInvoiceAmount'
                />
              </Grid>
            </Grid>
            {isExtension ? (
              <Grid item xs={12}>
                <Typography variant='subtitle1' className={classes.secondaryHeading}>
                  The one-off extension fee is included in your new repayment. All repayments after this won’t include this extension fee.
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography variant='subtitle1' className={classes.secondaryHeading}>
                  The one-off transaction fee is included in your new repayment. All repayments after this won’t include this transaction fee.
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant='subtitle1' className={classes.secondaryHeading}>
                Your new balance will become <DollarsAndCentsText amount={newBalance} data-testid='uia-newBalance' />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps: any = createSelector(selectors.selectInstallmentTermInWeeks, (installmentTermInWeeks) => ({
  installmentTermInWeeks,
}));

const mapDispatchToProps = (dispatch) => bindActionCreators({ setInstallmentTermInWeeks: actions.setInstallmentTermInWeeks }, dispatch);

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(WeekSelector);
