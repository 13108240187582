import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Dashboard from './Component';
import actions from './actions';
import selectors from './selectors';
import supplierRequestActions from '../../../components/SupplierRequests/actions';
import pendingInvoicesActions from '../../../components/PendingInvoices/actions';
import accountSetupCheckSelectors from '../../../components/AccountSetupCheck/selectors';
import initialDataSelectors from '../../../components/InitialData/selectors';
import pendingInvoiceSelectors from '../../../components/PendingInvoices/selectors';
import supplierRequestSelectors from '../../../components/SupplierRequests/selectors';
import { SpStatus } from '../../../types';
import accountReadinessSelectors from '../../../components/AccountReadinessAlerts/selectors';

const mapStateToProps = createSelector(
  initialDataSelectors.selectActiveAccount,
  initialDataSelectors.selectFirstName,
  initialDataSelectors.selectIsAllKycCompleted,
  initialDataSelectors.selectReferralAccount,
  pendingInvoiceSelectors.selectTotalPendingInvoices,
  supplierRequestSelectors.selectTotalSupplierRequests,
  initialDataSelectors.selectSelectedAccountIsDelinquent,
  accountSetupCheckSelectors.selectLoanAgreementSetup,
  accountSetupCheckSelectors.selectIsAllSetupCompleteRequirement,
  accountReadinessSelectors.selectIsAccountReady,
  selectors.selectFacilityLimits,
  selectors.isLoadingFacilityLimits,
  (
    activeAccount,
    firstName,
    isAllKycCompleted,
    referralAccount,
    totalPendingInvoices,
    totalSupplierRequests,
    activeAccountIsDelinquent,
    loanAgreementSetup,
    isAllSetupCompleteRequirement,
    isAccountReady,
    facilityLimits,
    isLoadingFacilityLimits
  ) => {
    const gcAccountId = activeAccount ? activeAccount.gcAccountId : null;
    // we need to refresh the token if the FLA has just been signed. the check on the
    // loanAgreementSetup is necessary to avoid the infinite loop
    const doRefreshToken =
      loanAgreementSetup &&
      loanAgreementSetup.isCompleted &&
      (!loanAgreementSetup.data.isSigned || loanAgreementSetup.currentActiveAgreementVersion !== loanAgreementSetup.signedAgreementVersion) &&
      activeAccount.spStatus.toLowerCase() === SpStatus.NotSigned;

    return {
      activeAccount,
      gcAccountId,
      firstName,
      isAllKycCompleted,
      referralAccount,
      totalPendingInvoices,
      totalSupplierRequests,
      activeAccountIsDelinquent,
      doRefreshToken,
      isAllSetupCompleteRequirement,
      isAccountReady,
      facilityLimits,
      isLoadingFacilityLimits,
    };
  }
);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...actions,
      ...supplierRequestActions,
      ...pendingInvoicesActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
