// @flow
import { handleActions } from 'redux-actions';

import { actionTypes, PARTITION, ACTION_PREFIX } from './constants';
import { type Account, type Contact, type ReferralAccount } from '../../types';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import applicationState from '../../lib/applicationState';
import { type JwtValues } from '../../api/claims';

export type ReduxState = {
  accounts: ?Array<Account>,
  contact: ?Contact,
  jwtValues: ?JwtValues,
  isAllKycCompleted: boolean,
  referralAccount: ?ReferralAccount,
};

const initialState: ReduxState = {
  accounts: null,
  contact: null,
  jwtValues: null,
  isAllKycCompleted: applicationState.get().isAllKycCompleted || false,
  referralAccount: null,
};

const reducer = handleActions<ReduxState>(
  {
    [actionTypes.GET_INITIAL_DATA_FULFILLED]: (state, { payload }) => ({
      ...state,
      accounts: payload.accounts,
      contact: payload.contact,
    }),
    [actionTypes.SET_JWT_VALUES]: (state, { payload }) => ({
      ...state,
      jwtValues: payload,
    }),
    [actionTypes.SET_IS_ALL_KYC_COMPLETED]: (state, { payload }) => ({
      ...state,
      isAllKycCompleted: payload,
    }),
    [actionTypes.SET_ACCOUNTS]: (state, { payload }) => ({
      ...state,
      accounts: payload,
    }),
    [actionTypes.SET_REFERRAL_ACCOUNT]: (state, { payload }) => ({
      ...state,
      referralAccount: payload,
    }),
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
