// @flow
import * as React from 'react';
import type { FormikState, FormikHelpers } from 'formik';

import type { Field } from './types';
import FormikField from './FormikField';
import DndFileUpload from '../DndFileUpload/Component';
import { type File } from '../../types';

type Props = {
  children: React.Node,
  field: Field,
  form: FormikState<any> & FormikHelpers<any>,
  showNoError: boolean,
  supportedFileTypesText?: string,
};

const FormikDndFileUpload = (props: Props) => {
  const { field, form, ...other } = props;

  const handleFilesSelected = (files) => {
    if (files && files.length > 0) {
      const filesArray = form.values[field.name] || [];
      for (let i = 0; i < files.length; ++i) {
        filesArray.push(files[i]);
      }

      form.setFieldValue(field.name, filesArray);
      form.setTouched({ [field.name]: true });
    }
  };

  const handleFileStatusChange = (file: File) => {
    const filesArray = form.values[field.name] || [];
    const fileIndex = filesArray.findIndex((x) => x.name === file.name);
    filesArray.splice(fileIndex, 1);
    filesArray.push(file);
    form.setFieldValue(field.name, filesArray);
    form.setTouched({ [field.name]: true });
  };

  return (
    <FormikField field={field} form={form}>
      <DndFileUpload onFilesSelected={handleFilesSelected} onFileStatusChange={handleFileStatusChange} {...(other: any)} />
    </FormikField>
  );
};

export default FormikDndFileUpload;
