// @flow
import * as React from 'react';
import { makeStyles, Grid, Typography, Hidden } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  lineBreak: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2.5),
    },
  },
  textWithIcon: {
    [theme.breakpoints.down('xs')]: {
      '& svg': {
        marginTop: theme.spacing(0.5),
      },
      '& h5, p, span': {
        marginLeft: theme.spacing(2),
      },
    },
    [theme.breakpoints.up('sm')]: {
      height: 50,
      marginRight: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      '& h5': {
        marginBottom: theme.spacing(0.5),
      },
    },
  },
  summaryText: {
    borderLeft: `2px solid ${theme.palette.common.jaggedIceBlue}`,
    paddingLeft: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    '& p': {
      width: '90%',
    },
  },
}));

type BulletPointProps = {
  icon: any,
  title: string,
  description: string,
  summaryText: string,
};

const BulletPoint = ({ icon, title, description, summaryText }: BulletPointProps) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} md={7}>
        <Grid container className={classes.textWithIcon}>
          <Grid item xs={2}>
            {icon}
          </Grid>
          <Grid item xs={10}>
            <Typography variant='h5'>{title}</Typography>
            <Typography variant='body2'>{description}</Typography>
            <Hidden mdUp>
              <Typography variant='subtitle2' component='p'>
                {summaryText}
              </Typography>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} md={5} className={classes.summaryText}>
          <Typography variant='subtitle2' component='p'>
            {summaryText}
          </Typography>
        </Grid>
      </Hidden>
      <Grid item xs={12} className={classes.lineBreak} />
    </>
  );
};

export default BulletPoint;
