// @flow
import { handleActions } from 'redux-actions';

import { actionTypes, PARTITION, ACTION_PREFIX } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';

export type ReduxState = {
  activeAccountIndex: number,
};

const initialState: ReduxState = {
  activeAccountIndex: 0,
};

const reducer = handleActions<ReduxState>(
  {
    [actionTypes.UPDATE_ACTIVE_ACCOUNT_INDEX]: (state, { payload }) => ({
      ...state,
      activeAccountIndex: payload,
    }),
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
