// @flow
import * as React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { type DeedStatusEnum } from '../../types';
import AlertWarning from '../AlertWarning';
import initialDataSelectors from '../InitialData/selectors';
import { TrustDeedAlert } from '../TrustDeedAlert';
import selectors from './selectors';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 1),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 0, 2),
    },
  },
}));

const AccountReadinessAlerts = () => {
  const classes = useStyles();
  const activeAccountTrustDeedNeedsApproval: boolean = useSelector(initialDataSelectors.selectSelectedAccountTrustDeedNeedsApproval);
  const activeAccountTrustDeedStatus: ?DeedStatusEnum = useSelector(initialDataSelectors.selectSelectedAccountDeedStatus);
  const isAccountReady: boolean = useSelector(selectors.selectIsAccountReady);

  if (activeAccountTrustDeedNeedsApproval) {
    return <TrustDeedAlert accountTrustDeedStatus={activeAccountTrustDeedStatus} />;
  }

  if (!isAccountReady) {
    return (
      <div className={classes.container} data-testid='uia-accountReadiness'>
            <AlertWarning message='Pending Account set-up' subMessage='Your account set-up is pending. Please call 1300 249 649 for assistance.' />
      </div>
    );
  }
  return null;
};

export default AccountReadinessAlerts;
