import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from '@reduxjs/toolkit';
import Component from './Component';
import actions from './actions';
import initialDataSelectors from '../InitialData/selectors';
import accountSelectMenuSelectors from '../AccountSelectMenu/selectors';

const mapStateToProps = createSelector(
  initialDataSelectors.selectAccounts,
  initialDataSelectors.selectReferralAccount,
  accountSelectMenuSelectors.selectActiveAccountIndex,
  (accounts, referralAccount, activeAccountIndex) => ({
    accounts,
    referralAccount,
    activeAccountIndex,
  })
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Component);
