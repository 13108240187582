import { createActions } from 'redux-actions';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import identityApi from '../../api/identityApi';
import { useAuth } from '../../api/authhooks';
import { getSessionId } from '../../lib/threatmetrix';

const {
  getPinByMobileNumberPending,
  getPinByMobileNumberFulfilled,
  getPinByMobileNumberRejected,
  getTokenByPinPending,
  getTokenByPinFulfilled,
  getTokenByPinRejected,
  setLoginStep,
  setInputMobileNumberValue,
} = createActions(
  actionTypes.GET_PIN_BY_MOBILE_NUMBER_PENDING,
  actionTypes.GET_PIN_BY_MOBILE_NUMBER_FULFILLED,
  actionTypes.GET_PIN_BY_MOBILE_NUMBER_REJECTED,
  actionTypes.GET_TOKEN_BY_PIN_PENDING,
  actionTypes.GET_TOKEN_BY_PIN_FULFILLED,
  actionTypes.GET_TOKEN_BY_PIN_REJECTED,
  actionTypes.SET_LOGIN_STEP,
  actionTypes.SET_INPUT_MOBILE_NUMBER_VALUE,
  getActionOptions(ACTION_PREFIX)
);

const getPinByMobileNumber = (values) => (dispatch) => {
  dispatch(getPinByMobileNumberPending());

  return identityApi
    .getPinByMobileNumber(values)
    .then((response) => {
      return dispatch(getPinByMobileNumberFulfilled(response, values));
    })
    .catch((error) => {
      return dispatch(getPinByMobileNumberRejected(error));
    });
};

const getTokenByPin = (values, mobileNumber) => (dispatch) => {
  const { login } = useAuth();
  dispatch(getTokenByPinPending(mobileNumber));

  const requestData = {
    pin: values.pin,
    phoneNumber: mobileNumber,
    tmxSessionId: getSessionId(),
    portal: 'Shift Payments',
  };

  return login(requestData)
    .then((response) => {
      return dispatch(getTokenByPinFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getTokenByPinRejected(error));
    });
};

export default {
  getPinByMobileNumber,
  getTokenByPin,
  setInputMobileNumberValue,
  setLoginStep,
};
