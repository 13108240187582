// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  theme: any,
};

const WarningTriangleIcon = withTheme((props: Props) => (
  <SvgIcon
    style={{
      width: '24px',
      height: '24px',
    }}
    fill='none'
    {...(props: any)}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.98581 0.621975L0.183706 17.9753C-0.314164 18.8568 0.258533 20 1.19791 20H20.8021C21.7415 20 22.3142 18.8568 21.8163 17.9753L12.0142 0.621975C11.5458 -0.207326 10.4542 -0.207324 9.98581 0.621975ZM9.77778 7.5C9.77778 6.80965 10.325 6.25 11 6.25C11.675 6.25 12.2222 6.80965 12.2222 7.5V12.5C12.2222 13.1904 11.675 13.75 11 13.75C10.325 13.75 9.77778 13.1904 9.77778 12.5V7.5ZM9.77778 16.25C9.77778 15.5596 10.325 15 11 15C11.675 15 12.2222 15.5596 12.2222 16.25C12.2222 16.9404 11.675 17.5 11 17.5C10.325 17.5 9.77778 16.9404 9.77778 16.25Z'
      fill='#F7B500'
    />
  </SvgIcon>
));

export default WarningTriangleIcon;
