// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

type Props = {
  htmlColor: string,
};

const AlertIcon = (props: Props) => (
  <SvgIcon style={{ alignSelf: 'baseline' }} {...(props: any)}>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-90.000000, -463.000000)' fill={props.htmlColor}>
        <g transform='translate(74.000000, 445.000000)'>
          <g transform='translate(18.000000, 18.000000)'>
            <path
              d='M8.06648439,0.559777888 L-0.955907252,16.1777497 C-1.4141739,16.9710836 -0.887031849,18 -0.0223821529,18 L18.0223571,18 C18.8870839,18 19.4141764,16.9710836 18.9558877,16.1777497 L9.9335566,0.559777888 C9.50234913,-0.186593225 8.49765884,-0.186592034 8.06648439,0.559777888 Z M7.875,6.75000375 C7.875,6.12868668 8.37867123,5.625 9.00000562,5.625 C9.62134002,5.625 10.125,6.12868668 10.125,6.75000375 L10.125,11.2500188 C10.125,11.8713358 9.62134002,12.375 9.00000562,12.375 C8.37867123,12.375 7.875,11.8713358 7.875,11.2500188 L7.875,6.75000375 Z M7.875,14.625 C7.875,14.0036625 8.37867123,13.5 9.00000562,13.5 C9.62134002,13.5 10.125,14.0036625 10.125,14.625 C10.125,15.2463375 9.62134002,15.75 9.00000562,15.75 C8.37867123,15.75 7.875,15.2463375 7.875,14.625 Z'
              id='Shape'
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default AlertIcon;
