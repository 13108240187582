// @flow
import type { OrderBy } from '../api/shiftPaymentsApi';

export const getColumnIndex = (columns: Array<{ name: string }>, columnName: string) => {
  return columns ? columns.findIndex((column) => column.name === columnName) : -1;
};

type ColumnOptions = {
  name: string,
  options?: ?Object,
};

export const enrichColumnsWithBodyRenders = (columns: Array<ColumnOptions>, columnCustomBodyRenders: Object): Array<Object> => {
  const enrichedColumns = columns.map((column: ColumnOptions) => {
    if (Object.prototype.hasOwnProperty.call(columnCustomBodyRenders, column.name)) {
      const enrichedColumn = {
        ...column,
        options: {
          ...column.options,
          customBodyRender: columnCustomBodyRenders[column.name],
        },
      };
      return enrichedColumn;
    }
    return column;
  });

  return enrichedColumns;
};

export const getColumnOrderBy = (tableState: {
  activeColumn: number,
  columns: Array<{ name: string, sortDirection: 'asc' | 'desc' }>,
}): ?Array<OrderBy> => {
  if (!tableState || !tableState.columns) {
    return null;
  }

  const { activeColumn, columns } = tableState;
  if (!activeColumn && activeColumn !== 0) {
    return null;
  }

  const { name, sortDirection } = columns[activeColumn];
  let isAscending = true;
  switch (sortDirection) {
    case 'desc':
      isAscending = false;
      break;
    case 'asc':
    default:
      isAscending = true;
      break;
  }

  return [
    {
      columnName: name,
      isAscending,
    },
  ];
};

export const convertDataToDataGridFormat = (columns: Array<any>, rawData: Array<any>) => {
  const formattedData: Array<{ index: number, data: Array<any> }> = [];
  rawData.forEach((rawRow, rowIndex) => {
    const formattedRow = [];
    columns
      .filter((column) => column.display)
      .forEach((column, colIndex) => {
        formattedRow[colIndex] = rawRow[column.name];
      });

    formattedData.push({ index: rowIndex, data: formattedRow });
  });

  return formattedData;
};

export default {
  getColumnIndex,
  enrichColumnsWithBodyRenders,
  getColumnOrderBy,
  convertDataToDataGridFormat,
};
