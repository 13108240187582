// @flow
import { PARTITION } from './constants';
import type { ReduxState } from './reducer';

const selectPartition = (state: any): ReduxState => state[PARTITION];
const selectActiveAccountIndex = (state: any) => selectPartition(state).activeAccountIndex;

export default {
  selectPartition,
  selectActiveAccountIndex,
};
