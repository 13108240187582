/* eslint-disable flowtype/no-types-missing-file-annotation */
import { createActions } from 'redux-actions';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';
import type { OrderBy } from '../../api/shiftPaymentsApi';

export const { getPendingInvoicesPending, getPendingInvoicesFulfilled, getPendingInvoicesRejected } = createActions(
  actionTypes.GET_PENDING_INVOICES_PENDING,
  actionTypes.GET_PENDING_INVOICES_FULFILLED,
  actionTypes.GET_PENDING_INVOICES_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getPendingInvoices = (gcAccountId: string, skip: number, limit: number, orderBy: ?Array<OrderBy>) => (dispatch: Dispatch<any>) => {
  dispatch(getPendingInvoicesPending());

  return shiftPaymentsApi
    .getPendingInvoices(gcAccountId, skip, limit, orderBy)
    .then((response) => {
      return dispatch(getPendingInvoicesFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getPendingInvoicesRejected(error));
    });
};

export default {
  getPendingInvoices,
};
