// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

type Props = {
  text: string,
  color: string,
};

const useStyles = makeStyles((theme) => {
  return {
    background: {
      padding: theme.spacing(0.5, 1),
      borderRadius: 4,
      display: 'inline-block',
      textTransform: 'uppercase',
      width: '100%',
    },
    label: {
      fontSize: 10,
      textAlign: 'center',
    },
  };
});

const StatusLabel = (props: Props) => {
  const { text, color } = props;

  const classes = useStyles();

  return (
    <Box className={classes.background} style={{ backgroundColor: color }}>
      <Typography className={classes.label}>{text}</Typography>
    </Box>
  );
};

export default StatusLabel;
