/* eslint-disable no-param-reassign */
// @flow
import { Box, Grid, Link, Paper } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/styles';
import { Button, FormField, FieldHint } from '@shiftfinancial/design-system';
import clsx from 'clsx';
import { Field, Form, withFormik } from 'formik';
import type { FormikBag, FormikErrors } from 'formik';
import { TextField } from 'formik-material-ui';
import type { History, Location } from 'history';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { SupplierSelectionMode } from './constants';
import ExampleTooltip from './ExampleTooltip';
import SupplierSearch from './SupplierSearch';
import { formSchema } from './validations';
import responseCode from '../../../api/generated/responseCode';
import { type EntitySuggestionItem } from '../../../components/AbnAutoSuggest';
import AlertError from '../../../components/AlertError';
import AlertInfo from '../../../components/AlertInfo';
import BasicPageLayout from '../../../components/BasicPageLayout';
import DollarsAndCentsText from '../../../components/DollarsAndCentsText';
import { FormikDndAttachFiles, FormikNumberFormatInputField } from '../../../components/formik';
import FormItem from '../../../components/FormItem';
import { ATTACHMENT_SUPPORTED_FORMATS } from '../../../constants';
import { defaultHandleError, getErrorMessage } from '../../../lib/apiHelpers';
import { isMobileResolution } from '../../../lib/materialUiUtils';
import startupConfig, { type AttachmentConfig } from '../../../lib/startupConfig';
import { type Account, type Supplier } from '../../../types';
import type { Invoice } from '../Review/types';

const { v4: uuidv4 } = require('uuid');

const useStyles = makeStyles((theme) => ({
  supplierInfo: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 5),
    },
  },
  supplierInfoSelected: {
    backgroundColor: theme.palette.common.paleOrange,
  },
  fields: {
    borderTop: `1px solid ${theme.palette.grey['300']}`,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 5),
    },
  },
  invoiceAmountSubContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(0.5),
  },
  invoiceDescriptionSubText: {
    color: theme.palette.grey.light,
    marginTop: theme.spacing(0.5),
  },
  footerButtons: {
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    fontWeight: 'normal',
    [theme.breakpoints.up('xs')]: {
      width: '50%',
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      width: 90,
      marginRight: theme.spacing(1),
    },
  },
  submitButton: {
    minWidth: '10px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  row: {
    margin: theme.spacing(0.5),
  },
  invoiceSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));

type FormValues = {
  invoiceAmount: string,
  invoiceFiles: Array<any>,
  invoiceDescription: string,
  invoiceNumber: string,
  invoicePrn: string,
  supplierGcAccountId: string,
  supplierAccountName: string,
  supplierBsb: string,
  supplierBankAccountNumber: string,
  supplierContactId?: string,
  fileGroupId: string,
  supplierAbn: string,
  isNewSupplier: boolean,
  isAto: boolean,
  isDirector: boolean,
};

type Props = {
  width: string,
  invoice: Invoice,
  getSuppliers: (gcAccountId: string) => Promise<any>,
  getAccountBalance: (gcAccountId: string) => Promise<any>,
  addInvoice: (
    invoiceAmount: number,
    invoiceNumber: string,
    invoiceFiles: Array<any>,
    invoiceDescription: string,
    supplierGcAccountId: ?string,
    supplierAbnOrAcn: string,
    supplierAccountName: string,
    supplierBsb: string,
    supplierBankAccountNumber: string,
    fileGroupId: string,
    created: Date,
    isNewSupplier: boolean,
    isDirector: boolean
  ) => Promise<any>,
  searchDelay?: number,
  checkValidBsb: (bsbNumber: string) => Promise<any>,
  activeAccount: Account,
  history: History<Location>,
};

type WithHOCProps = {
  ...Props,
  isSubmitting: boolean,
  values: FormValues,
  errors: FormikErrors<FormValues>,
  touched: any,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  setFieldError: (fieldName: string, error: string) => void,
  deleteFiles: (gcAccountId: string, fileGroupId: string, files: Array<any>) => boolean | Promise<any>,
  deleteInvoiceFile: (gcAccountId: string, fileGroupId: string, fileName: string) => Promise<any>,
  uploadFile: (gcAccountId: string, fileGroupId: string, fileName: string) => Promise<any>,
};

const AddInvoice = (props: WithHOCProps) => {
  const {
    width,
    activeAccount,
    isSubmitting,
    values,
    getAccountBalance,
    errors,
    touched,
    setFieldValue,
    searchDelay = 1000,
    setFieldError,
    history,
    deleteFiles,
    uploadFile,
    deleteInvoiceFile,
  } = props;
  const classes = useStyles();
  const [accountBalance, setAccountBalanceState] = useState(0);
  const [supplierSelectionMode, setSupplierSelectionMode] = useState(SupplierSelectionMode.None);
  const isMobile = isMobileResolution(width);
  const fromReview = history.location.state && (history.location.state: any).fromReview;
  const [nonSupportedBusinessMessage, setNonSupportedBusinessMessage] = useState('');

  useEffect(() => {
    if (!accountBalance && activeAccount) {
      getAccountBalance(activeAccount.gcAccountId).then((accountResponse) => {
        const { error, payload } = accountResponse;
        if (!error) {
          setAccountBalanceState(payload.data.availableBalance);
        }
      });
    }
    if (activeAccount) {
      const isDirector = activeAccount && activeAccount.roles.some((i) => i === 'Director');
      setFieldValue('isDirector', isDirector);
    }
  }, [getAccountBalance, accountBalance, activeAccount]);

  const handleExistingSupplierSelected = (supplier: Supplier) => {
    setSupplierSelectionMode(SupplierSelectionMode.Existing);
    setFieldValue('supplierGcAccountId', supplier.gcAccountId);
    setFieldValue('supplierAbn', supplier.abn);
    setFieldValue('supplierAccountName', supplier.accountName);
    setFieldValue('isNewSupplier', false);
    setFieldValue('isAto', supplier.isAto);
  };

  const handleNewSupplierSelected = (entity: EntitySuggestionItem, relatedEntity: ?EntitySuggestionItem, validationResponse: any) => {
    setSupplierSelectionMode(SupplierSelectionMode.New);

    if (
      validationResponse.error &&
      validationResponse.payload &&
      validationResponse.payload.response &&
      validationResponse.payload.response.status === 400 &&
      validationResponse.payload.response.data &&
      validationResponse.payload.response.data.code === responseCode.AccountNonSupportedBusiness
    ) {
      setNonSupportedBusinessMessage(validationResponse.payload.response.data.detail);
    } else if (validationResponse.error) {
      setFieldError('supplierGcAccountId', getErrorMessage(validationResponse));
    } else {
      const validationResult = validationResponse.payload.data;

      setFieldValue('isNewSupplier', true);
      setFieldValue('supplierGcAccountId', validationResult.gcAccountId || '');
      setFieldValue('supplierAbn', entity.id);
      setFieldValue('supplierAccountName', entity.entityName);
      setFieldValue('isAto', entity.isAto);
    }
  };

  const handleCancel = () => {
    deleteFiles(activeAccount.gcAccountId, values.fileGroupId, values.invoiceFiles);
    history.goBack();
  };

  const { maxFilesCount, maxFileSizeInMb } = startupConfig.get().invoiceAttachment;

  return (
    <BasicPageLayout title='Make a payment' data-testid='uia-make-a-payment-page' noMargin={!isMobile}>
      <Form noValidate autoComplete='off'>
        <Paper>
          <Box
            className={clsx(classes.supplierInfo, {
              [classes.supplierInfoSelected]: values.supplierAbn,
            })}
          >
            <SupplierSearch
              searchDelay={searchDelay}
              onNewSupplierSelected={handleNewSupplierSelected}
              onExistingSupplierSelected={handleExistingSupplierSelected}
              defaultSelectedAbn={fromReview ? values.supplierAbn : ''}
              isAto={values.isAto}
            />
          </Box>
          {nonSupportedBusinessMessage ? (
            <Box className={classes.fields}>
              <AlertInfo data-testid='uia-nonSupportedBusinessMessage' message={nonSupportedBusinessMessage} />
            </Box>
          ) : null}
          {errors.supplierGcAccountId ? (
            <Box className={classes.fields}>
              <AlertError message={errors.supplierGcAccountId} />
            </Box>
          ) : null}
          {!errors.supplierGcAccountId && !nonSupportedBusinessMessage && values.supplierAbn ? (
            <Box className={classes.fields}>
              <Grid container spacing={2}>
                {supplierSelectionMode === SupplierSelectionMode.New && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant='h3' component='h4'>
                        Supplier bank details
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormItem label='BSB'>
                        <Field
                          component={FormikNumberFormatInputField}
                          variant='outlined'
                          fullWidth
                          id='supplierBsb'
                          name='supplierBsb'
                          data-testid='uia-supplierBsb'
                          value={values.supplierBsb}
                          pattern='[0-9]{6}'
                          format='######'
                        />
                      </FormItem>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormItem label='Account number'>
                        <Field
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          id='supplierBankAccountNumber'
                          name='supplierBankAccountNumber'
                          data-testid='uia-supplierBankAccountNumber'
                          value={values.supplierBankAccountNumber}
                          InputProps={{
                            onChange: ({ target }) => {
                              setFieldValue('supplierBankAccountNumber', target.value.toString().replace(/ /g, ''), true);
                            },
                            spellcheck: 'false',
                          }}
                        />
                      </FormItem>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12} className={classes.invoiceSection}>
                <Typography variant='h3' component='h4'>
                  Invoice for this supplier
                </Typography>
                <ExampleTooltip accountName={activeAccount.name} amount={values.invoiceAmount} description={values.invoiceDescription} />
              </Grid>
              <Grid container spacing={2} className={classes.marginWithSpace}>
                <Grid item xs={12} sm={4}>
                  <FormField
                    required={true}
                    label='Invoice amount'
                    hint={
                      <div className={classes.invoiceAmountSubContainer}>
                        <Typography variant='body2'>
                          Available funds: <DollarsAndCentsText amount={accountBalance} />
                        </Typography>
                        <Link component={RouterLink} to='/buyer/dashboard'>
                          Increase credit limit
                        </Link>
                      </div>
                    }
                    fieldId='invoiceAmount'
                  >
                    {({ fieldId }) => (
                      <Field
                        component={FormikNumberFormatInputField}
                        variant='outlined'
                        fullWidth
                        id={fieldId}
                        name='invoiceAmount'
                        data-testid='uia-invoiceAmount'
                        value={values.invoiceAmount}
                        thousandSeparator
                        allowNegative={false}
                        startAdornment={<InputAdornment position='start'>AUD&nbsp;$</InputAdornment>}
                        isAllowed={(x) => x.value !== 0 && Number(x.value) <= Number(accountBalance)}
                      />
                    )}
                  </FormField>
                </Grid>
                {!props.values.isAto && (
                  <Grid item xs={12} sm={8}>
                    <FormField
                      label='Description'
                      hint={<FieldHint id='static-id'>This will appear on your supplier's bank statement.</FieldHint>}
                      fieldId='invoiceDescription'
                    >
                      {({ fieldId }) => (
                        <Field
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          id={fieldId}
                          name={fieldId}
                          data-testid='uia-invoiceDescription'
                          value={values.invoiceDescription}
                          inputProps={{
                            maxLength: 18,
                          }}
                        />
                      )}
                    </FormField>
                  </Grid>
                )}
                {!props.values.isAto ? (
                  <Grid item xs={12} sm={4}>
                    <FormField required label='Invoice No.' fieldId='invoiceNumber'>
                      {({ fieldId }) => (
                        <Field
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          id={fieldId}
                          name={fieldId}
                          data-testid='uia-invoiceNumber'
                          value={values.invoiceNumber}
                          inputProps={{
                            maxLength: 18,
                          }}
                        />
                      )}
                    </FormField>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={8}>
                    <FormField
                      required
                      label='Payment Reference Number (PRN)'
                      hint={<FieldHint id='static-id'>This will appear on your supplier's bank statement.</FieldHint>}
                      fieldId='invoicePrn'
                    >
                      {({ fieldId }) => (
                        <Field
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          id={fieldId}
                          name={fieldId}
                          data-testid='uia-invoicePrn'
                          value={values.invoicePrn}
                          inputProps={{
                            maxLength: 100,
                          }}
                          onChangeText={() => {
                            setFieldValue('invoiceNumber', values.invoicePrn);
                          }}
                        />
                      )}
                    </FormField>
                  </Grid>
                )}
                <Grid item xs={12} sm={props.values.isAto ? 12 : 8}>
                  <FormField
                    label={supplierSelectionMode === SupplierSelectionMode.New ? 'Attach invoice *' : 'Attach invoice'}
                    fieldId='invoiceFiles'
                  >
                    {({ fieldId }) => (
                      <FormikDndAttachFiles
                        name={fieldId}
                        values={values}
                        errors={errors}
                        touched={touched}
                        accept={ATTACHMENT_SUPPORTED_FORMATS.toString()}
                        gcAccountId={activeAccount.gcAccountId}
                        fileGroupId={values.fileGroupId}
                        maxFilesCount={maxFilesCount}
                        maxFileSizeInMb={maxFileSizeInMb}
                        onFileUpload={uploadFile}
                        onFileDelete={deleteInvoiceFile}
                      />
                    )}
                  </FormField>
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </Paper>
        {!errors.supplierGcAccountId && !nonSupportedBusinessMessage && values.supplierAbn ? (
          <Box display='flex' justifyContent={isMobile ? 'inherit' : 'flex-end'} className={classes.footerButtons}>
            <Button className={classes.cancelButton} appearance='text' disabled={isSubmitting} onClick={handleCancel} data-testid='uia-cancelButton'>
              Cancel
            </Button>
            <Button className={classes.submitButton} appearance='primary' type='submit' disabled={isSubmitting} data-testid='uia-reviewButton'>
              Review and confirm
            </Button>
          </Box>
        ) : null}
      </Form>
    </BasicPageLayout>
  );
};

type WrapperProps = {
  ...Props,
  fileGroupIdForTesting: string,
  invoiceAttachmentConfig: AttachmentConfig,
  enqueueSnackbar: (snack: string, options?: Object) => void,
};

export default withRouter<WrapperProps>(
  withSnackbar(
    withFormik({
      mapPropsToValues: ({ fileGroupIdForTesting, invoice, history }): FormValues => {
        const fromReview = history.location.state && (history.location.state: any).fromReview;

        return {
          invoiceAmount: fromReview && invoice.amount !== 0 ? String(invoice.amount) : '',
          invoiceFiles: fromReview && invoice.attachments ? invoice.attachments : [],
          invoiceNumber: fromReview ? invoice.invoiceNumber : '',
          invoiceDescription: fromReview ? String(invoice.invoiceDescription) : '',
          supplierGcAccountId: fromReview && invoice.supplierGcAccountId ? invoice.supplierGcAccountId : '',
          supplierAccountName: fromReview ? invoice.supplierAccountName : '',
          supplierBsb: fromReview && invoice.supplierBsb !== 0 ? String(invoice.supplierBsb) : '',
          supplierBankAccountNumber: fromReview ? invoice.supplierBankAccountNumber : '',
          supplierContactId: fromReview ? invoice.supplierContactId : '',
          fileGroupId: fromReview ? invoice.fileGroupId : fileGroupIdForTesting || uuidv4(),
          supplierAbn: fromReview ? invoice.supplierAbn : '',
          isNewSupplier: invoice.isNewSupplier,
          invoicePrn: invoice.invoicePrn,
          isAto: false,
          isDirector: fromReview ? invoice.isDirector : false,
        };
      },
      validateOnBlur: false,
      validationSchema: formSchema,
      handleSubmit: (values: FormValues, formikBag: FormikBag<WrapperProps, FormValues>) => {
        const { props } = formikBag;
        const {
          invoiceAmount,
          invoiceNumber,
          invoiceFiles,
          invoiceDescription,
          supplierGcAccountId,
          supplierAbn,
          supplierAccountName,
          supplierBsb,
          supplierBankAccountNumber,
          fileGroupId,
          isNewSupplier,
          invoicePrn,
          isDirector,
        } = values;
        props
          .addInvoice(
            Number(invoiceAmount),
            values.isAto ? invoicePrn : invoiceNumber,
            invoiceFiles,
            values.isAto ? invoicePrn : invoiceDescription,
            supplierGcAccountId,
            supplierAbn,
            supplierAccountName,
            supplierBsb,
            supplierBankAccountNumber,
            fileGroupId,
            moment().toDate(),
            isNewSupplier,
            isDirector
          )
          .then((response) => {
            formikBag.setSubmitting(false);

            if (response.error) {
              defaultHandleError({ enqueueSnackbar: formikBag.props.enqueueSnackbar }, null);
              return;
            }

            props.history.push({
              pathname: '/buyer/payments/review',
              state: { from: props.history.location.pathname, validRedirectFromBuyer: true },
            });
          });
      },
      displayName: 'AddInvoice',
    })(withWidth()(AddInvoice))
  )
);
