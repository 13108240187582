import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AddPayment from './Component';
import actions from './actions';
import initialDataSelectors from '../../../components/InitialData/selectors';
import selectors from './selectors';
import startupConfigSelectors from '../../../components/StartupConfig/selectors';

const mapStateToProps = createSelector(
  initialDataSelectors.selectActiveAccount,
  selectors.selectInvoice,
  startupConfigSelectors.selectInvoiceAttachment,
  (activeAccount, invoice, invoiceAttachmentConfig) => ({
    activeAccount,
    invoice,
    invoiceAttachmentConfig,
  })
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddPayment);
