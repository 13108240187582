import React from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox/Checkbox';

export const fieldToCheckbox = ({ field, form: { isSubmitting }, disabled, ...props }) => {
  return {
    disabled: disabled !== undefined ? disabled : isSubmitting,
    ...props,
    ...field,
    checked: field.value,
    value: field.value,
  };
};

export const Checkbox = (props) => <MuiCheckbox {...fieldToCheckbox(props)} />;
