// @flow
import * as React from 'react';
import MUIDataTable from 'mui-datatables';
import { Box, MuiThemeProvider, Paper, makeStyles, TableRow, TableCell, ListItemText, Typography } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import { withTheme, createMuiTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';

import type { OrderBy } from '../api/shiftPaymentsApi';
import { PAGINATION_MAX_ROW_COUNT_MOBILE } from '../constants';
import { DownloadIcon, SortingIcon } from './svg';
import { isLargeMobileResolution } from '../lib/materialUiUtils';
import StyledMenu, { StyledMenuItem } from './StyledMenu';
import CustomSearchRender from './CustomSearchRender';

const getTextLabels = () => ({
  body: {
    noMatch: 'Sorry, no matching records found',
    toolTip: 'Sort',
    columnHeaderTooltip: (column) => `Sort by ${column.label}`,
  },
  pagination: {
    next: 'Next Page',
    previous: 'Previous Page',
    rowsPerPage: 'Rows per page:',
    displayRows: 'of',
  },
  toolbar: {
    search: 'Search',
    downloadCsv: 'Download CSV',
    print: 'Print',
    viewColumns: 'View Columns',
    filterTable: 'Filter Table',
  },
  filter: {
    all: 'All',
    title: 'Filters',
    reset: 'Reset',
  },
  viewColumns: {
    title: 'Show Columns',
    titleAria: 'Show/Hide Table Columns',
  },
  selectedRows: {
    text: 'row(s) selected',
    delete: 'Delete',
    deleteAria: 'Delete Selected Rows',
  },
});

const getBaseMuiTheme = (theme) => {
  return {
    ...theme,
    overrides: {
      MuiTableBody: {
        root: {
          [theme.breakpoints.up('xs')]: {
            backgroundColor: theme.palette.grey['200'],
          },
          [theme.breakpoints.up('sm')]: {
            backgroundColor: 'inherit',
          },
        },
      },
      MUIDataTableHeadCell: {
        sortLabelRoot: {
          height: 'inherit',
          '& svg': {
            width: 15,
            height: 15,
          },
        },
      },
      MUIDataTableBodyCell: {
        root: {
          borderBottom: `1px solid ${theme.palette.grey['200']}`,
        },
      },
      MuiTypography: {
        body1: {
          fontSize: 12,
          [theme.breakpoints.up('xs')]: {
            lineHeight: 1,
          },
          [theme.breakpoints.up('sm')]: {
            lineHeight: 'inherit',
          },
        },
      },
      MuiButton: {
        root: {
          fontSize: 12,
          textTransform: 'none',
        },
      },

      MuiPaper: {
        ...theme.overrides.MuiPaper,
      },
      MuiList: {
        ...theme.overrides.MuiList,
      },
      MUIDataTableToolbar: {
        root: {
          // display: 'none !important',
          [theme.breakpoints.up('xs')]: {
            backgroundColor: 'inherit',
            paddingLeft: 0,
            paddingRight: 0,
          },
          [theme.breakpoints.up('sm')]: {
            backgroundColor: theme.palette.common.white,
            paddingLeft: 16,
            paddingRight: 16,
          },
        },
        left: {
          marginTop: 12,
        },
        actions: {
          marginTop: 12,
          flexGrow: 0,
          '& button': {
            display: 'none',
          },
        },
        iconActive: {
          color: theme.palette.primary.main,
        },
      },
      MUIDataTableSearch: {
        searchText: {
          flexGrow: 1,
        },
      },
    },
  };
};

const createResponsiveStackedMuiTheme = (theme) => {
  const baseTheme = getBaseMuiTheme(theme);
  return createMuiTheme({
    ...baseTheme,
    overrides: {
      ...baseTheme.overrides,
      MuiTableCell: {
        root: {
          [theme.breakpoints.down('xs')]: {
            padding: 0,
            borderBottom: 0,
          },
        },
      },
      MuiTableRow: {
        root: {
          [theme.breakpoints.up('xs')]: {
            display: 'block !important',
            padding: 0,
            backgroundColor: 'inherit',
            '&:first-child': {
              margin: theme.spacing(0, 0, 1),
            },
            '&:not(:first-child)': {
              margin: theme.spacing(1, 0),
            },
            '&:nth-child(even)': {
              backgroundColor: 'inherit',
            },
          },
          [theme.breakpoints.up('sm')]: {
            display: 'table-row !important',
            padding: 'inherit',
            margin: 'inherit',
            backgroundColor: theme.palette.common.white,
            '&:nth-child(even)': {
              backgroundColor: theme.palette.common.white,
            },
            '&$hover:hover': {
              backgroundColor: theme.palette.common.lightGreen,
            },
          },
        },
      },
      MUIDataTableBodyRow: {
        responsiveStacked: {
          [theme.breakpoints.up('xs')]: {
            height: 100,
          },
          [theme.breakpoints.up('sm')]: {
            height: 'inherit',
            backgroundColor: theme.palette.common.white,
          },
          border: 'none !important',
          marginTop: theme.spacing(1),
        },
      },
    },
  });
};

const createResponsiveNonStackedMuiTheme = (theme) => {
  const baseTheme = getBaseMuiTheme(theme);
  return createMuiTheme({
    ...baseTheme,
    overrides: {
      ...baseTheme.overrides,
      MuiTableRow: {
        root: {
          [theme.breakpoints.up('xs')]: {
            display: 'table-row !important',
            padding: 'inherit',
            margin: 'inherit',
            backgroundColor: theme.palette.common.lightBlue,
            '&:nth-child(even)': {
              backgroundColor: theme.palette.common.white,
            },
          },
        },
      },
      MUIDataTableBodyRow: {
        responsiveStacked: {
          [theme.breakpoints.up('xs')]: {
            height: 'inherit',
            backgroundColor: theme.palette.common.lightBlue,
          },
          border: 'none !important',
          marginTop: theme.spacing(1),
        },
      },
    },
  });
};

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: 'none !important',
    [theme.breakpoints.up('xs')]: {
      backgroundColor: theme.palette.grey['200'],
    },
    [theme.breakpoints.up('sm')]: {
      backgroundColor: 'inherit',
    },
  },
  mobileRow: {
    margin: 0,
    padding: theme.spacing(2),
    boxShadow: 'none !important',
    borderTop: `1px solid ${theme.palette.secondary.solitude}`,
  },
  mobileRowCell: {
    display: 'block',
  },
  mobileLoadMoreButton: {
    backgroundColor: theme.palette.common.lightGreen,
    boxShadow: 'none',
  },
  mobileMenuSortExpandIcon: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0.5),
    },
  },
  mobileSortByButton: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      fontSize: 12,
    },
  },
  responsiveStackedToolbar: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0),
    },
  },
  responsiveNonStackedToolbar: {
    padding: theme.spacing(1),
  },
  downloadButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  downloadButton: {
    border: `1px solid ${theme.palette.grey['300']}`,
    backgroundColor: theme.palette.common.white,
    '& svg': {
      height: 16,
      width: 16,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 35,
      width: 35,
      height: 35,
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(1),
    },
  },
}));

// NOTE: check https://github.com/gregnb/mui-datatables to more details
export type Props = {
  width: string,
  theme: any,
  data: ?Array<Object>,
  columns: Array<Object>,
  options?: any,
  // mobileRowRender is optional. Used if you want a custom rendering for mobile.
  mobileRowRender?: ?(data: any, dataIndex: number, rowIndex: number) => React.Node,
  orderBy: ?Array<OrderBy>,
};

const DataGrid = (props: Props) => {
  const { theme, width, data, columns, options, mobileRowRender, orderBy } = props;
  const classes = useStyles();
  const isMobile = isLargeMobileResolution(width);
  const [stateSortMenuAnchorEl, setStateSortMenuAnchorEl] = React.useState(null);
  const tableRef = React.useRef(null);
  const [searchServersideText, setSearchServersideText] = React.useState(null);

  let isSearchActive = options ? !!options.search : false;

  let customOptions = {
    responsive: 'stacked',
    elevation: 0,
    filterType: 'checkbox',
    print: false,
    download: false,
    search: false,
    filter: false,
    sort: true,
    viewColumns: false,
    rowHover: true,
    selectableRowsHeader: false,
    pagination: false,
    selectableRows: 'none',
    textLabels: getTextLabels(),
    ...options,
    onTableChange: (action: string, state: any) => {
      if (action === 'sort') {
        state.searchServsideText = searchServersideText;
        // When the user clicks on sorting for any column on any page>1, the view would reset to page 1
        if (tableRef.current) {
          tableRef.current.changePage(0);
        }
      } else if (action === 'search') {
        // when the user search on any page > 1, the view would reset to page 1
        if (tableRef.current) {
          tableRef.current.changePage(0);
        }
      } else if (options) {
        if (action === 'changePage') {
          state.searchServsideText = searchServersideText;
        }
        options.onTableChange(action, state);
      }
    },
    customRowRender: undefined,
    searchOpen: isSearchActive,
    customSearchRender: isSearchActive
      ? (searchText, handleSearch, hideSearch, options) => {
          return (
            <CustomSearchRender
              searchText={options.serverSide ? searchServersideText : searchText}
              onSearch={options.serverSide ? handleServerSideSearch : handleSearch}
              onHide={hideSearch}
              options={options}
            />
          );
        }
      : undefined,
  };
  const isResponsiveStacked = customOptions.responsive === 'stacked';

  let actualColumns = columns;
  if (isMobile) {
    if (mobileRowRender && isResponsiveStacked) {
      customOptions = {
        ...customOptions,
        // eslint-disable-next-line react/display-name
        customRowRender: (rowData: any, dataIndex: number, rowIndex: number) => {
          return (
            <TableRow key={rowIndex} style={{ margin: 0 }}>
              <TableCell className={classes.mobileRowCell}>
                <Paper elevation={1} className={classes.mobileRow}>
                  {mobileRowRender(rowData, dataIndex, rowIndex)}
                </Paper>
              </TableCell>
            </TableRow>
          );
        },
      };

      // if mobile, remove the custom body rendering for desktop
      actualColumns = columns.map((column) => {
        if (Object.prototype.hasOwnProperty.call(column, 'options') && Object.prototype.hasOwnProperty.call(column.options, 'customBodyRender')) {
          const actualColumn = { ...column };
          delete actualColumn.options.customBodyRender;
          return actualColumn;
        }
        return column;
      });
    } else {
      delete customOptions.customRowRender;
    }

    customOptions = {
      ...customOptions,
      // eslint-disable-next-line react/display-name
      customFooter: (count: number, page: number, rowsPerPage: number, changeRowsPerPage: any, changePage: any) => {
        if (count === 0 || (data && (count <= data.length || data.length === PAGINATION_MAX_ROW_COUNT_MOBILE))) {
          return null;
        }

        return (
          <Button
            variant='contained'
            fullWidth
            className={classes.mobileLoadMoreButton}
            onClick={() => changePage(page + 1)}
            data-testid='uia-loadMoreButton'
          >
            Load more
            <ExpandMoreIcon />
          </Button>
        );
      },
      // // disable the column header sort feature if not responsive stacked
      sort: isResponsiveStacked || (isMobile && isResponsiveStacked),
    };
  } else {
    actualColumns = columns.map((column) => {
      if (Object.prototype.hasOwnProperty.call(column, 'options')) {
        const actualColumn = { ...column };
        if (column.options.sort && column.options.display !== false && !Object.prototype.hasOwnProperty.call(column.options, 'customHeadRender')) {
          // eslint-disable-next-line react/display-name
          actualColumn.options.customHeadRender = (columnMeta, updateDirection) => {
            const columnDisplayName = columnMeta.label || columnMeta.name;
            return (
              <TableCell key={columnMeta.index} onClick={() => updateDirection(columnMeta.index)} style={{ cursor: 'pointer' }}>
                <Box style={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                  <Typography style={{ fontWeight: '500' }}>{columnDisplayName} </Typography>
                  <SortingIcon sortDirection={columnMeta.sortDirection} />
                </Box>
              </TableCell>
            );
          };
        }
        return actualColumn;
      }
      return column;
    });
  }

  const handleMobileSortDropdownClick = (event) => {
    setStateSortMenuAnchorEl(event.currentTarget);
  };

  const handleMobileSortClose = () => {
    setStateSortMenuAnchorEl(null);
  };

  const handleServerSideSearch = (searchText: string) => {
    setSearchServersideText(searchText);
    if (customOptions && customOptions.onTableChange) {
      customOptions.onTableChange('search', {
        searchServsideText: searchText,
        searchRequestDateTime: new Date().getTime(),
        activeColumn: null,
        page: tableRef.current != null ? tableRef.current.state.page ?? 0 : 0,
        resetData: false,
        columns: columns,
      });
    }
  };

  const handleMobileSortClick = (event, colIndex: number) => {
    if (customOptions && customOptions.onTableChange) {
      const tempColumns = columns;
      const column = columns[colIndex];

      if (orderBy && orderBy.length) {
        // if the user has selected to sort on the same column, then reverse the sort
        if (column.name === orderBy[0].columnName) {
          tempColumns[colIndex].sortDirection = orderBy[0].isAscending ? 'desc' : 'asc';
        }
      } else {
        tempColumns[colIndex].sortDirection = 'asc';
      }

      // reset the data back to page 1 if sorting in mobile
      customOptions.onTableChange('changePage', {
        searchServsideText: searchServersideText,
        activeColumn: colIndex,
        page: 0,
        resetData: true,
        columns: tempColumns,
      });
      setStateSortMenuAnchorEl(null);
    }
  };

  const handleDownloadCsv = () => {
    const textLabels = getTextLabels();
    // HACK: go up to the parent node where we can query the toolbar and trigger the download button click
    if (tableRef.current && tableRef.current.tableRef && tableRef.current.tableRef.parentNode && tableRef.current.tableRef.parentNode.parentNode) {
      const downloadCsvButton = tableRef.current.tableRef.parentNode.parentNode.querySelectorAll(
        `button[title="${textLabels.toolbar.downloadCsv}"]`
      )[0];
      if (customOptions.onBeforeDownload) {
        customOptions.onBeforeDownload().then(() => downloadCsvButton.click());
        return;
      }
      downloadCsvButton.click();
    }
  };

  const isSortMenuOpen = Boolean(stateSortMenuAnchorEl);
  const dataGridTheme = isResponsiveStacked ? createResponsiveStackedMuiTheme(theme) : createResponsiveNonStackedMuiTheme(theme);

  return (
    <Paper elevation={isMobile ? 0 : 1} className={classes.container}>
      <Grid
        container
        className={clsx({
          [classes.responsiveStackedToolbar]: isResponsiveStacked,
          [classes.responsiveNonStackedToolbar]: !isResponsiveStacked,
        })}
      >
        <Grid item xs={12} style={{ backgroundColor: 'white', paddingLeft: '16', paddingRight: '16' }}>
          {isMobile && isResponsiveStacked && actualColumns.some((column) => column.options && column.options.sort) ? (
            <Paper elevation={1} margin='auto'>
              <Grid container justify='center'>
                <Grid align='center'>
                  <Button
                    aria-controls='mobileSortMenu'
                    aria-haspopup='true'
                    onClick={handleMobileSortDropdownClick}
                    className={classes.mobileSortByButton}
                    data-testid='uia-mobileShowSortMenu'
                  >
                    Sort by
                    {isSortMenuOpen ? (
                      <ExpandLessIcon className={classes.mobileMenuSortExpandIcon} />
                    ) : (
                      <ExpandMoreIcon className={classes.mobileMenuSortExpandIcon} />
                    )}
                  </Button>
                  <StyledMenu
                    id='mobileSortMenu'
                    data-testid='uia-mobileSortMenu'
                    anchorEl={stateSortMenuAnchorEl}
                    keepMounted
                    open={isSortMenuOpen}
                    onClose={handleMobileSortClose}
                  >
                    {actualColumns.map((column, colIndex) => {
                      const sortColumn = orderBy && orderBy.find((o) => o.columnName === column.name);
                      let sortDesc = '';
                      if (sortColumn) {
                        sortDesc = sortColumn.isAscending ? 'Ascending' : 'Descending';
                      }

                      return (
                        column.options &&
                        column.options.sort &&
                        (column.options.mobileSort === undefined || column.options.mobileSort) && (
                          <StyledMenuItem key={column.name} onClick={(event) => handleMobileSortClick(event, colIndex)} data-selected={!!sortColumn}>
                            <ListItemText
                              primary={isMobile && column.mobileLabel ? column.mobileLabel : column.label}
                              secondary={sortDesc ? `    (${sortDesc})` : null}
                              secondaryTypographyProps={{
                                component: 'span',
                              }}
                            />
                          </StyledMenuItem>
                        )
                      );
                    })}
                  </StyledMenu>
                </Grid>
              </Grid>
            </Paper>
          ) : null}
        </Grid>
        <Grid item xs={6} className={classes.downloadButtonContainer}>
          {customOptions.download ? (
            <Button
              className={classes.downloadButton}
              color='primary'
              onClick={handleDownloadCsv}
              size='small'
              data-testid='uia-downloadCsvButton'
              disabled={!data || !data.length}
            >
              <DownloadIcon />
              {!isMobile && 'Download CSV'}
            </Button>
          ) : null}
        </Grid>
      </Grid>
      <MuiThemeProvider theme={dataGridTheme}>
        <MUIDataTable ref={tableRef} data={data} columns={actualColumns} options={customOptions} />
      </MuiThemeProvider>
    </Paper>
  );
};

export default withWidth()(withTheme(DataGrid));
