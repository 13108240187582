import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import actions from './actions';
import Component from './Component';
import selectors from './selectors';
import { selectors as accountSetupCheckSelectors } from '../../components/AccountSetupCheck';
import initialDataSelectors from '../InitialData/selectors';

const mapStateToProps = createSelector(
  initialDataSelectors.selectSelectedGcAccountId,
  selectors.selectTotalBalanceRemaining,
  selectors.selectAvailableBalance,
  selectors.selectIsPayBalanceCaseOpen,
  selectors.selectNextScheduledPaymentAmount,
  selectors.selectNextScheduledPaymentDate,
  accountSetupCheckSelectors.selectBankAccountSetup,
  selectors.selectCreditLimit,
  (
    gcAccountId,
    totalBalanceRemaining,
    availableBalance,
    isPayBalanceCaseOpen,
    nextScheduledPaymentAmount,
    nextScheduledPaymentDate,
    bankAccountSetup,
    creditLimit
  ) => ({
    gcAccountId,
    totalBalanceRemaining,
    availableBalance,
    isPayBalanceCaseOpen,
    nextScheduledPaymentAmount,
    nextScheduledPaymentDate,
    bankAccountSetup,
    creditLimit,
  })
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Component);
