import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Customers from './Component';
import actions from './actions';
import initialDataSelectors from '../../../components/InitialData/selectors';
import accountSelectMenuSelectors from '../../../components/AccountSelectMenu/selectors';
import accountSetupCheckSelectors from '../../../components/AccountSetupCheck/selectors';

const mapStateToProps = createSelector(
  initialDataSelectors.selectAccounts,
  accountSelectMenuSelectors.selectActiveAccountIndex,
  accountSetupCheckSelectors.selectIsSettlementAccountSetupComplete,
  (accounts, activeAccountIndex, isSettlementAccountSetupCompleted) => ({
    gcAccountId: accounts ? accounts[activeAccountIndex].gcAccountId : null,
    isSettlementAccountSetupCompleted,
  })
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
