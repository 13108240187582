// @flow
import { PARTITION } from './constants';
import type { ReduxState } from './reducer';

const selectPartition = (state: any): ReduxState => state[PARTITION];

const selectSupplierRequest = (state: any) => selectPartition(state).detail;

export default {
  selectPartition,
  selectSupplierRequest,
};
