import * as Yup from 'yup';

import { ATTACHMENT_SUPPORTED_FORMATS, ATTACHMENT_MAX_FILE_NAME_LENGTH } from '../../../constants';

export const formSchema = (props) => {
  const {
    trustDeedAttachmentConfig: { maxFileSizeInMb, maxFilesCount },
  } = props;
  const maxFileSize = maxFileSizeInMb * 1048576;

  return Yup.object().shape({
    files: Yup.array()
      .test('uniqueFilename', 'Filenames must be unique', (value) => {
        return !value || value.length === new Set(value.map((x) => x.name)).size;
      })
      .of(
        Yup.mixed()
          .test('maxFileSize', `The maximum file size allowed is ${maxFileSizeInMb} MB`, (value) => {
            const isValid = value && value.size <= maxFileSize;
            return isValid;
          })
          .test('maxFileSize', 'The file must not be empty', (value) => {
            const isValid = value && value.size > 0;
            return isValid;
          })
          .test(
            'fileFormat',
            `The supported file types are ${ATTACHMENT_SUPPORTED_FORMATS.toString()}`,
            (value) => value && ATTACHMENT_SUPPORTED_FORMATS.includes(value.type)
          )
          .test(
            'fileName',
            `The maximum length of a file name is ${ATTACHMENT_MAX_FILE_NAME_LENGTH} characters`,
            (value) => value.name.length <= ATTACHMENT_MAX_FILE_NAME_LENGTH
          )
      )
      .min(1, 'Please attach a file')
      .max(maxFilesCount, `A maximum of ${maxFilesCount} files is allowed`),
  });
};
