// @flow
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';
import widgetsApi from '../../../api/widgetsApi';
import { defaultHandleError } from '../../../lib/apiHelpers';

export type SupplierTermFee = {
  term: number,
  termPeriod: string,
};

export const useSupplierTermFeeLookup = (gcAccountId: string): Array<SupplierTermFee> => {
  const [lookup, setLookup] = useState<Array<SupplierTermFee>>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    shiftPaymentsApi
      .getSupplierTermFeeLookup(gcAccountId)
      .then((response) => {
        setLookup(response.data);
      })
      .catch(() => {
        defaultHandleError({ enqueueSnackbar }, undefined);
      });
  }, [gcAccountId]);

  return lookup;
};

export const useNotAuthorizedWidgets = (gcAccountId: string): { names: Array<string>, isLoading: boolean } => {
  const [notAuthorizedWidgets, setNotAuthorizedWidgets] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    widgetsApi
      .getWidgetsAuthorizations(gcAccountId)
      .then((response) => {
        const notAuthorizedWidgets = response.data.filter((x) => !x.isCodeGenerationAuthorized).map((x) => x.widgetName);
        setNotAuthorizedWidgets(notAuthorizedWidgets);
      })
      .catch(() => {
        defaultHandleError({ enqueueSnackbar }, undefined);
        setNotAuthorizedWidgets([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [gcAccountId]);

  return { names: notAuthorizedWidgets, isLoading };
};
