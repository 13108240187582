/* eslint-disable react/prop-types */
// @flow
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { withFormik, Form } from 'formik';
import { withRouter, Redirect, type Location, type RouterHistory } from 'react-router';
import { Paper, Button, Typography, Grid, Box, Hidden } from '@material-ui/core';
import type { FormikBag } from 'formik';
import type { Dispatch } from 'redux';
import { withSnackbar } from 'notistack';
import withWidth from '@material-ui/core/withWidth';
import SetupPageLayout from '../../components/SetupPageLayout';
import AgreementContent from '../../components/AgreementContent';
import { defaultHandleError, handleError } from '../../lib/apiHelpers';
import { type AccountSetupStatus } from '../../components/AccountSetupCheck/reducer';
import { type NormalizedBankAccount } from './actions';
import BsbText from '../../components/BsbText';
import { startNewCreditLimitRequestPolling } from '../../lib/creditLimitStatusService';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(3, 2, 0, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 120,
    },
  },
  bankAccountContainer: {
    border: `1px solid ${theme.palette.grey['300']}`,
    margin: theme.spacing(0, 0, 3),
  },
  headerText: {
    margin: theme.spacing(0, 1),
    fontWeight: 500,
    lineHeight: 2.5,
  },
  bankName: {
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
    padding: theme.spacing(1, 2),
    display: 'flex',
  },
  bankAccountItem: {
    backgroundColor: theme.palette.grey['200'],
    padding: theme.spacing(2),
  },
  bankAccountItemHeader: {
    fontSize: 12,
    fontWeight: 500,
    padding: theme.spacing(1, 2),
  },
  bankAccountName: {
    [theme.breakpoints.down('xs')]: {
      fontWeight: 500,
    },
  },
  header: {
    fontWeight: 400,
  },
  row: {
    fontWeight: 500,
  },
  image: {
    maxWidth: '100px',
    width: '100%',
    height: 'auto',
  },
  accountDetailsSubText: {
    fontWeight: 500,
    color: theme.palette.grey['500'],
    marginRight: theme.spacing(1),
  },
  agreementContent: {
    display: 'inline-block',
  },
  iAgreeText: {
    marginTop: theme.spacing(2.5),
  },
}));

type FormValues = {
  agreement: {
    name: string,
    currentActiveAgreementVersion?: string,
    base64text?: string,
  },
};

type Props = {
  agreementSetup: AccountSetupStatus,
  bankAccountSetup: AccountSetupStatus,
  isSubmitting: boolean,
  getDirectDebitAgreement: () => Promise<any>,
  setFieldValue: (fieldName: string, value: any, shouldValidate?: boolean) => void,
  values: FormValues,
  location: any,
  history: any,
  isAccountSetupLoaded: boolean,
};

const normalizeSelectedBankAccount = (locationStateBankAccount: any, bankAccountSetup: AccountSetupStatus): ?NormalizedBankAccount => {
  if (locationStateBankAccount) {
    return {
      accountNumber: locationStateBankAccount.accountNumber,
      accountName: locationStateBankAccount.name,
      bsb: locationStateBankAccount.bsb,
      bankAccountId: locationStateBankAccount.id,
      bankName: locationStateBankAccount.bankName,
      bankIcon: locationStateBankAccount.bankIcon,
    };
  }

  if (bankAccountSetup.data && bankAccountSetup.data.length) {
    const existingBankAccount = bankAccountSetup.data.find((x) => x.isShiftBankAccount);
    return {
      accountNumber: existingBankAccount.bankAccountNumber,
      accountName: existingBankAccount.bankAccountName,
      bsb: existingBankAccount.bsb,
      bankAccountId: existingBankAccount.bankAccountId,
      bankName: existingBankAccount.bankName,
      bankIcon: existingBankAccount.bankIcon,
    };
  }

  return null;
};

const DirectDebitAgreement = (props: Props) => {
  const { agreementSetup, bankAccountSetup, isSubmitting, getDirectDebitAgreement, setFieldValue, values, location, isAccountSetupLoaded } = props;
  const classes = useStyles();

  useEffect(() => {
    if (!isAccountSetupLoaded) {
      return;
    }
    getDirectDebitAgreement().then((response) => {
      if (response.error) {
        return;
      }

      setFieldValue('agreement', response.payload.data);
    });
  }, [isAccountSetupLoaded]);

  const ddrAgreementSigned =
    (location.state && location.state.selectedBankAccount && location.state.selectedBankAccount.ddrAgreementSigned) ||
    (!location.state && agreementSetup && agreementSetup.isCompleted);
  if (ddrAgreementSigned) {
    return <Redirect to='/' />;
  }

  let agreementText = '';
  if (values.agreement && values.agreement.base64text) {
    agreementText = values.agreement.base64text;
  }

  const selectedBankAccount = normalizeSelectedBankAccount(location.state && location.state.selectedBankAccount, bankAccountSetup);

  return (
    <SetupPageLayout
      title='Direct Debit request service agreement'
      subtitle={
        'Please read the following agreement carefully before continuing. By agreeing electronically, ' +
        'you acknowledge that you have both read and understood all text presented to you.'
      }
    >
      {selectedBankAccount ? (
        <Paper elevation={0} className={classes.bankAccountContainer}>
          <Box className={classes.bankName}>
            <img src={selectedBankAccount.bankIcon} className={classes.image} alt={selectedBankAccount.bankName} />
            <Typography className={classes.headerText} style={{ marginRight: 'auto' }}>
              {selectedBankAccount.bankName}
            </Typography>
          </Box>
          <Hidden xsDown>
            <Grid container className={classes.bankAccountItemHeader}>
              <Grid item sm={5}>
                Account
              </Grid>
              <Grid item sm={2}>
                BSB
              </Grid>
              <Grid item sm={5}>
                Account No.
              </Grid>
            </Grid>
          </Hidden>
          <Box alignItems='center' className={classes.bankAccountItem}>
            <Grid container alignItems='baseline'>
              <Grid item xs={12} sm={5}>
                <Typography variant='body1' className={classes.bankAccountName}>
                  {selectedBankAccount.accountName}
                </Typography>
              </Grid>
              <Grid item xs={5} sm={2}>
                <Hidden smUp>
                  <Typography className={classes.accountDetailsSubText} variant='body2' display='inline'>
                    BSB
                  </Typography>
                </Hidden>
                <Typography display='inline' variant='body1'>
                  <BsbText value={selectedBankAccount.bsb} />
                </Typography>
              </Grid>
              <Grid item xs={7} sm={5}>
                <Hidden smUp>
                  <Typography variant='body2' display='inline' className={classes.accountDetailsSubText}>
                    ACC
                  </Typography>
                </Hidden>
                <Typography display='inline' variant='body1'>
                  {selectedBankAccount.accountNumber}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      ) : null}
      <Grid container className={classes.agreementContent} sm={12}>
        <Form noValidate autoComplete='off'>
          {agreementText ? <AgreementContent base64Text={agreementText} height='40vh' /> : null}
          <Typography variant='body1' className={classes.iAgreeText}>
            I have read and agree to the terms of Direct Debit request service agreement.
          </Typography>
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            type='submit'
            disabled={isSubmitting || !values.agreement}
            data-testid='uia-agreeButton'
          >
            I agree
          </Button>
        </Form>
      </Grid>
    </SetupPageLayout>
  );
};

type WrapperProps = {
  ...Props,
  gcAccountId: string,
  setupBankAccountAndSignDirectDebitAgreement: (
    gcAccountId: string,
    bankAccount: {
      accountNumber: string,
      accountName: string,
      bsb: string,
      bankAccountId: string,
      lbfcId: string,
    }
  ) => Promise<any>,
  updateBankAccountAndDirectDebitSetupComplete: () => void,
  location: Location,
  history: RouterHistory,
  closeSnackbar: () => void,
  enqueueSnackbar: (message: string, options: any) => void,
  dispatch: Dispatch<any>,
};

export default withRouter<WrapperProps>(
  withSnackbar(
    withFormik({
      mapPropsToValues: (): FormValues => ({
        agreement: {
          name: '',
        },
      }),
      validateOnBlur: false,
      handleSubmit: (values: FormValues, formikBag: FormikBag<WrapperProps, FormValues>) => {
        const {
          closeSnackbar,
          enqueueSnackbar,
          gcAccountId,
          updateBankAccountAndDirectDebitSetupComplete,
          setupBankAccountAndSignDirectDebitAgreement,
          bankAccountSetup,
          location,
          history,
        } = formikBag.props;

        closeSnackbar();

        let lbfcId: string = '';
        let selectedBankAccount: ?NormalizedBankAccount;
        let selectBankAccount = false;

        if (location.state) {
          lbfcId = location.state.lbfcId;
          selectedBankAccount = location.state.selectedBankAccount;
          selectBankAccount = location.state.select;
        }

        const bankAccount = normalizeSelectedBankAccount(selectedBankAccount, bankAccountSetup);

        if (!bankAccount) {
          enqueueSnackbar('Invalid bank account', {
            variant: 'error',
            key: 'ddr',
          });
          return;
        }

        const { accountNumber, accountName, bsb, bankAccountId } = bankAccount;

        setupBankAccountAndSignDirectDebitAgreement(gcAccountId, {
          accountNumber,
          accountName,
          bsb,
          bankAccountId,
          lbfcId,
          select: selectBankAccount,
        })
          .then((response) => {
            formikBag.setSubmitting(false);

            if (response.error) {
              defaultHandleError(formikBag.props);
              return;
            }

            enqueueSnackbar('Bank link connected. Please allow 5 minutes to update the records', {
              variant: 'success',
              persist: true,
              key: 'ddr',
            });

            updateBankAccountAndDirectDebitSetupComplete();
            if (response.payload && response.payload.data && response.payload.data.requestedCreditLimit) {
              //start polling if credit limit change requested
              const { isNewLimitBasedOnSales, requestedLimit, currentAccountLimit } = response.payload.data.requestedCreditLimit;
              if (isNewLimitBasedOnSales || requestedLimit) {
                startNewCreditLimitRequestPolling(
                  formikBag.props.dispatch,
                  enqueueSnackbar,
                  gcAccountId,
                  currentAccountLimit,
                  isNewLimitBasedOnSales
                );
              }
            }
            history.push('/');
          })
          .catch((error) => {
            handleError(error, formikBag.props);
            formikBag.setSubmitting(false);
          });
      },
      displayName: 'DirectDebitAgreement',
    })(withWidth()(DirectDebitAgreement))
  )
);
