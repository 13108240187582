/* eslint-disable flowtype/no-types-missing-file-annotation */

import { type ReferralAccount } from '../../types';
import { setReferralAccountFromQueryString } from '../InitialData/actions';

const setReferralAccount = (referralAccount: ?ReferralAccount) => (dispatch) => {
  return dispatch(setReferralAccountFromQueryString(referralAccount));
};

export default {
  setReferralAccount,
};
