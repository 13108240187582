//@ flow
import asyncSessionStorage from './asyncSessionStorage';
import { SESSION_STORAGE_INTERVALS_AND_TIMEOUTS } from '../constants';

const asyncSessionStorageIntervalsAndTimeouts = {
  addIntervalAndTimeout: async (interval, timeout) => {
    let intervalsAndTimeouts = await asyncSessionStorage.getItem(SESSION_STORAGE_INTERVALS_AND_TIMEOUTS);
    let intervalsAndTimeoutsParsed = JSON.parse(intervalsAndTimeouts) || {
      intervals: [],
      timeouts: [],
    };
    intervalsAndTimeoutsParsed.intervals.push(interval);
    intervalsAndTimeoutsParsed.timeouts.push(timeout);
    return asyncSessionStorage.setItem(SESSION_STORAGE_INTERVALS_AND_TIMEOUTS, JSON.stringify(intervalsAndTimeoutsParsed));
  },
  clearIntervalsAndTimeoutsThenRemoveItem: async () => {
    const intervalsAndTimeouts = await asyncSessionStorage.getItem(SESSION_STORAGE_INTERVALS_AND_TIMEOUTS);
    let intervalsAndTimeoutsParsed = JSON.parse(intervalsAndTimeouts) || {
      intervals: [],
      timeouts: [],
    };
    for (let i = 0; i < intervalsAndTimeoutsParsed.intervals.length; i++) {
      clearInterval(intervalsAndTimeoutsParsed.intervals[i]);
    }
    for (let i = 0; i < intervalsAndTimeoutsParsed.timeouts.length; i++) {
      clearTimeout(intervalsAndTimeoutsParsed.timeouts[i]);
    }
    return asyncSessionStorage.removeItem(SESSION_STORAGE_INTERVALS_AND_TIMEOUTS);
  },
};

export default asyncSessionStorageIntervalsAndTimeouts;
