// @flow
import { PARTITION } from './constants';
import type { ReduxState } from './reducer';

const selectPartition = (state: any): ReduxState => state[PARTITION];
const selectFacilityLimits = (state: any) => selectPartition(state).facilityLimits;
const isLoadingFacilityLimits = (state: any) => selectPartition(state).facilityLimitsPending;

export default {
  selectPartition,
  selectFacilityLimits,
  isLoadingFacilityLimits,
};
