// @flow
import { UserProfileMode } from '../types';
import { ROUTES, Permission } from '../constants';

class Tab {
  id: number;

  key: string;

  label: string;

  routes: Array<string>;

  disabled: boolean;

  constructor(id: number, key: string, label: string, routes: Array<string>, disabled?: boolean) {
    this.id = id;
    this.key = key;
    this.label = label;
    this.routes = routes;
    this.disabled = disabled || false;
  }
}

const getSupplierTabs = (ability: any, isMobileView: boolean) => {
  const tabs = [];
  // Array(new Tab(0, 'Dashboard', 'Dashboard', [ROUTES.SUPPLIER.DASHBOARD, '/']));
  tabs.push(new Tab(0, 'Payments', 'Payments', ['/supplier/payments']));
  tabs.push(new Tab(1, 'Customers', 'Customers', ['/supplier/customers', '/supplier/customers/add']));
  if (!isMobileView && ability.can(Permission.Actions.Create, Permission.Supplier.Buyer)) {
    tabs.push(new Tab(2, 'Resources', 'Resources', ['/supplier/resources']));
  }
  return tabs;
};

const getBuyerTabs = (isAllKycCompleted: boolean) => {
  const tabs = Array(new Tab(0, 'Dashboard', 'Dashboard', [ROUTES.BUYER_DASHBOARD]));
  tabs.push(new Tab(1, 'Invoices', 'Invoices', ['/buyer/payments', '/buyer/payments/pay'], !isAllKycCompleted));
  tabs.push(new Tab(2, 'Suppliers', 'Suppliers', ['/buyer/suppliers'], !isAllKycCompleted));
  tabs.push(new Tab(3, 'PaymentSchedule', 'Repayments', ['/buyer/payment-schedule'], !isAllKycCompleted));
  return tabs;
};

export const getNavigations = (
  isAllKycCompleted: boolean,
  isBuyer: boolean,
  isSupplier: boolean,
  activeProfileMode: number,
  isMobileView: boolean,
  ability: any
) => {
  if (activeProfileMode === UserProfileMode.Supplier && isSupplier) {
    return getSupplierTabs(ability, isMobileView);
  }
  // Supplier wants to become a buyer
  if (activeProfileMode === UserProfileMode.Buyer && isSupplier && !isBuyer) {
    return getSupplierTabs(ability, isMobileView);
  }
  return getBuyerTabs(isAllKycCompleted);
};

export const supplierBuyerMenuList = () => {
  return [
    { id: UserProfileMode.Buyer, text: 'Accounts Payable' },
    { id: UserProfileMode.Supplier, text: 'Accounts Receivable' },
  ];
};

export const selectActiveProfileMode = (): number =>
  window.location.pathname.startsWith('/supplier') ? UserProfileMode.Supplier : UserProfileMode.Buyer;

export const selectActiveProfileModeBasedPath = (relativePath: string, isSupplierBecomeBuyer: boolean = false) => {
  const routes = Object.assign([], {
    [UserProfileMode.Supplier]: '/supplier',
    [UserProfileMode.Buyer]: '/buyer',
  });

  // supplier wants to become a buyer, hence ignore the active profile mode
  if (isSupplierBecomeBuyer) {
    return `/buyer${relativePath}`;
  } else {
    const activeProfileModeIndex = selectActiveProfileMode();
    return `${routes[activeProfileModeIndex]}${relativePath}`;
  }
};
