// @flow
import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import SuccessIcon from './svg/SuccessIcon';

const useStyles = makeStyles((theme) => ({
  button: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
      padding: theme.spacing(0, 0, 0, 1),
      minWidth: theme.spacing(16),
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0),
      padding: theme.spacing(0, 0, 0, 1),
      minWidth: theme.spacing(14),
    },
    [theme.breakpoints.up('md')]: {
      minWidth: theme.spacing(14),
    },
  },
  message: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.success.main,
    fontFamily: 'Rubik',
    margin: theme.spacing(0, 0, 0, 2),
    display: 'inline-flex',
    alignSelf: 'center',
  },
  subMessage: {
    clear: 'both',
    display: 'inline-flex',
  },
  alertIcon: {
    marginTop: '4px',
    height: '18px',
    alignSelf: 'center',
    display: 'inline-flex',
  },
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
}));

type SuccessMessageProps = {
  message: string,
  subMessage?: string,
};

const SuccessMessage = ({ message, subMessage }: SuccessMessageProps) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, classes.container)} border={0} borderRadius='borderRadius'>
      <SuccessIcon className={classes.alertIcon} />
      <Typography className={classes.message} paragraph data-testid='uia-successMessage'>
        {message}
      </Typography>
      {subMessage ? <Typography className={classes.subMessage} variant='subtitle1' data-testid='uia-successSubMessage'>
          {subMessage}
        </Typography> : null}
    </Box>
  );
};

export default SuccessMessage;
