// @flow
import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';

import NumberFormatInput from './NumberFormatInput';

type Props = {
  onChange: ?(event: any) => void,
  onKeyPress: ?(event: SyntheticKeyboardEvent<any>) => void,
  onKeyUp: ?(event: SyntheticKeyboardEvent<any>) => void,
  pattern: string,
  format: string,
  readOnly: boolean,
  disabled: boolean,
  value: string,
  autoFocus: boolean,
  error: boolean,
};

const handleMobileNumberKeyPress = (e) => {
  const { which, keyCode, target } = e;
  const { value } = target;
  const charCode = which || keyCode;
  const char = String.fromCharCode(charCode);
  if (char === '0' && !value) {
    e.preventDefault();
  }
};

const PhoneNumberInput = ({ onChange, onKeyPress, onKeyUp, pattern, format, readOnly, disabled, value, autoFocus, error, ...other }: Props) => {
  return (
    <NumberFormatInput
      onChange={onChange}
      onKeyPress={onKeyPress || handleMobileNumberKeyPress}
      onKeyUp={onKeyUp}
      pattern={pattern}
      format={format}
      readOnly={readOnly}
      disabled={disabled}
      value={value}
      autoFocus={autoFocus}
      startAdornment={<InputAdornment position='start'>+61</InputAdornment>}
      type='tel'
      error={error}
      // $FlowFixMe
      {...other}
    />
  );
};

PhoneNumberInput.defaultProps = {
  onChange: undefined,
  onKeyPress: undefined,
  onKeyUp: undefined,
  pattern: null,
  format: null,
  readOnly: false,
  disabled: false,
  value: null,
  autoFocus: false,
  other: undefined,
};

export default PhoneNumberInput;
