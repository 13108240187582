// @flow
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { useSnackbar } from 'notistack';
import ButtonSelect from '../../components/ButtonSelect';
import PageItem from '../../components/PageItem';
import { BankAccounts } from '../../components/BankAccounts';
import type { BankAccount } from '../../components/BankAccounts';
import { AdapterLink, isMobileResolution } from '../../lib/materialUiUtils';
import BasicPageLayout from '../../components/BasicPageLayout';
import { ROUTES, DEFAULT_ERROR_MESSAGE, Permission } from '../../constants';
import { selectActiveProfileModeBasedPath } from '../../lib/profileHelper';
import { Can } from '../../lib/ability';
import { WeekdayValues, type Weekday } from '../../api/shiftPaymentsApi';

type Props = {
  classes: any,
  repaymentDay: number,
  smsReminder: boolean,
  directDebitAccount: BankAccount[],
  currentAccountLimit: number,
  gcAccountId: string,
  getSettings: (gcAccountId: string) => Promise<any>,
  setRepaymentDay: (gcAccountId: string, day: Weekday, smsReminder: boolean) => Promise<any>,
  setSmsReminder: (gcAccountId: string, smsReminder: boolean) => Promise<any>,
  requestAccountLimitIncrease: (gcAccountId: string, limit: number) => Promise<any>,
  width: string,
  persistSnackbarForTesting: boolean,
};

const styles = (theme) => ({
  header: {
    marginBottom: theme.spacing(4),
  },
  item: {
    padding: theme.spacing(0.5, 0),
  },
  repaymentLabel: {
    padding: theme.spacing(0, 0, 1),
  },
});

const Settings = (props: Props) => {
  const {
    classes,
    repaymentDay,
    directDebitAccount,
    smsReminder,
    gcAccountId,
    getSettings,
    setRepaymentDay,
    setSmsReminder,
    persistSnackbarForTesting = false,
    width,
  } = props;

  const repaymentDayOptions = Object.values(WeekdayValues).map((o) => ({ id: o, text: (o: any) }));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isMobile = isMobileResolution(width);

  useEffect(() => {
    getSettings(gcAccountId);
  }, [gcAccountId, getSettings]);

  const handleResponse = (isError, errorMessage) => {
    if (isError) {
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        persist: persistSnackbarForTesting,
        key: 'settings',
      });
      return;
    }

    enqueueSnackbar('Request submitted successfully', {
      variant: 'success',
      persist: persistSnackbarForTesting,
      key: 'settings',
    });
  };

  const handleSetReminderChange = (checked) => {
    closeSnackbar();
    const errorMessage = DEFAULT_ERROR_MESSAGE;
    setSmsReminder(gcAccountId, checked)
      .then((response) => {
        const isError = response && response.error;
        handleResponse(isError, errorMessage);
      })
      .catch(() => {
        handleResponse(true, errorMessage);
      });
  };

  const handleRepaymentDayChange = (optionId: Weekday) => {
    closeSnackbar();
    const errorMessage = DEFAULT_ERROR_MESSAGE;
    setRepaymentDay(gcAccountId, optionId, smsReminder)
      .then((response) => {
        const isError = response && response.error;
        handleResponse(isError, errorMessage);
      })
      .catch(() => {
        handleResponse(true, errorMessage);
      });
  };

  return (
    <BasicPageLayout title='Settings' noMargin={!isMobile}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <PageItem header='Repayment settings'>
            <Grid item>
              <InputLabel className={classes.repaymentLabel}>
                <Typography variant='h6' component='label'>
                  Choose the day for your weekly repayment
                </Typography>
              </InputLabel>
            </Grid>
            <Grid item>
              <ButtonSelect
                data-testid='uia-repaymentDay'
                value={repaymentDay}
                onSelectionChange={(option) => handleRepaymentDayChange(option.id)}
                options={repaymentDayOptions}
              />
            </Grid>
          </PageItem>
        </Grid>
        <Grid item sm={12}>
          <PageItem header='Nominated direct debit account'>
            <Grid container>
              <Grid className={classes.item} item xs={12}>
                <BankAccounts accounts={directDebitAccount} />
              </Grid>
              <Can I={Permission.Actions.Create} a={Permission.Buyer.BankAccount}>
                {() => (
                  <Grid className={classes.item} item xs={12}>
                    <Button
                      variant='outlined'
                      color='primary'
                      component={AdapterLink}
                      to={{
                        pathname: selectActiveProfileModeBasedPath(ROUTES.CHANGE_BANK_ACCOUNT),
                        state: { title: 'Change my payment method' },
                      }}
                    >
                      Change my payment method
                    </Button>
                  </Grid>
                )}
              </Can>
            </Grid>
          </PageItem>
        </Grid>
        <Grid item sm={12}>
          <PageItem header='Notification settings'>
            <Grid container>
              <Grid className={classes.item} item xs={12}>
                <Typography>
                  To help you with your payments, we are able to send you a reminder message that lets you know what your next upcoming payment will
                  be.
                </Typography>
              </Grid>
              <Grid className={classes.item} item xs={12}>
                <form>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={smsReminder}
                          onChange={(_, checked) => handleSetReminderChange(checked)}
                          data-testid='uia-smsReminder'
                          id='smsReminder'
                          name='smsReminder'
                        />
                      }
                      label='Send me an SMS reminder'
                    />
                  </FormGroup>
                </form>
              </Grid>
            </Grid>
          </PageItem>
        </Grid>
      </Grid>
    </BasicPageLayout>
  );
};

export default withStyles(styles)(Settings);
