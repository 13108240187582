// @flow
import React from 'react';
import { formatAbnOrAcn } from '../lib/formatter';

type Props = {
  value: string,
};

const AbnText = ({ value }: Props) => <>{formatAbnOrAcn(value)}</>;

export default AbnText;
