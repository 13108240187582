import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import SideNavigation from './Component';
import initialDataSelectors from '../InitialData/selectors';
import profileModeSelectors from '../UserSelectProfileMode/selectors';
import accountSetupCheckSelectors from '../AccountSetupCheck/selectors';

const mapStateToProps = createSelector(
  initialDataSelectors.selectIsAllKycCompleted,
  initialDataSelectors.selectActiveAccount,
  profileModeSelectors.selectActiveProfileMode,
  accountSetupCheckSelectors.selectIsAllSetupCompleteRequirement,
  (isAllKycCompleted, activeAccount, activeProfileMode, isAllSetupCompleteRequirement) => {
    const isSupplier = activeAccount && activeAccount.isSupplier;
    const isBuyer = activeAccount && activeAccount.isBuyer;
    return {
      isBuyer,
      isSupplier,
      isAllKycCompleted,
      activeProfileMode,
      isNavigationVisible: isAllSetupCompleteRequirement || isSupplier,
    };
  }
);

export default connect(mapStateToProps)(SideNavigation);
