import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from '@reduxjs/toolkit';
import Component from './Component';
import actions from '../Review/actions';
import selectors from '../Review/selectors';
import initialDataSelectors from '../../../components/InitialData/selectors';

const mapStateToProps = createSelector(
  selectors.selectInvoice,
  selectors.selectInstallmentTermInWeeks,
  initialDataSelectors.selectSelectedGcAccountId,
  selectors.selectExtensionTermsAndFees,
  selectors.selectPaymentDate,
  (invoice, installmentTermInWeeks, gcAccountId, extensionTermsAndFees, paymentDate) => ({
    invoice,
    installmentTermInWeeks,
    gcAccountId,
    extensionTermsAndFees,
    paymentDate,
  })
);
const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Component);
