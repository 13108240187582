import * as Yup from 'yup';
import {
  ATTACHMENT_MAX_FILE_NAME_LENGTH,
  MANUAL_STATEMENT_SUPPORTED_FORMATS,
  MAX_FILE_SIZE_IN_MB_BANKSTATEMENTS,
  MAX_FILES_COUNT_BANKSTATEMENTS,
} from '../../constants';

export const formSchema = () => {
  const maxFileSize = MAX_FILE_SIZE_IN_MB_BANKSTATEMENTS * 1048576;

  return Yup.object().shape({
    statements: Yup.array()
      .of(
        Yup.mixed()
          .test('maxFileSize', `The maximum file size allowed is ${MAX_FILE_SIZE_IN_MB_BANKSTATEMENTS}MB`, (value) => {
            const isValid = value && value.size <= maxFileSize;
            return isValid;
          })
          .test('maxFileSize', 'The file must not be empty', (value) => {
            const isValid = value && value.size > 0;
            return isValid;
          })
          .test('fileFormat', 'Please upload a PDF file', (value) => value && MANUAL_STATEMENT_SUPPORTED_FORMATS.includes(value.type))
          .test(
            'fileName',
            `The maximum length of a file name is ${ATTACHMENT_MAX_FILE_NAME_LENGTH} characters`,
            (value) => value.name.length <= ATTACHMENT_MAX_FILE_NAME_LENGTH
          )
      )
      .min(1, 'Bank statement is required')
      .max(MAX_FILES_COUNT_BANKSTATEMENTS, `A maximum of ${MAX_FILES_COUNT_BANKSTATEMENTS} files is allowed`),
  });
};
