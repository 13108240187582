// @flow
import React from 'react';
import { Grid, Button, Select, FormControl, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  selected: {
    fontWeight: 500,
    border: `1px solid ${theme.palette.action.selected}`,
    backgroundColor: `${theme.palette.action.selected} !important`,
    color: `${theme.palette.action.contrastText} !important`,
  },
  default: {
    fontWeight: 400,
    color: theme.palette.grey.contrastText,
    borderColor: theme.palette.action.light,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      borderColor: theme.palette.action.light,
    },
  },
}));

type Option = {
  id: any,
  text: string,
};

export type Props = {
  options: Array<Option>,
  onSelectionChange: (option: Option) => void,
  value: any,
  classes?: {
    selected: any,
    default: any,
  },
  dropdown?: boolean,
  inputProps?: {
    name?: string,
  },
};

const ButtonSelect = ({ options, value, onSelectionChange, classes, dropdown, inputProps, ...other }: Props) => {
  const [stateSelectedIndex, setSelectedIndex] = React.useState<any>(value);
  const styles = useStyles();
  const overridedStyles = classes || styles;

  React.useEffect(() => {
    const selectedIndex = options.findIndex((x) => x.id === value);
    setSelectedIndex(selectedIndex);
  }, [value, options]);

  const handleButtonSelection = (i) => () => {
    setSelectedIndex(i);
    if (onSelectionChange) {
      onSelectionChange(options[i]);
    }
  };

  const handleSelection = (e) => {
    const i = options.findIndex((x) => x.text === e.target.value);
    setSelectedIndex(i);
    if (onSelectionChange) {
      onSelectionChange(options[i]);
    }
  };

  if (dropdown) {
    return (
      <FormControl
        variant='outlined'
        fullWidth
        // $FlowFixMe
        {...other}
      >
        <Select onChange={handleSelection} value={value} inputProps={inputProps}>
          {options ? options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.text}
              </MenuItem>
            )) : null}
        </Select>
      </FormControl>
    );
  }

  return (
    <Grid container spacing={2}>
      {options ? options.map((option, i) => {
          const isSelected = stateSelectedIndex === i;
          return (
            <Grid item key={option.text}>
              <Button
                key={option.text}
                variant='outlined'
                className={isSelected ? overridedStyles.selected : overridedStyles.default}
                size='large'
                onClick={isSelected ? undefined : handleButtonSelection(i)}
              >
                {option.text}
              </Button>
            </Grid>
          );
        }) : null}
    </Grid>
  );
};

ButtonSelect.defaultProps = {
  onSelectionChange: null,
  value: null,
};

export default ButtonSelect;
