// @flow
import Axios from 'axios';
import baseApi from './baseApi';
import startupConfig from '../lib/startupConfig';

export const WidgetTypeValues = {
  Inline: 'Inline',
  Modal: 'Modal',
  FullPage: 'FullPage',
};

export type WidgetType = $Keys<typeof WidgetTypeValues>;

const get = (partUrl: string, cacheControl: string = 'no-cache'): Promise<any> => {
  const config = startupConfig.get();
  const baseUrl = config ? config.widgetsUrl || '' : '';
  return baseApi.get({ url: `${baseUrl}${partUrl}`, cacheControl });
};

const getOpenAccountButtonThemes = () => get('/shift/v1/open-account-button/themes', '');

const getEmbedOpenAccountButton = (gcAccountId: string, term: number, termPeriod: string, theme: string) => {
  const config = startupConfig.get();
  const baseUrl = config ? config.widgetsUrl || '' : '';
  return baseApi.get({
    url: `${baseUrl}/shift/v1/open-account-button/embed?gcAccountId=${gcAccountId}&term=${term}&termPeriod=${termPeriod}&theme=${theme}`,
    axiosInstance: Axios.create(),
  });
};

const getEmbedExplainer = (gcAccountId: string, term: number, termPeriod: string, widgetType: WidgetType) =>
  get(`/shift/v1/explainer/embed?gcAccountId=${gcAccountId}&term=${term}&termPeriod=${termPeriod}&widgettype=${widgetType}`);

const getWidgetsAuthorizations = (gcAccountId: string) => get(`/authorization?gcAccountId=${gcAccountId}&portal=ShiftPayments`);

export default {
  getOpenAccountButtonThemes,
  getEmbedOpenAccountButton,
  getEmbedExplainer,
  getWidgetsAuthorizations,
};
