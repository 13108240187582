import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Component from './Component';
import actions from './actions';
import { selectors as initialDataSelectors } from '../../components/InitialData';
import { selectors as accountSetupCheckSelectors } from '../../components/AccountSetupCheck';

const mapStateToProps = createSelector(
  initialDataSelectors.selectSelectedGcAccountId,
  initialDataSelectors.selectFullName,
  accountSetupCheckSelectors.selectPrivacyAgreementSetup,
  (gcAccountId, contactFullName, privacyAgreementSetup) => ({
    gcAccountId,
    contactFullName,
    privacyAgreementSetup,
  })
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Component);
