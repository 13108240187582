// @flow
import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AbilityBuilder, Ability } from '@casl/ability';

export type RolePermissionMap = {
  roleName: string,
  permissionName: string,
};

export type AbilityDef = {
  can: (action: string, subject: string) => boolean,
};

export const defineAbilitiesBasedOnRoles = (roles: Array<string>, rolePermissionMap: Array<RolePermissionMap>): AbilityDef => {
  const { can, rules } = new AbilityBuilder();

  rolePermissionMap
    .filter((x) => roles.includes(x.roleName))
    .map((x) => x.permissionName)
    .forEach((x) => {
      const firstIndex = x.indexOf('.');
      const lastIndex = x.lastIndexOf('.');
      const subject = x.substring(firstIndex + 1, lastIndex);
      const action = x.substring(lastIndex + 1);
      can(action, subject);
    });

  return new Ability(rules);
};

export const AbilityContext = createContext<any>();
export const Can = createContextualCan(AbilityContext.Consumer);
