export const SESSION_STORAGE_AUTH = 'auth';
export const SESSION_STORAGE_CLAIMS = 'claims';
export const SESSION_STORAGE_STARTUP_CONFIG = 'startupConfig';
export const SESSION_STORAGE_APPLICATION_STATE = 'applicationState';
export const SESSION_STORAGE_INTERVALS_AND_TIMEOUTS = 'intervalsAndTimeouts';
export const ACTION_GLOBAL_RESET = 'GLOBAL_RESET';

export const STANDARD_DATE_FORMAT = 'D MMM YYYY';
export const STANDARD_DATE_FORMAT_WITH_DAY_OF_WEEK = 'ddd, D MMM YYYY';
export const DATE_AND_MONTH = 'D MMM';

// TO BE MOVED TO SERVER - START (Add configs that should be moved to the server to ease maintenance)
export const PAGINATION_LIMIT_DESKTOP = 25;
export const PAGINATION_LIMIT_MOBILE = 10;
export const PAGINATION_MAX_ROW_COUNT_MOBILE = 50;
export const FULL_DATA_LIMIT = 2000000; // to be revisited

export const ALLOWED_ALPHANUM_CHARACTERS_VALIDATION_MESSAGE = 'Only characters(a-z) and numbers(0-9) allowed';
export const ALLOWED_NUMERIC_CHARACTERS_VALIDATION_MESSAGE = 'Only numbers(0-9) allowed';
export const ALLOWED_LENGTH_VALIDATION_MESSAGE = (length) => `Maximum ${length} characters allowed`;

export const ATTACHMENT_SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
export const MANUAL_STATEMENT_SUPPORTED_FORMATS = ['application/pdf'];
export const ATTACHMENT_MAX_FILE_NAME_LENGTH = 80;

export const BANK_STATEMENT_TYPE = 'BankStatement';
export const MAX_FILE_SIZE_IN_MB_BANKSTATEMENTS = 10;
export const MAX_FILES_COUNT_BANKSTATEMENTS = 10;

export const DEFAULT_TERM_PERIOD = 'days';
export const LBFCID_FALLBACK = 'select';

export const ROUTES = {
  ADD_BANK: '/settings/add-bank',
  CHANGE_BANK_ACCOUNT: '/settings/add-bank/account-select',
  LOAN_AGREEMENT: '/loan-agreement',
  BUYER_DASHBOARD: '/buyer/dashboard',
  SUPPLIER: {
    DASHBOARD: '/supplier/payments', // '/supplier/dashboard'
  },
};

export const AUTH = {
  FALLBACK_TOKEN_TYPE: 'Bearer',
};

export const LINKS = {
  TERMS_AND_CONDITIONS: 'https://www.shift.com.au/terms-and-conditions/',
  PRIVACY_POLICY: 'https://www.shift.com.au/privacy-policy',
  SHIFT_HOME_PAGE: 'https://www.shift.com.au',
  SHIFT_TRADE_LEARN_MORE: 'https://www.shift.com.au/business-trade-account',
};

export const SHIFT_PHONE_NUMBER = '1300 249 649';
export const MAXIMUM_CREDIT_LIMIT = 500000;

export const MINIMUM_INVOICE_AMOUNT = 20;
// TO BE MOVED TO SERVER - END

// reusable error messages
export const DEFAULT_ERROR_MESSAGE = 'Sorry we are unable to proceed any further, please call us on 1300 249 649.';
export const INVOICE_MISSING_ATTACHMENT_ERROR_MESSAGE =
  'Something went wrong while uploading the attachment(s). Please try again or call us on 1300 249 649 for assistance.';
export const INVOICE_REQUIRE_RETRY_ERROR_MESSAGE =
  "Something went wrong while processing your request. Please retry by clicking 'Pay' or call us on 1300 249 649 for assistance.";

export const Permission = {
  Actions: {
    Create: 'Create',
    Read: 'Read',
    Review: 'Review',
    Update: 'Update',
    Sign: 'Sign',
  },
  Buyer: {
    Invoice: 'Buyer.Invoice',
    SupplierRequest: 'Buyer.SupplierRequest',
    BankAccount: 'Buyer.BankAccount',
    LimitRequest: 'Buyer.LimitRequest',
    PrivacyAgreement: 'Buyer.PrivacyAgreement',
    LoanAgreement: 'Buyer.LoanAgreement',
    DDRAgreement: 'Buyer.DDRAgreement',
    TermsAndConditionsAgreement: 'Buyer.TermsAndConditionsAgreement',
  },
  Supplier: {
    Payment: 'Supplier.Payment',
    Buyer: 'Supplier.Buyer',
  },
};

export const CREDIT_LIMIT_STATUSES = {
  AVAILABLE: 'Available',
  BY_REQUEST: 'ByRequest',
  PENDING_REQUEST: 'PendingRequest',
};

export const PORTAL_TYPE = {
  SHIFTONLINE: 'SHIFTONLINE',
};

export const ROLE_TYPE = {
  ACCOUNT_RECEIVABLE_MANAGER: 'Accounts Receivable Manager',
  ACCOUNT_PAYABLE_MANAGER: 'Accounts Payable Manager',
  DIRECTOR: 'Director',
};

export const GTM_ID = 'GTM-MP7FK5X';

export const TERM_PERIOD_TYPE = {
  DAYS: 'Days',
  EOM: 'EOM',
};

export const PROBABILITY_OF_DEFAULT_CUTOFF = 0.125;
