// @flow
import { PARTITION } from './constants';
import type { ReduxState } from './reducer';

const selectPartition = (state: any): ReduxState => state[PARTITION];
const selectPopularBanks = (state: any) => {
  return selectPartition(state).popularBanks;
};

export default {
  selectPartition,
  selectPopularBanks,
};
