/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

export default withTheme((props) => (
  <SvgIcon {...props}>
    <g id='BankLinkPadlockIconSvg' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-395.000000, -375.000000)' fill={props.theme.palette.primary.main} fillRule='nonzero'>
        <g id='Security-lock' transform='translate(395.000000, 375.000000)'>
          <path
            d='M11.9166667,6.75 L10.8333333,6.75 L10.8333333,4.5 C10.8333333,2.01471863 8.89323392,0 6.5,0 C4.10676608,0 2.16666667,2.01471863 2.16666667,4.5 L2.16666667,6.75 L1.08333333,6.75 C0.485024854,6.75 0,7.25367966 0,7.875 L0,16.875 C0,17.4963203 0.485024854,18 1.08333333,18 L11.9166667,18 C12.5149751,18 13,17.4963203 13,16.875 L13,7.875 C13,7.25367966 12.5149751,6.75 11.9166667,6.75 Z M4.33333333,4.5 C4.33333333,3.25735931 5.30338304,2.25 6.5,2.25 C7.69661696,2.25 8.66666667,3.25735931 8.66666667,4.5 L8.66666667,6.75 L4.33333333,6.75 L4.33333333,4.5 Z M10.8333333,15.75 L2.16666667,15.75 L2.16666667,9 L10.8333333,9 L10.8333333,15.75 Z'
            id='Shape'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
));
