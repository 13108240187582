// @flow
import React, { useRef } from 'react';
import { Grid, Typography, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  embeddableCode: {
    margin: theme.spacing(1, 0, 2),
    '& .MuiInputBase-multiline': {
      padding: theme.spacing(1),
    },
    '& textarea': {
      fontSize: 12,
      overflow: 'auto !important',
    },
    '& textarea::-webkit-scrollbar': {
      height: '0.5em',
      width: '0.5em',
    },
    '& textarea::-webkit-scrollbar-track': {
      '-webkit-box-shadow': `inset 0 0 6px ${theme.palette.grey['300']}`,
    },
    '& textarea::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey['300'],
    },
  },
}));

type Props = {
  embeddableCode: string,
};

const EmbedCode = (props: Props) => {
  const { embeddableCode } = props;
  const classes = useStyles();
  let elCodeTextField = useRef();

  const handleCopyCode = () => {
    // $FlowFixMe
    elCodeTextField.select();
    document.execCommand('copy');
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='subtitle2' component='label'>
          Embed Code
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body1' component='label'>
          Copy and paste this code into your website.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          rowsMax={6}
          value={embeddableCode}
          variant='outlined'
          className={classes.embeddableCode}
          fullWidth
          inputProps={{
            readOnly: true,
            wrap: 'off',
            // eslint-disable-next-line no-return-assign
            ref: (ref) => (elCodeTextField = ref),
          }}
          data-testid='uia-embeddable-code'
        />
      </Grid>
      <Grid item xs={12}>
        <Button size='large' variant='outlined' color='primary' type='button' data-testid='uia-copy-code' onClick={handleCopyCode}>
          Copy code
        </Button>
      </Grid>
    </Grid>
  );
};

export default EmbedCode;
