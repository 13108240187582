export const PARTITION = 'multiFactorAuthenticationForm';
export const FORM_NAME = 'MultiFactorAuthenticationForm';
export const ACTION_PREFIX = 'MULTI-FACTOR-AUTHENTICATION-FORM';

export const actionTypes = {
  LOGIN_BANK_ACCOUNT_USER_MFA_PENDING: 'LOGIN_BANK_ACCOUNT_USER_MFA_PENDING',
  LOGIN_BANK_ACCOUNT_USER_MFA_FULFILLED: 'LOGIN_BANK_ACCOUNT_USER_MFA_FULFILLED',
  LOGIN_BANK_ACCOUNT_USER_MFA_REJECTED: 'LOGIN_BANK_ACCOUNT_USER_MFA_REJECTED',
  SET_BANK_LOGIN_ACCOUNT_RESULT: 'SET_BANK_LOGIN_ACCOUNT_RESULT',
};
