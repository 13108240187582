/* eslint-disable react/display-name */
// @flow
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button } from '@shiftfinancial/design-system';
import moment from 'moment';
import React from 'react';

import type { OrderBy } from '../../api/shiftPaymentsApi';
import { Permission, STANDARD_DATE_FORMAT } from '../../constants';
import { Can } from '../../lib/ability';
import { enrichColumnsWithBodyRenders, getColumnIndex } from '../../lib/dataGridUtils';
import { AdapterLink } from '../../lib/materialUiUtils';
import DollarsAndCentsText from '../DollarsAndCentsText';
import RemoteDataGrid from '../RemoteDataGrid';

const useStyles = makeStyles((theme) => ({
  box: {
    margin: '2% 0',
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
    },
  },
  mobileColumnLabel: {
    marginBottom: theme.spacing(0.5),
    fontSize: 12,
  },
  mobileColumnValue: {
    fontWeight: 500,
    fontSize: 14,
  },
  mobileRowDataLine: {
    marginBottom: theme.spacing(2),
  },
  mobileRowStatusLine: {
    marginTop: theme.spacing(2),
  },
  reviewButton: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      fontSize: 14,
    },
  },
}));

const columns = [
  {
    name: 'created',
    label: 'Date',
    options: {
      sort: true,
    },
  },
  {
    name: 'invoiceNumber',
    label: 'Invoice No.',
    options: {
      sort: false,
    },
  },
  {
    name: 'supplierAccountName',
    label: 'Company Name',
    options: {
      sort: true,
    },
  },
  {
    name: 'amount',
    label: 'Invoice $',
    options: {
      sort: false,
    },
  },
  {
    name: 'review',
    label: ' ',
  },
  {
    name: 'id',
    options: {
      display: false,
    },
  },
];

const invoiceDateIndex = getColumnIndex(columns, 'created');
const invoiceNumberIndex = getColumnIndex(columns, 'invoiceNumber');
const amountIndex = getColumnIndex(columns, 'amount');
const companyNameIndex = getColumnIndex(columns, 'supplierAccountName');
const idIndex = getColumnIndex(columns, 'id');

type Props = {
  gcAccountId: string,
  isActionBlocked: boolean,
  getPendingInvoices: (gcAccountId: string, skip: number, limit: number, orderBy: ?Array<OrderBy>) => Promise<any>,
};

const PendingInvoices = ({ gcAccountId, isActionBlocked, getPendingInvoices }: Props) => {
  const classes = useStyles();

  const getInvoicesData = (skip: number, limit: number, orderBy: Array<OrderBy>) => {
    return getPendingInvoices(gcAccountId, skip, limit, orderBy);
  };

  const reviewButton = (rowData: any, fullWidth: boolean, disabled: boolean) => {
    return (
      <Can I={Permission.Actions.Create} a={Permission.Buyer.Invoice}>
        {() => (
          <Button
            className={classes.reviewButton}
            asChild
            fullWidth={fullWidth}
            appearance='secondary'
            data-testid='uia-review'
            size='small'
            disabled={disabled}
            data-disabled={disabled || undefined}
          >
            <AdapterLink
              to={{
                pathname: `/buyer/payments/${rowData[idIndex]}/review`,
                state: { title: 'Review invoice' },
              }}
            >
              Review
            </AdapterLink>
          </Button>
        )}
      </Can>
    );
  };

  const desktopColumnCustomBodyRenders = {
    created: (value) => <Typography>{moment(value).format(STANDARD_DATE_FORMAT)}</Typography>,
    invoiceNumber: (value) => <Typography>{value}</Typography>,
    supplierAccountName: (value) => <Typography>{value}</Typography>,
    amount: (value) => (
      <Typography>
        <DollarsAndCentsText amount={value} />
      </Typography>
    ),
    review: (_, row) => reviewButton(row.rowData, false, isActionBlocked),
  };

  const mobileRowRender = (rowData) => {
    const invoiceDate = moment(rowData[invoiceDateIndex]).format(STANDARD_DATE_FORMAT);

    return (
      <>
        <Grid container className={classes.box} data-testid='uia-paymentsmobileview'>
          <Grid container className={classes.box}>
            <Grid item xs={12}>
              <Typography className={classes.mobileColumnLabel}>{invoiceDate}</Typography>
              <Typography variant='h5'>{rowData[companyNameIndex]}</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.box}>
            <Grid item xs={6}>
              <Typography className={classes.mobileColumnLabel}>Invoice No.</Typography>
              <Typography className={classes.mobileColumnValue}>{rowData[invoiceNumberIndex] || '-'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.mobileColumnLabel}>Invoice $</Typography>
              <Typography className={classes.mobileColumnValue}>
                <DollarsAndCentsText amount={rowData[amountIndex]} />
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {reviewButton(rowData, true, isActionBlocked)}
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    gcAccountId && (
      <div className={classes.container}>
        <RemoteDataGrid
          getData={getInvoicesData}
          columns={enrichColumnsWithBodyRenders(columns, desktopColumnCustomBodyRenders)}
          mobileRowRender={mobileRowRender}
          data-testid='uia-dataGrid'
          defaultOrderBy={[{ columnName: 'created', isAscending: false }]}
        />
      </div>
    )
  );
};

export default PendingInvoices;
