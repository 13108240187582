// @flow
import * as React from 'react';
import { Field, initialize } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { type RouterHistory, withRouter } from 'react-router-dom';

import { FORM_NAME } from './constants';
import { renderTextField, RenderSelectField } from '../../lib/materialUiFormComponents';
import getReduxFormValidators from '../../lib/getReduxFormValidators';
import BankLinkPadlockIcon from '../svg/BankLinkPadlockIcon';
import type { BankLoginAccountOptions } from './reducer';

const styles = (theme) => {
  return {
    bankLinkFormContainer: {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1, 4),
        margin: theme.spacing(1, 0),
        border: `1px solid ${theme.palette.grey['300']}`,
      },
    },
    logInto: {
      fontWeight: 500,
    },
    field: {
      margin: theme.spacing(1, 0),
    },
    submitButton: {
      [theme.breakpoints.down('xs')]: {
        width: '50%',
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 130,
      },
      marginRight: 10,
      margin: theme.spacing(2, 0),
    },
    cancelButton: {
      [theme.breakpoints.down('xs')]: {
        width: '50%',
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 90,
      },
      margin: theme.spacing(2, 0),
    },
    textField: {
      margin: theme.spacing(0),
    },
    image: {
      margin: theme.spacing(2, 0),
      height: 37,
      width: 100,
    },
  };
};

const getField = (credential, classes, autoFocus) => {
  if (credential.type === 'select') {
    return (
      <Field
        name={credential.fieldID}
        component={RenderSelectField}
        className={`uia-${credential.fieldID}`}
        variant='outlined'
        validate={getReduxFormValidators(credential.name, credential.validation, credential.optional)}
      >
        <option value='' />
        {Object.keys(credential.values).map((option) => {
          return (
            <option key={credential.values[option]} value={option}>
              {credential.values[option]}
            </option>
          );
        })}
      </Field>
    );
  }

  return (
    <Field
      name={credential.fieldID}
      type={credential.type.toLowerCase()}
      component={renderTextField}
      className={clsx(classes.textField, `uia-${credential.fieldID}`)}
      fullWidth
      inputProps={{ margin: 'none' }}
      variant='outlined'
      validate={getReduxFormValidators(credential.name, credential.validation, credential.optional)}
      autoFocus={autoFocus}
    />
  );
};

type Props = {
  classes: any,
  bankLoginAccountResult: ?BankLoginAccountOptions,
  bankId: string,
  getLoginFields(bankId: string): Promise<any>,
  bankCredentials: ?(any[]),
  bankName: ?string,
  bankImageLink: ?string,
  dispatch: any,
  handleSubmit: Function,
  submitting: boolean,
  setBankLoginAccountResult: (value: any) => void,
  history: RouterHistory,
};

export class AddBankConnectForm extends React.Component<Props> {
  static displayName = 'AddBankConnectForm';

  constructor(props: Props) {
    super(props);
    (this: any).initializeWithDefaultValues = this.initializeWithDefaultValues.bind(this);
  }

  componentDidMount() {
    if (this.props.bankId) {
      this.props.getLoginFields(this.props.bankId);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.bankCredentials !== this.props.bankCredentials) {
      this.initializeWithDefaultValues(this.props.bankCredentials);
    }
  }

  componentWillUnmount() {
    this.props.setBankLoginAccountResult(null);
  }

  initializeWithDefaultValues(bankCredentials: ?(any[])) {
    if (bankCredentials) {
      const defaultFormValuesReduced = bankCredentials.reduce((defaultFormValues, field) => {
        if (field.defaultKey) {
          // eslint-disable-next-line no-param-reassign
          defaultFormValues[field.fieldID] = field.defaultKey;
        }
        return defaultFormValues;
      }, {});
      if (defaultFormValuesReduced) {
        this.props.dispatch(initialize(FORM_NAME, defaultFormValuesReduced));
      }
    }
  }

  goBackToBankSearch() {
    this.props.history.push('/settings/add-bank');
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {!this.props.bankLoginAccountResult && (
          <Grid container>
            <Grid item xs={12} md={8} lg={6} className='uia-AddBankConnectForm'>
              <Box className={classes.bankLinkFormContainer} borderRadius={5}>
                {this.props.bankCredentials ? <form noValidate autoComplete='off' onSubmit={this.props.handleSubmit}>
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                      <Box display='flex'>
                        <BankLinkPadlockIcon />
                        <Typography className={classes.logInto} display='inline'>
                          Log in to {this.props.bankName}
                        </Typography>
                      </Box>
                      <img src={this.props.bankImageLink} className={classes.image} alt={this.props.bankName} />
                    </Box>
                    <Grid container>
                      {this.props.bankCredentials ? this.props.bankCredentials.map((credential, fieldIndex) => {
                          return (
                            <Grid key={credential.fieldID} item xs={12} className={classes.field}>
                              <InputLabel>
                                <Typography variant='h6' component='label'>
                                  {credential.name}
                                </Typography>
                              </InputLabel>
                              {getField(credential, classes, fieldIndex === 0)}
                            </Grid>
                          );
                        }) : null}
                    </Grid>
                    <Box display='flex'>
                      <Button
                        className={classes.submitButton}
                        fullWidth
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={this.props.submitting}
                        data-testid='uia-connect-button'
                      >
                        Connect now
                      </Button>
                      <Button
                        className={classes.cancelButton}
                        fullWidth
                        color='primary'
                        type='button'
                        disabled={this.props.submitting}
                        data-testid='uia-cancel-button'
                        onClick={() => this.goBackToBankSearch()}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </form> : null}
              </Box>
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(AddBankConnectForm));
