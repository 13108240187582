// @flow
import { PARTITION } from './constants';
import type { ReduxState } from './reducer';

const selectPartition = (state: any): ReduxState => state[PARTITION];

const selectTotalBalanceRemaining = (state: any) => selectPartition(state).totalBalanceRemaining;
const selectAvailableBalance = (state: any) => selectPartition(state).availableBalance;
const selectNextScheduledPaymentAmount = (state: any) => selectPartition(state).nextScheduledPaymentAmount;
const selectNextScheduledPaymentDate = (state: any) => selectPartition(state).nextScheduledPaymentDate;
const selectIsPayBalanceCaseOpen = (state: any) => selectPartition(state).isPayBalanceCaseOpen;
const selectBankAccountSetup = (state: any) => selectPartition(state).bankAccountSetup;
const selectCreditLimit = (state: any) => selectPartition(state).creditLimit;

export default {
  selectPartition,
  selectTotalBalanceRemaining,
  selectAvailableBalance,
  selectNextScheduledPaymentAmount,
  selectNextScheduledPaymentDate,
  selectIsPayBalanceCaseOpen,
  selectBankAccountSetup,
  selectCreditLimit,
};
