/* eslint-disable flowtype/no-types-missing-file-annotation */
import type { Dispatch } from 'redux';
import { createActions } from 'redux-actions';

import { ACTION_PREFIX, actionTypes } from './constants';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';
import { getActionOptions } from '../../lib/reduxActionsUtils';

export const { getBalanceSummaryPending, getBalanceSummaryFulfilled, getBalanceSummaryRejected, setIsPayBalanceCaseOpen } = createActions(
  actionTypes.GET_BALANCE_SUMMARY_PENDING,
  actionTypes.GET_BALANCE_SUMMARY_FULFILLED,
  actionTypes.GET_BALANCE_SUMMARY_REJECTED,
  actionTypes.SET_IS_PAY_BALANCE_CASE_OPEN,
  getActionOptions(ACTION_PREFIX)
);

const getBalanceSummary = (gcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(getBalanceSummaryPending());

  return shiftPaymentsApi
    .getBalanceSummary(gcAccountId)
    .then((response) => {
      return dispatch(getBalanceSummaryFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getBalanceSummaryRejected(error));
    });
};

const payBalance = (balance, bankAccountId, gcAccountId) => (dispatch: Dispatch<any>) => {
  return shiftPaymentsApi
    .payBalance(balance, bankAccountId, gcAccountId)
    .then((response) => {
      dispatch(setIsPayBalanceCaseOpen(response));
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export default {
  getBalanceSummary,
  payBalance,
};
