/* eslint-disable react/display-name */
// @flow
import { useAbility } from '@casl/react';
import { Grid } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React from 'react';

import type { RouterHistory } from 'react-router-dom';

import type { OrderBy } from '../../../api/shiftPaymentsApi';
import DataGridPageLayout from '../../../components/DataGridPageLayout';
import DollarsAndCentsText from '../../../components/DollarsAndCentsText';
import StatusLabel from '../../../components/StatusLabel';
import { Permission, STANDARD_DATE_FORMAT } from '../../../constants';
import { AbilityContext } from '../../../lib/ability';
import { enrichColumnsWithBodyRenders, getColumnIndex } from '../../../lib/dataGridUtils';
import styles from '../../../theme';
import { isLargeMobileResolution } from '../../../lib/materialUiUtils';

const useStyles = makeStyles((theme) => ({
  box: {
    margin: '2% 0',
  },
  mobileColumnLabel: {
    marginBottom: theme.spacing(0.5),
    fontSize: 11,
  },
  mobileColumnValue: {
    fontWeight: 500,
  },
  mobileRowDataLine: {
    marginBottom: theme.spacing(2),
  },
  mobileRowStatusLine: {
    marginTop: theme.spacing(2),
  },
}));

const getColor = (v) => {
  switch (v) {
    case 'Paid':
      return styles.palette.success.light;
    case 'Declined':
      return styles.palette.error.light;
    default:
      return styles.palette.warning.light;
  }
};

const columns = [
  {
    name: 'orderId',
    label: 'Order ID',
    options: {
      sort: false,
    },
  },
  {
    name: 'buyerAccountName',
    label: 'Business',
    options: {
      sort: false,
    },
  },
  {
    name: 'loanAmount',
    label: 'Invoice $',
    options: {
      sort: true,
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      sort: true,
    },
  },
  {
    name: 'createdDate',
    label: 'Invoice Date',
    options: {
      sort: true,
    },
  },
  {
    name: 'invoiceNumber',
    label: 'Invoice No.',
    options: {
      sort: false,
    },
  },
  {
    name: 'gcDisbursalFees',
    label: 'Merchant Service Fee',
    options: {
      sort: false,
    },
  },
  {
    name: 'disbursedAmount',
    label: 'Disbursed Amount',
    options: {
      sort: false,
    },
  },
  {
    name: 'disbursalDate',
    label: 'Disbursal Date',
    options: {
      sort: true,
    },
  },
  {
    name: 'disbursalRefNo',
    label: 'Disbursal Ref No.',
    options: {
      sort: false,
    },
  },
];

const statusIndex = getColumnIndex(columns, 'status');
const disbursalDateIndex = getColumnIndex(columns, 'disbursalDate');
const disbursalRefNoIndex = getColumnIndex(columns, 'disbursalRefNo');
const invoiceNumberIndex = getColumnIndex(columns, 'invoiceNumber');
const gcDisbursalFeeIndex = getColumnIndex(columns, 'gcDisbursalFees');
const disbursedAmountIndex = getColumnIndex(columns, 'disbursedAmount');

type Props = {
  activeAccount: any,
  gcAccountId: string,
  getPayments: (gcAccountId: string, skip: number, limit: number, search: ?string, from: ?Date, to: ?Date, orderBy: ?Array<OrderBy>) => Promise<any>,
  setIsAllKycCompletedWithSessionStorage: (isAllKycCompleted: boolean) => void,
  history: RouterHistory,
};

const Payments = (props: Props) => {
  const { gcAccountId, getPayments, setIsAllKycCompletedWithSessionStorage, history, width } = props;
  const classes = useStyles();
  const ability = useAbility(AbilityContext);
  const isMobile = isLargeMobileResolution(width);

  setIsAllKycCompletedWithSessionStorage(true);

  const getPaymentsData = (skip: number, limit: number, orderBy: ?Array<OrderBy>, search: ?string) => {
    const from = moment().subtract(12, 'months').toDate();
    const to = moment().toDate();

    return getPayments(gcAccountId, skip, limit, search, from, to, orderBy);
  };

  const desktopColumnCustomBodyRenders = {
    orderId: (value) => <Typography>{value}</Typography>,
    buyerContactName: (value) => <Typography>{value}</Typography>,
    buyerAccountName: (value) => <Typography>{value}</Typography>,
    loanAmount: (value) => (
      <Typography>
        <DollarsAndCentsText amount={value} />
      </Typography>
    ),
    status: (value) => <StatusLabel text={value} color={getColor(value)} />,
    createdDate: (value) => {
      if (value) {
        return <Typography>{moment(value).format(STANDARD_DATE_FORMAT)}</Typography>;
      }
      return null;
    },
    gcDisbursalFees: (value) => {
      if (value) {
        return (
          <Typography>
            <DollarsAndCentsText amount={value} />
          </Typography>
        );
      }
      return <Typography>-</Typography>;
    },
    disbursedAmount: (value) => (
      <Typography>
        <DollarsAndCentsText amount={value} />
      </Typography>
    ),
    disbursalDate: (value) => {
      if (value) {
        return <Typography>{moment(value).format(STANDARD_DATE_FORMAT)}</Typography>;
      }
      return null;
    },
    disbursalRefNo: (value) => <Typography>{value}</Typography>,
  };

  const mobileRowRender = (rowData) => {
    const disbursalDate = rowData[disbursalDateIndex] ? moment(rowData[disbursalDateIndex]).format(STANDARD_DATE_FORMAT) : null;
    const status = rowData[statusIndex];
    return (
      <>
        <Grid container className={classes.box} data-testid='uia-paymentsmobileview'>
          <Grid container className={classes.box}>
            <Grid item xs={4}>
              <Typography className={classes.mobileColumnLabel}>Invoice Number</Typography>
              <Typography className={classes.mobileColumnValue}>{rowData[invoiceNumberIndex]}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.mobileColumnLabel}>Amount</Typography>
              <Typography className={classes.mobileColumnValue}>
                <DollarsAndCentsText amount={rowData[disbursedAmountIndex]} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.box}>
            <Grid item xs={4}>
              <Typography className={classes.mobileColumnLabel}>Disbursal Date</Typography>
              <Typography className={classes.mobileColumnValue}>{disbursalDate}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.mobileColumnLabel}>Disbursal Ref No.</Typography>
              <Typography className={classes.mobileColumnValue}>{rowData[disbursalRefNoIndex]}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.mobileColumnLabel}>GC Fees</Typography>
              <Typography className={classes.mobileColumnValue}>
                <DollarsAndCentsText amount={rowData[gcDisbursalFeeIndex]} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.box}>
            <Grid item xs={4}>
              <Typography className={classes.mobileColumnLabel}>Status</Typography>
              <StatusLabel text={status} color={getColor(status)} />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const handleNewPayment = () => {
    history.push('/supplier/payments/add');
  };

  return (
    <DataGridPageLayout
      title='Payments'
      getData={getPaymentsData}
      columns={enrichColumnsWithBodyRenders(columns, desktopColumnCustomBodyRenders)}
      mobileRowRender={mobileRowRender}
      defaultOrderBy={[{ columnName: 'createdDate', isAscending: false }]}
      addButtonOptions={
        ability.can(Permission.Actions.Create, Permission.Supplier.Payment)
          ? {
              label: '+ New payment',
              onAddClick: handleNewPayment,
              disabled: false,
            }
          : null
      }
      options={{ search: isMobile ? false : true }}
    />
  );
};

export default withWidth()(Payments);
