// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';

import type { OrderBy } from '../../../api/shiftPaymentsApi';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';

export const {
  getCustomersPending,
  getCustomersFulfilled,
  getCustomersRejected,
  cancelSupplierRequestPending,
  cancelSupplierRequestFulfilled,
  cancelSupplierRequestRejected,
  updateDefaultTermsPending,
  updateDefaultTermsFulfilled,
  updateDefaultTermsRejected,
  deleteSupplierBuyerPending,
  deleteSupplierBuyerFulfilled,
  deleteSupplierBuyerRejected,
} = createActions(
  actionTypes.GET_CUSTOMERS_PENDING,
  actionTypes.GET_CUSTOMERS_FULFILLED,
  actionTypes.GET_CUSTOMERS_REJECTED,
  actionTypes.CANCEL_SUPPLIER_REQUEST_PENDING,
  actionTypes.CANCEL_SUPPLIER_REQUEST_FULFILLED,
  actionTypes.CANCEL_SUPPLIER_REQUEST_REJECTED,
  actionTypes.UPDATE_DEFAULT_TERMS_PENDING,
  actionTypes.UPDATE_DEFAULT_TERMS_FULFILLED,
  actionTypes.UPDATE_DEFAULT_TERMS_REJECTED,
  actionTypes.DELETE_SUPPLIER_BUYER_PENDING,
  actionTypes.DELETE_SUPPLIER_BUYER_FULFILLED,
  actionTypes.DELETE_SUPPLIER_BUYER_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getCustomers = (gcAccountId: string, search: string, skip: number, limit: number, orderBy: Array<OrderBy>) => (dispatch: Dispatch<any>) => {
  dispatch(getCustomersPending());

  return shiftPaymentsApi
    .getCustomers(gcAccountId, search, skip, limit, orderBy)
    .then((response) => {
      return dispatch(getCustomersFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getCustomersRejected(error));
    });
};

const cancelRequest = (supplierGcAccountId: string, buyerGcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(cancelSupplierRequestPending());

  return shiftPaymentsApi
    .cancelSupplierRequest(supplierGcAccountId, buyerGcAccountId)
    .then((response) => {
      return dispatch(cancelSupplierRequestFulfilled(response));
    })
    .catch((error) => {
      return dispatch(cancelSupplierRequestRejected(error));
    });
};

const updateDefaultTerms =
  (supplierGcAccountId: string, buyerGcAccountId: string, term: number, termsPeriod: string) => (dispatch: Dispatch<any>) => {
    dispatch(updateDefaultTermsPending());
    return shiftPaymentsApi
      .updateDefaultTerms(supplierGcAccountId, buyerGcAccountId, term, termsPeriod)
      .then((response) => {
        return dispatch(updateDefaultTermsFulfilled(response));
      })
      .catch((error) => {
        return dispatch(updateDefaultTermsRejected(error));
      });
  };

const deleteSupplierBuyer = (supplierGcAccountId: string, buyerGcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(deleteSupplierBuyerPending());
  return shiftPaymentsApi
    .deleteSupplierBuyer(supplierGcAccountId, buyerGcAccountId)
    .then((response) => {
      return dispatch(deleteSupplierBuyerFulfilled(response));
    })
    .catch((error) => {
      return dispatch(deleteSupplierBuyerRejected(error));
    });
};

export default {
  getCustomers,
  cancelRequest,
  updateDefaultTerms,
  deleteSupplierBuyer,
};
