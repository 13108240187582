// @flow
import React from 'react';
import type { FormikState, FormikHelpers } from 'formik';

import ButtonSelect, { type Props as ButtonSelectProps } from '../ButtonSelect';
import FormikField from './FormikField';
import type { Field } from './types';

type Props = ButtonSelectProps & {
  field: Field,
  form: FormikState<any> & FormikHelpers<any>,
  onSelectionChange: (value: any) => void,
};

const FormikButtonSelectField = ({ field, form, onSelectionChange, ...other }: Props) => (
  <FormikField field={field} form={form}>
    <ButtonSelect
      onSelectionChange={(value) => {
        form.setFieldValue(field.name, value.id, true);
        if (onSelectionChange) {
          onSelectionChange(value);
        }
      }}
      // $FlowFixMe
      {...other}
    />
  </FormikField>
);

export default FormikButtonSelectField;
