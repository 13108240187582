import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import Component from './Component';
import initialDataSelectors from '../../../components/InitialData/selectors';
import accountReadinessSelectors from '../../../components/AccountReadinessAlerts/selectors';

const mapStateToProps = createSelector(
  initialDataSelectors.selectSelectedAccountIsDelinquent,
  accountReadinessSelectors.selectIsAccountReady,
  initialDataSelectors.selectSelectedGcAccountId,
  (activeAccountIsDelinquent, isAccountReady, gcAccountId) => ({
    isActionBlocked: activeAccountIsDelinquent || !isAccountReady,
    gcAccountId,
  })
);

export default connect(mapStateToProps)(Component);
