// @flow
import { useState } from 'react';
import SetupPageLayout from '../../components/SetupPageLayout';
import { FormikDndAttachFiles } from '../../components/formik';
import { formSchema } from './validations';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Form, withFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import initialDataSelectors from '../../components/InitialData/selectors';
import type { FormikErrors } from 'formik';
import { shiftPaymentsApi } from '../../api';
import { handleError } from '../../lib/apiHelpers';
import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Notification, Button, FormField } from '@shiftfinancial/design-system';
import {
  MAX_FILES_COUNT_BANKSTATEMENTS,
  MAX_FILE_SIZE_IN_MB_BANKSTATEMENTS,
  INVOICE_MISSING_ATTACHMENT_ERROR_MESSAGE,
  BANK_STATEMENT_TYPE,
} from '../../constants';

const useStyles = makeStyles((theme) => ({
  bankLinkBanner: {
    marginBottom: theme.spacing(2),
  },
  notificationAction: {
    padding: theme.spacing(1.2, 2),
  },
  cancelButton: {
    float: 'right',
  },
  actionContainer: {
    padding: theme.spacing(2, 5),
    backgroundColor: theme.palette.grey[200],
    borderBottom: `1px solid ${theme.palette.secondary.solitude}`,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 3),
    },
  },
  divider: {
    backgroundColor: theme.palette.secondary.solitude,
  },
}));

type FormValues = {
  statements: Array<any>,
};

type ManualBankStatementProps = {
  isSubmitting: boolean,
  values: FormValues,
  errors: FormikErrors<FormValues>,
  touched: any,
  validateForm: any,
};

const ManualBankStatement = (props: ManualBankStatementProps) => {
  const { values, errors, touched, validateForm } = props;

  const classes = useStyles();
  const history = useHistory();
  const [documentId, setDocumentId] = useState();
  const activeAccount = useSelector((state) => initialDataSelectors.selectActiveAccount(state));

  const { enqueueSnackbar } = useSnackbar();

  const onUpload = async (gcAccountId: string, fileGroupId: string, file: any) => {
    try {
      let createDocumentId = documentId;
      if (!documentId) {
        var response = await shiftPaymentsApi.createDocument(activeAccount.gcAccountId, {
          documentType: BANK_STATEMENT_TYPE,
          SecondaryRelations: [],
        });
        createDocumentId = response?.data?.documentId;
        setDocumentId(response?.data?.documentId);
      }

      return await shiftPaymentsApi.uploadDocumentFile(activeAccount.gcAccountId, createDocumentId, file);
    } catch (error) {
      handleError(error);
    }
  };

  const onDeleteDocumentFile = async (gcAccountId: string, documentFileId: string, file: any) => {
    try {
      if (file && file.documentFileId) {
        return await shiftPaymentsApi.deleteDocumentFile(activeAccount.gcAccountId, file.documentFileId);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const onDeleteDocumentFiles = async (files: Array<any>) => {
    if (files && files.length) {
      const promises = files.map((file) => {
        if (file.documentFileId) {
          return shiftPaymentsApi.deleteDocumentFile(activeAccount.gcAccountId, file.documentFileId);
        }
      });

      return Promise.all(promises)
        .then((response) => response)
        .catch((error) => handleError(error));
    }
  };

  const onCancel = () => {
    onDeleteDocumentFiles(values.statements);
    history.push('/settings/add-bank');
  };

  const onSubmit = () => {
    history.push('/buyer/dashboard');
  };

  const handleSubmit = () => {
    validateForm()
      .then((errors: FormikErrors<FormValues>) => {
        if (Object.entries(errors).length === 0) {
          enqueueSnackbar('We’ve got your statements. We will review them and let you know if we need anything else.', {
            variant: 'success',
            key: 'manualBankStatementsSuccess',
            persist: true,
          });
          onSubmit();
        }
      })
      .catch(() => {
        enqueueSnackbar(INVOICE_MISSING_ATTACHMENT_ERROR_MESSAGE, {
          variant: 'error',
          key: 'manualBankStatementsFailed',
        });
      });
  };

  return (
    <Form noValidate autoComplete='off'>
      <SetupPageLayout
        title='Upload bank statements'
        footer={
          <>
            <Divider className={classes.divider} />
            <Grid item className={classes.actionContainer}>
              <Button appearance='primary' type='submit' data-testid='uia-submit-manual-statements' onClick={() => handleSubmit()}>
                Submit
              </Button>
              <Button appearance='neutral' onClick={() => onCancel()} className={classes.cancelButton} data-testid='uia-cancel-manual-statements'>
                Cancel
              </Button>
            </Grid>
          </>
        }
      >
        <Grid className={classes.bankLinkBanner}>
          <Notification
            appearance='warning'
            variant='alert'
            title='Bank connection unavailable'
            children={
              <Grid>
                <Grid>We couldn’t connect to your bank.</Grid>
                <Grid>
                  Please provide us with bank statements for the last <strong>6 months</strong>.
                </Grid>
              </Grid>
            }
            data-testid='uia-manual-statements-notification'
          />
        </Grid>
        <Grid item>
          <FormField fieldId='statements'>
            {({ fieldId }) => (
              <FormikDndAttachFiles
                name={fieldId}
                values={values}
                errors={errors}
                touched={touched}
                gcAccountId={activeAccount.gcAccountId}
                fileGroupId={documentId}
                maxFilesCount={MAX_FILES_COUNT_BANKSTATEMENTS}
                maxFileSizeInMb={MAX_FILE_SIZE_IN_MB_BANKSTATEMENTS}
                onFileUpload={onUpload}
                onFileDelete={onDeleteDocumentFile}
                supportedFileTypesText='Upload files in PDF format.'
              />
            )}
          </FormField>
        </Grid>
      </SetupPageLayout>
    </Form>
  );
};

export default withRouter<WrapperProps>(
  withSnackbar(
    withFormik({
      mapPropsToValues: (): FormValues => ({
        statements: [],
      }),
      validateOnBlur: false,
      validationSchema: formSchema,
      displayName: 'ManualBankStatementUpload',
    })(ManualBankStatement)
  )
);
