export const ACTION_PREFIX = 'BUYER_DASHBOARD';
export const PARTITION = 'buyerdashboard';

export const actionTypes = {
  GET_CONTACT_INVITATIONS_PENDING: 'GET_CONTACT_INVITATIONS_PENDING',
  GET_CONTACT_INVITATIONS_FULFILLED: 'GET_CONTACT_INVITATIONS_FULFILLED',
  GET_CONTACT_INVITATIONS_REJECTED: 'GET_CONTACT_INVITATIONS_REJECTED',
  GET_FACILITY_LIMITS_PENDING: 'GET_FACILITY_LIMITS_PENDING',
  GET_FACILITY_LIMITS_FULFILLED: 'GET_FACILITY_LIMITS_FULFILLED',
  GET_FACILITY_LIMITS_REJECTED: 'GET_FACILITY_LIMITS_REJECTED',
  GET_ARREARS_PAYMENT_DETAILS_FULFILLED: 'GET_ARREARS_PAYMENT_DETAILS_FULFILLED',
  GET_ARREARS_PAYMENT_DETAILS_REJECTED: 'GET_ARREARS_PAYMENT_DETAILS_REJECTED',
  SEND_CONTACT_INVITE_PENDING: 'SEND_CONTACT_INVITE_PENDING',
  SEND_CONTACT_INVITE_FULFILLED: 'SEND_CONTACT_INVITE_FULFILLED',
  SEND_CONTACT_INVITE_REJECTED: 'SEND_CONTACT_INVITE_REJECTED',
  REFRESH_TOKEN_PENDING: 'REFRESH_TOKEN_PENDING',
  REFRESH_TOKEN_FULFILLED: 'REFRESH_TOKEN_FULFILLED',
  REFRESH_TOKEN_REJECTED: 'REFRESH_TOKEN_REJECTED',
};

export const messages = {
  invitationSent: (sentTo) => `Invitation successfully sent to ${sentTo}. To re-invite try after 48 hours.`,
};
