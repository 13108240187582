// @flow
import { PARTITION } from './constants';
import { type ReduxState } from './reducer';
import { type RolePermissionMap } from '../../lib/ability';

const selectPartition = (state: any): ReduxState => state[PARTITION];
const selectRolePermissionMap = (state: any): Array<RolePermissionMap> => selectPartition(state).rolePermissionMap;
const hasPermissionToSignPrivacyAgreement = (state: any): boolean => selectPartition(state).hasPermissionToSignPrivacyAgreement;
const hasPermissionToSignLoanAgreement = (state: any): boolean => selectPartition(state).hasPermissionToSignLoanAgreement;
const hasPermissionToSignDDRAgreement = (state: any): boolean => selectPartition(state).hasPermissionToSignDDRAgreement;

export default {
  selectPartition,
  selectRolePermissionMap,
  hasPermissionToSignPrivacyAgreement,
  hasPermissionToSignLoanAgreement,
  hasPermissionToSignDDRAgreement,
};
