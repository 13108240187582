import * as Yup from 'yup';

const pinRegExp = /^[0-9]{6}$/;

export const formSchema = Yup.object().shape({
  pin: Yup.string()
    .required('Please enter a verification code')
    .length(6, 'Verification code must be 6 digits')
    .matches(pinRegExp, 'Please enter a valid verification code'),
});
