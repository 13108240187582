// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  theme: any,
};

const DownloadIcon = withTheme((props: Props) => (
  <SvgIcon viewBox='0 0 12 16' {...(props: any)}>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-931.000000, -437.000000)' fill={props.theme.palette.primary.main}>
        <path d='M940.9,451 C941.5083,451 942,451.4477 942,452 C942,452.5523 941.5083,453 940.9,453 L940.9,453 L932.1,453 C931.4928,453 931,452.5523 931,452 C931,451.4477 931.4928,451 932.1,451 L932.1,451 Z M936.538,437 C937.09,437 937.538,437.448 937.538,438 L937.538,438 L937.538,446.254 L940.169,443.751 C940.605,443.336 941.312,443.336 941.748,443.751 C942.184,444.166 942.184,444.838 941.748,445.253 L941.748,445.253 L936.537,450 L931.327,445.253 C930.891,444.838 930.891,444.166 931.327,443.751 C931.763,443.336 932.47,443.336 932.906,443.751 L932.906,443.751 L935.538,446.254 L935.538,438 C935.538,437.448 935.985,437 936.538,437 Z' />
      </g>
    </g>
  </SvgIcon>
));

export default DownloadIcon;
