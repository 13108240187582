import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { bindActionCreators } from 'redux';

import Component from './Component';
import actions from './actions';
import selectors from './selectors';
import initialDataSelectors from '../InitialData/selectors';

const mapStateToProps = createSelector(
  selectors.selectSelectedAccountsResponse,
  selectors.selectSelectedAccountsError,
  initialDataSelectors.selectSelectedGcAccountId,
  (selectedAccountsResponse, selectedAccountsError, gcAccountId) => ({
    selectedAccountsResponse,
    selectedAccountsError,
    gcAccountId,
  })
);
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(actions, dispatch),
    dispatch,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Component);
