export const ACTION_PREFIX = 'SETTINGS';
export const PARTITION = 'settings';

export const actionTypes = {
  GET_SETTINGS_PENDING: 'GET_SETTINGS_PENDING',
  GET_SETTINGS_FULFILLED: 'GET_SETTINGS_FULFILLED',
  GET_SETTINGS_REJECTED: 'GET_SETTINGS_REJECTED',
  SET_REPAYMENT_DAY_PENDING: 'SET_REPAYMENT_DAY_PENDING',
  SET_REPAYMENT_DAY_FULFILLED: 'SET_REPAYMENT_DAY_FULFILLED',
  SET_REPAYMENT_DAY_REJECTED: 'SET_REPAYMENT_DAY_REJECTED',
  SET_SMS_REMINDER_PENDING: 'SET_SMS_REMINDER_PENDING',
  SET_SMS_REMINDER_FULFILLED: 'SET_SMS_REMINDER_FULFILLED',
  SET_SMS_REMINDER_REJECTED: 'SET_SMS_REMINDER_REJECTED',
  REQUEST_ACCOUNT_LIMIT_INCREASE_PENDING: 'REQUEST_ACCOUNT_LIMIT_INCREASE_PENDING',
  REQUEST_ACCOUNT_LIMIT_INCREASE_FULFILLED: 'REQUEST_ACCOUNT_LIMIT_INCREASE_FULFILLED',
  REQUEST_ACCOUNT_LIMIT_INCREASE_REJECTED: 'REQUEST_ACCOUNT_LIMIT_INCREASE_REJECTED',
  GET_CREDIT_LIMIT_PENDING_REQUEST_PENDING: 'GET_CREDIT_LIMIT_PENDING_REQUEST_PENDING',
  GET_CREDIT_LIMIT_PENDING_REQUEST_FULFILLED: 'GET_CREDIT_LIMIT_PENDING_REQUEST_FULFILLED',
  GET_CREDIT_LIMIT_PENDING_REQUEST_REJECTED: 'GET_CREDIT_LIMIT_PENDING_REQUEST_REJECTED',
  SET_HAS_NEW_CREDIT_LIMIT_PENDING_REQUEST: 'SET_HAS_NEW_CREDIT_LIMIT_PENDING_REQUEST',
};

export const REQUEST_NEW_CREDIT_LIMIT_STATUSES = {
  COMPLETED: 'Completed',
  PENDING: 'Pending',
};
