import * as Yup from 'yup';

const phoneRegExp = /^4[0-9]{8}|^04[0-9]{8}/;
const blankSpaceRegExp = /^(.*)?\S+(.*)?$/;
export const formSchema = () => {
  return Yup.object().shape({
    staffs: Yup.array()
      .of(
        Yup.object().shape({
          firstName: Yup.string().required('Please enter First name').matches(blankSpaceRegExp, 'Please enter a valid First name'),
          lastName: Yup.string().required('Please enter Last name').matches(blankSpaceRegExp, 'Please enter a valid Last name'),
          mobileNumber: Yup.string().required('Please enter a mobile number').matches(phoneRegExp, 'Please enter a valid mobile number'),
        })
      )
      .uniqueMobile('Mobile number already added above'),
  });
};

function uniqueMobile(errorMsg) {
  return this.test('uniqueMobile', errorMsg, function testItem(list) {
    const { path } = this;
    if (list.length < 2) {
      return true;
    }
    const mobileNumberSet = [...new Set(list.map((x) => x.mobileNumber))];
    const idx = list.findIndex((l, i) => l.mobileNumber !== mobileNumberSet[i]);
    if (idx === -1) {
      return true;
    }
    throw this.createError({ path: `${path}.[${idx}].mobileNumber`, message: errorMsg });
  });
}
Yup.addMethod(Yup.array, 'uniqueMobile', uniqueMobile);
