import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Component from './Component';
import actions from './actions';
import { actions as settingsPageActions } from '../../pages/Settings';
import { selectors as initialDataSelectors } from '../../components/InitialData';
import { selectors as accountSetupCheckSelectors } from '../../components/AccountSetupCheck';

const mapStateToProps = createSelector(
  initialDataSelectors.selectSelectedGcAccountId,
  accountSetupCheckSelectors.selectDirectDebitAgreementSetup,
  accountSetupCheckSelectors.selectBankAccountSetup,
  accountSetupCheckSelectors.selectIsAccountSetupLoaded,
  (gcAccountId, agreementSetup, bankAccountSetup, isAccountSetupLoaded) => ({
    gcAccountId,
    agreementSetup,
    bankAccountSetup,
    isAccountSetupLoaded,
  })
);

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ ...actions, ...settingsPageActions }, dispatch),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
