import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import StartupConfig from './Component';
import actions from './actions';
import selectors from './selectors';

const mapStateToProps = createSelector(selectors.selectConfiguration, selectors.selectIsConfigurationSet, (configuration, isConfigurationSet) => ({
  configuration,
  isConfigurationSet,
}));

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StartupConfig);
