// @flow
import { useAbility } from '@casl/react';
import { Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import React, { type Node, useEffect, useState } from 'react';
import type { RouterHistory } from 'react-router-dom';
import { Notification, NotificationAction } from '@shiftfinancial/design-system';

import { messages } from './constants';
import { type ContactInvitation, ContactInvitationStatusValues, type FacilityLimits, type ShiftInvitee } from './types';
import { AccountLimitBanner } from '../../../components/AccountLimitBanner';
import { ArrearsPaymentPendingBanner } from '../../../components/ArrearsPaymentPendingBanner';
import { ArrearsPaymentCollectionBanner } from '../../../components/ArrearsPaymentCollectionBanner';
import { AccountReadinessAlerts } from '../../../components/AccountReadinessAlerts';
import AlertError from '../../../components/AlertError';
import { AssociateWithReferer } from '../../../components/AssociateWithReferer';
import { BalanceSummary } from '../../../components/BalanceSummary';
import { BankLinkDialog, useGetAnalyticsAccountSummary } from '../../../components/BankLinkDialog/';
import BasicPageLayout from '../../../components/BasicPageLayout';
// eslint-disable-next-line import/no-named-as-default
import InviteeList from '../../../components/InviteeList';
import JoinBrokerNetwork from '../../../components/JoinBrokerNetwork';
import PageItem from '../../../components/PageItem';
import { PendingInvoices } from '../../../components/PendingInvoices';
import { SupplierRequests } from '../../../components/SupplierRequests';
import TabLabel from '../../../components/TabLabel';
import TabPanel from '../../../components/TabPanel';
import { Permission, PROBABILITY_OF_DEFAULT_CUTOFF } from '../../../constants';
import { AbilityContext } from '../../../lib/ability';
import { defaultHandleError } from '../../../lib/apiHelpers';
import { type Account, type ReferralAccount, SpStatus } from '../../../types';
import { BusinessOverDraft } from '../BusinessOverDraft';
import ExtendableInvoices from '../../../components/ExtendableInvoices/Component';

const useStyles = makeStyles((theme) => ({
  left: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(2),
    },
  },
  right: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 !important',
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0),
    },
  },
  tab: {
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
  balanceSummaryContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  errorAlert: {
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('xs')]: {
      margin: '0 8px 12px 8px',
    },
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      margin: '0 !important',
      width: '100% !important',
    },
  },
  invitationList: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
      width: 'inherit',
    },
  },
  bankLinkBanner: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  notificationAction: {
    padding: theme.spacing(1.2, 2),
  },
}));

const convertInvitation = (unformattedInvitation: ContactInvitation, index: number): ShiftInvitee => {
  return {
    index,
    name: unformattedInvitation.name,
    mobileNumber: unformattedInvitation.mobileNumber,
    gcContactId: unformattedInvitation.gcContactId,
    status: unformattedInvitation.status,
    spAccess: unformattedInvitation.spAccess,
  };
};

const convertInvitations = (unformattedInvitations: Array<ContactInvitation>): ?Array<ShiftInvitee> => {
  if (!Array.isArray(unformattedInvitations)) {
    return null;
  }
  const invitations = unformattedInvitations.map((unformattedInvitation, index) => convertInvitation(unformattedInvitation, index));
  return invitations;
};

const getIsAllKycCompleted = (activeAccount: Account, invitations: Array<ShiftInvitee>) => {
  if (!activeAccount || !invitations) {
    return null;
  }

  if (activeAccount.spStatus.toLowerCase() === SpStatus.Enable || activeAccount.spStatus.toLowerCase() === SpStatus.Delinquent) {
    return invitations.every((invitation) => invitation.status === ContactInvitationStatusValues.Verified);
  }
  return null;
};

type Props = {
  activeAccount: any,
  gcAccountId: string,
  firstName: string,
  isAllKycCompleted: boolean,
  isAllSetupCompleteRequirement: boolean,
  totalPendingInvoices: number,
  totalSupplierRequests: number,
  activeAccountIsDelinquent: boolean,
  isAccountReady: boolean,
  facilityLimits: FacilityLimits,
  isLoadingFacilityLimits: boolean,
  history: RouterHistory,
  enqueueSnackbar: (snack: string, options?: Object) => void,
  setIsAllKycCompletedWithSessionStorage: (isAllKycCompleted: boolean) => void,
  getContactInvitations: (gcAccountId: string) => Promise<any>,
  sendContactInvite: (gcAccountId: string, recipientGCContactId: string, mobileNumber: string) => Promise<any>,
  getSupplierRequests: (gcAccountId: string, skip: number, limit: number) => Promise<any>,
  getPendingInvoices: (gcAccountId: string, skip: number, limit: number) => Promise<any>,
  getFacilityLimits: (gcAccountId: string) => Promise<any>,
  getArrearsPaymentDetails: (gcAccountId: string) => Promise<any>,
  refreshToken: () => Promise<any>,
  doRefreshToken: boolean,
  referralAccount?: ReferralAccount,
};

const Dashboard = (props: Props) => {
  const {
    activeAccount,
    gcAccountId,
    firstName,
    isAllKycCompleted,
    isAllSetupCompleteRequirement,
    totalPendingInvoices = 0,
    totalSupplierRequests = 0,
    history,
    enqueueSnackbar,
    setIsAllKycCompletedWithSessionStorage,
    getContactInvitations,
    sendContactInvite,
    getSupplierRequests,
    getPendingInvoices,
    getFacilityLimits,
    getArrearsPaymentDetails,
    activeAccountIsDelinquent,
    isAccountReady,
    facilityLimits,
    isLoadingFacilityLimits,
    refreshToken,
    doRefreshToken,
    referralAccount,
  } = props;

  const [tokenRefreshed, setTokenRefreshed] = useState(!doRefreshToken);
  const [unformattedInvitations, setUnformattedInvitations] = useState<?Array<ContactInvitation>>(null);

  const classes = useStyles();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [refreshArrearsBanners, setRefreshArrearsBanners] = useState(false);
  const [arrearsDetails, setArrearsDetails] = useState({ isPendingLPTs: false });
  const ability = useAbility(AbilityContext);
  const invitations = unformattedInvitations ? convertInvitations(unformattedInvitations) : null;
  const [bankLinkEnforcementStatusDialog, setBankLinkEnforcementStatusDialog] = useState(false);
  const analyticsAccountSummary = useGetAnalyticsAccountSummary(gcAccountId);

  const shouldShowDelinquencyBanner = () => {
    // if totoal amount to current is greater than 0 either of arrears collection banners shown in desktop
    // and no need to show the delinquency banner
    if (arrearsDetails?.totalAmountToCurrent > 0) {
      return false;
    } else if (activeAccountIsDelinquent) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (gcAccountId) {
      getContactInvitations(gcAccountId).then((response) => {
        const { error } = response;

        if (error) {
          defaultHandleError({ enqueueSnackbar }, null);
          return;
        }

        setUnformattedInvitations(response.data);
      });
    }
  }, [gcAccountId, getContactInvitations]);

  useEffect(() => {
    if (!isAllKycCompleted) {
      return;
    }

    if (totalPendingInvoices === 0 && totalSupplierRequests > 0) {
      setActiveTabIndex(1);
      getPendingInvoices(gcAccountId, 0, 1); // just need total
    } else {
      setActiveTabIndex(0);
      getSupplierRequests(gcAccountId, 0, 10); // just need total
    }
  }, [gcAccountId, isAllKycCompleted, totalPendingInvoices, totalSupplierRequests]);

  useEffect(() => {
    if (activeAccount && invitations) {
      const newIsAllKycCompleted = getIsAllKycCompleted(activeAccount, invitations) === true && tokenRefreshed;
      setIsAllKycCompletedWithSessionStorage(newIsAllKycCompleted);
    }
  }, [activeAccount, invitations, isAllKycCompleted, setIsAllKycCompletedWithSessionStorage]);

  useEffect(() => {
    if (!isAllKycCompleted && invitations && !tokenRefreshed) {
      refreshToken().then((response) => {
        if (!response.error) {
          setTokenRefreshed(true);
        }
      });
    }
  }, [isAllKycCompleted, invitations, tokenRefreshed, setTokenRefreshed]);

  const getArrearsData = async (gcAccountId) => {
    const result = await getArrearsPaymentDetails(gcAccountId);

    if (result?.payload?.data) {
      setArrearsDetails(result.payload.data);
    }
  };

  useEffect(() => {
    if (gcAccountId) {
      const fetchData = async () => {
        await getArrearsData(gcAccountId);
      };

      fetchData();
    }
  }, [gcAccountId, refreshArrearsBanners]);

  useEffect(() => {
    if (gcAccountId) {
      getFacilityLimits(gcAccountId);
    }
  }, [gcAccountId]);

  const updateInvitation = (contactToUpdate: any, index: number): Promise<void> => {
    return new Promise((resolve) => {
      const updatedunformattedInvitation = {
        ...(unformattedInvitations ? unformattedInvitations[index] : {}),
        ...contactToUpdate,
      };
      // $FlowFixMe
      const updatedInvitations = Object.assign([], unformattedInvitations, {
        [index]: updatedunformattedInvitation,
      });
      setUnformattedInvitations(updatedInvitations);
      resolve();
    });
  };

  const handleTabChange = (e, tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  const handleNewInvoice = () => {
    if (analyticsAccountSummary && analyticsAccountSummary.isRequireConnection) {
      setBankLinkEnforcementStatusDialog(true);
      return;
    }
    history.push('/buyer/payments/pay-new-or-existing');
  };

  const onConnectBank = () => {
    history.push('/settings/add-bank');
  };

  const handleInviteRequest = (shiftInvite: ShiftInvitee) => {
    const recipientGCContactId = shiftInvite.gcContactId;
    const mobileNumber = `0${shiftInvite.mobileNumber}`;
    updateInvitation({ mobilePhone: mobileNumber }, shiftInvite.index).then(() => {
      sendContactInvite(gcAccountId, recipientGCContactId, mobileNumber).then((response) => {
        const { error } = response;

        if (!response || error) {
          defaultHandleError({ enqueueSnackbar }, null);
          return;
        }

        updateInvitation(
          {
            mobilePhone: mobileNumber,
            ...shiftInvite,
            status: ContactInvitationStatusValues.Invited,
          },
          shiftInvite.index
        );
        enqueueSnackbar(messages.invitationSent(mobileNumber), { variant: 'success' });
      });
    });
  };

  if (!tokenRefreshed) {
    return null;
  }

  let leftComponent: Node;
  let rightComponent: Node;
  const hasPermissionToViewDashboard =
    isAllKycCompleted || (isAllSetupCompleteRequirement && ability.can(Permission.Actions.Create, Permission.Buyer.BankAccount));

  if (isAllKycCompleted) {
    leftComponent = (
      <div>
        <div className={classes.balanceSummaryContainer}>
          <BalanceSummary
            activeAccountIsDelinquent={activeAccountIsDelinquent}
            isAccountReady={isAccountReady}
            handleNewInvoice={handleNewInvoice}
            isRequiredToConnectBank={analyticsAccountSummary.isTransactionsOlderThanAllowed}
            isPdEligibleForCli={analyticsAccountSummary.probabilityOfDefault < PROBABILITY_OF_DEFAULT_CUTOFF}
            openBankLinkDialog={() => setBankLinkEnforcementStatusDialog(true)}
          />
          {activeAccount && activeAccount.isAllowedToRegisterForBusinessOverdraft ? <BusinessOverDraft /> : null}
        </div>
        <JoinBrokerNetwork />
      </div>
    );
    rightComponent = (
      <>
        {shouldShowDelinquencyBanner() ? (
          <Grid item xs={12} className={classes.errorAlert} data-testid='uia-delinquent-banner'>
            <AlertError
              message='Your account is not able to pay invoice at this time'
              subMessage='Please call 1300 249 649 for assistance.'
              data-testid='uia-alertError'
            />
          </Grid>
        ) : null}
        {analyticsAccountSummary && analyticsAccountSummary.probabilityOfDefault < PROBABILITY_OF_DEFAULT_CUTOFF ? (
          <ExtendableInvoices gcAccountId={gcAccountId} />
        ) : null}
        <Tabs value={activeTabIndex} indicatorColor='primary' onChange={handleTabChange}>
          <Tab
            label={<TabLabel label='Pending invoices' badgeLabel={totalPendingInvoices.toString()} showBadge={totalPendingInvoices > 0} />}
            className={classes.tab}
          />
          <Tab
            label={<TabLabel label='Supplier requests' badgeLabel={totalSupplierRequests.toString()} showBadge={totalSupplierRequests > 0} />}
            className={classes.tab}
          />
        </Tabs>
        <TabPanel tabIndex={0} activeTabIndex={activeTabIndex}>
          <PendingInvoices />
        </TabPanel>
        <TabPanel tabIndex={1} activeTabIndex={activeTabIndex}>
          <SupplierRequests />
        </TabPanel>
      </>
    );
  } else {
    if (invitations === null) {
      return null;
    }
    leftComponent = (
      <PageItem className={classes.leftInfo} header={`Welcome ${firstName}`}>
        <Grid container>
          <Grid item sm={12}>
            <Typography paragraph>
              All directors and/or beneficial owners are required to be verified for your Shift Trade accounts activation.
            </Typography>
            <Typography paragraph>Your account will be ready to start paying invoices once that is done.</Typography>
          </Grid>
        </Grid>
      </PageItem>
    );
    rightComponent = gcAccountId ? (
      <PageItem header='Add other directors & beneficial owners' className={classes.invitationList}>
        <Typography paragraph>
          As your business has more than 1 director and/or beneficial owners, all directors and/or beneficial owners &nbsp;must be registered to a
          Shift Trade account before you can start adding invoices.
        </Typography>
        <Typography paragraph>Please invite the other directors and/or beneficial owners below to speed up the process.</Typography>
        <InviteeList invitees={invitations} gcAccountId={gcAccountId} handleInviteRequest={handleInviteRequest} />
      </PageItem>
    ) : null;
  }

  return (
    <BasicPageLayout title='Dashboard' noMargin>
      {bankLinkEnforcementStatusDialog ? <BankLinkDialog onClose={() => setBankLinkEnforcementStatusDialog(false)} /> : null}
      {analyticsAccountSummary && analyticsAccountSummary.isRequireConnection ? (
        <Grid className={classes.bankLinkBanner}>
          <Notification
            appearance='error'
            variant='banner'
            title='No bank account connected'
            children='To transact using this account, we require a connected business bank account. Please connect your bank account.'
            actions={[
              <NotificationAction
                appearance='secondary'
                key='okay'
                className={classes.notificationAction}
                onClick={onConnectBank}
                data-testid='uia-bank-link-banner-button'
              >
                Connect bank account
              </NotificationAction>,
            ]}
          />
        </Grid>
      ) : null}
      {referralAccount ? <AssociateWithReferer /> : null}
      {hasPermissionToViewDashboard ? (
        <>
          <AccountReadinessAlerts />
          {!isLoadingFacilityLimits && facilityLimits && facilityLimits.hasPendingRequest ? (
            <AccountLimitBanner currentLimit={facilityLimits.currentLimit} requestedLimit={facilityLimits.requestedLimit} />
          ) : null}
          {!!arrearsDetails?.isPendingLPTs && arrearsDetails?.totalAmountToCurrent > 0 ? (
            <ArrearsPaymentPendingBanner totalArrearsAmount={arrearsDetails.totalAmountToCurrent} />
          ) : null}
          {!!arrearsDetails?.isPendingLPTs === false && arrearsDetails?.totalAmountToCurrent > 0 ? (
            <ArrearsPaymentCollectionBanner
              totalArrearsAmount={arrearsDetails.totalAmountToCurrent}
              gcAccountId={gcAccountId}
              setRefreshArrearsBanners={setRefreshArrearsBanners}
            />
          ) : null}
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12} sm={4} className={classes.left}>
              {leftComponent}
            </Grid>
            <Grid item xs={12} sm={8} className={classes.right}>
              {rightComponent}
            </Grid>
          </Grid>
        </>
      ) : null}
      {!hasPermissionToViewDashboard && (
        <div className={classes.errorAlert}>
          <AlertError
            message='Account setup incomplete'
            subMessage={
              <Typography>
                The Shift account is not complete for your business. The account set-up is usually pending when all directors are yet to complete
                registration and/or payment method is missing. Please request your directors to login to review the pending steps.
              </Typography>
            }
          />
        </div>
      )}
    </BasicPageLayout>
  );
};

export default withSnackbar(Dashboard);
