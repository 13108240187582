// @flow
import * as React from 'react';
import IdleTimer from 'react-idle-timer';
import Timer from 'react-compound-timer';
import { type RouterHistory, withRouter } from 'react-router-dom';

import Auth from '../../api/auth';
import { useAuth } from '../../api/authhooks';
import ConfirmationDialog from '../ConfirmationDialog';

type State = {
  dialogOpen: boolean,
  timeoutValue: number,
  extendCount: number,
};

type Props = {
  history: RouterHistory,
};

export class GlobalSessionHandler extends React.Component<Props, State> {
  idleTimerRef: any;

  timerRef: any;

  refreshTokenTimerId: any;

  static displayName = 'GlobalSessionHandler';

  constructor() {
    super();
    this.idleTimerRef = React.createRef();
    this.timerRef = React.createRef();

    this.state = {
      dialogOpen: false,
      timeoutValue: 1000 * 60 * 30,
      extendCount: 0,
    };
  }

  componentDidMount() {
    this.scheduleTokenRefresh();
  }

  componentWillUnmount() {
    if (this.refreshTokenTimerId) {
      clearTimeout(this.refreshTokenTimerId);
      this.refreshTokenTimerId = undefined;
    }
  }

  onAction = () => {
    if (this.timerRef) {
      this.timerRef.reset();
    }
  };

  handleExtend = () => {
    this.setState((state) => ({
      dialogOpen: false,
      extendCount: state.extendCount + 1,
    }));

    if (this.idleTimerRef) {
      this.idleTimerRef.reset();
    }

    if (this.timerRef) {
      this.timerRef.reset();
    }
  };

  handleCancel = () => {
    this.setState({ dialogOpen: false });
    this.handleLogout();
  };

  handleLogout = () => {
    this.props.history.push('/logout');
  };

  scheduleTokenRefresh() {
    // refresh the token 1 minute before the expiry
    const timeout = (Auth.getTokenExpiry() - 60) * 1000;
    this.refreshTokenTimerId = setTimeout(async () => {
      try {
        const { renew } = useAuth();
        const response = await renew();
        if (response.status !== 200) {
          this.handleLogout();
          return;
        }

        this.scheduleTokenRefresh();
      } catch (error) {
        this.handleLogout();
      }
    }, timeout);
  }

  render() {
    return (
      <React.Fragment>
        {this.state.dialogOpen ? <ConfirmationDialog
            isOpen={this.state.dialogOpen}
            onClose={this.handleCancel}
            onConfirm={this.handleExtend}
            title='Session Expiry'
            contentText='Your session is about to expire. Would you like to extend it?'
            id='session-extend-confirmation'
          /> : null}
        <Timer
          ref={(ref) => {
            this.timerRef = ref;
          }}
          initialTime={this.state.timeoutValue}
          direction='backward'
          checkpoints={[
            {
              time: 1000 * 60 * 5,
              callback: () => {
                if (this.state.extendCount < 2) {
                  this.setState({ dialogOpen: true });
                  this.idleTimerRef.pause();
                }
              },
            },
            {
              time: 0,
              callback: () => {
                this.handleLogout();
              },
            },
          ]}
        />
        <IdleTimer
          ref={(ref) => {
            this.idleTimerRef = ref;
          }}
          element={document}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeoutValue}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(GlobalSessionHandler);
