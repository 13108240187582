// @flow
import { handleActions } from 'redux-actions';

import { type FacilityLimits } from './types';
import { actionTypes, PARTITION, ACTION_PREFIX } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';

export type ReduxState = {
  facilityLimits: ?FacilityLimits,
  facilityLimitsPending: boolean,
};

const initialState: ReduxState = {
  facilityLimits: null,
  facilityLimitsPending: false,
};

const reducer = handleActions<ReduxState>(
  {
    [actionTypes.GET_FACILITY_LIMITS_FULFILLED]: (state, { payload }) => ({
      ...state,
      facilityLimits: payload.data,
      facilityLimitsPending: false,
    }),
    [actionTypes.GET_FACILITY_LIMITS_PENDING]: (state) => ({
      ...state,
      facilityLimitsPending: true,
    }),
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
