export const ACTION_PREFIX = 'ADD_STAFFS';
export const PARTITION = 'addStaffs';

export const actionTypes = {
  ADD_STAFFS_PENDING: 'ADD_STAFFS_PENDING',
  ADD_STAFFS_FULFILLED: 'ADD_STAFFS_FULFILLED',
  ADD_STAFFS_REJECTED: 'ADD_STAFFS_REJECTED',
  SET_STAFF_PAGE_VISIBILITY_PENDING: 'SET_STAFF_PAGE_VISIBILITY_PENDING',
  SET_STAFF_PAGE_VISIBILITY_FULFILLED: 'SET_STAFF_PAGE_VISIBILITY_FULFILLED',
  SET_STAFF_PAGE_VISIBILITY_REJECTED: 'SET_STAFF_PAGE_VISIBILITY_REJECTED',
};
