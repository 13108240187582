// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  theme: any,
};

const TriangularWarningIcon = withTheme((props: Props) => (
  <SvgIcon style={{ alignSelf: 'baseline' }} viewBox='0 0 69 58' {...(props: any)}>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g
        transform='translate(-696.000000, -257.000000)'
        fill={props.theme.palette.warning.main}
        fillRule='nonzero'
        stroke={props.theme.palette.common.white}
      >
        <g transform='translate(696.000000, 257.000000)'>
          <path d='M67.89,48.3 C67.89,46.6119987 67.4100536,44.9664682 66.498892,43.5995701 L41.7222915,4.89480825 C40.0463208,2.34733282 37.319036,0.85 34.3,0.85 C31.3250198,0.85 28.538998,2.36964828 26.8808863,4.88991619 L2.10225429,43.527771 C0.377203861,46.2480428 0.24822572,49.6936018 1.77089232,52.4741739 C3.27816212,55.2921131 6.24037052,57.04 9.45,57.04 L59.08,57.04 C62.3336933,57.04 65.2398937,55.3144435 66.7672869,52.4594819 C67.4964622,51.1999972 67.89,49.7456184 67.89,48.3 Z M63.9289478,50.9894261 L63.9252814,50.9960286 C62.9288111,52.7590146 61.0847772,53.84 59.08,53.84 L9.52,53.84 C7.48787361,53.84 5.58189988,52.7354016 4.67471858,50.9960286 C3.67908571,49.2345243 3.74318429,47.0765386 4.8292378,45.3698904 L29.6748674,6.66682269 C30.6672745,5.06370363 32.4288546,4.12 34.37,4.12 C36.2835361,4.12 37.9812084,5.04042475 39.061108,6.66042994 L63.8418307,45.3715623 C64.3859837,46.2266597 64.69,47.3008507 64.6879466,48.4152679 C64.6055336,49.321811 64.3549907,50.2083474 63.9289478,50.9894261 Z' />
          <rect x='31.71' y='40.39' width='5.11' height='5.39' />
          <rect x='31.71' y='18.41' width='5.11' height='17.43' />
        </g>
      </g>
    </g>
  </SvgIcon>
));

export default TriangularWarningIcon;
