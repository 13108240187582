/* istanbul ignore file */
import { v4 as uuid } from 'uuid';

let sessionId = null;

/**
 * Initializes the ThreatMetrix session.
 * @param {{domain: string, orgId: string, pageId?: string}} options The profiling options.
 * @returns {string} The session ID.
 */
export function initSession(options) {
  if (!sessionId) {
    sessionId = uuid();
    // eslint-disable-next-line
    global.tmx?.prfl(options.domain, options.orgId, sessionId, options.pageId || undefined);
  }
}

export function getSessionId() {
  return sessionId;
}
