// @flow
import React from 'react';
import type { FormikState, FormikHelpers } from 'formik';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import NumberFormatInput from '../NumberFormatInput';
import FormikField from './FormikField';
import type { Field } from './types';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(1, 3),
  },
}));

type Props = {
  field: Field,
  form: FormikState<any> & FormikHelpers<any>,
  showNoError: boolean,
  disableButton: boolean,
};

const FormikLimitRequest = ({ field, form, showNoError, disableButton, ...other }: Props) => {
  const classes = useStyles();
  return (
    <FormikField field={field} form={form} showNoError={showNoError}>
      <Grid container spacing={1}>
        <Grid item>
          <NumberFormatInput
            onChange={({ target }) => {
              form.setFieldValue(field.name, target.value, true);
              form.setTouched({ [field.name]: true });
            }}
            // $FlowFixMe
            {...other}
          />
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            disabled={disableButton}
            size='large'
            variant='outlined'
            color='primary'
            type='submit'
            data-testid='uia-requestAccountLimitIncrease'
          >
            Request
          </Button>
        </Grid>
      </Grid>
    </FormikField>
  );
};

export default FormikLimitRequest;
