// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';

const { requestAccessPending, requestAccessFulfilled, requestAccessRejected } = createActions(
  actionTypes.REQUEST_ACCESS_PENDING,
  actionTypes.REQUEST_ACCESS_FULFILLED,
  actionTypes.REQUEST_ACCESS_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const requestAccess = (gcAccountId: string, customerBase: string, terms: string, avgTransactionSize: number) => (dispatch: Dispatch<any>) => {
  dispatch(requestAccessPending());

  return shiftPaymentsApi
    .requestSupplierAccess(gcAccountId, customerBase, terms, avgTransactionSize)
    .then((response) => {
      return dispatch(requestAccessFulfilled(response));
    })
    .catch((error) => {
      return dispatch(requestAccessRejected(error));
    });
};

export default {
  requestAccess,
};
