// @flow
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, Paper, Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import NotificationIcon from '../../../components/svg/NotificationIcon';
import PhoneIcon from '../../../components/svg/PhoneIcon';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import actions from './actions';
import initialDataSelectors from '../../../components/InitialData/selectors';
import OkIcon from '@material-ui/icons/DoneRounded';

const useStyles = makeStyles((theme) => ({
  notificationIcon: {
    margin: theme.spacing(3),
  },
  businessOverDraft: {
    marginTop: theme.spacing(2),
    backgroundColor: '#E6F8F9',
  },
  businessOverDraftMessage: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  enquireNowButton: {
    marginTop: theme.spacing(2),
    backgroundColor: '#FDFDFD',
    color: '#00B7C1',
    '&:hover': {
      backgroundColor: '#FDFDFD',
      color: '#00B7C1',
    },
  },
  phoneIconSvg: {
    height: 16,
    width: 13.65,
    margin: 9,
  },
  businessOverDraftText: {
    fontSize: 12,
  },
  businessOverdraftInfo: {
    backgroundColor: '#F2FBFC',
    padding: 24,
  },
  subContentText: {
    color: theme.palette.grey.text,
  },
  container: {
    padding: theme.spacing(4),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  confirmationBox: {
    backgroundColor: '#E8F8ED',
    padding: theme.spacing(4),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  okIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: '50%',
    backgroundColor: '#00CAA6',
    color: 'white',
    marginBottom: theme.spacing(2),
  },
  confirmationHeader: {
    marginBottom: theme.spacing(4),
  },
}));

const BusinessOverDraft = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isBusinessOverDraftPopupOpen, setBusinessOverDraftPopupOpen] = useState(false);
  const [isConfirmationPopupOpen, setConfirmationPopupOpen] = useState(false);
  const gcAccountId = useSelector(initialDataSelectors.selectSelectedGcAccountId);

  const enquireNow = () => {
    setBusinessOverDraftPopupOpen(true);
  };

  const startBusinessOverDraft = () => {
    setBusinessOverDraftPopupOpen(false);
    if (gcAccountId)
      dispatch(actions.requestBusinessOverdraft(gcAccountId)).then((response) => {
        if (!response.error) {
          setConfirmationPopupOpen(true);
        }
      });
  };

  const cancelBusinessOverDraft = () => {
    setBusinessOverDraftPopupOpen(false);
  };

  const cancelConfirmationPopup = () => {
    setConfirmationPopupOpen(false);
  };

  return (
    <React.Fragment>
      <Paper className={classes.businessOverDraft}>
        <Grid container>
          <Grid item sm={2} xs={2}>
            <NotificationIcon className={classes.notificationIcon} htmlColor={theme.palette.primary.main} viewBox='0 0 26 26' />
          </Grid>
          <Grid className={classes.businessOverDraftMessage} item sm={10} xs={10}>
            <Typography variant='subtitle2' data-testid='uia-businessOverdraft'>
              Need a Business Overdraft?
            </Typography>
            <Typography variant='body1' data-testid='uia-businessOverdraft' className={classes.businessOverDraftText}>
              Readily available funds for all business needs and added cash flow flexibility.
            </Typography>
            <Button
              className={classes.enquireNowButton}
              variant='outlined'
              color='primary'
              size='large'
              fullWidth
              onClick={enquireNow}
              data-testid={`uia-BusinessOverdraftEnquireNow`}
            >
              <PhoneIcon className={classes.phoneIconSvg} />
              Enquire now
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {isBusinessOverDraftPopupOpen ? (
        <ConfirmationDialog
          isOpen={isBusinessOverDraftPopupOpen}
          title='Business Overdraft'
          id='businessOverdraftStart'
          data-testid='uia-adHocBusinessOverdraftStartDialog'
          showChildren
          contentText=''
          onClose={cancelBusinessOverDraft}
          onConfirm={startBusinessOverDraft}
          overrideActions
          rightAlignActions
          actions={
            <>
              <Button
                onClick={startBusinessOverDraft}
                color='primary'
                variant='contained'
                disabled={false}
                autoFocus
                data-testid='uia-adHocStartButton'
              >
                Enquire now
              </Button>
            </>
          }
        >
          <Grid container rowSpacing={3} columnSpacing={5} className={classes.businessOverdraftInfo}>
            <Typography variant='body1'>
              Access a line of credit. Cover day-to-day expenses to manage your cash flow, or free up capital to grow your business.
            </Typography>
            <Typography variant='body1'>
              <ul>
                <li>Seamlessly transfer funds to your business bank account​​</li>
                <li>Only pay interest on funds you use</li>
                <li>Use funds for any business purpose</li>
              </ul>
            </Typography>
            <Typography className={classes.subContentText} variant='body2'>
              Pending a credit assessment​.
            </Typography>
          </Grid>
        </ConfirmationDialog>
      ) : null}
      {isConfirmationPopupOpen ? (
        <ConfirmationDialog
          isOpen={isConfirmationPopupOpen}
          title='Business Overdraft'
          id='businessOverdraftConfirmation'
          data-testid='uia-adHocBusinessOverdraftConfirmationDialog'
          showChildren
          contentText=''
          onClose={cancelConfirmationPopup}
          onConfirm={cancelConfirmationPopup}
          overrideActions
          titleVariant='h1'
          maxWidth={'sm'}
        >
          <Grid container className={classes.container}>
            <Grid container className={classes.confirmationBox}>
              <Grid item xs={12}>
                <Grid container align='center' justify='center' alignItems='center'>
                  <OkIcon className={classes.okIcon} />
                </Grid>
              </Grid>
              <Grid item xs={12} align='center' justify='center' alignItems='center'>
                <Typography variant='h2' className={classes.confirmationHeader}>
                  We’ve got your request
                </Typography>
                <Typography variant='body1'>Someone from our Customer Operations team will be in touch in the next business day</Typography>
              </Grid>
            </Grid>
          </Grid>
        </ConfirmationDialog>
      ) : null}
    </React.Fragment>
  );
};

export default BusinessOverDraft;
