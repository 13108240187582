import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Settings from './Component';
import actions from './actions';
import settingsSelectors from './selectors';
import initialDataSelectors from '../../components/InitialData/selectors';
import accountSelectMenuSelectors from '../../components/AccountSelectMenu/selectors';

const mapStateToProps = createSelector(
  settingsSelectors.selectRepaymentDay,
  settingsSelectors.selectDirectDebitAccount,
  settingsSelectors.selectSmsReminder,
  initialDataSelectors.selectAccounts,
  accountSelectMenuSelectors.selectActiveAccountIndex,
  (repaymentDay, directDebitAccount, smsReminder, accounts, activeAccountIndex) => ({
    repaymentDay,
    directDebitAccount,
    smsReminder,
    gcAccountId: accounts ? accounts[activeAccountIndex].gcAccountId : null,
  })
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
