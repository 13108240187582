import { Grid, Radio, makeStyles } from '@material-ui/core';
import { Text } from '@shiftfinancial/design-system';

const useStyles = makeStyles((theme) => ({
  radio: {
    textAlign: 'center',
  },
  subText: {
    marginTop: theme.spacing(0.5),
  },
}));

export type PaymentMethodRadioBoxProps = {
  text: string;
  secondaryText: string;
  checked: boolean;
  testId: string;
};

export function PaymentMethodRadioBox(props: PaymentMethodRadioBoxProps) {
  const classes = useStyles();

  const { text, secondaryText, checked, testId } = props;

  return (
    <Grid container data-testid={`uia-paymentMethodRadioBox-${testId}-container`}>
      <Grid item xs={2} sm={1} className={classes.radio}>
        <Radio checked={checked} data-testid={`uia-paymentMethodRadio-${testId}`} />
      </Grid>
      <Grid item xs={10} sm={11}>
        <Grid container>
          <Grid item xs={12}>
            <Text fontFamily='standard' fontSize='large' fontWeight='medium'>
              {text}
            </Text>
          </Grid>
          <Grid item xs={12} className={classes.subText}>
            <Text fontFamily='standard' fontSize='small' color='secondary'>
              {secondaryText}
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
