// @flow
import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Grid } from '@material-ui/core';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withRouter, useHistory } from 'react-router-dom';
import { useAbility } from '@casl/react';

import ShiftLogo from '../svg/ShiftLogo';
import { AccountSelectMenu } from '../AccountSelectMenu';
import { UserSelectProfileMode } from '../UserSelectProfileMode';
import Footer from '../Footer';
import { getNavigations } from '../../lib/profileHelper';
import { AbilityContext } from '../../lib/ability';

const drawerWidth = 270;
const useStyles = makeStyles((theme) => ({
  root: {
    opacity: '1 !important',
    color: `${theme.palette.common.white} !important`,
    maxHeight: '8%',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'white',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    color: theme.palette.common.black,
    marginRight: theme.spacing(2),
  },
  menuButtonClicked: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  logo: {
    justifyContent: 'center',
    '& svg': {
      width: '100%',
      height: 30,
    },
    [theme.breakpoints.down('xs')]: {
      width: '75%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '85%',
    },
  },
  tabs: {
    '& nav': {
      padding: 0,
    },
    '& .MuiListItem-root': {
      padding: theme.spacing(2, 1.3),
      '&[data-selected="true"]': {
        backgroundColor: theme.palette.action.selectedDark,
      },
    },
    '& .MuiListItemText-root': {
      opacity: '1 !important',
      color: `${theme.palette.common.white} !important`,
      margin: 'auto',
      justifyContent: 'left !important',
      width: '100%',
      backgroundColor: 'inherit',
      border: 0,
      '& .MuiListItemText-primary': {
        fontSize: '12px !important',
        fontWeight: 500,
      },
    },
  },
  tabsContainer: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  tabsContainerWithoutFooter: {
    flex: 1,
  },
}));

type Props = {
  isBuyer: boolean,
  isSupplier: boolean,
  isAllKycCompleted: boolean,
  activeProfileMode: number,
  isNavigationVisible: boolean,
};

const SideNavigation = (props: Props) => {
  const { isBuyer, isSupplier, isAllKycCompleted, activeProfileMode, isNavigationVisible } = props;
  const classes = useStyles();
  const history = useHistory();
  const ability = useAbility(AbilityContext);
  const [menuButtonClass, setMenuButtonClass] = useState(classes.menuButton);
  const [open, setOpen] = useState(false);
  const navList = useMemo(
    () => getNavigations(isAllKycCompleted, isBuyer, isSupplier, activeProfileMode, true, ability),
    [isSupplier, isAllKycCompleted, activeProfileMode, ability]
  );

  const handleDrawerOpen = () => {
    setMenuButtonClass(!open ? classes.menuButtonClicked : classes.menuButton);
    setOpen(!open);
  };

  const handleMenuItemClick = (event, route) => {
    setMenuButtonClass(classes.menuButton);
    setOpen(false);
    history.push(route);
  };

  const toggleDrawer = (isOpen) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(isOpen);
  };

  return (
    <Grid className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton color='primary' aria-label='open drawer' onClick={handleDrawerOpen} edge='start' className={menuButtonClass}>
            <MenuIcon />
          </IconButton>
          {!open && (
            <Grid className={classes.logo}>
              <ShiftLogo />
            </Grid>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        anchor='left'
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={toggleDrawer(false)}
      >
        <div className={classes.tabsContainer}>
          <div className={classes.tabsContainerWithoutFooter}>
            <UserSelectProfileMode />
            <List component='div' disablePadding data-testid='navTabs' className={classes.tabs}>
              <AccountSelectMenu isCollapsible />
              {isNavigationVisible ? navList.map((nav) => {
                  return (
                    !nav.disabled && (
                      <React.Fragment key={nav.id}>
                        <ListItem
                          button
                          className={classes.mobileAccountName}
                          onClick={(event) => handleMenuItemClick(event, nav.routes[0])}
                          data-selected={nav.routes.findIndex((r) => history.location.pathname.indexOf(r) > -1) > -1}
                        >
                          <ListItemText primary={nav.label} />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    )
                  );
                }) : null}
            </List>
          </div>
          <Footer />
        </div>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      />
    </Grid>
  );
};

export default withRouter(SideNavigation);
