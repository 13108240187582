// @flow

import { Chip, Divider, FormControl, Grid, MenuItem, Select, Typography, makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';

import DollarsAndCentsText from '../../../components/DollarsAndCentsText';
import { DATE_AND_MONTH } from '../../../constants';
import { type WeeklyExtensionFee } from '../../Buyer/Review/types';

const useStyle = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(2),
    color: theme.palette.common.charcoalBlack,
  },
  bold: {
    fontWeight: 500,
  },
  dateChip: {
    alignItems: 'center',
    width: '100%',
    margin: theme.spacing(2, 0),
    height: theme.spacing(3),
    fontSize: theme.spacing(1.5),
    backgroundColor: theme.palette.primary.oysterBay,
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
  extensionDropDown: {
    width: '100%',
    marginBottom: theme.spacing(0.5),
  },
  subTitles: {
    fontWeight: 500,
    marginTop: theme.spacing(2),
    lineHeight: theme.spacing(2),
  },
  valueText: {
    color: theme.palette.grey.lightSlateGrey,
  },
  values: {
    textAlign: 'end',
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
  },
  divider: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.grey.faintLineGrey,
  },
  footNote: {
    fontSize: theme.spacing(1.2),
    color: theme.palette.grey['500'],
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  feeAndTerms: WeeklyExtensionFee[],
  existingFee: number,
  enableConfirm: (extension: WeeklyExtensionFee) => void,
  selectedExtension?: WeeklyExtensionFee,
  title?: string,
};

const NewExtensionSchedule = (props: Props) => {
  const { feeAndTerms, existingFee, enableConfirm, selectedExtension, title } = props;

  const classes = useStyle();
  const [selectedExtensionKey, setSelectedExtensionKey] = useState(selectedExtension ? selectedExtension.week : -1);
  const [extensionOption, setExtensionOption] = useState(selectedExtension);

  const installmentDateRange =
    extensionOption && extensionOption.newRepaymentStartDate && extensionOption.newRepaymentEndDate
      ? `${extensionOption.newTotalNoOfInstallments} instalments (${moment(extensionOption.newRepaymentStartDate).format(DATE_AND_MONTH)} - ${moment(
          extensionOption.newRepaymentEndDate
        ).format(DATE_AND_MONTH)})`
      : '-';

  const footNoteDate = extensionOption ? moment(extensionOption.newRepaymentStartDate).format(DATE_AND_MONTH) : '';

  const setSelectedExtension = (week: number) => {
    setSelectedExtensionKey(week);
    if (feeAndTerms) {
      const extension = feeAndTerms.find((item) => item.week === week);
      setExtensionOption(extension);
      if (extension) {
        enableConfirm(extension);
      }
    }
  };

  return (
    <Grid item xs={12} className={classes.main}>
      {!!title && (
        <Grid item>
          <Typography variant='h5'>{title}</Typography>
        </Grid>
      )}
      <Grid item className={classes.bold}>
        <Chip className={classes.dateChip} label={installmentDateRange} data-testid='uia-new-extension-date-range' />
      </Grid>
      {!!feeAndTerms && (
        <Grid item>
          <FormControl className={classes.extensionDropDown} variant='outlined'>
            <Select
              value={selectedExtensionKey}
              onChange={(e) => {
                setSelectedExtension(e.target.value);
              }}
              data-testid='uia-extension-options'
            >
              <MenuItem value={-1} disabled>
                Select term...
              </MenuItem>
              {feeAndTerms.map((feeAndTerm: WeeklyExtensionFee) => (
                <MenuItem key={feeAndTerm.week} value={feeAndTerm.week}>
                  {feeAndTerm.week} week{feeAndTerm.week > 1 ? 's' : ''}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item className={classes.subTitles}>
        <Typography variant='body2' className={classes.bold}>
          Weekly repayments
        </Typography>
      </Grid>
      <Divider className={classes.divider} />
      <Grid item container>
        <Grid item sm={6}>
          <Typography variant='body2' className={classes.valueText}>
            Amount
          </Typography>
        </Grid>
        <Grid item sm={6} className={classes.values}>
          <Typography variant='body2' className={classes.bold}>
            {extensionOption ? <DollarsAndCentsText amount={extensionOption.newWeeklyRepayment} /> : '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.subTitles}>
        <Typography variant='body2' className={classes.bold}>
          Fee
        </Typography>
      </Grid>
      <Divider className={classes.divider} />
      <Grid item container>
        <Grid item sm={6}>
          <Typography variant='body2' className={classes.valueText}>
            Existing
          </Typography>
        </Grid>
        <Grid item sm={6} className={classes.values}>
          <Typography variant='body2'>{extensionOption ? <DollarsAndCentsText amount={existingFee} /> : '-'}</Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item sm={6}>
          <Typography variant='body2' className={classes.valueText}>
            Extension fee
          </Typography>
        </Grid>
        <Grid item sm={6} className={classes.values}>
          <Typography variant='body2' data-testid='uia-new-extension-fee-value'>
            {extensionOption ? <DollarsAndCentsText amount={extensionOption.newExtensionFee} /> : '-'}
          </Typography>
        </Grid>
      </Grid>
      {!!extensionOption && (
        <Grid item container>
          <Typography className={classes.footNote} variant='body2' data-testid='uia-extension-footer-note'>
            One-off fee of <DollarsAndCentsText amount={existingFee + extensionOption.newExtensionFee} /> will be drawn on{` ${footNoteDate}`}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default NewExtensionSchedule;
