// @flow
import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  fieldLabel: {
    paddingBottom: `${theme.spacing(1)}px !important`,
    paddingTop: `${theme.spacing(1.5)}px !important`,
    width: '100%',
  },
  fieldLabelNoTopMargin: {
    paddingBottom: `${theme.spacing(1)}px !important`,
    paddingTop: '0 !important',
    width: '100%',
  },
  fieldControl: {
    paddingTop: '0 !important',
    width: '100%',
  },
}));

type Props = {
  label: string,
  children: React.Node,
  noTopMargin?: boolean,
};

const FormItem = ({ label, children, noTopMargin = false }: Props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div
        className={clsx({
          [classes.fieldLabelNoTopMargin]: noTopMargin,
          [classes.fieldLabel]: !noTopMargin,
        })}
      >
        <Typography variant='subtitle2' component='label'>
          {label}
        </Typography>
      </div>
      <div className={classes.fieldControl}>{children}</div>
    </React.Fragment>
  );
};

export default FormItem;
