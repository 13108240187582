// @flow
export const ACTION_PREFIX = 'ABILITY';
export const PARTITION = 'ability';

export const actionTypes = {
  GET_ROLE_PERMISSION_MAP_PENDING: 'GET_ROLE_PERMISSION_MAP_PENDING',
  GET_ROLE_PERMISSION_MAP_FULFILLED: 'GET_ROLE_PERMISSION_MAP_FULFILLED',
  GET_ROLE_PERMISSION_MAP_REJECTED: 'GET_ROLE_PERMISSION_MAP_REJECTED',
};
export const loanAgreementPermissionName = 'Shift.Buyer.LoanAgreement.Sign';
export const ddrAgreementPermissionName = 'Shift.Buyer.DDRAgreement.Sign';
export const privacyAgreementPermissionName = 'Shift.Buyer.PrivacyAgreement.Sign';
