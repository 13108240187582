import React, { useEffect } from 'react';
import { Grid, Typography, Box, MenuItem, TextField, Button, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LinkIcon from '../svg/LinkIcon';
import initialDataSelectors from '../InitialData/selectors';
import { useSupplierTermFeeLookup } from '../../pages/Supplier/AccountOpeningWidget/hooks';
import { useSelector } from 'react-redux';
import { formatSupplierTerm } from '../../lib/formatter';
import { DEFAULT_TERM_PERIOD } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '93px',
    width: '97%',
    border: `1px solid ${theme.palette.common.bright}`,
    paddingTop: theme.spacing(1.5),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(0.5),
    overflow: 'hidden',
  },
  header: {
    [theme.breakpoints.down('xs')]: {
      '& .MuiButton-root': {
        minWidth: 35,
        width: 35,
        height: 35,
      },
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  container: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    padding: theme.spacing(4, 4),
    fontSize: '14px',
  },
  marginBetweenContents: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  iconBox: {
    marginLeft: theme.spacing(2.5),
    marginTop: theme.spacing(1.5),
    height: theme.spacing(5.4),
    width: theme.spacing(5.6),
    backgroundColor: theme.palette.grey.background,
    overflow: 'hidden',
  },
  description: {
    color: theme.palette.grey.text,
  },
  descriptionContainer: {
    marginTop: theme.spacing(0.7),
  },
  terms: {
    width: '100%',
    height: '36px',
  },
  fieldDisabled: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.grey['100'],
      height: '36px',
    },
    width: '75%',
  },
  copyButton: {
    height: theme.spacing(5.5),
    height: '36px',
  },
  headerContainer: {
    marginTop: theme.spacing(1),
  },
}));

const SupplierReferralLink = () => {
  const classes = useStyles();
  const [terms, setTerms] = React.useState({ term: 0xe, termPeriod: DEFAULT_TERM_PERIOD });
  const gcAccount = useSelector(initialDataSelectors.selectActiveAccount);
  const termFeeLookup = useSupplierTermFeeLookup(gcAccount.gcAccountId);
  const [url, setUrl] = React.useState('');

  useEffect(() => {
    if (terms) {
      setUrl(calculateUrl(terms.term, terms.termPeriod));
    }
  }, [terms]);

  const toFriendlyTermPeriod = (termPeriod) => {
    if (termPeriod && termPeriod.toLowerCase() === 'days') {
      return termPeriod.toLowerCase();
    }
    return termPeriod;
  };

  const calculateUrl = (term, termPeriod) => {
    if (gcAccount && gcAccount.shiftReferralUrl) {
      const url = new URL(gcAccount.shiftReferralUrl);
      let qParams = url.searchParams;
      qParams.set('term', term.toString(16));
      if (!qParams.has('termPeriod')) {
        qParams.append('termPeriod', toFriendlyTermPeriod(termPeriod));
      } else {
        qParams.set('termPeriod', termPeriod);
      }
      return url.toString();
    } else {
      return 'error';
    }
  };

  const handleTermChanged = (e) => {
    setTerms(e.target.value);
  };

  const getSelectedElement = (term, termList) => {
    const index = termList.findIndex((x) => x.term === term.term && x.termPeriod.toLowerCase() === term.termPeriod.toLowerCase());
    const element = termList[index];
    return element;
  };

  const withTermFeeLookup = () => {
    return termFeeLookup && termFeeLookup.length > 0;
  };

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item container xs={12} spacing={5} style={{ marginLeft: '0' }}>
          <Grid item xs={1}>
            <Box className={classes.iconBox}>
              <LinkIcon />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.headerContainer}>
              <Typography variant='h3' component='h3'>
                Registration link
              </Typography>
            </Box>
            <Box className={classes.descriptionContainer}>
              <Typography variant='body2' component='label' className={classes.description}>
                Generate your unique registration link to help customers register to your Trade Account program.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='subtitle2'>Choose terms</Typography>
            {withTermFeeLookup() && (
              <Select
                id='terms'
                value={getSelectedElement(terms, termFeeLookup)}
                disableUnderline
                variant='outlined'
                label='terms'
                onChange={handleTermChanged}
                className={classes.terms}
              >
                {termFeeLookup.map(
                  (x) =>
                    x && (
                      <MenuItem key={`${x.term}-${x.termPeriod}-${x.feeInPercentage}`} value={x}>
                        {formatSupplierTerm(x.term, x.termPeriod)}
                      </MenuItem>
                    )
                )}
              </Select>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography variant='subtitle2'>Copy This URL</Typography>
            <TextField
              data-testid='uia-referralUrl'
              placeholder='url'
              rows={2}
              variant='outlined'
              fullWidth
              autoFocus
              autoComplete='off'
              readOnly={true}
              disabled={true}
              className={classes.fieldDisabled}
              value={url}
              isDense={true}
            />
            <Button
              variant='outlined'
              color='primary'
              className={classes.copyButton}
              onClick={() => navigator.clipboard.writeText(url)}
              data-testid='uia-copy'
            >
              Copy
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SupplierReferralLink;
