// @flow
import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, Zoom, IconButton, Grid, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import DollarsAndCentsText from '../DollarsAndCentsText';
import BsbText from '../BsbText';

type Props = {
  onClose: () => void,
  paymentAmount: number,
  gcAccountId: ?string,
  payBalance: (balance: number, bankAccountId: string, gcAccountId: ?string) => Promise<any>,
  bankAccount: any,
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    marginTop: theme.spacing(1),
    fontWeight: 500,
    fontSize: theme.spacing(2.5),
  },
  detailsBox: {
    backgroundColor: theme.palette.selectPanel.selected.backgroundColor,
    padding: theme.spacing(1, 1, 1, 2),
    marginBottom: theme.spacing(2),
  },
  bold: {
    fontWeight: 500,
  },
  fieldContainer: {
    padding: theme.spacing(1, 1, 1, 0),
  },
  contentDivider: {
    backgroundColor: theme.palette.success.light,
    marginBottom: theme.spacing(1.5),
  },
  dialogDivider: {
    backgroundColor: theme.palette.grey.background,
  },
  actionContainer: {
    margin: theme.spacing(1),
    justifyContent: 'flex-start',
  },
  confirmButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  rightGap: {
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(2),
    },
  },
}));

const PayBalanceConfirmationDialog = (props: Props) => {
  const { onClose, paymentAmount, gcAccountId, payBalance, bankAccount } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handlePayBalance = () => {
    payBalance(paymentAmount, bankAccount.salesforceRecordId, gcAccountId)
      .then((response) => {
        onClose();
        if (response.data) {
          enqueueSnackbar('Your repayment request has been received. Please allow 3 business days for the direct debit to be processed.', {
            variant: 'success',
            key: 'payBalanceSuccess',
            persist: true,
          });
        }
      })
      .catch(() => {
        onClose();
          enqueueSnackbar('Repayment could not be processed. If this persists, please call us on 1300 249 649', {
          variant: 'error',
          key: 'payBalanceFailed',
        });
      });
  };

  return (
    <Dialog open={true} onClose={onClose} TransitionComponent={Zoom} fullWidth={true}>
      <DialogTitle className={classes.title} disableTypography>
        Please confirm
        <IconButton aria-label='close' onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.detailsBox}>
          <Grid item container className={classes.fieldContainer} sm={12}>
            <Grid item sm={4} className={classes.rightGap}>
              <Typography>Payment amount</Typography>
            </Grid>
            <Grid item sm={8}>
              <Typography className={classes.bold}>
                <DollarsAndCentsText amount={paymentAmount} />
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.contentDivider} />
          <Typography variant='body2' className={classes.bold}>
            From linked bank account
          </Typography>
          <Grid item container className={classes.fieldContainer} sm={12}>
            <Grid item sm={4} className={classes.rightGap}>
              <Typography>Account name</Typography>
            </Grid>
            <Grid item sm={8}>
              <Typography className={classes.bold}>{bankAccount.accountName}</Typography>
            </Grid>
          </Grid>
          <Grid item container className={classes.fieldContainer} sm={12}>
            <Grid item sm={4} className={classes.rightGap}>
              <Typography>BSB</Typography>
            </Grid>
            <Grid item sm={8} className={classes.bold}>
              <Typography className={classes.bold}>
                <BsbText value={bankAccount.bsb} />
              </Typography>
            </Grid>
          </Grid>
          <Grid item container className={classes.fieldContainer} sm={12}>
            <Grid item sm={4} className={classes.rightGap}>
              <Typography>Account number</Typography>
            </Grid>
            <Grid item sm={8}>
              <Typography className={classes.bold}>{bankAccount.accountNumber}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider className={classes.dialogDivider} />
      <DialogActions className={classes.actionContainer}>
        <Button
          onClick={() => handlePayBalance()}
          color='primary'
          variant='contained'
          className={classes.confirmButton}
          autoFocus
          data-testid='uia-payBalance-confirmation-button'
        >
          Pay balance
        </Button>
        <Button onClick={() => onClose()} color='primary' variant='text' data-testid='uia-payBalance-cancel-button'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PayBalanceConfirmationDialog;
