// @flow
import React from 'react';
import moment from 'moment';
import { Grid, Typography, Chip, makeStyles, Divider } from '@material-ui/core';

import DollarsAndCentsText from '../../../components/DollarsAndCentsText';
import { DATE_AND_MONTH } from '../../../constants';
import type { ExtensionTermsAndFees } from '../Review/types';

const useStyle = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(2),
    color: theme.palette.common.charcoalBlack,
  },
  bold: {
    fontWeight: 500,
  },
  dateChip: {
    alignItems: 'center',
    width: '100%',
    margin: theme.spacing(2, 0),
    height: theme.spacing(3),
    fontSize: theme.spacing(1.5),
    backgroundColor: theme.palette.secondary.solitude,
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
  extensionDropDown: {
    width: '100%',
  },
  subTitles: {
    fontWeight: 500,
    marginTop: theme.spacing(2),
    lineHeight: theme.spacing(2),
  },
  values: {
    textAlign: 'end',
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
  },
  divider: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.grey.faintLineGrey,
  },
  valueText: {
    color: theme.palette.grey.lightSlateGrey,
  },
}));

type Props = {
  schedule: ExtensionTermsAndFees,
  title?: string,
};

const CurrentExtensionSchedule = (props: Props) => {
  const { schedule, title } = props;
  const classes = useStyle();

  const getInstallmentDateRange = () => {
    if (schedule.canPayInFull) {
      return schedule.repaymentStartDate ? moment(schedule.repaymentStartDate).format(DATE_AND_MONTH) : '-';
    }
    return schedule.repaymentStartDate && schedule.repaymentEndDate
      ? `${schedule.totalNoOfInstallments} instalment${schedule.totalNoOfInstallments > 1 ? 's' : ''} (${moment(schedule.repaymentStartDate).format(
          DATE_AND_MONTH
        )} - ${moment(schedule.repaymentEndDate).format(DATE_AND_MONTH)})`
      : '-';
  };

  return (
    <Grid item xs={12} className={classes.main}>
      {!!title && (
        <Grid item>
          <Typography variant='h5'>{title}</Typography>
        </Grid>
      )}
      <Grid item className={classes.bold}>
        <Chip className={classes.dateChip} label={getInstallmentDateRange()} data-testid='uia-pay-balance-banner' />
      </Grid>
      <>
        <Grid item container>
          <Grid item sm={6}>
            <Typography variant='body2' className={classes.valueText}>
              Outstanding amount
            </Typography>
          </Grid>
          <Grid item sm={6} className={classes.values}>
            <Typography variant='body2' className={classes.bold}>
              <DollarsAndCentsText amount={schedule.outstandingAmount} />
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item sm={6}>
            <Typography variant='body2' className={classes.valueText}>
              {schedule.canPayInFull ? 'Days until due date' : 'Instalments left'}
            </Typography>
          </Grid>
          <Grid item sm={6} className={classes.values}>
            <Typography variant='body2'>{schedule.timeLeftToDueDate}</Typography>
          </Grid>
        </Grid>
      </>
      <Grid item className={classes.subTitles}>
        <Typography variant='body2' className={classes.bold}>
          {schedule.canPayInFull ? 'Amount due' : 'Weekly repayments'}
        </Typography>
      </Grid>
      <Divider className={classes.divider} />
      <Grid item container>
        <Grid item sm={6}>
          <Typography variant='body2' className={classes.valueText}>
            Amount
          </Typography>
        </Grid>
        <Grid item sm={6} className={classes.values}>
          <Typography variant='body2'>
            <DollarsAndCentsText amount={schedule.weeklyRepayment} />
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.subTitles}>
        <Typography variant='body2' className={classes.bold}>
          Fee
        </Typography>
      </Grid>
      <Divider className={classes.divider} />
      <Grid item container>
        <Grid item sm={6}>
          <Typography variant='body2' className={classes.valueText}>
            Existing
          </Typography>
        </Grid>
        <Grid item sm={6} className={classes.values}>
          <Typography variant='body2' data-testid='uia-current-extension-fee-value'>
            <DollarsAndCentsText amount={schedule.existingFee} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CurrentExtensionSchedule;
