// @flow
import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import moment from 'moment';
import filesize from 'filesize';
import { truncate } from 'lodash';

import { type File } from '../types';

import { ImageFileIcon, PdfIcon, LoadingIcon, DeleteIcon } from './svg';
import { STANDARD_DATE_FORMAT } from '../constants';

const useStyles = makeStyles((theme) => ({
  fileIcon: {
    height: '25px !important',
    width: '17px !important',
  },
  list: {
    '& .MuiListItem-root:not(:last-child)': {
      borderTop: `1px solid ${theme.palette.grey['300']}`,
    },
  },
  listIcon: {
    minWidth: '40px !important',
  },
  listItem: {
    '& .MuiListItemText-primary': {
      fontSize: '12px !important',
      fontWeight: 500,
    },
  },
  deleteIcon: {
    height: 15,
    width: 15,
  },
  secondaryText: {
    color: theme.palette.grey.light,
  },
  secondaryTextError: {
    color: theme.palette.error.main,
  },
}));

type FileListItemProps = {
  file: File,
  onDelete: () => any,
};

const FileListItem = (props: FileListItemProps) => {
  const { file, onDelete } = props;
  const classes = useStyles();

  const renderFileTypeIcon = useCallback(
    (type) => {
      switch (type) {
        case 'application/pdf':
          return <PdfIcon viewBox='0 0 22 25' className={classes.fileIcon} />;
        default:
          return <ImageFileIcon viewBox='0 0 22 25' className={classes.fileIcon} />;
      }
    },
    [file.type]
  );

  let secondaryText;
  if (file.error) {
    secondaryText = (
      <Typography variant='subtitle1' className={classes.secondaryTextError}>
        {file.error}
      </Typography>
    );
  } else {
    secondaryText = (
      <Typography variant='subtitle1' className={classes.secondaryText}>
        {file.uploaded ? `${moment(file.timestamp).format(STANDARD_DATE_FORMAT)}, ${filesize(file.size, { spacer: '' })}` : 'Uploading...'}
      </Typography>
    );
  }

  return (
    <ListItem className={classes.listItem} disableGutters title={file.name} data-testid='uia-fileItem'>
      <ListItemIcon className={classes.listIcon}>{renderFileTypeIcon(file.type)}</ListItemIcon>
      <Hidden mdUp>
        <ListItemText primary={truncate(file.name, { length: 25 })} secondary={secondaryText} />
      </Hidden>
      <Hidden smDown>
        <ListItemText primary={truncate(file.name, { length: 80 })} secondary={secondaryText} />
      </Hidden>
      <ListItemSecondaryAction>
        {file.uploaded || file.error ? (
          <IconButton edge='end' aria-label='delete' onClick={onDelete} title='Delete' data-testid='uia-fileItem'>
            <DeleteIcon className={classes.deleteIcon} />
          </IconButton>
        ) : (
          <LoadingIcon />
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

type FileListProps = {
  files: Array<File>,
  onDelete: (index: number) => void,
};

const FileList = (props: FileListProps) => {
  const { files, onDelete } = props;
  const classes = useStyles();
  const sortedFiles = useMemo(() => {
    return files
      .map((file: File, index: number) => ({
        ...file,
        realIndex: index,
      }))
      .sort((a: File, b: File) => {
        if (!a.uploaded && b.uploaded) {
          return -1;
        }
        if (a.uploaded && !b.uploaded) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
  }, [files]);

  return (
    <List component='nav' className={classes.list} data-testid='uia-filelist'>
      {sortedFiles.map((file) => {
        return file && <FileListItem file={file} error={file.error} onDelete={() => onDelete(file.realIndex)} key={file.name} />;
      })}
    </List>
  );
};

export default FileList;
