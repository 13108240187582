import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SignMeUp from './Component';
import actions from './actions';
import startupConfigSelectors from '../../components/StartupConfig/selectors';

const mapStateToProps = createSelector(startupConfigSelectors.selectConfiguration, (configuration) => ({
  configuration,
}));

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SignMeUp);
