import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  FormattedNumber,
  Icon,
  IconButton,
  Stack,
  Text,
} from '@shiftfinancial/design-system';
import { Form } from '@shiftfinancial/react-form';
import { useOpenState } from '@shiftfinancial/react-hooks';
import { CloseIcon, InformationIcon, TransferIcon } from '@shiftfinancial/react-icons/system';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import fetchApi from '../../api/fetchiApi';
import { selectors as accountSetupCheckSelectors } from '../../components/AccountSetupCheck';
import { type AccountSetupStatus } from '../../components/AccountSetupCheck/reducer';
import { SHIFT_PHONE_NUMBER } from '../../constants';
import BsbText from '../BsbText';

const useStyles = makeStyles((theme) => ({
  actionButtonDesktop: {
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0.5),
  },
}));

type InvoicePayoffDialogueProps = {
  isMobile: boolean;
  amount: number;
  gcAccountId: string;
  contractId: string;
  supplierName: string;
  invoiceNumber: string;
  setRefreshInvoicesData: (refreshInvoicesData: boolean) => void;
};

type InvoicePayoffDialogueContentProps = {
  onClose: () => void;
  contractId: string;
};

type BankAccountDetails = {
  accountNumber: string;
  accountName: string;
  bsb: string;
};

function InvoicePayoffDialogContent({
  onClose,
  amount,
  gcAccountId,
  contractId,
  supplierName,
  invoiceNumber,
  setRefreshInvoicesData,
}: InvoicePayoffDialogueProps & InvoicePayoffDialogueContentProps) {
  const [bankAccountDetails, setBankAccountDetails] = useState<BankAccountDetails | null>();
  const { enqueueSnackbar } = useSnackbar();
  const bankAccountSetup: AccountSetupStatus | null = useSelector(accountSetupCheckSelectors.selectBankAccountSetup);

  const showSuccessNotification = () =>
    enqueueSnackbar('Repayment request received. Allow 3 business days for the direct debit to be processed and balances to update.', {
      variant: 'success',
      key: 'invoicePayoffSuccess',
      persist: true,
    });

  const showFailureNotification = () =>
    enqueueSnackbar('Repayment could not be processed. If this persists, please call us on ' + SHIFT_PHONE_NUMBER + '.', {
      variant: 'error',
      key: 'invoicePayoffFailure',
      persist: true,
    });

  //run once on initial page render:
  useEffect(() => {
    if (bankAccountSetup && bankAccountSetup.data && bankAccountSetup.data.length) {
      const shiftBankAccount = bankAccountSetup.data.find((x: { isShiftBankAccount: boolean }) => x.isShiftBankAccount);
      if (shiftBankAccount) {
        setBankAccountDetails({
          accountNumber: shiftBankAccount.bankAccountNumber,
          accountName: shiftBankAccount.bankAccountName,
          bsb: shiftBankAccount.bsb,
        });
      }
    } else {
      enqueueSnackbar('Invalid bank account', {
        variant: 'error',
        key: 'makeRepaymentBankAccountFailed',
      });
    }
  }, [bankAccountSetup, enqueueSnackbar]);

  const handlePayNow = async (gcAccountId: string, contractId: string, amount: number) => {
    window.dispatchEvent(new Event('fetchStart'));

    await fetchApi
      .payInvoice(gcAccountId, contractId, amount)
      .then((response) => {
        if (!response.ok) {
          showFailureNotification();
        } else {
          setRefreshInvoicesData(true);
          showSuccessNotification();
          onClose();
        }
      })
      .catch((error) => {
        showFailureNotification();
      })
      .finally(() => {
        window.dispatchEvent(new Event('fetchEnd'));
      });
  };

  return (
    <Form onSubmit={() => handlePayNow(gcAccountId, contractId, amount)}>
      {({ formState: { isSubmitting } }) => {
        return (
          <>
            <DialogHeader>
              <Stack orientation='horizontal' justifyContent='space-between'>
                <DialogTitle>Repay outstanding balance</DialogTitle>
                <IconButton type='button' data-testid='uia-closeButton' onClick={onClose}>
                  <Icon>
                    <CloseIcon />
                  </Icon>
                </IconButton>
              </Stack>
            </DialogHeader>
            <DialogBody>
              <Box sx={{ borderRadius: 'standard' }}>
                <Stack orientation='vertical' alignItems='stretch' gap='x6'>
                  <Stack orientation='vertical' alignItems='stretch' gap='x2'>
                    <Text>Repayment amount</Text>
                    <Box
                      asChild
                      sx={{
                        backgroundColor: 'neutral200',
                        width: '1/2',
                        height: 'x10',
                        borderRadius: 'standard',
                        display: 'inline-flex',
                        alignItems: 'center',
                        px: 'x4',
                      }}
                    >
                      <Text asChild fontWeight='medium' fontSize='large' data-testid='uia-repaymentAmount'>
                        <FormattedNumber value={amount} options={{ style: 'currency', currency: 'AUD', currencyDisplay: 'narrowSymbol' }} />
                      </Text>
                    </Box>
                  </Stack>
                  <Stack orientation='vertical' alignItems='stretch' gap='x2'>
                    <Text>Invoice details</Text>
                    <Text fontWeight='medium' fontSize='large' data-testid='uia-supplierName'>
                      {supplierName}
                    </Text>
                    <Text color='secondary'>
                      <Stack>
                        <Text data-testid='uia-invoiceNumber'>{invoiceNumber}</Text>
                      </Stack>
                    </Text>
                  </Stack>
                  <Stack orientation='vertical' alignItems='stretch' gap='x2'>
                    <Text>Pay from</Text>
                    <Text fontWeight='medium' fontSize='large' data-testid='uia-bankAccountName'>
                      {bankAccountDetails?.accountName}
                    </Text>
                    <Text color='secondary'>
                      <Stack>
                        <Text data-testid='uia-bankAccountBsb'>
                          <BsbText value={bankAccountDetails?.bsb ?? ''} />
                        </Text>
                        <Text data-testid='uia-bankAccountNumber'>{bankAccountDetails?.accountNumber}</Text>
                      </Stack>
                    </Text>
                  </Stack>
                  <Stack orientation='vertical' alignItems='stretch' gap='x2'>
                    <Stack>
                      <Icon size='large' color='info'>
                        <InformationIcon />
                      </Icon>
                      <Text>Allow 3 business days for the direct debit to be processed and balances to update.</Text>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </DialogBody>
            <DialogActions justifyContent='space-between'>
              <Button type='submit' appearance='primary' disabled={isSubmitting}>
                Make repayment
              </Button>
              <Button type='button' appearance='neutral' onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </>
        );
      }}
    </Form>
  );
}

export function InvoicePayoffDialogue(props: InvoicePayoffDialogueProps) {
  const classes = useStyles();
  const dialogOpenState = useOpenState();
  const { isMobile } = props;

  const onDialogOpen = () => {
    dialogOpenState.open();
  };

  return (
    <Dialog modal open={dialogOpenState.isOpen}>
      <DialogTrigger>
        <Button
          appearance='text'
          className={isMobile ? '' : classes.actionButtonDesktop}
          startIcon={
            isMobile ? (
              <Icon size='xsmall'>
                <TransferIcon />
              </Icon>
            ) : undefined
          }
          data-testid='uia-repayInvoiceLink'
          onClick={onDialogOpen}
        >
          Repay
        </Button>
      </DialogTrigger>
      <DialogContent size='small'>
        <InvoicePayoffDialogContent {...props} onClose={dialogOpenState.close} />
      </DialogContent>
    </Dialog>
  );
}
