export const ACTION_PREFIX = 'LOGIN';
export const PARTITION = 'login';

export const actionTypes = {
  GET_PIN_BY_MOBILE_NUMBER_PENDING: 'GET_PIN_BY_MOBILE_NUMBER_PENDING',
  GET_PIN_BY_MOBILE_NUMBER_FULFILLED: 'GET_PIN_BY_MOBILE_NUMBER_FULFILLED',
  GET_PIN_BY_MOBILE_NUMBER_REJECTED: 'GET_PIN_BY_MOBILE_NUMBER_REJECTED',
  GET_TOKEN_BY_PIN_PENDING: 'GET_TOKEN_BY_PIN_PENDING',
  GET_TOKEN_BY_PIN_FULFILLED: 'GET_TOKEN_BY_PIN_FULFILLED',
  GET_TOKEN_BY_PIN_REJECTED: 'GET_TOKEN_BY_PIN_REJECTED',
  SET_LOGIN_STEP: 'SET_LOGIN_STEP',
  SET_INPUT_MOBILE_NUMBER_VALUE: 'SET_INPUT_MOBILE_NUMBER_VALUE',
};
