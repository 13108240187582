// @flow
import * as React from 'react';
import { Box, Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, Zoom, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onConfirm: (text: ?string) => void,
  title: string,
  subTitle?: string,
  contentText: any,
  showInputField?: boolean,
  inputFieldPlaceHolder?: string,
  inputFieldMaxLength?: number,
  showChildren?: boolean,
  children?: React.Node,
  overrideActions?: boolean,
  actions?: React.Node,
  id: string,
  titleVariant?: string,
  maxWidth?: any,
  rightAlignActions?: boolean,
};

const ConfirmationDialog = (props: Props) => {
  const {
    isOpen,
    onClose,
    onConfirm,
    title,
    subTitle,
    contentText,
    showChildren,
    children,
    overrideActions,
    actions,
    id,
    titleVariant,
    maxWidth,
    rightAlignActions,
  } = props;

  const useStyles = makeStyles((theme) => ({
    closeButton: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: theme.palette.grey[500],
    },
    root: {
      display: 'flex',
      justifyContent: rightAlignActions ? 'flex-start' : 'flex-end',
    },
  }));

  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose()}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
      disableBackdropClick
      TransitionComponent={Zoom}
      data-testid={`uia-${id}-dialog`}
      maxWidth={maxWidth ? maxWidth : 'xs'}
      fullWidth={maxWidth !== undefined}
    >
      <DialogTitle id={`${id}-title`} disableTypography>
        <Typography variant={titleVariant ? titleVariant : 'h5'}>{title}</Typography>
        {subTitle ? <Box style={{ display: 'flex', paddingTop: '2%' }}>
            <Typography variant='body'>{subTitle}</Typography>
          </Box> : null}
        {onClose ? (
          <IconButton aria-label='close' onClick={onClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant='body1'>{contentText}</Typography>
        {showChildren ? children : null}
      </DialogContent>
      {(!overrideActions || actions) ? <DialogActions
          classes={{
            root: classes.root,
          }}
        >
          {!overrideActions && (
            <>
              <Button onClick={() => onClose()} color='primary' variant='outlined' data-testid='uia-noButton'>
                No
              </Button>
              <Button onClick={() => onConfirm()} color='primary' variant='contained' autoFocus data-testid='uia-yesButton'>
                Yes
              </Button>
            </>
          )}
          {overrideActions ? actions : null}
        </DialogActions> : null}
    </Dialog>
  );
};

export default ConfirmationDialog;
