import { required, length, format } from 'redux-form-validators';

export default (name, validation, optional) => {
  let validator = [];

  if (!optional) {
    validator = [...validator, required({ message: `${name} is required` })];
  }

  if (validation.chars && validation.chars !== '*') {
    const pattern = `^[${validation.chars}]+$`;
    validator = [...validator, format({ with: pattern, message: 'does not match the required format' })];
  }

  if (validation.minLength && validation.minLength > 0) {
    validator = [...validator, length({ min: validation.minLength })];
  }

  if (validation.maxLength) {
    validator = [...validator, length({ max: validation.maxLength })];
  }

  return validator;
};
