// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';

const { shareInvoicePending, shareInvoiceFulfilled, shareInvoiceRejected, getAccountPending, getAccountFulfilled, getAccountRejected } =
  createActions(
    actionTypes.SHARE_INVOICE_PENDING,
    actionTypes.SHARE_INVOICE_FULFILLED,
    actionTypes.SHARE_INVOICE_REJECTED,
    actionTypes.GET_ACCOUNT_PENDING,
    actionTypes.GET_ACCOUNT_FULFILLED,
    actionTypes.GET_ACCOUNT_REJECTED,
    getActionOptions(ACTION_PREFIX)
  );

const shareInvoice =
  (
    gcAccountId: string,
    email: string,
    loanAmount?: number,
    isToDirector: boolean,
    invoiceNumber: string,
    supplierName: string,
    effectiveDate: string
  ) =>
  (dispatch: Dispatch<any>) => {
    dispatch(shareInvoicePending());

    return shiftPaymentsApi
      .shareInvoice(gcAccountId, email, loanAmount, isToDirector, invoiceNumber, supplierName, effectiveDate)
      .then((response) => {
        return dispatch(shareInvoiceFulfilled(response));
      })
      .catch((error) => {
        return dispatch(shareInvoiceRejected(error));
      });
  };

const getAccount = (gcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(getAccountPending());

  return shiftPaymentsApi
    .getAccount(gcAccountId)
    .then((response) => {
      return dispatch(getAccountFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getAccountRejected(error));
    });
};

export default {
  shareInvoice,
  getAccount,
};
