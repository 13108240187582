// @flow
import baseApi from './baseApi';
import startupConfig from '../lib/startupConfig';

const searchAbn = (search: string) => {
  const config = startupConfig.get();
  const baseUrl = config ? config.abnSearchServiceUrl || '' : '';
  return baseApi.get({ url: `${baseUrl}/api/v1/business/${encodeURIComponent(search)}`, sendAuthToken: false });
};

export default {
  searchAbn,
};
