// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';

export const { getInvitationDetailsPending, getInvitationDetailsFulfilled, getInvitationDetailsRejected } = createActions(
  actionTypes.GET_INVITATION_DETAILS_PENDING,
  actionTypes.GET_INVITATION_DETAILS_FULFILLED,
  actionTypes.GET_INVITATION_DETAILS_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getInvitationDetails =
  (senderGcAccountId: string, senderGcContactId: string, recipientGcAccountId: string, recipientGcContactId: string) => (dispatch: Dispatch<any>) => {
    dispatch(getInvitationDetailsPending());

    if (senderGcAccountId && !senderGcContactId && !recipientGcAccountId && !recipientGcContactId) {
      return shiftPaymentsApi
        .getAccountName(senderGcAccountId)
        .then((response) => {
          return dispatch(getInvitationDetailsFulfilled(response));
        })
        .catch((error) => {
          return dispatch(getInvitationDetailsRejected(error));
        });
    }

    return shiftPaymentsApi
      .getInvitationSenderAndRecipient(senderGcAccountId, senderGcContactId, recipientGcAccountId, recipientGcContactId)
      .then((response) => {
        return dispatch(getInvitationDetailsFulfilled(response));
      })
      .catch((error) => {
        return dispatch(getInvitationDetailsRejected(error));
      });
  };

export default {
  getInvitationDetails,
};
