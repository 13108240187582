import { handleActions } from 'redux-actions';
import { PARTITION, actionTypes, ACTION_PREFIX } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';

const initialState = {
  bank: {},
};

const reducer = handleActions(
  {
    [actionTypes.SET_BANK]: (state, { payload }) => ({
      ...state,
      bank: payload,
    }),
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
