// @flow
import { handleActions } from 'redux-actions';

import { actionTypes, PARTITION, ACTION_PREFIX } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';

export type ReduxState = {
  totalPendingInvoices: number,
};

const initialState: ReduxState = {
  totalPendingInvoices: 0,
};

const reducer = handleActions<ReduxState>(
  {
    [actionTypes.GET_PENDING_INVOICES_FULFILLED]: (state, { payload }) => ({
      ...state,
      totalPendingInvoices: payload.data.totalCount,
    }),
    [actionTypes.GET_PENDING_INVOICES_REJECTED]: (state) => ({
      ...state,
      totalPendingInvoices: 0,
    }),
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
