// @flow
import React from 'react';
import NumberFormat from 'react-number-format';

type Props = {
  amount: number,
  decimalScale?: number,
};

const DollarsAndCentsText = (props: Props) => {
  const { amount, decimalScale = 2, ...other } = props;

  return (
    <NumberFormat
      value={amount}
      displayType='text'
      thousandSeparator
      prefix='$'
      decimalScale={decimalScale}
      fixedDecimalScale
      // $FlowFixMe
      data-testid={other['data-testid']}
    />
  );
};

export default DollarsAndCentsText;
