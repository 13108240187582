// @flow
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { withFormik, Form, Field } from 'formik';
import { withRouter } from 'react-router';
import { Grid, Paper, Button, Box, Typography, Hidden } from '@material-ui/core';
import type { FormikBag } from 'formik';
import type { History, Location } from 'history';
import { withSnackbar } from 'notistack';
import withWidth from '@material-ui/core/withWidth';
import InputAdornment from '@material-ui/core/InputAdornment';

import BasicPageLayout from '../../../components/BasicPageLayout';
import FormItem from '../../../components/FormItem';
import { formSchema } from './validations';
import { FormikButtonSelectField, FormikNumberFormatInputField } from '../../../components/formik';
import { handleError } from '../../../lib/apiHelpers';
import { StixWithDrawerIcon, CapitalIcon, BusinessIcon, GrowthIcon } from '../../../components/svg';
import BulletPoint from './BulletPoint';
import { isMobileResolution } from '../../../lib/materialUiUtils';

const useStyles = makeStyles((theme) => ({
  info: {
    backgroundColor: theme.palette.common.lightGreen,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2.5),
      '& h2': {
        textAlign: 'center',
        margin: theme.spacing(0, 5, 4),
      },
      '& h6': {
        margin: theme.spacing(1, 3, 2),
        textAlign: 'center',
      },
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(7, 7, 5),
      '& h2': {
        margin: theme.spacing(0, 0, 5),
      },
      '& h6': {
        margin: theme.spacing(1, 0, 0),
      },
    },
  },
  stixIcon: {
    height: '320px !important',
    width: '90% !important',
  },
  fields: {
    borderTop: `1px solid ${theme.palette.grey['300']}`,
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(2),
      '& h2': {
        margin: theme.spacing(1, 0),
      },
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 5),
    },
  },
  footerButtons: {
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    fontWeight: 'normal',
    [theme.breakpoints.up('xs')]: {
      width: '50%',
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      width: 90,
      marginRight: theme.spacing(1),
    },
  },
  submitButton: {
    [theme.breakpoints.up('xs')]: {
      width: '50%',
      marginLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      width: 150,
    },
  },
  capitalIcon: {
    width: 42,
    height: 57,
  },
  businessIcon: {
    width: 53,
    height: 53,
  },
  growthIcon: {
    width: 46,
    height: 56,
  },
}));

type FormValues = {
  customerBase: string,
  terms: string,
  avgTransactionSize: ?number,
};

type Props = {
  width: string,
  gcAccountId: string,
  requestAccess: (gcAccountId: string, customerBase: string, terms: string, avgTransactionSize: number) => Promise<any>,
};

type WithHOCProps = {
  ...Props,
  isSubmitting: boolean,
  values: FormValues,
  resetForm: () => void,
};

const customerBaseOptions = [
  {
    id: 'Other businesses',
    text: 'Other businesses',
  },
  {
    id: 'Consumers',
    text: 'Consumers',
  },
  {
    id: 'Both',
    text: 'Both',
  },
];

const termsOptions = [
  {
    id: 'COD',
    text: 'COD',
  },
  {
    id: '14 days',
    text: '14 days',
  },
  {
    id: '30 days',
    text: '30 days',
  },
  {
    id: '30 EOM',
    text: '30 EOM',
  },
  {
    id: 'Longer',
    text: 'Longer',
  },
];

const RequestAccess = (props: WithHOCProps) => {
  const { width, isSubmitting, values, resetForm } = props;
  const classes = useStyles();
  const isMobile = isMobileResolution(width);

  const handleCancel = () => {
    resetForm();
  };

  return (
    <BasicPageLayout title='Request Access' noHeader noMargin={!isMobile}>
      <Form noValidate autoComplete='off'>
        <Paper>
          <Box className={classes.info}>
            <Grid container>
              <Hidden xsDown>
                <Grid item xs={12} sm={3}>
                  <StixWithDrawerIcon className={classes.stixIcon} />
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={9}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='h2' component='h2'>
                      Streamline your trade credit by becoming a Shift Supplier today!
                    </Typography>
                  </Grid>
                  <BulletPoint
                    icon={<CapitalIcon className={classes.capitalIcon} />}
                    title='Release capital'
                    description='With traditional trade credit you get paid on the terms you provide to customers.'
                    summaryText='With Shift you get paid today'
                  />
                  <BulletPoint
                    icon={<BusinessIcon className={classes.businessIcon} />}
                    title='Simplify your business'
                    description='With traditional trade credit you need to dedicate people, systems and effort managing your trade credit program.'
                    summaryText='With Shift you are free to run your own business'
                  />
                  <BulletPoint
                    icon={<GrowthIcon className={classes.growthIcon} />}
                    title='Grow your business'
                    description='With traditional trade credit your customers are regularly asking for better payment terms.'
                    summaryText='With Shift your customers get the trade terms they want'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'>Whether you&apos;re a supplier or a buyer, with Shift Trade, you&apos;re in control.</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.fields}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h2' component='h2'>
                  To get started tell us a bit about your business
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormItem label='Who do you sell to?'>
                  <Field
                    id='customerBase'
                    name='customerBase'
                    options={customerBaseOptions}
                    component={FormikButtonSelectField}
                    value={values.customerBase}
                    data-testid='uia-customerBase'
                    dropdown={isMobile}
                    inputProps={{
                      name: 'customerBase',
                    }}
                  />
                </FormItem>
              </Grid>
              <Grid item xs={12}>
                <FormItem label='What terms do you give?'>
                  <Field
                    id='terms'
                    name='terms'
                    options={termsOptions}
                    component={FormikButtonSelectField}
                    value={values.terms}
                    data-testid='uia-terms'
                    dropdown={isMobile}
                    inputProps={{
                      name: 'terms',
                    }}
                  />
                </FormItem>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormItem label='Average transaction size'>
                  <Field
                    component={FormikNumberFormatInputField}
                    variant='outlined'
                    fullWidth
                    id='avgTransactionSize'
                    name='avgTransactionSize'
                    data-testid='uia-avgTransactionSize'
                    value={values.avgTransactionSize}
                    thousandSeparator
                    allowNegative={false}
                    startAdornment={<InputAdornment position='start'>AUD&nbsp;$</InputAdornment>}
                    isAllowed={(x) => x.value !== '0'}
                  />
                </FormItem>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Box display='flex' justifyContent={isMobile ? 'inherit' : 'flex-end'} className={classes.footerButtons}>
          <Button className={classes.cancelButton} color='primary' disabled={isSubmitting} onClick={handleCancel} data-testid='uia-cancelButton'>
            Cancel
          </Button>
          <Button
            className={classes.submitButton}
            variant='contained'
            color='primary'
            type='submit'
            disabled={isSubmitting}
            data-testid='uia-requestAccessButton'
          >
            Request access
          </Button>
        </Box>
      </Form>
    </BasicPageLayout>
  );
};

RequestAccess.defaultProps = {
  customerSearchDelay: 500,
};

type WrapperProps = {
  ...Props,
  setError: (error: ?string) => void,
  fileGroupIdForTesting: string,
};

type WithHOCWrapperProps = {
  ...WrapperProps,
  history: History<Location>,
  closeSnackbar: () => void,
  enqueueSnackbar: (snack: string, options?: Object) => void,
};

export default withRouter<WrapperProps>(
  withSnackbar(
    withFormik({
      mapPropsToValues: (): FormValues => ({
        customerBase: '',
        terms: '',
        avgTransactionSize: null,
      }),
      validateOnBlur: false,
      validationSchema: formSchema,
      handleSubmit: (values: FormValues, formikBag: FormikBag<WithHOCWrapperProps, FormValues>) => {
        const { props } = formikBag;
        const { customerBase, terms, avgTransactionSize } = values;

        props.closeSnackbar();

        props.requestAccess(props.gcAccountId, customerBase, terms, Number(avgTransactionSize)).then((response) => {
          formikBag.setSubmitting(false);

          if (response.error) {
            handleError(response, props, formikBag);
            return;
          }

          if (response.payload && response.payload.status === 200) {
            props.enqueueSnackbar('Supplier access has been requested', {
              variant: 'success',
              key: 'requestAccess',
            });
            props.history.push({
              pathname: '/buyer/dashboard',
            });
          }
        });
      },
      displayName: 'RequestAccess',
    })(withWidth()(RequestAccess))
  )
);
