// @flow
import * as React from 'react';
import { useHistory } from 'react-router';

import { defineAbilitiesBasedOnRoles, AbilityContext, type AbilityDef, type RolePermissionMap } from '../../lib/ability';

type Props = {
  children: (ability: AbilityDef) => React.Node,
  getRolePermissionFilteredByHierarchy: (gcAccountId: string) => Promise<any>,
  activeAccountRoles: Array<string>,
  rolePermissionMap: Array<RolePermissionMap>,
  gcAccountId: string,
};

const AbilityProvider = (props: Props) => {
  const { children, getRolePermissionFilteredByHierarchy, activeAccountRoles, rolePermissionMap, gcAccountId } = props;
  const [ability, setAbility] = React.useState<any>(null);
  const history = useHistory();

  React.useEffect(() => {
    if (!gcAccountId) {
      return;
    }
    getRolePermissionFilteredByHierarchy(gcAccountId).then((response) => {
      if (response.error) {
        history.push('/500');
      }
    });
  }, [gcAccountId]);

  React.useEffect(() => {
    if (!activeAccountRoles || !activeAccountRoles.length || !rolePermissionMap || !rolePermissionMap.length) {
      return;
    }

    const abilityBasedOnRoles = defineAbilitiesBasedOnRoles(activeAccountRoles, rolePermissionMap);
    setAbility(abilityBasedOnRoles);
  }, [activeAccountRoles, rolePermissionMap]);

  if (!ability) {
    return null;
  }

  return <AbilityContext.Provider value={ability}>{children(ability)}</AbilityContext.Provider>;
};

export default AbilityProvider;
