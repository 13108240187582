// @flow
import { PARTITION } from './constants';
import type { ReduxState } from './reducer';

const selectPartition = (state: any): ReduxState => state[PARTITION];

const selectTotalSupplierRequests = (state: any) => selectPartition(state).totalSupplierRequests;
export default {
  selectPartition,
  selectTotalSupplierRequests,
};
