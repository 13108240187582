import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { reduxForm, getFormSubmitErrors, getFormValues, getFormSyncErrors, getFormAsyncErrors } from 'redux-form';
import { createSelector } from '@reduxjs/toolkit';
import { bindActionCreators } from 'redux';
import Component from './Component';
import { FORM_NAME } from './constants';
import formActions from './actions';
import { actions as mfaActions, selectors as mfaSelectors } from '../MultiFactorAuthenticationForm';
import selectors from './selectors';
import initialData from '../InitialData/selectors';
import accountSelectMenuSelectors from '../AccountSelectMenu/selectors';
import { selectActiveProfileModeBasedPath } from '../../lib/profileHelper';
import { traceInfo, traceError } from '../../lib/telemetryUtils';
import { LBFCID_FALLBACK } from '../../constants';

const mapStateToProps = createSelector(
  accountSelectMenuSelectors.selectActiveAccountIndex,
  initialData.selectAccounts,
  mfaSelectors.selectBankLoginAccountResult,
  selectors.selectBankLoginConfiguration,
  getFormSubmitErrors(FORM_NAME),
  getFormValues(FORM_NAME),
  getFormSyncErrors(FORM_NAME),
  getFormAsyncErrors(FORM_NAME),
  (activeAccountIndex, accounts, bankLoginAccountResult, bankLoginConfiguration, formSubmitErrors, formValues, formSyncErrors, formAsyncErrors) => {
    const selectedAccount = accounts ? accounts[activeAccountIndex] : null;
    const externalReferenceId = initialData.selectJwtValues ? initialData.selectJwtValues.externalReferenceId : null;
    const bankCredentials = bankLoginConfiguration ? bankLoginConfiguration.credentials : null;
    const bankName = bankLoginConfiguration ? bankLoginConfiguration.name : null;
    const bankImageLink = bankLoginConfiguration ? bankLoginConfiguration.imageLink : null;

    return {
      bankLoginAccountResult,
      selectedAccount,
      bankLoginConfiguration,
      externalReferenceId,
      bankCredentials,
      bankName,
      bankImageLink,
      formSubmitErrors,
      formValues,
      formSyncErrors,
      formAsyncErrors,
    };
  }
);

const actions = {
  ...formActions,
  ...mfaActions,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

const displayError = (props, message) => {
  props.enqueueSnackbar(message, {
    variant: 'error',
    persist: true,
  });
};

const Form = reduxForm({
  form: FORM_NAME,
  onSubmit: (values, dispatch, props) => {
    props.closeSnackbar();
    props.loginBankLinkUserPending();
    const loginBody = {
      getCapitalContactId: props.externalReferenceId,
      getcapitalAccountId: props.selectedAccount ? props.selectedAccount.gcAccountId : null,
      bankId: props.bankId,
      credentials: { ...values },
    };
    return actions.bankLogin(loginBody);
  },
  onSubmitSuccess: (result, dispatch, props) => {
    // added this trace to help us end all the pain and suffering when connecting bank accounts once and for all
    if (result && result.data) {
      traceInfo(`Bank login response - ${JSON.stringify(result.data)}`);
    } else {
      traceError('No Bank login response');
    }

    if (result.data && result.data.isSuccess) {
      if (result.data.result.isVerificationCodeRequired) {
        props.loginBankLinkUserFulfilled();
        props.setBankLoginAccountResult(result);
      } else {
        props.loginBankLinkUserFulfilled(result);
        props.history.push(
          selectActiveProfileModeBasedPath(
            `/settings/add-bank/account-select/${result.data.result.bankId}/${result.data.result.lbfcId ?? LBFCID_FALLBACK}`
          )
        );
      }
    } else {
      displayError(props, 'Please confirm your username and password before trying again or call us on 1300 249 649 for assistance.');
      props.loginBankLinkUserRejected();
    }
  },
  onSubmitFail: (errors, dispatch, submitError, props) => {
    if (submitError) {
      props.loginBankLinkUserRejected();
    }
    if (props.bankCredentials.length === 0) {
      displayError(props, 'Bank not supported. Please contact us on 1300 249 649');
    } else {
      displayError(props, 'Please confirm your username and password before trying again or call us on 1300 249 649 for assistance.');
    }
  },
})(Component);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(Form)));
