import { createAction } from 'redux-actions';
import { ACTION_PREFIX } from './constants';

export const actionTypes = {
  SET_BANK: `${ACTION_PREFIX}/SET_BANK`,
};

const setBank = createAction(actionTypes.SET_BANK, (values) => values);

export default {
  setBank,
};
