// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

type Props = {
  fill: string,
};

const DeleteIcon = (props: Props) => (
  <SvgIcon style={{ alignSelf: 'baseline' }} viewBox='0 0 13 13' {...(props: any)}>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-1117.000000, -1038.000000)' fill={props.fill || '#ED535B'} fillRule='nonzero'>
        <g transform='translate(255.000000, 252.000000)'>
          <g transform='translate(327.000000, 706.000000)'>
            <g transform='translate(535.000000, 80.000000)'>
              <path d='M4.7304,10.3572 C5.0076,10.3572 5.2344,10.1304 5.2344,9.8532 L5.2344,5.6232 C5.2344,5.3424 5.0076,5.1192 4.7304,5.1192 C4.4532,5.1192 4.2264,5.346 4.2264,5.6232 L4.2264,9.8532 C4.2264,10.1304 4.4532,10.3572 4.7304,10.3572 Z' />
              <path d='M7.3944,10.3572 C7.6716,10.3572 7.8984,10.1304 7.8984,9.8532 L7.8984,5.6232 C7.8984,5.3424 7.6716,5.1192 7.3944,5.1192 C7.1172,5.1192 6.8904,5.346 6.8904,5.6232 L6.8904,9.8532 C6.8904,10.1304 7.1172,10.3572 7.3944,10.3572 Z' />
              <path d='M11.6244,1.764 L8.3304,1.764 L8.3304,1.224 C8.3304,0.5508 7.7976,-2.03392858e-13 7.1244,-2.03392858e-13 L5.004,-2.03392858e-13 C4.3308,-2.03392858e-13 3.798,0.5508 3.798,1.224 L3.798,1.764 L0.504,1.764 C0.2268,1.764 2.52597943e-12,1.9908 2.52597943e-12,2.268 C2.52597943e-12,2.5452 0.2268,2.772 0.504,2.772 L1.5624,2.772 L1.5624,12.024 C1.5624,12.6972 2.1024,13.248 2.7756,13.248 L9.3456,13.248 C10.0188,13.248 10.5588,12.6972 10.5588,12.024 L10.5588,2.772 L11.6208,2.772 C11.898,2.772 12.1248,2.5452 12.1248,2.268 C12.1248,1.9908 11.9052,1.764 11.6244,1.764 Z M4.7988,1.224 C4.8024,1.1052 4.8816,1.008 5.0004,1.008 L7.1208,1.008 C7.2396,1.008 7.3188,1.1052 7.3188,1.224 L7.3188,1.764 L4.7988,1.764 L4.7988,1.224 L4.7988,1.224 Z M9.5544,12.024 C9.5544,12.1428 9.468,12.24 9.3492,12.24 L2.7756,12.24 C2.6568,12.24 2.5704,12.1428 2.5704,12.024 L2.5704,2.772 L9.5544,2.772 L9.5544,12.024 Z' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default DeleteIcon;
