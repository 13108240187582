// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  theme: any,
};

const ApiIcon = withTheme((props: Props) => (
  <SvgIcon style={{ height: '3rem', width: '4rem', backgroundColor: props.theme.palette.common.lightYellow }} viewBox='0 0 72 50' {...(props: any)}>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='api_icon' transform='translate(11.049073, 12.258542)' fill={props.theme.palette.common.orange}>
        <path
          d='M4.10400192,2.802096 C4.10400192,4.05563136 2.2236696,4.05563136 2.2236696,2.802096 C2.2236696,1.54856064 4.10400192,1.54856064 4.10400192,2.802096'
          id='Path'
        />
        <path
          d='M6.65546112,2.802096 C6.65546112,4.05563136 4.77398688,4.05563136 4.77398688,2.802096 C4.77398688,1.54856064 6.65546112,1.54856064 6.65546112,2.802096'
          id='Path'
        />
        <path
          d='M9.20604192,2.802096 C9.20604192,4.05563136 7.3257096,4.05563136 7.3257096,2.802096 C7.3257096,1.54856064 9.20604192,1.54856064 9.20604192,2.802096'
          id='Path'
        />
        <polygon id='Path' points='19.9526803 2.3447424 24.1581667 2.3447424 24.1581667 3.2597424 19.9526803 3.2597424' />
        <path
          d='M25.0034803,0 L1.83685152,0 C0.8235,0 0,0.8235 0,1.83685152 L0,17.5566979 C0,18.5689075 0.8235,19.3935494 1.83685152,19.3935494 L25.0034803,19.3935494 C26.0156899,19.3935494 26.8403318,18.5700494 26.8403318,17.5566979 L26.8403318,13.5374323 L26.8391879,10.2000979 L26.8391879,1.83714432 C26.8391879,0.8237928 26.0156879,0.0002928 25.0034783,0.0002928 L25.0034803,0 Z M25.9241899,7.6848288 L25.9241899,17.5565808 C25.9241899,18.0644131 25.5112834,18.4784323 25.0023384,18.4784323 L1.8368808,18.4784323 C1.32790656,18.4784323 0.91502928,18.0655258 0.91502928,17.5577227 L0.91502928,5.25895152 L25.9254197,5.25895152 L25.9241899,7.6848288 Z M25.9241899,4.3439808 L0.91497072,4.3439808 L0.91497072,1.8368808 C0.91497072,1.32904848 1.32787728,0.91502928 1.83682224,0.91502928 L25.003451,0.91502928 C25.5112834,0.91502928 25.9253026,1.32793584 25.9253026,1.8368808 L25.9241899,4.3439808 Z'
          id='Shape'
        />
        <polygon
          id='Path'
          points='11.4696787 14.7486288 8.45471712 11.732496 11.4696787 8.7187056 10.8223272 8.07135408 7.161156 11.7325253 10.8223272 15.3948677'
        />
        <polygon
          id='Path'
          points='16.0171555 15.3948384 19.6794979 11.732496 16.0171555 8.0713248 15.3709459 8.71867632 18.3847363 11.7324667 15.3709459 14.7485995'
        />
        <polygon id='Path' points='14.0120611 8.2601808 14.8858934 8.53010726 12.824845 15.2062401 11.9510126 14.9363136' />
      </g>
    </g>
  </SvgIcon>
));

export default ApiIcon;
