// @flow
import { handleActions } from 'redux-actions';
import { PARTITION, actionTypes, ACTION_PREFIX } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';

export type ReduxState = {
  step: number,
  mobileNumber: ?string,
  inputMobileNumberValue: ?string,
  codeExpiry: ?number,
};

const initialState: ReduxState = {
  step: 0,
  mobileNumber: null,
  inputMobileNumberValue: null,
  codeExpiry: null,
};

const reducer = handleActions(
  {
    [actionTypes.GET_PIN_BY_MOBILE_NUMBER_FULFILLED]: (state, { payload }) => ({
      ...state,
      step: 1,
      mobileNumber: payload.data.toMobile,
      codeExpiry: payload.data.validityInSeconds / 60,
    }),
    [`${actionTypes.SET_LOGIN_STEP}`]: (state, { payload }) => ({
      ...state,
      step: payload,
    }),
    [`${actionTypes.SET_INPUT_MOBILE_NUMBER_VALUE}`]: (state, { payload }) => ({
      ...state,
      inputMobileNumberValue: payload,
    }),
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
