import { handleActions } from 'redux-actions';
import { actionTypes, PARTITION } from './constants';

const initialPartitionState = {
  count: 0,
};

const reducers = handleActions(
  {
    [`${actionTypes.ADD_COUNT}`]: (state) => {
      return {
        ...state,
        count: state.count + 1,
      };
    },
    [`${actionTypes.SUBTRACT_COUNT}`]: (state) => {
      return {
        ...state,
        count: state.count === 0 ? state.count : state.count - 1,
      };
    },
  },
  initialPartitionState
);

export default {
  [PARTITION]: reducers,
};
