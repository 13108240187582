// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    borderRadius: 4,
    padding: theme.spacing(0.25, 0.75),
    margin: theme.spacing(0.5),
    fontSize: 10,
  },
}));

type Props = {
  label: string,
  badgeLabel?: ?string,
  showBadge: boolean,
};

const TabLabel = (props: Props) => {
  const { label, badgeLabel, showBadge } = props;
  const classes = useStyles();

  return (
    <div>
      <span>{label}</span>
      {showBadge ? <span className={classes.badge}>{badgeLabel}</span> : null}
    </div>
  );
};

export default TabLabel;
