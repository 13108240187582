/* eslint-disable react/display-name */
// @flow
import React, { useEffect, useState } from 'react';
import { FormControl, Select, makeStyles, Grid, Button, Typography, MenuItem } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { useSnackbar } from 'notistack';
import { useAbility } from '@casl/react';
import moment from 'moment';
import * as queryString from 'query-string';
import clsx from 'clsx';

import { type OrderBy, AuthorizationStatusValues } from '../../../api/shiftPaymentsApi';
import { getColumnIndex, enrichColumnsWithBodyRenders } from '../../../lib/dataGridUtils';
import StatusLabel from '../../../components/StatusLabel';
import styles from '../../../theme';
import DollarsAndCentsText from '../../../components/DollarsAndCentsText';
import AbnText from '../../../components/AbnText';
import PhoneNumberText from '../../../components/PhoneNumberText';
import DataGridPageLayout from '../../../components/DataGridPageLayout';
import { AdapterLink } from '../../../lib/materialUiUtils';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { defaultHandleError } from '../../../lib/apiHelpers';
import { AbilityContext, Can } from '../../../lib/ability';
import { Permission, STANDARD_DATE_FORMAT, DEFAULT_TERM_PERIOD } from '../../../constants';
import { formatSupplierTerm, formatAbnOrAcn } from '../../../lib/formatter';
import { useSupplierTermFeeLookup } from '../../ReferenceDataHooks/hooks';
import MoreActionsContextMenu from '../../../components/MoreActionsContextMenu';
import SettlementAccountWarning from '../../../components/SettlementAccountWarning';
import { type MoreActionsContextMenuItem } from '../../../types';
import { ACTION_SUCCEED, UPDATE_TERM_ACTION, CANCELPENDIN_ASSOCIATION_ACTION, DELETE_CUSTOMER_ACTION } from './constants';

const columns = [
  {
    name: 'abn',
    label: 'Business details',
    options: {
      sort: false,
    },
  },
  {
    name: 'accountId',
    options: {
      sort: false,
      display: false,
    },
  },
  {
    name: 'accountName',
    options: {
      sort: false,
      display: false,
    },
  },
  {
    name: 'dateOfInvite',
    label: 'Date invited',
    options: {
      sort: true,
    },
  },
  {
    name: 'contactFirstName',
    label: 'Contact',
    options: {
      sort: true,
    },
  },
  {
    name: 'contactLastName',
    options: {
      sort: false,
      display: false,
    },
  },
  {
    name: 'mobileNumber',
    label: 'Mobile',
    options: {
      sort: false,
    },
  },
  {
    name: 'defaultTermsInDays',
    label: 'Terms',
    options: {
      sort: true,
    },
  },
  {
    name: 'termPeriod',
    label: 'TermPeriod',
    options: {
      sort: false,
      display: false,
    },
  },
  {
    name: 'availableBalance',
    label: 'Available',
    options: {
      sort: false,
    },
  },
  {
    name: 'authorizationStatus',
    label: 'Auto-billing',
    options: {
      sort: true,
    },
  },
  {
    name: 'buyerStatus',
    label: 'Actions',
    options: {
      sort: true,
      setCellProps: () => ({ style: { minWidth: 170, maxWidth: 170 } }),
    },
  },
  {
    name: 'accountReadiness',
    options: {
      sort: false,
      display: false,
    },
  },
  {
    name: 'moreActions',
    label: ' ',
    options: {
      sort: false,
    },
  },
  {
    name: 'actions',
    options: {
      sort: false,
      display: false,
    },
  },
];

const abnIndex = getColumnIndex(columns, 'abn');
const accountNameIndex = getColumnIndex(columns, 'accountName');
const accountIdIndex = getColumnIndex(columns, 'accountId');
const contactLastNameIndex = getColumnIndex(columns, 'contactLastName');
const termIndex = getColumnIndex(columns, 'defaultTermsInDays');
const termPeriodIndex = getColumnIndex(columns, 'termPeriod');
const availableBalanceIndex = getColumnIndex(columns, 'availableBalance');
const buyerStatusIndex = getColumnIndex(columns, 'buyerStatus');
const authorizationStatusIndex = getColumnIndex(columns, 'authorizationStatus');
const dateOfInviteIndex = getColumnIndex(columns, 'dateOfInvite');
const accountReadinessIndex = getColumnIndex(columns, 'accountReadiness');
const actionsIndex = getColumnIndex(columns, 'actions');

const useStyles = makeStyles((theme) => ({
  header: {
    [theme.breakpoints.down('xs')]: {
      '& .MuiButton-root': {
        minWidth: 35,
        width: 35,
        height: 35,
      },
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  mobileColumnLabel: {
    fontSize: 12,
    marginTop: theme.spacing(2),
  },
  mobileColumnValue: {
    fontWeight: 500,
    fontSize: 14,
    marginTop: theme.spacing(0.5),
  },
  mobileRowAccountLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  mobileRowMoreActions: {
    display: 'flex',
    justifyContent: 'end',
  },
  processPaymentButton: {
    width: '100%',
  },
  cancelButton: {
    marginTop: theme.spacing(0.5),
    width: '100%',
    fontSize: 12,
  },
  formControl: {
    minWidth: '100%',
    '& .MuiSelect-select': {
      height: theme.spacing(1.8),
    },
    marginBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  contentText: {
    marginBottom: theme.spacing(1.5),
  },
  feePlaceholder: {
    width: '75px',
    backgroundColor: theme.palette.common.paleOrange,
    paddingTop: theme.spacing(0.3),
    paddingBottom: theme.spacing(0.3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  feeTitle: {
    fontSize: 12,
    color: theme.palette.secondary.contrastText,
  },
  feeValue: {
    fontSize: 14,
    color: theme.palette.secondary.contrastText,
    fontWeight: '500',
  },
  bold: {
    fontWeight: 'bold',
  },
  deleteConfirm: {
    backgroundColor: theme.palette.warning.deepRed,
    color: theme.palette.common.white,
  },
  animationHolderBlank: {
    marginBottom: theme.spacing(5),
  },
  animationHolder: {
    animationName: '$wobble',
    animationDuration: '1s',
    animationIterationCount: 1,
    marginBottom: theme.spacing(5),
  },
  '@keyframes wobble': {
    '0%': {
      webkitTransform: 'none',
      transform: 'none',
    },
    '15%': {
      webkitTransform: 'translate3d(-10%, 0, 0) rotate3d(0, 0, 1, 0deg);',
      transform: 'translate3d(-10%, 0, 0) rotate3d(0, 0, 1, 0deg);',
    },
    '30%': {
      webkitTransform: 'translate3d(15%, 0, 0) rotate3d(0, 0, 1, 0deg);',
      transform: 'translate3d(15%, 0, 0) rotate3d(0, 0, 1, 0deg);',
    },
    '45%': {
      webkitTransform: 'translate3d(-10%, 0, 0) rotate3d(0, 0, 1, 0deg);',
      transform: 'translate3d(-10%, 0, 0) rotate3d(0, 0, 1, 0deg);',
    },
    '60%': {
      webkitTransform: 'translate3d(15%, 0, 0) rotate3d(0, 0, 1, 0deg);',
      transform: 'translate3d(15%, 0, 0) rotate3d(0, 0, 1, 0deg);',
    },
    '75%': {
      webkitTransform: 'translate3d(-10%, 0, 0) rotate3d(0, 0, 1, 0deg);',
      transform: 'translate3d(-10%, 0, 0) rotate3d(0, 0, 1, 0deg);',
    },
    '100%': {
      webkitTransform: 'none',
      transform: 'none',
    },
  },
}));

type Props = {
  width: string,
  gcAccountId: string,
  isSettlementAccountSetupCompleted: boolean,
  getCustomers: (gcAccountId: string, search: ?string, skip: number, limit: number, orderBy: ?Array<OrderBy>) => Promise<any>,
  cancelRequest: (supplierGcAccountId: string, buyerAccountId: string) => Promise<any>,
  updateDefaultTerms: (supplierGcAccountId: string, buyerAccountId: string, term: number, termsPeriod: string) => Promise<any>,
  deleteSupplierBuyer: (supplierGcAccountId: string, buyerAccountId: string) => Promise<any>,
};

const Customers = (props: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const snackbar = useSnackbar();
  const ability = useAbility(AbilityContext);
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [buyerAccount, setBuyerAccount] = useState<{ accountId: string, accountName: string, abn: string }>({
    accountId: '',
    accountName: '',
    abn: '',
  });
  const { gcAccountId, getCustomers, cancelRequest, updateDefaultTerms, deleteSupplierBuyer } = props;
  const [selectedTerm, setSelectedTerm] = useState<{ term: number, termsPeriod: string }>({ term: 0, termsPeriod: DEFAULT_TERM_PERIOD });
  const [selectedTermFee, setSelectedTermFee] = useState(0);
  const [isTermChangeConfirmationOpen, setIsTermChangeConfirmationOpen] = useState(false);
  const [termUpdateAssociation, setTermUpdateAssociation] = useState({ supplerGcAccount: '', buyerGcAccountId: '' });
  const [isPrompt, setIsPrompt] = useState(false);

  const supplierTerms = useSupplierTermFeeLookup(gcAccountId);

  const queryStrings = queryString.parse(location.search);

  const setDefaultSelectedTerms = (term: number, termsPeriod: string) => {
    if (supplierTerms.length) {
      if (term && termsPeriod) {
        const terms = supplierTerms.find((x) => x.term === term && x.termPeriod.toLowerCase() === termsPeriod.toLowerCase());
        if (terms) {
          setSelectedTerm({ term: terms.term, termsPeriod: terms.termPeriod });
          setSelectedTermFee(terms.feeInPercentage);
        }
      }
    }
  };

  useEffect(() => {
    if (queryStrings) {
      const tu = queryStrings.tu ? queryStrings.tu.toString() : '';
      const ca = queryStrings.ca ? queryStrings.ca.toString() : '';
      const dc = queryStrings.dc ? queryStrings.dc.toString() : '';
      if (tu && tu === ACTION_SUCCEED) {
        history.push({
          path: history.location.pathname,
        });
        snackbar.enqueueSnackbar('Default terms successfully updated', {
          variant: 'success',
          key: 'update-default-terms',
        });
      }
      if (dc && dc === ACTION_SUCCEED) {
        history.push({
          path: history.location.pathname,
        });
        snackbar.enqueueSnackbar('Customer successfully deleted', {
          variant: 'success',
          key: 'delete-supplier-buyer',
        });
      }
      if (ca && ca === ACTION_SUCCEED) {
        history.push({
          path: history.location.pathname,
        });
        snackbar.enqueueSnackbar('Customer request successfully canceled', {
          variant: 'success',
          key: 'update-default-terms',
        });
      }
    }
  }, []);

  const getData = (skip: number, limit: number, orderBy: ?Array<OrderBy>, search: ?string) => getCustomers(gcAccountId, search, skip, limit, orderBy);

  const handleConfirmCancelRequest = (accountId: string, accountName: string, abn: string) => {
    snackbar.closeSnackbar();
    setBuyerAccount({ accountId, accountName, abn });
    setIsCancelConfirmationOpen(true);
  };

  const handleCloseCancelConfirmation = () => {
    setBuyerAccount({ accountId: '', accountName: '', abn: '' });
    setIsCancelConfirmationOpen(false);
  };

  const handleCancelRequest = () => {
    setIsCancelConfirmationOpen(false);
    cancelRequest(gcAccountId, buyerAccount.accountId).then((response) => {
      const { error, payload } = response;

      if (error) {
        defaultHandleError({ enqueueSnackbar: snackbar.enqueueSnackbar }, undefined);
        return;
      }

      if (payload && payload.status === 200) {
        history.push({
          path: history.location.pathname,
          search: `?ca=${ACTION_SUCCEED}`,
        });
        window.location.reload();
      }
    });
  };

  const handleDeleteConfimationRequest = (details) => {
    setBuyerAccount({ accountId: details.buyerAccountId, accountName: details.buyerAccountName, abn: details.buyerAccountAbn });
    setIsDeleteConfirmationOpen(true);
  };

  const handleCancelDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
  };

  const handleDeleteRequest = (supplierGcAccountId, buyerGcAccountId) => {
    setIsDeleteConfirmationOpen(false);
    deleteSupplierBuyer(supplierGcAccountId, buyerGcAccountId).then((response) => {
      const { error, payload } = response;
      if (error) {
        defaultHandleError({ enqueueSnackbar: snackbar.enqueueSnackbar }, undefined);
        return;
      }
      if (payload && payload.status === 200) {
        history.push({
          path: history.location.pathname,
          search: `?dc=${ACTION_SUCCEED}`,
        });
        window.location.reload();
      }
    });
  };

  const handleShowChangeTermDialog = ({ supplierGcAccountId, buyerAccountId, term, termsPeriod }) => {
    setDefaultSelectedTerms(term, termsPeriod);
    setTermUpdateAssociation({ supplerGcAccount: supplierGcAccountId, buyerGcAccountId: buyerAccountId });
    setIsTermChangeConfirmationOpen(true);
  };

  const handleCancelTermUpdate = () => {
    setTermUpdateAssociation({ supplerGcAccount: '', buyerGcAccountId: '' });
    setIsTermChangeConfirmationOpen(false);
    setSelectedTerm({ term: 0, termsPeriod: DEFAULT_TERM_PERIOD });
    getTermFees(0);
  };

  const handleTermUpdate = () => {
    const { supplerGcAccount, buyerGcAccountId } = termUpdateAssociation;
    updateDefaultTerms(supplerGcAccount, buyerGcAccountId, selectedTerm.term, selectedTerm.termsPeriod).then((response) => {
      const { error, payload } = response;
      if (error) {
        defaultHandleError({ enqueueSnackbar: snackbar.enqueueSnackbar }, undefined);
        return;
      }
      if (payload && payload.status === 200) {
        history.push({
          path: history.location.pathname,
          search: `?tu=${ACTION_SUCCEED}`,
        });
        window.location.reload();
      }
    });
  };

  const handleCancelAssociationRequest = ({ buyerAccountId, buyerAccountName, buyerAccountAbn }) => {
    handleConfirmCancelRequest(buyerAccountId, buyerAccountName, buyerAccountAbn);
  };

  const moreActionMenuoptions: Array<MoreActionsContextMenuItem> = [
    {
      actionText: 'Change my terms',
      actionType: UPDATE_TERM_ACTION,
      permissionTo: Permission.Actions.Create,
      subject: Permission.Supplier.Buyer,
      action: handleShowChangeTermDialog,
    },
    {
      actionText: 'Delete',
      actionType: DELETE_CUSTOMER_ACTION,
      permissionTo: Permission.Actions.Create,
      subject: Permission.Supplier.Buyer,
      action: handleDeleteConfimationRequest,
    },
    {
      actionText: 'Cancel',
      actionType: CANCELPENDIN_ASSOCIATION_ACTION,
      permissionTo: Permission.Actions.Create,
      subject: Permission.Supplier.Buyer,
      action: handleCancelAssociationRequest,
    },
  ];

  const desktopColumnCustomBodyRenders = {
    abn: (value, { rowData }) => (
      <React.Fragment>
        <AbnText value={value} />
        <Typography>{rowData[accountNameIndex]}</Typography>
      </React.Fragment>
    ),
    dateOfInvite: (value) => <Typography>{moment(value).format(STANDARD_DATE_FORMAT)}</Typography>,
    contactFirstName: (value, { rowData }) => <Typography>{`${value} ${rowData[contactLastNameIndex]}`}</Typography>,
    mobileNumber: (value) => <PhoneNumberText value={value} />,
    defaultTermsInDays: (value, { rowData }) => <Typography>{formatSupplierTerm(value, rowData[termPeriodIndex])}</Typography>,
    availableBalance: (value) => <DollarsAndCentsText amount={value} />,
    buyerStatus: (value, { rowData }) => {
      const isAccepted = value === 'Accepted';
      const isbuyerAccountReadyToProcessPayment = isAccepted && rowData[accountReadinessIndex];
      if (isbuyerAccountReadyToProcessPayment) {
        return (
          <Can I={Permission.Actions.Create} a={Permission.Supplier.Payment}>
            {() => (
              <>
                <Button
                  variant='outlined'
                  color='secondary'
                  type='button'
                  data-testid='uia-processPayment'
                  className={classes.processPaymentButton}
                  component={AdapterLink}
                  to={{
                    pathname: `/supplier/payments/add/${rowData[accountIdIndex]}`,
                  }}
                >
                  Process payment
                </Button>
              </>
            )}
          </Can>
        );
      }

      return (
        <>
          <StatusLabel color={styles.palette.warning.light} text='Awaiting Acceptance' />
        </>
      );
    },
    authorizationStatus: (value) => <Typography>{value === AuthorizationStatusValues.NotRequired ? 'Yes' : 'No'}</Typography>,
    moreActions: (value, { rowData }) => {
      return (
        <MoreActionsContextMenu
          actions={rowData[actionsIndex]}
          isVisible={true}
          buyerGcAccountId={rowData[accountIdIndex]}
          buyerAccountName={rowData[accountNameIndex]}
          buyerAccountAbn={rowData[abnIndex]}
          supplierGcAccountId={gcAccountId}
          options={moreActionMenuoptions}
          ability={ability}
          data-testid='uia-moreActionsMenu'
          term={rowData[termIndex]}
          termsPeriod={rowData[termPeriodIndex]}
        />
      );
    },
  };

  const mobileRowRender = (rowData) => {
    const abn = rowData[abnIndex];
    const accountName = rowData[accountNameIndex];
    const accountId = rowData[accountIdIndex];
    const defaultTermsInDays = rowData[termIndex];
    const termsPeriod = rowData[termPeriodIndex];
    const availableBalance = rowData[availableBalanceIndex];
    const authorizationStatus = rowData[authorizationStatusIndex];
    const buyerStatus = rowData[buyerStatusIndex];
    const dateOfInvite = rowData[dateOfInviteIndex];
    const isAccepted = buyerStatus === 'Accepted';
    const isbuyerAccountReadyToProcessPayment = isAccepted && rowData[accountReadinessIndex];

    return (
      <>
        <Grid container>
          <Grid item xs={6} className={classes.mobileRowAccountLine}>
            <div>
              <AbnText value={abn} />
              <Typography variant='body1' className={classes.mobileColumnValue}>
                {accountName}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} className={classes.mobileRowMoreActions}>
            <div>
              <MoreActionsContextMenu
                actions={rowData[actionsIndex]}
                isVisible={true}
                buyerGcAccountId={rowData[accountIdIndex]}
                buyerAccountName={rowData[accountNameIndex]}
                buyerAccountAbn={rowData[abnIndex]}
                supplierGcAccountId={gcAccountId}
                options={moreActionMenuoptions}
                ability={ability}
                term={rowData[termIndex]}
                termsPeriod={rowData[termPeriodIndex]}
              />
            </div>
          </Grid>
          <Grid item xs={4} className={classes.mobileColumnLabel}>
            Terms
          </Grid>
          <Grid item xs={5} className={classes.mobileColumnLabel}>
            Available
          </Grid>
          <Grid item xs={3} className={classes.mobileColumnLabel}>
            Auto-billing
          </Grid>
          <Grid item xs={4}>
            <Typography variant='body1' className={classes.mobileColumnValue}>
              {formatSupplierTerm(defaultTermsInDays, termsPeriod)}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant='body1' className={classes.mobileColumnValue}>
              <DollarsAndCentsText amount={availableBalance} />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='body1' className={classes.mobileColumnValue} data-testid='uia-authorizationStatus'>
              {authorizationStatus === AuthorizationStatusValues.NotRequired ? 'Yes' : 'No'}
            </Typography>
          </Grid>
          {isbuyerAccountReadyToProcessPayment ? (
            <Can I={Permission.Actions.Create} a={Permission.Supplier.Payment}>
              {() => (
                <Grid item xs={12} className={classes.mobileColumnLabel}>
                  <Button
                    variant='outlined'
                    color='secondary'
                    fullWidth
                    type='button'
                    data-testid='uia-processPayment'
                    component={AdapterLink}
                    to={{
                      pathname: `/supplier/payments/add/${accountId}`,
                    }}
                  >
                    Process payment
                  </Button>
                </Grid>
              )}
            </Can>
          ) : (
            <>
              <Grid item xs={4} className={classes.mobileColumnLabel}>
                Date invited
              </Grid>
              <Grid item xs={5} className={classes.mobileColumnLabel}>
                Status
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={4}>
                <Typography className={classes.mobileColumnValue}>{moment(dateOfInvite).format(STANDARD_DATE_FORMAT)}</Typography>
              </Grid>
              <Grid item xs={5}>
                <StatusLabel color={styles.palette.warning.light} text='Awaiting Acceptance' />
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  };

  const handleAddCustomerClick = () => {
    if (!props.isSettlementAccountSetupCompleted) {
      setIsPrompt(true);
    } else {
      history.push('/supplier/customers/add');
    }
    // reset value when animation time elapsed
    setTimeout(() => {
      setIsPrompt(false);
    }, 1000);
  };

  const handleTermChanged = (e: any) => {
    const term = e.target.value;
    getTermFees(term);
  };

  const getTermFees = (terms: any) => {
    const item = supplierTerms.find((i) => i.term === terms.term && i.termPeriod.toLowerCase() === terms.termPeriod.toLowerCase());
    item ? setSelectedTerm({ term: item.term, termsPeriod: item.termPeriod }) : setSelectedTerm({ term: 0, termsPeriod: DEFAULT_TERM_PERIOD });
    item ? setSelectedTermFee(item.feeInPercentage) : setSelectedTermFee(0.0);
  };

  const getSelectedElement = (term, termList) => {
    const index = termList.findIndex((x) => x.term === term.term && x.termPeriod.toLowerCase() === term.termsPeriod.toLowerCase());
    const element = termList[index];
    return element;
  };

  return (
    <>
      {!props.isSettlementAccountSetupCompleted ? (
        <div className={clsx({ [classes.animationHolder]: isPrompt, [classes.animationHolderBlank]: !isPrompt })}>
          <SettlementAccountWarning />
        </div>
      ) : (
        <></>
      )}
      <DataGridPageLayout
        title='Customers'
        getData={getData}
        columns={enrichColumnsWithBodyRenders(columns, desktopColumnCustomBodyRenders)}
        mobileRowRender={mobileRowRender}
        addButtonOptions={
          ability.can(Permission.Actions.Create, Permission.Supplier.Buyer)
            ? {
                label: '+ Add a customer',
                onAddClick: handleAddCustomerClick,
                disabled: false,
              }
            : null
        }
        options={{ search: true }}
      />
      {isCancelConfirmationOpen ? (
        <ConfirmationDialog
          isOpen={isCancelConfirmationOpen}
          onClose={handleCloseCancelConfirmation}
          onConfirm={handleCancelRequest}
          title='Cancel request?'
          contentText={`On cancel, ${buyerAccount.accountName} will no longer be available as your connected customer. Please click Yes to proceed.`}
          id='cancel-confirmation'
          data-testid='uia-cancelDialog'
        />
      ) : null}
      {isDeleteConfirmationOpen ? (
        <ConfirmationDialog
          isOpen={isDeleteConfirmationOpen}
          onClose={handleCancelDeleteConfirmation}
          onConfirm={() => setIsDeleteConfirmationOpen(false)}
          title='Delete'
          contentText={
            <>
              Are you sure you want to delete{' '}
              <strong>
                {formatAbnOrAcn(buyerAccount.abn)}, {buyerAccount.accountName}
              </strong>
              ?
            </>
          }
          id='delete-confirmation'
          data-testid='uia-deleteCustomerDialog'
          showChildren
          overrideActions
          actions={
            <>
              <Button onClick={handleCancelDeleteConfirmation} color='primary' disabled={false} data-testid='uia-cancelDeleteButton'>
                Cancel
              </Button>
              <Button
                className={classes.deleteConfirm}
                onClick={() => handleDeleteRequest(gcAccountId, buyerAccount.accountId)}
                variant='outlined'
                disabled={false}
                autoFocus
                data-testid='uia-deleteRequestButton'
              >
                Delete
              </Button>
            </>
          }
        />
      ) : null}
      {isTermChangeConfirmationOpen ? (
        <ConfirmationDialog
          isOpen={isTermChangeConfirmationOpen}
          onClose={handleCancelTermUpdate}
          onConfirm={handleTermUpdate}
          title='Change my terms'
          id='confirmation'
          data-testid='uia-changeTermDialog'
          showChildren
          contentText=''
          overrideActions
          actions={
            <>
              <Button onClick={handleCancelTermUpdate} color='primary' disabled={false} data-testid='uia-cancelButton'>
                Cancel
              </Button>
              <Button onClick={handleTermUpdate} color='primary' variant='contained' disabled={false} autoFocus data-testid='uia-updateButton'>
                Update
              </Button>
            </>
          }
        >
          <Grid container rowSpacing={3} columnSpacing={5}>
            <Typography className={classes.contentText} variant='h5'>
              What terms would you like to offer?
            </Typography>
            <Grid container columnSpacing={5}>
              <Grid item xs={8}>
                <FormControl className={classes.formControl} variant='outlined'>
                  <Select
                    name='selectedTerm'
                    id='selectedTerm'
                    data-testid='uia-selectedTerm'
                    onChange={handleTermChanged}
                    value={getSelectedElement(selectedTerm, supplierTerms)}
                    disableUnderline
                  >
                    {supplierTerms.map(
                      (item) =>
                        item && (
                          <MenuItem key={`${item.term}-${item.termPeriod}-${item.feeInPercentage}`} value={item}>
                            {formatSupplierTerm(item.term, item.termPeriod)}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4} direction='column'>
                <Grid className={classes.feePlaceholder}>
                  <Typography className={classes.feeTitle}>FEE</Typography>
                  <Typography className={classes.feeValue} data-testid='uia-selectedTerm-fee'>
                    {selectedTermFee !== 0 ? selectedTermFee : '0.00'}%
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ConfirmationDialog>
      ) : null}
    </>
  );
};

export default Customers;
