import { createActions } from 'redux-actions';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import { shiftPaymentsApi, registrationApi } from '../../api';
import startupConfig from '../../lib/startupConfig';

export const { getStartupConfigFulfilled, getStartupConfigPending, getStartupConfigRejected } = createActions(
  actionTypes.GET_STARTUP_CONFIG_FULFILLED,
  actionTypes.GET_STARTUP_CONFIG_PENDING,
  actionTypes.GET_STARTUP_CONFIG_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getLocalConfig = () =>
  Promise.resolve({
    data: {
      ...startupConfig.get(),
      shiftServiceUrl: process.env.REACT_APP_SHIFT_SERVICE_URL,
      identityServerUrl: process.env.REACT_APP_IDENTITY_SERVER_URL,
      registrationUiUrl: process.env.REACT_APP_REGISTRATION_UI_URL,
      abnSearchServiceUrl: process.env.REACT_APP_ABN_SEARCH_SERVICE_URL,
      instrumentationKey: process.env.REACT_APP_AI_INSTRUMENTATION_KEY,
      mfKey: process.env.REACT_APP_MOUSEFLOW_KEY,
      widgetsUrl: process.env.REACT_APP_WIDGETS_URL,
    },
  });

const getStartupConfig = () => (dispatch) => {
  dispatch(getStartupConfigPending());

  const getConfig = process.env.REACT_APP_STAND_ALONE ? getLocalConfig : shiftPaymentsApi.getStartupConfig;

  return getConfig()
    .then((response) => {
      return registrationApi.getStartupConfig(response.data.registrationUiUrl).then((regResponse) => {
        const config = {
          ...response.data,
          trustDeedAttachment: regResponse.data.trustDeedAttachment,
        };
        return dispatch(getStartupConfigFulfilled(config));
      });
    })
    .catch((error) => {
      return dispatch(getStartupConfigRejected(error));
    });
};

export default {
  getStartupConfig,
};
