// @flow
import * as React from 'react';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

import type { OrderBy } from '../api/shiftPaymentsApi';
import DataGrid, { type Props as DataGridProps } from './DataGrid';
import { PAGINATION_LIMIT_DESKTOP, PAGINATION_LIMIT_MOBILE, FULL_DATA_LIMIT } from '../constants';
import { getColumnOrderBy, convertDataToDataGridFormat } from '../lib/dataGridUtils';
import AlertError from './AlertError';
import { useIsLargeMobileResolution } from '../lib/materialUiUtils';

export type Props = {
  ...DataGridProps,
  getData: (skip: number, limit: number, orderBy: ?Array<OrderBy>, search: ?string) => Promise<Object>,
  defaultOrderBy: Array<OrderBy>,
};

export type PagedData = {
  items: Array<Object>,
  totalCount: number,
  page: number,
  orderBy: ?Array<OrderBy>,
};

const useStyles = makeStyles((theme) => ({
  errorAlert: {
    margin: theme.spacing(2),
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

const RemoteDataGrid = React.forwardRef(
  ({ getData, columns, options, mobileRowRender, defaultOrderBy }: Props, ref) => {
    const classes = useStyles();
    const isMobile = useIsLargeMobileResolution();
    const paginationLimit = isMobile ? PAGINATION_LIMIT_MOBILE : PAGINATION_LIMIT_DESKTOP;
    const [stateData, setStateData] = React.useState<PagedData>({
      items: [],
      totalCount: 0,
      page: 0,
      orderBy: [],
    });
    const [errorMessage, setErrorMessage] = React.useState('');
    const tableRef = React.useRef({});

    const overwriteTable = (newTable) => {
        tableRef.current = newTable;
    };
      
    let enrichedColumns;
    if (stateData.orderBy) {
      enrichedColumns = columns.map((column) => {
        const orderBy = stateData.orderBy && stateData.orderBy.find((o) => o.columnName === column.name);
        if (orderBy) {
          return {
            ...column,
            options: {
              ...column.options,
              sortDirection: orderBy.isAscending ? 'asc' : 'desc',
            },
          };
        }
    
        return column;
      });
    } else {
      enrichedColumns = columns;
    }
    
    let searchTime = new Date().getTime();

    const reloadData = () => {
      const { page, resetData, searchServsideText } = tableRef.current;
      const searchRequestDateTime = tableRef.current.searchRequestDateTime ?? new Date().getTime();
      const orderBy = getColumnOrderBy(tableRef.current) || defaultOrderBy;

      return getData(page * paginationLimit, paginationLimit, orderBy, searchServsideText ?? null).then((response) => {
        const { error, payload } = response;

        if (error) {
          if (!(payload && payload.response && payload.response.status === 404 && payload.response.data && payload.response.data.message)) {
            setErrorMessage('No data was returned');
          }

          const data = {
            items: [],
            totalCount: 0,
            page: 0,
            orderBy,
          };
          setStateData(data);
        } else {
          const data = {
            items: isMobile && !resetData ? stateData.items.concat(payload.data.items) : payload.data.items,
            totalCount: payload.data.totalCount,
            page,
            orderBy,
          };
          if (searchTime && searchRequestDateTime && searchTime > searchRequestDateTime) {
            return;
          }
          searchTime = searchRequestDateTime;
          setStateData(data);
        }
      });
    };

    React.useImperativeHandle(
      ref,
      () => ({
        reloadData,
      }),
      []
    );
    
    const handleTableChange = (action: string, newTable: any) => {
      switch (action) {
        case 'search':
          newTable.resetData = true;
          overwriteTable(newTable)
        case 'tableInitialized':
        case 'changePage':
          {
            setErrorMessage('');
            overwriteTable(newTable)
            reloadData();
          }
          break;
        default:
          break;
      }
    };
    
    const customOptions = {
      serverSide: true,
      pagination: true,
      onTableInit: handleTableChange,
      onTableChange: handleTableChange,
      rowsPerPageOptions: [],
      rowsPerPage: paginationLimit,
      count: stateData.totalCount,
      page: stateData.page,
      ...options,
    };
    let fullDataForDownload;
    
    if (customOptions.download && customOptions.downloadOptions) {
      const { dataFormatter, fetchFullData } = customOptions.downloadOptions;
      customOptions.onDownload = (buildHead, buildBody, downloadColumns, downloadData) => {
        if (fetchFullData && fullDataForDownload) {
          const convertedData = convertDataToDataGridFormat(downloadColumns, fullDataForDownload.items);
          const formattedData = dataFormatter(buildHead, buildBody, downloadColumns, convertedData);
          fullDataForDownload = undefined;
          return formattedData;
        }
    
        return dataFormatter(buildHead, buildBody, downloadColumns, downloadData);
      };
    
      if (fetchFullData) {
        customOptions.onBeforeDownload = async () => {
          const { error, payload } = await getData(0, FULL_DATA_LIMIT, defaultOrderBy, '');
          if (error) {
            setErrorMessage('Failed to dowload the full data.');
            return;
          }
    
          fullDataForDownload = payload.data;
        };
      }
    }
    
    return (
      <Grid container className={classes.container}>
        {errorMessage ? (
          <Grid item xs={12} className={classes.errorAlert}>
            <AlertError
              message={errorMessage}
              subMessage='Failed to load the data.'
              action={
                <IconButton aria-label='close' onClick={() => setErrorMessage('')}>
                  <CloseIcon />
                </IconButton>
              }
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <DataGrid
            data={stateData.items}
            columns={enrichedColumns}
            mobileRowRender={mobileRowRender}
            orderBy={stateData.orderBy}
            options={customOptions}
          />
        </Grid>
      </Grid>
    );
  }
);

export default (RemoteDataGrid);
