/* eslint-disable flowtype/no-types-missing-file-annotation */
import { createActions } from 'redux-actions';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';
import type { OrderBy } from '../../api/shiftPaymentsApi';

export const { getSupplierRequestsPending, getSupplierRequestsFulfilled, getSupplierRequestsRejected, resetTotalSupplierRequests } = createActions(
  actionTypes.GET_SUPPLIER_REQUESTS_PENDING,
  actionTypes.GET_SUPPLIER_REQUESTS_FULFILLED,
  actionTypes.GET_SUPPLIER_REQUESTS_REJECTED,
  actionTypes.RESET_TOTAL_SUPPLIER_REQUESTS,
  getActionOptions(ACTION_PREFIX)
);

const getSupplierRequests = (buyerGcAccountId: string, skip: number, limit: number, orderBy: ?Array<OrderBy>) => (dispatch: Dispatch<any>) => {
  dispatch(getSupplierRequestsPending());

  return shiftPaymentsApi
    .getSupplierRequests(buyerGcAccountId, skip, limit, orderBy)
    .then((response) => {
      return dispatch(getSupplierRequestsFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getSupplierRequestsRejected(error));
    });
};

export default {
  getSupplierRequests,
  resetTotalSupplierRequests,
};
