// @flow
import { PARTITION } from './constants';
import type { ReduxState } from './reducer';

const selectPartition = (state: any): ReduxState => state[PARTITION];
const selectInvoice = (state: any) => selectPartition(state).invoice;
const selectIsAllowedJoinBrokerNetwork = (state: any) => selectPartition(state).isAllowedJoinBrokerNetwork;

export default {
  selectPartition,
  selectInvoice,
  selectIsAllowedJoinBrokerNetwork,
};
