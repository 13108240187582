// @flow
import * as React from 'react';
import { isArray, isString, uniq } from 'lodash';
import { FormHelperText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getIn } from 'formik';
import type { FormikState } from 'formik';

import type { Field } from './types';

const useStyles = makeStyles(() => ({
  errorList: {
    marginTop: 0,
  },
}));

type Props = {
  children: React.Node,
  field: Field,
  form: FormikState<any>,
  showNoError: boolean,
  labelForArrayError?: string,
};

const FormikField = (props: Props) => {
  const { children, field, form, showNoError, labelForArrayError = '' } = props;
  const classes = useStyles();

  if (showNoError) {
    return [children];
  }

  const touched = getIn(form.touched, field.name);
  const error = getIn(form.errors, field.name);

  if (isArray(error)) {
    return (
      <>
        {children}
        {labelForArrayError && error.length ? <>
            <Typography variant='body2'>{labelForArrayError}</Typography>
            <ul className={classes.errorList}>
              {uniq(error).map(
                (value) =>
                  value && (
                    <li key={value}>
                      <FormHelperText error variant='filled'>
                        {value}
                      </FormHelperText>
                    </li>
                  )
              )}
            </ul>
          </> : null}
      </>
    );
  }

  let currentError;
  if (touched && error) {
    if (isString(error)) {
      currentError = error;
    } else {
      currentError = error;
    }
  }

  return (
    <>
      {children}
      {currentError ? <FormHelperText error variant='filled'>
          {currentError}
        </FormHelperText> : null}
    </>
  );
};

FormikField.defaultProps = {
  showNoError: false,
};

export default FormikField;
