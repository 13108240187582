// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  theme: any,
  sortDirection: any,
};

const SortingIcon = withTheme((props: Props) => (
  <SvgIcon
    style={{
      alignSelf: 'baseline',
      paddingTop: '6px',
      paddingLeft: '5px',
      fontSize: '1.5rem',
    }}
    viewBox='0 0 12 12'
    {...(props: any)}
  >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-504.000000, -329.000000)' fillRule='nonzero'>
        <g id='sort' transform='translate(504.000000, 329.000000)'>
          <path
            d='M5.182177,3.161543 L0.361592,3.161543 C0.1733039,3.161543 0.0171941,3.0057412 0.0171941,2.8171451 C0.0171941,2.7257076 0.05479166,2.6396832 0.1194578,2.5753189 L2.5299428,0.1648339 C2.59461048,0.10016622 2.6806318,0.0622699 2.7720693,0.0622699 C2.8635068,0.0622699 2.9495312,0.09986746 3.0138955,0.1645336 L5.4246885,2.5750186 C5.48905588,2.63938598 5.5266519,2.7254073 5.5266519,2.8168448 C5.5266519,3.0051329 5.3708501,3.161543 5.182254,3.161543 L5.182177,3.161543 Z'
            fill={props.sortDirection === 'asc' ? props.theme.palette.primary.main : props.theme.palette.grey.light}
          />
          <path
            d='M5.4246038,5.125351 L3.0138108,7.535836 C2.94944342,7.60020338 2.8634221,7.6377994 2.7719846,7.6377994 C2.6805471,7.6377994 2.5945227,7.60020184 2.5301584,7.535836 L0.1196734,5.125351 C0.05500572,5.06098362 0.0174097,4.974662 0.0174097,4.8832245 C0.0174097,4.6949364 0.1732115,4.5388266 0.3618076,4.5388266 L5.1821616,4.5388266 C5.37075,4.5388266 5.5268598,4.6946284 5.5268598,4.8832245 C5.52655904,4.974662 5.48926224,5.0606864 5.4245961,5.125351 L5.4246038,5.125351 Z'
            fill={props.sortDirection === 'desc' ? props.theme.palette.primary.main : props.theme.palette.grey.light}
          />
        </g>
      </g>
    </g>
  </SvgIcon>
));

export default SortingIcon;
