import * as Yup from 'yup';
import { checkSpecialCharacters } from '../../../lib/validations';
import {
  ATTACHMENT_SUPPORTED_FORMATS,
  ATTACHMENT_MAX_FILE_NAME_LENGTH,
  MINIMUM_INVOICE_AMOUNT,
  ALLOWED_ALPHANUM_CHARACTERS_VALIDATION_MESSAGE,
  ALLOWED_NUMERIC_CHARACTERS_VALIDATION_MESSAGE,
  ALLOWED_LENGTH_VALIDATION_MESSAGE,
  MAXIMUM_CREDIT_LIMIT,
} from '../../../constants';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';

let prevBsbValid = false;
let prevBsbValue = '';

export const formSchema = (props) => {
  const {
    invoiceAttachmentConfig: { maxFileSizeInMb, maxFilesCount },
  } = props;
  const maxFileSize = maxFileSizeInMb * 1048576;

  return Yup.object().shape({
    supplierBsb: Yup.string()
      .required('Please enter a BSB')
      .test('isValidBsbNumber', 'BSB invalid. Please recheck the supplier bank details', (bsbNumber) => {
        if (!bsbNumber) {
          return true;
        }

        if (bsbNumber.length !== 6) {
          return false;
        }

        if (bsbNumber !== prevBsbValue) {
          prevBsbValue = bsbNumber;
          return shiftPaymentsApi
            .getBankNameByBsbNumber(bsbNumber)
            .then((response) => {
              const { status } = response;
              prevBsbValid = status === 200;
              return prevBsbValid;
            })
            .catch(() => {
              prevBsbValid = false;
              return prevBsbValid;
            });
        }

        return prevBsbValid;
      }),
    supplierBankAccountNumber: Yup.string()
      .required('Please enter an account number')
      .test(
        'supplierAccountNumber',
        ALLOWED_NUMERIC_CHARACTERS_VALIDATION_MESSAGE,
        (value) => !checkSpecialCharacters(value) && !Number.isNaN(Number(value))
      )
      .max(9, ALLOWED_LENGTH_VALIDATION_MESSAGE(9)),
    invoiceAmount: Yup.mixed()
      .required('Please enter an invoice amount')
      .test('minimumAmount', `The minimum amount we can process is $${MINIMUM_INVOICE_AMOUNT}.`, (value) => Number(value) >= MINIMUM_INVOICE_AMOUNT)
      .test('maximumAmount', `The max payment we can process is $${MAXIMUM_CREDIT_LIMIT}`, (value) => Number(value) <= MAXIMUM_CREDIT_LIMIT),
    invoiceNumber: Yup.mixed()
      .test('invoiceNumber', 'Validate invoice number', function (value) {
        if (!value) {
          return this.createError({
            path: 'invoiceNumber',
            message: 'Please enter an invoice number',
          });
        }
        return true;
      })
      .test('invoiceNumber', ALLOWED_ALPHANUM_CHARACTERS_VALIDATION_MESSAGE, (value) => !checkSpecialCharacters(value)),
    invoiceDescription: Yup.mixed()
      .test('invoiceDescriptionLength', 'Only 18 characters allowed', (value) => !(!!value && value.length > 18))
      .test('invoiceDescription', ALLOWED_ALPHANUM_CHARACTERS_VALIDATION_MESSAGE, (value) => !checkSpecialCharacters(value)),
    invoiceFiles: Yup.array()
      .of(
        Yup.mixed()
          .test('maxFileSize', `The maximum file size allowed is ${maxFileSizeInMb}MB`, (value) => {
            const isValid = value && value.size <= maxFileSize;
            return isValid;
          })
          .test('maxFileSize', 'The file must not be empty', (value) => {
            const isValid = value && value.size > 0;
            return isValid;
          })
          .test(
            'fileFormat',
            `The supported file types are ${ATTACHMENT_SUPPORTED_FORMATS.toString()}`,
            (value) => value && ATTACHMENT_SUPPORTED_FORMATS.includes(value.type)
          )
          .test(
            'fileName',
            `The maximum length of a file name is ${ATTACHMENT_MAX_FILE_NAME_LENGTH} characters`,
            (value) => value.name.length <= ATTACHMENT_MAX_FILE_NAME_LENGTH
          )
      )
      .max(maxFilesCount, `A maximum of ${maxFilesCount} files is allowed`),
  });
};
