/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

const PadlockIcon = withTheme((props) => (
  <SvgIcon {...props}>
    <g id='PadlockIconSvg' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='PS_Bank-connectstep_bank-logos' transform='translate(-476.000000, -593.000000)'>
        <g id='padlock' transform='translate(476.000000, 593.000000)'>
          <g id='Icon-Circle'>
            <g id='Oval'>
              <mask id='mask-2' fill='white'>
                <use xlink='#path-1' />
              </mask>
              <circle stroke={props.theme.palette.success.main} cx='12' cy='12' r='11.5' />
            </g>
          </g>
          <g transform='translate(9.000000, 7.000000)' fill={props.theme.palette.success.main} fillRule='nonzero'>
            <path
              d='M6.41666667,3.75 L5.83333333,3.75 L5.83333333,2.5 C5.83333333,1.11928813 4.78866442,0 3.5,0 C2.21133558,0 1.16666667,1.11928813 1.16666667,2.5 L1.16666667,3.75 L0.583333333,3.75 C0.261167229,3.75 0,4.02982203 0,4.375 L0,9.375 C0,9.72017797 0.261167229,10 0.583333333,10 L6.41666667,10 C6.73883277,10 7,9.72017797 7,9.375 L7,4.375 C7,4.02982203 6.73883277,3.75 6.41666667,3.75 Z M2.33333333,2.5 C2.33333333,1.80964406 2.85566779,1.25 3.5,1.25 C4.14433221,1.25 4.66666667,1.80964406 4.66666667,2.5 L4.66666667,3.75 L2.33333333,3.75 L2.33333333,2.5 Z M5.83333333,8.75 L1.16666667,8.75 L1.16666667,5 L5.83333333,5 L5.83333333,8.75 Z'
              id='Shape'
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
));

export default PadlockIcon;
