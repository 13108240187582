import { Box, NotificationBanner, Text } from '@shiftfinancial/design-system';

import React from 'react';

import { SHIFT_PHONE_NUMBER } from '../../constants';
import DollarsAndCentsText from '../DollarsAndCentsText';

type ArrearsPaymentPendingBannerProps = {
  totalArrearsAmount: number;
};

export function ArrearsPaymentPendingBanner({ totalArrearsAmount = 0 }: ArrearsPaymentPendingBannerProps) {
  const title = `Repayment in progress`;

  return (
    <Box
      sx={{
        marginBottom: { xs: 'x1', sm: 'x3' },
        px: { xs: 'x2', sm: 'x0' },
      }}
    >
      <NotificationBanner appearance='info' title={title}>
        <Text>You have a repayment in progress.</Text>
        <Text data-testid='uia-arrears-pending-amount'>
          Your account still has an overdue balance of <DollarsAndCentsText amount={totalArrearsAmount} /> and will need to be repaid in full to
          process new invoices.
        </Text>
        <Text>If you need assistance, call us on {SHIFT_PHONE_NUMBER}.</Text>
      </NotificationBanner>
    </Box>
  );
}
