/* eslint-disable flowtype/no-types-missing-file-annotation */
import { createActions } from 'redux-actions';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';
import type { OrderBy } from '../../api/shiftPaymentsApi';

export const { getUpcomingPaymentsPending, getUpcomingPaymentsFulfilled, getUpcomingPaymentsRejected } = createActions(
  actionTypes.GET_UPCOMING_PAYMENTS_PENDING,
  actionTypes.GET_UPCOMING_PAYMENTS_FULFILLED,
  actionTypes.GET_UPCOMING_PAYMENTS_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getUpcomingPayments = (gcAccountId: string, skip: number, limit: number, orderBy: ?Array<OrderBy>) => (dispatch: Dispatch<any>) => {
  dispatch(getUpcomingPaymentsPending());

  return shiftPaymentsApi
    .getUpcomingPayments(gcAccountId, skip, limit, orderBy)
    .then((response) => {
      return dispatch(getUpcomingPaymentsFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getUpcomingPaymentsRejected(error));
    });
};

export default {
  getUpcomingPayments,
};
