import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from '@reduxjs/toolkit';
import Component from './Component';
import actions from './actions';
import initialDataSelectors from '../InitialData/selectors';

const mapStateToProps = createSelector(initialDataSelectors.selectSelectedGcAccountId, (gcAccountId) => ({
  gcAccountId,
}));

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Component);
