// @flow
import React, { useEffect, useState } from 'react';
import { withStyles, Button, Typography, Grid } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { AddBankSelectAccountForm } from '../../components/AddBankSelectAccountForm';
import type { BankLoginAccountOptions, BankLoginConfiguration, BankAccount } from '../../components/AddBankConnectForm/reducer';
import SetupPageLayout from '../../components/SetupPageLayout';
import CardContentSecurity from '../../components/CardContentSecurity';
import { AdapterLink } from '../../lib/materialUiUtils';

type Props = {
  classes: any,
  bankLoginAccountOptions: ?BankLoginAccountOptions,
  bankLoginConfiguration: ?BankLoginConfiguration,
  getBankAccounts: (gcAccountId: string) => Promise<any>,
  gcAccountId: string,
  resetBankLogin: () => void,
};

const styles = (theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(5),
    },
  },
  addBankContainer: {
    backgroundColor: theme.palette.common.lightGreen,
    borderRadius: 4,
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
    '& div > span:nth-child(1)': {
      lineHeight: 2.5,
    },
    '& div:nth-child(2)': {
      justifyContent: 'flex-end',
      display: 'flex',
    },
    '& a': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  },
});

const AddBankSelectAccount = (props: Props) => {
  const { classes, bankLoginConfiguration, bankLoginAccountOptions, getBankAccounts, gcAccountId, resetBankLogin } = props;
  const [stateAccounts, setAccounts] = useState(null);

  useEffect(() => {
    if (gcAccountId && !bankLoginAccountOptions && !stateAccounts) {
      getBankAccounts(gcAccountId).then((response) => {
        const { error, payload } = response;
        if (error) {
          // tODO: error handling
          return;
        }

        const accounts = payload.data.map((x) => ({
          name: x.bankAccountName,
          bsb: x.bsb,
          accountNumber: x.bankAccountNumber,
          bankIcon: x.bankIcon,
          bankId: x.bankId,
          bankName: x.bankName,
          id: x.bankAccountId,
          isShiftBankAccount: x.isShiftBankAccount,
          ddrAgreementSigned: !!x.ddrAgreement,
        }));
        setAccounts(accounts);
      });
    }

    return () => {
      resetBankLogin();
    };
  }, [bankLoginAccountOptions, gcAccountId]);

  if (bankLoginAccountOptions && bankLoginAccountOptions.accounts && !stateAccounts) {
    const accounts = bankLoginAccountOptions.accounts.map((x: BankAccount) => {
      if (bankLoginConfiguration) {
        return {
          ...x,
          bankIcon: bankLoginConfiguration.imageLink,
          bankName: bankLoginConfiguration.name,
        };
      }

      return x;
    });

    setAccounts(accounts);
  }

  return (
    <SetupPageLayout
      title='Add a payment method'
      subtitle='Please select the business accounts you would like to connect. Personal accounts cannot be connected.'
      footer={<CardContentSecurity />}
    >
      {stateAccounts ? <AddBankSelectAccountForm accounts={stateAccounts} showCancel={bankLoginAccountOptions} /> : null}
      {!bankLoginAccountOptions && (
        <Grid container className={classes.addBankContainer}>
          <Grid item xs={12} sm={7}>
            <Typography variant='subtitle2' component='span'>
              Want to add a different payment method?
            </Typography>
          </Grid>
          <Grid xs={12} sm={5}>
            <Button
              variant='outlined'
              color='secondary'
              startIcon={<AddCircleOutlineIcon />}
              component={AdapterLink}
              to={{
                pathname: '/settings/add-bank',
              }}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      )}
    </SetupPageLayout>
  );
};

export default withStyles(styles)(AddBankSelectAccount);
