import React from 'react';
import { Button, Box, Typography, makeStyles } from '@material-ui/core';
import { SuccessOutlinedIcon, ShiftTradeLogo } from '../../../../components/svg';
import Footer from '../../../../components/Footer';
import { AdapterLink } from '../../../../lib/materialUiUtils';
import { withRouter } from 'react-router';
import BasicPageLayout from '../../../../components/BasicPageLayout';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    backgroundColor: theme.palette.common.mediumGreen,
  },
  main: {
    [theme.breakpoints.up('xs')]: {
      maxWidth: 500,
      padding: theme.spacing(1, 2, 2, 2),
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.up('sm')]: {
      width: 500,
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3, 3, 4, 3),
      margin: theme.spacing(1, 3),
    },
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    fontFamily: 'Rubik',
  },
  logo: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      maxWidth: 300,
      margin: theme.spacing(1),
      padding: theme.spacing(2.5),
    },
    [theme.breakpoints.up('sm')]: {
      width: 300,
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(4),
    },
  },
  pageTitle: {
    fontSize: '20px',
    fontWeight: 500,
    padding: theme.spacing(2, 0),
  },
  form: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 1, 5, 1),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 3, 2, 3),
    },
    width: '100%',
  },
  paragraph: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  icon: {
    width: theme.spacing(6.75),
    height: theme.spacing(6.75),
    alignSelf: 'inherit !important',
    margin: theme.spacing(4, 0, 1, 0),
  },
  backButtonContainer: {
    marginTop: theme.spacing(3),
  },
}));

const ExtendInvoiceConfirmation = () => {
  const classes = useStyles();
  return (
    <BasicPageLayout title='Request received for extension' noHeader noMargin>
      <Box className={classes.container} flexDirection='column' display='flex' alignItems='center'>
        <ShiftTradeLogo className={classes.logo} dark />
        <Box className={classes.main} flexDirection='column' display='flex' alignItems='center' justifyContent='space-around'>
          <SuccessOutlinedIcon className={classes.icon} />
          <Typography variant='h2' data-testid='uia-title' className={classes.pageTitle}>
            Request received
          </Typography>
          <div className={classes.form}>
            <Typography variant='body1' component='p' data-testid='uia-message' paragraph align='center' className={classes.paragraph}>
              Please allow 1-2 business days for the new terms to take effect and update in the system.
            </Typography>
            <Box flexDirection='column' display='flex' alignItems='center' justifyContent='space-around' className={classes.backButtonContainer}>
              <Button variant='outlined' color='primary' component={AdapterLink} to='/buyer/payments' data-testid='uia-backToInvoiceButton'>
                Go back to invoices
              </Button>
            </Box>
          </div>
        </Box>
        <Footer />
      </Box>
    </BasicPageLayout>
  );
};

export default withRouter(ExtendInvoiceConfirmation);
