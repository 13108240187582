// @flow
import moment from 'moment';
import type { FeesTermsAndPaymentTypes } from './types';
import { type PaymentMethod, PaymentMethodValues } from '../../../api/shiftPaymentsApi';
import { getTimezoneOffset, addDays } from '../../../lib/dateUtils';
import { TERM_PERIOD_TYPE } from '../../../constants';

const timezoneOffset = getTimezoneOffset();

export const calculateTransactionFee = (
  paymentMethod: PaymentMethod,
  feesTermsAndPaymentTypes: FeesTermsAndPaymentTypes,
  isSupplierInitiated: boolean,
  supplierDueDate: ?moment,
  invoiceDate: Date,
  paymentDate: ?moment,
  installmentTermInWeeks: number,
  utcOffsetInHours: number = timezoneOffset
) => {
  if (paymentMethod === PaymentMethodValues.Full) {
    const startDate: moment = (supplierDueDate ? supplierDueDate : moment(invoiceDate)).utcOffset(utcOffsetInHours);
    if (paymentDate && feesTermsAndPaymentTypes && feesTermsAndPaymentTypes.oneTime.length && startDate) {
      paymentDate.local();
      const paymentDateWithoutTime = moment(paymentDate).clone().startOf('day');
      const startDateWithoutTime = moment(startDate).clone().startOf('day');
      const days = paymentDateWithoutTime.diff(startDateWithoutTime, 'days');
      const termAndFee = feesTermsAndPaymentTypes.oneTime.find((fee) => fee.term === days);
      if (termAndFee) {
        return termAndFee.fee;
      }
    }
  } else if (
    paymentMethod === PaymentMethodValues.Weekly &&
    installmentTermInWeeks !== null &&
    feesTermsAndPaymentTypes &&
    feesTermsAndPaymentTypes.weekly.length
  ) {
    const termAndFee = feesTermsAndPaymentTypes.weekly.find((x) => x.term === installmentTermInWeeks);
    if (termAndFee) {
      return termAndFee.fee;
    }
  }
  return 0;
};

export const calculateSupplierTermsInDays = (createdDate: Date, supplierTerms: number, supplierTermPeriod: string) => {
  let dueDate = addDays(moment(createdDate), supplierTerms);
  if (supplierTermPeriod === TERM_PERIOD_TYPE.EOM) {
    dueDate = dueDate.endOf('month');
  }

  const termsInDays = moment(dueDate).diff(moment(createdDate), 'days');
  return termsInDays;
};
