// @flow
export const ACTION_PREFIX = 'ACCOUNT_SETUP_CHECK';
export const PARTITION = 'accountSetupCheck';

export const actionTypes = {
  UPDATE_BANK_ACCOUNT_SETUP: 'UPDATE_BANK_ACCOUNT_SETUP',
  UPDATE_BANK_ACCOUNT_SETUP_COMPLETED: 'UPDATE_BANK_ACCOUNT_SETUP_COMPLETED',
  UPDATE_DIRECT_DEBIT_AGREEMENT_SETUP: 'UPDATE_DIRECT_DEBIT_AGREEMENT_SETUP',
  UPDATE_DIRECT_DEBIT_AGREEMENT_SETUP_COMPLETED: 'UPDATE_DIRECT_DEBIT_AGREEMENT_SETUP_COMPLETED',
  UPDATE_LOAN_AGREEMENT_SETUP: 'UPDATE_LOAN_AGREEMENT_SETUP',
  UPDATE_LOAN_AGREEMENT_SETUP_COMPLETED: 'UPDATE_LOAN_AGREEMENT_SETUP_COMPLETED',
  UPDATE_PRIVACY_AGREEMENT_SETUP: 'UPDATE_PRIVACY_AGREEMENT_SETUP',
  UPDATE_PRIVACY_AGREEMENT_SETUP_COMPLETED: 'UPDATE_PRIVACY_AGREEMENT_SETUP_COMPLETED',
  UPDATE_TERMS_AND_CONDITIONS_AGREEMENT_SETUP: 'UPDATE_TERMS_AND_CONDITIONS_AGREEMENT_SETUP',
  UPDATE_TERMS_AND_CONDITIONS_AGREEMENT_SETUP_COMPLETED: 'UPDATE_TERMS_AND_CONDITIONS_AGREEMENT_SETUP_COMPLETED',
  RESET_ACCOUNT_SETUP: 'RESET_ACCOUNT_SETUP',
  GET_ACCOUNT_SETUP_PENDING: 'GET_ACCOUNT_SETUP_PENDING',
  GET_ACCOUNT_SETUP_FULFILLED: 'GET_ACCOUNT_SETUP_FULFILLED',
  GET_ACCOUNT_SETUP_REJECTED: 'GET_ACCOUNT_SETUP_REJECTED',
  SET_ACCOUNT_PROFILE: 'SET_ACCOUNT_PROFILE',
  UPDATE_SETTLEMENT_ACCOUNT_SETUP_COMPLETED: 'UPDATE_SETTLEMENT_ACCOUNT_SETUP_COMPLETED',
  UPDATE_MERCHANT_AGREEMENT_SETUP_COMPLETED: 'UPDATE_MERCHANT_AGREEMENT_SETUP_COMPLETED',
};

export const BECOME_A_BUYER = 'bb';
