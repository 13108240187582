// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';

const { getSuppliersPending, getSuppliersFulfilled, getSuppliersRejected } = createActions(
  actionTypes.GET_SUPPLIERS_PENDING,
  actionTypes.GET_SUPPLIERS_FULFILLED,
  actionTypes.GET_SUPPLIERS_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getSuppliers = (gcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(getSuppliersPending());

  return shiftPaymentsApi
    .getSuppliers(gcAccountId)
    .then((response) => {
      return dispatch(getSuppliersFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getSuppliersRejected(error));
    });
};

export default {
  getSuppliers,
};
