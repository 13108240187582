// @flow
import { handleActions } from 'redux-actions';
import { PARTITION, actionTypes, ACTION_PREFIX } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import type { SupplierRequestDetail } from './types';

export type ReduxState = {
  detail: SupplierRequestDetail,
};

const initialState: ReduxState = {
  detail: {},
};

const reducer = handleActions<ReduxState>(
  {
    [actionTypes.GET_SUPPLIER_REQUEST_FULFILLED]: (state, { payload }) => ({
      ...state,
      detail: payload.data,
    }),
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
