// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';

import AbnText from './AbnText';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  footer: {
    width: '100%',
    color: theme.palette.grey.light,
    textAlign: 'center',
    padding: theme.spacing(1),
    fontSize: 11,
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    '& a:hover': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up('xs')]: {
      color: theme.palette.grey.light,
      '& a': {
        textDecoration: 'none',
        color: theme.palette.grey.light,
      },
      '& a:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const year = moment().year();

  return (
    <footer className={classes.footer}>
      &copy; Copyright {year}.&nbsp;
      <a href='https://www.shift.com.au/' target='_blank' rel='noopener noreferrer'>
        Shift Financial
      </a>
      &nbsp;ABN:{' '}
      <a href='https://abr.business.gov.au/ABN/View?id=24149390625' target='_blank' rel='noopener noreferrer'>
        <AbnText value='24149390625' />
      </a>
    </footer>
  );
};

export default Footer;
