// @flow
import * as React from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import { Zoom } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AlertIcon from './svg/AlertIcon';
import SuccessIcon from './svg/SuccessIcon';
import InfoIcon from './svg/InfoIcon';

const useStyles = makeStyles((theme) => ({
  success: {
    color: `${theme.palette.common.mediumGreen} !important`,
    backgroundColor: `${theme.palette.success.light} !important`,
    border: `2px solid ${theme.palette.success.contrast}`,
    boxShadow: 'none',
    fontSize: 12,
  },
  error: {
    color: `${theme.palette.common.mediumGreen} !important`,
    backgroundColor: `${theme.palette.error.light} !important`,
    border: `2px solid ${theme.palette.error.contrast}`,
    boxShadow: 'none',
    fontSize: 12,
  },
  warning: {
    color: `${theme.palette.common.mediumGreen} !important`,
    backgroundColor: `${theme.palette.warning.light} !important`,
    border: `2px solid ${theme.palette.warning.contrast}`,
    boxShadow: 'none',
    fontSize: 12,
  },
  info: {
    color: `${theme.palette.common.mediumGreen} !important`,
    backgroundColor: `${theme.palette.info.light} !important`,
    border: `2px solid ${theme.palette.info.contrast}`,
    boxShadow: 'none',
    fontSize: 12,
  },
  snackBarIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
      '& > div': {
          alignItems: 'flex-start',
      },
      '& #client-snackbar': {
          flex: '1 1 0',
      },
      pointerEvents: 'auto',
  },
}));

type Props = {
  children: React.Node,
};

const StyledSnackbarProvider = (props: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    if (notistackRef.current) {
      notistackRef.current.closeSnackbar(key);
    }
  };

  return (
    <SnackbarProvider
      preventDuplicate
      TransitionComponent={Zoom}
      ref={notistackRef}
      action={(key) => (
        <IconButton aria-label='close' onClick={onClickDismiss(key)}>
          <CloseIcon />
        </IconButton>
      )}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
        root: classes.root,
      }}
      iconVariant={{
        success: <SuccessIcon className={classes.snackBarIcon} htmlColor={theme.palette.success.main} viewBox='0 0 26 26' />,
        error: <AlertIcon className={classes.snackBarIcon} htmlColor={theme.palette.error.main} viewBox='0 0 26 26' />,
        warning: <AlertIcon className={classes.snackBarIcon} htmlColor={theme.palette.warning.main} viewBox='0 0 26 26' />,
        info: <InfoIcon className={classes.snackBarIcon} htmlColor={theme.palette.info.main} viewBox='0 0 26 26' />,
      }}
    >
      {props.children}
    </SnackbarProvider>
  );
};

export default StyledSnackbarProvider;
