// @flow
export const ContactInvitationStatusValues = {
  NotInvited: 'NotInvited',
  Invited: 'Invited',
  Reinvite: 'Reinvite',
  Verified: 'Verified',
  FLAAcceptancePending: 'FLAAcceptancePending',
};
export type ContactInvitationStatus = $Keys<typeof ContactInvitationStatusValues>;

export const SpAccessValues = {
  Enable: 'Enable',
  Disable: 'Disable',
  Invited: 'Invited',
};
export type SpAccess = $Keys<typeof SpAccessValues>;

export type ContactInvitation = {
  name: string,
  mobileNumber: string,
  status: ContactInvitationStatus,
  spAccess: ?SpAccess,
  mobilePhone?: string,
  gcContactId: string,
};

export type ShiftInvitee = {
  ...ContactInvitation,
  index: number,
};

export type FacilityLimits = {
  currentLimit: number,
  approvedLimit: ?number,
  requestedLimit: ?number,
  hasPendingRequest: boolean,
  approvedLimitExpiryDate: ?Date,
};
