// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';
import { updateTermsAndConditionsAgreementSetupCompleted } from '../../components/AccountSetupCheck/actions';

export const {
  signConsentTermsAndConditionsAgreementFulfilled,
  signConsentTermsAndConditionsAgreementPending,
  signConsentTermsAndConditionsAgreementRejected,
  addReferralAccountRelationPending,
  addReferralAccountRelationFulfilled,
  addReferralAccountRelationRejected,
  setCreditLimitOptionPending,
  setCreditLimitOptionFulfilled,
  setCreditLimitOptionRejected,
} = createActions(
  actionTypes.SIGN_CONSENT_TERMS_AND_CONDITIONS_AGREEMENT_FULFILLED,
  actionTypes.SIGN_CONSENT_TERMS_AND_CONDITIONS_AGREEMENT_PENDING,
  actionTypes.SIGN_CONSENT_TERMS_AND_CONDITIONS_AGREEMENT_REJECTED,
  actionTypes.ADD_REFERRAL_ACCOUNT_RELATION_PENDING,
  actionTypes.ADD_REFERRAL_ACCOUNT_RELATION_FULFILLED,
  actionTypes.ADD_REFERRAL_ACCOUNT_RELATION_REJECTED,
  actionTypes.SET_CREDIT_LIMIT_OPTION_PENDING,
  actionTypes.SET_CREDIT_LIMIT_OPTION_FULFILLED,
  actionTypes.SET_CREDIT_LIMIT_OPTION_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const signConsentTermsAndConditionsAgreement = (gcAccountId: string, agreementVersion: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(signConsentTermsAndConditionsAgreementPending());

    return shiftPaymentsApi
      .signConsentTermsAndConditionsAgreement(gcAccountId, agreementVersion)
      .then((response) => {
        return dispatch(signConsentTermsAndConditionsAgreementFulfilled(response.data));
      })
      .catch((error) => {
        return dispatch(signConsentTermsAndConditionsAgreementRejected(error));
      });
  };
};

const addReferralAccountRelation = (buyerGcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(addReferralAccountRelationPending());

  return shiftPaymentsApi
    .addReferralAccountRelation(buyerGcAccountId)
    .then((response) => {
      return dispatch(addReferralAccountRelationFulfilled(response));
    })
    .catch((error) => {
      return dispatch(addReferralAccountRelationRejected(error));
    });
};

const setCreditLimitOption = (gcAccountId: string, isNewLimitBasedOnSales: boolean, newAccountLimit: ?number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setCreditLimitOptionPending());

    return shiftPaymentsApi
      .setCreditLimitOption(gcAccountId, isNewLimitBasedOnSales, newAccountLimit)
      .then((response) => {
        return dispatch(setCreditLimitOptionFulfilled(response));
      })
      .catch((error) => {
        return dispatch(setCreditLimitOptionRejected(error));
      });
  };
};

export default {
  signConsentTermsAndConditionsAgreement,
  updateTermsAndConditionsAgreementSetupCompleted,
  addReferralAccountRelation,
  setCreditLimitOption,
};
