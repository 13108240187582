// @flow
import { PARTITION } from './constants';

const selectPartition = (state: any) => state[PARTITION];
const selectSelectedAccountsResponse = (state: any) => selectPartition(state).selectedAccountsResponse;
const selectSelectedAccountsError = (state: any) => selectPartition(state).selectedAccountsError;

export default {
  selectPartition,
  selectSelectedAccountsResponse,
  selectSelectedAccountsError,
};
