import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';

import WarningTriangleIcon from './svg/WarningTriangleIcon';
import { useAuth } from '../api/authhooks';
import { defaultHandleError } from '../lib/apiHelpers';
import { getSingleSignOnUrlAsync } from '../lib/ssoUtils';
import { PORTAL_TYPE } from '../constants';
import initialDataSelectors from '../components/InitialData/selectors';

const useStyles = makeStyles((theme) => ({
  holder: {
    boxShadow: '0 0 0 0 !important',
    border: '1px solid',
    borderColor: '#F6E3B9',
    backgroundColor: '#FDF8ED',
    marginTop: theme.spacing(4),
    padding: theme.spacing(3, 2),
  },
  heading: {
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    lineHeight: '17px',
  },
  information: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  setupAccountButton: {
    margin: '10px 10px 10px 10px',
  },
  alertIcon: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
}));

const SettlementAccountWarning = () => {
  const classes = useStyles();
  const activeAccount = useSelector((state) => initialDataSelectors.selectActiveAccount(state));
  const { renew } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleSetupSettlementAccount = () => {
    const gcAccountId = activeAccount && activeAccount.gcAccountId;
    renew()
      .then(async () => {
        const ssoUrl = await getSingleSignOnUrlAsync(gcAccountId, PORTAL_TYPE.SHIFTONLINE, false, true);
        window.open(ssoUrl, '_blank');
      })
      .catch(() => {
        defaultHandleError({ enqueueSnackbar }, undefined);
      });
  };

  return (
    <Paper className={classes.holder} data-testid='uia-settlementAccountWarning'>
      <Grid container>
        <Grid item className={classes.alertIcon}>
          <WarningTriangleIcon />
        </Grid>
        <Grid item xs={8} sm container>
          <Grid item xs container direction='column' spacing={2}>
            <Grid item xs>
              <Typography className={classes.heading}>No settlement account</Typography>
              <Typography variant='body2' gutterBottom className={classes.information}>
                Please connect your account. If you&apos;ve just provided your bank details, please log out and log back in again in a few minutes.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.setupAccountButton}>
          <Button
            variant='outlined'
            color='secondary'
            fullWidth
            type='button'
            onClick={() => handleSetupSettlementAccount()}
            data-testid='uia-setupAccount'
          >
            Setup account
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SettlementAccountWarning;
