// @flow
import React, { useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import {getAppInsights, traceInfo} from '../../lib/telemetryUtils';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles(() => ({
  loaderContainer: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    minHeight: '100vh',
    position: 'fixed',
    zIndex: 6969,
  },
}));

type Props = {
  count: number,
  addCount: any,
  subtractCount: any,
};

const GlobalLoadingIndicator = (props: Props) => {
  const classes = useStyles();

  useEffect(() => {
    const axiosRequestListener = axios.interceptors.request.use(
      (config) => {
        //traceInfo below is for investigating the duplicate POST issue  
        if (config && config.url && config.url.includes("api/buyer-accounts/") && config.url.includes("/suppliers") 
          && config.method && config.method.toLowerCase() === "post") 
        {
          const ai = getAppInsights();
                    
          const newRequestId = uuid();
          config.headers['x-request-id-manual'] =  newRequestId;//to allow us to view in browser
          //current requestID is not reset until some condition, eg react re-routes
          //we reset it here, to investigate the duplicate POST issue:
          ai.context.telemetryTrace.traceID = newRequestId;

          //additionally, let's track an event:
          const telemetryItem = { name: 'Custom add supplier' };
          ai.trackEvent(telemetryItem);
            
          traceInfo("In interceptor request listener for url " + config.url + " and method " + config.method  
              + " - UTC time is: " + new Date().toISOString()
              + " - requestId is: " + newRequestId);
        }
        if (!(config && config.headers && config.headers['x-polling'])) {
          props.addCount();
        }
        return config;
      },
      (error) => {
        // $FlowFixMe
        if (!(error && error.config && error.config.headers && error.config.headers['x-polling'])) {
          props.subtractCount();
        }
        return Promise.reject(error);
      }
    );
    const axiosResponseListener = axios.interceptors.response.use(
      (response) => {
        //traceInfo below is for investigating the duplicate POST issue
        if (response && response.config && response.config.url && response.config.url.includes("api/buyer-accounts/") && response.config.url.includes("/suppliers")
          && response.config.method && response.config.method.toLowerCase() === "post")
        {
          const ai = getAppInsights();
          let requestId = '';
          if(ai && ai.context && ai.context.telemetryTrace && ai.context.telemetryTrace.traceID) {
            requestId = ai.context.telemetryTrace.traceID;
            //usually requestId = `${traceId}:{spanId}` but here there is no spanID so we leave out spanID
          }
          traceInfo("In interceptor response listener for url " + response.config.url + " and method " + response.config.method 
              + " - UTC time is: " + new Date().toISOString()
              + " - requestId is: " + requestId);
        }
        if (!(response && response.config && response.config.headers && response.config.headers['x-polling'])) {
          props.subtractCount();
        }
        return response;
      },
      (error) => {
        // $FlowFixMe
        if (!(error && error.config && error.config.headers && error.config.headers['x-polling'])) {
          props.subtractCount();
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.request.eject(axiosRequestListener);
      axios.interceptors.request.eject(axiosResponseListener);
    };
  }, []);

  return props.count > 0 ? (
    <Grid container spacing={0} direction='column' alignItems='center' justify='center' className={classes.loaderContainer}>
      <Grid item xs={3}>
        <CircularProgress size={60} />
      </Grid>
    </Grid>
  ) : null;
};

GlobalLoadingIndicator.defaultProps = {
  count: 0,
};

export default GlobalLoadingIndicator;
