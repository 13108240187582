// @flow
import * as React from 'react';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { debounce } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    border: `1px solid ${theme.palette.grey['300']}`,
    maxHeight: '40vh',
    overflowY: 'scroll',
  },
  suggestion: {
    display: 'block',
  },
  suggestionText: {
    whiteSpace: 'normal',
    padding: theme.spacing(2),
  },
  suggestionHighlighted: {},
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    '& li:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey['300']}`,
    },
  },
});

type Props = {
  classes: any,
  getSuggestions: (keyword: string) => void,
  getSuggestionValue: (row: any) => string,
  onSuggestionSelected: (event: SyntheticKeyboardEvent<any>, data: any) => void,
  suggestions: Array<any>,
  delay: number,
  onKeyPress: (event: SyntheticKeyboardEvent<any>) => void,
  onKeyUp: (event: SyntheticKeyboardEvent<any>) => void,
  autoFocus: boolean,
  fieldValue: string | Object,
  inputProps?: Object,
};

type State = {
  popper: string,
  lastQuery: ?string,
  stateSuggestions: ?Array<any>,
};

class AutoSuggestInput extends React.Component<Props, State> {
  static renderSuggestion(displayText, { query, isHighlighted }, classes) {
    const matches = match(displayText, query);
    const parts = parse(displayText, matches);

    return (
      <MenuItem selected={isHighlighted} component='div' key={query + displayText} className={classes.suggestionText}>
        <div>
          {parts.map((part, i) => {
            const key = part.text + i;
            return (
              <span key={key} style={{ fontWeight: part.highlight ? 500 : 400 }}>
                {part.text}
              </span>
            );
          })}
        </div>
      </MenuItem>
    );
  }

  static renderInputComponent(inputProps) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { inputRef = (_: any) => {}, ref, startAdornment, ...other } = inputProps;

    return (
      <React.Fragment>
        <TextField
          fullWidth
          variant='outlined'
          InputProps={{
            inputRef: (node) => {
              ref(node);
              inputRef(node);
            },
            startAdornment,
          }}
          {...other}
        />
      </React.Fragment>
    );
  }

  anchorEl: ?HTMLElement;

  getSuggestionsDebounced;

  static defaultProps = {
    classes: {},
    getSuggestionValue: null,
    onSuggestionSelected: null,
    suggestions: null,
    delay: 500,
    onKeyPress: null,
    onKeyUp: null,
    autoFocus: false,
    fieldValue: null,
  };

  constructor(props: Props) {
    super(props);

    this.anchorEl = null;
    this.state = {
      popper: '',
      lastQuery: null,
      stateSuggestions: [],
    };

    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
    this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this);
    this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.reset = this.reset.bind(this);

    const { delay, getSuggestions } = this.props;
    this.getSuggestionsDebounced = !delay ? getSuggestions : debounce(getSuggestions, delay);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.fieldValue && prevProps.fieldValue) {
      this.reset();
    }
  }

  /*:: reset: () => void */
  reset() {
    this.setState({
      popper: '',
      lastQuery: null,
      stateSuggestions: [],
    });
  }

  /*:: handleSuggestionsFetchRequested: ({}) => void */
  handleSuggestionsFetchRequested({ value, reason }) {
    if (reason !== 'input-focused') {
      this.getSuggestionsDebounced(value);
    }
  }

  /*:: handleSuggestionsClearRequested: () => void */
  handleSuggestionsClearRequested() {
    this.setState({ stateSuggestions: [] });
  }

  /*:: handleChange: (name: string) => void */
  handleChange(name: string) {
    return (_e, { newValue }) => {
      this.setState({
        [name]: newValue,
      });
    };
  }

  /*:: handleSuggestionSelected: () => void */
  handleSuggestionSelected(e, data) {
    const { getSuggestionValue, onSuggestionSelected } = this.props;
    const lastQuery = getSuggestionValue(data.suggestion);
    onSuggestionSelected(e, data);
    this.setState({ lastQuery });
  }

  render() {
    const { classes, getSuggestionValue, suggestions, onKeyPress, onKeyUp, autoFocus, inputProps } = this.props;
    const { lastQuery, stateSuggestions, popper } = this.state;

    return (
      <div className={classes.root}>
        <Autosuggest
          data-testid='uia-autoSuggest'
          renderInputComponent={AutoSuggestInput.renderInputComponent}
          suggestions={suggestions || stateSuggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          onSuggestionSelected={this.handleSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={(suggestion, params) => {
            const displayText = getSuggestionValue(suggestion);
            return AutoSuggestInput.renderSuggestion(displayText, params, classes);
          }}
          inputProps={{
            id: 'text-autosuggest',
            value: popper || '',
            onChange: this.handleChange('popper'),
            onKeyPress,
            onKeyUp,
            autoFocus,
            inputRef: (node) => {
              this.anchorEl = node;
            },
            ...inputProps,
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
            suggestionHighlighted: classes.suggestionHighlighted,
          }}
          renderSuggestionsContainer={(options) => {
            if (lastQuery === options.query) {
              return null;
            }

            return (
              <Paper square data-testid='uia-suggestionsContainer' {...options.containerProps}>
                {options.children}
              </Paper>
            );
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(AutoSuggestInput);
