// @flow
import moment from 'moment';
import asyncSessionStorageIntervalsAndTimeouts from '../lib/asyncSessionStorageIntervalsAndTimeouts';
import { SESSION_STORAGE_AUTH, AUTH } from '../constants';
import claims from './claims';

class Authentication {
  accessToken: string;

  expiresIn: ?number;

  tokenType: ?string;

  refreshToken: string;

  constructor() {
    if (typeof window !== 'undefined' && window.sessionStorage) {
      const authJson = window.sessionStorage.getItem(SESSION_STORAGE_AUTH);

      if (authJson) {
        const auth: {
          accessToken: string,
          expiresIn: number,
          tokenType: string,
          refreshToken: string,
        } = JSON.parse(authJson);
        this.accessToken = auth.accessToken;
        this.expiresIn = auth.expiresIn;
        this.tokenType = auth.tokenType;
        this.refreshToken = auth.refreshToken;
        return;
      }
    }

    this.accessToken = '';
    this.expiresIn = null;
    this.tokenType = null;
    this.refreshToken = '';
  }

  isLoggedIn() {
    return !!this.accessToken && !!this.tokenType && !this.isTokenExpired();
  }

  getAccessToken() {
    return this.accessToken;
  }

  getRefreshToken() {
    return this.refreshToken;
  }

  isTokenExpired() {
    if (!this.accessToken) {
      return false;
    }

    const jwtValues = claims.get();
    const now = moment().unix();
    const isTokenExpired = jwtValues.exp <= now;
    return isTokenExpired;
  }

  getTokenExpiry() {
    const jwtValues = claims.get();
    const now = moment().unix();
    return jwtValues.exp - now;
  }

  loggedIn(token: { accessToken: string, refreshToken: string, expiresIn?: number, tokenType?: string }) {
    let tokenExpiryFallback = { exp: 0, auth_time: 0 };

    try {
      tokenExpiryFallback = claims.get();
      // eslint-disable-next-line no-empty
    } catch {}

    // When redirecting from rego, we have redirect token and don't have expiry info
    // In this case we need to handle it properly.
    const auth = {
      accessToken: token.accessToken,
      expiresIn: token.expiresIn || tokenExpiryFallback.exp - tokenExpiryFallback.auth_time,
      tokenType: token.tokenType || AUTH.FALLBACK_TOKEN_TYPE,
      refreshToken: token.refreshToken,
    };

    if (typeof window !== 'undefined' && window.sessionStorage) {
      window.sessionStorage.setItem(SESSION_STORAGE_AUTH, JSON.stringify(auth));
    }

    this.accessToken = auth.accessToken;
    this.expiresIn = auth.expiresIn;
    this.tokenType = auth.tokenType;
    this.refreshToken = auth.refreshToken;
  }

  logout() {
    // make sure we have cleared polling before we clear session storage
    asyncSessionStorageIntervalsAndTimeouts
      .clearIntervalsAndTimeoutsThenRemoveItem()
      .catch((error) => {
        console.warn('clearIntervalsAndTimeoutsThenRemoveItem failed', error);
      })
      .finally(() => {
        window.sessionStorage.clear();
        this.accessToken = '';
        this.expiresIn = null;
        this.tokenType = null;
        this.refreshToken = '';
      });
  }

  ssoConfiguration() {
    if (window && window.sessionStorage) {
      return JSON.parse(window.sessionStorage.getItem(SESSION_STORAGE_AUTH));
    }
  }
}

const Auth = new Authentication();

export default Auth;
