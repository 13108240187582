// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(0.5),
  },
  label: {
    overflowWrap: 'break-word',
  },
  value: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
}));

type ResponsiveLabelValueProps = {
  label: string,
  value: any,
  labelVariant?: string,
  valueVariant?: string,
  classes?: any,
  hideValue?: boolean,
  widthProps?: { left: number, right: number },
};

const ResponsiveLabelValue = (props: ResponsiveLabelValueProps) => {
  const { label, value, labelVariant, valueVariant, hideValue, classes, widthProps = { left: 4, right: 8 }, ...others } = props;
  const componentClasses = useStyles();

  return (
    <Grid item xs={12} sm={12}>
      <Grid container>
        <Grid item xs={6} sm={widthProps.left}>
          <Typography variant={labelVariant || 'body2'} className={componentClasses.label}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={widthProps.right}>
          {!hideValue && (
            <Typography
              className={clsx(componentClasses.value, classes && classes.value)}
              variant={valueVariant || 'body2'}
              // $FlowFixMe
              data-testid={others['data-testid']}
            >
              {value}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResponsiveLabelValue;
