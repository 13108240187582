// @flow
import * as React from 'react';
import { makeStyles, Fade } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  containerNoMargin: {
    margin: theme.spacing(0),
  },
  container: {
    margin: theme.spacing(1),
  },
  header: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
    },
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  title?: string,
  noMargin?: boolean,
  children: React.Node,
  className?: string,
  noHeader?: boolean,
  noHeaderButGap?: boolean,
};

const BasicPageLayout = ({ title, children, noMargin, className, noHeader, noHeaderButGap }: Props) => {
  const classes = useStyles();

  return (
    <>
      {title ? <Helmet>
          <title>Shift Trade - {title}</title>
        </Helmet> : null}
      <div
        className={clsx(className, {
          [classes.containerNoMargin]: noMargin,
          [classes.container]: !noMargin,
        })}
      >
        {title && !noHeader ? <Typography variant='h1' className={classes.header}>
            {title}
          </Typography> : null}
        {!title && noHeaderButGap ? <Typography variant='h1' className={classes.header}>
            &nbsp;
          </Typography> : null}
        <Fade in timeout={1000}>
          <div>{children}</div>
        </Fade>
      </div>
    </>
  );
};

export default BasicPageLayout;
