// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';
import { type ReferralAccount } from '../../types';
import { setReferralAccountFromQueryString } from '../InitialData/actions';
const {
  addReferralAccountRelationPending,
  addReferralAccountRelationFulfilled,
  addReferralAccountRelationRejected,
  getAccountNamePending,
  getAccountNameFulfilled,
  getAccountNameRejected,
} = createActions(
  actionTypes.ADD_REFERRAL_ACCOUNT_RELATION_PENDING,
  actionTypes.ADD_REFERRAL_ACCOUNT_RELATION_FULFILLED,
  actionTypes.ADD_REFERRAL_ACCOUNT_RELATION_REJECTED,
  actionTypes.GET_ACCOUNT_NAME_FULFILLED,
  actionTypes.GET_ACCOUNT_NAME_PENDING,
  actionTypes.GET_ACCOUNT_NAME_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const addReferralAccountRelation = (gcAccountId: string, referralAccount: ReferralAccount) => (dispatch: Dispatch<any>) => {
  dispatch(addReferralAccountRelationPending());
  dispatch(setReferralAccountFromQueryString(null));
  return shiftPaymentsApi
    .addReferralAccountRelation(gcAccountId, referralAccount)
    .then((response) => {
      return dispatch(addReferralAccountRelationFulfilled(response));
    })
    .catch((error) => {
      return dispatch(addReferralAccountRelationRejected(error));
    });
};

const getAccountName = (gcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(getAccountNamePending());

  return shiftPaymentsApi
    .getAccountName(gcAccountId)
    .then((response) => {
      return dispatch(getAccountNameFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getAccountNameRejected(error));
    });
};

export default {
  addReferralAccountRelation,
  getAccountName,
};
