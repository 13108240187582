// @flow
import React from 'react';
import { Redirect, Route } from 'react-router';

type Props = {
  component: any,
  condition: ?boolean,
  exact: boolean,
  path: string | string[],
  componentProps?: any,
};

export const ConditionalRoute = (props: Props) => {
  const {
    // eslint-disable-next-line react/prop-types
    component: Component,
    condition,
    exact,
    path,
    componentProps,
  } = props;
  return <Route exact={exact} path={path} render={(p) => (condition ? <Component {...p} {...componentProps} /> : <Redirect to='/404' />)} />;
};
