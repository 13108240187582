import { createActions } from 'redux-actions';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';

export const { loginBankAccountUserMfaPending, loginBankAccountUserMfaFulfilled, loginBankAccountUserMfaRejected, setBankLoginAccountResult } =
  createActions(
    actionTypes.LOGIN_BANK_ACCOUNT_USER_MFA_PENDING,
    actionTypes.LOGIN_BANK_ACCOUNT_USER_MFA_FULFILLED,
    actionTypes.LOGIN_BANK_ACCOUNT_USER_MFA_REJECTED,
    actionTypes.SET_BANK_LOGIN_ACCOUNT_RESULT,
    getActionOptions(ACTION_PREFIX)
  );

const bankLoginMfa = (values) => (dispatch) => {
  dispatch(loginBankAccountUserMfaPending());
  return shiftPaymentsApi.bankMfaLogin(values);
};

export default {
  bankLoginMfa,
  loginBankAccountUserMfaPending,
  loginBankAccountUserMfaFulfilled,
  loginBankAccountUserMfaRejected,
  setBankLoginAccountResult,
};
