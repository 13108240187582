// @flow
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { withFormik, Form } from 'formik';
import { withRouter, Redirect, type RouterHistory } from 'react-router';
import { Button, Typography } from '@material-ui/core';
import type { FormikBag } from 'formik';
import { withSnackbar } from 'notistack';
import withWidth from '@material-ui/core/withWidth';

import SetupPageLayout from '../../components/SetupPageLayout';
import { formSchema } from './validations';
import AgreementContent from '../../components/AgreementContent';
import { defaultHandleError } from '../../lib/apiHelpers';
import { type AccountSetupStatus } from '../../components/AccountSetupCheck/reducer';
import { type Account } from '../../types';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(3, 2, 0, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 120,
    },
  },
  borrowerName: {
    margin: theme.spacing(2, 0),
  },
  checkbox: {
    paddingTop: 0,
    alignSelf: 'baseline',
  },
}));

type FormValues = {};

type Props = {
  gcAccountId: string,
  contactFullName: string,
  loanAgreementSetup: AccountSetupStatus,
  isLoanAgreementSetupComplete: boolean,
  activeAccount: Account,
};

type WithHOCProps = {
  ...Props,
  isSubmitting: boolean,
};

const LoanAgreement = ({ contactFullName, loanAgreementSetup, isSubmitting, isLoanAgreementSetupComplete }: WithHOCProps) => {
  const classes = useStyles();
  const hasAgreementFailedToLoad = loanAgreementSetup && !loanAgreementSetup.data;

  if (hasAgreementFailedToLoad || isLoanAgreementSetupComplete) {
    return <Redirect to='/' />;
  }

  // $FlowFixMe
  const agreementText = loanAgreementSetup.data.base64text;

  return (
    <SetupPageLayout
      title='Facility agreement'
      subtitle={
        'Please read the following agreement carefully before continuing. By agreeing electronically, ' +
        'you acknowledge that you have both read and understood all text presented to you.'
      }
    >
      <Form noValidate autoComplete='off'>
        {loanAgreementSetup ? <AgreementContent base64Text={agreementText} height='45vh' /> : null}
        <Typography variant='h5' className={classes.borrowerName}>
          The borrower: {contactFullName}
        </Typography>
        <Typography variant='body1'>
          {`By clicking “I agree”, I ${contactFullName} agree to the Facility Agreement above. 
          I also acknowledge and agree that I am personally liable as Guarantor for all of the borrower’s obligation outlined in this Facility Agreement.`}
        </Typography>
        <Button
          className={classes.button}
          variant='contained'
          color='primary'
          type='submit'
          disabled={isSubmitting || hasAgreementFailedToLoad}
          data-testid='uia-agreeButton'
        >
          I agree
        </Button>
      </Form>
    </SetupPageLayout>
  );
};

type WrapperProps = {
  ...WithHOCProps,
  signLoanAgreement: (gcAccountId: string, agreementVersion: string, isRequireDataRefresh: boolean) => Promise<any>,
  updateLoanAgreementSetupCompleted: (value: boolean) => void,
  autoApproveSupplierRequest: (gcAccountId: string) => Promise<any>,
  updateOpenTradeAccount: ({ isOpenTradeAccount: boolean, attempt: number }) => void,
  getInitialData: () => void,
};

type WithHOCWrapperProps = {
  ...WrapperProps,
  history: RouterHistory,
};

export default withRouter<WrapperProps>(
  withSnackbar(
    withFormik({
      validateOnBlur: false,
      validationSchema: formSchema,
      handleSubmit: (values: FormValues, formikBag: FormikBag<WithHOCWrapperProps, FormValues>) => {
        const { props } = formikBag;
        // if sign by supplier, require to refresh data.
        const isRequireDataRefresh = !props.activeAccount.isBuyer && props.activeAccount.isSupplier;

        props
          .signLoanAgreement(
            props.gcAccountId,
            // $FlowFixMe
            props.loanAgreementSetup.data.currentActiveAgreementVersion,
            isRequireDataRefresh
          )
          .then((response) => {
            formikBag.setSubmitting(false);
            if (response.error) {
              defaultHandleError(formikBag.props);
              return;
            }
            props.autoApproveSupplierRequest(props.gcAccountId);
            if (isRequireDataRefresh) {
              formikBag.props.updateOpenTradeAccount({ isOpenTradeAccount: isRequireDataRefresh, attempt: 1 });
            }
            formikBag.props.updateLoanAgreementSetupCompleted(true);
          });
      },
      displayName: 'LoanAgreement',
    })(withWidth()(LoanAgreement))
  )
);
