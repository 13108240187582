import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import InitialData from './Component';
import initialDataSelectors from '../InitialData/selectors';
import actions from './actions';
import selectors from './selectors';

const mapStateToProps = createSelector(
  initialDataSelectors.selectActiveAccount,
  selectors.selectRolePermissionMap,
  (activeAccount, rolePermissionMap) => ({
    activeAccountRoles: activeAccount ? activeAccount.roles : [],
    gcAccountId: activeAccount ? activeAccount.gcAccountId : null,
    rolePermissionMap,
  })
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InitialData);
