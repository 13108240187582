// @flow
import { MAXIMUM_CREDIT_LIMIT } from '../../constants';

export const ACTION_PREFIX = 'WELCOME_PAGE';
export const PARTITION = 'welcomePage';

export const actionTypes = {
  SIGN_CONSENT_TERMS_AND_CONDITIONS_AGREEMENT_PENDING: 'SIGN_CONSENT_TERMS_AND_CONDITIONS_AGREEMENT_PENDING',
  SIGN_CONSENT_TERMS_AND_CONDITIONS_AGREEMENT_FULFILLED: 'SIGN_CONSENT_TERMS_AND_CONDITIONS_AGREEMENT_FULFILLED',
  SIGN_CONSENT_TERMS_AND_CONDITIONS_AGREEMENT_REJECTED: 'SIGN_CONSENT_TERMS_AND_CONDITIONS_AGREEMENT_REJECTED',
  ADD_REFERRAL_ACCOUNT_RELATION_PENDING: 'ADD_REFERRAL_ACCOUNT_RELATION_PENDING',
  ADD_REFERRAL_ACCOUNT_RELATION_FULFILLED: 'ADD_REFERRAL_ACCOUNT_RELATION_FULFILLED',
  ADD_REFERRAL_ACCOUNT_RELATION_REJECTED: 'ADD_REFERRAL_ACCOUNT_RELATION_REJECTED',
  SET_CREDIT_LIMIT_OPTION_PENDING: 'SET_CREDIT_LIMIT_OPTION_PENDING',
  SET_CREDIT_LIMIT_OPTION_FULFILLED: 'SET_CREDIT_LIMIT_OPTION_FULFILLED',
  SET_CREDIT_LIMIT_OPTION_REJECTED: 'SET_CREDIT_LIMIT_OPTION_REJECTED',
  GET_APPROVED_CREDIT_LIMIT_PENDING: 'GET_APPROVED_CREDIT_LIMIT_PENDING',
  GET_APPROVED_CREDIT_LIMIT_FULFILLED: 'GET_APPROVED_CREDIT_LIMIT_FULFILLED',
  GET_APPROVED_CREDIT_LIMIT_REJECTED: 'GET_APPROVED_CREDIT_LIMIT_REJECTED',
};

export const CreditLimitOption = {
  RecommendMeBasedOnSales: 1,
  LetMeChoose: 2,
  NoChange: 3,
};

export const StepInUserJourney = {
  SetupBankAccount: 0,
  AcceptFacilityAgreement: 1,
};

export const limitChangeRequestOptions = [
  { id: CreditLimitOption.RecommendMeBasedOnSales, text: 'Based on my sales' },
  { id: CreditLimitOption.LetMeChoose, text: 'Let me choose' },
  { id: CreditLimitOption.NoChange, text: 'Proceed with starting limit' },
];

export const consts = {
  maxCreditLimitUserCanSelect: MAXIMUM_CREDIT_LIMIT,
};
