// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  theme: any,
};

const SuccessOutlinedIcon = withTheme((props: Props) => (
  <SvgIcon style={{ alignSelf: 'baseline' }} viewBox='0 0 54 54' {...(props: any)}>
    <defs>
      <circle id='path-1' cx='27' cy='27' r='27' />
    </defs>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='D---Supplier---success' transform='translate(-704.000000, -237.000000)'>
        <g id='tick' transform='translate(704.000000, 237.000000)'>
          <g id='Icon-Circle'>
            <g id='Oval'>
              <mask id='mask-2' fill='white'>
                <use xlinkHref='#path-1' />
              </mask>
              <circle stroke={props.theme.palette.success.main} strokeWidth='2.25' cx='27' cy='27' r='25.875' />
            </g>
          </g>
          <g
            id='Icon-Circle'
            transform='translate(14.400000, 15.750000)'
            fill={props.theme.palette.success.main}
            stroke={props.theme.palette.common.white}
            strokeWidth='1.6875'
          >
            <g id='Element/Icon/Check'>
              <path d='M25.2483783,5.24099263 L10.4125118,20.8376303 C9.45701459,21.8421247 7.91013542,21.8421247 6.9570819,20.8376303 L0.75979031,14.3251156 C-0.193263208,13.3206213 -0.193263208,11.6944194 0.75979031,10.689925 C1.71528756,9.68799968 3.26216673,9.68799968 4.21522024,10.689925 L8.68479687,15.3886979 L21.7929483,1.60837104 C22.7460019,0.603876665 24.292881,0.603876665 25.2483783,1.60837104 C26.2038755,2.61029637 26.2038755,4.23649825 25.2483783,5.24099263' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
));

export default SuccessOutlinedIcon;
