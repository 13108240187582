// @flow
import React from 'react';
import { type RouterHistory, withRouter } from 'react-router-dom';

import claims from '../../api/claims';

type Props = {
  jwtValues: ?Object,
  accounts: ?Array<Object>,
  setJwtValues: (jwtValues: Object) => void,
  getInitialData: (jwtValues: Object) => Promise<any>,
  history: RouterHistory,
};

class InitialData extends React.Component<Props, {}> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const jwtValues = claims.get();
    this.props.setJwtValues(jwtValues);
  }

  componentDidUpdate(prevProps: Props) {
    const jwtValues = claims.get();

    if (
      (!prevProps.jwtValues && prevProps.jwtValues !== this.props.jwtValues) ||
      (!prevProps.accounts && prevProps.accounts !== this.props.accounts)
    ) {
      this.props.getInitialData(jwtValues).then((response) => {
        if (response.error) {
          this.props.history.push('/500');
        }
      });
    }
  }

  render() {
    return null;
  }
}

export default withRouter(InitialData);
