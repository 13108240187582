/* eslint-disable react/display-name */
// @flow
import { Box, Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import { Button } from '@shiftfinancial/design-system';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { type AuthorizationStatus, AuthorizationStatusValues, type OrderBy } from '../../api/shiftPaymentsApi';
import { Permission, STANDARD_DATE_FORMAT } from '../../constants';
import { Can } from '../../lib/ability';
import { defaultHandleError } from '../../lib/apiHelpers';
import { enrichColumnsWithBodyRenders, getColumnIndex } from '../../lib/dataGridUtils';
import { formatSupplierTerm } from '../../lib/formatter';
import AbnText from '../AbnText';
import { BankLinkDialog } from '../BankLinkDialog/BankLinkDialog';
import ConfirmationDialog from '../ConfirmationDialog';
import PhoneNumberText from '../PhoneNumberText';
import RemoteDataGrid from '../RemoteDataGrid';
import StyledSwitch from '../StyledSwitch';

const useStyles = makeStyles((theme) => ({
  box: {
    margin: '2% 0',
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
    },
  },
  mobileColumnLabel: {
    marginBottom: theme.spacing(0.5),
    fontSize: 12,
  },
  mobileColumnValue: {
    fontWeight: 500,
    fontSize: 14,
  },
  mobileRowDataLine: {
    marginBottom: theme.spacing(2),
  },
  mobileRowStatusLine: {
    marginTop: theme.spacing(2),
  },
  mobileRowAccountLine: {
    marginBottom: theme.spacing(2),
  },
  paymentOrReviewButton: {
    width: '100%',
  },
  nonActionLabelBox: {
    padding: theme.spacing(0.5, 1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.2, 1),
    },
    borderRadius: 4,
    textTransform: 'uppercase',
    width: '100%',
    textAlign: 'center',
    backgroundColor: theme.palette.warning.light,
    fontSize: 10,
  },
}));

const columns = [
  {
    name: 'abn',
    label: 'Supplier details',
    options: {
      sort: false,
    },
  },
  {
    name: 'accountId',
    options: {
      sort: false,
      display: false,
    },
  },
  {
    name: 'accountName',
    options: {
      sort: false,
      display: false,
    },
  },
  {
    name: 'contactFirstName',
    label: 'Contact name',
    options: {
      sort: true,
      display: false,
    },
  },
  {
    name: 'contactLastName',
    options: {
      sort: false,
      display: false,
    },
  },
  {
    name: 'mobileNumber',
    label: 'Mobile',
    options: {
      sort: false,
      display: false,
    },
  },
  {
    name: 'defaultTermsInDays',
    label: 'Terms',
    options: {
      sort: true,
    },
  },
  {
    name: 'termPeriod',
    label: 'termPeriod',
    options: {
      display: false,
    },
  },
  {
    name: 'authorizationStatus',
    label: 'Auto-billing',
    options: {
      sort: false,
    },
  },
  {
    name: 'status',
    label: 'Action',
    options: {
      sort: false,
    },
  },
  {
    name: 'vendorAccountVerificationStatus',
    options: {
      sort: false,
      display: false,
    },
  },
];

const abnIndex = getColumnIndex(columns, 'abn');
const accountNameIndex = getColumnIndex(columns, 'accountName');
const accountIdIndex = getColumnIndex(columns, 'accountId');
const contactLastNameIndex = getColumnIndex(columns, 'contactLastName');
const defaultTermsInDaysIndex = getColumnIndex(columns, 'defaultTermsInDays');
const termPeriodIndex = getColumnIndex(columns, 'termPeriod');
const authorizationStatusIndex = getColumnIndex(columns, 'authorizationStatus');
const vendorAccountVerificationStatusIndex = getColumnIndex(columns, 'vendorAccountVerificationStatus');
const statusIndex = getColumnIndex(columns, 'status');

type Props = {
  gcAccountId: string,
  isActionBlocked: boolean,
  getPublicSuppliers: (gcAccountId: string, skip: number, limit: number, orderBy: ?Array<OrderBy>) => Promise<any>,
  setSupplierAuthorization: (
    buyerGcAccountId: string,
    supplierGcAccountId: string,
    authorizationStatus: AuthorizationStatus,
    reason: ?string
  ) => Promise<any>,
  isBankConnectRequired: ?boolean,
};

type PendingAuthorizationAction = {
  supplierGcAccountId: string,
  supplierAccountName: string,
  cancelAction: () => void,
};

const PublicSuppliersGrid = (props: Props) => {
  const { gcAccountId, isActionBlocked, getPublicSuppliers, setSupplierAuthorization, isBankConnectRequired } = props;
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [pendingAuthorizationAction, setPendingAuthorizationAction] = useState<?PendingAuthorizationAction>(null);
  const [inputDialogBoxFieldValue, setInputDialogBoxFieldValue] = useState(null);
  const [bankConnectReinforcementDialog, setBankConnectReinforcementDialog] = useState(false);

  const getData = (skip: number, limit: number, orderBy: Array<OrderBy>) => {
    return getPublicSuppliers(gcAccountId, skip, limit, orderBy);
  };

  const setAuthorization = (supplierGcAccountId: string, supplierAccountName: string, authorizationStatus: AuthorizationStatus, reason: ?string) => {
    setSupplierAuthorization(gcAccountId, supplierGcAccountId, authorizationStatus, reason).then((response) => {
      const { error } = response;

      if (error) {
        defaultHandleError({ enqueueSnackbar }, undefined);
        return;
      }

      if (authorizationStatus === AuthorizationStatusValues.NotRequired) {
        enqueueSnackbar(
          `Auto-billing has been successfully enabled for ${supplierAccountName}. All future transactions will be authorised automatically.`,
          { variant: 'success', key: supplierAccountName }
        );
      } else {
        enqueueSnackbar(
          `Auto-billing has been successfully disabled for ${supplierAccountName}. You will need to review and approve all future transactions.`,
          { variant: 'success', key: supplierAccountName }
        );
        setPendingAuthorizationAction(null);
      }
    });
  };

  const closeConfirmation = (confirmation: boolean) => {
    if (!pendingAuthorizationAction) {
      return;
    }

    if (confirmation) {
      setAuthorization(
        pendingAuthorizationAction.supplierGcAccountId,
        pendingAuthorizationAction.supplierAccountName,
        AuthorizationStatusValues.Required,
        inputDialogBoxFieldValue
      );
    } else {
      if (pendingAuthorizationAction) {
        pendingAuthorizationAction.cancelAction();
      }
      setPendingAuthorizationAction(null);
    }
  };

  const handleChange = (checked: boolean, supplierGcAccountId: string, supplierAccountName: string, cancelAction: () => void) => {
    if (checked) {
      setAuthorization(
        supplierGcAccountId,
        supplierAccountName,
        checked ? AuthorizationStatusValues.NotRequired : AuthorizationStatusValues.Required,
        ''
      );
    } else {
      setPendingAuthorizationAction({ supplierGcAccountId, supplierAccountName, cancelAction });
    }
  };

  const goToReviewScreen = (accountId) => {
    history.push(`/buyer/requests/${accountId}`);
  };

  const goToPaymentScreen = (accountId) => {
    if (isBankConnectRequired) {
      setBankConnectReinforcementDialog(true);
      return;
    }
    history.push(`/buyer/payments/pay/${accountId}`);
  };

  const actionAvailableForEachSupplier = (rowData) => {
    const isAccepted = rowData[statusIndex] === 'Accepted';
    const isVendorAccountPending = rowData[vendorAccountVerificationStatusIndex] === 'Pending verification';
    if (isAccepted) {
      if (isVendorAccountPending) {
        return (
          <Box className={classes.nonActionLabelBox}>
            <Typography>Pending</Typography>
          </Box>
        );
      }
      return (
        <Can I={Permission.Actions.Create} a={Permission.Buyer.Invoice}>
          {() => (
            <Button
              size='small'
              appearance='primary'
              data-testid='uia-pay'
              className={classes.paymentOrReviewButton}
              disabled={isActionBlocked}
              onClick={() => goToPaymentScreen(rowData[accountIdIndex])}
            >
              Pay
            </Button>
          )}
        </Can>
      );
    }
    return (
      <Can I={Permission.Actions.Review} a={Permission.Buyer.SupplierRequest}>
        {() => (
          <Button
            className={classes.paymentOrReviewButton}
            size='small'
            appearance='primary'
            data-testid='uia-reviewButton'
            disabled={isActionBlocked}
            onClick={() => goToReviewScreen(rowData[accountIdIndex])}
          >
            Review
          </Button>
        )}
      </Can>
    );
  };
  const desktopColumnCustomBodyRenders = {
    abn: (value, { rowData }) => (
      <React.Fragment>
        <AbnText value={value} />
        <Typography>{rowData[accountNameIndex]}</Typography>
      </React.Fragment>
    ),
    contactFirstName: (value, { rowData }) => <Typography>{`${value} ${rowData[contactLastNameIndex]}`}</Typography>,
    mobileNumber: (value) => <PhoneNumberText value={value} />,
    defaultTermsInDays: (value, { rowData }) => <Typography>{formatSupplierTerm(value, rowData[termPeriodIndex])}</Typography>,
    authorizationStatus: (value, { rowData }) => (
      <StyledSwitch
        onChange={(e, cancelAction) => handleChange(e, rowData[accountIdIndex], rowData[accountNameIndex], cancelAction)}
        checked={value === AuthorizationStatusValues.NotRequired}
        data-testid='uia-authorizationStatus'
      />
    ),
    acceptedDate: (value) => {
      if (value) {
        return <Typography>{moment(value).format(STANDARD_DATE_FORMAT)}</Typography>;
      }
      return null;
    },
    status: (value, { rowData }) => {
      return actionAvailableForEachSupplier(rowData);
    },
  };

  const handleInputFieldChange = (e) => {
    setInputDialogBoxFieldValue(e.target.value);
  };

  const mobileRowRender = (rowData) => {
    const abn = rowData[abnIndex];
    const accountName = rowData[accountNameIndex];
    const accountId = rowData[accountIdIndex];
    const defaultTermsInDays = rowData[defaultTermsInDaysIndex];
    const termPeriod = rowData[termPeriodIndex];
    const authorizationStatus = rowData[authorizationStatusIndex];
    const actionAvailableForSupplier = actionAvailableForEachSupplier(rowData);

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} className={classes.mobileRowAccountLine}>
            <AbnText value={abn} />
            <Typography variant='body1' className={classes.mobileColumnValue}>
              {accountName}
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.mobileColumnLabel}>
            Terms
          </Grid>
          <Grid item xs={8} className={classes.mobileColumnLabel}>
            Auto-billing
          </Grid>
          <Grid item xs={4}>
            <Typography variant='body1' className={classes.mobileColumnValue}>
              {formatSupplierTerm(defaultTermsInDays, termPeriod)}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='body1' className={classes.mobileColumnValue}>
              <StyledSwitch
                onChange={(e, cancelAction) => handleChange(e, accountId, accountName, cancelAction)}
                checked={authorizationStatus === AuthorizationStatusValues.NotRequired}
                data-testid='uia-authorizationStatus'
              />
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.mobileRowStatusLine}>
            {actionAvailableForSupplier}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    gcAccountId && (
      <>
        {bankConnectReinforcementDialog ? (
          <BankLinkDialog
            onClose={() => {
              setBankConnectReinforcementDialog(false);
            }}
          />
        ) : null}
        <div className={classes.container}>
          <RemoteDataGrid
            getData={getData}
            columns={enrichColumnsWithBodyRenders(columns, desktopColumnCustomBodyRenders)}
            mobileRowRender={mobileRowRender}
            data-testid='uia-publicSuppliersGrid'
            defaultOrderBy={[{ columnName: 'accountName', isAscending: true }]}
          />
        </div>
        {pendingAuthorizationAction ? (
          <ConfirmationDialog
            isOpen={!!pendingAuthorizationAction}
            onClose={() => closeConfirmation(false)}
            onConfirm={() => closeConfirmation(true)}
            title='Auto-billing'
            contentText={`Are you sure you want to disable auto-billing for ${pendingAuthorizationAction.supplierAccountName}?`}
            id='confirmation'
            showChildren
          >
            <>
              <TextField
                data-testid='uia-dialogInput'
                multiline
                placeholder='Please let us know the reason'
                rows={2}
                variant='outlined'
                fullWidth
                onChange={(e) => handleInputFieldChange(e)}
                autoFocus
                autoComplete='off'
                inputProps={{
                  maxLength: 200,
                }}
              />
              <Typography variant='subtitle1'>
                {200 - (inputDialogBoxFieldValue ? inputDialogBoxFieldValue.length : 0)} character(s) remaining
              </Typography>
            </>
          </ConfirmationDialog>
        ) : null}
      </>
    )
  );
};

export default PublicSuppliersGrid;
