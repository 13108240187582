// @flow
// $FlowFixMe
import { batch } from 'react-redux';
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import { setIsAllKycCompletedWithSessionStorage } from '../InitialData/actions';
import { resetAccountSetup } from '../AccountSetupCheck/actions';
import { updateOpenTradeAccount } from '../UserSelectProfileMode/actions';

export const { updateActiveAccountIndex } = createActions(actionTypes.UPDATE_ACTIVE_ACCOUNT_INDEX, getActionOptions(ACTION_PREFIX));

export const updateActiveAccount = (index: number) => {
  return (dispatch: Dispatch<any>) => {
    batch(() => {
      dispatch(resetAccountSetup());
      dispatch(updateActiveAccountIndex(index));
    });
  };
};

export default {
  updateActiveAccount,
  setIsAllKycCompletedWithSessionStorage,
  updateOpenTradeAccount,
};
