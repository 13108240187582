import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography, Zoom } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@shiftfinancial/design-system';
import { useHistory } from 'react-router-dom';
import { traceInfo } from '../../lib/telemetryUtils';
import ErrorIcon from '../svg/ErrorIcon';
import { useSelector } from 'react-redux';
import initialDataSelectors from '../InitialData/selectors';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  errorIcon: {
    textAlign: 'center',
    marginTop: theme.spacing(7.5),
  },
  title: {
    //@ts-ignore
    color: theme.palette.common.mediumGreen,
    marginTop: theme.spacing(3),
    fontWeight: 500,
    fontSize: theme.spacing(2.5),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  contentText: {
    //@ts-ignore
    color: theme.palette.common.mediumGreen,
    paddingTop: theme.spacing(0),
    textAlign: 'center',
    fontWeight: 400,
  },
  secondaryContentText: {
    //@ts-ignore
    color: theme.palette.common.mediumGreen,
    paddingTop: theme.spacing(0),
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 400,
  },
  actionButtons: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(3),
    },
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(9),
  },
}));

export type BankLinkDialogProps = {
  onClose: () => void;
};

export const BankLinkDialog = (props: BankLinkDialogProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { onClose } = props;
  const gcAccountId = useSelector(initialDataSelectors.selectSelectedGcAccountId);

  traceInfo(`Showing enforce bank link popup for ${gcAccountId}`);

  const onConnectBank = () => {
    traceInfo(`Moving from enforce bank link popup to linking a bank account page for ${gcAccountId}`);
    history.push('/settings/add-bank');
  };

  return (
    <Dialog open={true} TransitionComponent={Zoom} maxWidth={'sm'}>
      <Grid item className={classes.errorIcon}>
        <ErrorIcon />
      </Grid>
      <DialogTitle className={classes.title} disableTypography>
        Bank account needed
        <IconButton aria-label='close' data-testid='uia-bank-link-model-close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid className={classes.contentText}>
          <Typography variant='body1'>Please connect your business bank account to proceed.</Typography>
        </Grid>
        <Grid>&nbsp;</Grid>
        <Grid>
          <Typography variant='body1' className={classes.secondaryContentText}>
            If you connected a bank in the last 10 mins, please let the system to update. Allow 2 business days if you uploaded bank statements.
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <Button appearance='primary' data-testid='uia-bank-link-model-connect' onClick={onConnectBank}>
          Connect bank account
        </Button>
      </DialogActions>
    </Dialog>
  );
};
