// @flow
import React, { useEffect } from 'react';
import { Divider, Grid, Hidden, List, ListItem, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, withFormik, Field, type FormikBag } from 'formik';

import SuccessMessage from './SuccessMessage';
import FormikPhoneNumberField from './formik/FormikPhoneNumberField';
import { type ShiftInvitee, ContactInvitationStatusValues } from '../pages/Buyer/Dashboard/types';
import { inviteeFormSchema } from '../lib/validations';

const useStyles = makeStyles((theme) => ({
  tab: {
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
  divider: {
    margin: theme.spacing(0, -2),
    backgroundColor: theme.palette.grey['300'],
  },
  inviteesList: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(4, -2, -4, -2),
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(4, -2, -4, -2),
    },
  },
  inviteeListItem: {
    padding: theme.spacing(2, 1),
  },
  inviteeName: {
    display: 'inline-flex',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  sendInvitationButton: {
    padding: theme.spacing(0, 0, 0, 1),
    '& button': {
      padding: theme.spacing(1),
    },
  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },
}));

type InviteeListProps = {
  invitees: ?Array<ShiftInvitee>,
  handleInviteRequest: (shiftInvite: ShiftInvitee) => void,
};

export const InviteeList = (props: InviteeListProps) => {
  const classes = useStyles();
  const { invitees, handleInviteRequest } = props;

  if (!invitees || invitees.length === 0) {
    return null;
  }

  return (
    <Grid className={classes.inviteesList}>
      <List data-testid='uia-inviteesList'>
        <Hidden smDown>
          <ListItem>
            <Grid item sm={12} md={5}>
              <Typography className={classes.label} variant='h6' component='label'>
                Name
              </Typography>
            </Grid>
            <Grid item sm={12} md={5}>
              <Typography className={classes.label} variant='h6' component='label'>
                Mobile Number
              </Typography>
            </Grid>
            <Grid item sm={12} md={2} />
          </ListItem>
        </Hidden>
        {invitees.map((invitee: ShiftInvitee, dindex) => {
          const key = invitee.gcContactId || `${dindex}.${invitee.name}`;
          return (
            <React.Fragment key={key}>
              <Divider className={classes.divider} />
              <ListItem key={key} className={classes.inviteeListItem}>
                <Invitee first={dindex === 0} index={dindex} values={{ ...invitee }} handleInviteRequest={handleInviteRequest} />
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
    </Grid>
  );
};

type InviteeFormValues = ShiftInvitee;
type InviteeFormProps = {
  first: boolean,
  values: InviteeFormValues,
  index: number,
  handleInviteRequest: (invitee: ShiftInvitee) => void,
};

const formattedMobileNumber = (mobileNumber: string) => {
  return mobileNumber && mobileNumber.length === 10 && mobileNumber.startsWith('04') ? mobileNumber.substr(1) : mobileNumber;
};

const Invitee = withFormik<InviteeFormProps, InviteeFormValues>({
  displayName: 'InviteeList',
  enableReinitialize: true,
  mapPropsToValues: ({ values }): InviteeFormValues => ({
    ...values,
    mobileNumber: formattedMobileNumber(values.mobileNumber) || '',
  }),
  validateOnBlur: false,
  // eslint-disable-next-line no-unused-vars
  handleSubmit: (values: InviteeFormValues, formikBag: FormikBag<InviteeFormProps, InviteeFormValues>) => {
    formikBag.props.handleInviteRequest(values);
  },
  validationSchema: inviteeFormSchema,
})((props: InviteeFormProps) => {
  const classes = useStyles();
  const { values: invitee, first, index } = props;
  if (!invitee) {
    return null;
  }

  const autoFocusProp = {};
  if (first) {
    autoFocusProp.autoFocus = true;
  }
  const [shouldPreventReInvite, setShouldPreventReInvite] = React.useState(false);
  useEffect(() => {
    const preventReInvite = invitee.status === ContactInvitationStatusValues.Invited;
    setShouldPreventReInvite(preventReInvite);
  }, [invitee.status]);

  return (
    <Form noValidate autoComplete='off' data-testid='uia-invitee' style={{ width: '100%' }}>
      <Grid container className={classes.container}>
        <Grid item xs={12} md={5} className={classes.inviteeName}>
          <Typography variant='subtitle2' component='label'>
            {invitee.name}
          </Typography>
        </Grid>
        {invitee.status === ContactInvitationStatusValues.Verified || invitee.status === ContactInvitationStatusValues.FLAAcceptancePending ? (
          <Grid item xs={12} sm={12} md={7}>
            <SuccessMessage message={invitee.status === ContactInvitationStatusValues.Verified ? 'Verified' : 'Loan Agreement acceptance pending'} />
          </Grid>
        ) : (
          <React.Fragment>
            <Grid item xs={7} sm={7} md={5}>
              <Field
                component={FormikPhoneNumberField}
                variant='outlined'
                id={`inviteeMobileNumber-${index}`}
                name='mobileNumber'
                fullWidth
                pattern='^4[0-9]{2} [0-9]{3} [0-9]{3}'
                format='### ### ###'
                InputProps={{
                  classes: {
                    root: classes.inputBase,
                  },
                }}
                {...autoFocusProp}
                data-testid='uia-inviteeMobileNumber'
                value={formattedMobileNumber(props.values.mobileNumber)}
                disabled={shouldPreventReInvite}
                placeholder='Enter mobile number'
              />
            </Grid>
            <Grid item xs={5} sm={5} md={2} className={classes.sendInvitationButton}>
              <Button
                fullWidth
                size='large'
                variant='outlined'
                color='primary'
                type='submit'
                data-testid='uia-buttonInvite'
                disabled={shouldPreventReInvite}
              >
                {invitee.status === ContactInvitationStatusValues.Invited || invitee.status === ContactInvitationStatusValues.Reinvite
                  ? 'Re-invite'
                  : 'Invite'}
              </Button>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Form>
  );
});

export default InviteeList;
