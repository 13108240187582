import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Component from './Component';
import actions from './actions';
import { selectors as initialDataSelectors } from '../../components/InitialData';
import { selectors as accountSetupCheckSelectors } from '../../components/AccountSetupCheck';
import { SpStatus } from '../../types';
import initialDataActions from '../../components/InitialData/actions';

const mapStateToProps = createSelector(
  initialDataSelectors.selectActiveAccount,
  initialDataSelectors.selectFullName,
  accountSetupCheckSelectors.selectLoanAgreementSetup,
  initialDataActions.getInitialData,
  (activeAccount, contactFullName, loanAgreementSetup) => ({
    gcAccountId: activeAccount.gcAccountId,
    contactFullName,
    loanAgreementSetup,
    isLoanAgreementSetupComplete: loanAgreementSetup.isCompleted && activeAccount.spStatus.toLowerCase() !== SpStatus.NotSigned,
    activeAccount,
  })
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Component);
