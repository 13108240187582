// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  htmlColor: string,
  theme: any,
};

const AvatarIcon = withTheme((props: Props) => (
  <SvgIcon viewBox='0 0 25 25' {...(props: any)}>
    <defs>
      <path
        d='M10.0555556,13.2 L14.9444444,13.2 C16.571712,13.2 17.9018768,14.44892 17.994813,16.0237272 L18,16.2 L18,17.4 C18,17.7313708 17.7263962,18 17.3888889,18 C17.0851323,18 16.8331376,17.7824104 16.7857762,17.4973231 L16.7777778,17.4 L16.7777778,16.2 C16.7777778,15.2555931 16.0369956,14.4810593 15.0948063,14.4059669 L14.9444444,14.4 L10.0555556,14.4 C9.09365962,14.4 8.30478263,15.1273134 8.22829967,16.052372 L8.22222222,16.2 L8.22222222,17.4 C8.22222222,17.7313708 7.94861846,18 7.61111111,18 C7.3073545,18 7.05535979,17.7824104 7.00799841,17.4973231 L7,17.4 L7,16.2 C7,14.6023191 8.27204811,13.2963391 9.87601846,13.2050927 L10.0555556,13.2 L14.9444444,13.2 Z M12.5,6 C14.1875367,6 15.5555556,7.34314575 15.5555556,9 C15.5555556,10.6568542 14.1875367,12 12.5,12 C10.8124633,12 9.44444444,10.6568542 9.44444444,9 C9.44444444,7.34314575 10.8124633,6 12.5,6 Z M12.5,7.2 C11.487478,7.2 10.6666667,8.00588745 10.6666667,9 C10.6666667,9.99411255 11.487478,10.8 12.5,10.8 C13.512522,10.8 14.3333333,9.99411255 14.3333333,9 C14.3333333,8.00588745 13.512522,7.2 12.5,7.2 Z'
        id='path-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-1059.000000, -30.000000)'>
        <g transform='translate(1059.000000, 30.000000)'>
          <g>
            <circle fill={props.theme.palette.primary.main} opacity='0.101074219' cx='12.5' cy='12.5' r='12.5' />
            <g transform='translate(7.000000, 6.000000)' />
          </g>
          <mask id='mask-2' fill='white'>
            <use xlinkHref='#path-1' />
          </mask>
          <use fill={props.theme.palette.primary.main} xlinkHref='#path-1' />
        </g>
      </g>
    </g>
  </SvgIcon>
));

export default AvatarIcon;
