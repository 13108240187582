// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DollarsAndCentsText from '../../../components/DollarsAndCentsText';

const useStyles = makeStyles(() => ({
  transactionFeeAmount: {
    fontWeight: 500,
    fontSize: 14,
  },
}));

type Props = {
  amount: number,
  isExtension?: boolean,
};

const TransactionFee = (props: Props) => {
  const classes = useStyles();
  const { amount, isExtension } = props;

  return (
    <Typography variant='body2'>
      {isExtension && isExtension === true ? 'Extension Fee (One-time):' : 'Transaction Fee (One-time):'}&nbsp;
      <Box display='inline' className={classes.transactionFeeAmount} data-testid='uia-transactionFee'>
        <DollarsAndCentsText amount={amount} />
      </Box>
    </Typography>
  );
};

export default TransactionFee;
