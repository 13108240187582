// @flow
import jwtDecode from 'jwt-decode';
import identityApi from './identityApi';
import Auth from './auth';
import claims from './claims';

export const useAuth = () => {
  return {
    renew: async () => {
      const refreshToken = Auth.getRefreshToken();
      const currentAccessToken = Auth.getAccessToken();
      try {
        const response = await identityApi.refreshToken(refreshToken, currentAccessToken.includes('.') ? '1.0' : '2.0');
        const { error, data } = response;
        if (!error) {
          if (data.token.accessToken.includes('.')) {
            const jwtValues = jwtDecode(data.token.accessToken);
            claims.set(jwtValues);
          } else {
            const introspect = await identityApi.getUserClaimsIntrospect(data.token.accessToken);
            claims.set(introspect.data);
          }
          Auth.loggedIn(data.token);
        }
        return Promise.resolve();
      } catch (error) {
        return Promise.reject();
      }
    },
    sso: async (tokenAlias: string) => {
      const response = await identityApi.getTokenByTokenAlias(tokenAlias);
      if (response.data.accessToken.includes('.')) {
        const jwtValues = jwtDecode(response.data.accessToken);
        claims.set(jwtValues);
      } else {
        const introspect = await identityApi.getUserClaimsIntrospect(response.data.accessToken);
        claims.set(introspect.data);
      }

      Auth.loggedIn(response.data);
    },
    login: async (data: { pin: string, phoneNumber: string }) => {
      try {
        const tokenResponse = await identityApi.getTokenByPin(data);
        const introspectResponse = await identityApi.getUserClaimsIntrospect(tokenResponse.data.token.accessToken);
        claims.set(introspectResponse.data);
        Auth.loggedIn(tokenResponse.data.token);
        return tokenResponse;
      } catch (error) {
        throw error;
      }
    },
    logout: async () => {
      try {
        const refreshToken = Auth.getRefreshToken();
        if (refreshToken) {
          await identityApi.revokeAccessToken(refreshToken);
        }
        const accessToken = Auth.getAccessToken();
        if (accessToken) {
          await identityApi.revokeAccessToken(accessToken);
        }
      } catch (error) {}
      Auth.logout();
    },
  };
};
