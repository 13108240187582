// @flow
import React from 'react';
import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';
import jwtDecode from 'jwt-decode';

import Auth from '../api/auth';
import claims from '../api/claims';

export const UseWelcomePageRedirectToken = () => {
  const location = useLocation();

  if (location.pathname === '/') {
    const queryStrings = queryString.parse(location.search);
    const { _a: gcAccountId, _e: accessToken = '', _r: refreshToken = '' } = queryStrings;

    if (accessToken && refreshToken) {
      try {
        if (accessToken.toString().includes('.')) {
          claims.set(jwtDecode(accessToken.toString()));
        } else {
          claims.set(accessToken.toString());
        }
        Auth.loggedIn({
          accessToken: accessToken.toString(),
          refreshToken: refreshToken.toString(),
        });
      } catch {
        return null;
      }
      return (
        <Redirect
          to={{
            pathname: '/welcome',
            state: { gcAccountId },
          }}
        />
      );
    }
  }

  return null;
};

export default UseWelcomePageRedirectToken;
