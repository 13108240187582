// @flow
import { PARTITION } from './constants';
import type { ReduxState } from './reducer';

const selectPartition = (state: any): ReduxState => state[PARTITION];

const selectTotalPendingInvoices = (state: any) => selectPartition(state).totalPendingInvoices;
export default {
  selectPartition,
  selectTotalPendingInvoices,
};
