// @flow
import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import startupConfig from '../lib/startupConfig';

type Props = {
  src: string,
  title: string,
};

const useStyles = makeStyles((theme) => ({
  image: {
    padding: theme.spacing(0, 0, 2),
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const ImagePreview = ({ src, title }: Props) => {
  const classes = useStyles();
  const [showModal, setshowModal] = useState(false);
  const config = startupConfig.get();
  const widgetImageBaseUrl = config ? config.widgetsUrl || '' : '';
  const setModalView = () => {
    setshowModal(!showModal);
  };

  return (
    <>
      <Button color='primary' disabled={false} onClick={() => setModalView()}>
        View preview
      </Button>
      <Dialog open={showModal} maxWidth='md' disableBackdropClick>
        <IconButton aria-label='close' className={classes.closeButton} onClick={() => setModalView()}>
          <CloseIcon />
        </IconButton>
        <DialogTitle disableTypography>
          <Typography variant='h5'>{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <Box display='flex'>
            <img className={classes.image} alt='' src={`${widgetImageBaseUrl}/images/${src}`} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImagePreview;
