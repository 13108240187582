// @flow
import * as React from 'react';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { DeedStatus, type DeedStatusEnum } from '../../types';
import AlertError from '../AlertError';
import AlertWarning from '../AlertWarning';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 1),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 0, 2),
    },
  },
}));

type Props = {
  accountTrustDeedStatus: ?DeedStatusEnum,
};

const TrustDeedAlert = ({ accountTrustDeedStatus }: Props) => {
  const classes = useStyles();

  const subMessageSuffixLink = (
    <Link component={RouterLink} to='/buyer/trust-deed'>
      here
    </Link>
  );

  switch (accountTrustDeedStatus) {
    case DeedStatus.NotAvailable:
    case null:
      return (
        <div className={classes.container} data-testid='uia-deedAlert'>
          <AlertWarning
            message='Trust deed required'
            subMessage={<React.Fragment>In order to be able to transact, please upload your trust deed {subMessageSuffixLink}.</React.Fragment>}
          />
        </div>
      );
    case DeedStatus.UnderReview:
      return (
        <div className={classes.container} data-testid='uia-deedAlert'>
          <AlertWarning
            message='Pending trust deed verification'
            subMessage='You will be able to transact once your trust deed has been verified. We will notify you once this has been done.'
          />
        </div>
      );
    case DeedStatus.Declined:
      return (
        <div className={classes.container} data-testid='uia-deedAlert'>
          <AlertError
            message='Trust deed declined'
            subMessage={
              <React.Fragment>
                Unfortunately your trust deed has been declined. Please call 1300 249 649 for assistance or upload your latest trust deed{' '}
                {subMessageSuffixLink}.
              </React.Fragment>
            }
          />
        </div>
      );
    default:
      return null;
  }
};

export default TrustDeedAlert;
