// @flow
import * as React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { AdapterLink } from '../lib/materialUiUtils';
import ShiftTradeLogo from './svg/ShiftTradeLogo';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    backgroundColor: theme.palette.common.mediumGreen,
    paddingTop: '5%',
  },
  main: {
    [theme.breakpoints.up('xs')]: {
      maxWidth: 500,
      padding: theme.spacing(1, 3, 2, 3),
      margin: theme.spacing(5, 3),
    },
    [theme.breakpoints.up('sm')]: {
      width: 500,
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3, 3, 4, 3),
      margin: theme.spacing(5, 3),
    },
    maxHeight: 460,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
  },
  logo: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      maxWidth: 300,
      margin: theme.spacing(1),
      padding: theme.spacing(2.5),
    },
    [theme.breakpoints.up('sm')]: {
      width: 300,
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(4),
    },
  },
}));

type Props = {
  children: React.Node,
  backButtonLabel: string,
  backButtonRoute: { pathname: string, state: any },
};

const MessagePageLayout = ({ children, backButtonLabel, backButtonRoute }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.container} flexDirection='column' display='flex' alignItems='center'>
      <ShiftTradeLogo className={classes.logo} dark />
      <Box className={classes.main} flexDirection='column' display='flex' alignItems='center' justifyContent='space-around'>
        {children}
        <Button color='primary' component={AdapterLink} to={backButtonRoute} variant='outlined'>
          {backButtonLabel}
        </Button>
      </Box>
      <Footer />
    </Box>
  );
};

export default MessagePageLayout;
