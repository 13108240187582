// @flow
import React from 'react';
import { Step, StepLabel, StepConnector, Stepper, Typography, withStyles, StepContent } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import clsx from 'clsx';

const ActionStepConnector = withStyles(() => ({
  line: {
    borderLeftStyle: 'dashed',
  },
}))(StepConnector);

type ActionStepIconProps = {
  active: boolean,
  completed: boolean,
  classes: any,
};

const ActionStepIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.grey.text,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 24,
    width: 24,
  },
  circle: {
    width: 11,
    height: 11,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.primary.main,
    fontSize: 18,
  },
}))((props: ActionStepIconProps) => {
  const { active, completed, classes } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
});

const ActionStepContent = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0.5),
    '&:not($last)': {
      borderLeftStyle: 'dashed',
    },
  },
  last: {},
}))(StepContent);

type ActionStepsProps = {
  activeStep: number,
  stepsToTake: [{ title: string, description: string }],
  classes: {
    stepper: any,
    stepLabel: any,
  },
};

export const ActionSteps = withStyles((theme) => ({
  stepper: {
    padding: 0,
    margin: `${theme.spacing(2)}px 0`,
  },
  stepLabel: {
    fontSize: theme.typography.fontSize,
  },
}))(({ activeStep, stepsToTake, classes }: ActionStepsProps) => {
  return (
    <Stepper activeStep={activeStep} orientation='vertical' className={classes.stepper} connector={<ActionStepConnector />}>
      {stepsToTake.map((step) => {
        return (
          <Step key={step.title} active>
            <StepLabel StepIconComponent={ActionStepIcon} classes={{ label: classes.stepLabel }}>
              {step.title}
            </StepLabel>

            <ActionStepContent>
              <Typography variant='caption' color='textSecondary'>
                {step.description}
              </Typography>
            </ActionStepContent>
          </Step>
        );
      })}
    </Stepper>
  );
});
