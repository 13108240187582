import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Icon,
  IconButton,
  Input,
  InputProps,
  Stack,
  Text,
} from '@shiftfinancial/design-system';
import { Form, FormField } from '@shiftfinancial/react-form';
import { useOpenState } from '@shiftfinancial/react-hooks';
import { CloseIcon } from '@shiftfinancial/react-icons/system';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import { z } from 'zod';

import fetchApi from '../../api/fetchiApi';
import { selectors as accountSetupCheckSelectors } from '../../components/AccountSetupCheck';
import { type AccountSetupStatus } from '../../components/AccountSetupCheck/reducer';
import { SHIFT_PHONE_NUMBER } from '../../constants';
import BsbText from '../BsbText';
import DollarsAndCentsText from '../DollarsAndCentsText';

type ArrearsPaymentDialogueProps = {
  totalArrearsAmount: number;
  gcAccountId: string;
  setRefreshArrearsBanners: (refreshArrearsBanners: boolean) => void;
};

type ArrearsPaymentDialogueContentProps = {
  bankAccountDetails: BankAccountDetails | null | undefined;
  onClose: () => void;
  gcAccountId: string;
  setRefreshArrearsBanners: (refreshArrearsBanners: boolean) => void;
};

type BankAccountDetails = {
  accountNumber: string;
  accountName: string;
  bsb: string;
};

function CurrencyInput({ ...rest }: InputProps) {
  return <Input prefix='$' {...rest} />;
}

function ArrearsPaymentDialogContent({
  onClose,
  bankAccountDetails,
  totalArrearsAmount,
  gcAccountId,
  setRefreshArrearsBanners,
}: ArrearsPaymentDialogueProps & ArrearsPaymentDialogueContentProps) {
  const { enqueueSnackbar } = useSnackbar();

  const showSuccessNotification = () =>
    enqueueSnackbar("Repayment request received. Please allow up to 3 business days for processing. You can upload invoices once it's complete.", {
      variant: 'success',
      key: 'payArrearsSuccess',
      persist: true,
    });

  const showFailureNotification = () =>
    enqueueSnackbar('Please try again or call ' + SHIFT_PHONE_NUMBER + ' if the problem persists.', {
      variant: 'error',
      key: 'payArrearsFailure',
      persist: true,
    });

  const formSchema = useMemo(
    () =>
      z.object({
        repaymentAmount: z
          .number()
          .min(20, { message: 'Enter an amount greater than or equal to $20' })
          .max(totalArrearsAmount, { message: 'Enter an amount less than or equal to the amount due' }),
      }),
    [totalArrearsAmount]
  );

  const handlePayNow = async (repaymentAmount: number) => {
    window.dispatchEvent(new Event('fetchStart'));
    await fetchApi
      .payArrearsPayment(gcAccountId, repaymentAmount)
      .then((response) => {
        if (!response.ok) {
          showFailureNotification();
        } else {
          setRefreshArrearsBanners(true);
          showSuccessNotification();
          onClose();
        }
      })
      .catch((error) => {
        showFailureNotification();
      })
      .finally(() => {
        window.dispatchEvent(new Event('fetchEnd'));
      });
  };

  return (
    <Form
      defaultValues={{ repaymentAmount: totalArrearsAmount }}
      resolver={zodResolver(formSchema)}
      onSubmit={(value) => handlePayNow(value.repaymentAmount)}
    >
      {({ watch, formState: { isSubmitting } }) => {
        const inputValue = watch('repaymentAmount');
        return (
          <>
            <DialogHeader>
              <Stack orientation='horizontal' justifyContent='space-between'>
                <DialogTitle>Make a repayment</DialogTitle>
                <IconButton type='button' data-testid='uia-closeButton' onClick={onClose}>
                  <Icon>
                    <CloseIcon />
                  </Icon>
                </IconButton>
              </Stack>
            </DialogHeader>
            <DialogBody>
              <FormField id='repaymentAmount' name='repaymentAmount' label='Repayment amount'>
                {({ invalid }, { field }) => (
                  <Box sx={{ width: { xs: '1/2', sm: '1/3' } }}>
                    <NumberFormat
                      customInput={CurrencyInput}
                      state={invalid ? 'error' : undefined}
                      value={field.value || ''}
                      allowNegative={false}
                      decimalScale={2}
                      data-testid='uia-repaymentAmountInput'
                      thousandSeparator
                      onValueChange={(values) => field.onChange(values.floatValue ?? 0)}
                    />
                  </Box>
                )}
              </FormField>
              <Box sx={{ backgroundColor: 'neutral100', padding: 'x4', borderRadius: 'standard', marginTop: 'x6' }}>
                <Stack orientation='vertical' alignItems='stretch'>
                  <Stack orientation='vertical' alignItems='stretch' gap='x1'>
                    <Text>Amount due</Text>
                    <Text fontWeight='medium' fontSize='large' data-testid='uia-amountDue'>
                      <DollarsAndCentsText amount={totalArrearsAmount} />
                    </Text>
                  </Stack>
                  <Stack orientation='vertical' alignItems='stretch' gap='x1'>
                    <Text>Outstanding balance</Text>
                    <Text fontWeight='medium' fontSize='large'>
                      <DollarsAndCentsText
                        data-testid='uia-outstandingBalance'
                        amount={(inputValue ?? 0) <= totalArrearsAmount ? totalArrearsAmount - (inputValue ?? 0) : 0}
                      />
                    </Text>
                  </Stack>
                  <Stack orientation='vertical' alignItems='stretch' gap='x1'>
                    <Text>Pay from</Text>
                    <Text fontWeight='medium' fontSize='large' data-testid='uia-bankAccountName'>
                      {bankAccountDetails?.accountName}
                    </Text>
                    <Text color='secondary'>
                      <Stack>
                        <Text data-testid='uia-bankAccountBsb'>
                          <BsbText value={bankAccountDetails?.bsb ?? ''} />
                        </Text>
                        <Text data-testid='uia-bankAccountNumber'>{bankAccountDetails?.accountNumber}</Text>
                      </Stack>
                    </Text>
                  </Stack>
                </Stack>
              </Box>
            </DialogBody>
            <DialogActions justifyContent='space-between'>
              <Button type='submit' appearance='primary' disabled={isSubmitting} data-testid='uia-payNowButton'>
                Pay now
              </Button>
              <Button type='button' appearance='neutral' onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </>
        );
      }}
    </Form>
  );
}

export function ArrearsPaymentDialogue(props: ArrearsPaymentDialogueProps) {
  const dialogOpenState = useOpenState();
  const [bankAccountDetails, setBankAccountDetails] = useState<BankAccountDetails | null>();
  const { enqueueSnackbar } = useSnackbar();
  const bankAccountSetup: AccountSetupStatus | null = useSelector(accountSetupCheckSelectors.selectBankAccountSetup);

  const onDialogOpen = () => {
    if (bankAccountSetup && bankAccountSetup.data && bankAccountSetup.data.length) {
      const shiftBankAccount = bankAccountSetup.data.find((x: { isShiftBankAccount: boolean }) => x.isShiftBankAccount);
      if (shiftBankAccount) {
        setBankAccountDetails({
          accountNumber: shiftBankAccount.bankAccountNumber,
          accountName: shiftBankAccount.bankAccountName,
          bsb: shiftBankAccount.bsb,
        });
      }
    } else {
      enqueueSnackbar('Invalid bank account', {
        variant: 'error',
        key: 'makeRepaymentBankAccountFailed',
      });
    }
    dialogOpenState.open();
  };

  return (
    <Dialog modal open={dialogOpenState.isOpen}>
      <DialogTrigger>
        <Button key='make-repayment' appearance='primary' data-testid='uia-makeRepaymentButton' onClick={onDialogOpen}>
          Make a repayment
        </Button>
      </DialogTrigger>
      <DialogContent size='standard'>
        <ArrearsPaymentDialogContent {...props} bankAccountDetails={bankAccountDetails} onClose={dialogOpenState.close} />
      </DialogContent>
    </Dialog>
  );
}
