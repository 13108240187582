// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  theme: any,
};

const PreviewIcon = withTheme((props: Props) => (
  <SvgIcon style={{ height: '50px', width: '100' }} viewBox='0 0 82 60' {...(props: any)}>
    <g
      id='Shift-Resources_-open-account_empty'
      transform='translate(-672.000000, -779.000000)'
      fill={props.theme.palette.grey['400']}
      fillRule='nonzero'
    >
      <g id='Group-5' transform='translate(320.000000, 405.000000)'>
        <g id='no-preview-icon' transform='translate(352.000000, 374.875000)'>
          <path
            d='M93.75,0 L6.25,0 C2.8125,0 0,2.8125 0,6.25 L0,31.25 C0,34.6875 2.8125,37.5 6.25,37.5 L69.738,37.5 L68.99191,34.375 L6.24991,34.375 C4.52721,34.375 3.12491,32.9727 3.12491,31.25 L3.12491,6.25 C3.12491,4.5273 4.52721,3.125 6.24991,3.125 L93.74991,3.125 C95.47261,3.125 96.87491,4.5273 96.87491,6.25 L96.87491,31.25 C96.87491,32.7383 95.82411,33.9844 94.42961,34.2969 L94.71086,34.382837 C95.81636,34.718777 96.71866,35.449237 97.30466,36.382837 C98.92966,35.250037 99.99996,33.371137 99.99996,31.250037 L99.99996,6.250037 C99.99996,2.812537 97.18746,0 93.74996,0 L93.75,0 Z'
            id='Path'
          />
          <path
            d='M89.199,41.988 L93.867,40.3396 C94.50372,40.11304 94.9256,39.50757 94.91,38.8318 C94.8944,38.15603 94.44906,37.5701 93.8045,37.3709 L73.8985,31.3162 C73.36334,31.15214 72.7813,31.288856 72.3751,31.67167 C71.9689,32.054484 71.79698,32.6287 71.92588,33.17167 L76.43758,52.06667 C76.58602,52.69558 77.10946,53.16827 77.75008,53.25417 C78.3907,53.336202 79.01958,53.0237 79.32818,52.45339 L81.67978,48.14869 L89.14858,55.77759 C89.45327,56.09009 89.85952,56.24634 90.26578,56.24634 C90.61734,56.24634 90.97281,56.12525 91.26578,55.88306 L96.32438,51.66426 C96.66032,51.38692 96.86344,50.97676 96.88297,50.54316 C96.906408,50.10957 96.74235,49.68378 96.44156,49.37126 L89.199,41.988 Z M90.3592,52.562 L82.4256,44.4565 C82.12872,44.15572 81.72638,43.98775 81.3084,43.98775 C81.238087,43.98775 81.16387,43.991657 81.09356,44.003375 C80.60137,44.069781 80.17559,44.366655 79.93736,44.800255 L78.52326,47.390055 L75.58966,35.101055 L88.33966,38.979955 L85.84746,39.858865 C85.34355,40.038555 84.96465,40.460425 84.84746,40.979965 C84.72637,41.499495 84.87871,42.046365 85.25371,42.425265 L93.01541,50.354965 L90.3592,52.562 Z'
            id='Shape'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
));

export default PreviewIcon;
