// @flow
import { Box } from '@material-ui/core';
import React from 'react';

import AlertInfo from '../AlertInfo';
import DollarsAndCentsText from '../DollarsAndCentsText';

type AccountLimitBannerProps = {
  currentLimit?: number,
  requestedLimit?: ?number,
};

export function AccountLimitBanner({ currentLimit = 0, requestedLimit = null }: AccountLimitBannerProps) {
  let bannerText =
    'We are currently assessing your maximum available funds and will get back to you within the next business day. You will receive an SMS when this has been completed.';

  if (requestedLimit && currentLimit === 0) {
    bannerText = (
      <>
        We are currently assessing your request of <DollarsAndCentsText amount={requestedLimit} decimalScale={0} /> and we will get back to you within
        the next business day. You will receive an SMS when this has been completed.
      </>
    );
  } else if (requestedLimit && currentLimit > 0) {
    bannerText = (
      <>
        We are currently assessing your request of <DollarsAndCentsText amount={requestedLimit} decimalScale={0} /> and we will get back to you within
        the next business day. You will receive an SMS when this has been completed. In the meantime, you may continue to use your current limit of{' '}
        <DollarsAndCentsText amount={currentLimit} decimalScale={0} />.
      </>
    );
  }

  return (
    <Box mb={2}>
      <AlertInfo message='Trade account funds' subMessage={bannerText} data-testid='uia-account-limit-banner' />
    </Box>
  );
}
