// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';
// $FlowFixMe
import { batch } from 'react-redux';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';
import { useAuth } from '../../api/authhooks';
import claims from '../../api/claims';
import { updateLoanAgreementSetupCompleted } from '../../components/AccountSetupCheck/actions';
import initialDataActions from '../../components/InitialData/actions';
import { updateOpenTradeAccount } from '../../components/UserSelectProfileMode/actions';

export const {
  signLoanAgreementFulfilled,
  signLoanAgreementPending,
  signLoanAgreementRejected,
  autoApproveRequestPending,
  autoApproveRequestFulfilled,
  autoApproveRequestRejected,
} = createActions(
  actionTypes.SIGN_LOAN_AGREEMENT_FULFILLED,
  actionTypes.SIGN_LOAN_AGREEMENT_PENDING,
  actionTypes.SIGN_LOAN_AGREEMENT_REJECTED,
  actionTypes.AUTO_APPROVE_REQUEST_PENDING,
  actionTypes.AUTO_APPROVE_REQUEST_FULFILLED,
  actionTypes.AUTO_APPROVE_REQUEST_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const signLoanAgreement = (gcAccountId: string, agreementVersion: string, isRequireDataRefresh: boolean = false) => {
  return async (dispatch: Dispatch<any>) => {
    const { renew } = useAuth();
    dispatch(signLoanAgreementPending());
    return shiftPaymentsApi
      .signLoanAgreement(gcAccountId, agreementVersion)
      .then((response) => {
        return renew().then(() => {
          const jwtValues = claims.get();
          dispatch(initialDataActions.getInitialData(jwtValues, isRequireDataRefresh));
          return batch(() => {
            dispatch(initialDataActions.updateValues(jwtValues));
            return dispatch(signLoanAgreementFulfilled(response.data));
          });
        });
      })
      .catch((error) => {
        return dispatch(signLoanAgreementRejected(error));
      });
  };
};

const autoApproveSupplierRequest = (buyerGcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(autoApproveRequestPending());

  return shiftPaymentsApi
    .autoApproveSupplierRequest(buyerGcAccountId)
    .then((response) => {
      return dispatch(autoApproveRequestFulfilled(response));
    })
    .catch((error) => {
      return dispatch(autoApproveRequestRejected(error));
    });
};

export default {
  signLoanAgreement,
  updateLoanAgreementSetupCompleted,
  autoApproveSupplierRequest,
  updateOpenTradeAccount,
};
