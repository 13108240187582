export const ACTION_PREFIX = 'PUBLIC_SUPPLIERS_GRID';

export const actionTypes = {
  GET_PUBLIC_SUPPLIERS_PENDING: 'GET_PUBLIC_SUPPLIERS_PENDING',
  GET_PUBLIC_SUPPLIERS_FULFILLED: 'GET_PUBLIC_SUPPLIERS_FULFILLED',
  GET_PUBLIC_SUPPLIERS_REJECTED: 'GET_PUBLIC_SUPPLIERS_REJECTED',
  SET_SUPPLIER_AUTHORIZATION_PENDING: 'SET_SUPPLIER_AUTHORIZATION_PENDING',
  SET_SUPPLIER_AUTHORIZATION_FULFILLED: 'SET_SUPPLIER_AUTHORIZATION_FULFILLED',
  SET_SUPPLIER_AUTHORIZATION_REJECTED: 'SET_SUPPLIER_AUTHORIZATION_REJECTED',
};
