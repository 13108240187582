// @flow
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Redirect, useLocation } from 'react-router';
import { useAbility } from '@casl/react';
import { withSnackbar } from 'notistack';
import get from 'lodash/get';
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography, makeStyles } from '@material-ui/core';
import { Form, withFormik, type FormikBag } from 'formik';

import { LINKS, Permission } from '../../constants';
import { ActionSteps } from '../../components/ActionSteps';
import { defaultHandleError } from '../../lib/apiHelpers';
import { type WelcomePageProps, type WelcomePageValues, type StepDefinition } from './types';
import { formSchema } from './validations';
import SetupPageLayout from '../../components/SetupPageLayout';
import { AbilityContext } from '../../lib/ability';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  continueButton: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  containerContinueButton: {
    justifyContent: 'flex-end',
    display: 'flex',
    width: '100%',
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  stepBox: {
    backgroundColor: theme.palette.secondary.light,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: '100%',
      paddingLeft: theme.spacing(4),
    },
  },
  stepBoxHeading: {
    fontSize: '14px',
    fontWeight: '500 !important',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3.5),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  stepBoxContainer: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(9),
    },
  },
  links: {
    color: theme.palette.primary.main,
  },
}));

const WelcomePage = (props: WelcomePageProps) => {
  const classes = useStyles();
  const location = useLocation();

  const gcAccountId = get(location, ['state', 'gcAccountId'], null);

  const {
    isBankSetupDone,
    contactFirstName,
    values,
    getSettings,
    updateActiveAccount,
    setFieldValue,
    isValid,
    enqueueSnackbar,
    history,
    isAccountSetupLoaded,
    isPrivacyAgreementSetupComplete,
    updateTermsAndConditionsAgreementSetupCompleted,
    addReferralAccountRelation,
  } = props;

  const [isRedirected] = React.useState(!!gcAccountId);
  const ability = useAbility(AbilityContext);

  useEffect(() => {
    if (!isAccountSetupLoaded) {
      return;
    }

    if (gcAccountId) {
      if (!ability.can(Permission.Actions.Sign, Permission.Buyer.TermsAndConditionsAgreement)) {
        addReferralAccountRelation(gcAccountId);
        updateTermsAndConditionsAgreementSetupCompleted(true);
        return;
      }

      getSettings(gcAccountId).then((response) => {
        const { error } = response;

        if (error) {
          defaultHandleError({ enqueueSnackbar }, null);
          return;
        }

        if (history.location.state) {
          history.replace({
            pathname: history.location.pathname,
            state: undefined,
          });

          setFieldValue('gcAccountId', gcAccountId);
          updateActiveAccount(gcAccountId);
        }
      });
    }
  }, [isAccountSetupLoaded]);

  if (!history.location.state && !isRedirected) {
    return <Redirect to='/' />;
  }

  if (!values.gcAccountId || !contactFirstName) {
    return null;
  }

  let activeStep = 0;
  const stepsToTake: Array<StepDefinition> = [];
  if (ability.can(Permission.Actions.Create, Permission.Buyer.BankAccount)) {
    if (isBankSetupDone) {
      activeStep = 1;
    }
    stepsToTake.push({
      title: 'Link your business bank account',
      description: 'This helps us set a suitable amount of available funds based on your current business needs.',
    });
  }

  if (ability.can(Permission.Actions.Sign, Permission.Buyer.LoanAgreement)) {
    stepsToTake.push({ title: 'Accept the Facility Agreement', description: 'Review our Terms & Conditions and accept the agreement.' });
  }

  if (ability.can(Permission.Actions.Sign, Permission.Buyer.PrivacyAgreement)) {
    stepsToTake.push({ title: 'Accept the privacy agreement', description: 'Review our Terms & Conditions.' });
    if (isPrivacyAgreementSetupComplete) {
      activeStep = 1;
    }
  }

  return (
    <Form noValidate autoComplete='off' data-testid='uia-invitee'>
      <SetupPageLayout title={`Hi ${contactFirstName}, Welcome to Shift Trade.`}>
        <Box>
          <Typography variant='subtitle2' component='h4' gutterBottom>
            {activeStep === 0 ? '2 more steps to get set up' : '1 more step to get set up'}
          </Typography>

          <ActionSteps activeStep={activeStep} stepsToTake={stepsToTake} />
        </Box>

        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.reviewedAndAgreed}
                onChange={(_, checked) => setFieldValue('reviewedAndAgreed', checked)}
                data-testid='uia-reviewedAndAgreed-check'
                id='reviewedAndAgreed'
                name='reviewedAndAgreed'
              />
            }
            label={
              <Typography variant='body1'>
                I have reviewed and agreed to Shift&apos;s{' '}
                <a href={LINKS.TERMS_AND_CONDITIONS} target='_blank' rel='noopener noreferrer' className={classes.links}>
                  Terms and Conditions
                </a>{' '}
                and{' '}
                <a href={LINKS.PRIVACY_POLICY} target='_blank' rel='noopener noreferrer' className={classes.links}>
                  Privacy Policy
                </a>
              </Typography>
            }
          />
        </Box>
      </SetupPageLayout>

      <Grid container>
        <Box className={classes.containerContinueButton}>
          <Button
            color='primary'
            size='large'
            variant='contained'
            data-testid='uia-continue'
            className={classes.continueButton}
            type='submit'
            disabled={!isValid}
          >
            Continue
          </Button>
        </Box>
      </Grid>
    </Form>
  );
};

export default withRouter(
  withSnackbar(
    withFormik<WelcomePageProps, WelcomePageValues>({
      displayName: 'WelcomePage',
      validationSchema: formSchema,
      validateOnBlur: false,
      enableReinitialize: true,
      mapPropsToValues: ({ values, isBankSetupDone = false }): WelcomePageValues => {
        return {
          ...values,
          reviewedAndAgreed: false,
          isBankSetupDone,
        };
      },
      handleSubmit: (values: WelcomePageValues, formikBag: FormikBag<WelcomePageProps, WelcomePageValues>) => {
        const { props } = formikBag;
        const { gcAccountId } = values;

        if (gcAccountId) {
          props.signConsentTermsAndConditionsAgreement(gcAccountId, props.termsAndConditionsAgreementSetup.data.version).then((response) => {
            if (response.error) {
              defaultHandleError(props);
              formikBag.setSubmitting(false);
              return;
            }
            // call request credit limit if no pre-approved limit exists
            props
              .setCreditLimitOption(gcAccountId, !props.preApprovedLimitAmount, props.preApprovedLimitAmount ? props.preApprovedLimitAmount : null)
              .then((response) => {
                if (response.error) {
                  defaultHandleError(props);
                  formikBag.setSubmitting(false);
                }
              });

            props.addReferralAccountRelation(gcAccountId);
            props.updateTermsAndConditionsAgreementSetupCompleted(true);
            props.history.push('/');
            return;
          });
        }
      },
    })(WelcomePage)
  )
);
