export const ACTION_PREFIX = 'ADD_CUSTOMER';
export const PARTITION = 'addCustomer';

export const actionTypes = {
  SEARCH_CUSTOMERS_PENDING: 'SEARCH_CUSTOMERS_PENDING',
  SEARCH_CUSTOMERS_FULFILLED: 'SEARCH_CUSTOMERS_FULFILLED',
  SEARCH_CUSTOMERS_REJECTED: 'SEARCH_CUSTOMERS_REJECTED',
  ADD_CUSTOMER_PENDING: 'ADD_CUSTOMER_PENDING',
  ADD_CUSTOMER_FULFILLED: 'ADD_CUSTOMER_FULFILLED',
  ADD_CUSTOMER_REJECTED: 'ADD_CUSTOMER_REJECTED',
  GET_SUPPLIER_TERM_FEE_LOOKUP_PENDING: 'GET_SUPPLIER_TERM_FEE_LOOKUP_PENDING',
  GET_SUPPLIER_TERM_FEE_LOOKUP_FULFILLED: 'GET_SUPPLIER_TERM_FEE_LOOKUP_FULFILLED',
  GET_SUPPLIER_TERM_FEE_LOOKUP_REJECTED: 'GET_SUPPLIER_TERM_FEE_LOOKUP_REJECTED',
  GET_CONTACTS_PENDING: 'GET_CONTACTS_PENDING',
  GET_CONTACTS_FULFILLED: 'GET_CONTACTS_FULFILLED',
  GET_CONTACTS_REJECTED: 'GET_CONTACTS_REJECTED',
  VALIDATE_NEW_BUYER_PENDING: 'VALIDATE_NEW_BUYER_PENDING',
  VALIDATE_NEW_BUYER_FULFILLED: 'VALIDATE_NEW_BUYER_FULFILLED',
  VALIDATE_NEW_BUYER_REJECTED: 'VALIDATE_NEW_BUYER_REJECTED',
};

export const CONTACT_NAME_NOT_HERE = 'Name not here';
