import { DEFAULT_ERROR_MESSAGE } from '../constants';
import responseCode from '../api/generated/responseCode';

/* eslint-disable no-console */
const handleFormikFieldErrors = (errors, formikBag) => {
  const errorFields = Object.keys(errors);
  errorFields.forEach((field) => {
    formikBag.setFieldError(field, errors[field]);
  });
};

const responseCodesAsDefaultErrorMessage = [
  responseCode.CommonServerError,
  responseCode.CommonUpdateFailure,
  responseCode.AccountContactsRetrievalFailed,
  responseCode.AccountCreationFailure,
  responseCode.AccountBusinessEligibilityValidationFailed,
  responseCode.AccountBusinessDetailsRetrievalFailed,
  responseCode.InvoiceRetrievalFailed,
  responseCode.InvoiceConfirmationFailed,
  responseCode.InvoiceDeclineFailed,
  responseCode.InvoiceShareFailed,
  responseCode.InvoiceAttachmentUploadFailed,
  responseCode.InvoiceAttachmentDeletionFailed,
  responseCode.SupplierBuyerBuyerStatusValidationFailed,
  responseCode.ContactInvalidInvitationSenderOrRecipient,
  responseCode.ContactInvitationSendingFailed,
];

export const getErrorMessage = (response, defaultMessage = DEFAULT_ERROR_MESSAGE) => {
  let message = defaultMessage;

  if (response) {
    const { error, payload } = response;
    if (error && payload && payload.response && (payload.response.status === 400 || payload.response.status === 404) && payload.response.data) {
      if (!responseCodesAsDefaultErrorMessage.includes(payload.response.data.code)) {
        if (payload.response.data.message) {
          message = payload.response.data.message;
        } else if (payload.response.data.detail) {
          message = payload.response.data.detail;
        }
      }
    }
  }
  return message;
};

export const defaultHandleError = (
  props,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  key = 'shift'
) => {
  props.enqueueSnackbar(DEFAULT_ERROR_MESSAGE, {
    anchorOrigin,
    variant: 'error',
    key,
  });
};

export const handleError = (
  error,
  props,
  formikBag = null,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  customMessage = null,
  key = 'shift'
  // eslint-disable-next-line consistent-return
) => {
  if (props && props.enqueueSnackbar && props.history) {
    if (error.response) {
      if (error.response.data) {
        const { errors } = error.response.data;

        if (errors && typeof errors === 'object') {
          if (formikBag) {
            handleFormikFieldErrors(errors, formikBag);
          }
        }

        const { messages } = error.response.data;
        if (messages && Array.isArray(messages) && messages.length > 0) {
          return messages.forEach((message) => {
            props.enqueueSnackbar(message, {
              anchorOrigin,
              variant: 'error',
              key,
            });
          });
        }
      }
    } else if (error.request) {
      if (error.message) {
        props.enqueueSnackbar(error.message, {
          anchorOrigin,
          variant: 'error',
          key,
        });
      }
    } else {
      console.log('Error', error.message);
      props.enqueueSnackbar(customMessage || getErrorMessage(error), {
        anchorOrigin,
        variant: 'error',
        key,
      });
    }
  } else {
    if (!error) {
      return defaultHandleError(props);
    }
    // todo from https://github.com/axios/axios#handling-errors

    if (error.response) {
      if (error.response.status === 401) {
        window.location.href = '/logout';
      }
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(error.response.data);
      console.error(error.response.status);
      console.error(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
    }
    console.error(error.config);

    return error;
  }
};

export const getFirstMessage = (error) => {
  const { messages } = error.response.data;
  if (messages && Array.isArray(messages) && messages.length > 0) {
    return messages[0];
  }

  return null;
};
