// @flow
import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import business from 'moment-business';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import withWidth from '@material-ui/core/withWidth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// $FlowFixMe Flow doesn't like type re-exports
import { createSelector } from '@reduxjs/toolkit';
import DateLabel from './DateLabel';
import TransactionFee from './TransactionFee';
import actions from './actions';
import selectors from './selectors';
import { addDays } from '../../../lib/dateUtils';
import { isMobileResolution } from '../../../lib/materialUiUtils';
import { traceInfo } from '../../../lib/telemetryUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    boxShadow: 'none !important',
  },
  selectNewDateContainer: {
    boxShadow: 'none !important',
  },
  selectNewDateInnerContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 3, 0, 3),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 0, 2),
    },
  },
  supplierDueDateHeader: {
    fontWeight: 400,
  },
  dateButtonOuterContainer: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  dateButtonInnerContainer: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      display: 'inline-block',
    },
  },
  feeApplicable: {
    fontStyle: 'italic',
    fontSize: 11,
    color: theme.palette.grey.text,
  },
  dontChangeButton: {
    [theme.breakpoints.up('sm')]: {
      float: 'right',
    },
    marginTop: -7,
    fontSize: 13,
    fontWeight: 400,
    visibility: ({ showDontChangeButton }) => showDontChangeButton ? 'visible' : 'hidden'
  },
  datePicker: {
    '& .MuiPickersDay-daySelected': {
      fontWeight: '500 !important',
    },
  },
  newDateContainer: {
    backgroundColor: theme.palette.action.selected,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  changeDateButton: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      fontWeight: 400,
      width: '100%',
    },
  },
  calendarIcon: {
    fontSize: '13px',
    marginRight: theme.spacing(1),
    marginBottom: 2,
  },
  transactionFeeBox: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0.5),
    },
  },
}));

type Props = {
  invoiceDate: Date,
  supplierDueDate: ?moment,
  isBuyerInitiated: boolean,
  availableDays: number,
  paymentDate: moment,
  transactionFee: number,
  setPaymentDate: (date: ?moment) => void,
  width: string,
  isExtension?: boolean,
  supplierTerms?: number,
  showDontChangeButton?: boolean
};

const BUYER_INITIATED_ADDITIONAL_DAYS = 7;

const PaymentDateSelector = (props: Props) => {
  const {
    invoiceDate,
    supplierDueDate,
    availableDays,
    transactionFee,
    paymentDate,
    setPaymentDate,
    isBuyerInitiated,
    width,
    isExtension,
    supplierTerms,
    showDontChangeButton
  } = props;
  const classes = useStyles({ showDontChangeButton });

  if (paymentDate) paymentDate.local();

  const [showCalendar, setShowCalendar] = useState(false);

  let minDate;
  let maxDate = addDays(supplierDueDate ? addDays(moment(invoiceDate).local(), supplierTerms) : moment(invoiceDate).local(), availableDays);
  const localInvoiceDate = moment(invoiceDate);
  localInvoiceDate.local();

  if (supplierDueDate && maxDate) minDate = maxDate.isAfter(supplierDueDate) ? addDays(supplierDueDate, 1) : supplierDueDate;
  // disable the next n days, excluding the current date
  else minDate = addDays(localInvoiceDate, BUYER_INITIATED_ADDITIONAL_DAYS + 1);

  const traceDatesAndProps = () => {
    traceInfo(`Buyer Initiated - ${JSON.stringify(isBuyerInitiated)}`);
    traceInfo(`Supplier due date - ${supplierDueDate ? JSON.stringify(supplierDueDate) : 'undefined'}`);
    traceInfo(`Available days - ${JSON.stringify(availableDays)}`);
    traceInfo(`Invoice date - ${JSON.stringify(invoiceDate)}`);
    traceInfo(`Min date - ${JSON.stringify(minDate)}`);
    traceInfo(`Max date - ${JSON.stringify(maxDate)}`);
    traceInfo(`Default Payment date selected is - ${JSON.stringify(paymentDate)}`);
  };

  const setFullPaymentDate = (day: moment) => {
    traceInfo(`selected date - ${JSON.stringify(day)} is weekday - ${!business.isWeekendDay(day)}`);
    setPaymentDate(day);
  };

  const disableWeekends = (day: ?moment) => {
    if (!day || !day.isValid()) {
      traceInfo('date is invalid');
      return false;
    }
    return business.isWeekendDay(day);
  };

  useEffect(() => {
    if (isBuyerInitiated) {
      setShowCalendar(true);

      while (disableWeekends(maxDate)) {
        maxDate = addDays(maxDate, -1);
      }
      traceInfo(`Last payment date - ${JSON.stringify(maxDate)}`);
      setPaymentDate(maxDate);
    } else if (supplierDueDate) {
      setPaymentDate(supplierDueDate);
    }
    traceDatesAndProps();
  }, [invoiceDate, supplierDueDate, availableDays, isBuyerInitiated]);

  const hideDateSelector = () => {
    setShowCalendar(false);
    if (supplierDueDate) {
      setPaymentDate(supplierDueDate);
    }
  };

  const isMobile = isMobileResolution(width);

  const dontChangeButton = () =>
    supplierDueDate && (
      <Button color='primary' className={classes.dontChangeButton} onClick={() => hideDateSelector()} data-testid='uia-dontChange'>
        I don&apos;t want to change
      </Button>
    );

  return (
    <Grid container spacing={1} data-testid='uia-payment-date-selector'>
      {supplierDueDate ? (
        <Grid item xs={12}>
          <Paper className={classes.container}>
            <Grid container spacing={isMobile ? 2 : 0}>
              <Grid item xs={12} sm={6}>
                <Typography variant='h6' className={classes.supplierDueDateHeader}>
                  Current due date
                </Typography>
                <DateLabel date={supplierDueDate} />
              </Grid>
              {(!maxDate || maxDate.isAfter(supplierDueDate)) && (
                <Grid item xs={12} sm={6} className={classes.dateButtonOuterContainer}>
                  {isMobile ? <TransactionFee isExtension={isExtension} amount={transactionFee} /> : null}
                  <Box className={classes.dateButtonInnerContainer}>
                    <Button
                      variant='contained'
                      color='primary'
                      align-items='baseline'
                      className={classes.changeDateButton}
                      data-testid='uia-changeDate'
                      onClick={() => setShowCalendar(true)}
                    >
                      <CalendarTodayIcon className={classes.calendarIcon} />
                      Change your date
                    </Button>
                    {!isMobile && <Typography className={classes.feeApplicable}>Fee applicable</Typography>}
                  </Box>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      ) : null}
      {invoiceDate && showCalendar && paymentDate ? (
        <Grid item xs={12}>
          <Paper className={classes.selectNewDateContainer}>
            <Box className={classes.selectNewDateInnerContainer} data-testid='uia-calendar'>
              {!isMobile && dontChangeButton()}
              <Typography variant='subtitle2'>Select your {supplierDueDate ? 'new' : ''} date</Typography>
              <DatePicker
                className={classes.datePicker}
                disableToolbar
                fullWidth
                autoOk
                variant='static'
                openTo='date'
                value={paymentDate}
                onChange={setFullPaymentDate}
                minDate={minDate}
                maxDate={maxDate}
                shouldDisableDate={(date) => disableWeekends(date)}
              />
            </Box>
            {isMobile ? dontChangeButton() : null}
            <Box className={classes.newDateContainer}>
              <Box>
                <Typography variant='h6' className={classes.supplierDueDateHeader}>
                  Your {supplierDueDate ? 'new' : ''} due date
                </Typography>
                <DateLabel date={moment(paymentDate)} />
              </Box>
              <Box className={classes.transactionFeeBox}>
                <TransactionFee isExtension={isExtension} amount={transactionFee} />
              </Box>
            </Box>
          </Paper>
        </Grid>
      ) : null}
    </Grid>
  );
};

const mapStateToProps: any = createSelector(selectors.selectPaymentDate, (paymentDate) => ({
  paymentDate,
}));

const mapDispatchToProps = (dispatch) => bindActionCreators({ setPaymentDate: actions.setPaymentDate }, dispatch);

PaymentDateSelector.defaultProps = {
    showDontChangeButton: true
};

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(PaymentDateSelector));
