// @flow
import type { Dispatch } from 'redux';
import { handleError } from './apiHelpers';
import asyncSessionStorageIntervalsAndTimeouts from './asyncSessionStorageIntervalsAndTimeouts';
import { actions as settingsPageActions } from '../pages/Settings';

const INTERVAL_IN_SECONDS = 30;
const TIMEOUT_IN_SECONDS = 600;

export const startNewCreditLimitRequestPolling = async (
  dispatch: Dispatch<any>,
  enqueueSnackbar: (snack: string, options?: Object) => void,
  gcAccountId: string,
  existingCurrentAccountLimit: ?number,
  isNewLimitBasedOnSales: boolean
) => {
  const requestCompleted = await pollCreditLimitStatus(dispatch, enqueueSnackbar, gcAccountId, existingCurrentAccountLimit, isNewLimitBasedOnSales);
  if (!requestCompleted) {
    let timeout;
    const interval = setInterval(async () => {
      const requestCompleted = await pollCreditLimitStatus(
        dispatch,
        enqueueSnackbar,
        gcAccountId,
        existingCurrentAccountLimit,
        isNewLimitBasedOnSales
      );
      if (requestCompleted) {
        clearInterval(interval);
        clearTimeout(timeout);
      }
    }, INTERVAL_IN_SECONDS * 1000);
    timeout = setTimeout(() => {
      clearInterval(interval);
    }, TIMEOUT_IN_SECONDS * 1000);

    //put values in sessionStorage so they can be cleared on logout
    await asyncSessionStorageIntervalsAndTimeouts.addIntervalAndTimeout(interval, timeout);
  }
};

const pollCreditLimitStatus = async (
  dispatch: Dispatch<any>,
  enqueueSnackbar: (snack: string, options?: Object) => void,
  gcAccountId: string,
  existingCurrentAccountLimit: ?number,
  isNewLimitBasedOnSales: boolean
) => {
  let requestCompleted = false;
  try {
    const getCreditLimitPendingRequestResponse = await dispatch(settingsPageActions.getCreditLimitPendingRequest(gcAccountId, true));
    if (
      getCreditLimitPendingRequestResponse &&
      getCreditLimitPendingRequestResponse.payload &&
      getCreditLimitPendingRequestResponse.payload.data &&
      (!getCreditLimitPendingRequestResponse.payload.data.isPending || getCreditLimitPendingRequestResponse.payload.data.withInterimLimit)
    ) {
      const { newLimit, requestedLimit } = getCreditLimitPendingRequestResponse.payload.data;
      if (!isNewLimitBasedOnSales && newLimit > 0 && newLimit < requestedLimit) {
        const msg = `Your request for a $${requestedLimit.toFixed(2)} limit is being reviewed.`;
        enqueueSnackbar(msg, {
          variant: 'success',
          key: 'new-credit-limit-reviewed',
        });
      } else {
        const msg = `Your $${newLimit.toFixed(2)} Trade Account limit is ready.`;
        enqueueSnackbar(msg, {
          variant: 'success',
          key: 'new-credit-limit-approved',
        });
      }
      requestCompleted = true;
    }
  } catch (error) {
    handleError(error);
  } finally {
    return requestCompleted;
  }
};
