/* eslint-disable react/display-name */
// @flow
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@shiftfinancial/design-system';
import moment from 'moment';
import React from 'react';

import type { OrderBy } from '../../api/shiftPaymentsApi';
import { Permission, STANDARD_DATE_FORMAT } from '../../constants';
import { Can } from '../../lib/ability';
import { enrichColumnsWithBodyRenders, getColumnIndex } from '../../lib/dataGridUtils';
import { formatSupplierTerm } from '../../lib/formatter';
import { AdapterLink } from '../../lib/materialUiUtils';
import RemoteDataGrid from '../RemoteDataGrid';

const useStyles = makeStyles((theme) => ({
  box: {
    margin: '2% 0',
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
    },
  },
  mobileColumnLabel: {
    marginBottom: theme.spacing(0.5),
    fontSize: 12,
  },
  mobileColumnValue: {
    fontWeight: 500,
    fontSize: 14,
  },
  mobileRowDataLine: {
    marginBottom: theme.spacing(2),
  },
  mobileRowStatusLine: {
    marginTop: theme.spacing(2),
  },
  reviewButton: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      fontSize: 14,
    },
  },
}));

const columns = [
  {
    name: 'dateOfInvite',
    label: 'Date',
    options: {
      sort: true,
    },
  },
  {
    name: 'termsInDays',
    options: {
      display: false,
    },
  },
  {
    name: 'termPeriod',
    options: {
      display: false,
    },
  },
  {
    name: 'accountName',
    options: {
      display: false,
    },
  },
  {
    name: 'gcAccountId',
    options: {
      display: false,
    },
  },
  {
    name: 'detail',
    label: 'Details',
  },
  {
    name: 'review',
    label: ' ',
  },
];

const dateOfInviteIndex = getColumnIndex(columns, 'dateOfInvite');
const termsInDaysIndex = getColumnIndex(columns, 'termsInDays');
const termPeriodIndex = getColumnIndex(columns, 'termPeriod');
const supplierGcAccountIdIndex = getColumnIndex(columns, 'gcAccountId');
const supplierAccountNameIndex = getColumnIndex(columns, 'accountName');

type Props = {
  buyerGcAccountId: string,
  isActionBlocked: boolean,
  getSupplierRequests: (gcAccountId: string, skip: number, limit: number, orderBy: ?Array<OrderBy>) => Promise<any>,
};

const SupplierRequests = (props: Props) => {
  const { buyerGcAccountId, isActionBlocked, getSupplierRequests } = props;
  const classes = useStyles();

  const getSupplierRequestData = (skip: number, limit: number, orderBy: Array<OrderBy>) => {
    return getSupplierRequests(buyerGcAccountId, skip, limit, orderBy);
  };

  const reviewButton = (rowData: any, fullWidth: boolean, disabled: boolean) => {
    return (
      <Can I={Permission.Actions.Review} a={Permission.Buyer.SupplierRequest}>
        {() => (
          <Button
            className={classes.reviewButton}
            appearance='secondary'
            fullWidth={fullWidth}
            asChild
            size='small'
            data-testid='uia-reviewButton'
            disabled={disabled}
            data-disabled={disabled || undefined}
          >
            <AdapterLink
              to={{
                pathname: `/buyer/requests/${rowData[supplierGcAccountIdIndex]}`,
                state: { title: 'Review request' },
              }}
            >
              Review
            </AdapterLink>
          </Button>
        )}
      </Can>
    );
  };

  const desktopColumnCustomBodyRenders = {
    dateOfInvite: (value) => <Typography>{moment(value).format(STANDARD_DATE_FORMAT)}</Typography>,
    detail: (_, row) => {
      const rowData = row.rowData;
      return (
        <Typography>
          {rowData[supplierAccountNameIndex]} has offered you a {formatSupplierTerm(rowData[termsInDaysIndex], rowData[termPeriodIndex], false)}{' '}
          account
        </Typography>
      );
    },
    review: (_, row) => reviewButton(row.rowData, false, isActionBlocked),
  };

  const mobileRowRender = (rowData) => {
    const inviteDate = moment(rowData[dateOfInviteIndex]).format(STANDARD_DATE_FORMAT);
    return (
      <Grid container className={classes.box} data-testid='uia-paymentsmobileview'>
        <Grid container className={classes.box}>
          <Grid item xs={12}>
            <Typography className={classes.mobileColumnLabel}>{inviteDate}</Typography>
            <Typography className={classes.mobileColumnValue}>{rowData[supplierAccountNameIndex]}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {reviewButton(rowData, true, isActionBlocked)}
        </Grid>
      </Grid>
    );
  };

  return (
    buyerGcAccountId && (
      <div className={classes.container}>
        <RemoteDataGrid
          getData={getSupplierRequestData}
          columns={enrichColumnsWithBodyRenders(columns, desktopColumnCustomBodyRenders)}
          mobileRowRender={mobileRowRender}
          data-testid='uia-dataGrid'
          defaultOrderBy={[{ columnName: 'dateOfInvite', isAscending: false }]}
        />
      </div>
    )
  );
};

export default SupplierRequests;
