export const ACTION_PREFIX = 'CUSTOMER_LIST';
export const PARTITION = 'customers';

export const actionTypes = {
  GET_CUSTOMERS_PENDING: 'GET_CUSTOMERS_PENDING',
  GET_CUSTOMERS_FULFILLED: 'GET_CUSTOMERS_FULFILLED',
  GET_CUSTOMERS_REJECTED: 'GET_CUSTOMERS_REJECTED',
  CANCEL_SUPPLIER_REQUEST_PENDING: 'CANCEL_SUPPLIER_REQUEST_PENDING',
  CANCEL_SUPPLIER_REQUEST_FULFILLED: 'CANCEL_SUPPLIER_REQUEST_FULFILLED',
  CANCEL_SUPPLIER_REQUEST_REJECTED: 'CANCEL_SUPPLIER_REQUEST_REJECTED',
  UPDATE_DEFAULT_TERMS_PENDING: 'UPDATE_DEFAULT_TERMS_PENDING',
  UPDATE_DEFAULT_TERMS_FULFILLED: 'UPDATE_DEFAULT_TERMS_FULFILLED',
  UPDATE_DEFAULT_TERMS_REJECTED: 'UPDATE_DEFAULT_TERMS_REJECTED',
  DELETE_SUPPLIER_BUYER_PENDING: 'DELETE_SUPPLIER_BUYER_PENDING',
  DELETE_SUPPLIER_BUYER_FULFILLED: 'DELETE_SUPPLIER_BUYER_FULFILLED',
  DELETE_SUPPLIER_BUYER_REJECTED: 'DELETE_SUPPLIER_BUYER_REJECTED',
};

export const ACTION_SUCCEED = '1';
export const UPDATE_TERM_ACTION = 'UPDATE_TERM';
export const CANCELPENDIN_ASSOCIATION_ACTION = 'CANCEL';
export const DELETE_CUSTOMER_ACTION = 'DELETE_CUSTOMER';
