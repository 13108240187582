// @flow
import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import parser from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  agreementContent: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    border: `1px solid ${theme.palette.grey['300']}`,
    padding: theme.spacing(0, 2),
    borderRadius: 4,
    backgroundColor: theme.palette.grey['200'],
  },
}));

type Props = {
  base64Text: string,
  height: any,
};

const AgreementContent = ({ base64Text, height }: Props) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.agreementContent} style={{ height }}>
      {base64Text ? parser(atob(base64Text)) : null}
    </Paper>
  );
};

export default AgreementContent;
