import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import initialDataSelectors from '../../components/InitialData/selectors';
import profileModeSelectors from '../../components/UserSelectProfileMode/selectors';
import accountCheckSetupSelectors from '../../components/AccountSetupCheck/selectors';
import PrivateRoutes from './Component';
import accountReadinessSelectors from '../../components/AccountReadinessAlerts/selectors';

const mapStateToProps = createSelector(
  initialDataSelectors.selectIsAllKycCompleted,
  initialDataSelectors.selectSelectedAccountTrustDeedNeedsApproval,
  initialDataSelectors.selectActiveAccount,
  profileModeSelectors.selectActiveProfileMode,
  accountCheckSetupSelectors.selectIsAllSetupCompleteRequirement,
  accountCheckSetupSelectors.selectIsTermsAndConditionsAgreementSetupComplete,
  accountReadinessSelectors.selectIsAccountReady,
  (
    isAllKycCompleted,
    activeAccountTrustDeedNeedsApproval,
    activeAccount,
    activeProfileModeIndex,
    isAllSetupCompleteRequirement,
    isTermsAndConditionsAgreementSetupComplete,
    isAccountReady
  ) => {
    const isSupplier = activeAccount && activeAccount.isSupplier;
    const isBuyer = activeAccount && activeAccount.isBuyer;
    return {
      isBuyer,
      isSupplier,
      isAllKycCompleted,
      activeAccountTrustDeedNeedsApproval,
      isAllSetupCompleteRequirement: isAllSetupCompleteRequirement || false,
      isTermsAndConditionsAgreementSetupComplete: isTermsAndConditionsAgreementSetupComplete || false,
      isAccountReady,
    };
  }
);

export default connect(mapStateToProps)(PrivateRoutes);
