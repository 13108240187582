import { createActions } from 'redux-actions';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';

export const { getPopularBanksPending, getPopularBanksFulfilled, getPopularBanksRejected } = createActions(
  actionTypes.GET_POPULAR_BANKS_PENDING,
  actionTypes.GET_POPULAR_BANKS_FULFILLED,
  actionTypes.GET_POPULAR_BANKS_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getPopularBanks = () => (dispatch) => {
  dispatch(getPopularBanksPending());

  return shiftPaymentsApi
    .getPopularBanks()
    .then((response) => {
      dispatch(getPopularBanksFulfilled(response));
    })
    .catch((error) => {
      dispatch(getPopularBanksRejected(error));
    });
};

export default {
  getPopularBanks,
};
