import withWidth from '@material-ui/core/withWidth';
import { Box, Button, Icon, NotificationBanner, Text } from '@shiftfinancial/design-system';
import { PhoneIcon } from '@shiftfinancial/react-icons/system';

import React from 'react';

import { ArrearsPaymentDialogue } from './ArrearsPaymentDialogue';
import { SHIFT_PHONE_NUMBER } from '../../constants';
import { isMobileResolution } from '../../lib/materialUiUtils';
import DollarsAndCentsText from '../DollarsAndCentsText';

type ArrearsPaymentCollectionBannerProps = {
  totalArrearsAmount: number;
  width: string;
  gcAccountId: string;
  setRefreshArrearsBanners: (refreshArrearsBanners: boolean) => void;
};

function ArrearsPaymentCollectionBannerWithoutWidth({
  totalArrearsAmount = 0,
  gcAccountId,
  width,
  setRefreshArrearsBanners,
}: ArrearsPaymentCollectionBannerProps) {
  const title = `Action required: Overdue balance`;
  const isMobile = isMobileResolution(width);

  const actions = [
    <ArrearsPaymentDialogue
      key={'arrearsDialogueAction'}
      totalArrearsAmount={totalArrearsAmount}
      gcAccountId={gcAccountId}
      setRefreshArrearsBanners={setRefreshArrearsBanners}
    />,
  ];

  if (isMobile) {
    actions.push(
      <Button
        key='arrearsCallButton'
        // Technically button elements do not support the `href` attribute.
        // If we want to be semantically correctly, we can use the asChild prop
        // to apply the styles to the child element, meaning we get the look of the Button,
        // but render an anchor element with the href.
        asChild
        appearance='secondary'
        startIcon={
          <Icon size='xsmall'>
            <PhoneIcon />
          </Icon>
        }
      >
        <a href='tel:+1300249649'>Call {SHIFT_PHONE_NUMBER}</a>
      </Button>
    );
  }

  return (
    <Box
      sx={{
        marginBottom: { xs: 'x1', sm: 'x3' },
        px: { xs: 'x2', sm: 'x0' },
      }}
    >
      <NotificationBanner appearance='error' actions={actions} title={title}>
        <Text data-testid='uia-arrears-collection-amount'>
          Your Trade Account has an overdue balance of <DollarsAndCentsText amount={totalArrearsAmount} /> (may include fees).
        </Text>
        <Text>Make a repayment or call us on {SHIFT_PHONE_NUMBER} to discuss alternative options.</Text>
        <Text>You’ll need to repay this amount to process new invoices.</Text>
      </NotificationBanner>
    </Box>
  );
}

const ArrearsPaymentCollectionBanner = withWidth()(ArrearsPaymentCollectionBannerWithoutWidth);
export { ArrearsPaymentCollectionBanner };
