// @flow
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import type { SupplierRequestDetail } from './types';
import BasicPageLayout from '../../../components/BasicPageLayout';
import AbnText from '../../../components/AbnText';
import { defaultHandleError } from '../../../lib/apiHelpers';
import { STANDARD_DATE_FORMAT } from '../../../constants';
import { formatSupplierTerm } from '../../../lib/formatter';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  summary: {
    fontWeight: 500,
    marginBottom: theme.spacing(2.5),
  },
  detail: {
    backgroundColor: '#FBFDFF',
    border: 'solid 1px #EEF6FF',
    borderLeft: `solid 5px ${theme.palette.secondary.main}`,
    '&.MuiPaper-elevation1': {
      boxShadow: 'none !important',
    },
    '& .MuiCardContent-root:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  contactName: {
    marginTop: theme.spacing(2),
  },
  icons: {
    fontSize: 14,
    verticalAlign: 'middle',
    marginRight: theme.spacing(0.5),
  },
  phoneNumber: {
    marginRight: theme.spacing(2.5),
  },
  email: {
    display: 'flex',
  },
  actions: {
    marginTop: theme.spacing(3),
  },
  primaryActions: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  approveButton: {
    [theme.breakpoints.down('xs')]: {
      flex: 1,
    },
  },
}));

type Props = {
  detail: SupplierRequestDetail,
  buyerGcAccountId: string,
  getSupplierRequest: (supplierGcAccountId: string, buyerGcAccountId: string) => Promise<any>,
  rejectRequest: (supplierGcAccountId: string, buyerGcAccountId: string) => Promise<any>,
  approveRequest: (supplierGcAccountId: string, buyerGcAccountId: string) => Promise<any>,
  resetTotalSupplierRequests: () => void,
};

const SupplierRequest = (props: Props) => {
  // $FlowFixMe
  const supplierGcAccountId: string = useParams().supplierGcAccountId;

  const { detail, buyerGcAccountId, getSupplierRequest, rejectRequest, approveRequest, resetTotalSupplierRequests } = props;

  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getSupplierRequest(supplierGcAccountId, buyerGcAccountId).then((response) => {
      if (response.error) {
        const {
          payload: { response: payloadResponse },
        } = response;

        if (payloadResponse.status === 404 && !!payloadResponse.data.message) {
          enqueueSnackbar(payloadResponse.data.message, {
            variant: 'error',
          });
        } else {
          defaultHandleError({ enqueueSnackbar });
        }

        history.push('/buyer/dashboard');
      }
    });
  }, [buyerGcAccountId, supplierGcAccountId]);

  const approve = () => {
    approveRequest(supplierGcAccountId, buyerGcAccountId).then((response) => {
      // TODO: Snackbar?
      if (response) {
        // reduce totalPendingRequest on success to trigger fetch data.
        resetTotalSupplierRequests();
        history.push('/buyer/dashboard');
      }
    });
    history.push('/buyer/dashboard');
  };

  const reject = () => {
    rejectRequest(supplierGcAccountId, buyerGcAccountId).then(() => {
      // TODO: Snackbar?
      history.push('/buyer/dashboard');
    });
  };

  return (
    <BasicPageLayout title={detail.accountName}>
      <Paper className={classes.container}>
        <Typography variant='body2'>{moment(detail.dateOfInvite).format(STANDARD_DATE_FORMAT)}</Typography>
        <Typography className={classes.summary}>
          {detail.contactName} from {detail.accountName} has offered you a {formatSupplierTerm(detail.termsInDays, detail.termPeriod, false)} account
        </Typography>
        <Card className={classes.detail}>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='h6'>{detail.accountName}</Typography>
                <Typography variant='body2'>
                  <AbnText value={detail.abn} />
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.contactName}>
                <Typography variant='h6'>{detail.contactName}</Typography>
              </Grid>
              <Grid item className={classes.phoneNumber}>
                <Typography variant='body2'>
                  <CallOutlinedIcon className={classes.icons} />
                  {detail.contactPhoneNumber}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' className={classes.email}>
                  <EmailOutlinedIcon className={classes.icons} />
                  <Link href={`mailto:${detail.contactEmail}`}>{detail.contactEmail}</Link>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
      <Grid container className={classes.actions}>
        <Grid item xs={12} sm={6}>
          {/* Report this supplier - Deferred */}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.primaryActions}>
          <Grid container spacing={1} justify='flex-end'>
            <Grid item>
              <Button variant='outlined' color='primary' onClick={reject} data-testid='uia-rejectRequest'>
                I don&apos;t know this supplier
              </Button>
            </Grid>
            <Grid item className={classes.approveButton}>
              <Button fullWidth variant='contained' color='primary' onClick={approve}>
                Approve
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BasicPageLayout>
  );
};

export default SupplierRequest;
