// @flow
import { handleActions } from 'redux-actions';

import { actionTypes, PARTITION, ACTION_PREFIX } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import { type AccountSetupStatus } from '../../components/AccountSetupCheck/reducer';

export type ReduxState = {
  totalBalanceRemaining: number,
  availableBalance: number,
  isPayBalanceCaseOpen: boolean,
  nextScheduledPaymentAmount: number,
  nextScheduledPaymentDate: ?Date,
  bankAccountSetup: AccountSetupStatus,
  creditLimit: number,
};

const initialState: ReduxState = {
  totalBalanceRemaining: 0,
  availableBalance: 0,
  isPayBalanceCaseOpen: false,
  nextScheduledPaymentAmount: 0,
  nextScheduledPaymentDate: undefined,
  bankAccountSetup: {},
  creditLimit: 0,
};

const reducer = handleActions<ReduxState>(
  {
    [actionTypes.GET_BALANCE_SUMMARY_FULFILLED]: (state, { payload }) => ({
      ...state,
      ...payload.data,
    }),
    [actionTypes.SET_IS_PAY_BALANCE_CASE_OPEN]: (state, { payload }) => ({
      ...state,
      isPayBalanceCaseOpen: payload.data,
    }),
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
