// @flow
import * as React from 'react';
import { Box } from '@material-ui/core';

import type { OrderBy } from '../api/shiftPaymentsApi';
import RemoteDataGrid from './RemoteDataGrid';
import BasicPageLayout from './BasicPageLayout';
import DataGridPageHeader from './DataGridPageHeader';
import { useIsMobileResolution } from '../lib/materialUiUtils';

type Props = {
  title: string,
  getData: (skip: number, limit: number, orderBy: ?Array<OrderBy>, search: ?string) => Promise<Object>,
  columns: Array<Object>,
  options?: { search: boolean },
  // mobileRowRender is optional. Used if you want a custom rendering for mobile.
  mobileRowRender?: ?(data: any, dataIndex: number, rowIndex: number) => React.Node,
  addButtonOptions?: { label: string, onAddClick: (event: Event) => void, disabled: boolean },
  defaultOrderBy?: Array<OrderBy>,
};

const DataGridPageLayout = React.forwardRef(
  ({ title, getData, columns, options, mobileRowRender, addButtonOptions, defaultOrderBy }: Props, ref) => {
    const isMobile = useIsMobileResolution();
  
    return (
      <BasicPageLayout noMargin={!isMobile} title={title} noHeader>
        <DataGridPageHeader title={title} addButtonOptions={addButtonOptions} />
        <Box>
          <RemoteDataGrid
            ref={ref}  
            getData={getData}
            columns={columns}
            options={options}
            mobileRowRender={mobileRowRender}
            data-testid='uia-dataGrid'
            defaultOrderBy={defaultOrderBy}
          />
        </Box>
      </BasicPageLayout>
    );
  }
);

export default (DataGridPageLayout);
