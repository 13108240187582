// @flow
import { PARTITION } from './constants';
import type { ReduxState } from './reducer';

const selectPartition = (state: any): ReduxState => state[PARTITION];
const selectBankLoginAccountOptions = (state: any) => selectPartition(state).bankLoginAccountOptions;
const selectBankLoginConfiguration = (state: any) => selectPartition(state).bankLoginConfiguration;

export default {
  selectPartition,
  selectBankLoginAccountOptions,
  selectBankLoginConfiguration,
};
