import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { bindActionCreators } from 'redux';
import BankSearch from './Component';
import actions from './actions';
import selectors from './selectors';

const mapStateToProps = createSelector(selectors.selectBank, (bank) => ({
  bank,
}));

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BankSearch);
