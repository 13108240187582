// @flow
import { useState, useEffect } from 'react';
import widgetsApi from '../../../api/widgetsApi';
import { type EmbedWidget } from '../types';

export type Theme = {
  name: string,
  hexColor: string,
};

export const useOpenAccountButtonThemes = (): Array<Theme> => {
  const [themes, setThemes] = useState<Array<Theme>>([]);

  useEffect(() => {
    widgetsApi.getOpenAccountButtonThemes().then((response) => {
      setThemes(response.data);
    });
  }, []);

  return themes;
};

export const useEmbedOpenAccountButton = (selectedTheme: string, term: number, termPeriod: string, gcAccountId: ?string): EmbedWidget => {
  const [embedWidget, setEmbedWidget] = useState<EmbedWidget>({ embeddableCode: '', isLoading: false });

  useEffect(() => {
    if (selectedTheme && selectedTheme !== ' ' && term > -1 && gcAccountId) {
      setEmbedWidget({
        embeddableCode: '',
        isLoading: true,
      });
      widgetsApi.getEmbedOpenAccountButton(gcAccountId, term, termPeriod, selectedTheme).then((response) => {
        setEmbedWidget({
          embeddableCode: response.data,
          isLoading: false,
        });
      });
    } else {
      setEmbedWidget({
        embeddableCode: '',
        isLoading: false,
      });
    }
  }, [selectedTheme, term, termPeriod, gcAccountId]);

  return embedWidget;
};
