// @flow
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { withFormik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { withRouter, useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Grid, Paper, Button, Box } from '@material-ui/core';
import type { FormikBag, FormikErrors } from 'formik';
import type { History, Location } from 'history';
import { withSnackbar } from 'notistack';
import InputAdornment from '@material-ui/core/InputAdornment';
import withWidth from '@material-ui/core/withWidth';
import clsx from 'clsx';

import BasicPageLayout from '../../../components/BasicPageLayout';
import FormItem from '../../../components/FormItem';
import { formSchema } from './validations';
import { FormikNumberFormatInputField, FormikAbnAutoSuggestField, FormikDndAttachFiles } from '../../../components/formik';
import { ATTACHMENT_SUPPORTED_FORMATS, DEFAULT_ERROR_MESSAGE, DEFAULT_TERM_PERIOD } from '../../../constants';
import { handleError, getErrorMessage } from '../../../lib/apiHelpers';
import { traceInfo } from '../../../lib/telemetryUtils';
import { useSupplierTermFeeLookup, type SupplierTermFee } from '../../ReferenceDataHooks/hooks';
import AlertError from '../../../components/AlertError';
import responseCode from '../../../api/generated/responseCode';
import { isMobileResolution } from '../../../lib/materialUiUtils';
import startupConfig, { type AttachmentConfig } from '../../../lib/startupConfig';
import CustomerProfile from './CustomerProfile';
import SettlementAccountWarning from '../../../components/SettlementAccountWarning';
import DollarsAndCentsText from "../../../components/DollarsAndCentsText";
import { Text } from '@shiftfinancial/design-system';

const { v4: uuidv4 } = require('uuid');

const useStyles = makeStyles((theme) => ({
  customerSearch: {
    marginBottom: theme.spacing(4),
  },
  customerInfo: {
    backgroundColor: theme.palette.common.paleOrange,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 5),
    },
  },
  fields: {
    borderTop: `1px solid ${theme.palette.grey['300']}`,
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 5),
    },
  },
  footerButtons: {
    marginTop: theme.spacing(2),
  },
  invoiceFilesDescription: {
    color: theme.palette.grey.light,
  },
  cancelButton: {
    fontWeight: 'normal',
    [theme.breakpoints.up('xs')]: {
      width: '50%',
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      width: 90,
      marginRight: theme.spacing(1),
    },
  },
  submitButton: {
    [theme.breakpoints.up('xs')]: {
      width: '50%',
      marginLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      width: 150,
    },
  },
  subLabel: {
    fontWeight: 500,
    marginRight: theme.spacing(1),
  },
  subBullet: {
    fontWeight: 500,
    color: theme.palette.common.orange,
    lineHeight: 1.2,
    margin: theme.spacing(0, 2),
  },
  customerDetails: {
    marginTop: theme.spacing(1),
    '& svg': {
      height: 18,
      width: 18,
      marginRight: theme.spacing(0.5),
    },
  },
  animationHolderBlank: {
    marginBottom: theme.spacing(5),
  },
  animationHolder: {
    animationName: '$wobble',
    animationDuration: '1s',
    animationIterationCount: 1,
    marginBottom: theme.spacing(5),
  },
  availableBalance: {
    marginTop: theme.spacing(1),
  },
  '@keyframes wobble': {
    '0%': {
      webkitTransform: 'none',
      transform: 'none',
    },
    '15%': {
      webkitTransform: 'translate3d(-10%, 0, 0) rotate3d(0, 0, 1, 0deg);',
      transform: 'translate3d(-10%, 0, 0) rotate3d(0, 0, 1, 0deg);',
    },
    '30%': {
      webkitTransform: 'translate3d(15%, 0, 0) rotate3d(0, 0, 1, 0deg);',
      transform: 'translate3d(15%, 0, 0) rotate3d(0, 0, 1, 0deg);',
    },
    '45%': {
      webkitTransform: 'translate3d(-10%, 0, 0) rotate3d(0, 0, 1, 0deg);',
      transform: 'translate3d(-10%, 0, 0) rotate3d(0, 0, 1, 0deg);',
    },
    '60%': {
      webkitTransform: 'translate3d(15%, 0, 0) rotate3d(0, 0, 1, 0deg);',
      transform: 'translate3d(15%, 0, 0) rotate3d(0, 0, 1, 0deg);',
    },
    '75%': {
      webkitTransform: 'translate3d(-10%, 0, 0) rotate3d(0, 0, 1, 0deg);',
      transform: 'translate3d(-10%, 0, 0) rotate3d(0, 0, 1, 0deg);',
    },
    '100%': {
      webkitTransform: 'none',
      transform: 'none',
    },
  },
}));

type FormValues = {
  customerGcAccountId: string,
  customerAccountName: string,
  customerAbn: string,
  defaultTermsInDays: ?number,
  defaultTermPeriod: ?string,
  contactName: string,
  contactMobileNumber: string,
  orderId: string,
  invoiceNumber: string,
  invoiceAmount: ?number,
  invoiceFiles: Array<any>,
  fileGroupId: string,
  adHocTerm?: SupplierTermFee,
  addPaymentRequestId: string,
  availableBalance: number,
};

type Props = {
  width: string,
  gcAccountId: string,
  customerSearchDelay: number,
  searchCustomers: (gcAccountId: string, search: string) => Promise<any>,
  addPayment: (
    supplierGcAccountId: string,
    customerGcAccountId: string,
    orderId: string,
    invoiceNumber: string,
    invoiceAmount: number,
    invoiceFiles: Array<any>,
    fileGroupId: string,
    addPaymentRequestId: string,
    adHocTerm: ?number,
    adHocTermPeriod: ?string
  ) => Promise<any>,
};

type WithHOCProps = {
  ...Props,
  isSubmitting: boolean,
  values: FormValues,
  errors: FormikErrors<FormValues>,
  touched: any,
  setFieldValue: (fieldName: string, value: any, shouldValidate?: boolean) => void,
  setValues: (fields: Object, shouldValidate?: boolean) => void,
  setFieldError: (fieldName: string, error: string) => void,
  resetForm: () => void,
  validatePaymentBuyer: (supplierGcAccount: string, buyerGcAccountId: string) => Promise<any>,
  deleteFiles: (gcAccountId: string, fileGroupId: string, files: Array<any>) => Promise<any>,
  getBuyerDetail: (supplierGcAccount: string, buyerGcAccountId: string) => Promise<any>,
  deleteInvoiceFile: (gcAccountId: string, fileGroupId: string, fileName: string) => Promise<any>,
  uploadFile: (gcAccountId: string, fileGroupId: string, fileName: string) => Promise<any>,
  onAdHocTermSelected: (term: any) => void,
  submitForm: () => void,
  isSettlementAccountSetupCompleted: boolean,
  isMerchantAgreementSetupCompleted: boolean,
};

const AddPayment = (props: WithHOCProps) => {
  const {
    width,
    gcAccountId,
    customerSearchDelay,
    searchCustomers,
    isSubmitting,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    resetForm,
    setFieldError,
    validatePaymentBuyer,
    deleteFiles,
    getBuyerDetail,
    uploadFile,
    deleteInvoiceFile,
    submitForm,
    isSettlementAccountSetupCompleted,
    isMerchantAgreementSetupCompleted,
  } = props;
  const classes = useStyles();
  const isMobile = isMobileResolution(width);
  const history = useHistory();
  const { buyerGcAccountId } = useParams();
  const [isPrompt, setIsPrompt] = useState(false);
  const supplierTerms = useSupplierTermFeeLookup(gcAccountId);
  const [addPaymentRequestId] = useState(uuidv4());

  const getCustomerSuggestions = (search: string) => {
    return searchCustomers(gcAccountId, search);
  };

  const handleCancel = () => {
    resetForm();
    deleteFiles(gcAccountId, values.fileGroupId, values.invoiceFiles);
    setFieldValue('invoiceFiles', []);
    if (buyerGcAccountId) {
      history.push('/supplier/payments/add');
    }
  };

  const handleSubmit = () => {
    setFieldValue('addPaymentRequestId', addPaymentRequestId);
    if (!isSettlementAccountSetupCompleted) {
      setIsPrompt(true);
    } else if (!isMerchantAgreementSetupCompleted) {
      setIsPrompt(true);
    } else {
      submitForm();
    }
    // reset value when animation time elapsed
    setTimeout(() => {
      setIsPrompt(false);
    }, 1000);
  };

  const handleCustomerSelected = (suggestion: { accountId: string }) => {
    if (values.customerGcAccountId) {
      setFieldValue('customerGcAccountId', '', false);
    }

    validatePaymentBuyer(gcAccountId, suggestion.accountId).then((validateResponse) => {
      const { error } = validateResponse;
      if (error) {
        setFieldError('customerGcAccountId', getErrorMessage(validateResponse));
      } else {
        getBuyerDetail(gcAccountId, suggestion.accountId).then((buyerDetailResponse) => {
          if (!buyerDetailResponse.error) {
            const { account, contact, terms, termPeriod, availableBalance } = buyerDetailResponse.payload.data;
            setValues(
              {
                ...values,
                customerGcAccountId: suggestion.accountId,
                customerAccountName: account.accountName,
                customerAbn: account.abn,
                defaultTermsInDays: terms,
                defaultTermPeriod: termPeriod,
                contactName: `${contact.firstName} ${contact.lastName}`,
                contactMobileNumber: contact.mobileNumber,
                availableBalance,
              },
              false
            );
          }
        });
      }
    });
  };

  const onAdHocTermSelected = (value: any) => {
    setFieldValue('adHocTerm', value);
  };

  useEffect(() => {
    if (buyerGcAccountId) {
      handleCustomerSelected({ accountId: buyerGcAccountId });
    }
  }, [buyerGcAccountId]);

  let customerSearch = null;
  if (!buyerGcAccountId) {
    customerSearch = (
      <div className={clsx({ [classes.customerSearch]: !!values.customerAccountName })}>
        <FormItem label='Enter customers ABN or Business/Trading name' noTopMargin>
          <Field
            component={FormikAbnAutoSuggestField}
            variant='outlined'
            fullWidth
            id='customerGcAccountId'
            name='customerGcAccountId'
            autoFocus
            data-testid='uia-customerAutoSuggest'
            getSuggestions={getCustomerSuggestions}
            onSuggestionSelected={handleCustomerSelected}
            delay={customerSearchDelay}
            searchMode='ShiftSearch'
            showGrid={false}
          />
        </FormItem>
      </div>
    );
  }

  const { maxFilesCount, maxFileSizeInMb } = startupConfig.get().invoiceAttachment;

  return (
    <BasicPageLayout title='Add a new payment' noMargin={!isMobile}>
      <Form noValidate autoComplete='off'>
        {!isSettlementAccountSetupCompleted ? (
          <div className={clsx({ [classes.animationHolder]: isPrompt, [classes.animationHolderBlank]: !isPrompt })}>
            <SettlementAccountWarning />
          </div>
        ) : (
          <></>
        )}
        {!isMerchantAgreementSetupCompleted ? (
          <div className={clsx({ [classes.animationHolder]: isPrompt, [classes.animationHolderBlank]: !isPrompt })}>
            <AlertError
              message="You can't process an invoice until you have signed the merchant agreement."
              data-testid='uia-merchantAgreementError'
            />
          </div>
        ) : (
          <></>
        )}
        <Paper>
          <Box className={classes.customerInfo}>
            {customerSearch}
            {values.customerAccountName ? (
              <CustomerProfile
                customerAccountName={values.customerAccountName}
                customerAbn={values.customerAbn}
                customerGcAccountId={values.customerGcAccountId}
                contactName={values.contactName}
                contactMobileNumber={values.contactMobileNumber}
                supplierTerms={supplierTerms}
                defaultTermsInDays={values.defaultTermsInDays}
                defaultTermPeriod={values.defaultTermPeriod}
                onAdHocTermSelected={onAdHocTermSelected}
              />
            ) : null}
          </Box>
          {errors.customerGcAccountId ? (
            <Box className={classes.fields}>
              <AlertError message={errors.customerGcAccountId} />
            </Box>
          ) : null}
          {values.customerGcAccountId && !errors.customerGcAccountId ? (
            <Box className={classes.fields}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormItem label='Order ID' noTopMargin>
                    <Field
                      component={TextField}
                      variant='outlined'
                      fullWidth
                      id='orderId'
                      name='orderId'
                      data-testid='uia-orderId'
                      value={values.orderId}
                      autoFocus
                    />
                  </FormItem>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormItem label='Invoice number *' noTopMargin>
                    <Field
                      component={TextField}
                      variant='outlined'
                      fullWidth
                      id='invoiceNumber'
                      name='invoiceNumber'
                      data-testid='uia-invoiceNumber'
                      value={values.invoiceNumber}
                      inputProps={{
                        maxLength: 18,
                      }}
                    />
                  </FormItem>
                </Grid>
                {!isMobile && <Grid item xs={12} sm={4} />}
                <Grid item xs={12} sm={4}>
                  <FormItem label='Invoice amount *'>
                    <Field
                      component={FormikNumberFormatInputField}
                      variant='outlined'
                      fullWidth
                      id='invoiceAmount'
                      name='invoiceAmount'
                      data-testid='uia-invoiceAmount'
                      value={values.invoiceAmount}
                      thousandSeparator
                      allowNegative={false}
                      startAdornment={<InputAdornment position='start'>AUD&nbsp;$</InputAdornment>}
                      isAllowed={(x) => x.value !== '0'}
                    />
                    <div className={classes.availableBalance}>
                      <Text color='secondary'>
                        Customer's available balance: <DollarsAndCentsText amount={values.availableBalance}/>
                      </Text>
                    </div>
                  </FormItem>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormItem label='Attach invoice'>
                    <FormikDndAttachFiles
                      name='invoiceFiles'
                      values={values}
                      errors={errors}
                      touched={touched}
                      accept={ATTACHMENT_SUPPORTED_FORMATS.toString()}
                      fileGroupId={values.fileGroupId}
                      gcAccountId={gcAccountId}
                      onFileUpload={uploadFile}
                      onFileDelete={deleteInvoiceFile}
                      maxFilesCount={maxFilesCount}
                      maxFileSizeInMb={maxFileSizeInMb}
                    />
                  </FormItem>
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </Paper>
        {values.customerGcAccountId && !errors.customerGcAccountId ? (
          <Box display='flex' justifyContent={isMobile ? 'inherit' : 'flex-end'} className={classes.footerButtons}>
            <Button className={classes.cancelButton} color='primary' disabled={isSubmitting} onClick={handleCancel} data-testid='uia-cancelButton'>
              Cancel
            </Button>
            <Button
              className={classes.submitButton}
              variant='contained'
              color='primary'
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
              data-testid='uia-processButton'
            >
              Process
            </Button>
          </Box>
        ) : null}
      </Form>
    </BasicPageLayout>
  );
};

AddPayment.defaultProps = {
  customerSearchDelay: 1000,
};

type WrapperProps = {
  ...Props,
  setError: (error: ?string) => void,
  fileGroupIdForTesting: string,
  closeSnackbar: () => void,
  invoiceAttachmentConfig: AttachmentConfig,
  history: History<Location>,
};

export default withRouter<WrapperProps>(
  withSnackbar(
    withFormik({
      mapPropsToValues: ({ fileGroupIdForTesting }): FormValues => ({
        customerGcAccountId: '',
        customerAccountName: '',
        defaultTermsInDays: null,
        defaultTermPeriod: DEFAULT_TERM_PERIOD,
        customerAbn: '',
        contactName: '',
        contactMobileNumber: '',
        orderId: '',
        invoiceNumber: '',
        invoiceAmount: null,
        invoiceFiles: [],
        fileGroupId: fileGroupIdForTesting || uuidv4(),
        addPaymentRequestId: '',
        availableBalance: 0,
      }),
      validateOnBlur: false,
      validationSchema: formSchema,
      handleSubmit: (values: FormValues, formikBag: FormikBag<WrapperProps, FormValues>) => {
        const { props } = formikBag;
        const {
          customerGcAccountId,
          customerAccountName,
          orderId,
          invoiceNumber,
          invoiceAmount,
          invoiceFiles,
          fileGroupId,
          adHocTerm,
          addPaymentRequestId,
        } = values;
        props.closeSnackbar();
        props
          .addPayment(
            props.gcAccountId,
            customerGcAccountId,
            orderId,
            invoiceNumber,
            (invoiceAmount: any),
            invoiceFiles,
            fileGroupId,
            addPaymentRequestId,
            adHocTerm ? adHocTerm.term : null,
            adHocTerm ? adHocTerm.termPeriod : null
          )
          .then((response) => {
            traceInfo("5 - Handling reponse for addPaymentRequestId=" + addPaymentRequestId + " - UTC time is:" + new Date().toISOString());
            formikBag.setSubmitting(false);

            //the response format of the payload for success would be for example:
            //{"id":"a4E9r000000DB7JEAW","paymentStatus":"Pending"}

            //we handle errors first:

            if (response?.payload?.code) {
              let errorMessage = null;
              if (response.payload.code === responseCode.SalesforceAccountInsufficientAvailableBalance && response.payload.data) {
                errorMessage = `${customerAccountName} has insufficient funds of $${(response.payload.data.availableFunds || 0).toFixed(2)}.
                          Alternatively you can also proceed to process a partial payment of the invoice amount.`;
              } else if (response.payload.code === responseCode.InvoiceMissingAttachment) {
                  errorMessage = 'Something went wrong while uploading the attachment(s). Please try again or call us on 1300 249 649 for assistance.';
              } else if (response.payload.code === responseCode.CommonServerError) {
                errorMessage = DEFAULT_ERROR_MESSAGE;
              } else if (response.payload.code === responseCode.InvoiceRequireRetry) {
                errorMessage = response.payload.detail;
              } else {
                errorMessage = getErrorMessage(response);
              }
              return handleError(response.payload, props, formikBag, undefined, errorMessage);
            }

            //handle 404 & 500 network errors
            //or any other errors which do not result in the expected format of {"id":"a4E9r000000DB7JEAW","paymentStatus":"Pending"}
            //eg response attribute validation errors which have payload like { "supplierGCAccountId": ["The field SupplierGCAccountId is invalid."] }
            if (response?.payload?.status || response?.payload?.id === undefined) {
                return handleError(response.payload, props, formikBag, undefined, null);
            }

            if (response.payload) {
              const { paymentStatus } = response.payload;

              if (paymentStatus === 'Paid') {
                props.history.push({
                  pathname: '/supplier/payments/message',
                  state: {
                    variant: 'success',
                    title: 'Success',
                    paragraph1: `Your payment of $${invoiceAmount} to ${customerAccountName} is successfully processed.`,
                    paragraph2: `${customerAccountName} will be notified of this payment.`,
                    backButtonLabel: 'Go back to payments',
                    backButtonRoute: {
                      pathname: '/supplier/payments',
                    },
                  },
                });
              } else {
                props.history.push({
                  pathname: '/supplier/payments/message',
                  state: {
                    variant: 'success',
                    title: 'Success',
                    paragraph1: `A request has been sent successfully to ${customerAccountName}.`,
                    paragraph2: 'This request will be processed once they approve the request.',
                    backButtonLabel: 'Go back to payments',
                    backButtonRoute: {
                      pathname: '/supplier/payments',
                    },
                  },
                });
              }
            }
          });
      },
      displayName: 'AddPayment',
    })(withWidth()(AddPayment))
  )
);
