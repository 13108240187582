/* eslint-disable no-nested-ternary */
// @flow
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { MenuItem, Menu, Grid, Button, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { UserProfileMode } from '../../types';
import { ROUTES } from '../../constants';
import ButtonSelect from '../ButtonSelect';
import { isLargeMobileResolution } from '../../lib/materialUiUtils';

const useStyles = makeStyles((theme) => ({
  desktopRoot: {
    display: 'inlineflex',
    width: 180,
    backgroundColor: theme.palette.common.orange,
  },
  desktopButton: {
    color: theme.palette.common.white,
    width: '100%',
    height: '100%',
    fontSize: 13,
    '& .MuiMenu-paper': {
      left: '83.8% !important',
    },
  },
  buttontabForMobile: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& .MuiGrid-spacing-xs-2': {
      margin: '0px',
    },
    '& .MuiGrid-spacing-xs-2 > .MuiGrid-item': {
      width: '47%',
      padding: 0,
    },
    color: theme.palette.common.white.concat('!important'),
    '& a': {
      opacity: '1 !important',
      color: `${theme.palette.common.white} !important`,
      margin: 'auto',
    },
    '& span': {
      color: `${theme.palette.common.white} !important`,
      fontSize: '10px !important',
    },
    '& a:active': {
      backgroundColor: theme.palette.common.orange.concat('!important'),
    },
    '& button': {
      opacity: 1,
      margin: 'auto',
      width: '100% !important',
      minWidth: '100% !important',
      border: '0px',
      padding: '10% !important',
    },
  },
  buttonSelectSelectedForMobile: {
    backgroundColor: theme.palette.common.orange.concat('!important'),
    borderRadius: 0,
  },
  buttonSelectDefaultForMobile: {
    backgroundColor: `${theme.palette.common.darkerGreen} !important`,
    borderRadius: 0,
  },
  becomeSupplierLabel: {
    borderRadius: 16,
    color: theme.palette.common.white.concat('!important'),
    backgroundColor: theme.palette.common.orange,
    width: '95%',
    [theme.breakpoints.up('sm')]: {
      fontSize: '12px !important',
      justifyContent: 'flex-end',
      height: theme.spacing(4),
      paddingTop: theme.spacing(0.8),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRadius: 25,
      padding: 3,
    },
  },
  becomeSupplierButtonPanel: {
    width: '15%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      paddingTop: theme.spacing(1.5),
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(0.2),
    },
    '& button': {
      width: '100% !important',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-end !important',
      },
    },
  },
  becomeBuyerButtonPanel: {
    width: '15%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      paddingTop: theme.spacing(1.5),
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(0.2),
    },
    '& button': {
      width: '100% !important',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-end !important',
      },
    },
  },
}));

const StyledMenu = withStyles((theme) => ({
  paper: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    border: `1px solid ${theme.palette.grey['300']}`,
    '& .MuiListItem-root': {
      minWidth: 172.5,
      fontSize: 13,
      padding: theme.spacing(2),
    },
    '& .MuiListItem-root:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey['300']}`,
    },
  },
}))((props) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

type Props = {
  isSupplier: boolean,
  isBuyer: boolean,
  options: Array<Object>,
  selected: number,
  width: string,
  history: RouterHistory,
  isDirector: boolean,
  isAPM: boolean,
  isARM: boolean,
  isAtLeastOneDirectorKycCompleted: boolean,
  updateOpenTradeAccount: ({ isOpenTradeAccount: boolean, attempt: number }) => void,
  updateProfileModeIndex: (newProfileMode: number) => void,
};

const UserSelectProfileMode = (props: Props) => {
  const {
    isSupplier,
    isBuyer,
    isAtLeastOneDirectorKycCompleted,
    options,
    selected,
    width,
    history,
    isDirector,
    isAPM,
    isARM,
    updateOpenTradeAccount,
    updateProfileModeIndex,
  } = props;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const isMobile = isLargeMobileResolution(width);

  const handleClose = () => {
    setOpen(false);
  };

  const redirectTo = (userProfileMode: number, isOpenTradeAccount: boolean = false) => {
    let route = '/';
    if (userProfileMode === UserProfileMode.Supplier && isAtLeastOneDirectorKycCompleted) {
      if (isSupplier) {
        route = ROUTES.SUPPLIER.DASHBOARD;
      } else {
        route = '/supplier/request-supplier-access';
      }
    } else if (userProfileMode === UserProfileMode.Buyer && isOpenTradeAccount) {
      if (!isBuyer && isSupplier) {
        updateOpenTradeAccount({ isOpenTradeAccount, attempt: 1 });
      } else {
        route = '/buyer/dashboard';
      }
    } else {
      route = '/buyer/dashboard';
    }
    if (updateProfileModeIndex) {
      updateProfileModeIndex(userProfileMode);
    }
    history.push(route);
  };

  const handleProfileModeChangeDesktop = (newValue) => {
    redirectTo(Number(newValue.id));
    setOpen(false);
  };

  const handleProfileModeChangeMobile = (newValue) => {
    redirectTo(Number(newValue.id));
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  if (isBuyer && isSupplier && isAtLeastOneDirectorKycCompleted) {
    if (isDirector || isAPM || isARM) {
      return isMobile ? (
        <div className={classes.buttontabForMobile} data-testid='uia-profileModeButtonMobile'>
          <ButtonSelect
            options={options}
            onSelectionChange={handleProfileModeChangeMobile}
            value={selected}
            classes={{
              selected: classes.buttonSelectSelectedForMobile,
              default: classes.buttonSelectDefaultForMobile,
            }}
          />
        </div>
      ) : (
        <Grid className={classes.desktopRoot} data-testid='uia-profileMenuButtonDesktop'>
          <Button className={classes.desktopButton} ref={anchorRef} aria-haspopup='true' onClick={handleToggle}>
            {options[selected].text}
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
          <StyledMenu data-testid='uia-menu' anchorEl={anchorRef.current} keepMounted open={open} onClose={handleClose}>
            {options
              .filter((value, index) => index !== selected)
              .map((option) => (
                <MenuItem key={option.id} onClick={() => handleProfileModeChangeDesktop(option)}>
                  {option.text}
                </MenuItem>
              ))}
          </StyledMenu>
        </Grid>
      );
    } else {
      return <div />;
    }
  }
  if (!isBuyer && isSupplier) {
    if (isDirector) {
      return (
        <div className={classes.becomeBuyerButtonPanel} data-testid='uia-becomeABuyerButtonMobile'>
          <Button onClick={() => redirectTo(Number(UserProfileMode.Buyer), true)}>
            <Typography className={classes.becomeSupplierLabel}>Open Trade Account</Typography>
          </Button>
        </div>
      );
    } else {
      return <div />;
    }
  }

  if (isDirector) {
    return (
      <div className={classes.becomeSupplierButtonPanel} data-testid='uia-becomeASupplierButtonMobile'>
        <Button onClick={() => redirectTo(Number(UserProfileMode.Supplier))}>
          <Typography className={classes.becomeSupplierLabel}>Become a Supplier </Typography>
        </Button>
      </div>
    );
  } else {
    return <div />;
  }
};
export default withRouter(withWidth()(UserSelectProfileMode));
