import moment from 'moment';

import baseFetchApi, { getBaseUrl } from './baseFetchApi';
import { PaymentMethod } from './shiftPaymentsApi';
import { traceInfo } from '../lib/telemetryUtils';

const addInvoice = (
  buyerGcAccountId: string,
  invoiceNumber: string,
  amount: number,
  invoiceDescription: string,
  fileGroupId: string,
  attachments: Array<string>,
  supplierGcAccountId: string,
  paymentMethod: PaymentMethod,
  isNewSupplier: boolean,
  isDirector: boolean,
  invoiceConfirmRequestId: string,
  paymentDate?: Date,
  installmentTermInWeeks?: number
) => {
  const url = `${getBaseUrl()}/api/invoices`;

  const data = {
    gcAccountId: buyerGcAccountId,
    invoiceNumber,
    invoiceAmount: amount,
    invoiceDescription,
    fileGroupId,
    invoiceFilenames: attachments,
    supplierGcAccountId,
    paymentMethod,
    isNewSupplier,
    isDirector,
    invoiceConfirmRequestId,
    paymentDate: paymentDate ? moment(paymentDate).format('YYYY-MM-DD') : null,
    installmentTermInWeeks,
  };

  const options = baseFetchApi.post({ url: url, data: data, apiVersion: '2.0', requestId: invoiceConfirmRequestId });
  return fetch(url, options);
};

const confirmInvoice = (
  contractId: string,
  gcAccountId: string,
  paymentMethod: PaymentMethod,
  requestId: string,
  paymentDate: Date,
  installmentTermInWeeks?: number
) => {
  const url = `${getBaseUrl()}/api/invoices/${contractId}/confirm?gcAccountId=${gcAccountId}`;

  const data = {
    paymentMethod,
    paymentDate,
    installmentTermInWeeks,
  };

  const options = baseFetchApi.post({ url: url, data: data, requestId });
  return fetch(url, options);
};

const addPayment = (
  supplierGcAccountId: string,
  customerGcAccountId: string,
  orderId: string,
  invoiceNumber: string,
  invoiceAmount: number,
  invoiceFilenames: Array<string>,
  fileGroupId: string,
  addPaymentRequestId: string,
  adHocTerm?: number,
  adHocTermPeriod?: string
) => {
  const url = `${getBaseUrl()}/api/payments`;

  const data = {
    supplierGcAccountId,
    gcAccountId: customerGcAccountId,
    orderId,
    invoiceNumber,
    invoiceAmount,
    invoiceFilenames,
    fileGroupId,
    adHocTerm,
    adHocTermPeriod,
  };

  const options = baseFetchApi.post({ url: url, data: data, apiVersion: '2.0', requestId: addPaymentRequestId });
  traceInfo('2 - Fetch about to execute for addPaymentRequestId=' + addPaymentRequestId + ' - UTC time is:' + new Date().toISOString());
  return fetch(url, options);
};

const declineInvoice = (contractId: string, gcAccountId: string) => {
  const url = `${getBaseUrl()}/api/invoices/${contractId}/decline?gcAccountId=${gcAccountId}`;

  const options = baseFetchApi.post({ url: url });
  return fetch(url, options);
};

const payArrearsPayment = (gcAccountId: string, amount: number) => {
  const url = `${getBaseUrl()}/api/payments/arrears/${gcAccountId}`;

  const options = baseFetchApi.post({ url: url, apiVersion: '2.0', data: `${amount}` });
  return fetch(url, options);
};

const payInvoice = (gcAccountId: string, contractId: string, amount: number) => {
  const url = `${getBaseUrl()}/api/payments/invoice/${gcAccountId}/${contractId}`;

  const options = baseFetchApi.post({ url: url, apiVersion: '2.0', data: `${amount}` });
  return fetch(url, options);
};

export default {
  addInvoice,
  confirmInvoice,
  declineInvoice,
  addPayment,
  payArrearsPayment,
  payInvoice,
};
