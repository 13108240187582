import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import Component from './Component';
import startupConfigSelectors from '../StartupConfig/selectors';
import initialDataSelectors from '../InitialData/selectors';

const mapStateToProps = createSelector(
  startupConfigSelectors.selectConfiguration,
  startupConfigSelectors.selectIsConfigurationSet,
  initialDataSelectors.selectMobileNumber,
  (configuration, isConfigurationSet, mobileNumber) => ({
    configuration,
    isConfigurationSet,
    mobileNumber,
  })
);

export default connect(mapStateToProps)(Component);
