// @flow
import { useAbility } from '@casl/react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { BankLinkDialog, useGetAnalyticsAccountSummary } from '../../../components/BankLinkDialog/';

import BasicPageLayout from '../../../components/BasicPageLayout';
import DataGridPageHeader from '../../../components/DataGridPageHeader';
import { PublicSuppliersGrid } from '../../../components/PublicSuppliersGrid';
import { Permission } from '../../../constants';
import { AbilityContext } from '../../../lib/ability';

const useStyles = makeStyles((theme) => ({
    tab: {
        [theme.breakpoints.down('xs')]: {
            width: '50%',
        },
    },
    container: {
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(1),
        },
    },
}));

type Props = {
    isActionBlocked: boolean,
    gcAccountId: string,
};

const Suppliers = (props: Props) => {
    const { isActionBlocked, gcAccountId } = props;
    const ability = useAbility(AbilityContext);
    const classes = useStyles();
    const history = useHistory();

    const [bankLinkEnforcementStatusDialog, setBankLinkEnforcementStatusDialog] = useState(false);
    const bankLinkEnforcementStatus = useGetAnalyticsAccountSummary(gcAccountId);

    const handleAddClick = () => {
        if (bankLinkEnforcementStatus && bankLinkEnforcementStatus.isRequireConnection) {
            setBankLinkEnforcementStatusDialog(true);
            return;
        }
        history.push('/buyer/payments/pay-new-or-existing');
    };

    return (
        <BasicPageLayout noMargin title='Supplier' noHeader>
            {bankLinkEnforcementStatusDialog ? <BankLinkDialog onClose={() => setBankLinkEnforcementStatusDialog(false)} /> : null}
            <Box className={classes.container}>
                <DataGridPageHeader
                    title='Suppliers'
                    addButtonOptions={
                        ability.can(Permission.Actions.Create, Permission.Buyer.Invoice)
                            ? {
                                label: 'Pay a supplier',
                                onAddClick: handleAddClick,
                                disabled: isActionBlocked,
                            }
                            : null
                    }
                />
            </Box>
            <PublicSuppliersGrid isBankConnectRequired={bankLinkEnforcementStatus.isRequireConnection} />
        </BasicPageLayout>
    );
};

export default Suppliers;
