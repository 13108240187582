// @flow
import { makeStyles, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import { Button } from '@shiftfinancial/design-system';
import * as React from 'react';

import { isMobileResolution } from '../lib/materialUiUtils';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      '& .MuiButton-root': {
        minWidth: 35,
        height: 35,
      },
    },
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  headerSecondLine: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {},
  button: {
    fontSize: '14px',
  },
}));

type Props = {
  width: string,
  title: string,
  addButtonOptions?: { label: string, onAddClick: (event: Event) => void, disabled: boolean, showNextLine: Boolean },
};

const DataGridPageHeader = ({ width, title, addButtonOptions }: Props) => {
  const classes = useStyles();
  const isMobile = isMobileResolution(width);

  return (
    (title || addButtonOptions) && (
      <Grid container className={classes.header}>
        <Grid item xs={addButtonOptions && addButtonOptions.showNextLine ? 12 : 6}>
          <Typography variant='h1' className={classes.title}>
            {title}
          </Typography>
        </Grid>
        {addButtonOptions ? (
          <Grid
            item
            xs={addButtonOptions && addButtonOptions.showNextLine ? 12 : 6}
            className={addButtonOptions && addButtonOptions.showNextLine ? classes.headerSecondLine : classes.headerRight}
          >
            <Button
              appearance='primary'
              data-testid='uia-addButton'
              disabled={addButtonOptions.disabled}
              className={classes.button}
              onClick={addButtonOptions.onAddClick}
            >
              {isMobile ? '+' : addButtonOptions.label}
            </Button>
          </Grid>
        ) : null}
      </Grid>
    )
  );
};

export default withWidth()(DataGridPageHeader);
