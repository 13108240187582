/* eslint-disable react/display-name */
// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import withWidth from '@material-ui/core/withWidth';

import DollarsAndCentsText from '../DollarsAndCentsText';
import type { OrderBy } from '../../api/shiftPaymentsApi';
import { enrichColumnsWithBodyRenders } from '../../lib/dataGridUtils';
import RemoteDataGrid from '../RemoteDataGrid';
import { STANDARD_DATE_FORMAT_WITH_DAY_OF_WEEK } from '../../constants';
import { isLargeMobileResolution } from '../../lib/materialUiUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

const columns = [
  {
    name: 'dueDate',
    label: 'Date',
    options: {
      sort: true,
    },
  },
  {
    name: 'totalInstalment',
    label: 'Amount',
    options: {
      sort: false,
    },
  },
];

type Props = {
  width: string,
  gcAccountId: string,
  getUpcomingPayments: (gcAccountId: string, skip: number, limit: number, orderBy: ?Array<OrderBy>) => Promise<any>,
};

const UpcomingPayments = ({ width, gcAccountId, getUpcomingPayments }: Props) => {
  const classes = useStyles();
  const isMobile = isLargeMobileResolution(width);

  const getData = (skip: number, limit: number, orderBy: Array<OrderBy>) => {
    return getUpcomingPayments(gcAccountId, skip, limit, orderBy);
  };

  const desktopColumnCustomBodyRenders = {
    dueDate: (value) => <Typography>{moment(value).format(STANDARD_DATE_FORMAT_WITH_DAY_OF_WEEK)}</Typography>,
    totalInstalment: (value) => (
      <Typography>
        <DollarsAndCentsText amount={value} />
      </Typography>
    ),
  };

  const options = {
    responsive: isMobile ? 'scrollFullHeight' : 'stacked',
  };

  return (
    gcAccountId && (
      <div className={classes.container}>
        <RemoteDataGrid
          getData={getData}
          columns={enrichColumnsWithBodyRenders(columns, desktopColumnCustomBodyRenders)}
          data-testid='uia-dataGrid'
          options={options}
          defaultOrderBy={[{ columnName: 'dueDate', isAscending: true }]}
        />
      </div>
    )
  );
};

export default withWidth()(UpcomingPayments);
