export const ACTION_PREFIX = 'SHARE_INVOICE';
export const PARTITION = 'shareInvoice';

export const actionTypes = {
  SHARE_INVOICE_PENDING: 'SHARE_INVOICE_PENDING',
  SHARE_INVOICE_FULFILLED: 'SHARE_INVOICE_FULFILLED',
  SHARE_INVOICE_REJECTED: 'SHARE_INVOICE_REJECTED',
  GET_ACCOUNT_PENDING: 'GET_ACCOUNT_PENDING',
  GET_ACCOUNT_FULFILLED: 'GET_ACCOUNT_FULFILLED',
  GET_ACCOUNT_REJECTED: 'GET_ACCOUNT_REJECTED',
};
