// @flow
/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import startupConfigSelectors from '../StartupConfig/selectors';
import { type StartupConfigValues } from '../../lib/startupConfig';
import initialDataSelectors from '../InitialData/selectors';
import { getCorrelationId } from '../../lib/telemetryUtils';

const Mouseflow = () => {
  const configuration: StartupConfigValues = useSelector(startupConfigSelectors.selectConfiguration);
  const mobileNumber: string = useSelector(initialDataSelectors.selectMobileNumber);
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (!initialized && configuration && configuration.mfKey) {
      window._mfq = window._mfq || [];

      // eslint-disable-next-line func-names
      const mf = document.createElement('script');
      mf.type = 'text/javascript';
      mf.defer = true;
      mf.src = `//cdn.mouseflow.com/projects/${configuration.mfKey}.js`;
      document.getElementsByTagName('head')[0].appendChild(mf);
      window._mfq.push(['setVariable', 'shift-correlation-id', getCorrelationId()]);
      window._mfq.push(['tag', 'Shift']);
      setInitialized(true);
    }
  }, [configuration, initialized]);

  useEffect(() => {
    if (initialized && mobileNumber) {
      window._mfq.push(['identify', mobileNumber]);
      window._mfq.push(['setVariable', 'mobileNumber', mobileNumber]);
    }
  }, [mobileNumber, initialized]);

  return null;
};

export default Mouseflow;
