// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  theme: any,
};

const AccountOpenIcon = withTheme((props: Props) => (
  <SvgIcon style={{ height: '3rem', width: '4rem', backgroundColor: props.theme.palette.common.lightYellow }} viewBox='0 0 72 50' {...(props: any)}>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='account_open_icon' transform='translate(11.049073, 12.258542)' fill={props.theme.palette.common.orange}>
        <path
          d='M4.10400192,2.802096 C4.10400192,4.05563136 2.2236696,4.05563136 2.2236696,2.802096 C2.2236696,1.54856064 4.10400192,1.54856064 4.10400192,2.802096'
          id='Path'
          strokeWidth='0.3'
          strokeLinejoin='round'
        />
        <path
          d='M6.65546112,2.802096 C6.65546112,4.05563136 4.77398688,4.05563136 4.77398688,2.802096 C4.77398688,1.54856064 6.65546112,1.54856064 6.65546112,2.802096'
          id='Path'
          strokeWidth='0.3'
          strokeLinejoin='round'
        />
        <path
          d='M9.20604192,2.802096 C9.20604192,4.05563136 7.3257096,4.05563136 7.3257096,2.802096 C7.3257096,1.54856064 9.20604192,1.54856064 9.20604192,2.802096'
          id='Path'
          strokeWidth='0.3'
          strokeLinejoin='round'
        />
        <polygon
          id='Path'
          strokeWidth='0.3'
          strokeLinejoin='round'
          points='19.9526803 2.3447424 24.1581667 2.3447424 24.1581667 3.2597424 19.9526803 3.2597424'
        />
        <path
          d='M25.0034803,0 L1.83685152,0 C0.8235,0 0,0.8235 0,1.83685152 L0,17.5566979 C0,18.5689075 0.8235,19.3935494 1.83685152,19.3935494 L25.0034803,19.3935494 C26.0156899,19.3935494 26.8403318,18.5700494 26.8403318,17.5566979 L26.8403318,13.5374323 L26.8391879,10.2000979 L26.8391879,1.83714432 C26.8391879,0.8237928 26.0156879,0.0002928 25.0034783,0.0002928 L25.0034803,0 Z M25.9241899,7.6848288 L25.9241899,17.5565808 C25.9241899,18.0644131 25.5112834,18.4784323 25.0023384,18.4784323 L1.8368808,18.4784323 C1.32790656,18.4784323 0.91502928,18.0655258 0.91502928,17.5577227 L0.91502928,5.25895152 L25.9254197,5.25895152 L25.9241899,7.6848288 Z M25.9241899,4.3439808 L0.91497072,4.3439808 L0.91497072,1.8368808 C0.91497072,1.32904848 1.32787728,0.91502928 1.83682224,0.91502928 L25.003451,0.91502928 C25.5112834,0.91502928 25.9253026,1.32793584 25.9253026,1.8368808 L25.9241899,4.3439808 Z'
          id='Shape'
          strokeWidth='0.3'
          strokeLinejoin='round'
        />
        <path
          d='M13.6636724,7.7414576 L19.7069787,7.7414576 C19.971192,7.7414576 20.1853256,8.02260776 20.1853256,8.2504679 L20.1853256,14.2324473 C20.1853256,14.5135975 19.971192,14.7414576 19.7069787,14.7414576 L13.6636724,14.7414576 C13.3994591,14.7414576 13.1853256,14.4603074 13.1853256,14.2324473 L13.1853256,8.2504679 C13.1853256,7.96931774 13.3994591,7.7414576 13.6636724,7.7414576 Z'
          id='Path'
        />
        <path
          d='M11.4926388,7.97222801 L4.79265514,7.97222801 C4.54849533,7.97222801 4.35093681,8.17865907 4.35093681,8.43376884 C4.35093681,8.68887861 4.54850196,8.89530967 4.79265514,8.89530967 L11.4926388,8.89530967 C11.7367986,8.89530967 11.9343572,8.68887861 11.9343572,8.43376884 C11.9343572,8.17865907 11.736792,7.97222801 11.4926388,7.97222801 Z'
          id='Path'
        />
        <path
          d='M4.79265514,11.4337842 L8.92338414,11.4337842 C9.16754394,11.4337842 9.36510247,11.2273532 9.36510247,10.9722434 C9.36510247,10.7171336 9.16753732,10.5107026 8.92338414,10.5107026 L4.79265514,10.5107026 C4.54849533,10.5107026 4.35093681,10.7171336 4.35093681,10.9722434 C4.35093681,11.2273532 4.54850196,11.4337842 4.79265514,11.4337842 Z'
          id='Path'
        />
        <path
          d='M10.0407107,13.0491771 L4.79265514,13.0491771 C4.54849533,13.0491771 4.35093681,13.2556082 4.35093681,13.510718 C4.35093681,13.7658277 4.54850196,13.9722588 4.79265514,13.9722588 L10.0407107,13.9722588 C10.2840091,13.9722588 10.482429,13.7658277 10.482429,13.510718 C10.482429,13.2556082 10.2840003,13.0491771 10.0407107,13.0491771 Z'
          id='Path'
        />
      </g>
    </g>
  </SvgIcon>
));

export default AccountOpenIcon;
