// @flow
import { PARTITION } from './constants';
import type { ReduxState } from './reducer';

const selectPartition = (state: any): ReduxState => state[PARTITION];
const selectConfiguration = (state: any) => selectPartition(state).configuration;
const selectIsConfigurationSet = (state: any) => selectPartition(state).isConfigurationSet;
const selectInvoiceAttachment = (state: any) => selectConfiguration(state).invoiceAttachment;
const selectTrustDeedAttachment = (state: any) => selectConfiguration(state).trustDeedAttachment;

export default {
  selectPartition,
  selectConfiguration,
  selectIsConfigurationSet,
  selectInvoiceAttachment,
  selectTrustDeedAttachment,
};
