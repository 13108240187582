// @flow
import baseApi from './baseApi';
import startupConfig from '../lib/startupConfig';

const post = (partUrl: string, data: any, apiVersion: ?string = undefined): Promise<any> => {
  const config = startupConfig.get();
  const baseUrl = config ? config.registrationUiUrl : '';
  return baseApi.post({ url: `${baseUrl}/api${partUrl}`, data, apiVersion });
};

const deleteRequest = (partUrl: string): Promise<any> => {
  const config = startupConfig.get();
  const baseUrl = config ? config.registrationUiUrl || '' : '';
  return baseApi.delete({ url: `${baseUrl}/api${partUrl}` });
};

const patch = (partUrl: string, data: any, apiVersion: ?string = undefined): Promise<any> => {
  const config = startupConfig.get();
  const baseUrl = config ? config.registrationUiUrl : '';
  return baseApi.patch({ url: `${baseUrl}/api${partUrl}`, data, apiVersion });
};

const get = (partUrl) => {
  const config = startupConfig.get();
  const baseUrl = config ? config.registrationUiUrl || '' : '';
  return baseApi.get({ url: `${baseUrl}/api${partUrl}` });
};

const uploadTrustDeedFile = (gcAccountId: string, fileGroupId: string, file: any) => {
  const data = new FormData();
  data.append('fileContent', file);

  const url = `/accounts/${gcAccountId}/deed-files/${fileGroupId}`;
  return post(url, data);
};

const updateTrustDeed = (gcAccountId: string, fileGroupId: string, fileNames: Array<string>) => {
  const data = {
    gcAccountId,
    fileGroupId,
    deedFileNames: fileNames,
    portal: 'SP',
  };
  const url = `/accounts/${gcAccountId}/deeds`;
  return patch(url, data);
};

const deleteTrustDeedFiles = (gcAccountId: string, fileGroupId: string) => {
  const url = `/accounts/${gcAccountId}/deed-files/${fileGroupId}`;
  return deleteRequest(url);
};

const deleteTrustDeedFile = (gcAccountId: string, fileGroupId: string, fileName: string) => {
  const url = `/accounts/${gcAccountId}/deed-files/${fileGroupId}/${fileName}`;
  return deleteRequest(url);
};

const searchCompany = (keyword: string) => get(`/companies/${keyword}`);

const searchTrust = (keyword: string) => get(`/trusts/${keyword}`);

const getStartupConfig = (registrationUrl: string) => {
  return baseApi.get({
    url: `${registrationUrl}/api/configuration/startup`,
  });
};

export default {
  uploadTrustDeedFile,
  updateTrustDeed,
  deleteTrustDeedFiles,
  deleteTrustDeedFile,
  getStartupConfig,
  searchCompany,
  searchTrust,
};
