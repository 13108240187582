// @flow
import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';

const useStyles = (labels: { on: string, off: string }) =>
  makeStyles((theme) => ({
    root: {
      height: 20,
      width: 50,
      padding: 0,
    },
    switchBase: {
      color: theme.palette.primary.main,
      padding: '0 !important',
    },
    input: {
      left: 0,
    },
    thumb: {
      backgroundColor: '#fff',
      height: 16,
      width: 16,
      marginTop: 2,
      marginLeft: 2,
    },
    track: {
      background: theme.palette.grey.light,
      opacity: 1,
      borderRadius: 20,
      position: 'relative',
      fontWeight: 'normal !important',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#fff',
        textAlign: 'center',
      },
      '&:before': {
        content: `"${labels.on}"`,
        left: 4,
        opacity: 0,
      },
      '&:after': {
        content: `"${labels.off}"`,
        right: 4,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
    checked: {
      '&$switchBase': {
        color: theme.palette.primary.main,
        transform: 'translateX(32px)',
        '&:hover': {
          backgroundColor: 'rgba(24,90,257,0.08)',
        },
      },
      '& $input': {
        left: '-200% !important',
      },
      '& $thumb': {
        backgroundColor: '#fff',
        marginLeft: '0 !important',
      },
      '& + $track': {
        background: theme.palette.primary.main,
        opacity: '1 !important',
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        },
      },
    },
  }));

type Props = {
  onChange: (e: any, cancelAction: () => void) => void,
  checked: boolean,
  labels?: { on: string, off: string },
};

const StyledSwitch = (props: Props) => {
  const { onChange, checked, labels, ...others } = props;
  const classes = useStyles(labels || { on: 'Yes', off: 'No' })();
  
  const [toggled, setToggled] = useState(checked);
  //the above line only sets "toggled" on component initialisation, not component re-render.
  //this component may re-render, with another "checked" value, in which case we need to updated "toggled"
  //we do this below:  
  useEffect(() => {
      setToggled(checked);
  }, [checked]);
  
  const handleChange = (e: any) => {
    const isChecked = e.target.checked;
    setToggled(isChecked);
    if (onChange) {
      onChange(isChecked, () => setToggled(!isChecked));
    }
  };

  return <Switch classes={classes} checked={toggled} onChange={handleChange} {...(others: any)} />;
};

export default StyledSwitch;
