import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';

import MessagePageLayout from '../components/MessagePageLayout';
import { DEFAULT_ERROR_MESSAGE } from '../constants';
import initialDataSelectors from '../components/InitialData/selectors';

const useStyles = makeStyles((theme) => ({
  statusCodeHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 50,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 80,
    },
    fontWeight: 500,
    lineHeight: 1,
  },
  pageTitle: {
    fontSize: '20px',
    fontWeight: 500,
    padding: theme.spacing(2, 0),
  },
  description: {
    padding: theme.spacing(2, 0, 5, 0),
    textAlign: 'center',
  },
}));

const ErrorPage = () => {
  const classes = useStyles();
  const activeAccount = useSelector(initialDataSelectors.selectActiveAccount);
  const [returnPath, setReturnPath] = useState('/buyer/dashboard/');

  useEffect(() => {
    if (activeAccount && !activeAccount.isBuyer && activeAccount.isSupplier) {
      setReturnPath('/supplier/payments');
    }
  }, [activeAccount]);

  return (
    <MessagePageLayout
      backButtonLabel='Go back to dashboard'
      backButtonRoute={{
        pathname: returnPath,
        state: { title: 'Dashboard' },
      }}
      data-testid='uia-error-page-back-button'
    >
      <Typography variant='h1' color='primary' className={classes.statusCodeHeader}>
        500
      </Typography>
      <Typography variant='h2' className={classes.pageTitle} data-testid='uia-errorpage-error'>
        Error
      </Typography>
      <Typography variant='body1' className={classes.description}>
        {DEFAULT_ERROR_MESSAGE}
      </Typography>
    </MessagePageLayout>
  );
};

export default ErrorPage;
