// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';
// $FlowFixMe
import { batch } from 'react-redux';

import { getActionOptions } from '../../../lib/reduxActionsUtils';
import { handleError } from '../../../lib/apiHelpers';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';
import { useAuth } from '../../../api/authhooks';
import claims from '../../../api/claims';
import initialDataActions from '../../../components/InitialData/actions';
import { ACTION_PREFIX, actionTypes } from './constants';

export const {
  getContactInvitationsPending,
  getContactInvitationsFulfilled,
  getContactInvitationsRejected,
  getFacilityLimitsPending,
  getFacilityLimitsFulfilled,
  getFacilityLimitsRejected,
  getArrearsPaymentDetailsFulfilled,
  getArrearsPaymentDetailsRejected,
  sendContactInvitePending,
  sendContactInviteFulfilled,
  sendContactInviteRejected,
  refreshTokenPending,
  refreshTokenFulfilled,
  refreshTokenRejected,
} = createActions(
  actionTypes.GET_CONTACT_INVITATIONS_PENDING,
  actionTypes.GET_CONTACT_INVITATIONS_FULFILLED,
  actionTypes.GET_CONTACT_INVITATIONS_REJECTED,
  actionTypes.GET_FACILITY_LIMITS_PENDING,
  actionTypes.GET_FACILITY_LIMITS_FULFILLED,
  actionTypes.GET_FACILITY_LIMITS_REJECTED,
  actionTypes.GET_ARREARS_PAYMENT_DETAILS_FULFILLED,
  actionTypes.GET_ARREARS_PAYMENT_DETAILS_REJECTED,
  actionTypes.SEND_CONTACT_INVITE_PENDING,
  actionTypes.SEND_CONTACT_INVITE_FULFILLED,
  actionTypes.SEND_CONTACT_INVITE_REJECTED,
  actionTypes.REFRESH_TOKEN_PENDING,
  actionTypes.REFRESH_TOKEN_FULFILLED,
  actionTypes.REFRESH_TOKEN_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getContactInvitations =
  (gcAccountId: string) =>
  (dispatch: Dispatch<any>): Promise<any> => {
    dispatch(getContactInvitationsPending());

    return shiftPaymentsApi
      .getContactInvitations(gcAccountId)
      .then((response) => {
        dispatch(getContactInvitationsFulfilled(response));
        return response;
      })
      .catch((error) => {
        return dispatch(getContactInvitationsRejected(error));
      });
  };

const getFacilityLimits =
  (gcAccountId: string) =>
  (dispatch: Dispatch<any>): Promise<any> => {
    dispatch(getFacilityLimitsPending());

    return shiftPaymentsApi
      .getFacilityLimits(gcAccountId)
      .then((response) => {
        return dispatch(getFacilityLimitsFulfilled(response.data));
      })
      .catch((error) => {
        return dispatch(getFacilityLimitsRejected(error));
      });
  };

const getArrearsPaymentDetails =
  (gcAccountId: string) =>
  (dispatch: Dispatch<any>): Promise<any> => {
    return shiftPaymentsApi
      .getArrearsPaymentDetails(gcAccountId)
      .then((response) => {
        return dispatch(getArrearsPaymentDetailsFulfilled(response));
      })
      .catch((error) => {
        return dispatch(getArrearsPaymentDetailsRejected(error));
      });
  };

const sendContactInvite =
  (gcAccountId: string, recipientGCContactId: string, mobileNumber: string) =>
  (dispatch: Dispatch<any>): Promise<any> => {
    const channel: string = '0';
    dispatch(sendContactInvitePending());

    return shiftPaymentsApi
      .invite(gcAccountId, recipientGCContactId, mobileNumber, channel)
      .then((response) => {
        dispatch(sendContactInviteFulfilled());
        return response;
      })
      .catch((error) => {
        handleError(error);
        return dispatch(sendContactInviteRejected(error));
      });
  };

const refreshToken =
  () =>
  (dispatch: Dispatch<any>): Promise<any> => {
    const { renew } = useAuth();
    dispatch(refreshTokenPending());

    return renew()
      .then(() => {
        const jwtValues = claims.get();
        return batch(() => {
          dispatch(initialDataActions.updateValues(jwtValues));
          return dispatch(refreshTokenFulfilled());
        });
      })
      .catch((error) => {
        handleError(error);
        return dispatch(refreshTokenRejected(error));
      });
  };

export default {
  getContactInvitations,
  getFacilityLimits,
  getArrearsPaymentDetails,
  sendContactInvite,
  setIsAllKycCompletedWithSessionStorage: initialDataActions.setIsAllKycCompletedWithSessionStorage,
  refreshToken,
};
