// @flow
import * as React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';

import Header from './Header/Component';
import { HeaderNavigation } from './HeaderNavigation';
import { SideNavigation } from './SideNavigation';
import Footer from './Footer';
import { isLargeMobileResolution } from '../lib/materialUiUtils';
import initialDataSelectors from './InitialData/selectors';
import ImpersonationWarningBanner from './ImpersonationWarningBanner';
import claims from '../api/claims';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  appContent: {
    flex: 1,
    background: theme.palette.grey['200'],
  },
  mainContentContainer: {
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
  },
  mainContent: {
    [theme.breakpoints.up('sm')]: {
      margin: `${theme.spacing(1)}px auto`,
      maxWidth: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      margin: `${theme.spacing(1)}px auto`,
      maxWidth: 1200,
      alignContent: 'center',
    },
  },
  header: {
    backgroundColor: theme.palette.common.white,
  },
  footer: {
    padding: theme.spacing(2),
  },
}));

type Props = {
  children: React.Node,
  width: string,
};

const Layout = ({ width, children }: Props) => {
  const classes = useStyles();
  const isMobile = isLargeMobileResolution(width);
  const contactFirstName: string = useSelector(initialDataSelectors.selectFirstName);
  const contactLastName: string = useSelector(initialDataSelectors.selectLastName);
  const jwtValues = claims.get();

  const impersonationMessage = () => {
    return `${jwtValues.impersonator} logged in on behalf of ${contactFirstName} ${contactLastName}`;
  };

  return (
    <Grid className={classes.root}>
      <Grid className={classes.appContent}>
        {isMobile ? (
          <React.Fragment>
            <SideNavigation />
            {jwtValues && jwtValues.impersonator ? <ImpersonationWarningBanner data-testid='uia-impersonationBanner' message={impersonationMessage()} /> : null}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {jwtValues && jwtValues.impersonator ? <ImpersonationWarningBanner data-testid='uia-impersonationBanner' message={impersonationMessage()} /> : null}
            <div className={classes.header}>
              <Header width={width} />
            </div>
            <HeaderNavigation />
          </React.Fragment>
        )}
        <Grid className={classes.mainContentContainer}>
          <main className={classes.mainContent}>{children}</main>
        </Grid>
      </Grid>
      {!isMobile && (
        <div className={classes.footer}>
          <Footer />
        </div>
      )}
    </Grid>
  );
};

export default withWidth()(Layout);
