// @flow
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';

export const renderFromHelper = ({ touched, error }: any) => {
  if (!(touched && error)) {
    return undefined;
  }

  return <FormHelperText error={!!error}>{touched ? error : null}</FormHelperText>;
};

export const renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }: any) => (
  <TextField label={label} placeholder={label} error={touched ? invalid : null} helperText={touched ? error : null} {...input} {...custom} />
);

export const renderCheckbox = ({ input, label, ...rest }: any) => (
  <FormControl>
    <FormControlLabel control={<Checkbox checked={!!input.value} onChange={input.onChange} {...rest} />} label={label} />
  </FormControl>
);

export const radioButton = ({ input, children, meta: { touched, error }, ...rest }: any) => (
  <FormControl>
    <RadioGroup {...input} {...rest}>
      {children}
    </RadioGroup>
    {renderFromHelper({ touched, error })}
  </FormControl>
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const RenderSelectField = ({ input, label, meta: { touched, error }, children, ...custom }: any) => {
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    // $FlowFixMe
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <FormControl variant='outlined' className={classes.formControl} fullWidth>
      <InputLabel ref={inputLabel} htmlFor={`outlined-${input.name}-native`} error={touched ? !!error : null}>
        {label}
      </InputLabel>
      <Select
        native
        {...custom}
        inputProps={input}
        input={<OutlinedInput name={input.name} labelWidth={labelWidth} error={touched ? !!error : null} id={`outlined-${input.name}-native`} />}
      >
        {children}
      </Select>
      {renderFromHelper({ touched, error })}
    </FormControl>
  );
};

export { RenderSelectField };
