/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CheckIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M5,0.079787234 C2.28702128,0.079787234 0.079787234,2.28702128 0.079787234,5 C0.079787234,7.71297872 2.28702128,9.92021277 5,9.92021277 C7.71297872,9.92021277 9.92021277,7.71297872 9.92021277,5 C9.92021277,2.28702128 7.71297872,0.079787234 5,0.079787234 Z M7.72723404,3.99755319 L4.58489362,7.13989362 C4.48925532,7.23553191 4.35670213,7.29042553 4.22148936,7.29042553 L4.22148936,7.50319149 L4.21691489,7.29042553 C4.07989362,7.28925532 3.94680851,7.23202128 3.85170213,7.13340426 L2.26638298,5.49021277 C2.06957447,5.2862766 2.07542553,4.96042553 2.2793617,4.76361702 C2.3756383,4.6706383 2.50234043,4.61957447 2.63606383,4.61957447 C2.77680851,4.61957447 2.90819149,4.67531915 3.00595745,4.77659574 L4.22808511,6.04329787 L7.0006383,3.27085106 C7.09765957,3.17382979 7.22670213,3.12031915 7.36404255,3.12031915 C7.5012766,3.12031915 7.63031915,3.1737234 7.72734043,3.27074468 C7.92755319,3.47117021 7.92755319,3.79723404 7.72723404,3.99755319 Z' />
  </SvgIcon>
);

export default CheckIcon;
