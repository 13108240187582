import { handleActions } from 'redux-actions';
import { PARTITION, actionTypes, ACTION_PREFIX } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';

const initialState = {
  selectedAccountsResponse: null,
  selectedAccountsError: null,
};

const reducer = handleActions(
  {
    [actionTypes.SELECT_ACCOUNTS_FULFILLED]: (state, { payload }) => ({
      ...state,
      selectedAccountsResponse: payload,
    }),
    [actionTypes.SELECT_ACCOUNTS_REJECTED]: (state, { payload }) => ({
      ...state,
      selectedAccountsResponse: payload,
    }),
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
