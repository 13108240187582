// @flow
import { SESSION_STORAGE_CLAIMS } from '../constants';

export type JwtValues = {
  exp: number,
  externalReferenceId: string,
  auth_time: number,
  impersonator: string,
  accounts: string,
};

const get = (): JwtValues => {
  const introspect = window.sessionStorage.getItem(SESSION_STORAGE_CLAIMS);

  return JSON.parse(introspect);
};

const set = (value: any) => {
  window.sessionStorage.setItem(SESSION_STORAGE_CLAIMS, JSON.stringify(value));
};

export default {
  get,
  set,
};
