// @flow
import * as React from 'react';

type Props = {
  children: React.Node,
  tabIndex: number,
  activeTabIndex: number,
};

const TabPanel = ({ children, tabIndex, activeTabIndex }: Props) => {
  if (tabIndex !== activeTabIndex) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default TabPanel;
