// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  theme: any,
};

const FullPageWidgetIcon = withTheme((props: Props) => (
  <SvgIcon style={{ height: '121', width: '116' }} viewBox='0 0 116 121' {...(props: any)}>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Shift-Resources_popup' transform='translate(-358.000000, -692.000000)'>
        <g id='inline' transform='translate(358.000000, 692.000000)'>
          <path
            d='M17.6472083,12.0490128 C17.6472083,17.4392148 9.56177928,17.4392148 9.56177928,12.0490128 C9.56177928,6.65881075 17.6472083,6.65881075 17.6472083,12.0490128'
            id='Path'
            fill='#EEEEEE'
            fillRrule='nonzero'
           />
          <path
            d='M28.6184828,12.0490128 C28.6184828,17.4392148 20.5281436,17.4392148 20.5281436,12.0490128 C20.5281436,6.65881075 28.6184828,6.65881075 28.6184828,12.0490128'
            id='Path'
            fill='#EEEEEE'
            fillRule='nonzero'
           />
          <path
            d='M39.5859803,12.0490128 C39.5859803,17.4392148 31.5005513,17.4392148 31.5005513,12.0490128 C31.5005513,6.65881075 39.5859803,6.65881075 39.5859803,12.0490128'
            id='Path'
            fill='#EEEEEE'
            fillRule='nonzero'
           />
          <polygon
            id='Path'
            fill='#EEEEEE'
            fillRule='nonzero'
            points='85.7965254 10.0823923 103.880117 10.0823923 103.880117 14.0168923 85.7965254 14.0168923'
           />
          <path
            d='M7.89846154,0 C3.54105,0 0,3.54105 0,7.89846154 L0,111.493801 C0,115.846302 3.54105,119.392263 7.89846154,119.392263 L107.514965,119.392263 C111.867467,119.392263 115.413427,115.851213 115.413427,111.493801 L115.413427,58.210959 L115.408508,43.8604211 L115.408508,7.89972058 C115.408508,3.54230904 111.867458,0.00125904 107.514957,0.00125904 L7.89846154,0 Z M111.474017,33.0447638 L111.474017,111.493297 C111.474017,113.676976 109.698518,115.457259 107.510055,115.457259 L7.89858744,115.457259 C5.70999821,115.457259 3.9346259,113.681761 3.9346259,111.498208 L3.9346259,22.6134915 L111.479305,22.6134915 L111.474017,33.0447638 Z M111.474017,18.6791174 L3.9343741,18.6791174 L3.9343741,7.89858744 C3.9343741,5.71490846 5.7098723,3.9346259 7.89833563,3.9346259 L107.514839,3.9346259 C109.698518,3.9346259 111.478801,5.71012411 111.478801,7.89858744 L111.474017,18.6791174 Z'
            id='Shape'
            fill='#EEEEEE'
            fillRule='nonzero'
           />
          <path
            d='M5,22.8882677 L111,22.8882677 L111,111.888268 C111,113.545122 109.656854,114.888268 108,114.888268 L8,114.888268 C6.34314575,114.888268 5,113.545122 5,111.888268 L5,22.8882677 L5,22.8882677 Z'
            id='Rectangle'
            fill='#FFFFFF'
            fillRule='nonzero'
           />
          <path
            d='M18.7889849,33.8882677 L97.7889849,33.8882677 C98.8935544,33.8882677 99.7889849,34.7836982 99.7889849,35.8882677 L99.7889849,102.888268 C99.7889849,103.992837 98.8935544,104.888268 97.7889849,104.888268 L18.7889849,104.888268 C17.6844154,104.888268 16.7889849,103.992837 16.7889849,102.888268 L16.7889849,35.8882677 C16.7889849,34.7836982 17.6844154,33.8882677 18.7889849,33.8882677 Z'
            id='Rectangle'
            stroke='#80DBE0'
            strokeWidth='2'
            fill='#CCF1F3'
            fillRule='nonzero'
           />
          <path
            d='M88.5,75 C89.8807119,75 91,76.1192881 91,77.5 C91,78.8807119 89.8807119,80 88.5,80 L29.5,80 C28.1192881,80 27,78.8807119 27,77.5 C27,76.1192881 28.1192881,75 29.5,75 L88.5,75 Z'
            id='Path-2'
            fill='#B3E9EC'
           />
          <path
            d='M88.5,91 C89.8807119,91 91,92.1192881 91,93.5 C91,94.8807119 89.8807119,96 88.5,96 L29.5,96 C28.1192881,96 27,94.8807119 27,93.5 C27,92.1192881 28.1192881,91 29.5,91 L88.5,91 Z M88.5,83 C89.8807119,83 91,84.1192881 91,85.5 C91,86.8807119 89.8807119,88 88.5,88 L29.5,88 C28.1192881,88 27,86.8807119 27,85.5 C27,84.1192881 28.1192881,83 29.5,83 L88.5,83 Z'
            id='Path-2'
            fill='#B3E9EC'
           />
          <line x1='27.3125' y1='43.425' x2='89.3125' y2='43.425' id='Path' stroke='#B3E9EC' strokeWidth='2.1875' strokeLinecap='round' />
          <rect id='Rectangle' fill='#B3E9EC' x='28' y='56' width='16.4230769' height='14' rx='2' />
          <rect id='Rectangle-Copy' fill='#B3E9EC' x='50.2884615' y='56' width='16.4230769' height='14' rx='2' />
          <rect id='Rectangle-Copy-2' fill='#B3E9EC' x='72.5769231' y='56' width='16.4230769' height='14' rx='2' />
          <line x1='27.3125' y1='48.89375' x2='89.3125' y2='48.89375' id='Path' stroke='#B3E9EC' strokeWidth='2.1875' strokeLinecap='round' />
        </g>
      </g>
    </g>
  </SvgIcon>
));

export default FullPageWidgetIcon;
