// @flow
export const ACTION_PREFIX = 'DDR_AGREEMENT';
export const PARTITION = 'ddrAgreement';

export const actionTypes = {
  SIGN_DIRECT_DEBIT_AGREEMENT_PENDING: 'SIGN_DIRECT_DEBIT_AGREEMENT_PENDING',
  SIGN_DIRECT_DEBIT_AGREEMENT_FULFILLED: 'SIGN_DIRECT_DEBIT_AGREEMENT_FULFILLED',
  SIGN_DIRECT_DEBIT_AGREEMENT_REJECTED: 'SIGN_DIRECT_DEBIT_AGREEMENT_REJECTED',
  GET_DIRECT_DEBIT_AGREEMENT_PENDING: 'GET_DIRECT_DEBIT_AGREEMENT_PENDING',
  GET_DIRECT_DEBIT_AGREEMENT_FULFILLED: 'GET_DIRECT_DEBIT_AGREEMENT_FULFILLED',
  GET_DIRECT_DEBIT_AGREEMENT_REJECTED: 'GET_DIRECT_DEBIT_AGREEMENT_REJECTED',
};
