import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { reduxForm, getFormSubmitErrors, getFormValues, getFormSyncErrors, getFormAsyncErrors } from 'redux-form';
import { bindActionCreators } from 'redux';
import { createSelector } from '@reduxjs/toolkit';
import Component from './Component';
import { FORM_NAME } from './constants';
import formActions from './actions';
import addBankConnectFormActions from '../AddBankConnectForm/actions';
import accountSelectMenuSelectors from '../AccountSelectMenu/selectors';
import initialData from '../InitialData/selectors';
import selectors from './selectors';
import { selectActiveProfileModeBasedPath } from '../../lib/profileHelper';
import { traceInfo, traceError } from '../../lib/telemetryUtils';
import { defaultHandleError } from '../../lib/apiHelpers';
import { LBFCID_FALLBACK } from '../../constants';

const mapStateToProps = createSelector(
  accountSelectMenuSelectors.selectActiveAccountIndex,
  initialData.selectAccounts,
  selectors.selectBankLoginAccountResult,
  getFormSubmitErrors(FORM_NAME),
  getFormValues(FORM_NAME),
  getFormSyncErrors(FORM_NAME),
  getFormAsyncErrors(FORM_NAME),
  (activeAccountIndex, accounts, bankLoginAccountResult, formSubmitErrors, formValues, formSyncErrors, formAsyncErrors) => {
    const selectedAccount = accounts ? accounts[activeAccountIndex] : null;
    return {
      bankLoginAccountResult,
      selectedAccount,
      formSubmitErrors,
      formValues,
      formSyncErrors,
      formAsyncErrors,
    };
  }
);

const actions = {
  ...formActions,
  ...addBankConnectFormActions,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

const displayError = (props, message) => {
  props.enqueueSnackbar(message, {
    variant: 'error',
    persist: true,
  });
};

const Form = reduxForm({
  form: FORM_NAME,
  initialValues: {},
  onSubmit: (values, dispatch, props) => {
    props.closeSnackbar();
    props.loginBankAccountUserMfaPending();
    const requestValues = {
      getcapitalAccountId: props.selectedAccount.gcAccountId,
      bankId: props.match.params.bankId,
      credentials: {
        ...values,
      },
    };
    return props.bankLoginMfa(requestValues, dispatch);
  },
  onSubmitSuccess: (result, dispatch, props) => {
    // added this trace to help us end all the pain and suffering when connecting bank accounts once and for all
    if (result && result.data) {
      traceInfo(`Bank login MFA response - ${JSON.stringify(result.data)}`);
    } else {
      traceError('No Bank login MFA response');
    }

    if (result && result.data && result.data.accounts) {
      props.loginBankAccountUserMfaFulfilled(result);
      props.loginBankLinkUserFulfilled(result);
      props.history.push(
        selectActiveProfileModeBasedPath(
          `/settings/add-bank/account-select/${props.bankLoginAccountResult.bankId}/${result.data.lbfcId ?? LBFCID_FALLBACK}/mfa`
        )
      );
    } else {
      defaultHandleError(props, null);
      props.loginBankAccountUserMfaRejected(result);
    }
  },
  onSubmitFail: (errors, dispatch, submitError, props) => {
    props.loginBankAccountUserMfaRejected(errors);
    if (submitError) {
      if (submitError.response.data) {
        displayError(props, submitError.response.data.detail);
      }
    }
  },
})(Component);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(Form)));
