import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  loaderContainer: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    minHeight: '100vh',
    position: 'fixed',
    zIndex: 6969,
  },
}));

const FetchLoadingIndicator = () => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const showLoading = () => setLoading(true);
    const hideLoading = () => setLoading(false);

    // Add event listeners to show/hide the loading indicator
    window.addEventListener('fetchStart', showLoading);
    window.addEventListener('fetchEnd', hideLoading);

    // remove event listeners
    return () => {
      window.removeEventListener('fetchStart', showLoading);
      window.removeEventListener('fetchEnd', hideLoading);
    };
  }, []);

  return loading ? (
    <Grid container spacing={0} direction='column' alignItems='center' justify='center' className={classes.loaderContainer}>
      <Grid item xs={3}>
        <CircularProgress size={60} />
      </Grid>
    </Grid>
  ) : null;
};

export default FetchLoadingIndicator;
