// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';
// $FlowFixMe
import { batch } from 'react-redux';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import registrationApi from '../../../api/registrationApi';
import { useAuth } from '../../../api/authhooks';
import claims from '../../../api/claims';
import { handleError } from '../../../lib/apiHelpers';
import initialDataActions from '../../../components/InitialData/actions';

const {
  updateTrustDeedsPending,
  updateTrustDeedsFulfilled,
  updateTrustDeedsRejected,
  deleteTrustDeedsPending,
  deleteTrustDeedsFulfilled,
  deleteTrustDeedsRejected,
  fileUploadPending,
  fileUploadFulfilled,
  fileUploadRejected,
  fileDeletePending,
  fileDeleteFulfilled,
  fileDeleteRejected,
  refreshTokenPending,
  refreshTokenFulfilled,
  refreshTokenRejected,
} = createActions(
  actionTypes.UPDATE_TRUST_DEEDS_PENDING,
  actionTypes.UPDATE_TRUST_DEEDS_FULFILLED,
  actionTypes.UPDATE_TRUST_DEEDS_REJECTED,
  actionTypes.DELETE_TRUST_DEEDS_PENDING,
  actionTypes.DELETE_TRUST_DEEDS_FULFILLED,
  actionTypes.DELETE_TRUST_DEEDS_REJECTED,
  actionTypes.FILE_UPLOAD_PENDING,
  actionTypes.FILE_UPLOAD_FULFILLED,
  actionTypes.FILE_UPLOAD_REJECTED,
  actionTypes.FILE_DELETE_PENDING,
  actionTypes.FILE_DELETE_FULFILLED,
  actionTypes.FILE_DELETE_REJECTED,
  actionTypes.REFRESH_TOKEN_PENDING,
  actionTypes.REFRESH_TOKEN_FULFILLED,
  actionTypes.REFRESH_TOKEN_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const uploadTrustDeedFile = (gcAccountId: string, fileGroupId: string, file: any) => (dispatch: Dispatch<any>) => {
  dispatch(fileUploadPending());
  return registrationApi
    .uploadTrustDeedFile(gcAccountId, fileGroupId, file)
    .then((response) => dispatch(fileUploadFulfilled(response)))
    .catch((error) => dispatch(fileUploadRejected(error)));
};

const updateTrustDeeds = (gcAccountId: string, fileGroupId: string, files: Array<any>) => (dispatch: Dispatch<any>) => {
  dispatch(updateTrustDeedsPending());
  const fileNames = files.map((file) => file.name);
  return registrationApi
    .updateTrustDeed(gcAccountId, fileGroupId, fileNames)
    .then((response) => {
      return dispatch(updateTrustDeedsFulfilled(response));
    })
    .catch((error) => {
      return dispatch(updateTrustDeedsRejected(error));
    });
};

const deleteTrustDeedFiles = (gcAccountId: string, fileGroupId: string) => (dispatch: Dispatch<any>) => {
  dispatch(deleteTrustDeedsPending());

  return registrationApi
    .deleteTrustDeedFiles(gcAccountId, fileGroupId)
    .then(() => {
      return dispatch(deleteTrustDeedsFulfilled());
    })
    .catch((error) => {
      return dispatch(deleteTrustDeedsRejected(error));
    });
};

const deleteTrustDeedFile = (gcAccountId: string, fileGroupId: string, file: any) => (dispatch: Dispatch<any>) => {
  dispatch(fileDeletePending());

  return registrationApi
    .deleteTrustDeedFile(gcAccountId, fileGroupId, file.name)
    .then((response) => dispatch(fileDeleteFulfilled(response)))
    .catch((error) => dispatch(fileDeleteRejected(error)));
};

const refreshToken =
  () =>
  (dispatch: Dispatch<any>): Promise<any> => {
    const { renew } = useAuth();
    dispatch(refreshTokenPending());

    return renew()
      .then(() => {
        const jwtValues = claims.get();
        return batch(() => {
          dispatch(initialDataActions.updateValues(jwtValues));
          return dispatch(refreshTokenFulfilled());
        });
      })
      .catch((error) => {
        handleError(error);
        return dispatch(refreshTokenRejected(error));
      });
  };

export default {
  updateTrustDeeds,
  uploadTrustDeedFile,
  deleteTrustDeedFile,
  deleteTrustDeedFiles,
  refreshToken,
};
