// @flow
import * as React from 'react';
import { makeStyles, Fade, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      margin: `${theme.spacing(1)}px auto`,
      maxWidth: 1100,
    },
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 5, 5),
    },
  },
  title: {
    borderLeft: `12px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 3, 0, 1.5),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 5, 0, 3.5),
    },
  },
  subtitle: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 3),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 5),
    },
  },
}));

type Props = {
  title: string,
  subtitle?: string,
  children: React.Node,
  footer?: React.Node,
};

const SetupPageLayout = ({ title, subtitle, children, footer }: Props) => {
  const classes = useStyles();

  return (
    <>
      {title ? <Helmet>
          <title>Shift Trade - {title}</title>
        </Helmet> : null}
      <Fade in timeout={1000}>
        <Paper className={classes.container}>
          <Typography variant='h1' className={classes.title}>
            {title}
          </Typography>
          {subtitle ? <Typography variant='body1' className={classes.subtitle}>
              {subtitle}
            </Typography> : null}
          <div className={classes.content}>{children}</div>
          {footer}
        </Paper>
      </Fade>
    </>
  );
};

export default SetupPageLayout;
