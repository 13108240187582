// @flow
import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';

import ShiftLogo from '../svg/ShiftLogo';
import { AccountSelectMenu } from '../AccountSelectMenu';

const useStyles = makeStyles((theme) => ({
  header: {
    margin: 'auto',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
      padding: theme.spacing(1, 2),
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1200,
      padding: theme.spacing(3, 0),
    },
  },
  logo: {
    margin: '0 auto',
    width: '300px !important',
    display: 'block !important',
  },
  headerposition: {
    margin: '0 auto',
  },
  right: {
    minWidth: 50,
    float: 'right',
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.header}>
      <Grid item sm={6}>
        <ShiftLogo width='auto' height={44} />
      </Grid>
      <Grid item sm={6}>
        <div className={classes.right}>
          <AccountSelectMenu />
        </div>
      </Grid>
    </Grid>
  );
};

export default Header;
