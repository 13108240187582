// @flow
import React, { useState, useEffect } from 'react';
import { Typography, Box, Hidden, Grid, Link, FormControl, Select, MenuItem, Button } from '@material-ui/core';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { makeStyles } from '@material-ui/styles';
import AbnText from '../../../components/AbnText';
import { AvatarIcon } from '../../../components/svg';
import PhoneNumberText from '../../../components/PhoneNumberText';
import { type SupplierTermFee } from '../../ReferenceDataHooks/hooks';
import { formatSupplierTerm } from '../../../lib/formatter';

const useStyles = makeStyles((theme) => ({
  subLabel: {
    fontWeight: 500,
    marginRight: theme.spacing(1),
  },
  spacer: {
    margin: theme.spacing(0, 1),
  },
  subBullet: {
    fontWeight: 500,
    color: theme.palette.common.orange,
    lineHeight: 1.2,
    margin: theme.spacing(0, 2),
  },
  customerDetails: {
    marginTop: theme.spacing(1),
    '& svg': {
      height: 18,
      width: 18,
      marginRight: theme.spacing(0.5),
    },
  },
  cancelButton: {
    marginTop: theme.spacing(0.5),
    width: '100%',
    fontSize: 12,
  },
  formControl: {
    minWidth: '100%',
    '& .MuiSelect-select': {
      height: theme.spacing(1.8),
    },
    marginBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  contentText: {
    marginBottom: theme.spacing(1.5),
  },
  feePlaceholder: {
    width: '75px',
    backgroundColor: theme.palette.common.paleOrange,
    paddingTop: theme.spacing(0.3),
    paddingBottom: theme.spacing(0.3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  feeTitle: {
    fontSize: 12,
    color: theme.palette.secondary.contrastText,
  },
  feeValue: {
    fontSize: 14,
    color: theme.palette.secondary.contrastText,
    fontWeight: '500',
  },
}));

type Props = {
  customerAccountName: string,
  customerAbn: string,
  contactName: string,
  contactMobileNumber: string,
  supplierTerms: SupplierTermFee[],
  defaultTermsInDays: ?number,
  defaultTermPeriod: ?string,
  onAdHocTermSelected: (term: any) => void,
};

const CustomerProfile = (props: Props) => {
  const {
    customerAccountName,
    customerAbn,
    contactName,
    contactMobileNumber,
    supplierTerms,
    defaultTermsInDays,
    defaultTermPeriod,
    onAdHocTermSelected,
  } = props;
  const classes = useStyles();
  const [isTermChangeConfirmationOpen, setIsTermChangeConfirmationOpen] = useState(false);
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState({});
  const [dropDownSelectedTerm, setDropDownSelectedTerm] = useState({});

  const setDefaultTerm = () => {
    let term = supplierTerms.find((t) => t.term === defaultTermsInDays && t.termPeriod === defaultTermPeriod);
    if (term) {
      setSelectedTerm(term);
    }
  };

  useEffect(() => {
    setDefaultTerm();
  }, [supplierTerms, defaultTermsInDays, defaultTermPeriod]);

  useEffect(() => {}, [defaultTermsInDays, defaultTermPeriod, customerAccountName]);

  useEffect(() => {
    onAdHocTermSelected(selectedTerm);
  }, [selectedTerm]);

  const handleShowChangeTermDialog = (e) => {
    e.preventDefault();
    setDropDownSelectedTerm(selectedTerm);
    setIsTermChangeConfirmationOpen(true);
  };

  const handleTermUpdate = () => {
    setSelectedTerm(dropDownSelectedTerm);
    setIsTermChangeConfirmationOpen(false);
  };

  const handleCancelTermUpdate = () => {
    setDropDownSelectedTerm(selectedTerm);
    setIsTermChangeConfirmationOpen(false);
  };

  const handleCloseCancelConfirmation = () => {
    setIsCancelConfirmationOpen(false);
  };

  const handleCancelRequest = () => {
    setIsCancelConfirmationOpen(false);
  };

  const handleTermChangedInDropDown = (e: any) => {
    setDropDownSelectedTerm(e.target.value);
  };

  return (
    <>
      <Typography variant='h5'>{customerAccountName}</Typography>
      <Box display='flex' className={classes.customerDetails}>
        <Hidden smUp>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant='body2' className={classes.subLabel}>
                ABN
              </Typography>
              <Typography variant='body2'>
                <AbnText value={customerAbn} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2' className={classes.subLabel}>
                Terms
              </Typography>
              <Typography variant='body2'>{formatSupplierTerm(selectedTerm.term, selectedTerm.termPeriod)}</Typography>
              <Typography className={classes.spacer} />
              <Link
                variant='body2'
                className={classes.subLabel}
                component='button'
                onClick={handleShowChangeTermDialog}
                data-testid='uia-change-adhoc-term'
              >
                Change my terms
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' className={classes.subLabel} component='p'>
                {contactName}
              </Typography>
              <Typography variant='body2'>
                <PhoneNumberText value={contactMobileNumber} />
              </Typography>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden xsDown>
          <Typography variant='body2' className={classes.subLabel}>
            ABN
          </Typography>
          <Typography variant='body2'>
            <AbnText value={customerAbn} />
          </Typography>
          <Typography className={classes.subBullet}>&bull;</Typography>
          <AvatarIcon />
          <Typography variant='body2' className={classes.subLabel}>
            {contactName}
          </Typography>
          <Typography variant='body2'>
            - <PhoneNumberText value={contactMobileNumber} />
          </Typography>
          <Typography className={classes.subBullet}>&bull;</Typography>
          <Typography variant='body2' className={classes.subLabel}>
            Terms
          </Typography>
          <Typography variant='body2'>{formatSupplierTerm(selectedTerm.term, selectedTerm.termPeriod)}</Typography>
          <Typography className={classes.spacer} />
          <Link
            variant='body2'
            className={classes.subLabel}
            component='button'
            onClick={handleShowChangeTermDialog}
            data-testid='uia-change-adhoc-term'
          >
            {'Change my terms'}
          </Link>
        </Hidden>
      </Box>
      {isCancelConfirmationOpen ? <ConfirmationDialog
          isOpen={isCancelConfirmationOpen}
          onClose={handleCloseCancelConfirmation}
          onConfirm={handleCancelRequest}
          title='Cancel request?'
          contentText={`On cancel, your next term will remain as default. Please click Yes to proceed with an ad-hoc once off term change.`}
          id='cancel-confirmation'
          data-testid='uia-cancelDialog'
        /> : null}
      {isTermChangeConfirmationOpen ? <ConfirmationDialog
          isOpen={isTermChangeConfirmationOpen}
          onClose={handleCancelTermUpdate}
          onConfirm={handleTermUpdate}
          title='Change my terms'
          id='confirmation'
          data-testid='uia-adHocTermDialog'
          showChildren
          contentText=''
          overrideActions
          actions={
            <>
              <Button onClick={handleCancelTermUpdate} color='primary' disabled={false} data-testid='uia-adHocCancelButton'>
                Cancel
              </Button>
              <Button onClick={handleTermUpdate} color='primary' variant='contained' disabled={false} autoFocus data-testid='uia-adHocUpdateButton'>
                Update
              </Button>
            </>
          }
        >
          <Grid container rowSpacing={3} columnSpacing={5}>
            <Typography className={classes.contentText} variant='h5'>
              What terms would you like to offer?
            </Typography>
            <Grid container columnSpacing={5}>
              <Grid item xs={8}>
                <FormControl className={classes.formControl} variant='outlined'>
                  <Select
                    name='selectedTerm'
                    id='selectedTerm'
                    data-testid='uia-adHocSelectedTerm'
                    onChange={handleTermChangedInDropDown}
                    value={dropDownSelectedTerm}
                    disableUnderline
                  >
                    {supplierTerms.map((item) => (
                      <MenuItem key={`${item.term}-${item.termPeriod}`} value={item}>
                        {formatSupplierTerm(item.term, item.termPeriod)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4} direction='column'>
                <Grid className={classes.feePlaceholder}>
                  <Typography className={classes.feeTitle}>FEE</Typography>
                  <Typography className={classes.feeValue}>
                    {dropDownSelectedTerm.feeInPercentage !== 0 ? dropDownSelectedTerm.feeInPercentage : '0.00'}%
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ConfirmationDialog> : null}
    </>
  );
};

export default CustomerProfile;
