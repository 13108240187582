import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, TextField, Tooltip, Typography, Zoom } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Button, FormField, IconButton, RadioGroup, RadioItem, Stack } from '@shiftfinancial/design-system';
import { useFormik } from 'formik';
import moment from 'moment';
import NumericFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import * as Yup from 'yup';

import { LINKS, MAXIMUM_CREDIT_LIMIT, STANDARD_DATE_FORMAT } from '../../constants';

import selectors from '../../pages/Buyer/Dashboard/selectors';

// eslint-disable-next-line import/namespace
import { type FacilityLimits } from '../../pages/Buyer/Dashboard/types';

import DollarsAndCentsText from '../DollarsAndCentsText';

import { InfoOutlinedIcon } from '../svg';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(4),
      fontSize: '18px',
    },
    paddingLeft: theme.spacing(8),
    paddingTop: theme.spacing(5),
    marginTop: theme.spacing(1),
    fontWeight: 500,
    fontSize: theme.spacing(2.5),
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(5),
    },
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  creditLimitContainer: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  creditLimit: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  actionButtons: {
    //@ts-ignore
    background: theme.palette.common.ghostWhite,
    borderTop: '1px solid #E6E8EB',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  preApprovalDetail: {
    fontSize: '14px',
    fontWeight: 500,
  },
  cliOptionsContainer: {
    marginTop: theme.spacing(1),
  },
  finPrintContainer: {
    marginBottom: theme.spacing(17),
  },
  finPrintTitle: {
    marginTop: theme.spacing(4),
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
  },
  finPrintContent: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  links: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  toolTipButton: {
    height: 'auto',
  },
  newLimit: {
    fontSize: '16px',
    fontWeight: 500,
  },
}));

export enum CliOption {
  higher = 'higher',
  exact = 'exact',
}

export type LimitFormValue = {
  newLimitRequest?: number;
  cliOption?: CliOption;
  isPreApproved: boolean;
};

export type LimitFormProps = {
  onClose: () => void | Promise<unknown>;
  onSubmit: (values: LimitFormValue) => void | Promise<unknown>;
  currentLimit: number;
  isRequestAllowed: boolean;
};

export const validationSchema = (props: LimitFormProps) => {
  const { currentLimit } = props;
  return Yup.object({
    newLimitRequest: Yup.number()
      .required('Please enter a new account limit')
      .moreThan(currentLimit, `The requested limit should be higher than your current limit of $${currentLimit}`)
      .max(MAXIMUM_CREDIT_LIMIT, `The max limit is $${MAXIMUM_CREDIT_LIMIT}`),
  });
};

export function LimitIncreaseDialog(props: LimitFormProps) {
  const classes = useStyles();
  const { onClose, onSubmit = () => {}, currentLimit, isRequestAllowed } = props;
  const facilityLimits: FacilityLimits | null = useSelector(selectors.selectFacilityLimits);
  const isPreApproval = (facilityLimits?.approvedLimit ?? 0) > currentLimit;

  const formik = useFormik<LimitFormValue>({
    validationSchema: validationSchema(props),
    initialValues: {
      newLimitRequest: isPreApproval ? facilityLimits?.approvedLimit ?? 0 : undefined,
      isPreApproved: isPreApproval,
    },
    onSubmit,
  });

  const isValidLimitIncrease = isRequestAllowed && formik.isValid;
  const isValidPreApproval = isPreApproval && formik.values.newLimitRequest;
  const isValidWithoutPreApproval = !isPreApproval && formik.values.cliOption;
  const isValidToSubmit = isValidLimitIncrease && (isValidPreApproval || isValidWithoutPreApproval);

  return (
    <Dialog open={true} TransitionComponent={Zoom} maxWidth={isPreApproval ? 'md' : 'md'} fullWidth={!isPreApproval}>
      <DialogTitle className={classes.title} disableTypography>
        Request a new credit limit
        <IconButton aria-label='close' data-testid='ui-close-button' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <form id='new-limit' name='new-limit' noValidate onSubmit={formik.handleSubmit}>
          <Grid>
            <Grid item>
              {isPreApproval ? (
                <Grid item className={classes.creditLimitContainer}>
                  <Typography data-testid='ui-pre-approval-detail'>
                    Your account is pre-approved for a credit limit of{' '}
                    <span className={classes.preApprovalDetail}>
                      <NumericFormat thousandSeparator displayType='text' prefix='$' decimalScale={0} value={facilityLimits?.approvedLimit ?? 0} />{' '}
                    </span>{' '}
                    until{' '}
                    <span className={classes.preApprovalDetail}>{moment(facilityLimits?.approvedLimitExpiryDate).format(STANDARD_DATE_FORMAT)}</span>
                  </Typography>
                </Grid>
              ) : null}
              <Grid item className={classes.creditLimitContainer}>
                <Typography>Current limit</Typography>

                <Typography className={classes.creditLimit}>
                  <DollarsAndCentsText amount={currentLimit} />
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction='column'>
                <Grid item>
                  <FormField label='New limit' fieldId='newAccountLimit' error={formik.errors.newLimitRequest}>
                    {({ fieldId, invalid, errorId }) => (
                      <NumericFormat
                        className={classes.newLimit}
                        displayType='input'
                        customInput={TextField}
                        variant='outlined'
                        id={fieldId}
                        name={fieldId}
                        data-testid='ui-cli-new-limit'
                        decimalScale={2}
                        thousandSeparator
                        fullWidth={false}
                        error={invalid}
                        inputProps={{
                          'aria-errormessage': invalid ? errorId : undefined,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <span className={classes.newLimit}>$</span>
                            </InputAdornment>
                          ),
                        }}
                        value={formik.values.newLimitRequest}
                        onBlur={formik.handleBlur}
                        onValueChange={(v) => formik.setFieldValue('newLimitRequest', v.floatValue)}
                      />
                    )}
                  </FormField>
                </Grid>
              </Grid>
            </Grid>
            {!!isValidLimitIncrease && !!formik.dirty && !isPreApproval && (
              <Grid item className={classes.cliOptionsContainer}>
                <Stack>
                  <RadioGroup name='cli' onValueChange={(value: string) => formik.setFieldValue('cliOption', value)}>
                    <Stack orientation='horizontal' alignItems='normal'>
                      <RadioItem id='cli-higher' value={CliOption.higher} data-testid='ui-cli-option-high'>
                        I will accept a higher limit
                        <Tooltip
                          placement='top-start'
                          title='You could be eligible for a higher limit based on Shift’s credit policy and we’ll assess you for the maximum amount.'
                        >
                          <span>
                            <Button className={classes.toolTipButton} disabled>
                              <InfoOutlinedIcon />
                            </Button>
                          </span>
                        </Tooltip>
                      </RadioItem>
                    </Stack>
                    <Stack orientation='horizontal' alignItems='normal'>
                      <RadioItem id='cli-exact' value={CliOption.exact} data-testid='ui-cli-option-exact'>
                        I require the exact limit
                        <Tooltip placement='top-start' title='You will be assessed up to the requested amount.'>
                          <span>
                            <Button className={classes.toolTipButton} disabled>
                              <InfoOutlinedIcon />
                            </Button>
                          </span>
                        </Tooltip>
                      </RadioItem>
                    </Stack>
                  </RadioGroup>
                </Stack>
                <Grid item>
                  <Stack alignItems='start' orientation='vertical' className={classes.finPrintContainer}>
                    <Typography className={classes.finPrintTitle}>The fine print</Typography>
                    <Typography className={classes.finPrintContent}>
                      By submitting, I acknowledge that I have read and agree to{' '}
                      <a href={LINKS.TERMS_AND_CONDITIONS} target='_blank' rel='noopener noreferrer' className={classes.links}>
                        Shift&apos;s Terms & Conditions.
                      </a>
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <Button appearance='primary' type='submit' disabled={!isValidToSubmit} form='new-limit'>
          Submit
        </Button>
        <Button appearance='neutral' data-testid='ui-cli-close' onClick={() => onClose()}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
