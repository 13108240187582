// @flow
import React from 'react';
import { isArray } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { Field, FieldArray, getIn } from 'formik';

import { FileUploadContext } from '../DndFileUpload';
import FileList from '../FileList';
import FormikDndFileUpload from './FormikDndFileUpload';

type Props = {
  name: string,
  values: any,
  errors: Object,
  touched: Object,
  fileGroupId: string,
  gcAccountId: string,
  onFileUpload: (gcAccountId: string, fileGroupId: string, file: any) => Promise<any>,
  onFileDelete: (gcAccountId: string, fileGroupId: string, file: any) => Promise<any>,
  maxFilesCount: number,
  maxFileSizeInMb: number,
  supportedFileTypesText?: string,
};

const FormikDndAttachFiles = (props: Props) => {
  const {
    name,
    values,
    errors,
    touched,
    fileGroupId,
    gcAccountId,
    onFileUpload,
    onFileDelete,
    maxFilesCount,
    maxFileSizeInMb,
    supportedFileTypesText,
  } = props;

  const handleDelete = (arrayHelpers: any, index: number) => {
    const file = arrayHelpers.remove(index);
    onFileDelete(gcAccountId, fileGroupId, file);
  };

  const canUpload = (file) => {
    const index = values[name].findIndex((x) => x.name === file.name);
    return index < 0;
  };

  const files = values[name].map((file, index) => {
    const fileErrors = isArray(errors[name]) && getIn(touched, name) && getIn(errors, name);
    const error = fileErrors && fileErrors.length > index ? fileErrors[index] : null;

    return {
      type: file.type,
      name: file.name,
      size: file.size,
      error: error || file.error,
      uploaded: file.uploaded,
      timestamp: file.timestamp,
      documentFileId: file.documentFileId,
    };
  });

  const contextValue = {
    maxFilesCount,
    maxFileSizeInMb,
    onFileUpload,
    fileGroupId,
    gcAccountId,
    canUpload,
    fileCount: values[name].length,
    supportedFileTypesText,
  };

  return (
    <FileUploadContext.Provider value={contextValue}>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <React.Fragment>
            <Grid item xs={12}>
              <Field name={name} component={FormikDndFileUpload} variant='outlined' color='secondary' data-testid='uia-fileUpload' />
            </Grid>
            {values[name] && values[name].length > 0 ? <FileList files={files} onDelete={(index) => handleDelete(arrayHelpers, index)} /> : null}
          </React.Fragment>
        )}
      />
    </FileUploadContext.Provider>
  );
};

export default FormikDndAttachFiles;
