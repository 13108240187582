// @flow
import * as React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tileActive: {
    height: theme.spacing(35),
    width: '90%',
    border: `1px solid ${theme.palette.common.bright}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(0.5),
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.warning.background,
      border: `1px solid ${theme.palette.warning.main}`,
    },
  },
  tileDisabled: {
    height: theme.spacing(35),
    width: '90%',
    border: `.5px solid ${theme.palette.common.bright}`,
    backgroundColor: theme.palette.common.ghostWhite,
    borderRadius: theme.spacing(1),
  },
  tilePosition: {
    padding: theme.spacing(5),
  },
  iconActiveBox: {
    height: theme.spacing(5.4),
    width: theme.spacing(5.6),
    backgroundColor: theme.palette.grey.background,
    marginTop: theme.spacing(1.9),
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
  },
  iconDisabledBox: {
    height: theme.spacing(5.4),
    width: theme.spacing(5.6),
    backgroundColor: theme.palette.grey.background,
    marginBottom: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
    wordWrap: 'break-word',
  },
  comingSoon: {
    color: theme.palette.primary.contrastText,
    fontSize: '10px',
    textAlign: 'end',
    padding: '1.3px 8px',
    backgroundColor: theme.palette.success.main,
    borderRadius: '0px 8px 0px 0px',
  },
  comingSoonBox: {
    textAlign: 'end',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

type WidgetTileProps = {
  heading: string,
  content: string,
  isActive: boolean,
  icon: SvgIcon,
  redirectTo: string,
  id: string,
  onMouseEnter?: (e: any) => void,
  onMouseLeave?: (e: any) => void,
  openNewTab?: boolean,
};

const WidgetTile = ({ id, heading, content, icon, isActive, redirectTo, onMouseEnter, onMouseLeave, openNewTab }: WidgetTileProps) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    if (isActive) {
      if (openNewTab) window.open(redirectTo, '_blank');
      else history.push(redirectTo);
    }
  };
  return (
    <Box
      data-testid={id}
      onClick={handleClick}
      className={isActive ? classes.tileActive : classes.tileDisabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {!isActive && (
        <Grid item className={classes.comingSoonBox}>
          <Typography variant='body1' className={classes.comingSoon} data-testid='uia-widget-tile-coming-soon'>
            COMING SOON
          </Typography>
        </Grid>
      )}
      <Box className={classes.tilePosition}>
        <Grid item>
          <Box className={isActive ? classes.iconActiveBox : classes.iconDisabledBox} data-testid='uia-widget-tile-icon-box'>
            {icon}
          </Box>
        </Grid>
        <Grid item>
          <Typography variant='h3' component='h3'>
            {heading}
          </Typography>
        </Grid>
        <Grid item className={classes.content}>
          <Typography variant='body1' component='label'>
            {content}
          </Typography>
        </Grid>
      </Box>
    </Box>
  );
};

export default WidgetTile;
