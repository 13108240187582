import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Login from './Component';
import actions from './actions';
import selectors from './selectors';
import startupConfigSelectors from '../../components/StartupConfig/selectors';

const mapStateToProps = createSelector(
  selectors.selectStep,
  selectors.selectMobileNumber,
  selectors.selectInputMobileNumberValue,
  selectors.selectCodeExpiry,
  startupConfigSelectors.selectConfiguration,
  (step, mobileNumber, inputMobileNumberValue, codeExpiry, configuration) => ({
    step,
    mobileNumber,
    inputMobileNumberValue,
    codeExpiry,
    configuration,
  })
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
