// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';
import { updatePrivacyAgreementSetupCompleted } from '../../components/AccountSetupCheck/actions';

export const { signPrivacyAgreementFulfilled, signPrivacyAgreementPending, signPrivacyAgreementRejected } = createActions(
  actionTypes.SIGN_PRIVACY_AGREEMENT_FULFILLED,
  actionTypes.SIGN_PRIVACY_AGREEMENT_PENDING,
  actionTypes.SIGN_PRIVACY_AGREEMENT_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const signPrivacyAgreement = (gcAccountId: string, agreementVersion: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(signPrivacyAgreementPending());

    return shiftPaymentsApi
      .signPrivacyAgreement(gcAccountId, agreementVersion)
      .then((response) => {
        return dispatch(signPrivacyAgreementFulfilled(response.data));
      })
      .catch((error) => {
        return dispatch(signPrivacyAgreementRejected(error));
      });
  };
};

export default {
  signPrivacyAgreement,
  updatePrivacyAgreementSetupCompleted,
};
