import * as Yup from 'yup';
import { BuyerRequestType } from '../../../types';

const phoneRegExp = /^4[0-9]{8}|^04[0-9]{8}/;

const isNameNotHere = (requestType) => requestType === BuyerRequestType.NewAccountNameNotHere || requestType === BuyerRequestType.NameNotHere;

export const formSchema = Yup.object().shape({
  selectedCustomer: Yup.mixed()
    .test('selectedCustomer', (value) => !!value)
    .required('Please select a customer'),
  contactId: Yup.string().required('Please select a contact'),
  contactFirstName: Yup.mixed().when('requestType', {
    is: (requestType) => isNameNotHere(requestType),
    then: Yup.string().required('Please enter a first name').max(40, 'Please enter no more than 40 characters'),
  }),
  contactLastName: Yup.mixed().when('requestType', {
    is: (requestType) => isNameNotHere(requestType),
    then: Yup.string().required('Please enter a last name').max(80, 'Please enter no more than 80 characters'),
  }),
  mobileNumber: Yup.mixed().when('loggedInMobileNumber', (loggedInMobileNumber) => {
    return Yup.string()
      .required('Please enter a mobile number')
      .matches(phoneRegExp, 'Please enter a valid mobile number')
      .test('mobileNumberDuplicate', 'mobile number cannot be same as logged in number', (value) => {
        return `0${value}` !== loggedInMobileNumber;
      });
  }),
  emailAddress: Yup.string().email('Please enter a valid email').required('Please enter an email'),
  avgMonthlyOrderValue: Yup.mixed()
    .required('Please enter the average monthly order value from this customer')
    .test('minimumAvgMonthlyOrderValue', 'Please enter a valid average monthly order value from this customer', (value) => Number(value) >= 0),
  term: Yup.mixed().test('validateTerm', 'Please select a term', (value) => !!value),
});
