export const PARTITION = 'addBankConnectForm';
export const FORM_NAME = 'AddBankConnectForm';
export const ACTION_PREFIX = 'ADD-BANK-CONNECT-FORM';

export const actionTypes = {
  LOGIN_BANK_LINK_USER_PENDING: 'LOGIN_BANK_LINK_USER_PENDING',
  LOGIN_BANK_LINK_USER_FULFILLED: 'LOGIN_BANK_LINK_USER_FULFILLED',
  LOGIN_BANK_LINK_USER_REJECTED: 'LOGIN_BANK_LINK_USER_REJECTED',
  GET_BANK_LOGIN_FIELDS_PENDING: 'GET_BANK_LOGIN_FIELDS_PENDING',
  GET_BANK_LOGIN_FIELDS_FULFILLED: 'GET_BANK_LOGIN_FIELDS_FULFILLED',
  GET_BANK_LOGIN_FIELDS_REJECTED: 'GET_BANK_LOGIN_FIELDS_REJECTED',
  RESET_BANK_LOGIN: 'RESET_BANK_LOGIN',
};
