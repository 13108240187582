import { createActions } from 'redux-actions';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';

export const {
  getSettingsPending,
  getSettingsFulfilled,
  getSettingsRejected,
  setRepaymentDayPending,
  setRepaymentDayFulfilled,
  setRepaymentDayRejected,
  setSmsReminderPending,
  setSmsReminderFulfilled,
  setSmsReminderRejected,
  requestAccountLimitIncreasePending,
  requestAccountLimitIncreaseFulfilled,
  requestAccountLimitIncreaseRejected,
  getCreditLimitPendingRequestPending,
  getCreditLimitPendingRequestFulfilled,
  getCreditLimitPendingRequestRejected,
  setHasNewCreditLimitPendingRequest,
} = createActions(
  actionTypes.GET_SETTINGS_PENDING,
  actionTypes.GET_SETTINGS_FULFILLED,
  actionTypes.GET_SETTINGS_REJECTED,
  actionTypes.SET_REPAYMENT_DAY_PENDING,
  actionTypes.SET_REPAYMENT_DAY_FULFILLED,
  actionTypes.SET_REPAYMENT_DAY_REJECTED,
  actionTypes.SET_SMS_REMINDER_PENDING,
  actionTypes.SET_SMS_REMINDER_REJECTED,
  actionTypes.SET_SMS_REMINDER_FULFILLED,
  actionTypes.REQUEST_ACCOUNT_LIMIT_INCREASE_PENDING,
  actionTypes.REQUEST_ACCOUNT_LIMIT_INCREASE_FULFILLED,
  actionTypes.REQUEST_ACCOUNT_LIMIT_INCREASE_REJECTED,
  actionTypes.GET_CREDIT_LIMIT_PENDING_REQUEST_PENDING,
  actionTypes.GET_CREDIT_LIMIT_PENDING_REQUEST_FULFILLED,
  actionTypes.GET_CREDIT_LIMIT_PENDING_REQUEST_REJECTED,
  actionTypes.SET_HAS_NEW_CREDIT_LIMIT_PENDING_REQUEST,
  getActionOptions(ACTION_PREFIX)
);

const getSettings = (gcAccountId, isPolling) => (dispatch) => {
  dispatch(getSettingsPending());

  return shiftPaymentsApi
    .getMySettings(gcAccountId, isPolling)
    .then((response) => {
      return dispatch(getSettingsFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getSettingsRejected(error));
    });
};

const setRepaymentDay = (gcAccountId, day, shouldRemind) => (dispatch) => {
  dispatch(setRepaymentDayPending());

  return shiftPaymentsApi
    .setRepaymentDay(gcAccountId, day, shouldRemind)
    .then(() => {
      return dispatch(setRepaymentDayFulfilled(day));
    })
    .catch((error) => {
      return dispatch(setRepaymentDayRejected(error));
    });
};

const setSmsReminder = (gcAccountId, shouldRemind) => (dispatch) => {
  dispatch(setSmsReminderPending());

  return shiftPaymentsApi
    .setSmsReminder(gcAccountId, shouldRemind)
    .then(() => {
      return dispatch(setSmsReminderFulfilled(shouldRemind));
    })
    .catch((error) => {
      return dispatch(setSmsReminderRejected(error));
    });
};

const requestAccountLimitIncrease = (gcAccountId, limit) => (dispatch) => {
  dispatch(requestAccountLimitIncreasePending());

  return shiftPaymentsApi
    .requestAccountLimitIncrease(gcAccountId, limit)
    .then((response) => {
      return dispatch(requestAccountLimitIncreaseFulfilled(response));
    })
    .catch((error) => {
      return dispatch(requestAccountLimitIncreaseRejected(error));
    });
};

const getCreditLimitPendingRequest = (gcAccountId, isPolling) => (dispatch) => {
  dispatch(getCreditLimitPendingRequestPending());
  return shiftPaymentsApi
    .getCreditLimitPendingRequest(gcAccountId, isPolling)
    .then((response) => {
      let hasPendingRequest = false;
      if (response && response.data && response.data.isPending) {
        hasPendingRequest = true;
      }
      dispatch(setHasNewCreditLimitPendingRequest(hasPendingRequest));
      return dispatch(getCreditLimitPendingRequestFulfilled(response.data));
    })
    .catch((error) => {
      return dispatch(getCreditLimitPendingRequestRejected(error));
    });
};

export default {
  getSettings,
  setRepaymentDay,
  setSmsReminder,
  requestAccountLimitIncrease,
  getCreditLimitPendingRequest,
  setHasNewCreditLimitPendingRequest,
};
