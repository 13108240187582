// @flow
import React from 'react';
import { Box, Typography, makeStyles, Hidden } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.warning.deepRed,
      color: theme.palette.common.white,
      fontWeight: 400,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height: theme.spacing(3),
      position: 'fixed',
      left: 0,
      right: 0,
    },
  };
});

type Props = {
  message: string,
};

const ImpersonationWarningBanner = ({ message }: Props) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography variant='h6' className={classes.root}>
        {message}
      </Typography>
      <Hidden smDown>&nbsp;</Hidden>
    </Box>
  );
};

export default ImpersonationWarningBanner;
