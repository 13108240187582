// @flow
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

const ErrorIcon = withTheme(() => (
  <SvgIcon viewBox='0 0 16 16' style={{ alignSelf: 'center', height: 80, width: 80 }}>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-622.000000, -191.000000)' fillRule='nonzero'>
        <g transform='translate(622.000000, 191.000000)'>
          <path
            d='M16,8 C16,12.4183 12.4182,16 8,16 C3.58179,16 0,12.4183 0,8 C0,3.58179 3.58179,0 8,0 C12.4182,0 16,3.58179 16,8 Z'
            fill='#D34343'
          />
          <path
            d='M8.12500562,10.875 C8.74634002,10.875 9.25,11.3786625 9.25,12 C9.25,12.6213375 8.74634002,13.125 8.12500562,13.125 C7.50367123,13.125 7,12.6213375 7,12 C7,11.3786625 7.50367123,10.875 8.12500562,10.875 Z M8.12500562,3 C8.74634002,3 9.25,3.50368668 9.25,4.12500375 L9.25,8.62501875 C9.25,9.24633582 8.74634002,9.75 8.12500562,9.75 C7.50367123,9.75 7,9.24633582 7,8.62501875 L7,4.12500375 C7,3.50368668 7.50367123,3 8.12500562,3 Z'
            fill='#FFFFFF'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
));

export default ErrorIcon;
