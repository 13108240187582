import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import InitialData from './Component';
import actions from './actions';
import selectors from './selectors';
import initialDataSelectors from '../InitialData/selectors';
import accountSetupCheckSelectors from '../AccountSetupCheck/selectors';

const mapStateToProps = createSelector(
  initialDataSelectors.selectAccounts,
  initialDataSelectors.selectFirstName,
  initialDataSelectors.selectLastName,
  initialDataSelectors.selectIsAllKycCompleted,
  accountSetupCheckSelectors.selectIsAllSetupCompleteRequirement,
  selectors.selectActiveAccountIndex,
  (accounts, firstName, lastName, isAllKycCompleted, isAllSetupCompleteRequirement, activeAccountIndex) => ({
    accounts,
    firstName,
    lastName,
    isAllSetupCompleteRequirement: isAllKycCompleted && isAllSetupCompleteRequirement,
    activeAccountIndex,
  })
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InitialData);
