// @flow
import { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import withWidth from '@material-ui/core/withWidth';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import InvoiceDetail, { type SetSupplierAuthorization } from './InvoiceDetail';
import BasicPageLayout from '../../../components/BasicPageLayout';
import type { Invoice, FeesTermsAndPaymentTypes } from './types';
import { defaultHandleError, getErrorMessage } from '../../../lib/apiHelpers';
import { calculateTransactionFee } from './calculateTransactionFee';
import responseCode from '../../../api/generated/responseCode';
import { isMobileResolution } from '../../../lib/materialUiUtils';
import { type PaymentMethod, PaymentMethodValues, type AuthorizationStatus } from '../../../api/shiftPaymentsApi';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { DEFAULT_ERROR_MESSAGE, INVOICE_MISSING_ATTACHMENT_ERROR_MESSAGE, INVOICE_REQUIRE_RETRY_ERROR_MESSAGE } from '../../../constants';
import { traceInfo } from '../../../lib/telemetryUtils';
import { PaymentMethodRadioGroup } from '../../../components/PaymentMethodRadioGroup';

const { v4: uuidv4 } = require('uuid');

type Props = {
  invoice: Invoice,
  feesTermsAndPaymentTypes: FeesTermsAndPaymentTypes,
  gcAccountId: string,
  getInvoice: (contractId: string, gcAccountId: string) => Promise<any>,
  getTermsFeesAndPaymentTypes: (supplierGcAccountId: string, buyerGcAccountId: string, amount: number) => Promise<any>,
  declineInvoice: (contractId: string, gcAccountId: string) => Promise<any>,
  confirmInvoice: (
    contractId: string,
    gcAccountId: string,
    paymentMethod: PaymentMethod,
    selectedDate: ?Date,
    selectedTerm: ?number,
    requestId: string
  ) => Promise<any>,
  addInvoice: (
    buyerGcAccountId: string,
    invoiceNumber: string,
    amount: number,
    invoiceDescription: string,
    fileGroupId: string,
    attachments: Array<any>,
    paymentMethod: PaymentMethod,
    paymentDate: ?Date,
    installmentTermInWeeks: ?number,
    isNewSupplier: boolean,
    supplierGcAccountId?: string,
    supplierAbn: string,
    supplierBsb: string,
    supplierBankAccountNumber: string,
    isDirector: boolean,
    invoiceConfirmRequestId: string
  ) => Promise<any>,
  editSupplierAndAddInvoice: (
    buyerGcAccountId: string,
    invoiceNumber: string,
    amount: number,
    invoiceDescription: string,
    fileGroupId: string,
    attachments: Array<any>,
    paymentMethod: PaymentMethod,
    paymentDate: ?Date,
    installmentTermInWeeks: ?number,
    isNewSupplier: boolean,
    supplierGcAccountId: string,
    supplierAbn: string,
    supplierBsb: string,
    supplierBankAccountNumber: string,
    isDirector: boolean,
    invoiceConfirmRequestId: string
  ) => Promise<any>,
  transactionFee: number,
  installmentTermInWeeks: number,
  paymentDate: ?moment,
  setTransactionFee: (fee: number) => void,
  setInstallmentTermInWeeks: (term: number) => void,
  enqueueSnackbar: (message: string, options?: Object) => void,
  closeSnackbar: () => void,
  getInvoiceAttachment: (buyerGcAccountId: string, filePath: string) => Promise<any>,
  width: string,
  setSupplierAuthorization: SetSupplierAuthorization,
  resetInvoice: () => void,
  getStaffPageVisibility: (gcAccountId: string) => Promise<any>,
  allowJoinBrokerNetwork: (isAllowed: boolean) => void,
  isAllowedJoinMemberNetwork: Boolean,
};

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(4),
  },
  secondaryHeading: {
    color: theme.palette.grey.text,
    fontWeight: 400,
    lineHeight: 1.4,
  },
  payMode: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  expansionSummary: {
    '&.MuiExpansionPanelSummary-root': {
      [theme.breakpoints.up('sm')]: {
        padding: 0,
      },
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0),
      '& .MuiExpansionPanelSummary-content': {
        margin: 0,
      },
    },
  },
  expansionDetails: {
    margin: 0,
    padding: theme.spacing(0, 3, 3, 0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2, 2, 2),
    },
  },
  selectPanelSelected: theme.palette.selectPanel.selected,
  selectPanelDefault: theme.palette.selectPanel.default,
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
  goBack: {
    marginRight: theme.spacing(2),
  },
  radio: {
    textAlign: 'center',
  },
  primaryButtonContainer: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  buttons: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  validationError: {
    marginTop: theme.spacing(2),
  },
}));

const RedirectSourceState = {
  Invalid: 0,
  Initial: 1,
  Valid: 2,
};

const Review = (props: Props) => {
  const classes = useStyles();
  const { id } = useParams();
  const isBuyerInitiated = !id;
  const isSupplierInitiated = !isBuyerInitiated;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newAuthorizationStatus, setNewAuthorizationStatus] = useState(null);
  const [invoiceConfirmRequestId] = useState(uuidv4());

  const {
    gcAccountId,
    invoice,
    feesTermsAndPaymentTypes,
    getInvoice,
    getTermsFeesAndPaymentTypes,
    confirmInvoice,
    declineInvoice,
    addInvoice,
    editSupplierAndAddInvoice,
    installmentTermInWeeks,
    setInstallmentTermInWeeks,
    paymentDate,
    closeSnackbar,
    enqueueSnackbar,
    width,
    setSupplierAuthorization,
    resetInvoice,
    getStaffPageVisibility,
    allowJoinBrokerNetwork,
    isAllowedJoinMemberNetwork,
  } = props;

  const paymentTypes = feesTermsAndPaymentTypes.paymentTypes;
  const [paymentMethod, setPaymentMethod] = useState<?PaymentMethod>(
    paymentTypes && paymentTypes.includes(PaymentMethodValues.Full) && isSupplierInitiated ? PaymentMethodValues.Full : null
  );

  const [supplierDueDate, setSupplierDueDate] = useState<moment | null>(null);
  const [transactionFee, setTransactionFee] = useState(0);
  const [previousPath, setPreviousPath] = useState(null);
  const [validationError, setValidationError] = useState(null);

  const history = useHistory();
  const { validRedirectFromBuyer } = history.location.state || {};

  useEffect(() => {
    setPreviousPath(history.location.state && history.location.state.from ? history.location.state.from : null);
  }, []);

  useEffect(() => {
    if (paymentTypes && paymentTypes.length === 2) {
      setPaymentMethod(isSupplierInitiated ? PaymentMethodValues.Full : null);
    } else if (paymentTypes && paymentTypes.length === 1) {
      setPaymentMethod(paymentTypes.includes(PaymentMethodValues.Full) ? PaymentMethodValues.Full : PaymentMethodValues.Weekly);
    }
  }, [paymentTypes]);

  useEffect(() => {
    setSupplierDueDate(isSupplierInitiated ? moment(invoice.nextInstallmentDate) : null);
  }, [isSupplierInitiated, invoice]);

  useEffect(() => {
    //do not calculate transaction fee when paymentDate falls before or on supplierDueDate
    const isNoTransactionFee =
      // eslint-disable-next-line eqeqeq
      paymentDate && supplierDueDate && paymentMethod != PaymentMethodValues.Weekly && paymentDate.diff(supplierDueDate, 'days') <= 0;
    if (isNoTransactionFee) {
      setTransactionFee(0);
    } else {
      setTransactionFee(
        calculateTransactionFee(
          // $FlowFixMe
          paymentMethod,
          feesTermsAndPaymentTypes,
          isSupplierInitiated,
          supplierDueDate ? moment(supplierDueDate.toDate()) : null,
          invoice.created,
          paymentDate,
          installmentTermInWeeks
        )
      );
    }
  }, [invoice, paymentMethod, feesTermsAndPaymentTypes, isSupplierInitiated, paymentDate, installmentTermInWeeks]);

  useEffect(() => {
    setInstallmentTermInWeeks(-1);
  }, [id, invoice, feesTermsAndPaymentTypes]);

  const handleSelectionChanged = (value) => {
    setPaymentMethod(value);
    setValidationError(null);
  };

  useEffect(() => {
    if (id && gcAccountId) {
      getInvoice(id, gcAccountId).then((response) => {
        if (response.error) {
          defaultHandleError({ enqueueSnackbar });
          history.push('/buyer/payments');
        }
      });

      return () => resetInvoice();
    }

    return undefined;
  }, [id, gcAccountId]);

  useEffect(() => {
    if (gcAccountId && invoice.amount) {
      getTermsFeesAndPaymentTypes(invoice.supplierGcAccountId, gcAccountId, invoice.amount);
    }
  }, [gcAccountId, invoice]);

  const [redirectSourceValidity, setRedirectSourceValidity] = useState(RedirectSourceState.Initial);

  useEffect(() => {
    if (validRedirectFromBuyer && redirectSourceValidity === RedirectSourceState.Initial) {
      history.replace({
        pathname: history.location.pathname,
        state: undefined,
      });
      setRedirectSourceValidity(RedirectSourceState.Valid);
      window.onbeforeunload = () => true;
    } else {
      setRedirectSourceValidity(isSupplierInitiated ? RedirectSourceState.Valid : RedirectSourceState.Invalid);
    }
    return () => {
      window.onbeforeunload = undefined;
    };
  }, []);

  const isMobile = isMobileResolution(width);

  const [declineConfirmationIsOpen, setDeclineConfirmationIsOpen] = useState(false);

  const openDeclineConfirmation = () => {
    closeSnackbar();
    setDeclineConfirmationIsOpen(true);
  };

  const handleGoBack = () => {
    closeSnackbar();
    if (previousPath) {
      history.push({
        pathname: previousPath,
        state: {
          fromReview: true,
          abn: invoice.supplierAbn,
          supplierGcAccountId: invoice.supplierGcAccountId,
        },
      });
    } else {
      history.goBack();
    }
  };

  const closeDeclineConfirmation = (declined: boolean) => {
    setDeclineConfirmationIsOpen(false);
    if (declined) {
      setIsSubmitting(true);
      declineInvoice(id || '', gcAccountId).then((result) => {
        setIsSubmitting(false);
        if (result.error) {
          defaultHandleError(props, undefined);
        } else {
          history.push({
            pathname: '/supplier/payments/message',
            state: {
              variant: 'warning',
              title: 'Invoice declined',
              paragraph1: `${invoice.supplierAccountName} has been notified`,
              backButtonLabel: 'Go back to dashboard',
              backButtonRoute: {
                pathname: '/',
              },
            },
          });
        }
      });
    }
  };

  const isValid = () => {
    if (!paymentMethod) {
      setValidationError('Please select a payment method');
      return false;
    }

    if (paymentMethod === PaymentMethodValues.Weekly && installmentTermInWeeks === -1) {
      setValidationError('Please select an installment term');
      return false;
    }

    // TODO: Deferred until ACAT-343
    // if (paymentMethod === PAYMENT_METHOD_FULL && paymentDate === null) {
    //   setValidationError('Please select a date');
    //   return false;
    // }

    setValidationError(null);
    return true;
  };

  if (redirectSourceValidity === RedirectSourceState.Invalid) {
    return <Redirect to='/buyer/payments/pay-new-or-existing' />;
  }

  const forwardSuccess = (response) => {
    setRedirectSourceValidity(RedirectSourceState.Initial);
    if (response && response.showAddStaffPage) {
      history.push({
        pathname: '/buyer/payments/staffs/add',
        state: {
          amount: invoice.amount,
          supplierAccountName: invoice.supplierAccountName,
          gcAccountId,
          invoiceNumber: invoice.invoiceNumber,
          isSupplierInitiated: !!id,
        },
      });
    } else {
      history.push({
        pathname: '/buyer/payments/paid',
        state: {
          amount: invoice.amount,
          supplierAccountName: invoice.supplierAccountName,
          gcAccountId,
          invoiceNumber: invoice.invoiceNumber,
          isSupplierInitiated: !!id,
        },
      });
    }
  };

  const handleApiResponse = (response) => {
    traceInfo(`Confirm/add invoice completed.... for ${JSON.stringify(gcAccountId)}-${JSON.stringify(isSubmitting)}`);
    setIsSubmitting(false);
    //here we handle responses both from axios (eg addSupplier from shiftPaymentApi.js) and fetch (eg addInvoice from fetchApi.tsx)
    //these responses are in different formats, eg:
    // - fetch may have response.payload.status and/or response.payload.code
    // - axios with error will have response.error
    if (response?.payload?.status || response.error === true) {
      //handle 400, 404 and 500 network errors
      setValidationError(DEFAULT_ERROR_MESSAGE);
      setIsSubmitting(true);
    } else if (response?.payload?.code) {
      if (response.payload.code === responseCode.InvoiceMissingAttachment) {
        setValidationError(INVOICE_MISSING_ATTACHMENT_ERROR_MESSAGE);
      } else if (response.payload.code === responseCode.InvoiceRequireRetry) {
        setValidationError(INVOICE_REQUIRE_RETRY_ERROR_MESSAGE);
      } else {
        setValidationError(getErrorMessage(response.payload.message));
      }
      setIsSubmitting(true);
    } else {
      if (response?.payload) {
        // prevents the immediate positive confirmation to join the broker network feedback from other payments confirmations
        if (!isAllowedJoinMemberNetwork) {
          allowJoinBrokerNetwork(response.payload.isAllowedJoinBrokerNetwork);
        }
      }
      getStaffPageVisibility(gcAccountId).then((staffPageResponse) => {
        forwardSuccess(staffPageResponse.payload.data);
      });
    }
  };

  const onSubmit = () => {
    try {
      if (isValid()) {
        closeSnackbar();
        traceInfo(`Submit Invoice for ${JSON.stringify(gcAccountId)}-${JSON.stringify(isSubmitting)}`);
        setIsSubmitting(true);
        if (id) {
          // supplier initiated invoice
          traceInfo(`Supplier initiated invoice for ${JSON.stringify(gcAccountId)}-${JSON.stringify(isSubmitting)}`);
          if (!invoice.supplierAuthorizationStatus && !newAuthorizationStatus) {
            const date = paymentDate ? paymentDate.toDate() : null;
            history.push({
              pathname: '/buyer/payments/review-authorization',
              state: {
                invoice,
                paymentMethod,
                paymentDate: date,
                installmentTermInWeeks,
                invoiceConfirmRequestId,
              },
            });
            return;
          }
          confirmInvoice(
            id || '',
            gcAccountId,
            // $FlowFixMe
            paymentMethod,
            invoiceConfirmRequestId,
            paymentDate ? paymentDate.toDate() : null,
            installmentTermInWeeks
          )
            .then((response) => {
              handleApiResponse(response);
            })
            .catch((error) => {
              traceInfo(
                `Buyer review - uncaught promise error confirmInvoice:\nName: ${error.name}\nMessage: ${error.message}\nStack: ${error.stack}\nResponse: ${error.response}`
              );
              throw new Error(error); //temporarily doing this, so that we don't change current behaviour
            });
        } else {
          // buyer initiated invoice

          if (invoice.isEditSupplier) {
            editSupplierAndAddInvoice(
              gcAccountId,
              invoice.invoiceNumber,
              invoice.amount,
              // $FlowFixMe
              invoice.invoiceDescription,
              invoice.fileGroupId,
              invoice.attachments,
              // $FlowFixMe
              paymentMethod,
              paymentDate ? paymentDate.toDate() : null,
              installmentTermInWeeks,
              invoice.isNewSupplier,
              invoice.supplierGcAccountId,
              invoice.supplierAbn,
              invoice.supplierBsb,
              invoice.supplierBankAccountNumber,
              invoice.isDirector,
              invoiceConfirmRequestId
            )
              .then((response) => {
                handleApiResponse(response);
              })
              .catch((error) => {
                traceInfo(
                  `Buyer review - uncaught promise error editSupplierAndAddInvoice:\nName: ${error.name}\nMessage: ${error.message}\nStack: ${error.stack}\nResponse: ${error.response}`
                );
                throw new Error(error); //temporarily doing this, so that we don't change current behaviour
              });
            return;
          }

          traceInfo(`Buyer initiated invoice for ${JSON.stringify(gcAccountId)}-${JSON.stringify(isSubmitting)}`);
          addInvoice(
            gcAccountId,
            invoice.invoiceNumber,
            invoice.amount,
            // $FlowFixMe
            invoice.invoiceDescription,
            invoice.fileGroupId,
            invoice.attachments,
            // $FlowFixMe
            paymentMethod,
            paymentDate ? paymentDate.toDate() : null,
            installmentTermInWeeks,
            invoice.isNewSupplier,
            invoice.supplierGcAccountId,
            invoice.supplierAbn,
            invoice.supplierBsb,
            invoice.supplierBankAccountNumber,
            invoice.isDirector,
            invoiceConfirmRequestId
          )
            .then((response) => {
              handleApiResponse(response);
            })
            .catch((error) => {
              traceInfo(
                `Buyer review - uncaught promise error addInvoice:\nName: ${error.name}\nMessage: ${error.message}\nStack: ${error.stack}\nResponse: ${error.response}`
              );
              throw new Error(error); //temporarily doing this, so that we don't change current behaviour
            });
        }
      }
    } catch (error) {
      traceInfo(`Buyer review - uncaught error:\nName: ${error.name}\nMessage: ${error.message}\nStack: ${error.stack}\nResponse: ${error.response}`);
      throw new Error(error); //temporarily doing this, so that we don't change current behaviour
    }
  };

  const setAuthorization = (buyerGcAccountId: string, supplierGcAccountId: string, authorizationStatus: AuthorizationStatus, reason: ?string) => {
    return setSupplierAuthorization(buyerGcAccountId, supplierGcAccountId, authorizationStatus, reason).then((response) => {
      if (!response.error) {
        setNewAuthorizationStatus(authorizationStatus);
      }
      return response;
    });
  };

  return (
    <BasicPageLayout title='Review transaction'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} className={classes.left}>
          {props.invoice ? (
            <InvoiceDetail
              buyerGcAccountId={gcAccountId}
              // $FlowFixMe
              {...invoice}
              getInvoiceAttachment={props.getInvoiceAttachment}
              setSupplierAuthorization={setAuthorization}
              authorizationStatus={invoice.supplierAuthorizationStatus}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} sm={9}>
          <PaymentMethodRadioGroup
            paymentTypes={paymentTypes}
            paymentMethodDefaultValue={paymentMethod}
            invoiceCreatedDate={invoice.created}
            supplierDueDate={supplierDueDate}
            isBuyerInitiated={isBuyerInitiated}
            transactionFee={transactionFee}
            supplierTerms={invoice.supplierTerm}
            supplierTermPeriod={invoice.supplierTermPeriod}
            feesTermsAndPaymentTypes={feesTermsAndPaymentTypes}
            invoiceAmount={invoice.amount}
            validationError={validationError}
            selectionChanged={handleSelectionChanged}
          />
          <Grid container className={classes.buttonContainer} spacing={1}>
            <Grid item xs={6}>
              {id ? (
                <Button
                  variant='outlined'
                  color='primary'
                  className={classes.buttons}
                  onClick={() => openDeclineConfirmation()}
                  data-testid='uia-declineInvoice'
                  disabled={isSubmitting}
                >
                  Decline
                </Button>
              ) : null}
              {isMobile && !id ? (
                <Button color='primary' className={classes.goBack} onClick={handleGoBack} data-testid='uia-goBack' fullWidth>
                  Go back
                </Button>
              ) : null}
            </Grid>
            <Grid item xs={6} className={classes.primaryButtonContainer}>
              {!isMobile && !id && (
                <Button color='primary' className={classes.goBack} onClick={handleGoBack} data-testid='uia-goBack'>
                  Go back
                </Button>
              )}
              <Button
                variant='contained'
                color='primary'
                className={classes.buttons}
                onClick={onSubmit}
                data-testid='uia-payButton'
                disabled={isSubmitting}
              >
                Pay invoice
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {declineConfirmationIsOpen ? (
        <ConfirmationDialog
          isOpen={declineConfirmationIsOpen}
          onClose={() => closeDeclineConfirmation(false)}
          onConfirm={() => closeDeclineConfirmation(true)}
          title='Decline invoice?'
          contentText='Do you confirm to decline this invoice?'
          id='decline-confirmation'
        />
      ) : null}
    </BasicPageLayout>
  );
};

export default withSnackbar(withWidth()(Review));
