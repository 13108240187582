import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AddPayment from './Component';
import actions from './actions';
import initialDataSelectors from '../../../components/InitialData/selectors';
import startupConfigSelectors from '../../../components/StartupConfig/selectors';
import accountSetupCheckSelectors from '../../../components/AccountSetupCheck/selectors';

const mapStateToProps = createSelector(
  initialDataSelectors.selectSelectedGcAccountId,
  startupConfigSelectors.selectInvoiceAttachment,
  accountSetupCheckSelectors.selectIsSettlementAccountSetupComplete,
  accountSetupCheckSelectors.selectIsMerchantAgreementSetupComplete,
  (gcAccountId, invoiceAttachmentConfig, isSettlementAccountSetupCompleted, isMerchantAgreementSetupCompleted) => ({
    gcAccountId,
    invoiceAttachmentConfig,
    isSettlementAccountSetupCompleted,
    isMerchantAgreementSetupCompleted,
  })
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddPayment);
