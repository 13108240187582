// @flow
/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/styles';

type Props = {
  theme: any,
};

const PhoneIcon = withTheme((props: Props) => (
  <SvgIcon width='15' height='17' viewBox='0 0 15 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...(props: any)}>
    <path
      id='Path'
      d='M1.50156 0.999869C0.332037 4.43755 0.933017 8.37859 3.3927 11.4703L3.44706 11.5386V11.5494C5.90761 14.5958 9.63612 16.0994 13.3058 15.8319L13.3059 15.8319C13.5955 15.8108 13.8035 15.7397 13.9292 15.6553C14.051 15.5735 14.0776 15.4951 14.0745 15.4349C14.0745 15.4349 14.0745 15.4349 14.0745 15.4349L13.9154 12.4005L13.9154 12.4003C13.9097 12.2899 13.8412 12.1573 13.707 12.0328C13.5748 11.9101 13.4065 11.8222 13.26 11.7907L13.2597 11.7906L11.0548 11.3133L11.043 11.3107L11.0431 11.3104C10.6977 11.218 10.5433 11.2531 10.4475 11.3124C10.3327 11.3836 10.2362 11.5245 10.083 11.8028L9.5994 12.9611L9.50124 13.1962L9.26801 13.0936L8.41913 12.72C7.03032 12.1594 6.07964 11.4346 5.04302 10.2443L5.0357 10.2359L5.02916 10.2269C4.10428 8.95144 3.60895 7.85062 3.39357 6.39244L3.23514 5.50975L3.18897 5.25249L3.44802 5.2178L4.70751 5.04912C5.01876 4.97207 5.18616 4.91172 5.28707 4.8171C5.37046 4.73892 5.44302 4.60061 5.43623 4.25435L5.43598 4.2417L5.4363 4.24171L5.50449 2.03675L5.5045 2.03655C5.50905 1.8929 5.46388 1.71135 5.37452 1.55408C5.28418 1.39507 5.16871 1.29445 5.05732 1.26098L5.05708 1.26091L2.08134 0.363643C2.08038 0.363501 2.07809 0.36321 2.07396 0.362963C2.07179 0.362832 2.06933 0.362723 2.06641 0.362635L2.02481 0.362372C1.96565 0.361999 1.88105 0.390524 1.7817 0.494226C1.68213 0.598164 1.58139 0.765235 1.50156 0.999869ZM1.50156 0.999869L1.26488 0.91935M1.50156 0.999869L1.26488 0.91935M1.26488 0.91935C0.0680477 4.43731 0.684195 8.46739 3.19706 11.626L2.02639 0.112377H2.02079C1.72823 0.114223 1.43968 0.405594 1.26488 0.91935Z'
      fill='#00B7C1'
      stroke='#00B7C1'
      strokeWidth='0.5'
    />
  </SvgIcon>
));

export default PhoneIcon;
