// @flow
import React from 'react';
import NumberFormat from 'react-number-format';

type Props = {
  value: string,
};

const BsbText = ({ value }: Props) => {
  return <NumberFormat value={value} displayType='text' format='###-###' />;
};

export default BsbText;
