// @flow
export const ACTION_PREFIX = 'PAY_NEW_OR_EXISTING_SUPPLIER';
export const PARTITION = 'payNewOrExistingSupplier';

export const actionTypes = {
  GET_SUPPLIERS_PENDING: 'GET_SUPPLIERS_PENDING',
  GET_SUPPLIERS_FULFILLED: 'GET_SUPPLIERS_FULFILLED',
  GET_SUPPLIERS_REJECTED: 'GET_SUPPLIERS_REJECTED',
};

export const SELECT_A_SUPPLIER = 'SELECT_A_SUPPLIER';
