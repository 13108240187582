// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SuccessIcon from './svg/SuccessIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 1.5, 1.5, 1.5),
    },
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.contrast,
    width: '100%',
    padding: theme.spacing(1),
  },
  message: {
    color: theme.palette.success.main,
    fontSize: 14,
    fontWeight: 400,
  },
  subMessage: {
    color: theme.palette.grey.subMessage,
    fontSize: 13,
  },
  alertIcon: {
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  message: string,
  subMessage: ?(string | React.Node),
  noIcon?: boolean,
};

const AlertSuccess = (props: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} border={2} borderRadius='borderRadius' display='flex' alignItems='flex-start'>
      {!props.noIcon ? <SuccessIcon className={classes.alertIcon} viewBox='0 0 26 26' /> : null}
      <Box>
        <Typography className={classes.message}>{props.message}</Typography>
        {props.subMessage ? <Typography className={classes.subMessage}>{props.subMessage}</Typography> : null}
      </Box>
    </Box>
  );
};

AlertSuccess.defaultProps = {
  subMessage: null,
};

export default AlertSuccess;
