// @flow
import { type Dispatch } from 'redux';
import { createActions } from 'redux-actions';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';

export const { registerInterestPending, registerInterestFulfilled, registerInterestRejected } = createActions(
  actionTypes.REGISTER_INTEREST_PENDING,
  actionTypes.REGISTER_INTEREST_FULFILLED,
  actionTypes.REGISTER_INTEREST_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const registerInterest = (gcAccountId: string, gcContactId: string) => (dispatch: Dispatch<any>) => {
  dispatch(registerInterestPending());

  return shiftPaymentsApi
    .registerInterest(gcAccountId, gcContactId)
    .then((response) => {
      return dispatch(registerInterestFulfilled(response));
    })
    .catch((error) => {
      return dispatch(registerInterestRejected(error));
    });
};

export default {
  registerInterest,
};
