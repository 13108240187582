// @flow
// $FlowFixMe
import { batch } from 'react-redux';
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';
import { type Account } from '../../types';
import { updateOpenTradeAccount } from '../UserSelectProfileMode/actions';

export const {
  updateBankAccountSetup,
  updateBankAccountSetupCompleted,
  updateDirectDebitAgreementSetup,
  updateDirectDebitAgreementSetupCompleted,
  updateLoanAgreementSetup,
  updateLoanAgreementSetupCompleted,
  updatePrivacyAgreementSetup,
  updatePrivacyAgreementSetupCompleted,
  updateTermsAndConditionsAgreementSetup,
  updateTermsAndConditionsAgreementSetupCompleted,
  resetAccountSetup,
  getAccountSetupFulfilled,
  getAccountSetupPending,
  getAccountSetupRejected,
  setAccountProfile,
  updateSettlementAccountSetupCompleted,
  updateMerchantAgreementSetupCompleted,
} = createActions(
  actionTypes.UPDATE_BANK_ACCOUNT_SETUP,
  actionTypes.UPDATE_BANK_ACCOUNT_SETUP_COMPLETED,
  actionTypes.UPDATE_DIRECT_DEBIT_AGREEMENT_SETUP,
  actionTypes.UPDATE_DIRECT_DEBIT_AGREEMENT_SETUP_COMPLETED,
  actionTypes.UPDATE_LOAN_AGREEMENT_SETUP,
  actionTypes.UPDATE_LOAN_AGREEMENT_SETUP_COMPLETED,
  actionTypes.UPDATE_PRIVACY_AGREEMENT_SETUP,
  actionTypes.UPDATE_PRIVACY_AGREEMENT_SETUP_COMPLETED,
  actionTypes.UPDATE_TERMS_AND_CONDITIONS_AGREEMENT_SETUP,
  actionTypes.UPDATE_TERMS_AND_CONDITIONS_AGREEMENT_SETUP_COMPLETED,
  actionTypes.RESET_ACCOUNT_SETUP,
  actionTypes.GET_ACCOUNT_SETUP_FULFILLED,
  actionTypes.GET_ACCOUNT_SETUP_PENDING,
  actionTypes.GET_ACCOUNT_SETUP_REJECTED,
  actionTypes.SET_ACCOUNT_PROFILE,
  actionTypes.UPDATE_SETTLEMENT_ACCOUNT_SETUP_COMPLETED,
  actionTypes.UPDATE_MERCHANT_AGREEMENT_SETUP_COMPLETED,
  getActionOptions(ACTION_PREFIX)
);

const getAccountSetup = (account: Account) => {
  if (!account) return;
  return async (dispatch: Dispatch<any>) => {
    dispatch(getAccountSetupPending());
    dispatch(setAccountProfile(account));
    return shiftPaymentsApi
      .getAccountSetup(account.gcAccountId)
      .then((response) => {
        const {
          bankAccount,
          termsAndConditionsAgreement,
          directDebitAgreement,
          loanAgreement,
          privacyAgreement,
          isSettlementAccountCompleted,
          isMerchantAgreementSetupCompleted,
        } = response.data;
        return batch(() => {
          dispatch(resetAccountSetup());
          dispatch(updatePrivacyAgreementSetup(privacyAgreement));
          dispatch(updateBankAccountSetup(bankAccount));
          dispatch(updateDirectDebitAgreementSetup(directDebitAgreement));
          dispatch(updateLoanAgreementSetup(loanAgreement));
          dispatch(updateTermsAndConditionsAgreementSetup(termsAndConditionsAgreement));
          dispatch(updateSettlementAccountSetupCompleted(isSettlementAccountCompleted));
          dispatch(updateMerchantAgreementSetupCompleted(isMerchantAgreementSetupCompleted));
          return dispatch(getAccountSetupFulfilled());
        });
      })
      .catch((error) => {
        return batch(() => {
          return dispatch(getAccountSetupRejected(error));
        });
      });
  };
};

export default {
  getAccountSetup,
  updateOpenTradeAccount,
};
