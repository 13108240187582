import hashSHA256 from './cryptoUtils';
const loginSuccessEvent = async (mobileNumber) => {
  const mobileNumberHash = mobileNumber ? await hashSHA256(mobileNumber) : '';
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'login_success',
    platform: 'Shift Trade',
    user_id: mobileNumberHash,
  });
};
export default { loginSuccessEvent };
