// @flow
import React from 'react';

import { formatMobileNumber } from '../lib/formatter';

type Props = {
  value: string,
};

const PhoneNumberText = ({ value }: Props) => <>{formatMobileNumber(value)}</>;

export default PhoneNumberText;
