// @flow
import { handleActions } from 'redux-actions';
import { PARTITION, actionTypes, ACTION_PREFIX } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import type { BankAccount } from '../../components/BankAccounts';
import type { PreApprovalDetail } from '../../types';

export type ReduxState = {
  repaymentDay: ?number,
  directDebitAccount: BankAccount[],
  smsReminder: false,
  currentAccountLimit: ?number,
  creditLimitPendingRequest: ?any,
  hasNewCreditLimitPendingRequest: boolean,
  preApprovalDetail: PreApprovalDetail,
};

const initialState: ReduxState = {
  repaymentDay: null,
  directDebitAccount: [],
  smsReminder: false,
  currentAccountLimit: 0,
  creditLimitPendingRequest: {
    isLoading: false,
    data: null,
  },
  hasNewCreditLimitPendingRequest: false,
  preApprovalDetail: {},
};

const reducer = handleActions<ReduxState>(
  {
    [actionTypes.GET_SETTINGS_FULFILLED]: (state, { payload }) => ({
      ...state,
      repaymentDay: payload.data.repaymentDay,
      directDebitAccount: payload.data.directDebitAccount ? [payload.data.directDebitAccount] : null,
      smsReminder: payload.data.smsReminder,
      currentAccountLimit: payload.data.currentAccountLimit,
      preApprovalDetail: payload.data.preApprovalDetail ? payload.data.preApprovalDetail : {},
    }),
    [actionTypes.SET_SMS_REMINDER_FULFILLED]: (state, { payload }) => ({
      ...state,
      smsReminder: payload,
    }),
    [actionTypes.SET_REPAYMENT_DAY_FULFILLED]: (state, { payload }) => ({
      ...state,
      repaymentDay: payload,
    }),
    [actionTypes.GET_CREDIT_LIMIT_PENDING_REQUEST_FULFILLED]: (state, { payload }) => ({
      ...state,
      creditLimitPendingRequest: payload,
    }),
    [actionTypes.SET_HAS_NEW_CREDIT_LIMIT_PENDING_REQUEST]: (state, { payload }) => ({
      ...state,
      hasNewCreditLimitPendingRequest: payload,
    }),
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
