/* eslint-disable no-param-reassign */
// @flow
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';
import { type ReferralAccount } from '../../types';

type Props = {
  referralAccount: ?ReferralAccount,
  setReferralAccount: (referralAccount: ?ReferralAccount) => any,
};

const ReferralAccountFromQueryString = ({ referralAccount, setReferralAccount }: Props) => {
  const location = useLocation();
  React.useEffect(() => {
    if ((location.pathname === '/' || location.pathname === '/login') && location.search && !referralAccount) {
      const { referrer_account: hashedAccountId = null, term = null, termPeriod = null } = queryString.parse(location.search);
      referralAccount = {
        hashedAccountId,
        term,
        termPeriod,
      };
      if (referralAccount && referralAccount.hashedAccountId && referralAccount.term) {
        setReferralAccount(referralAccount);
      }
    } else {
      setReferralAccount(null);
    }
  }, []);

  return null;
};

ReferralAccountFromQueryString.defaultProps = {
  referralAccount: null,
};

export default ReferralAccountFromQueryString;
