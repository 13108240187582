export const ACTION_PREFIX = 'PAYMENTS';
export const PARTITION = 'payments';

export const actionTypes = {
  GET_PAYMENTS_PENDING: 'GET_PAYMENTS_PENDING',
  GET_PAYMENTS_FULFILLED: 'GET_PAYMENTS_FULFILLED',
  GET_PAYMENTS_REJECTED: 'GET_PAYMENTS_REJECTED',
  GET_CONTACTS_PENDING: 'GET_CONTACTS_PENDING',
  GET_CONTACTS_FULFILLED: 'GET_CONTACTS_FULFILLED',
  GET_CONTACTS_REJECTED: 'GET_CONTACTS_REJECTED',
};
