// @flow
// $FlowFixMe
import { batch } from 'react-redux';
import { type Dispatch } from 'redux';
import { createActions } from 'redux-actions';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';
import { resetBankLogin } from '../../components/AddBankConnectForm/actions';

export type NormalizedBankAccount = {
  accountNumber: string,
  accountName: string,
  bsb: string,
  bankAccountId: string,
  bankName: string,
  bankIcon: string,
};

export const { getBankAccountsPending, getBankAccountsFulfilled, getBankAccountsRejected } = createActions(
  actionTypes.GET_BANK_ACCOUNTS_PENDING,
  actionTypes.GET_BANK_ACCOUNTS_FULFILLED,
  actionTypes.GET_BANK_ACCOUNTS_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getBankAccounts = (gcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(getBankAccountsPending());

  return shiftPaymentsApi
    .getBankAccounts(gcAccountId, true)
    .then((agreementResponse) => {
      return batch(() => {
        dispatch(resetBankLogin());
        return dispatch(getBankAccountsFulfilled(agreementResponse));
      });
    })
    .catch((agreementError) => {
      return dispatch(getBankAccountsRejected(agreementError));
    });
};

export default {
  getBankAccounts,
};
