/* eslint-disable flowtype/no-types-missing-file-annotation */
import { createActions } from 'redux-actions';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';
import { resetTotalSupplierRequests } from '../../../components/SupplierRequests/actions';

export const {
  rejectRequestPending,
  rejectRequestFulfilled,
  rejectRequestRejected,
  approveRequestPending,
  approveRequestFulfilled,
  approveRequestRejected,
  getSupplierRequestPending,
  getSupplierRequestFulfilled,
  getSupplierRequestRejected,
} = createActions(
  actionTypes.REJECT_REQUEST_PENDING,
  actionTypes.REJECT_REQUEST_FULFILLED,
  actionTypes.REJECT_REQUEST_REJECTED,
  actionTypes.APPROVE_REQUEST_PENDING,
  actionTypes.APPROVE_REQUEST_FULFILLED,
  actionTypes.APPROVE_REQUEST_REJECTED,
  actionTypes.GET_SUPPLIER_REQUEST_PENDING,
  actionTypes.GET_SUPPLIER_REQUEST_FULFILLED,
  actionTypes.GET_SUPPLIER_REQUEST_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getSupplierRequest = (supplierGcAccountId: string, buyerGcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(getSupplierRequestPending());

  return shiftPaymentsApi
    .getSupplierRequest(supplierGcAccountId, buyerGcAccountId)
    .then((response) => {
      return dispatch(getSupplierRequestFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getSupplierRequestRejected(error));
    });
};

const rejectRequest = (supplierGcAccountId: string, buyerGcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(rejectRequestPending());

  return shiftPaymentsApi
    .rejectSupplierRequest(supplierGcAccountId, buyerGcAccountId)
    .then((response) => {
      return dispatch(rejectRequestFulfilled(response));
    })
    .catch((error) => {
      return dispatch(rejectRequestRejected(error));
    });
};

const approveRequest = (supplierGcAccountId: string, buyerGcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(approveRequestPending());

  return shiftPaymentsApi
    .approveSupplierRequest(supplierGcAccountId, buyerGcAccountId)
    .then((response) => {
      return dispatch(approveRequestFulfilled(response));
    })
    .catch((error) => {
      return dispatch(approveRequestRejected(error));
    });
};

export default {
  rejectRequest,
  approveRequest,
  getSupplierRequest,
  resetTotalSupplierRequests,
};
