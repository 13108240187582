// @flow
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Link, Box } from '@material-ui/core';
import { useLocation, Redirect } from 'react-router';
import * as queryStringParser from 'query-string';
import { Helmet } from 'react-helmet';
import withWidth from '@material-ui/core/withWidth';

import { type StartupConfigValues } from '../../lib/startupConfig';
import { ShiftTradeLogo } from '../../components/svg';
import Footer from '../../components/Footer';
import { formatSupplierTerm } from '../../lib/formatter';
import { isMobileResolution } from '../../lib/materialUiUtils';
import { DEFAULT_TERM_PERIOD } from '../../constants';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.mediumGreen,
    [theme.breakpoints.up('sm')]: {
      height: '100vh',
    },
  },
  mainContent: {
    backgroundColor: theme.palette.common.nileBlue,
    color: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      height: 692,
      width: 930,
      padding: theme.spacing(12),
    },
  },
  logo: {
    display: 'block',
    marginBottom: `${theme.spacing(6)}px !important`,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: 220,
      margin: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      width: 220,
    },
    [theme.breakpoints.up('xl')]: {},
  },
  stixAndHeaderContainer: {
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      '& h2': {
        lineHeight: 1.2,
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(1, 0, 0, 2),
        },
      },
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      width: '100%',
    },
  },
  activateNowButton: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  card: {
    backgroundColor: theme.palette.common.lightGreen,
    borderRadius: '6px 6px 40px 6px',
    color: theme.palette.common.black,
    boxShadow: `12px 12px ${theme.palette.common.mediumGreen}`,
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(2),
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(3),
    },
  },
  info: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(10, 0, 0, 3),
    },
    '& h3': {
      margin: theme.spacing(3, 0, 0),
    },
    '& ul': {
      fontSize: 16,
      paddingLeft: theme.spacing(3),
      listStyle: 'none',
      fontWeight: 500,
      marginBottom: theme.spacing(4),
      '& li:before': {
        content: "'•'",
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: 16,
        display: 'inline-block',
        width: '1em',
        marginLeft: '-1em',
        lineHeight: '20px',
      },
      '& li': {
        marginBottom: theme.spacing(4),
      },
    },
  },
  liInner: {
    fontSize: 14,
    fontWeight: 400,
  },
  businessIcon: {
    [theme.breakpoints.down('xs')]: {
      height: 55,
      width: 55,
    },
    [theme.breakpoints.up('sm')]: {
      height: 75,
      width: 75,
    },
  },
  accountNameText: {
    color: theme.palette.common.black,
    fontSize: 14,
    fontWeight: 500,
  },
  subTextContainer: {
    borderLeft: `3px solid ${theme.palette.common.ripTide}`,
    paddingLeft: theme.spacing(2),
  },
  subTextUl: {
    paddingLeft: theme.spacing(2),
  },
  headerText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    marginBottom: theme.spacing(2),
  },
  headerTextWrapper: {
    marginBottom: theme.spacing(2),
  },
  welcomeShiftHeader: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  accountSetupInfo: {
    fontSize: 12,
    lineHeight: '18px',
  },
  learnMore: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
  },
}));

type Props = {
  configuration: StartupConfigValues,
  getInvitationDetails: (
    senderGcAccountId: string,
    senderGcContactId: string,
    recipientGcAccountId: string,
    recipientGcContactId: string
  ) => Promise<any>,
  width: string,
};

const getHeaderText = (term?: number, termPeriod?: string, classes: any, senderAccountName: string) => {
  if (!term || term === 0 || term === 182) {
    return (
      <>
        Set up your account at <span className={classes.accountNameText}>{senderAccountName}</span>
      </>
    );
  } else {
    return (
      <>
        <span className={classes.accountNameText}>{senderAccountName}</span>
        <span className={classes.headerText}>
          {' '}
          has invited you to register for their
          {''} {formatSupplierTerm(term, termPeriod || DEFAULT_TERM_PERIOD, false)} trade program.
        </span>
      </>
    );
  }
};

const hasTerm = (term?: number) => term || term === 0;

const SignMeUp = ({ configuration, getInvitationDetails, width }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const isMobile = isMobileResolution(width);
  const [queryParamsMissing, setQueryParamsMissingState] = useState(false);
  const [getInvitationFailed, setGetInvitationFailedState] = useState(false);
  const [sender, setSenderState] = useState<any>(null);
  const [recipient, setRecipientState] = useState(null);
  const [formattedTerm, setFormattedTerm] = useState('');

  const queryStrings = queryStringParser.parse(location.search);
  const senderGcAccountId = queryStrings.senderGcAccountId ? queryStrings.senderGcAccountId.toString() : '';
  const senderGcContactId = queryStrings.senderGcContactId ? queryStrings.senderGcContactId.toString() : '';
  const recipientGcAccountId = queryStrings.recipientGcAccountId ? queryStrings.recipientGcAccountId.toString() : '';
  const recipientGcContactId = queryStrings.recipientGcContactId ? queryStrings.recipientGcContactId.toString() : '';
  const invitationCode = queryStrings.invitation_code ? queryStrings.invitation_code.toString() : '';
  const initiatedBy = queryStrings.initiatedBy ? queryStrings.initiatedBy.toString().toLowerCase() : '';
  const referrerGcAccountId = queryStrings.referrer_account ? queryStrings.referrer_account.toString() : '';
  let term = queryStrings.term || queryStrings.term === 0 ? parseInt(queryStrings.term.toString(), 16) : undefined;
  let termPeriod = queryStrings.termPeriod ? queryStrings.termPeriod.toString() : undefined;
  const isReferralLink = referrerGcAccountId;
  const isRMSalesforceLink = initiatedBy === 'rm';

  useEffect(() => {
    setGetInvitationFailedState(false);
    setQueryParamsMissingState(false);
    setSenderState(null);
    setRecipientState(null);

    if (!(recipientGcAccountId && recipientGcContactId && invitationCode) && !isReferralLink) {
      setQueryParamsMissingState(true);
    } else if (!isRMSalesforceLink && !(senderGcAccountId && senderGcContactId) && !isReferralLink) {
      setQueryParamsMissingState(true);
    } else {
      getInvitationDetails(senderGcAccountId || referrerGcAccountId, senderGcContactId, recipientGcAccountId, recipientGcContactId).then(
        (response) => {
          const { error, payload } = response;
          if (error) {
            setGetInvitationFailedState(true);
          } else {
            if (isReferralLink) {
              const senderAccount = {
                account: {
                  name: payload.data.accountName,
                },
              };
              setSenderState(senderAccount);
              return;
            }

            setRecipientState(payload.data.recipient);
            if (payload.data.sender) {
              setSenderState(payload.data.sender);
            }
          }
        }
      );
    }
  }, [getInvitationDetails, senderGcAccountId, senderGcContactId, recipientGcAccountId, recipientGcContactId]);

  useEffect(() => {
    if (sender) {
      if (!hasTerm(term)) {
        term = sender.termInDays;
        termPeriod = sender.termPeriod;
      }
      setFormattedTerm(getTermOffered());
    }
  }, [sender]);

  useEffect(() => {
    setFormattedTerm(getTermOffered());
  }, []);

  if (queryParamsMissing || getInvitationFailed) {
    return <Redirect to='/' />;
  }

  let headerText = <></>;
  let subText;

  if (recipient && sender) {
    if (recipient.account.gcAccountId === sender.account.gcAccountId) {
      subText = (
        <>
          Hi {recipient.contact.firstName}, {sender.contact.firstName} has invited you&nbsp; to Shift Trade platform for{' '}
          <span className={classes.accountNameText}>{recipient.account.name}</span>.
        </>
      );
    } else {
      headerText = getHeaderText(sender.termInDays, sender.termPeriod, classes, sender.account.name);
      subText = (
        <>
          <span className={classes.accountNameText}>{sender.account.name}</span> provides trade accounts on the Shift Trade platform.
        </>
      );
    }
  } else if (recipient) {
    subText = (
      <>
        Hi {recipient.contact.firstName}, <span className={classes.accountNameText}>{recipient.account.name}</span>&nbsp; has set up a trade account
        on Shift Trade platform.
      </>
    );
  } else if (sender && hasTerm(term)) {
    headerText = getHeaderText(term, termPeriod, classes, sender.account.name);
    subText = (
      <>
        <span className={classes.accountNameText}>{sender.account.name}</span> provides trade accounts on the Shift Trade Platform.
      </>
    );
  } else if (sender && !term) {
    subText = (
      <>
        <span className={classes.accountNameText}>{sender.account.name}</span> has invited you to set up a trade account on Shift Trade platform.
      </>
    );
  }

  const getSenderName = () => {
    return sender && sender.account.name;
  };

  const getTermOffered = () => {
    if (!term) return '';

    return formatSupplierTerm(term, termPeriod || DEFAULT_TERM_PERIOD, false);
  };

  let activateNowUrl = configuration.registrationUiUrl;
  activateNowUrl += isReferralLink ? location.search : `?invitationCode=${invitationCode}`;

  return (
    <div className={classes.container}>
      <Helmet>
        <title>Shift Trade - Sign Me Up</title>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <Box className={classes.container} flexDirection='column' display='flex' alignItems='center' justifyContent='center'>
        <Box className={classes.mainContent} flexDirection='column' display='flex' alignItems='center' justifyContent='space-around' borderRadius={6}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <ShiftTradeLogo width={isMobile ? '113px' : '118px'} height={isMobile ? '41px' : '46px'} className={classes.logo} dark />
              <Box className={classes.card} flexDirection='column' display='flex'>
                <Box className={classes.welcomeShiftHeader}>
                  <span>Welcome to Shift</span>
                </Box>
                {headerText ? <Typography variant='h2' data-testid='uia-headerText' className={classes.headerText}>
                    {headerText}
                  </Typography> : null}
                {subText ? <Box className={classes.subTextContainer}>
                    <Typography variant='body1' className={classes.accountSetupInfo}>
                      For account set up you&apos;ll need:
                      <ul className={classes.subTextUl}>
                        <li>an Australian mobile number</li>
                        <li>Australian bank account for direct debit set up</li>
                        <li>
                          one form of ID (<i>Australian driver&apos;s licence or passport</i>)
                        </li>
                      </ul>
                    </Typography>
                  </Box> : null}
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  className={classes.activateNowButton}
                  data-testid='uia-activateNowButton'
                  href={activateNowUrl}
                >
                  Get started
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.info} flexDirection='column' display='flex'>
                <ul>
                  <li>
                    Choose your account limit
                    <br />
                    <span className={classes.liInner}>Get an account up to $50K in minutes, up to $500K within hours.</span>
                  </li>
                  <li>
                    More flexible trade payment terms
                    <br />
                    <span className={classes.liInner}>
                      Get access to {formattedTerm} supplier terms with {getSenderName()} at no cost to you.
                    </span>
                  </li>
                  <li>
                    All your invoices in one place
                    <br />
                    <span className={classes.liInner}>One account, one universal limit to pay all supplier invoices on flexible terms.</span>
                    <br />
                    <br />
                    <br />
                    <Typography variant='subtitle2' className={classes.learnMore}>
                      <Link href='https://www.shift.com.au/shift-trade/account-activation/' target='_blank'>
                        Learn more
                      </Link>{' '}
                      about the Shift Trade Account.
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <br />
        <Footer />
      </Box>
    </div>
  );
};

export default withWidth()(SignMeUp);
