/* eslint-disable no-param-reassign */
// @flow
import React, { useState, Fragment } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { withFormik, Form, Field } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withRouter } from 'react-router';
import { Grid, Paper, Button, Box, MenuItem, Typography, FormHelperText } from '@material-ui/core';
import type { FormikBag } from 'formik';
import type { History, Location } from 'history';
import { withSnackbar } from 'notistack';
import withWidth from '@material-ui/core/withWidth';
import clsx from 'clsx';

import BasicPageLayout from '../../../components/BasicPageLayout';
import FormItem from '../../../components/FormItem';
import { formSchema } from './validations';
import { handleError, getErrorMessage, defaultHandleError } from '../../../lib/apiHelpers';
import { FormikAbnAutoSuggestField, FormikPhoneNumberField, FormikButtonSelectField } from '../../../components/formik';
import { CONTACT_NAME_NOT_HERE } from './constants';
import { DEFAULT_ERROR_MESSAGE } from '../../../constants';
import AlertError from '../../../components/AlertError';
import FormikNumberFormatInputField from '../../../components/formik/FormikNumberFormatInputField';
import { isMobileResolution } from '../../../lib/materialUiUtils';
import { type BuyerRequestTypeEnum, BuyerRequestType } from '../../../types';
import AlertInfo from '../../../components/AlertInfo';
import { InfoIcon } from '../../../components/svg';
import { formatSupplierTerm } from '../../../lib/formatter';

const { v4: uuidv4 } = require('uuid');

const useStyles = makeStyles((theme) => ({
  customerSearch: {
    borderRadius: 4,
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(2, 2, 3),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 5),
    },
  },
  customerSearchOrange: {
    backgroundColor: theme.palette.common.paleOrange,
  },
  fields: {
    borderTop: `1px solid ${theme.palette.grey['300']}`,
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 5),
    },
  },
  footerButtons: {
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    fontWeight: 'normal',
    [theme.breakpoints.up('xs')]: {
      width: '50%',
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      width: 90,
      marginRight: theme.spacing(1),
    },
  },
  submitButton: {
    [theme.breakpoints.up('xs')]: {
      width: '50%',
      marginLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      width: 150,
    },
  },
  fee: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.action.selected,
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: 4,
  },
  locateTrustTrustee: {
    marginTop: theme.spacing(5),
    justifyContent: 'center',
    fontWeight: 'normal',
  },
  locateTrustTrusteeButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
    borderColor: theme.palette.main,
    borderRadius: 4,
  },
  searchInfo: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.common.lightGreen,
    border: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `5px solid ${theme.palette.primary.main} !important`,
    borderRadius: 6,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 1),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 3, 1),
    },
    '& svg': {
      marginRight: theme.spacing(2),
    },
    '& ul': {
      fontSize: 14,
      paddingLeft: theme.spacing(7),
      marginTop: 4,
      listStyle: 'none',
      '& li:before': {
        content: "'•'",
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: 14,
        display: 'inline-block',
        width: '1em',
        marginLeft: '-1em',
        lineHeight: 2,
      },
    },
  },
  alertInfo: {
    marginTop: theme.spacing(3),
  },
}));

type FormValues = {
  customerGcAccountId: ?string,
  contactId: ?string,
  contactFirstName: ?string,
  contactLastName: ?string,
  mobileNumber: string,
  emailAddress: string,
  avgMonthlyOrderValue: ?number,
  term: { term: number, termPeriod: string, displayText: string, feeInPercentage: number },
  selectedCustomer: ?{ id: string, entityName: string },
  selectedRelatedEntity: ?{ id: string },
  requestType: BuyerRequestTypeEnum,
  loggedInMobileNumber: string,
};

type Props = {
  width: string,
  gcAccountId: string,
  isAutoBillingEnabled: boolean,
  loggedInMobileNumber: string,
  customerSearchDelay: number,
  searchCustomers: (search: string) => Promise<any>,
  searchRelatedCompany: (search: string) => Promise<any>,
  searchRelatedTrust: (search: string) => Promise<any>,
  addCustomer: (
    supplierGcAccountId: string,
    contactId: ?string,
    contactFirstName: ?string,
    contactLastName: ?string,
    mobileNumber: string,
    emailAddress: string,
    avgMonthlyOrderValue: number,
    term: number,
    termPeriod: string,
    customerAbnOrAcn: string,
    relatedEntityAbnOrAcn: string,
    requestType: BuyerRequestTypeEnum,
    isRelatedEntityATrustee: boolean,
    isAutoBillingEnabled: boolean
  ) => Promise<any>,
  getSupplierTermFeeLookup: (gcAccountId: string) => Promise<any>,
  validateNewBuyer: (supplierGcAccountId: string, buyerAbnOrAcn: string, isTrustee: boolean) => Promise<any>,
  validateRelatedEntity: (
    supplierGcAccountId: string,
    buyerAbnOrAcn: string,
    relatedEntityAbnOrAcn: string,
    isRelatedEntityATrustee: boolean
  ) => Promise<any>,
};

type WithHOCProps = {
  ...Props,
  isSubmitting: boolean,
  values: FormValues,
  errors: any,
  touched: any,
  resetForm: () => void,
  setErrors: (errors: any) => void,
  setFieldValue: (fieldName: string, value: any, shouldValidate?: boolean) => void,
  setFieldError: (fieldName: string, error: string) => void,
  enqueueSnackbar: (message: string, options: any) => void,
};

const AddCustomer = ({
  width,
  gcAccountId,
  loggedInMobileNumber,
  customerSearchDelay,
  searchCustomers,
  searchRelatedCompany,
  searchRelatedTrust,
  getSupplierTermFeeLookup,
  validateNewBuyer,
  validateRelatedEntity,
  isSubmitting,
  values,
  errors,
  touched,
  resetForm,
  setErrors,
  setFieldValue,
  setFieldError,
  enqueueSnackbar,
}: WithHOCProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = isMobileResolution(width);
  const [stateTermFeeLookup, setStateTermFeeLookup] = useState([]);
  const [stateContacts, setStateContacts] = useState([]);
  const [displayBusinessDetails, setDisplayBusinessDetails] = useState(false);
  const [displayTrustTrusteeSearch, setDisplayTrustTrusteeSearch] = useState(false);
  const [locateYourTrust, setLocateYourTrust] = useState(false);
  const [locateYourTrustee, setLocateYourTrustee] = useState(false);
  const [isSearchResultSelected, setIsSearchResultSelected] = useState(false);

  React.useEffect(() => {
    if (errors.customerGcAccountId && displayBusinessDetails) {
      setDisplayBusinessDetails(false);
    }
  }, [errors.customerGcAccountId]);

  React.useEffect(() => {
    getSupplierTermFeeLookup(gcAccountId).then((response) => {
      const { error, payload } = response;

      if (error) {
        defaultHandleError({ enqueueSnackbar }, undefined);
      } else {
        setStateTermFeeLookup(payload.data);
      }
    });
  }, [getSupplierTermFeeLookup]);

  const setDirectorList = (directors: Array<any>) => {
    const directorsDisplayList = directors.map((d) => ({
      ...d,
      text: `${d.firstName} ${d.lastName}`,
      requestType: BuyerRequestType.KnownContact,
    }));
    directorsDisplayList.push({
      id: uuidv4(),
      text: CONTACT_NAME_NOT_HERE,
      requestType: BuyerRequestType.UnknownContact,
    });
    setStateContacts(directorsDisplayList);
    setErrors({});
    setDisplayBusinessDetails(true);
  };

  const resetTrustTrusteeSelection = () => {
    setLocateYourTrust(false);
    setDisplayTrustTrusteeSearch(false);
    setLocateYourTrustee(false);
  };

  const handleValidateResponse = (validateResponse) => {
    if (validateResponse.error) {
      setFieldError('customerGcAccountId', getErrorMessage(validateResponse));
    } else {
      const validationResult = validateResponse.payload.data;
      const { directors } = validationResult;

      if (validationResult.locateYourTrust && !values.selectedCustomer) {
        setLocateYourTrust(true);
        setLocateYourTrustee(false);
      } else if (validationResult.locateYourTrustee && !values.selectedCustomer) {
        setLocateYourTrustee(true);
        setLocateYourTrust(false);
      } else if (directors) {
        setDirectorList(directors);
        if (validationResult.relationshipStatus) {
          if (validationResult.relationshipStatus === 'Pending') {
            setFieldError('customerGcAccountId', 'You already have a pending request to this customer.');
          } else {
            setFieldError('customerGcAccountId', 'You have already added this customer.');
          }
          resetTrustTrusteeSelection();
          return;
        }
        if (validationResult.isExistingAccount) {
          setFieldValue('customerGcAccountId', validationResult.gcAccountId);
        } else {
          setFieldValue('customerGcAccountId', '');
        }
      } else {
        resetTrustTrusteeSelection();
        setFieldValue('selectedCustomer', null);
        setFieldError('customerGcAccountId', DEFAULT_ERROR_MESSAGE);
      }
      setFieldValue('loggedInMobileNumber', loggedInMobileNumber);
    }
  };

  const handleCustomerSelected = (suggestion: { id: string, isTrustee: boolean }) => {
    setFieldValue('customerGcAccountId', '');
    setFieldValue('contactFirstName', '');
    setFieldValue('contactLastName', '');
    setFieldValue('contactId', '');
    setFieldValue('requestType', BuyerRequestType.KnownContact);
    setFieldValue('mobileNumber', '');
    setFieldValue('emailAddress', '');
    setFieldValue('avgMonthlyOrderValue', '');
    setFieldValue('term', '');
    setIsSearchResultSelected(true);
    if (displayBusinessDetails) {
      setDisplayBusinessDetails(false);
    }
    if ((locateYourTrust || locateYourTrustee) && displayTrustTrusteeSearch) {
      // $FlowFixMe
      validateRelatedEntity(gcAccountId, values.selectedCustomer.id, suggestion.id, locateYourTrustee).then((validateResponse) => {
        handleValidateResponse(validateResponse);
      });
    } else {
      validateNewBuyer(gcAccountId, suggestion.id, locateYourTrustee).then((validateResponse) => {
        handleValidateResponse(validateResponse);
      });
    }
  };
  const handleSelectionChange = (value) => {
    setFieldValue('contactFirstName', value.firstName || '');
    setFieldValue('contactLastName', value.lastName || '');
    setFieldValue('contactId', value.id || '');
    setFieldValue('requestType', value.requestType);
  };

  const handleCancel = () => {
    resetTrustTrusteeSelection();
    resetForm();
    setDisplayBusinessDetails(false);
    setIsSearchResultSelected(false);
  };

  const handleLocateTrustTrustee = () => {
    setDisplayTrustTrusteeSearch(true);
  };

  return (
    <BasicPageLayout title='Add a customer' noMargin={!isMobile}>
      <Form noValidate autoComplete='off'>
        <Paper>
          {!displayTrustTrusteeSearch && (
            <Box
              className={clsx(classes.customerSearch, {
                [classes.customerSearchOrange]: displayBusinessDetails,
              })}
            >
              <FormItem label="Enter customer's ABN or Business/Trading name" noTopMargin>
                <Field
                  component={FormikAbnAutoSuggestField}
                  variant='outlined'
                  fullWidth
                  autoFocus
                  id='selectedCustomer'
                  name='selectedCustomer'
                  data-testid='uia-customerAutoSuggest'
                  getSuggestions={searchCustomers}
                  onSuggestionSelected={handleCustomerSelected}
                  delay={customerSearchDelay}
                />
              </FormItem>
              {!isSearchResultSelected && (
                <Box className={classes.searchInfo}>
                  <Box display='flex' alignItems='flex-start'>
                    <InfoIcon className={classes.alertIcon} color={theme.palette.primary.main} viewBox='0 0 26 26' />
                    <Typography variant='subtitle2'>
                      Before entering your customer&apos;s ABN, please have the following customer details ready
                    </Typography>
                  </Box>
                  <ul>
                    <li>Mobile number</li>
                    <li>Email address</li>
                    <li>Approximate average monthly order value</li>
                  </ul>
                </Box>
              )}
              {(locateYourTrust || locateYourTrustee) && !displayTrustTrusteeSearch ? <AlertInfo
                  className={classes.alertInfo}
                  message={`This business has been identified as a ${locateYourTrustee ? 'trust' : 'trustee'}`}
                /> : null}
            </Box>
          )}
          {displayTrustTrusteeSearch ? <Box className={classes.customerSearch}>
              <FormItem
                label={locateYourTrustee ? 'Please search for the company acting as Trustee' : 'Please search for the Related Trust'}
                noTopMargin
              >
                <Field
                  component={FormikAbnAutoSuggestField}
                  variant='outlined'
                  fullWidth
                  autoFocus
                  id='selectedRelatedEntity'
                  name='selectedRelatedEntity'
                  data-testid='uia-relatedCustomerAutoSuggest'
                  getSuggestions={locateYourTrust ? searchRelatedTrust : searchRelatedCompany}
                  onSuggestionSelected={handleCustomerSelected}
                  delay={customerSearchDelay}
                />
              </FormItem>
            </Box> : null}
          {errors.customerGcAccountId ? <Box className={classes.fields}>
              <AlertError message={errors.customerGcAccountId} />
            </Box> : null}
          {displayBusinessDetails ? <Box className={classes.fields}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormItem label='Select the name from the list below:' noTopMargin>
                    <Field
                      id='contactId'
                      name='contactId'
                      options={stateContacts}
                      component={FormikButtonSelectField}
                      onSelectionChange={handleSelectionChange}
                      value={values.contactId}
                      data-testid='uia-contactId'
                    />
                  </FormItem>
                </Grid>
                {values.requestType === BuyerRequestType.UnknownContact && (
                  <Fragment>
                    <Grid item xs={12} sm={4}>
                      <FormItem label='First name'>
                        <Field
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          id='contactFirstName'
                          name='contactFirstName'
                          data-testid='uia-contactFirstName'
                          value={values.contactFirstName}
                          autoFocus
                        />
                      </FormItem>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormItem label='Last name'>
                        <Field
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          id='contactLastName'
                          name='contactLastName'
                          data-testid='uia-contactLastName'
                          value={values.contactLastName}
                        />
                      </FormItem>
                    </Grid>
                    {!isMobile && (
                      <React.Fragment>
                        <Grid item xs={12} sm={4} />
                      </React.Fragment>
                    )}
                  </Fragment>
                )}
                <Grid item xs={12} sm={4}>
                  <FormItem label='Customer mobile number'>
                    <Field
                      component={FormikPhoneNumberField}
                      variant='outlined'
                      id='mobileNumber'
                      name='mobileNumber'
                      fullWidth
                      pattern='^4[0-9]{2} [0-9]{3} [0-9]{3}'
                      format='### ### ###'
                      InputProps={{
                        classes: {
                          root: classes.inputBase,
                        },
                      }}
                      data-testid='uia-mobileNumber'
                      value={values.mobileNumber}
                    />
                  </FormItem>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormItem label='Customer email address'>
                    <Field
                      component={TextField}
                      variant='outlined'
                      fullWidth
                      id='emailAddress'
                      name='emailAddress'
                      data-testid='uia-emailAddress'
                      value={values.emailAddress}
                      type='email'
                    />
                  </FormItem>
                </Grid>
                {!isMobile && (
                  <React.Fragment>
                    <Grid item xs={12} sm={4} />
                  </React.Fragment>
                )}
                <Grid item xs={12} sm={4}>
                  <FormItem label="Customer's average monthly order value">
                    <Field
                      component={FormikNumberFormatInputField}
                      variant='outlined'
                      fullWidth
                      id='avgMonthlyOrderValue'
                      name='avgMonthlyOrderValue'
                      data-testid='uia-avgMonthlyOrderValue'
                      value={values.avgMonthlyOrderValue}
                      thousandSeparator
                      allowNegative={false}
                      startAdornment={<InputAdornment position='start'>AUD&nbsp;$</InputAdornment>}
                    />
                  </FormItem>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormItem label='What terms would you like to offer?'>
                    <Box display='flex'>
                      <Field component={Select} variant='outlined' fullWidth id='term' name='term' data-testid='uia-term' value={values.term}>
                        {stateTermFeeLookup.map((x) => (
                          <MenuItem key={`${x.term} ${x.termPeriod}`} value={x}>
                            {formatSupplierTerm(x.term, x.termPeriod)}
                          </MenuItem>
                        ))}
                      </Field>
                      <Box className={classes.fee}>
                        <Typography variant='body2'>FEE</Typography>
                        <Typography variant='subtitle2'>{values.term ? values.term.feeInPercentage : '0.00'}%</Typography>
                      </Box>
                    </Box>
                    {errors.term && touched.term ? <FormHelperText error variant='filled'>
                        {errors.term}
                      </FormHelperText> : null}
                  </FormItem>
                </Grid>
                {!isMobile && (
                  <React.Fragment>
                    <Grid item xs={12} sm={4} />
                  </React.Fragment>
                )}
              </Grid>
            </Box> : null}
        </Paper>
        {(displayBusinessDetails || ((locateYourTrust || locateYourTrustee) && !displayTrustTrusteeSearch)) ? <Box display='flex' justifyContent={isMobile ? 'inherit' : 'flex-end'} className={classes.footerButtons}>
            <Button className={classes.cancelButton} color='primary' disabled={isSubmitting} onClick={handleCancel} data-testid='uia-cancelButton'>
              Cancel
            </Button>
            {(locateYourTrust || locateYourTrustee) && !displayTrustTrusteeSearch ? (
              <Button
                className={classes.locateTrustTrusteeButton}
                variant='contained'
                type='button'
                color='primary'
                disabled={isSubmitting}
                data-testid='uia-searchtrustee'
                onClick={handleLocateTrustTrustee}
              >
                Locate your {locateYourTrustee ? 'trustee' : 'trust'}
              </Button>
            ) : (
              <Button
                className={classes.submitButton}
                variant='contained'
                color='primary'
                type='submit'
                disabled={isSubmitting}
                data-testid='uia-addButton'
              >
                Add customer
              </Button>
            )}
          </Box> : null}
      </Form>
    </BasicPageLayout>
  );
};

AddCustomer.defaultProps = {
  customerSearchDelay: 1000,
};

type WrapperProps = {
  ...Props,
  enqueueSnackbar: (message: string, options: any) => void,
  closeSnackbar: () => void,
};

type WithHOCWrapperProps = {
  ...WrapperProps,
  history: History<Location>,
};

export default withRouter<WrapperProps>(
  withSnackbar(
    withFormik({
      mapPropsToValues: (): FormValues => ({
        customerGcAccountId: '',
        contactId: '',
        contactFirstName: '',
        contactLastName: '',
        mobileNumber: '',
        emailAddress: '',
        avgMonthlyOrderValue: null,
        // $FlowFixMe
        term: null,
        selectedCustomer: null,
        selectedRelatedEntity: null,
        requestType: BuyerRequestType.KnownContact,
        loggedInMobileNumber: '',
      }),
      validateOnBlur: false,
      validationSchema: formSchema,
      handleSubmit: (values: FormValues, formikBag: FormikBag<WithHOCWrapperProps, FormValues>) => {
        const { props } = formikBag;
        const {
          contactId,
          contactFirstName,
          contactLastName,
          mobileNumber,
          emailAddress,
          avgMonthlyOrderValue,
          term,
          requestType,
          // $FlowFixMe
          selectedCustomer: { id: customerAbnOrAcn },
        } = values;
        const relatedEntityAbnOrAcn = values.selectedRelatedEntity ? values.selectedRelatedEntity.id : '';
        // $FlowFixMe
        const isRelatedEntityATrustee = values.selectedRelatedEntity ? values.selectedRelatedEntity.isTrustee : false;

        props.closeSnackbar();

        props
          .addCustomer(
            props.gcAccountId,
            // eslint-disable-next-line no-nested-ternary
            contactId,
            contactFirstName,
            contactLastName,
            `0${mobileNumber}`,
            emailAddress,
            Number(avgMonthlyOrderValue),
            term.term,
            term.termPeriod,
            customerAbnOrAcn,
            relatedEntityAbnOrAcn,
            requestType,
            // $FlowFixMe
            isRelatedEntityATrustee,
            props.isAutoBillingEnabled
          )
          .then((response) => {
            formikBag.setSubmitting(false);

            if (response.error) {
              handleError(response, props, formikBag);
              return;
            }

            if (response.payload && response.payload.status === 200) {
              props.enqueueSnackbar('You have successfully added a customer', {
                variant: 'success',
              });
              props.history.push('/supplier/customers');
            }
          });
      },
      displayName: 'AddCustomer',
    })(withWidth()(AddCustomer))
  )
);
