import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Component from './Component';
import actions from './actions';
import initialDataSelectors from '../../../components/InitialData/selectors';

const mapStateToProps = createSelector(
  initialDataSelectors.selectActiveAccount,
  initialDataSelectors.selectMobileNumber,
  (activeAccount, loggedInMobileNumber) => ({
    gcAccountId: activeAccount && activeAccount.gcAccountId,
    isAutoBillingEnabled: activeAccount && activeAccount.autoBilling,
    loggedInMobileNumber,
  })
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Component);
