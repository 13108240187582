// @flow
import { type Dispatch } from 'redux';
import { createActions } from 'redux-actions';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';

export const { requestBusinessOverdraftPending, requestBusinessOverdraftFulfilled, requestBusinessOverdraftRejected } = createActions(
  actionTypes.REQUEST_BUSINESS_OVERDRAFT_PENDING,
  actionTypes.REQUEST_BUSINESS_OVERDRAFT_FULFILLED,
  actionTypes.REQUEST_BUSINESS_OVERDRAFT_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const requestBusinessOverdraft = (gcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(requestBusinessOverdraftPending());

  return shiftPaymentsApi
    .requestBusinessOverdraft(gcAccountId)
    .then((response) => {
      return dispatch(requestBusinessOverdraftFulfilled(response));
    })
    .catch((error) => {
      return dispatch(requestBusinessOverdraftRejected(error));
    });
};

export default {
  requestBusinessOverdraft,
};
