// @flow
import { handleActions } from 'redux-actions';

import { actionTypes, PARTITION, ACTION_PREFIX } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import startupConfig from '../../lib/startupConfig';
import type { StartupConfigValues } from '../../lib/startupConfig';

export type ReduxState = {
  isConfigurationSet: boolean,
  configuration: StartupConfigValues,
};

const config = startupConfig.get();

const initialState: ReduxState = {
  isConfigurationSet: false,
  configuration: config,
};

const reducer = handleActions<ReduxState>(
  {
    [actionTypes.GET_STARTUP_CONFIG_FULFILLED]: (state, { payload }) => ({
      ...state,
      configuration: payload,
      isConfigurationSet: true,
    }),
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
