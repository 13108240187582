// @flow
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/styles';

import AlertIcon from './svg/AlertIcon';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2, 1.5, 1.5, 1.5),
      },
      backgroundColor: theme.palette.error.light,
      borderColor: theme.palette.error.contrast,
      padding: theme.spacing(1),
    },
    message: {
      color: theme.palette.error.main,
      fontSize: 14,
      fontWeight: 400,
    },
    subMessage: {
      color: theme.palette.grey.subMessage,
      fontSize: 13,
    },
    alertIcon: {
      marginRight: theme.spacing(1),
    },
  };
});

type Props = {
  message: string,
  subMessage: ?(string | React.Node),
  action?: React.Node,
};

const AlertError = (props: Props) => {
  const { message, subMessage, action, ...other } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box
      className={classes.root}
      border={2}
      borderRadius='borderRadius'
      display='flex'
      alignItems='flex-start'
      // $FlowFixMe
      {...other}
    >
      <AlertIcon className={classes.alertIcon} htmlColor={theme.palette.error.main} viewBox='0 0 26 26' />
      <Box>
        <Typography className={classes.message}>{message}</Typography>
        {subMessage ? <Typography className={classes.subMessage}>{subMessage}</Typography> : null}
      </Box>
      <Box style={{ flex: 1, textAlign: 'right' }}>{action}</Box>
    </Box>
  );
};

AlertError.defaultProps = {
  subMessage: null,
};

export default AlertError;
