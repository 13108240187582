// @flow
// $FlowFixMe
import { batch } from 'react-redux';
import { type Dispatch } from 'redux';
import { createActions } from 'redux-actions';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';
import { resetAccountSetup } from '../../components/AccountSetupCheck/actions';
import { resetBankLogin } from '../../components/AddBankConnectForm/actions';

export type NormalizedBankAccount = {
  accountNumber: string,
  accountName: string,
  bsb: string,
  bankAccountId: string,
  bankName: string,
  bankIcon: string,
};

export const {
  signDirectDebitAgreementPending,
  signDirectDebitAgreementFulfilled,
  signDirectDebitAgreementRejected,
  getDirectDebitAgreementPending,
  getDirectDebitAgreementFulfilled,
  getDirectDebitAgreementRejected,
} = createActions(
  actionTypes.SIGN_DIRECT_DEBIT_AGREEMENT_PENDING,
  actionTypes.SIGN_DIRECT_DEBIT_AGREEMENT_FULFILLED,
  actionTypes.SIGN_DIRECT_DEBIT_AGREEMENT_REJECTED,
  actionTypes.GET_DIRECT_DEBIT_AGREEMENT_PENDING,
  actionTypes.GET_DIRECT_DEBIT_AGREEMENT_FULFILLED,
  actionTypes.GET_DIRECT_DEBIT_AGREEMENT_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const setupBankAccountAndSignDirectDebitAgreement =
  (
    gcAccountId: string,
    bankAccount: {
      accountNumber: string,
      accountName: string,
      bsb: string,
      bankAccountId: string,
      lbfcId: string,
    }
  ) =>
  (dispatch: Dispatch<any>) => {
    dispatch(signDirectDebitAgreementPending());

    return shiftPaymentsApi
      .setupBankAccount(gcAccountId, bankAccount)
      .then((agreementResponse) => {
        return batch(() => {
          dispatch(resetBankLogin());
          return dispatch(signDirectDebitAgreementFulfilled(agreementResponse));
        });
      })
      .catch((agreementError) => {
        return dispatch(signDirectDebitAgreementRejected(agreementError));
      });
  };

const updateBankAccountAndDirectDebitSetupComplete = () => (dispatch: Dispatch<any>) => {
  dispatch(resetAccountSetup());
};

const getDirectDebitAgreement = () => (dispatch: Dispatch<any>) => {
  dispatch(getDirectDebitAgreementPending());

  return shiftPaymentsApi
    .getAgreement('dd')
    .then((agreementResponse) => {
      return dispatch(getDirectDebitAgreementFulfilled(agreementResponse));
    })
    .catch((agreementError) => {
      return dispatch(getDirectDebitAgreementRejected(agreementError));
    });
};

export default {
  setupBankAccountAndSignDirectDebitAgreement,
  updateBankAccountAndDirectDebitSetupComplete,
  getDirectDebitAgreement,
};
