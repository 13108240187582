// @flow
// $FlowFixMe
import { batch } from 'react-redux';
import { type Dispatch } from 'redux';
import { createActions } from 'redux-actions';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';
import { resetAccountSetup } from '../AccountSetupCheck/actions';
import { resetBankLogin } from '../AddBankConnectForm/actions';

export const { setupBankAccountPending, setupBankAccountFulfilled, setupBankAccountRejected } = createActions(
  actionTypes.SETUP_BANK_ACCOUNT_PENDING,
  actionTypes.SETUP_BANK_ACCOUNT_FULFILLED,
  actionTypes.SETUP_BANK_ACCOUNT_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const setupBankAccount =
  (
    gcAccountId: string,
    lbfcId: ?string,
    bankAccount: {
      accountNumber: string,
      accountName: string,
      bsb: string,
      bankAccountId: string,
      select: boolean,
    }
  ) =>
  (dispatch: Dispatch<any>) => {
    dispatch(setupBankAccountPending());

    return shiftPaymentsApi
      .setupBankAccount(gcAccountId, {
        ...bankAccount,
        lbfcId,
      })
      .then((response) => {
        return batch(() => {
          dispatch(resetBankLogin());
          return dispatch(setupBankAccountFulfilled(response));
        });
      })
      .catch((error) => {
        return dispatch(setupBankAccountRejected(error));
      });
  };

const resetAccountSetupAfterBankAccountUpdate = () => (dispatch: Dispatch<any>) => {
  dispatch(resetAccountSetup());
};

export default {
  setupBankAccount,
  resetAccountSetupAfterBankAccountUpdate,
};
