// @flow
import { Chip, Grid, Link, Paper, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button } from '@shiftfinancial/design-system';
import moment from 'moment';

import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { shiftPaymentsApi } from 'src/api';

import PayBalanceConfirmationDialog from './PayBalanceConfirmationDialog';
import { type AccountSetupStatus } from '../../components/AccountSetupCheck/reducer';
import InfoIcon from '../../components/svg/InfoIcon';
import { Permission } from '../../constants';
import { Can } from '../../lib/ability';

import { defaultHandleError } from '../../lib/apiHelpers';
import { selectActiveProfileModeBasedPath } from '../../lib/profileHelper';

import DollarsAndCentsText from '../DollarsAndCentsText';
import { LimitFormValue, LimitIncreaseDialog } from '../LimitIncreaseDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
  heading: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.body2,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.subtitle3.fontSize,
    },
  },
  dollars: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.h3.fontSize,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.h1.fontSize,
    },
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.body2.fontSize,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  totalRemaining: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  amount: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    marginBottom: theme.spacing(0.5),
  },
  changeSettings: {
    marginTop: theme.spacing(3),
  },
  paymentDate: {
    background: theme.typography.body2,
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    marginBottom: theme.spacing(0.5),
  },
  greyCaption: {
    color: theme.palette.grey.text,
    fontSize: '12px',
    lineHeight: '16px',
    marginBottom: theme.spacing(0.5),
  },
  disabledLink: {
    color: theme.palette.grey.text,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  activeLink: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  progressBar: {
    backgroundColor: theme.palette.info.background,
    borderColor: theme.palette.info.borderColor,
    borderRadius: theme.spacing(0.5),
    border: '1px solid',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
    justifyContent: 'left',
    '& .MuiChip-icon': {
      marginTop: '4px',
    },
  },
  availableFundsContainer: {
    marginBottom: theme.spacing(2),
  },
  outstandingContainer: {
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid #E6E8EB',
  },
  outstandingAmount: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    paddingRight: theme.spacing(4),
  },

  nextPaymentContainerWrapper: {
    paddingTop: theme.spacing(2),
  },
  leftContainer: {
    paddingRight: theme.spacing(2),
    width: '50%',
  },
  rightContainer: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));

type Props = {
  totalBalanceRemaining: number,
  isPayBalanceCaseOpen: boolean,
  isPdEligibleForCli: boolean,
  nextScheduledPaymentAmount: number,
  availableBalance: number,
  nextScheduledPaymentDate: ?Date,
  gcAccountId: ?string,
  getBalanceSummary: (gcAccountId: string) => Promise<any>,
  payBalance: (balance: number, bankAccountId: string, gcAccountId: ?string) => Promise<any>,
  bankAccountSetup: AccountSetupStatus,
  activeAccountIsDelinquent: boolean,
  isAccountReady: boolean,
  handleNewInvoice: () => {},
  creditLimit: number,
  isRequiredToConnectBank: Boolean,
  openBankLinkDialog: () => {},
};

const BalanceSummary = (props: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    gcAccountId,
    getBalanceSummary,
    nextScheduledPaymentDate,
    totalBalanceRemaining,
    isPayBalanceCaseOpen,
    isPdEligibleForCli,
    availableBalance,
    payBalance,
    bankAccountSetup,
    activeAccountIsDelinquent,
    isAccountReady,
    nextScheduledPaymentAmount,
    handleNewInvoice,
    creditLimit,
    isRequiredToConnectBank,
    openBankLinkDialog,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [showPayBalance, setShowPayBalance] = useState(false);
  const [shiftBankAccount, setShiftBankAccount] = useState();
  const [showLimitIncreaseDialog, setShowLimitIncreaseDialog] = useState(false);
  const [isRequestAllowed, setIsRequestAllowed] = useState(true);

  const isLimitRequestAllowed = async (gcAccountId: string): boolean => {
    try {
      const { data } = await shiftPaymentsApi.getCreditLimitPendingRequest(gcAccountId);
      if (data) {
        return !data.isPending && !activeAccountIsDelinquent;
      }
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    let mounted = true;
    const setIsAllowedLimitRequest = async (gcAccountId: string): boolean => {
      const isAllowed = await isLimitRequestAllowed(gcAccountId);
      if (mounted) setIsRequestAllowed(isAllowed);
    };

    if (gcAccountId) {
      getBalanceSummary(gcAccountId);
      setIsAllowedLimitRequest(gcAccountId);
    }
    return () => {
      mounted = false;
    };
  }, [gcAccountId]);

  const paymentDate = nextScheduledPaymentDate ? moment(nextScheduledPaymentDate).format('DD MMM YYYY') : '';

  const handlePayBalance = () => {
    if (bankAccountSetup && bankAccountSetup.data && bankAccountSetup.data.length) {
      const bankAccount = bankAccountSetup.data.find((x) => x.isShiftBankAccount);
      if (bankAccount) {
        setShiftBankAccount({
          accountNumber: bankAccount.bankAccountNumber,
          accountName: bankAccount.bankAccountName,
          bsb: bankAccount.bsb,
          salesforceRecordId: bankAccount.salesforceRecordId,
        });
        setShowPayBalance(true);
      }
    } else {
      enqueueSnackbar('Invalid bank account', {
        variant: 'error',
        key: 'payBalanceBankAccountFailed',
      });
    }
  };

  const handleLimitRequestDialog = () => {
    if (isRequiredToConnectBank) {
      openBankLinkDialog();
    } else {
      setShowLimitIncreaseDialog(!showLimitIncreaseDialog);
    }
  };

  const handleLimitRequest = async (limitRequestProps: LimitFormValue) => {
    const { isPreApproved, cliOption, newLimitRequest } = limitRequestProps;
    try {
      await shiftPaymentsApi.requestChangeLimitWithOptions(gcAccountId, newLimitRequest, cliOption, isPreApproved);
      enqueueSnackbar('Please allow up to 3 business days to process the request', {
        variant: 'success',
        key: 'newLimitRequestSuccess',
        persist: true,
      });
      setIsRequestAllowed(false);
    } catch (error) {
      defaultHandleError({ enqueueSnackbar });
    }
    handleLimitRequestDialog();
  };

  return (
    <Paper>
      <Grid className={classes.container} container>
        {isPayBalanceCaseOpen ? (
          <Grid item sm={12} xs={12}>
            <Chip
              className={classes.progressBar}
              label={'Processing repayment'}
              icon={<InfoIcon color={theme.palette.info.main} viewBox='0 0 26 26' style={{ alignSelf: 'center' }} />}
              data-testid='uia-pay-balance-banner'
            />
          </Grid>
        ) : null}
        <Grid className={classes.availableFundsContainer} container direction='column'>
          <Grid item>
            <Typography className={classes.heading}>Available funds</Typography>
          </Grid>
          <Grid className={classes.totalRemaining} item>
            <Typography variant='h1' className={classes.dollars}>
              <DollarsAndCentsText amount={availableBalance} />
            </Typography>
          </Grid>
          <Can I={Permission.Actions.Create} a={Permission.Buyer.Invoice}>
            {
              <Grid item>
                <Button
                  fullWidth
                  className={classes.addInvoiceButton}
                  appearance='primary'
                  data-testid='uia-addAnInvoice'
                  disabled={activeAccountIsDelinquent || !isAccountReady}
                  onClick={handleNewInvoice}
                >
                  Pay a supplier
                </Button>
              </Grid>
            }
          </Can>
        </Grid>
        <Grid container className={classes.outstandingContainer}>
          <Grid item className={classes.leftContainer}>
            <Grid container direction='column'>
              <Grid item>
                <Typography className={classes.greyCaption}>Outstanding</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.amount}>
                  <DollarsAndCentsText amount={totalBalanceRemaining} />
                </Typography>
              </Grid>
              <Grid item>
                {totalBalanceRemaining > 0 &&
                  (isPayBalanceCaseOpen ? (
                    <Typography data-testid='uia-pay-balance-disabled-text' className={classes.disabledLink}>
                      Pay balance
                    </Typography>
                  ) : (
                    <Link component={RouterLink} className={classes.activeLink} data-testid='uia-pay-balance-link' onClick={handlePayBalance}>
                      Pay balance
                    </Link>
                  ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.rightContainer}>
            <Grid container direction='column'>
              <Grid item>
                <Typography className={classes.greyCaption}>Limit</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.amount}>
                  <DollarsAndCentsText amount={creditLimit} />
                </Typography>
              </Grid>
              <Grid item>
                <Can I={Permission.Actions.Create} a={Permission.Buyer.LimitRequest}>
                  {isPdEligibleForCli ? (
                    isRequestAllowed ? (
                      <Link
                        className={classes.activeLink}
                        data-testid='uia-request-limit-link'
                        component={RouterLink}
                        onClick={handleLimitRequestDialog}
                      >
                        Increase limit
                      </Link>
                    ) : (
                      <Typography data-testid='uia-request-limit-disabled-text' className={classes.disabledLink}>
                        Increase limit
                      </Typography>
                    )
                  ) : null}
                </Can>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.nextPaymentContainerWrapper}>
          <Grid item className={classes.leftContainer}>
            <Grid container direction='column'>
              <Grid item>
                <Typography className={classes.greyCaption}>Next repayment</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.amount}>
                  <DollarsAndCentsText amount={nextScheduledPaymentAmount} />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.rightContainer}>
            <Grid container direction='column'>
              <Grid item>
                <Typography className={classes.greyCaption}>Debited on</Typography>
              </Grid>
              <Grid item>
                {' '}
                <Typography className={classes.paymentDate}>{paymentDate}</Typography>
              </Grid>
              <Grid item>
                <Link className={classes.activeLink} component={RouterLink} to={selectActiveProfileModeBasedPath('/settings')}>
                  Settings
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showPayBalance ? (
        <PayBalanceConfirmationDialog
          gcAccountId={gcAccountId}
          bankAccount={shiftBankAccount}
          paymentAmount={totalBalanceRemaining}
          payBalance={payBalance}
          onClose={() => setShowPayBalance(false)}
        />
      ) : null}
      {showLimitIncreaseDialog ? (
        <LimitIncreaseDialog
          currentLimit={creditLimit}
          isRequestAllowed={isRequestAllowed}
          onClose={handleLimitRequestDialog}
          onSubmit={handleLimitRequest}
        />
      ) : null}
    </Paper>
  );
};

export default BalanceSummary;
