// @flow
import React, { useEffect } from 'react';
import { Typography, MenuItem, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';

import { type Account } from '../../types';
import applicationState from '../../lib/applicationState';

import CompanyIcon from '../svg/CompanyIcon';
import StyledMenu from '../StyledMenu';

const AccountMenuItem = withStyles((theme) => ({
  root: {
    minHeight: 70,
    '&[data-selected="true"]': {
      backgroundColor: `${theme.palette.action.selected} !important`,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.black,
      },
    },
    '& .MuiListItemIcon-root': {
      minWidth: 40,
    },
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      fontSize: 12,
      fontWeight: 500,
    },
    '& .MuiListItemText-secondary': {
      color: theme.palette.primary.main,
    },
  },
}))(MenuItem);

const NormalMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.action.selected,
    },
    '& .MuiListItemText-primary': {
      fontSize: 12,
      color: theme.palette.primary.main,
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  desktopAccountName: {
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'left',
  },
  desktopContactName: {
    textAlign: 'left',
  },
  desktopMenuLabel: {
    color: theme.palette.primary.main,
  },
  desktopDropdownButton: {
    padding: theme.spacing(0),
    width: '100%',
    minWidth: 220,
    '& .MuiButton-label': {
      justifyContent: 'flex-end',
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  companyIcon: {
    marginRight: theme.spacing(1.5),
  },
  expandIcon: {
    marginLeft: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.primary.contrastText,
    },
  },
  mobileAccountName: {
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    fontWeight: 500,
    '& .MuiListItemIcon-root': {
      minWidth: 0,
    },
    '& .MuiListItemText-primary': {
      fontSize: 12,
      fontWeight: 500,
      color: theme.palette.primary.contrastText,
    },
    '& .MuiListItemText-secondary': {
      color: theme.palette.primary.main,
    },
    '&[data-selected="true"]': {
      backgroundColor: `${theme.palette.action.selectedDark} !important`,
    },
  },
  mobileContactName: {
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
  },
  mobileContainer: {
    margin: '0 !important',
    '& .MuiExpansionPanelSummary-root': {
      padding: 0,
    },
  },
  mobileAccountList: {
    backgroundColor: `${theme.palette.common.darkerGreen} !important`,
  },
}));

type Props = {
  accounts: Array<Account>,
  firstName: string,
  lastName: string,
  activeAccountIndex: number,
  updateActiveAccount: (index: number) => void,
  history: RouterHistory,
  isCollapsible: boolean,
  setIsAllKycCompletedWithSessionStorage: (isAllKycCompleted: boolean) => void,
  isAllSetupCompleteRequirement: boolean,
  updateOpenTradeAccount: ({ isOpenTradeAccount: boolean, attempt: number }) => void,
};

const AccountSelectMenu = ({
  accounts,
  firstName,
  lastName,
  activeAccountIndex,
  updateActiveAccount,
  history,
  isCollapsible,
  setIsAllKycCompletedWithSessionStorage,
  isAllSetupCompleteRequirement,
  updateOpenTradeAccount,
}: Props) => {
  useEffect(() => {
    const currentAccount = applicationState.get().selectedAccountId;
    if (!currentAccount) {
      const defaultAccountIndex = accounts && accounts.findIndex((x) => x.isDirect);
      if (defaultAccountIndex != null && defaultAccountIndex !== -1) {
        updateActiveAccount(0);
      }
    }
  }, [accounts, updateActiveAccount]);

  const classes = useStyles();
  const [stateAnchorEl, setStateAnchorEl] = React.useState(null);
  const [stateCollapsed, setStateCollapsed] = React.useState(true);

  if (!accounts || !accounts.length) {
    return null;
  }
  const activeAccount = accounts[activeAccountIndex];
  const isOpen = isCollapsible ? !stateCollapsed : Boolean(stateAnchorEl);

  const handleDropdownButtonClick = (event) => {
    setStateAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setStateAnchorEl(null);
  };

  const handleAccountMenuItemClick = (event, index) => {
    if (activeAccountIndex !== index) {
      updateActiveAccount(index);
      updateOpenTradeAccount({ isOpenTradeAccount: false, attempt: 0 });
      setIsAllKycCompletedWithSessionStorage(false);
      history.push('/');
    } else if (isAllSetupCompleteRequirement) {
      if (activeAccount && activeAccount.isSupplier) {
        history.push('/settings');
      } else {
        history.push('/buyer/settings');
      }
    }
    setStateAnchorEl(null);
  };

  const handleLogout = () => {
    history.push('/logout');
  };

  const handleClick = () => {
    setStateCollapsed(!stateCollapsed);
  };

  if (isCollapsible) {
    return (
      <List component='nav' className={classes.root}>
        <ListItem button onClick={handleClick} data-testid='uia-activeaccount'>
          <CompanyIcon className={classes.companyIcon} />
          <div style={{ width: '70%' }}>
            <Typography className={classes.mobileAccountName}>{activeAccount.name}</Typography>
            <Typography variant='subtitle1' className={classes.mobileContactName}>
              {`${firstName} ${lastName}`}
            </Typography>
          </div>
          {isOpen ? <ExpandLessIcon className={classes.expandIcon} /> : <ExpandMoreIcon className={classes.expandIcon} />}
        </ListItem>
        <Divider />
        <Collapse in={isOpen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding className={classes.mobileAccountList} data-testid='uia-accounts'>
            {accounts.map((account, index) => {
              const isSelected = activeAccountIndex === index;
              return (
                <React.Fragment key={account.gcAccountId}>
                  <ListItem
                    button
                    className={classes.mobileAccountName}
                    data-selected={isSelected}
                    onClick={(event) => handleAccountMenuItemClick(event, index)}
                  >
                    <ListItemIcon>
                      <CompanyIcon className={classes.companyIcon} />
                    </ListItemIcon>
                    <ListItemText primary={account.name} secondary={isAllSetupCompleteRequirement && isSelected ? 'Settings' : null} />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })}
            <ListItem button className={classes.mobileAccountName} onClick={handleLogout} data-testid='uia-logout'>
              <ListItemText primary='Logout' />
            </ListItem>
          </List>
        </Collapse>
      </List>
    );
  }

  return (
    <React.Fragment>
      <Button onClick={handleDropdownButtonClick} disableRipple className={classes.classesDropdownButton} data-testid='uia-showMenu'>
        <CompanyIcon className={classes.companyIcon} />
        <div>
          <Typography className={classes.desktopAccountName}>{activeAccount.name}</Typography>
          <Typography variant='subtitle1' className={classes.desktopContactName}>
            {`${firstName} ${lastName}`}
          </Typography>
        </div>
        {isOpen ? <ExpandLessIcon className={classes.expandIcon} /> : <ExpandMoreIcon className={classes.expandIcon} />}
      </Button>
      <StyledMenu data-testid='uia-menu' anchorEl={stateAnchorEl} keepMounted open={isOpen} onClose={handleMenuClose}>
        {accounts.map((account, index) => {
          const isSelected = activeAccountIndex === index;
          return (
            <AccountMenuItem
              key={account.gcAccountId}
              onClick={(event) => handleAccountMenuItemClick(event, index)}
              className={classes.desktopAccountName}
              data-selected={isSelected}
            >
              <ListItemIcon>
                <CompanyIcon />
              </ListItemIcon>
              <ListItemText primary={account.name} secondary={isAllSetupCompleteRequirement && isSelected ? 'Settings' : null} />
            </AccountMenuItem>
          );
        })}
        <NormalMenuItem onClick={handleLogout} data-testid='uia-logout'>
          <ListItemText primary='Logout' />
        </NormalMenuItem>
      </StyledMenu>
    </React.Fragment>
  );
};

export default withRouter(AccountSelectMenu);
