// @flow
import React from 'react';
import type { FormikState, FormikHelpers } from 'formik';

import NumberFormatInput from '../NumberFormatInput';
import FormikField from './FormikField';
import type { Field } from './types';

type Props = {
  field: Field,
  form: FormikState<any> & FormikHelpers<any>,
  showNoError: boolean,
};

const FormikNumberFormatInputField = ({ field, form, showNoError, ...other }: Props) => (
  <FormikField field={field} form={form} showNoError={showNoError}>
    <NumberFormatInput
      onChange={({ target }) => {
        form.setFieldValue(field.name, target.value, true);
        form.setTouched({ [field.name]: true });
      }}
      // $FlowFixMe
      {...other}
      error={!!form.touched[field.name] && !!form.errors[field.name]}
    />
  </FormikField>
);

export default FormikNumberFormatInputField;
