export default {
  palette: {
    type: 'light',
    common: {
      white: '#FFF',
      black: '#2E384D',
      charcoalBlack: '#1B3642',
      paleOrange: '#FCF7E9',
      orange: '#e5a923', // this represents gold as in the DSM
      lightGreen: '#E7F6F9',
      mediumGreen: '#1B3642', // this represents midnightBlue as in the DSM
      darkGreen: '#162E39',
      darkerGreen: '#062526',
      lightBlue: '#FAFCFF',
      blueGreen: '#106E78',
      mediumBlue: '#2D444F',
      jaggedIceBlue: '#CBEBED',
      nileBlue: '#194C57',
      ripTide: '#8ADDE2',
      robinBlue: '#00B6C0',
      ghostWhite: '#F9F9FA',
      lightOrange: '#FFE5BE',
      lightYellow: '#FCF6E9',
      bright: '#E8EBF2',
    },
    primary: {
      main: '#00B7C1',
      dark: '#01A8B1',
      contrastText: '#FFF',
      oysterBay: '#CCF1F3',
      snowDrift: '#F2FBFC',
      aquamarineBlue: '#80DBE0',
      lillyWhite: '#E6F8F9',
    },
    secondary: {
      main: '#00B7C1',
      light: '#E7F8F9',
      lighter: '#FEFBF4',
      contrastText: '#1E3641',
      solitude: '#E6E8EB',
    },
    success: {
      main: '#00bf0d',
      light: '#E0F7E0',
      lighter: '#F3FAF3',
      contrast: '#B9DCB9',
    },
    warning: {
      background: '#fffdf6',
      main: '#E19B01',
      light: '#FAEED3',
      contrast: '#FAEED3',
      deepRed: '#F35454',
    },
    info: {
      main: '#4EBFDC',
      light: '#E7F5FF',
      contrast: '#A7D5F4',
      background: '#E3F5FB',
      borderColor: '#ACDDF6',
    },
    error: {
      main: '#f20000',
      light: '#FFDEDE',
      contrast: '#EAB9BC',
    },
    grey: {
      background: '#F4F7FB',
      200: '#F3F5F5',
      300: '#E4E8F0',
      light: '#899CA4',
      text: '#828D99',
      subMessage: '#747474',
      ghostWhite: '#FAFAFB',
      lightSlateGrey: '#828D99',
      faintLineGrey: '#CDD1D6',
    },
    action: {
      hover: '#FCF6E9',
      selected: '#FAEED3',
      selectedDark: '#106E78',
    },
    selectPanel: {
      default: {
        backgroundColor: '#FCFCFC',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#E9F0F5',
        borderRadius: '4px',
        boxShadow: 'none !important',
      },
      selected: {
        backgroundColor: '#F2FCFC',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#D8F0F1',
        borderRadius: '4px',
        boxShadow: 'none !important',
      },
    },
    datePicker: {
      selectedHoverBorder: '#EDC365',
    },
  },
  typography: {
    fontFamily: ['Rubik'],
    h1: {
      '@media (max-width:599px)': {
        fontSize: '20px !important',
      },
      '@media (min-width:600px)': {
        fontSize: '24px !important',
      },
      fontWeight: 500,
      lineHeight: 'inherit',
    },
    h2: {
      fontSize: '20px',
      fontWeight: 500,
    },
    h3: {
      fontSize: '18px',
      fontWeight: 500,
    },
    h4: {
      fontSize: '18px',
    },
    h5: { fontSize: '16px', fontWeight: 500 },
    h6: {
      fontSize: '12px',
    },
    body1: {
      fontSize: '14px',
    },
    body2: {
      fontSize: '12px',
    },
    button: {},
    subtitle1: {
      fontSize: '11px',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 500,
    },
    subtitle3: {
      fontSize: '16px',
    },
  },
};
