// @flow
import { PARTITION } from './constants';
import type { ReduxState } from './reducer';

const selectPartition = (state: any): ReduxState => state[PARTITION];
const selectStep = (state: any) => selectPartition(state).step;
const selectMobileNumber = (state: any) => selectPartition(state).mobileNumber;
const selectInputMobileNumberValue = (state: any) => selectPartition(state).inputMobileNumberValue;
const selectCodeExpiry = (state: any) => selectPartition(state).codeExpiry;

export default {
  selectPartition,
  selectStep,
  selectMobileNumber,
  selectInputMobileNumberValue,
  selectCodeExpiry,
};
