// @flow
/* istanbul ignore file */
import Axios, { type Method, type ResponseType } from 'axios';
import Auth from './auth';
import { getCorrelationId } from '../lib/telemetryUtils';

const correlationId = getCorrelationId();

export type HttpRequest = {
  url: string,
  apiVersion?: ?string,
  data?: any,
  contentType?: string,
  accept?: string,
  cacheControl?: string,
  headers?: any,
  responseType?: ResponseType,
  axiosInstance?: any,
  sendAuthToken?: boolean,
  isPolling?: boolean,
  requestId?: ?string,
};

export type InternalHttpRequest = {
  ...HttpRequest,
  method: Method,
};

function sendRequest({
  url,
  method,
  apiVersion,
  data = undefined,
  contentType = 'application/json',
  accept = 'application/json',
  cacheControl = 'no-cache',
  headers = undefined,
  responseType = undefined,
  axiosInstance = undefined,
  sendAuthToken = true,
  isPolling = false,
  requestId,
}: InternalHttpRequest) {
  const customHeaders = {
    'x-correlation-id': correlationId,
    ...headers,
  };

  if (apiVersion) {
    customHeaders['api-version'] = apiVersion;
  }

  if (contentType) {
    customHeaders['content-type'] = contentType;
  }

  if (accept) {
    customHeaders.accept = accept;
  }

  if (cacheControl) {
    customHeaders['cache-control'] = cacheControl;
  }

  if (sendAuthToken && Auth.isLoggedIn()) {
    customHeaders.Authorization = `Bearer ${Auth.getAccessToken()}`;
  }

  if (isPolling) {
    customHeaders['x-polling'] = '1';
  }

  if (requestId) {
    customHeaders['x-request-id'] = requestId;
  }

  const config = {
    url,
    method,
    data,
    headers: customHeaders,
    responseType,
  };

  return (axiosInstance || Axios: any).request(config);
}

function get(request: HttpRequest) {
  return this.sendRequest({
    ...request,
    method: 'GET',
  });
}

function post(request: HttpRequest) {
  return this.sendRequest({
    ...request,
    method: 'POST',
  });
}

function deleteRequest(request: HttpRequest) {
  return this.sendRequest({
    ...request,
    method: 'DELETE',
  });
}

function patch(request: HttpRequest) {
  return this.sendRequest({
    ...request,
    method: 'PATCH',
  });
}

export default {
  sendRequest,
  get,
  post,
  delete: deleteRequest,
  patch,
};
