// @flow
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { AdapterLink } from '../../lib/materialUiUtils';
import shiftPaymentsApi from '../../api/shiftPaymentsApi';
import AutoSuggestInput from '../AutoSuggestInput';
import { selectActiveProfileModeBasedPath } from '../../lib/profileHelper';
import { useHistory } from 'react-router-dom';

type Props = {
  bank: any,
  setBank: (bank: any) => void,
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
      maxWidth: 535,
    },
    container: {
      position: 'relative',
    },
    searchContainer: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    searchLabel: {
      color: theme.palette.common.black,
      fontSize: '16px',
      fontWeight: 500,
      marginBottom: theme.spacing(1.5),
    },
    searchButton: {
      height: '100%',
      padding: theme.spacing(1, 1),
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
    searchField: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(2),
      },
    },
  };
});

export default function BankSearch(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const [stateSuggestions, setSuggestions] = useState([]);

  const handleSuggestionSelected = (event, options) => {
    props.setBank(options.suggestion);
  };

  const getSuggestions = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const suggestions = await shiftPaymentsApi.searchBanksFromBankData(inputValue);

    if (suggestions.data) {
      const enriched = suggestions.data.result.map((x) => {
        return {
          displayText: x.bankName,
          ...x,
        };
      });
      setSuggestions(enriched);
      return;
    }
    setSuggestions([]);
  };

  const routeBankFlow = () => {
    if (props.bank.isSupported) {
      history.push(selectActiveProfileModeBasedPath(`/settings/add-bank/login/${props.bank.bankId}`));
    } else {
      history.push(`/buyer/settings/add-bank/manual-statement/${props.bank.bankId}`);
    }
  };

  return (
    <div className={classes.root}>
      <InputLabel>
        <Typography variant='h6' component='label' className={classes.searchLabel}>
          Can&apos;t see your bank? Try our bank search!
        </Typography>
      </InputLabel>
      <Grid container className={classes.searchContainer}>
        <Grid item xs={12} sm={10} className={classes.searchField}>
          <AutoSuggestInput
            data-testid='autosuggest-banks'
            getSuggestions={getSuggestions}
            getSuggestionValue={(x) => x.displayText}
            onSuggestionSelected={(event, options) => handleSuggestionSelected(event, options)}
            suggestions={stateSuggestions}
            delay={1000}
            autoFocus
            inputProps={{
              placeholder: "Start typing your bank's name...",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            variant='contained'
            color='primary'
            className={classes.searchButton}
            fullWidth
            disabled={!props.bank.bankId}
            component={AdapterLink}
            onClick={() => routeBankFlow()}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
