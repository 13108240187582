import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router';

import SingleSignOnProvider from './components/SingleSignOnProvider';
import { UseWelcomePageRedirectToken } from './components/UseWelcomePageRedirectToken';
import GlobalWrapper from './GlobalWrapper';
import ErrorPage from './pages/ErrorPage';
import { Login } from './pages/Login';
import Logout from './pages/Logout';
import MessagePage from './pages/MessagePage';
import NoMatchPage from './pages/NoMatchPage';
import { SignMeUp } from './pages/SignMeUp';
import SecuredApp from './SecuredApp';

const App = () => {
  return (
    <React.StrictMode>
      <Helmet>
        <title>Shift Trade</title>
      </Helmet>
      <GlobalWrapper>
        <UseWelcomePageRedirectToken />
        <Switch>
          <Route exact path='/login' component={Login} />
          <Route exact path='/logout' component={Logout} />
          <Route exact path='/sign-me-up' component={SignMeUp} />

          <Route exact path='/404' component={NoMatchPage} />
          <Route exact path='/500' component={ErrorPage} />
          <Route exact path='*/message' component={MessagePage} />

          <SingleSignOnProvider>
            <Route path='/' component={SecuredApp} />
          </SingleSignOnProvider>
        </Switch>
      </GlobalWrapper>
    </React.StrictMode>
  );
};

export default App;
