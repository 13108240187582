// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';
import fetchiApi from '../../../api/fetchiApi';
import { traceInfo } from '../../../lib/telemetryUtils';

const {
  searchCustomersPending,
  searchCustomersFulfilled,
  searchCustomersRejected,
  addPaymentPending,
  addPaymentFulfilled,
  addPaymentRejected,
  validatePaymentBuyerPending,
  validatePaymentBuyerFulfilled,
  validatePaymentBuyerRejected,
  fileDeletePending,
  fileDeleteFulfilled,
  fileDeleteRejected,
  getBuyerDetailPending,
  getBuyerDetailFulfilled,
  getBuyerDetailRejected,
  fileUploadPending,
  fileUploadFulfilled,
  fileUploadRejected,
} = createActions(
  actionTypes.SEARCH_CUSTOMERS_PENDING,
  actionTypes.SEARCH_CUSTOMERS_FULFILLED,
  actionTypes.SEARCH_CUSTOMERS_REJECTED,
  actionTypes.ADD_PAYMENT_PENDING,
  actionTypes.ADD_PAYMENT_FULFILLED,
  actionTypes.ADD_PAYMENT_REJECTED,
  actionTypes.VALIDATE_PAYMENT_BUYER_PENDING,
  actionTypes.VALIDATE_PAYMENT_BUYER_FULFILLED,
  actionTypes.VALIDATE_PAYMENT_BUYER_REJECTED,
  actionTypes.FILE_DELETE_PENDING,
  actionTypes.FILE_DELETE_FULFILLED,
  actionTypes.FILE_DELETE_REJECTED,
  actionTypes.GET_BUYER_DETAIL_PENDING,
  actionTypes.GET_BUYER_DETAIL_FULFILLED,
  actionTypes.GET_BUYER_DETAIL_REJECTED,
  actionTypes.FILE_UPLOAD_PENDING,
  actionTypes.FILE_UPLOAD_FULFILLED,
  actionTypes.FILE_UPLOAD_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const searchCustomers = (gcAccountId: string, search: string) => (dispatch: Dispatch<any>) => {
  dispatch(searchCustomersPending());

  return shiftPaymentsApi
    .accountsQuickSearch(gcAccountId, search, 'Buyer')
    .then((response) => {
      return dispatch(searchCustomersFulfilled(response));
    })
    .catch((error) => {
      return dispatch(searchCustomersRejected(error));
    });
};

const addPayment =
  (
    supplierGcAccountId: string,
    customerGcAccountId: string,
    orderId: string,
    invoiceNumber: string,
    invoiceAmount: number,
    invoiceFiles: Array<any>,
    fileGroupId: string,
    addPaymentRequestId: String,
    adHocTerm?: number,
    adHocTermPeriod?: string
  ) =>
  (dispatch: Dispatch<any>) => {
    dispatch(addPaymentPending());

    window.dispatchEvent(new Event('fetchStart'));
    const invoiceFileNames = invoiceFiles.map((file) => file.name);
    traceInfo("1 - Calling fetch api for addPaymentRequestId=" + addPaymentRequestId + " - UTC time is:" + new Date().toISOString());

    return fetchiApi
      .addPayment(
        supplierGcAccountId,
        customerGcAccountId,
        orderId,
        invoiceNumber,
        invoiceAmount,
        invoiceFileNames,
        fileGroupId,
        addPaymentRequestId,
        adHocTerm,
        adHocTermPeriod
      )
      .then((response) => {
        traceInfo("3 - Handling immediate response from fetch api for addPaymentRequestId=" + addPaymentRequestId + " - UTC time is:" + new Date().toISOString()); 
        if (!response.ok && response.status !== 400) {
          return response;
        }
        return response.json();
      })
      .then((response) => dispatch(addPaymentFulfilled(response)))
      .catch((error) => {
        traceInfo("3 - Handling immediate error response from fetch api for addPaymentRequestId=" + addPaymentRequestId + " - UTC time is:" + new Date().toISOString()); 
        return dispatch(addPaymentRejected(error));
      })
      .finally(() => {
        window.dispatchEvent(new Event('fetchEnd'));
        traceInfo("4 - Finished calling fetch api for addPaymentRequestId=" + addPaymentRequestId + " - UTC time is:" + new Date().toISOString());
      });
  };

const validatePaymentBuyer = (supplierGcAccountId: string, buyerGcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(validatePaymentBuyerPending());

  return shiftPaymentsApi
    .validatePaymentBuyer(supplierGcAccountId, buyerGcAccountId)
    .then((response) => {
      return dispatch(validatePaymentBuyerFulfilled(response));
    })
    .catch((error) => {
      return dispatch(validatePaymentBuyerRejected(error));
    });
};

const deleteFiles = (gcAccountId: string, fileGroupId: string, files: Array<any>) => (dispatch: Dispatch<any>) => {
  dispatch(fileDeletePending());

  if (files && files.length) {
    const promises = files.map((file) => {
      return shiftPaymentsApi.removeInvoiceFile(gcAccountId, fileGroupId, file.name);
    });

    return (
      Promise.all(promises)
        // eslint-disable-next-line no-unused-vars
        .then((response) => dispatch(fileDeleteFulfilled(response)))
        .catch((error) => dispatch(fileDeleteRejected(error)))
    );
  }

  return Promise.resolve();
};

const getBuyerDetail = (supplierGcAccountId: string, buyerGcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(getBuyerDetailPending());

  return shiftPaymentsApi
    .getBuyerDetail(supplierGcAccountId, buyerGcAccountId)
    .then((response) => {
      return dispatch(getBuyerDetailFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getBuyerDetailRejected(error));
    });
};

const uploadFile = (gcAccountId: string, fileGroupId: string, file: any) => (dispatch: Dispatch<any>) => {
  dispatch(fileUploadPending());

  return shiftPaymentsApi
    .uploadInvoiceFile(gcAccountId, fileGroupId, file)
    .then((response) => dispatch(fileUploadFulfilled(response)))
    .catch((error) => dispatch(fileUploadRejected(error)));
};

const deleteInvoiceFile = (gcAccountId: string, fileGroupId: string, file: any) => (dispatch: Dispatch<any>) => {
  dispatch(fileDeletePending());

  return shiftPaymentsApi
    .removeInvoiceFile(gcAccountId, fileGroupId, file.name)
    .then((response) => dispatch(fileDeleteFulfilled(response)))
    .catch((error) => dispatch(fileDeleteRejected(error)));
};

export default {
  searchCustomers,
  addPayment,
  validatePaymentBuyer,
  deleteFiles,
  getBuyerDetail,
  uploadFile,
  deleteInvoiceFile,
};
