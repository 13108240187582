// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';

const {
  addStaffsPending,
  addStaffsFulfilled,
  addStaffsRejected,
  setStaffPageVisibilityPending,
  setStaffPageVisibilityFulfilled,
  setStaffPageVisibilityRejected,
} = createActions(
  actionTypes.ADD_STAFFS_PENDING,
  actionTypes.ADD_STAFFS_FULFILLED,
  actionTypes.ADD_STAFFS_REJECTED,
  actionTypes.SET_STAFF_PAGE_VISIBILITY_PENDING,
  actionTypes.SET_STAFF_PAGE_VISIBILITY_FULFILLED,
  actionTypes.SET_STAFF_PAGE_VISIBILITY_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const addStaffs = (staffs: Array<any>, gcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(addStaffsPending());
  shiftPaymentsApi
    .addStaff(staffs, gcAccountId)
    .then((response) => {
      return dispatch(addStaffsFulfilled(response));
    })
    .catch((error) => {
      return dispatch(addStaffsRejected(error));
    });
};

const setStaffPageVisibility = (gcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(setStaffPageVisibilityPending());
  shiftPaymentsApi
    .setStaffPageVisibility(gcAccountId)
    .then((response) => {
      return dispatch(setStaffPageVisibilityFulfilled(response));
    })
    .catch((error) => {
      return dispatch(setStaffPageVisibilityRejected(error));
    });
};

export default {
  addStaffs,
  setStaffPageVisibility,
};
