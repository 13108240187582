// @flow
import * as React from 'react';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { type RouterHistory, withRouter } from 'react-router-dom';

import { getAppInsights } from '../lib/telemetryUtils';

type Props = {
  globalError: boolean,
  children: React.Node,
  history: RouterHistory,
};

type State = {
  hasError: boolean,
};

class GlobalErrorBoundary extends React.Component<Props, State> {
  static defaultProps = {
    globalError: false,
    // $FlowFixMe
    children: undefined,
  };

  constructor() {
    super();
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    try {
      const appInsights = getAppInsights();
      appInsights.trackException({
        error,
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: { ...info },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }

  render() {
    if (this.props.globalError || this.state.hasError) {
      this.setState({ hasError: false });
      this.props.history.push('/500');
      return null;
    }

    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default withRouter(GlobalErrorBoundary);
