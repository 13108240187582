export const PARTITION = 'addBankSelectAccountForm';
export const ACTION_PREFIX = 'ADD_BANK_SELECT_ACCOUNT_FORM';

export const actionTypes = {
  GET_DIRECT_DEBIT_AGREEMENT_PENDING: 'GET_DIRECT_DEBIT_AGREEMENT_PENDING',
  GET_DIRECT_DEBIT_AGREEMENT_FULFILLED: 'GET_DIRECT_DEBIT_AGREEMENT_FULFILLED',
  GET_DIRECT_DEBIT_AGREEMENT_REJECTED: 'GET_DIRECT_DEBIT_AGREEMENT_REJECTED',
  SETUP_BANK_ACCOUNT_PENDING: 'SETUP_BANK_ACCOUNT_PENDING',
  SETUP_BANK_ACCOUNT_FULFILLED: 'SETUP_BANK_ACCOUNT_FULFILLED',
  SETUP_BANK_ACCOUNT_REJECTED: 'SETUP_BANK_ACCOUNT_REJECTED',
};
