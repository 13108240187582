// @flow
import React, { useEffect } from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { AdapterLink } from '../../lib/materialUiUtils';
import { selectActiveProfileModeBasedPath } from '../../lib/profileHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(4),
    maxWidth: '540px',
  },
  popularBankNavItem: {
    textAlign: 'center',
    border: `1px solid ${theme.palette.grey['300']}`,
    borderRadius: theme.shape.borderRadius,
    '&:hover, &:focus': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  image: {
    width: '100%',
    height: 'auto',
    padding: theme.spacing(1),
  },
}));

type Props = {
  popularBanks: any[],
  getPopularBanks: () => Promise<any>,
};

const BankConnectCards = (props: Props) => {
  const classes = useStyles();
  const { popularBanks, getPopularBanks } = props;

  useEffect(() => {
    getPopularBanks();
  }, []);

  return (
    <div className={classes.root}>
      <NoSsr>
        <Grid container spacing={2}>
          {popularBanks ? popularBanks.map((popularBank) => {
              return (
                <Grid key={popularBank.bankId} item xs={6} sm={4}>
                  <Box
                    display='flex'
                    className={classes.popularBankNavItem}
                    component={AdapterLink}
                    to={{
                      pathname: selectActiveProfileModeBasedPath(`/settings/add-bank/login/${popularBank.bankId}`),
                    }}
                  >
                    <img src={popularBank.bankIcon} className={classes.image} alt={popularBank.bankName} />
                  </Box>
                </Grid>
              );
            }) : null}
        </Grid>
      </NoSsr>
    </div>
  );
};

export default withRouter<Props>(withSnackbar(BankConnectCards));
