export const ACTION_PREFIX = 'ADD_PAYMENT';
export const PARTITION = 'addPayment';

export const actionTypes = {
  SEARCH_CUSTOMERS_PENDING: 'SEARCH_CUSTOMERS_PENDING',
  SEARCH_CUSTOMERS_FULFILLED: 'SEARCH_CUSTOMERS_FULFILLED',
  SEARCH_CUSTOMERS_REJECTED: 'SEARCH_CUSTOMERS_REJECTED',
  ADD_PAYMENT_PENDING: 'ADD_PAYMENT_PENDING',
  ADD_PAYMENT_FULFILLED: 'ADD_PAYMENT_FULFILLED',
  ADD_PAYMENT_REJECTED: 'ADD_PAYMENT_REJECTED',
  REMOVE_UPLOADED_FILES_PENDING: 'REMOVE_UPLOADED_FILES_PENDING',
  REMOVE_UPLOADED_FILES_FULFILLED: 'REMOVE_UPLOADED_FILES_FULFILLED',
  REMOVE_UPLOADED_FILES_REJECTED: 'REMOVE_UPLOADED_FILES_REJECTED',
  VALIDATE_PAYMENT_BUYER_PENDING: 'VALIDATE_PAYMENT_BUYER_PENDING',
  VALIDATE_PAYMENT_BUYER_FULFILLED: 'VALIDATE_PAYMENT_BUYER_FULFILLED',
  VALIDATE_PAYMENT_BUYER_REJECTED: 'VALIDATE_PAYMENT_BUYER_REJECTED',
  FILE_DELETE_PENDING: 'FILE_DELETE_PENDING',
  FILE_DELETE_FULFILLED: 'FILE_DELETE_FULFILLED',
  FILE_DELETE_REJECTED: 'FILE_DELETE_REJECTED',
  GET_BUYER_DETAIL_PENDING: 'GET_BUYER_DETAIL_PENDING',
  GET_BUYER_DETAIL_FULFILLED: 'GET_BUYER_DETAIL_FULFILLED',
  GET_BUYER_DETAIL_REJECTED: 'GET_BUYER_DETAIL_REJECTED',
  FILE_UPLOAD_PENDING: 'FILE_UPLOAD_PENDING',
  FILE_UPLOAD_FULFILLED: 'FILE_UPLOAD_FULFILLED',
  FILE_UPLOAD_REJECTED: 'FILE_UPLOAD_REJECTED',
};
