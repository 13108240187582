import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from '@reduxjs/toolkit';
import Component from './Component';
import actions from './actions';
import selectors from './selectors';
import initialDataSelectors from '../../../components/InitialData/selectors';

const mapStateToProps = createSelector(
  selectors.selectSupplierRequest,
  initialDataSelectors.selectSelectedGcAccountId,
  (detail, buyerGcAccountId) => ({
    detail,
    buyerGcAccountId,
  })
);

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Component);
