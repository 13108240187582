/* eslint-disable react/display-name */
// @flow
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Paper, Typography } from '@material-ui/core';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import actions from './actions';
import { Permission, STANDARD_DATE_FORMAT } from '../../constants';
import { Can } from '../../lib/ability';
import { enrichColumnsWithBodyRenders, getColumnIndex } from '../../lib/dataGridUtils';
import DollarsAndCentsText from '../DollarsAndCentsText';
import RemoteDataGrid from '../RemoteDataGrid';
import ChevronRight from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 1, 2, 1),
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  mobileBox: { margin: 0, padding: 0 },
  mobileRow: { marginBottom: theme.spacing(1) },
  mobileFont: { color: theme.palette.common.mediumGreen },
  mobileChevron: {
    alignSelf: 'center',
    fontSize: theme.spacing(3.5),
  },
}));

const nextRepaymentDate = 'nextRepaymentDate';
const supplierName = 'supplierName';
const totalOutstandingAmount = 'totalOutstandingAmount';
const nextRepaymentAmount = 'nextRepaymentAmount';
const id = 'id';

const columns: Array<ColumnOptions> = [
  {
    name: nextRepaymentDate,
    label: 'Next repayment',
    options: {
      sort: false,
    },
  },
  {
    name: supplierName,
    label: 'Supplier',
    options: {
      sort: false,
    },
  },
  {
    name: totalOutstandingAmount,
    label: 'Total outstanding',
    options: {
      sort: false,
    },
  },
  {
    name: nextRepaymentAmount,
    label: 'Repayment amount',
    options: {
      sort: false,
    },
  },
  {
    name: id,
    label: ' ',
    options: {
      sort: false,
    },
  },
];

const nextRepaymentDateIndex = getColumnIndex(columns, nextRepaymentDate);
const supplierNameIndex = getColumnIndex(columns, supplierName);
const nextRepaymentAmountIndex = getColumnIndex(columns, nextRepaymentAmount);
const idIndex = getColumnIndex(columns, id);

type Props = {
  gcAccountId: string,
};

const ExtendableInvoices = ({ gcAccountId }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getInvoicesData = async () => {
    return dispatch(actions.getExtendableInvoices(gcAccountId));
  };

  const extendLink = (id: string) => {
    return (
      <Can I={Permission.Actions.Create} a={Permission.Buyer.Invoice}>
        {() => (
          <Link component={RouterLink} data-testid='uia-dashboard-extend-link' to={`/buyer/payments/${id}/extend`}>
            Extend
          </Link>
        )}
      </Can>
    );
  };

  const desktopColumnCustomBodyRenders = {
    nextRepaymentDate: (value) => <Typography>{moment(value).format(STANDARD_DATE_FORMAT)}</Typography>,
    supplierName: (value) => <Typography>{value}</Typography>,
    totalOutstandingAmount: (value) => (
      <Typography>
        <DollarsAndCentsText amount={value} />
      </Typography>
    ),
    nextRepaymentAmount: (value) => (
      <Typography>
        <DollarsAndCentsText amount={value} />
      </Typography>
    ),
    id: (value) => extendLink(value),
  };

  const mobileRowRender = (rowData) => {
    const nextRepaymentDate = moment(rowData[nextRepaymentDateIndex]).format(STANDARD_DATE_FORMAT);
    return (
      <Grid item className={classes.mobileBox}>
        <Link component={RouterLink} data-testid='uia-dashboard-extend-link' to={`/buyer/payments/${rowData[idIndex]}/extend`}>
          <Grid container data-testid='uia-extendable-mobile-view'>
            <Grid item xs={11} className={classes.mobileFont}>
              <Grid item xs={12} className={classes.mobileRow}>
                <Typography variant='h5'>{rowData[supplierNameIndex]}</Typography>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant='body2'>{'Next repayment'}</Typography>
                  <Typography variant='h5'>{nextRepaymentDate}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='body2'>Repayment amount</Typography>
                  <Typography variant='h5'>
                    <DollarsAndCentsText amount={rowData[nextRepaymentAmountIndex]} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} className={classes.mobileChevron}>
              <ChevronRight fontSize='inherit' style={{ verticalAlign: 'middle' }} />
            </Grid>
          </Grid>
        </Link>
      </Grid>
    );
  };

  return (
    gcAccountId && (
      <div className={classes.container}>
        <Paper>
          <Typography variant='h5' className={classes.title}>
            Extend repayments
          </Typography>
          <RemoteDataGrid
            getData={getInvoicesData}
            columns={enrichColumnsWithBodyRenders(columns, desktopColumnCustomBodyRenders)}
            data-testid='uia-extendable-invoices-grid'
            mobileRowRender={mobileRowRender}
            options={{ pagination: false, sort: false }}
          />
        </Paper>
      </div>
    )
  );
};

export default ExtendableInvoices;
