// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Paper, Grid, Typography } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import InfoIcon from '../../svg/InfoIcon';
import PopupWidgetIcon from '../../svg/PopupWidgetIcon';
import InlineWidgetIcon from '../../svg/InlineWidgetIcon';
import FullPageWidgetIcon from '../../svg/FullPageWidgetIcon';
import EmbedCode from '../EmbedCode';
import ImagePreview from '../../ImagePreview';
import initialDataSelectors from '../../InitialData/selectors';
import { WidgetTypeValues } from '../../../api/widgetsApi';
import { useEmbedExplainer } from './hooks';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 4, 3),
  },
  previewContainer: {
    backgroundColor: theme.palette.grey['200'],
    padding: theme.spacing(4),
  },
  boxWithSeparator: {
    paddingBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
  },
  marginBetweenContents: {
    marginBottom: theme.spacing(1.5),
  },
  icon: {
    width: 18,
    height: 18,
  },
  iconBox: {
    display: 'flex',
  },
  iconText: {
    paddingLeft: theme.spacing(1),
  },
}));

type Props = {
  term: number,
  termPeriod: string,
};

const ExplainerWidget = (props: Props) => {
  const { term, termPeriod } = props;
  const classes = useStyles();
  const theme = useTheme();
  const gcAccountId = useSelector(initialDataSelectors.selectSelectedGcAccountId);
  const embedInlineWidget = useEmbedExplainer(gcAccountId, term, termPeriod, WidgetTypeValues.Inline);
  const embedModalWidget = useEmbedExplainer(gcAccountId, term, termPeriod, WidgetTypeValues.Modal);
  const embedFullPageWidget = useEmbedExplainer(gcAccountId, term, termPeriod, WidgetTypeValues.FullPage);

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <Grid container className={classes.container}>
            <Grid item xs={12} className={classes.iconBox}>
              <InfoIcon className={classes.icon} color={theme.palette.primary.main} viewBox='0 0 26 26' />
              <Typography variant='body1' component='label' className={classes.iconText}>
                Add a unique landing page to your website to educate customers and enable them to easily register to your Trade Account.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.previewContainer}>
          <Grid container className={clsx(classes.marginBetweenContents, classes.boxWithSeparator)}>
            <Grid item xs={12} className={classes.marginBetweenContents}>
              <Typography variant='subtitle2' component='span'>
                Full page
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <FullPageWidgetIcon />
              <ImagePreview src='shift_explainer_widget_fullPage_v2.png' title='Full page preview' />
            </Grid>
            <Grid item xs={10}>
              <EmbedCode embeddableCode={embedFullPageWidget.embeddableCode} />
            </Grid>
          </Grid>
          <Grid container className={clsx(classes.marginBetweenContents, classes.boxWithSeparator)}>
            <Grid item xs={12} className={classes.marginBetweenContents}>
              <Typography variant='subtitle2' component='span'>
                Pop up
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <PopupWidgetIcon />
              <ImagePreview src='shift_explainer_widget_popup_v2.png' title='Pop up preview' />
            </Grid>
            <Grid item xs={10}>
              <EmbedCode embeddableCode={embedModalWidget.embeddableCode} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.marginBetweenContents}>
              <Typography variant='subtitle2' component='span'>
                Inline
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <InlineWidgetIcon />
              <ImagePreview src='shift_explainer_widget_inline_v2.png' title='Inline preview' />
            </Grid>
            <Grid item xs={10}>
              <EmbedCode embeddableCode={embedInlineWidget.embeddableCode} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ExplainerWidget;
