import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { bindActionCreators } from 'redux';
import Component from './Component';
import selectors from './selectors';
import actions from './actions';

const mapStateToProps = createSelector(selectors.selectCount, (count) => ({
  count,
}));

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Component);
