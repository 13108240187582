// @flow
import { PARTITION } from './constants';
import type { ReduxState } from './reducer';
import type { ReduxState as AbiltityPartitionReduxState } from '../AbilityProvider/reducer';

const selectPartition = (state: any): ReduxState => state[PARTITION];
const selectAbilityProviderPartition = (state: any): AbiltityPartitionReduxState => state.ability;
const selectBankAccountSetup = (state: any) => selectPartition(state).bankAccountSetup;
const selectIsBankAccountSetupComplete = (state: any) => selectBankAccountSetup(state) && selectBankAccountSetup(state).isCompleted;
const selectDirectDebitAgreementSetup = (state: any) => selectPartition(state).directDebitAgreementSetup;
const selectIsDirectDebitAgreementSetupComplete = (state: any) =>
  selectDirectDebitAgreementSetup(state) && selectDirectDebitAgreementSetup(state).isCompleted;
const selectLoanAgreementSetup = (state: any) => selectPartition(state).loanAgreementSetup;
const selectIsLoanAgreementSetupComplete = (state: any) => selectLoanAgreementSetup(state) && selectLoanAgreementSetup(state).isCompleted;
const selectPrivacyAgreementSetup = (state: any) => selectPartition(state).privacyAgreementSetup;
const selectIsPrivacyAgreementSetupComplete = (state: any) => selectPrivacyAgreementSetup(state) && selectPrivacyAgreementSetup(state).isCompleted;
const selectTermsAndConditionsAgreementSetup = (state: any) => selectPartition(state).termAndConditionsAgreementSetup;
const selectIsTermsAndConditionsAgreementSetupComplete = (state: any) =>
  selectTermsAndConditionsAgreementSetup(state) && selectTermsAndConditionsAgreementSetup(state).isCompleted;
const selectIsAccountSetupLoaded = (state: any) => selectPartition(state).isAccountSetLoaded;
const selectIsSettlementAccountSetupComplete = (state: any) => selectPartition(state).isSettlementAccountSetupCompleted;
const selectIsMerchantAgreementSetupComplete = (state: any) => selectPartition(state).isMerchantAgreementSetupCompleted;

// return the underlying values since these flags are 3-state booleans
const selectIsAllSetupCompleteRequirement = (state: any) => {
  const isConsentTermsAndConditionsAgreementSetupComplete = selectIsTermsAndConditionsAgreementSetupComplete(state);
  if (!isConsentTermsAndConditionsAgreementSetupComplete) {
    return isConsentTermsAndConditionsAgreementSetupComplete;
  }

  const isPrivacyAgreementSetupComplete = selectIsPrivacyAgreementSetupComplete(state);
  if (selectAbilityProviderPartition(state).hasPermissionToSignPrivacyAgreement && !isPrivacyAgreementSetupComplete) {
    return isPrivacyAgreementSetupComplete;
  }

  const isBankAccountSetupComplete = selectIsBankAccountSetupComplete(state);
  if (!isBankAccountSetupComplete) {
    return isBankAccountSetupComplete;
  }

  const isDirectDebitAgreementSetupComplete = selectIsDirectDebitAgreementSetupComplete(state);
  if (selectAbilityProviderPartition(state).hasPermissionToSignDDRAgreement && !isDirectDebitAgreementSetupComplete) {
    return isDirectDebitAgreementSetupComplete;
  }

  const isLoanAgreementSetupComplete = selectIsLoanAgreementSetupComplete(state);
  if (selectAbilityProviderPartition(state).hasPermissionToSignLoanAgreement && !isLoanAgreementSetupComplete) {
    return isLoanAgreementSetupComplete;
  }

  return true;
};

export default {
  selectPartition,
  selectIsBankAccountSetupComplete,
  selectBankAccountSetup,
  selectDirectDebitAgreementSetup,
  selectIsDirectDebitAgreementSetupComplete,
  selectLoanAgreementSetup,
  selectIsLoanAgreementSetupComplete,
  selectTermsAndConditionsAgreementSetup,
  selectIsTermsAndConditionsAgreementSetupComplete,
  selectIsAllSetupCompleteRequirement,
  selectIsAccountSetupLoaded,
  selectPrivacyAgreementSetup,
  selectIsPrivacyAgreementSetupComplete,
  selectIsSettlementAccountSetupComplete,
  selectIsMerchantAgreementSetupComplete,
};
